<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="title">{{ $tc('title.ajuste_verba', 2) }}</div>
      </v-card-title>
      <v-data-table
        id="tabela_ajuste_verba"
        :headers="headers"
        :items="ajusteVerbaLista"
        class="custom-pagination-left"
        :options.sync="pagination"
        :server-items-length="totalPage"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :key="dataTableKey"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }"
        >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-menu bottom
                origin="center center"
                transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on"
                    icon
                    text>
                    <v-icon id="more_vert">more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="!permiteEditarAjusteVerba(item)"
                    @click="visualizarAjusteVerba(item)">
                    <v-list-item-action>
                      <v-icon>details</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.visualizar_detalhes', 2) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="permiteEditarAjusteVerba(item)"
                    @click="editarAjusteVerba(item)">
                    <v-list-item-action>
                      <v-icon>edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.editar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo"
                    @click="abrirJustificativaAprovar(item)">
                    <v-list-item-action>
                      <v-icon>thumb_up</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.aprovar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo"
                    @click="abrirJustificativaAnalise(item)">
                    <v-list-item-action>
                      <v-icon>chat_bubble</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.enviar_analise', 1) }}</v-list-item-title>
                  </v-list-item>
                    <v-list-item
                      v-show="item.aprovadorFluxo"
                      @click="abrirJustificativaReprovar(item)">
                      <v-list-item-action>
                        <v-icon>thumb_down</v-icon>
                      </v-list-item-action>
                      <v-list-item-title>{{ $tc('label.reprovar', 1) }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-show="item.status === 'EM_ANALISE' && canEdit && item.solicitanteFluxo"
                      @click="confirmarExecutarAnalise(item)">
                      <v-list-item-action>
                        <v-icon>edit</v-icon>
                      </v-list-item-action>
                      <v-list-item-title>{{ $tc('label.executar_analise', 1) }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="mostrarAcompanhamento(item)"
                      @click="abrirAcompanhamento(item)">
                      <v-list-item-action>
                        <v-icon>assignment_turned_in</v-icon>
                      </v-list-item-action>
                      <v-list-item-title>{{ $tc('label.acompanhamento', 1) }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="permiteCancelarAjusteVerba(item)"
                      @click="confirmarCancelarAjusteVerba(item)">
                      <v-list-item-action>
                        <v-icon>block</v-icon>
                      </v-list-item-action>
                      <v-list-item-title>{{ $tc('label.cancelar', 1) }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-show="ajusteVerbaReprovado(item)
                       || ajusteVerbaCancelado(item)
                        || ajusteVerbaEmAnalise(item)"
                      @click="abrirJustificativa(item)">
                      <v-list-item-action>
                        <v-icon>textsms</v-icon>
                      </v-list-item-action>
                      <v-list-item-title>{{ $tc('label.justificativa', 1) }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
              <td v-for="col in getHeaders()" :key="col.value">
                <status
                  v-if="col.value === 'status'"
                  :status="item.status"
                  :tooltip="item.status">
                </status>
                <span v-else>
                  {{ formatarCampo(col.value, item) }}
                </span>
              </td>
            </tr>
        </template>
      </v-data-table>
    </v-card>
    <workflow-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :observacao="justificativa"
      :somenteLeitura="justificativaSomenteLeitura"
    >
    </workflow-justificativa>

    <confirm
      ref="confirmDialog"
      :persistent="true">
    </confirm>

    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
    >
    </acompanhamento>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getMoney } from '../../common/functions/helpers';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import {
  buscarAjusteVerbaLista,
} from '../../common/resources/ajuste-verba';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import WorkflowJustificativa from '../../shared-components/workflow/WorkflowJustificativa';
import Acompanhamento from '../../shared-components/workflow/Acompanhamento';
import AjusteVerbaListDadosFluxo from './AjusteVerbaListDadosFluxo';
import Status from '../../shared-components/Status';

export default {
  name: 'AjusteVerbaListDados',
  components: {
    Confirm,
    WorkflowJustificativa,
    Acompanhamento,
    Status,
  },
  mixins: [
    AjusteVerbaListDadosFluxo,
  ],
  data() {
    return {
      ajusteVerbaResource: this.$api.ajusteVerba(this.$resource),

      ajusteVerbaLista: [],
      headers: [
        { text: this.$tc('label.acoes', 2), value: 'acoes', sortable: false },
        { text: this.$tc('label.tipo_de_ajuste', 1), value: 'tipoajuste', sortable: false },
        { text: this.$tc('label.tipo_de_verba', 1), value: 'tipoverba', sortable: false },
        { text: this.$tc('label.orcamento', 1), value: 'orcamento', sortable: false },
        { text: this.$tc('label.periodo_orcamentario', 1), value: 'periodoorcamentario', sortable: false },
        { text: this.$tc('label.valor', 1), value: 'valor', sortable: false },
        { text: this.$tc('label.status', 1), value: 'status', sortable: false },
      ],
      pagination: {},
      lastPagination: {},
      pagina: 1,
      quantidadePagina: 10,
      totalPage: 0,
      dataTableKey: 0,
    };
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...generateComputed('AJUSTE_VERBA', [
      'canEdit',
    ]),
  },
  props: {
    filtros: Object,
    ordenacao: Array,
  },
  methods: {
    getMoney,
    getHeaders() {
      return this.headers.filter((col) => col.value !== 'acoes');
    },
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    formatarCampo(coluna, item) {
      if (coluna === 'status') {
        return this.formatarStatus(item.status);
      } if (coluna === 'tipoajuste') {
        return this.formatarTipoAjuste(item.tipoajuste);
      } if (coluna === 'valor') {
        return this.getMoney(item.valor);
      }
      return item[coluna];
    },
    formatarStatus(status) {
      status = status.toLowerCase();
      return this.$tc(`status_entidade.${status}`, 1);
    },
    formatarTipoAjuste(tipoAjuste) {
      tipoAjuste = tipoAjuste.toLowerCase();
      return this.$tc(`label.${tipoAjuste}`, 1);
    },
    buscarDados() {
      const params = {
        ...this.filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };

      this.carregarDados(params);
    },
    aplicaFiltros(filtros) {
      const filtrosAplicados = {
        ...filtros,
        numeroPagina: this.pagina,
        tamanhoPagina: this.quantidadePagina,
      };

      this.carregarDados(filtrosAplicados);
    },
    carregarDados(filtrosAplicados) {
      buscarAjusteVerbaLista({ ...filtrosAplicados }, this.$resource)
        .then((res) => {
          this.ajusteVerbaLista = [...res.data.resposta];
          this.totalPage = res.data.quantidadeRegistrosPagina;
          this.ajusteVerbaLista.forEach((av) => {
            this.verificarUsuarioAprovador(av);
            this.verificarUsuarioSolicitante(av);
            if (av.temfluxoaprovacao === 'true') {
              this.buscarStatusFluxo(av);
            }
          });
        }, (err) => {
          this.$toast(err.data.error);
        })
        .then(() => {
          this.$forceUpdate();
        });
    },
    visualizarAjusteVerba(ajusteVerba) {
      if (ajusteVerba.tipoajuste === 'TRANSFERENCIA') {
        this.$router.push({ name: 'verAjusteVerbaTransferencia', params: { id: ajusteVerba.id, from: 'ajuste-verba' } });
      } else if (ajusteVerba.tipoajuste === 'APORTE_MASSIVO') {
        this.$router.push({ name: 'verAporteMassivo', params: { id: ajusteVerba.id, from: 'ajuste-verba' } });
      } else if (ajusteVerba.tipoajuste === 'REDUCAO_MASSIVA') {
        this.$router.push({ name: 'verReducaoMassiva', params: { id: ajusteVerba.id, from: 'ajuste-verba' } });
      } else {
        this.$router.push({ name: 'verAjusteVerba', params: { id: ajusteVerba.id, from: 'ajuste-verba' } });
      }
    },
    editarAjusteVerba(ajusteVerba) {
      if (ajusteVerba.tipoajuste === 'TRANSFERENCIA') {
        this.$router.push({ name: 'editarAjusteVerbaTransferencia', params: { id: ajusteVerba.id } });
      } else if (ajusteVerba.tipoajuste === 'APORTE_MASSIVO') {
        this.$router.push({ name: 'editarAporteMassivo', params: { id: ajusteVerba.id } });
      } else if (ajusteVerba.tipoajuste === 'REDUCAO_MASSIVA') {
        this.$router.push({ name: 'editarReducaoMassiva', params: { id: ajusteVerba.id } });
      } else {
        this.$router.push({ name: 'editarAjusteVerba', params: { id: ajusteVerba.id } });
      }
    },
    atualizarTabela() {
      this.dataTableKey += 1;
    },
    permiteEditarAjusteVerba(item) {
      return this.canEdit && item.solicitanteFluxo && item.status === 'EM_CADASTRO';
    },
    permiteCancelarAjusteVerba(item) {
      return this.canEdit && item.solicitanteFluxo && item.status !== 'EM_ANALISE';
    },
    ajusteVerbaAprovado(ajusteVerba) {
      const { status } = ajusteVerba;
      return status === 'APROVADO';
    },
    ajusteVerbaReprovado(ajusteVerba) {
      const { status } = ajusteVerba;
      return status === 'REPROVADO';
    },
    ajusteVerbaAguardandoAprovacao(ajusteVerba) {
      const { status } = ajusteVerba;
      return status === 'AGUARDANDO_APROVACAO';
    },
    ajusteVerbaEmAnalise(ajusteVerba) {
      const { status } = ajusteVerba;
      return status === 'EM_ANALISE';
    },
    ajusteVerbaCancelado(ajusteVerba) {
      const { status } = ajusteVerba;
      return status === 'CANCELADO';
    },
    isAjusteVerba(ajusteVerba) {
      return ajusteVerba.tipoajuste === 'ADENDO' || ajusteVerba.tipoajuste === 'REDUCAO';
    },
    isTransferencia(ajusteVerba) {
      return ajusteVerba.tipoajuste === 'TRANSFERENCIA';
    },
    abrirJustificativa(ajusteVerba) {
      this.justificativa = ajusteVerba.fluxo.passoAtual.observacao;
      this.justificativaSomenteLeitura = true;
      this.$refs.modalJustificativa.open();
    },
  },
  watch: {
    pagination: {
      handler() {
        if (this.mesmaPagina(this.pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = this.pagination;
        this.buscarDados();
      },
      deep: true,
    },
  },
};
</script>
