<template>
  <div>
    <div style="text-align: center" class="title justify-center">
      <p class="mb-0">{{ title }} - {{ subtitle }}</p>
    </div>
    <v-container fluid grid-list-md class="Ranking_Pagamento pt-0 pb-2">
      <v-data-iterator
        :items="lista"
        hide-default-footer>
        <template v-slot:default="props">
          <v-row align="start" justify="center">
            <v-col cols="12"
              class="pt-0 pl-0"
              v-for="(item, index) in props.items"
              :key="index">
              <v-hover>
                <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 5 : 1}`" fill-height class="Card_Recebimento">
                  <v-card-title class="Card_Title">
                    <span>
                      {{ item.posicao }}º {{ capitalize(item.titulo) }}
                    </span>
                  </v-card-title>
                  <v-card-text class="Card_Content">
                    <p nowrap>
                      {{ getMoney(item.valor) }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </div>
</template>

<script>
import {
  getMoney,
  capitalize,
} from '../../../../common/functions/helpers';
import {
  listarRankingPagamentosPorHolding,
  listarRankingPagamentosPorCategoria,
} from '../../../../common/resources/liquidacao/pagamento';

export default {
  name: 'DashboardInicialRankingPagamento',
  components: {
  },
  props: {
    filtros: Object,
    origem: String,
    title: String,
    subtitle: String,
  },
  data() {
    return {
      lista: [],
      filtrosAplicados: this.filtros,
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscar();
      },
    },
  },
  computed: {
  },
  methods: {
    getMoney,
    capitalize,

    buscar() {
      const params = {
        ...this.filtrosAplicados,
        tamanhoPagina: 7,
      };

      const promise = this.origem === 'categoria'
        ? listarRankingPagamentosPorCategoria(params, this.$resource)
        : listarRankingPagamentosPorHolding(params, this.$resource);

      promise.then((response) => {
        this.lista = response.data;
      })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    this.filtrosAplicados = this.filtros;
  },
};
</script>

<style>

.Ranking_Pagamento .Card_Title {
  padding: 7px 7px 0px 7px ;
}
.Ranking_Pagamento .Card_Content {
  padding: 0px 7px 7px 7px ;
}
.Ranking_Pagamento .Card_Title, .Ranking_Pagamento .Card_Content {
  color: #757575;
  display: block;
}
.Ranking_Pagamento .Card_Title, .Ranking_Pagamento .Card_Content span {
  font-weight: 700;
  margin: 0px;
  font-size: 16px;
}
.Ranking_Pagamento .Card_Content p {
  font-size: 1.4em;
  font-weight: 300;
  margin: 0px;
}
.Ranking_Pagamento .clickable {
  cursor: pointer;
}
</style>
