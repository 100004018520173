<template>
  <popover-confirmacao
    :message="$tc('message.finalizar_apuracao', 1)"
    @agree="concluir"
    @agreeSecond="$emit('ApuracaoContratoAcaoConcluir_irProximo')"
    icone="done_outline"
    :nudge-width="200"
    v-if="podeConcluir"
    :segunda-pergunta="segundaPergunta"
    :textoAcao="$t('label.concluir_apuracao')">
  </popover-confirmacao>
</template>

<script>
import PopoverConfirmacao from '../../shared-components/PopoverConfirmacao';
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  name: 'ApuracaoContratoAcaoConcluir',
  data() {
    return {
      resource: this.$api.apuracaoContrato(this.$resource),
    };
  },
  props: {
    apuracao: Object,
    perguntarProximo: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PopoverConfirmacao,
  },
  computed: {
    ...generateComputed('APU_CONTR', [
      'canAccessCRUD',
    ]),
    segundaPergunta() {
      if (this.perguntarProximo) {
        return this.$t('message.deseja_seguir_proxima_apuracao');
      }
      return null;
    },
    podeConcluir() {
      return (this.apuracao.status === 'AGUARDANDO_APURACAO' || this.apuracao.statusApuracao === 'AGUARDANDO_APURACAO')
        && (this.apuracao.vlrConfApuracao > 0 || this.apuracao.recebimento) && this.canAccessCRUD;
    },
  },
  methods: {
    concluir() {
      const idContrato = this.apuracao.idContrato || this.apuracao.codContrato;
      const idAcao = this.apuracao.idAcao || this.apuracao.codAcao;
      const idApuracao = this.apuracao.idApuracao || this.apuracao.codApuracao;
      this.resource.concluir({ idContrato, idAcao, idApuracao }, { idContrato, idAcao, idApuracao })
        .then(() => {
          this.$emit('ApuracaoContratoAcaoConcluir_concluido');
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
};
</script>
