<template>
  <div class="CardPagamentoContainer">
    <v-container fluid px-0 py-2 class="Container_Nivel2_Pagamento">
      <v-row justify="start" class="mx-0">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            v-model="pesquisaCards"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.pesquisar', 1)}`"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3" align-self="center">
          <v-tooltip bottom v-if="canEdit">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_importar"
                icon
                class="mx-0 pt-1"
                v-on="on"
                @click="abrirModalImportacao">
                <v-icon>backup</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.importar') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row justify="center" class="mx-0">
        <v-container fluid grid-list-md class="Container_Cards">
          <v-data-iterator
            :items="lista"
            :options.sync="pagination"
            :hide-default-footer="12 >= totalPage"
            :server-items-length="totalPage"
            :footer-props="{
              itemsPerPageOptions: rowsPerPageItems,
            }">
            <template v-slot:default="props">
              <v-row justify="start" class="mx-0">
                <v-col
                  v-for="item in props.items"
                  :key="item.id"
                  cols="12"
                  sm="3">
                  <v-hover v-slot:default="{ hover }">
                    <v-card :class="`Card_Pagamento_${item.status} elevation-${hover ? 5 : 1}`" fill-height class="clickable Card_Pagamento">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-card-title class="Card_Title pa-2 justify-center" v-on="on" @click="selectItem(item)">
                            <p style="width: 100%;" class="text-center mb-0">
                              <span v-if="item.id !== item.identificador" style="width: 100%;" class="text-center mb-0">
                                {{ item.idAcao }} - {{ item.identificador }} - {{ item.id }}<br />
                              </span>
                              <span v-else style="width: 100%;" class="text-center mb-0">
                                {{ item.idAcao }} - {{ item.identificador }}<br />
                              </span>
                              <span v-if="isVisaoStatus" style="width: 100%;" class="text-center mb-0">
                                {{ item.codPagador }} - {{ item.nomePagador }}
                              </span>
                            </p>
                          </v-card-title>
                        </template>
                        <div>
                          {{ $t('label.descricao') }}: {{ item.descricaoAcao }}<br />
                          {{ $t('label.status') }}: {{$tc(`status_entidade_pagamento.${item.status.toLowerCase()}`, 1)}}
                        </div>
                      </v-tooltip>
                      <v-card-text @click="selectItem(item)" class="Card_Content py-0 headline font-weight-light">
                        <v-row justify="center" align="center">
                          <v-col cols="12" justify="center" align="center">
                            <p nowrap>
                              {{ getMoney(item.valor) }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
            <template v-slot:no-data>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
            <template v-slot:no-results>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-row>
    </v-container>
    <confirm
      ref="modalConfirmacao"
      :message="mensagemConfirmacao"
      @agree="validarAcao">
    </confirm>
    <modal-importacao-pagamento
      ref="modalImportacaoPagamento"
      @RECARREGAR_PAGAMENTO="buscar">
    </modal-importacao-pagamento>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  getMoney,
  getPercent,
} from '../../../common/functions/helpers';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import { listarPagamentosNivel2, listarPagamentosNivel2Simplificado } from '../../../common/resources/liquidacao/pagamento';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import ModalImportacaoPagamento from './ModalImportacaoPagamento';

export default {
  name: 'PagamentoDadosNivel2Agrupado',
  components: {
    Confirm,
    ModalImportacaoPagamento,
  },
  props: {
    filtros: Object,
    objetoAcao: Object,
  },
  data() {
    return {
      status: null,
      idCliente: null,
      idFornecedor: null,
      origem: null,
      pesquisaCards: '',
      mensagemConfirmacao: '',
      objetoSelecionado: '',
      lista: [],
      filtrosAplicados: {},
      rowsPerPageItems: [12],
      pagination: {
        itemsPerPage: 12,
      },
      totalPage: 0,
      buscando: false,
      esperarDigitar: false,
      acaoConfirmacao: null,
      idPagamento: null,
      numNota: null,
    };
  },
  watch: {
    filtroLiquidacaoAcao: {
      handler() {
        this.filtrosAplicados = this.filtroLiquidacaoAcao;
        this.filtrar();
      },
    },
    pagination: {
      handler() {
        if (this.filtroLiquidacaoAcao) {
          this.filtrar();
        }
      },
      deep: true,
    },
  },
  computed: {
    isVisaoStatus() {
      return !!this.status;
    },
    isVisaoCliente() {
      return !!this.idCliente;
    },
    isVisaoFornecedor() {
      return !!this.idFornecedor;
    },
    ...mapGetters('filtros', [
      'filtroLiquidacaoAcaoNivel2',
      'filtroLiquidacaoAcao',
    ]),
    ...generateComputed('PGTO', [
      'canEdit',
    ]),
  },
  methods: {
    getMoney,
    getPercent,
    ...mapActions('filtros', [
      'setFiltroLiquidacaoAcaoNivel2',
    ]),
    selectItem(item) {
      this.consultar(listarPagamentosNivel2Simplificado, (response) => {
        const listaSimplificada = response.data;

        const params = {
          id: item.id,
          idAcao: item.idAcao,
          objetoAcao: item,
          liquidacoes: listaSimplificada,
        };

        if (this.isVisaoStatus) {
          params.status = this.status;
          this.$router.push({ name: 'detalharPagamentoStatus', params });
        }

        const query = { origem: this.origem };

        if (this.isVisaoCliente) {
          params.idCliente = this.idCliente;
          this.$router.push({ name: 'detalharPagamentoCliente', params, query });
        }

        if (this.isVisaoFornecedor) {
          params.idFornecedor = this.idFornecedor;
          this.$router.push({ name: 'detalharPagamentoFornecedor', params, query });
        }
      });
    },
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },
    buscar() {
      this.consultar(listarPagamentosNivel2, (response) => {
        this.lista = response.data.resposta;
        this.rowsPerPageItems = [4, 8, 12, 16, 20, 24, 28, 32, 36, 40];
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.preencherFiltrosNivel1Vuex();
        setTimeout(this.finalizandoBusca);
      });
    },
    consultar(consulta, sucesso) {
      if (this.objetoSelecionado) {
        if (this.buscando) return;
        this.buscando = true;

        const params = {
          ...this.filtrosAplicados,
          filtro: this.pesquisaCards,
          tamanhoPagina: this.pagination.itemsPerPage,
          numeroPagina: this.pagination.page,
        };

        if (this.isVisaoCliente) {
          params.id_cliente = this.idCliente;
        } else if (this.isVisaoFornecedor) {
          params.id_fornecedor = this.idFornecedor;
        } else {
          params.status = this.status;
        }

        consulta(params, this.$resource)
          .then(sucesso)
          .catch((err) => {
            this.finalizandoBusca();
            this.$error(this, err);
          });
      }
    },
    finalizandoBusca() {
      this.buscando = false;
    },
    abrirModalConfirmacao(acao, item) {
      this.idPagamento = item.id;
      this.numNota = item.num_nota;
      if (acao === 'cancelar') {
        this.mensagemConfirmacao = this.$t('message.deseja_cancelar_pagamento');
        this.acaoConfirmacao = null;
      } else if (acao === 'reenviar') {
        this.mensagemConfirmacao = this.$t('message.deseja_reenviar_pagamento');
        this.acaoConfirmacao = null;
      } else {
        this.mensagemConfirmacao = this.$t('message.deseja_editar_item');
        this.acaoConfirmacao = null;
      }
      setTimeout(() => this.$refs.modalConfirmacao.open());
    },
    validarAcao() {
      if (this.acaoConfirmacao) {
        this.acaoConfirmacao();
      }
    },
    preencherFiltrosRouter() {
      this.status = this.$route.params.status;
      this.idCliente = this.$route.params.idCliente;
      this.idFornecedor = this.$route.params.idFornecedor;
      this.origem = this.$route.query.origem;
    },
    preencherFiltrosNivel2() {
      this.pagination.page = this.filtroLiquidacaoAcaoNivel2.pagina;
      this.pagination.itemsPerPage = this.filtroLiquidacaoAcaoNivel2.quantidatePorPagina;
    },
    preencherFiltrosNivel1Vuex() {
      const pagina = this.pagination.page;
      const quantidatePorPagina = this.pagination.itemsPerPage;

      this.setFiltroLiquidacaoAcaoNivel2({
        pagina,
        quantidatePorPagina,
      });
    },
    abrirModalImportacao() {
      if (this.$refs.modalImportacaoPagamento) {
        this.$refs.modalImportacaoPagamento.toggle();
      }
    },
  },
  beforeMount() {
    this.preencherFiltrosRouter();
    this.objetoSelecionado = this.objetoAcao;
  },
  mounted() {
    if (this.filtroLiquidacaoAcaoNivel2) {
      this.preencherFiltrosNivel2();
    }
    if (this.filtroLiquidacaoAcao && this.objetoSelecionado) {
      this.filtrosAplicados = this.filtroLiquidacaoAcao;
      this.filtrar();
    }
  },
};
</script>

<style>
.Container_Nivel2_Pagamento .Card_Pagamento {
  min-height: 146px !important;
  height: 100%;
}
.Container_Nivel2_Pagamento .Card_Title, .Container_Nivel2_Pagamento .Card_Content {
  color: #757575;
}
.clickable {
  cursor: pointer;
}
</style>
<style src="../../../assets/css/card-status-pagamento.css" lang="css"></style>
