<template>
  <v-container fluid class="pl-0">
    <v-row v-if="tiposArquivos.length">
      <v-col cols="12" xs="6" sm="4" md="3" lg="2" class="pl-0" v-for="(tipo, index) in tiposArquivos" :key="index">
        <v-card
          style="box-shadow: 0 0 0 0"
          width="60px">
          <v-btn
            icon
            width="60px"
            height="60px"
            @click.native="abrirArquivos(tipo.id)">
            <v-icon style="font-size: 80px">folder</v-icon>
          </v-btn>
        </v-card>
        <span justify="center">{{ tipo.descricao }}</span>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="!tiposArquivos.length">
      {{ $t('label.tabela_sem_anexo') }}
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'ListTypeFiles',
  props: {
    uri: '',
  },
  data() {
    return {
      tiposArquivos: [],
    };
  },
  methods: {
    carregarTiposArquivos() {
      this.$http.get(`${this.uri}/tipo-documento`).then((response) => {
        this.tiposArquivos = response.body;
        if (!response.body.length) {
          this.abrirArquivos();
        }
      });
    },
    abrirArquivos(value) {
      this.$emit('ABRIR_ARQUIVOS', value);
    },
    refresh() {
      this.tiposArquivos = [];
      setTimeout(() => this.carregarTiposArquivos());
    },
  },
  mounted() {
    this.carregarTiposArquivos();
  },
};

</script>
