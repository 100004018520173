<template>
  <div>
    <!-- Visão de Cards (Agrupado) -->
    <apuracao-acao-dados-nivel-1-agrupado
      v-if="exibicao === 'agrupado'"
      ref="nivel_1_cards"
      :visao="visao"
      @ApuracaoAcao__SelecionaItemNivel1="selectItem">
    </apuracao-acao-dados-nivel-1-agrupado>

    <!-- Visão de Tabela (Lista) -->
    <apuracao-acao-dados-nivel-1-lista
      v-if="exibicao === 'lista'"
      ref="nivel_1_lista">
    </apuracao-acao-dados-nivel-1-lista>
  </div>
</template>

<script>
import ApuracaoAcaoDadosNivel1Agrupado from './ApuracaoAcaoDadosNivel1Agrupado';
import ApuracaoAcaoDadosNivel1Lista from './ApuracaoAcaoDadosNivel1Lista';

export default {
  data() {
    return {
      visao: 'Status',
      exibicao: 'agrupado',
    };
  },
  components: {
    ApuracaoAcaoDadosNivel1Agrupado,
    ApuracaoAcaoDadosNivel1Lista,
  },
  methods: {
    selectItem(item, visaoSelecionada) {
      if (visaoSelecionada === 'Status') {
        this.$router.push({
          name: 'listarApuracoesAcaoNivel2Status',
          params: {
            status: item.status,
          },
        });
      } else if (visaoSelecionada === 'Fornecedor') {
        this.$router.push({
          name: 'listarApuracoesAcaoNivel2Fornecedor',
          params: {
            idFornecedor: item.id_fornecedor,
          },
          query: {
            origem: item.titulo,
          },
        });
      } else if (visaoSelecionada === 'Fornecedor Pagador') {
        this.$router.push({
          name: 'listarApuracoesAcaoNivel2FornecedorPagador',
          params: {
            idFornecedorPagador: item.id_fornecedor,
          },
          query: {
            origem: item.titulo,
          },
        });
      } else if (visaoSelecionada === 'Grupo Fornecedor') {
        this.$router.push({
          name: 'listarApuracoesAcaoNivel2Grupo',
          params: {
            idGrupoFornecedor: item.id_grupo_fornecedor,
          },
          query: {
            origem: item.titulo,
          },
        });
      } else if (visaoSelecionada === 'Cliente') {
        this.$router.push({
          name: 'listarApuracoesAcaoNivel2Cliente',
          params: {
            idCliente: item.id_cliente,
          },
          query: {
            origem: item.titulo,
          },
        });
      } else if (visaoSelecionada === 'Holding') {
        this.$router.push({
          name: 'listarApuracoesAcaoNivel2Holding',
          params: {
            idHolding: item.id_holding,
          },
          query: {
            origem: item.titulo,
          },
        });
      } else if (visaoSelecionada.toUpperCase() === 'CANAL') {
        this.$router.push({
          name: 'listarApuracoesAcaoNivel2Extensao',
          params: {
            idExterno: item.id_extensao,
            mnemonico: visaoSelecionada.toUpperCase(),
            idExtensao: item.id,
            text: item.titulo,
          },
          query: {
            origem: item.titulo,
          },
        });
      } else if (visaoSelecionada === 'Regional') {
        this.$router.push({
          name: 'listarApuracoesAcaoNivel2Regional',
          params: {
            idRegional: item.id_regional,
          },
          query: {
            origem: item.titulo,
          },
        });
      }
    },
  },
  beforeMount() {
    this.visao = this.$route.params.visao ? this.$route.params.visao : 'Status';
  },
};
</script>
