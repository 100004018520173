var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-row',{attrs:{"justify-end":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5"}},[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$t('title.cadastrar_pre_usuario')))])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-switch',{staticClass:"my-auto",attrs:{"color":"primary","label":_vm.$t('label.exibir_usuario_ja_cadastrado'),"single-line":"","hide-details":""},on:{"change":_vm.executarBusca},model:{value:(_vm.indUsuarioJaCadastrado),callback:function ($$v) {_vm.indUsuarioJaCadastrado=$$v},expression:"indUsuarioJaCadastrado"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{staticClass:"my-auto pt-0",attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","hide-details":""},on:{"input":_vm.executarBusca},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.usuarios,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
      itemsPerPageOptions: [10, 25, 50],
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.nom_estrutura_usuario",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomEstruturaUsuario))]}},{key:"item.nom_perfil",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomPerfil))]}},{key:"item.ind_usuario_ja_cadastrado",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.indUsuarioJaCadastrado ? _vm.$t('label.sim') : _vm.$t('label.nao')))]}},{key:"item.acao",fn:function(ref){
    var item = ref.item;
return [(!item.indUsuarioJaCadastrado)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.excluirItem(item)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.excluir')))])]):_vm._e(),(!item.indUsuarioJaCadastrado)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.editar')))])]):_vm._e()]}}])}),_c('confirm',{ref:"cancelDialog",attrs:{"message":_vm.$t('message.deseja_excluir', {text: _vm.$t('label.cadastrar_pre_usuario')}),"persistent":true},on:{"agree":_vm.excluirUsuario}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }