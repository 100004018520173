<template>
  <v-form ref="form">
    <filtro-rapido
       v-model="abrirFiltro"
       ref="filtrosRapidos"
       id="ApuracaoContratoFinalizarLoteFiltros__filtro_rapido"
       v-if="carregouMetadados && carregouFiltrosPadroes"
       :campos-formulario="camposFormulario"
       :metadados="metadadosAgrupado"
       :ordenacao-campos="ordenacaoCampos"
       :mostrar-fixar-filtros="false"
       :formulario-filtros="false"
       :ignora-obrigatorios="false"
       grupo-expandido="Ação"
       :setar-filtro-padrao-ao-limpar="true"
       :aplicar-filtro-no-carregamento="false"
       :filtro-padrao="filtroPadrao"
       @FiltroRapido__AplicaFiltros="aplicarFiltros"
       @FiltroRapido_conteinerCarregado="$emit('ApuracaoContratoFinalizarLoteFiltros_conteinerCarregado')">
    </filtro-rapido>

  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FiltroRapido from '../../shared-components/filtro-rapido/FiltroRapido';

export default {
  name: 'ApuracaoContratoFinalizarLoteFiltros',
  components: { FiltroRapido },
  props: {
    value: false,
  },
  data() {
    return {
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      metadadoResource: this.$api.metadado(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      apuracaoContratoResource: this.$api.apuracaoContrato(this.$resource),

      abrirFiltro: false,
      carregouFiltrosPadroes: false,
      idContrato: null,
      status: null,
      ordenacaoCampos: [
        {
          campo: 'tipo_acao',
          nomeGrupo: 'Ação',
        },
        {
          campo: 'grupo_fornecedor',
          nomeGrupo: 'Ação',
        },
        {
          campo: 'categoria',
          nomeGrupo: 'Produto',
          forcarGrupo: true,
        },
        {
          campo: 'data_inicio_apuracao',
          nomeGrupo: 'Período',
        },
        {
          campo: 'data_fim_apuracao',
          nomeGrupo: 'Período',
        },
      ],
      filtroPadrao: {},
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getContratoMetadado',
    ]),
    camposFormulario() {
      return {
        padrao: [
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'LISTA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'grupo_fornecedor',
            nomCampoId: 'grupo_fornecedor',
            tipoCampo: 'LISTA',
            desAtributos: { desabilitado: !!this.idContrato },
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarGruposFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'data_inicio_apuracao',
            nomCampo: this.$t('label.data_inicio'),
            vlrObrigatorio: true,
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim_apuracao',
            nomCampo: this.$t('label.data_fim'),
            vlrObrigatorio: true,
            tipoCampo: 'DATA',
          },
        ],
      };
    },
    carregouMetadados() {
      return this.getAcaoMetadado && this.getContratoMetadado;
    },
    metadadosAgrupado() {
      if (!this.carregouMetadados) {
        return {};
      }

      const metadados = { ...this.getContratoMetadado };
      metadados.dicionario = { ...this.getAcaoMetadado.dicionario };
      Object.assign(metadados.dicionario, this.getContratoMetadado.dicionario);

      metadados.mapaCamposDinamicos = { ...this.getAcaoMetadado.mapaCamposDinamicos };
      Object.assign(metadados.mapaCamposDinamicos, this.getContratoMetadado.mapaCamposDinamicos);

      metadados.mapaEntidades = {};
      Object.assign(metadados.mapaEntidades, this.getContratoMetadado.mapaEntidades);

      Object.keys(this.getAcaoMetadado.mapaEntidades).forEach((k) => {
        metadados.mapaEntidades[k] = { ...this.getAcaoMetadado.mapaEntidades[k] };
        metadados.mapaEntidades[k].depObrigatoria = false;
        metadados.mapaEntidades[k].manyToMany = false;
      });

      Object.keys(this.getContratoMetadado.mapaEntidades).forEach((k) => {
        metadados.mapaEntidades[k] = { ...this.getContratoMetadado.mapaEntidades[k] };
        metadados.mapaEntidades[k].depObrigatoria = false;
        metadados.mapaEntidades[k].manyToMany = false;
      });

      if (metadados.cabecalho) {
        metadados.cabecalho.concat(...this.getAcaoMetadado.cabecalho);
      } else {
        metadados.cabecalho = this.getAcaoMetadado.cabecalho;
      }
      return metadados;
    },
  },
  watch: {
    value(val) {
      this.abrirFiltro = val;
    },
    abrirFiltro(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
      'setContratoMetadado',
    ]),
    aplicarFiltros(filtrosAplicados) {
      if (!this.$refs.form.validate() || !filtrosAplicados
        || !this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      filtrosAplicados.cod_contrato = this.idContrato;
      filtrosAplicados.status = [this.status];
      filtrosAplicados.id_grupo_fornecedor = filtrosAplicados.grupo_fornecedor;
      this.$emit('ApuracaoContratoFinalizarLoteFiltros__AplicaFiltros', filtrosAplicados);
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados && (filtrosAplicados.data_inicio_apuracao
        || filtrosAplicados.data_fim_apuracao)) {
        let dtInicio = '';
        let dtFinal = '';
        dtInicio = this.moment(filtrosAplicados.data_inicio_apuracao, 'DD-MM-YYYY');
        dtFinal = this.moment(filtrosAplicados.data_fim_apuracao, 'DD-MM-YYYY');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio_apuracao && !filtrosAplicados.data_fim_apuracao)
          || (!filtrosAplicados.data_inicio_apuracao && filtrosAplicados.data_fim_apuracao)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
    buscarFornecedorContrato() {
      if (!this.idContrato) {
        return new Promise((res) => { res(); });
      }
      const { idContrato } = this;
      return this.planejamentoContratoResource.buscarGrupoFornecedorContrato({ idContrato })
        .then((response) => {
          this.filtroPadrao.grupo_fornecedor = response.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarDataApuracoes() {
      const params = {
        status: this.status,
      };
      if (this.idContrato) {
        params.idContrato = this.idContrato;
      }

      return this.apuracaoContratoResource.buscarDataApuracaoAcaoPorStatus(params)
        .then((response) => {
          this.filtroPadrao.data_inicio_apuracao = response.data.dataInicioApuracao;
          this.filtroPadrao.data_fim_apuracao = response.data.dataFimApuracao;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    preencherFiltroPadrao() {
      Promise
        .all([
          this.buscarFornecedorContrato(),
          this.buscarDataApuracoes(),
        ])
        .then(() => {
          this.carregouFiltrosPadroes = true;
        });
    },
  },
  beforeMount() {
    this.idContrato = this.$router.currentRoute.params.idContrato;
    this.status = this.$router.currentRoute.params.status || 'AGUARDANDO_APURACAO';
    this.preencherFiltroPadrao();
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
    this.setContratoMetadado({
      resource: this.metadadoResource.definicaoContrato,
    });
  },
};
</script>

<style scoped>

</style>
