const mutations = {
  alterarImplantacao(state, dados) {
    state.implantacao.ambiente_produtivo = dados.ambiente_produtivo;
    state.implantacao.chave_empresa = dados.chave_empresa;
    state.implantacao.descricao = dados.descricao;
    state.implantacao.logomarca = dados.logomarca;
    state.implantacao.modo = dados.modo;
    state.implantacao.indVarejo = dados.modo === 'VAREJO';
    state.implantacao.nome = dados.nome;
    state.implantacao.indIntegracaoLiquidacao = dados.integracao_liquidacao;
  },
};

const actions = {
  carregarImplantacao({ commit }, resource) {
    return new Promise((resolve, reject) => {
      resource().then((response) => {
        commit('alterarImplantacao', response.data);
        resolve();
      }).catch(reject);
    });
  },
};

const getters = {
  implantacao(state) {
    return state.implantacao;
  },
  indModoVarejo(state) {
    return state.implantacao.indVarejo;
  },
  indAmbienteProdutivo(state) {
    return state.implantacao.ambiente_produtivo;
  },
};

const state = {
  implantacao: {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
