<template>
  <v-form ref="formContaCorrente"
          v-model="formContaCorrenteValido"
          lazy-validation>
    <planejamento-acao-form-conta-corrente
        v-if="!!objetoPassoGeral"
        :tipo-acao="tipoAcao"
        :configuracao="configuracao"
        :objeto-passo-geral="objetoPassoGeral"
        :objeto-passo-comportamento="objetoPassoComportamento"
        :acao-edicao="acaoEdicao"
        :nova-acao="novaAcao"
        :somente-leitura="somenteLeitura"
        :edicao="edicao"
        :get-objeto-outro-passo="getObjetoOutroPasso"
        ref="componenteContaCorrente"
        @ContaCorrenteRedistribuicaoMixin__abrirRedistribuicaoContas="abrirRedistribuicaoContas"
        @PlanejamentoAcaoFormContaCorrente__evento="(e) => $emit('PlanejamentoAcaoForm__evento', e)">
      <div class="card-title" slot="titulo-acao">
        {{ $t('label.criacao_acao')}} :: {{ tituloTipoAcao }} :: {{$tc('title.resumo', 1)}}
      </div>
    </planejamento-acao-form-conta-corrente>

    <confirm
        ref="redistribuicaoContasDialog"
        :message="$t('message.conta_corrente_filtros_alterados')"
        :persistent="true"
        @agree="avancaPasso"
        @disagree="cancelar"/>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';
import PlanejamentoAcaoFormContaCorrente from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormContaCorrente';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'PlanejamentoAcaoFormContaCorrenteWrapper',
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acaoEdicao: Object,
    edicao: Boolean,
    tipoAcao: {
      type: Object,
      required: true,
    },
    passosHabilitados: {
      type: Object,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
    getObjetoOutroPasso: {
      type: Function,
      required: true,
    },
  },
  components: {
    PlanejamentoAcaoFormContaCorrente,
    Confirm,
  },
  computed: {
    tituloTipoAcao() {
      return this.tipoAcao != null ? this.tipoAcao.nome : '';
    },
    passoComportamentoHabilitado() {
      return this.passosHabilitados.comportamento.habilitado;
    },
  },
  data() {
    return {
      formContaCorrenteValido: false,
      objetoPassoComportamento: null,
      objetoPassoGeral: null,
      valorApuracao: null,
      coeficienteApuracao: null,
      valorAlteradoApuracao: false,
      avancaPasso: () => ({}),
    };
  },
  methods: {
    ...mapActions('acaoSaldoContaCorrente', [
      'registrarContas',
      'setContas',
    ]),
    onEvent(evento) {
      if (evento.ehEvento('alteradoValorTotal')) {
        this.alteradoValorTotal(evento);
      } else if (this.$refs.componenteContaCorrente) {
        this.$refs.componenteContaCorrente.onEvent(evento);
      }
    },
    onChainedEvents(evento) {
      if (evento.ehEvento('antesIrUltimoPasso')
          || evento.ehEvento('carregarEdicao')) {
        this.abrePasso(evento);
      }
    },
    valida() {
      return this.$refs.formContaCorrente.validate();
    },
    validaSalvar(isSolicitandoAprovacao = false) {
      if (!this.valida()) {
        return false;
      }
      return this.$refs.componenteContaCorrente.validaSalvar(isSolicitandoAprovacao);
    },
    getObjetoPasso() {
      return this.$refs.componenteContaCorrente.getObjetoPasso();
    },
    preencherObjetoFormatado(acao) {
      this.$refs.componenteContaCorrente.preencherObjetoFormatado(acao);
    },
    abrirRedistribuicaoContas(avancaPasso) {
      this.avancaPasso = avancaPasso;
      this.$refs.redistribuicaoContasDialog.open();
    },
    cancelar() {
      this.$emit('PlanejamentoAcaoForm__cancelar');
    },
    alteradoValorTotal(evento) {
      this.valorApuracao = evento.objeto.valor;
      this.coeficienteApuracao = evento.objeto.coeficiente;
      this.valorAlteradoApuracao = true;
      this.alterarValorPassoGeral();
    },
    alterarValorPassoGeral() {
      if (this.valorAlteradoApuracao && this.objetoPassoGeral) {
        this.objetoPassoGeral.valor = this.valorApuracao;
        this.objetoPassoGeral.coeficienteValorTotal = this.coeficienteApuracao;
      }
    },
    preencherPassosAnteriores() {
      this.objetoPassoGeral = this.getObjetoOutroPasso('geral');
      this.alterarValorPassoGeral();
      if (this.passoComportamentoHabilitado) {
        this.objetoPassoComportamento = this.getObjetoOutroPasso('comportamento');
      }
    },
    abrePasso(evento = null) {
      this.preencherPassosAnteriores();
      setTimeout(() => {
        this.$refs.componenteContaCorrente.abrePasso();
        if (evento) {
          evento.executarProximoPasso();
        }
      }, 5E2);
    },
  },
};
</script>
