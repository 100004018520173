<template>
  <acao-previa-form
    :somente-leitura="false"
    :novo="false"
    :edicao="true"
    />
</template>
<script>
// Componente utilizado para criar comportamento de auto redirecionamento
// criando efeito de refresh na rota
import AcaoPreviaForm from './AcaoPreviaForm';

export default {
  components: {
    AcaoPreviaForm,
  },
};
</script>
