import getBasePath from '../../produto/common/functions/api-resource';

const basePath = getBasePath('api', 'extensao');

const extensaoAdamaActions = {
  buscarNomesFantasia: { methods: 'GET', url: `${basePath}/nomesFantasia?filtro={filtro}&limit{limit}` },
  buscarNomesFantasiaRelatorio: { methods: 'GET', url: `${basePath}/nomesFantasiaRelatorios?autocomplete={autocomplete}` },
};

export default (resource) => resource(`${basePath}`, {}, extensaoAdamaActions);
