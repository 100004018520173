var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"centered":"","background-color":"rgba(0,0,0,0)","color":"accent"},model:{value:(_vm.tabSelecionada),callback:function ($$v) {_vm.tabSelecionada=$$v},expression:"tabSelecionada"}},[_c('v-tab',{staticClass:"aba-text",attrs:{"href":"#tab-temporaria"}},[_vm._v(_vm._s(_vm.$t('label.transferencia_temporaria')))]),_c('v-tab',{staticClass:"aba-text",attrs:{"href":"#tab-historico"}},[_vm._v(_vm._s(_vm.$t('label.historico')))]),_c('v-tabs-items',{model:{value:(_vm.tabSelecionada),callback:function ($$v) {_vm.tabSelecionada=$$v},expression:"tabSelecionada"}},[_c('v-tab-item',{staticClass:"mt-3",attrs:{"id":"tab-temporaria"}},[_c('v-container',{attrs:{"ml-0":""}},[_c('v-row',{attrs:{"justify-end":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},on:{"click:clear":function($event){return _vm.limparEBuscarTransferenciasVigentes()}},model:{value:(_vm.searchQueryVigente),callback:function ($$v) {_vm.searchQueryVigente=$$v},expression:"searchQueryVigente"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",class:{ 'controls-puller': _vm.possesTemporarias.length > 5 },attrs:{"headers":_vm.headersPossesTemporarias,"items":_vm.possesTemporarias,"options":_vm.paginationTransferenciasVigentes,"server-items-length":_vm.totalPageVigente,"footer-props":{
            itemsPerPageText: _vm.$t('label.linhas_por_pagina'),
            itemsPerPageOptions: [10, 25, 50],
          },"no-data-text":_vm.$t('label.tabela_sem_conteudo')},on:{"update:options":function($event){_vm.paginationTransferenciasVigentes=$event}},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
          var item = ref.item;
return [(_vm.canEdit)?_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.editar(item)}}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e()]}},{key:"item.existeUsuarioAssociado",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(!item.existeUsuarioAssociado)?{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("warning")])],1)]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.usuario_associado_divisao', { de: item.de, para: item.para})))])])]}},{key:"item.dtaInicio",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formataData(item.dtaInicio))+" ")]}},{key:"item.dtaFim",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formataData(item.dtaFim))+" ")]}}])}),(_vm.canEdit)?_c('v-btn',{staticClass:"v-btn__fab",attrs:{"color":"primary","dark":"","fixed":"","fab":"","bottom":"","right":""},on:{"click":_vm.registrar}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1),_c('v-tab-item',{staticClass:"mt-3",attrs:{"id":"tab-historico"}},[_c('v-container',{attrs:{"ml-0":""}},[_c('v-row',{attrs:{"justify-end":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},on:{"click:clear":function($event){return _vm.limparEBuscarTransferenciasHistorico()}},model:{value:(_vm.searchQueryHistorico),callback:function ($$v) {_vm.searchQueryHistorico=$$v},expression:"searchQueryHistorico"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersHistoricoPosses,"items":_vm.historicoPosses,"options":_vm.paginationHistorico,"server-items-length":_vm.totalPageHistorico,"footer-props":{
            itemsPerPageText: _vm.$t('label.linhas_por_pagina'),
            itemsPerPageOptions: [10, 25, 50],
          },"no-data-text":_vm.$t('label.tabela_sem_conteudo')},on:{"update:options":function($event){_vm.paginationHistorico=$event}},scopedSlots:_vm._u([{key:"item.dtaInicio",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formataData(item.dtaInicio))+" ")]}},{key:"item.dtaFim",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formataData(item.dtaFim))+" ")]}},{key:"pageText",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" "+_vm._s(_vm.$tc('label.de', 2))+" "+_vm._s(props.itemsLength)+" ")]}}])})],1)],1)],1),_c('transferencia-posse-form',{ref:"transfForm",attrs:{"somente-leitura":!_vm.canAccessPage},on:{"cancelar":_vm.fecharForm,"atualizarGrid":function($event){return _vm.carregarGrids()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }