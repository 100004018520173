<template>
  <parametrizacao-periodo-passo
    tem-proximo
    tem-anterior
    :somente-leitura="somenteLeitura"
    :alterar-finalizado="alterarFinalizado"
    :nao-valida-form="true"
    @PARAMETRIZACAO_PERIODO_PASSO_VOLTAR="voltarPasso"
    @PARAMETRIZACAO_PERIODO_PASSO_PROXIMO="salvarEProximoPasso"
    @PARAMETRIZACAO_PERIODO_PASSO_SALVAR_RASCUNHO="salvarRascunho">
    <v-container fluid>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ $t('title.selecao') }}</v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col cols="12" md="3" :offset-md="index%2 === 0 ? '3' : '1'" class="pa-0" v-for="(indicador, index) in indicadores" :key="`indicador-${index}`">
          <v-checkbox
            v-model="passo3.indicadores"
            :label="$t(indicador.descricao)"
            :value="indicador"
            :disabled="indicador.bloqueado || somenteLeitura">
          </v-checkbox>
        </v-col>
      </v-row>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ $t('title.configuracao') }}</v-toolbar-title>
      </v-toolbar>
      <indicadores-cards
        ref="indicadoresCards"
        :id-periodo="idPeriodo"
        :indicadores="passo3.indicadores"
        :somente-leitura="somenteLeitura"
        :alterar-finalizado="alterarFinalizado"
        @IndicadoresCards_remover="removerIndicador">
      </indicadores-cards>
    </v-container>
  </parametrizacao-periodo-passo>
</template>

<script>
import ParametrizacaoPeriodoPasso from '../ParametrizacaoPeriodoPasso';
import ParametrizacaoPeriodo from '../ParametrizacaoPeriodo';
import Passo3 from './Passo3';
import IndicadoresCards from './IndicadoresCards';
import Indicador from './Indicador';

export default {
  name: 'ParametrizacaoPeriodoPasso3',
  components: {
    IndicadoresCards,
    ParametrizacaoPeriodoPasso,
  },
  props: {
    idPeriodo: {
      type: Number,
      default: null,
    },
    periodoConfiguracao: {
      type: ParametrizacaoPeriodo,
      default: null,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
    exibindo: false,
  },
  data() {
    return {
      parametrizacaoPeriodoPasso3: this.$api.parametrizacaoPeriodoPasso3(this.$resource),
      passo3: new Passo3(),
      carregado: false,
      indicadores: [],
    };
  },
  watch: {
    exibindo(val) {
      if (val && !this.carregado) {
        this.buscarIndicadores();
      }
    },
    idPeriodo() {
      this.passo3.idPeriodoConfiguracao = this.idPeriodo;
    },
  },
  methods: {
    configuracaoAlterada() {
      this.$emit('ParametrizacaoPeriodoPasso3_configuracaoAlterada', this.passo3.idsExtensoesCliente);
    },
    removerIndicador(indicador) {
      this.passo3.removerIndicador(indicador);
    },
    buscarPasso() {
      if (!this.idPeriodo) return;
      const id = this.idPeriodo;
      this.parametrizacaoPeriodoPasso3.obterPasso3({ id })
        .then((response) => {
          this.passo3 = new Passo3(response.body);
        })
        .then(this.substituirIndicadores)
        .then(this.configuracaoAlterada)
        .catch((err) => {
          this.$error(this, err);
        });
    },
    substituirIndicadores() {
      this.passo3.indicadores.forEach((indicador) => {
        let achou = false;
        for (let i = 0; i < this.indicadores.length && !achou; i += 1) {
          if (this.indicadores[i].id === indicador.id) {
            this.indicadores[i] = indicador;
            achou = true;
          }
        }
        if (!achou) {
          this.indicadores.push(indicador);
        }
      });
      this.$forceUpdate();
    },
    buscarIndicadores() {
      this.parametrizacaoPeriodoPasso3.buscarIndicadores()
        .then((response) => {
          this.indicadores = response.body.map((i) => new Indicador(i));
        })
        .then(this.buscarPasso)
        .catch((err) => {
          this.$error(this, err);
        });
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_PERIODO_PASSO3_VOLTAR');
    },
    salvarEProximoPasso() {
      if (this.somenteLeitura && !this.alterarFinalizado) {
        this.proximoPasso();
      } else {
        if (!this.passo3.estaConfigurado) {
          this.$toast(this.$t('message.indicador_nao_configurado'));
          return;
        }
        this.parametrizacaoPeriodoPasso3.salvarPasso3({ id: this.idPeriodo }, this.passo3)
          .then(() => {
            this.buscarPasso();
            this.proximoPasso();
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    proximoPasso() {
      this.$emit('PARAMETRIZACAO_PERIODO_PASSO3_PROXIMO', this.passo3);
    },
    salvarRascunho() {
      this.parametrizacaoPeriodoPasso3.salvarRascunhoPasso3({ id: this.idPeriodo }, this.passo3)
        .then(() => {
          this.$toast(this.$t('message.rascunho_salvo'));
          this.buscarPasso();
          this.$emit('PARAMETRIZACAO_PERIODO_PASSO3_RASCUNHO');
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    recarregarIndicadores() {
      this.$refs.indicadoresCards.recarregarIndicadores();
    },
  },
};
</script>
