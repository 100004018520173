<template>
  <div>
    <upload-file-form-area
    v-if="podeAnexar"
      ref="uploadArquivo"
      :title="$t('label.upload_anexo')"
      :url="uriUpload"
      :auto-process-queue="false"
      @UPLOADFILE_SENDING="enviandoArquivo"
      @UPLOADFILE_FILE_ADDED="preencherDescricaoComNomeArquivo"
      @UploadArquivo__success="uploadSucesso"
      @UploadArquivo__error="uploadErro">
    </upload-file-form-area>
    <div class="painel-anexo">
      <v-row>
        <list-files-novo
          @ListFiles__arquivos="(files) => $emit('FormAnexoArquivo__qtde', files.length)"
          :habilitar-exclusao="podeRemover" ref="listFiles" :uri="uri"></list-files-novo>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import basePath from '../../../common/functions/api-resource';
import ListFilesNovo from '../../../shared-components/files/ListFilesNovo';
import UploadFileFormArea from '../../../shared-components/upload/UploadFileFormArea';

export default {
  name: 'PlanejamentoContratoFormAnexoArquivoNovo',
  components: {
    UploadFileFormArea,
    ListFilesNovo,
  },
  props: {
    acao: Object,
    novaAcao: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      descricaoArquivo: null,
      exibeArquivos: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    uri() {
      const urlUpload = basePath('job', 'arquivo/acao');
      return `${urlUpload}/${this.acao.id}`;
    },
    uriUpload() {
      const urlUpload = basePath('job', 'arquivo/acao');
      return `${urlUpload}/${this.acao.id}/upload`;
    },
    hasRole() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ACAO_CRUD').length;
    },
    podeAnexar() {
      if (this.acao.passoGeral) {
        return this.hasRole && (['EM_CADASTRO', 'EM_ANALISE'].indexOf(this.acao.passoGeral.status) >= 0);
      }
      return this.hasRole;
    },
    getStatusPermitidosUploadPlanejamento() {
      const statusPermitidos = [];
      if (this.configuracao
        && this.configuracao.configuracao.passo1
        && this.configuracao.configuracao.passo1.statusPermitidosUploadPlanejamento) {
        const status = this.configuracao.configuracao.passo1.statusPermitidosUploadPlanejamento;
        if (status.emCadastro) {
          statusPermitidos.push('EM_CADASTRO');
        }
        if (status.aprovado) {
          statusPermitidos.push('APROVADO');
        }
        if (status.aguardandoAprovacao) {
          statusPermitidos.push('AGUARDANDO_APROVACAO');
        }
        if (status.reprovado) {
          statusPermitidos.push('REPROVADO');
        }
        if (status.emAnalise) {
          statusPermitidos.push('EM_ANALISE');
        }
        if (status.cancelado) {
          statusPermitidos.push('CANCELADO');
        }
      }
      return statusPermitidos;
    },
    podeRemover() {
      return this.hasRole && this.acao.passoGeral && ['EM_CADASTRO', 'EM_ANALISE'].indexOf(this.acao.passoGeral.status) >= 0;
    },
  },
  methods: {
    enviandoArquivo(data, xhr, formData) {
      formData.append('descricao', encodeURI(this.descricaoArquivo));
    },
    preencherDescricaoComNomeArquivo(file) {
      this.descricaoArquivo = file.name;
    },
    uploadSucesso() {
      setTimeout(() => this.$refs.listFiles.refresh(), 1E3);
      this.$toast(this.$t('label.upload_arquivo_sucesso'));
    },
    uploadErro(msg) {
      setTimeout(() => this.$refs.listFiles.refresh(), 1E3);
      this.$toast(msg);
    },
  },
};
</script>
<style scoped>
.anexo-desabilitado {
  padding: 12px 0 0 12px;
  font-size: 16px !important;
  color: red;
}
.painel-anexo .container.mx-0 {
  max-width: 97% !important;
}
</style>
