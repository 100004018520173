<template lang="html">
  <div style="height: 100%;">
    <v-card class="card-size" height="100%">
      <v-card-title>
        <h2 class="headline mb-0" v-text="title"></h2>
      </v-card-title>
      <v-row justify="start">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            v-model="searchQuery"
            @input="filtrar"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3" align-self="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon
                id="tipo-investimento-botao-ativar-inativar"
                v-show="canAccessCRUD && selected.length > 0"
                class="mx-0"
                v-on="on"
                @click="ativarDesativarRegistros">
                <v-icon>block</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.ativar_inativa') }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="canAccessCRUD">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_exportar" icon :disabled="bloquearBotaoExportacao" class="mx-0" v-on="on" @click="exportar()">
                <v-icon>get_app</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.exportar') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headersDinamico"
        v-model="selected"
        show-select
        :items="centroCustos"
        :options.sync="pagination"
        :server-items-length="totalPage"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:item="{ item, isSelected, select }">
          <tr :key="item.name" :class="{ 'TableRow--disabled': !item.indAtivo }">
            <td>
              <v-simple-checkbox :value="isSelected" @input="select($event)"></v-simple-checkbox>
            </td>
            <td class="justify-center px-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }" v-if="!somenteLeitura">
                  <v-btn icon class="mx-0" v-on="on" @click.stop="onEditarCentroCusto(item)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('label.editar') }}</span>
              </v-tooltip>
            </td>
            <td>{{ item.idExterno }}</td>
            <td>{{ item.nome }}</td>
            <td v-for="entidade in Object.keys(metadadosCentroCusto.mapaEntidades)">
              {{ getNomesSeparadosPorVirgula(entidade, 'nomExtensao', item) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import CentroCusto from './CentroCusto';
import * as metadados from '../../common/functions/metadados';

export default {
  data() {
    return {
      centroCustoResources: this.$api.centroCusto(this.$resource),
      bloquearBotaoExportacao: false,
      centroCustos: [],
      esperar: false,
      headers: [
        { text: this.$tc('label.cod_centro_custo', 1), value: 'id_externo' },
        { text: this.$tc('label.nome', 1), value: 'nome' },
      ],
      pagination: {},
      totalPage: 0,
      searchQuery: '',
      registros: [],
      selected: [],
    };
  },
  props: {
    title: String,
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    searchQuery() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  computed: {
    filteredList() {
      return [];
    },
    ...generateComputed('ceco', [
      'canAccessCRUD',
      'canAccessPage',
    ]),
    ...mapGetters('metadados', [
      'getCentroCustoMetadado',
    ]),
    metadadosCentroCusto() {
      if (this.getCentroCustoMetadado && this.getCentroCustoMetadado.mapaEntidades) {
        return this.getCentroCustoMetadado;
      }
      return undefined;
    },
    headersDinamico() {
      const headers = Object.assign([], this.headers);
      if (!this.metadadosCentroCusto) return headers;

      Object.keys(this.metadadosCentroCusto.mapaEntidades).forEach((entidade) => {
        const mapeamentoEntidade = this.metadadosCentroCusto.mapaEntidades[entidade];
        headers.push({
          text: mapeamentoEntidade.entidadeEstrangeira,
          value: mapeamentoEntidade.label,
          sortable: false,
        });
      });

      headers.unshift({
        text: '',
        value: 'acoes',
        sortable: false,
      });

      return headers;
    },
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  methods: {
    ...metadados,
    buscar() {
      if (this.searchQuery) {
        this.pagination.page = 1;
      }

      const params = {
        filtro: this.searchQuery,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.centroCustoResources.listar(params).then((response) => {
        this.registros = response.data.resposta;
        this.montarObjetoCentroCustos();
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    exportar() {
      this.bloquearBotaoExportacao = true;
      this.$emit('TABELA_EXPORTAR_DADOS',
        () => { this.bloquearBotaoExportacao = false; });
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },
    getSearchQuery() {
      return this.searchQuery;
    },
    getRegional(divRegional) {
      divRegional = divRegional && divRegional.value
        ? JSON.parse(divRegional.value) : {};
      return divRegional ? divRegional.regional : false;
    },
    montarColunasDinamicas() {
      const objeto = [];
      Object.keys(this.metadadosCentroCusto.mapaEntidades).forEach((entidade) => {
        objeto.push(this.metadadosCentroCusto.mapaEntidades[entidade].label);
      });
      return objeto;
    },
    montarObjetoCentroCustos() {
      this.centroCustos = this.registros.map((data) => {
        let centroCusto = new CentroCusto();
        centroCusto.construirAPartirDaConsultaCompleta(data);
        centroCusto = metadados.montarEstruturaDinamica(data, this.metadadosCentroCusto,
          centroCusto);
        return centroCusto;
      });
    },
    onEditarCentroCusto(centroCusto) {
      if (!centroCusto.desAtributos) {
        centroCusto.desAtributos = { regional: false };
      }
      this.$emit('PESQUISA_CENTRO_CUSTO_EDITAR', centroCusto);
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    mudarOrdenacao(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.centroCustos.slice();
    },
    ativarDesativarRegistros() {
      this.requestAtivarInativar();
    },
    requestAtivarInativar() {
      if (this.selected && this.selected.length > 0) {
        this.centroCustoResources.ativarMassa(this.selected)
          .then(() => {
            this.selected = [];
            this.$toast(this.$t('message.alteracao_massa_sucesso'));
            this.filtrar();
          })
          .catch((err) => this.$error(this, err));
      }
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
