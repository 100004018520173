import getBasePath from '../../../produto/common/functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'parametrizacao-periodo');

const grupoActions = {
  obterPasso4: { method: 'GET', url: `${basePath}/passo4/{id}` },
  buscarExtensao: { method: 'GET', url: `${basePath}/passo4/extensao` },
  salvarRascunhoPasso4: { method: 'PUT', url: `${basePath}/passo4/{id}/rascunho` },
  salvarPasso4: { method: 'PUT', url: `${basePath}/passo4/{id}` },
};

export default (resource) => resource(`${basePath}`, {}, grupoActions);
