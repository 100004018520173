<template lang="html">
  <div>
    <v-app-bar dark app color="primary" elevate-on-scroll clipped-right>
      <v-toolbar-title class="white--text">
        <img src="../../assets/img/logo_novo.svg" alt="" class="Toolbar__Image--size">
        <span v-if="$vuetify.breakpoint.mdAndUp">Tradelinks {{ title === 'Tradelinks' ? '' : `- ${title}` }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main id="content">
      <v-container mt-5 style="width: 80%;">
        <v-card>
          <v-card-title>
            <h4 class="headline mb-0">{{ $t('label.termo_aceite') }} - {{ termo.descricao }}</h4>
          </v-card-title>
          <v-container grid-list-xl fluid style="padding-top:10px;">
            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <span v-html="termo.conteudo"></span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-end>
              <div>
                <v-radio-group v-model="termoAceito" row :disabled="timeout">
                  <v-radio
                    :label="`${$tc('label.li_concordo_termos_condicoes')}`"
                    :value="true"
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-layout>
            <v-layout wrap justify-end>
              <v-spacer></v-spacer>
              <v-btn @click.native="logout" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
              <v-btn :disabled="!termoAceito" @click.native="proximo" color="primary" class="mr-3">{{ continuar ? $t('label.continuar') : $t('label.finalizar') }}</v-btn>
            </v-layout>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>
<script>
export default {
  name: 'TermoAceiteLogin',
  components: {
  },
  props: {
    termos: Array,
    title: String,
  },
  data() {
    return {
      termoAceiteResources: this.$api.termoAceite(this.$resource),

      termo: {},
      continuar: true,
      index: 0,
      termoAceito: false,
      timeout: true,
      sombraToolbar: false,
    };
  },
  computed: {
  },
  methods: {
    logout() {
      const auth = this.$auth || window.auth;
      auth.logout();
    },
    proximo() {
      if (this.continuar) {
        this.index += 1;
        this.termo = this.termos[this.index];
        if (this.index >= (this.termos.length - 1)) {
          this.continuar = false;
        }
        this.termoAceito = false;
        this.timeoutAceito();
      } else {
        this.finalizar();
      }
    },
    timeoutAceito() {
      this.timeout = true;
      window.setTimeout(() => {
        this.timeout = false;
      }, 5000);
    },
    onScroll(i) {
      if (i.currentTarget.scrollY) {
        this.sombraToolbar = true;
      } else {
        this.sombraToolbar = false;
      }
    },
    finalizar() {
      this.termoAceiteResources.aceitarTermos(this.termos)
        .then(() => {
          this.$emit('ACEITO');
        }).catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    if (this.termos.length) {
      this.termo = this.termos[this.index];
      if (this.termos.length < 2) {
        this.continuar = false;
      }
      this.timeoutAceito();
    }
  },
};
</script>
