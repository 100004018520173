import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('orcamento', 'ajuste_verba');

const ajusteVerbaActions = {
  salvarAjusteVerba: { method: 'POST', url: `${basePath}` },
  atualizarAjusteVerba: { method: 'PUT', url: `${basePath}` },
  pegarAjusteVerbaPorId: { method: 'GET', url: `${basePath}/id` },
  listarTipoVerba: { method: 'GET', url: `${basePath}/tipo_verba` },
  listarTipoAjuste: { method: 'GET', url: `${basePath}/tipo_ajuste` },
  listarOrcamento: { method: 'GET', url: `${basePath}/orcamento` },
  listarContaDivisao: { method: 'GET', url: `${basePath}/conta_divisao` },
  listarLinhaInvestimento: { method: 'GET', url: `${basePath}/linha_investimento` },
  listarPeriodoOrcamentario: { method: 'GET', url: `${basePath}/periodo_orcamentario` },
  getPlanejadoSaldo: { method: 'GET', url: `${basePath}/planejado_saldo` },
  executarAnalise: { method: 'PUT', url: `${basePath}/{idAjuste}/executarAnalise` },
  concluir: { method: 'PUT', url: `${basePath}/{idAjuste}/concluir` },
  verificarSeUsuarioPossuiAcesso: { methods: 'GET', url: `${basePath}/acesso/{idAjuste}` },
};

export default (resource) => resource(`${basePath}`, {}, ajusteVerbaActions);

export const buscarAjusteVerbaLista = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, '', parametros).doGet();
