<style>
.PlanejamentoAcaoCardsAcoes__Card .headline {
  width: 100%;
  text-align: center;
  text-transform: capitalize;
}

.PlanejamentoAcaoCardsAcoes__Rodape {
  position: fixed;
  z-index: 4;
  bottom: 0;
  width: 100%;
}

</style>
<template>
  <div class="PlanejamentoAcaoCardsAcoes__Rodape">
    <v-container fluid grid-list-md text-xs-center>
      <v-row align="stretch" justify="space-around" fill-height>
        <v-col cols="12" md="3" v-for="(item, index) in categorias" :key="index">
          <v-card color="primary" class="PlanejamentoAcaoCardsAcoes__Card" @click="criarAcao(item)">
            <v-card-title>
                  <span class="white--text headline">
                    {{ `${$t('label.solicitar')} ${item.nome}` }}
                  </span>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      filtroTipo: null,
      page: 1,
      categorias: [],
    };
  },
  watch: {
    page() {
      this.buscarTiposAcao(this.filtroTipo);
    },
  },
  methods: {
    buscarTiposAcao(nome = null) {
      this.planejamentoAcaoResource.buscarTiposAcao({ nome, size: 1000, page: this.page })
        .then((res) => {
          this.categorias = this.agruparPorCategoria(res.data.resposta);
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    agruparPorCategoria(lista) {
      const listaFiltrada = lista.map((item) => item.categoria_acao);
      const categorias = new Set(listaFiltrada);
      const categoriasFiltradas = [...categorias].filter((cat) => cat && cat.length);

      const resultado = [];
      categoriasFiltradas.forEach((cat) => {
        resultado.push({
          categoria: cat,
          tipos: lista.filter((tipo) => tipo.categoria_acao === cat),
        });
      });

      lista.filter((tipo) => !tipo.categoria_acao || !tipo.categoria_acao.length)
        .forEach((tipo) => resultado.push(tipo));

      return resultado;
    },
    criarAcao(tipoAcao) {
      const idTipoAcao = tipoAcao.id;
      this.$router.push({ name: 'novaAcao', params: { idTipoAcao } });
    },
  },
  mounted() {
    this.buscarTiposAcao();
  },
};
</script>
