export default class Passo1 {
  constructor(passo1) {
    if (passo1) {
      this._id = passo1.id;
      this._idAnoFiscal = passo1.idAnoFiscal;
      this._idsContratoTipo = passo1.idsContratoTipo;
      this._idsElegibilidade = passo1.idsElegibilidade;
      this._idsDpmPeriodoAnterior = passo1.idsDpmPeriodoAnterior;
    }
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get idAnoFiscal() {
    return this._idAnoFiscal;
  }

  set idAnoFiscal(value) {
    this._idAnoFiscal = value;
  }

  get idsContratoTipo() {
    return this._idsContratoTipo;
  }

  set idsContratoTipo(value) {
    this._idsContratoTipo = value;
  }

  get idsElegibilidade() {
    return this._idsElegibilidade;
  }

  set idsElegibilidade(value) {
    this._idsElegibilidade = value;
  }

  get idsDpmPeriodoAnterior() {
    return this._idsDpmPeriodoAnterior;
  }

  set idsDpmPeriodoAnterior(value) {
    this._idsDpmPeriodoAnterior = value;
  }

  toJSON() {
    return {
      id: this._id,
      idAnoFiscal: this._idAnoFiscal,
      idsContratoTipo: this._idsContratoTipo,
      idsElegibilidade: this._idsElegibilidade,
      idsDpmPeriodoAnterior: this._idsDpmPeriodoAnterior,
    };
  }
}
