<template>
  <v-speed-dial
    v-model="fab"
    bottom
    right
    fixed
    direction="left"
    v-show="canEdit"
  >
    <template v-slot:activator>
      <v-btn
        v-model="fab"
        color="primary"
        fab
      >
        <v-icon v-if="!fab" id="add">add</v-icon>
        <v-icon v-if="fab" id="close">close</v-icon>
      </v-btn>
    </template>
    <v-btn
      fab
      dark
      color="accent"
      @click="novoAjuste(tipoAjuste)"
      v-for="(tipoAjuste, nome) in tiposAjustes"
      :key="nome"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
          >{{tipoAjuste.icone}}
          </v-icon>
        </template>
        <span>{{tipoAjuste.nome}}</span>
      </v-tooltip>
    </v-btn>
  </v-speed-dial>
</template>

<script>
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  name: 'AjusteVerbaListBotoes',
  data() {
    return {
      fab: null,
      tiposAjustes: [
        {
          icone: 'notes',
          nome: this.$tc('label.reducao_massiva', 1),
          rota: 'novaReducaoMassiva',
        },
        {
          icone: 'swap_vert',
          nome: this.$tc('label.transferencia', 1),
          rota: 'novaTransferencia',
        },
        {
          icone: 'attach_money',
          nome: this.$tc('label.adendo_reducao', 1),
          rota: 'novoAdendoReducao',
        },
      ],
    };
  },
  computed: {
    ...generateComputed('AJUSTE_VERBA', [
      'canEdit',
    ]),
  },
  methods: {
    novoAjuste(tipoAjuste) {
      this.$router.push({ name: tipoAjuste.rota, params: { somenteLeitura: false, novo: true } });
    },
  },
};
</script>
