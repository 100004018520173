<template>
  <div>
    <v-data-table
      id="tabela_acoes_previas"
      class="elevation-1"
      :headers="headers"
      :items="acoesPrevias"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item.acoes="{ item }">
        <v-menu bottom
                v-if="acaoCancelada(item)"
                origin="center center"
                transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :id="'more_vert_' + item.id"
              icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="detalharAcao(item.id)">
              <v-list-item-action>
                <v-icon>details</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu bottom
                v-else
                origin="center center"
                transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :id="'more_vert_' + item.id"
              icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="!canEdit || !acaoEmCadastro(item)"
              @click="detalharAcao(item.id)">
              <v-list-item-action>
                <v-icon>details</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="acaoEmCadastro(item) && canEdit"
              @click="editarAcao(item.id)">
              <v-list-item-action>
                <v-icon>edit</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.editar', 1) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-show="item.aprovadorFluxo"
              @click="abrirJustificativaAprovar(item)">
              <v-list-item-action>
                <v-icon>thumb_up</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.aprovar', 1) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-show="item.aprovadorFluxo"
              @click="abrirJustificativaAnalise(item)">
              <v-list-item-action>
                <v-icon>chat_bubble</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.enviar_analise', 1) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-show="item.aprovadorFluxo"
              @click="abrirJustificativaReprovar(item)">
              <v-list-item-action>
                <v-icon>thumb_down</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.reprovar', 1) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-show="acaoReprovado(item) || acaoEmAnalise(item)"
              @click="abrirJustificativa(item)">
              <v-list-item-action>
                <v-icon>textsms</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.justificativa', 1) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-show="acaoEmAnalise(item) && canEdit && item.solicitanteFluxo"
              @click="confirmarExecutarAnalise(item)">
              <v-list-item-action>
                <v-icon>edit</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.executar_analise', 1) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="mostrarAcompanhamento(item)"
              @click="abrirAcompanhamento(item)">
              <v-list-item-action>
                <v-icon>assignment_turned_in</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.acompanhamento', 1) }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="mostrarBotaoCancelarAcao(item)"
              @click="confirmarCancelarAcao(item)">
              <v-list-item-action>
                <v-icon>cancel</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $tc('label.cancelar', 1) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.status="{ item }">
        <status
          :status="item.status"
          :tooltip="item.status">
        </status>
      </template>
      <template v-slot:item.vlr_total="{ item }">
        {{ getMoney(item.vlr_total) }}
      </template>
    </v-data-table>

    <planejamento-acao-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :observacao="justificativa"
      :somenteLeitura="justificativaSomenteLeitura"
      :id-acao="acaoSelecionada.id"
    >
    </planejamento-acao-justificativa>

    <confirm
      ref="confirmDialog"
      :persistent="true">
    </confirm>

    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
      :entidade="'ACAO'">
    </acompanhamento>
  </div>
</template>

<script>
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import { buscarAcoesPrevias } from '@/common/resources/acao-previa';
import { getMoney } from '../../produto/common/functions/helpers';
import { generateComputed } from '../../produto/common/functions/roles-computed-generator';
import { skipLoading } from '../../produto/common/functions/loading';
import Status from '../../produto/shared-components/Status';
import Confirm from '../../produto/shared-components/vuetify/dialog/Confirm';
import Acompanhamento from '../../produto/shared-components/workflow/Acompanhamento';

export default {
  name: 'AcaoPreviaListDados',
  components: {
    PlanejamentoAcaoJustificativa,
    Status,
    Confirm,
    Acompanhamento,
  },
  props: {
    filtros: Object,
  },
  data() {
    return {
      acaoPreviaResources: this.$api.acaoPrevia(this.$resource),
      workflowAcaoPreviaResource: this.$api.workflowAcaoPrevia(this.$resource),
      bloquearBotaoExportacao: false,
      acoesPrevias: [],
      justificativaObrigatoria: false,
      actionJustificativa() {},
      acaoSelecionada: {},
      justificativa: '',
      justificativaSomenteLeitura: false,
      esperar: false,
      statusPassos: {},
      pagination: {},
      totalPage: 0,
      headers: [
        {
          text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
        {
          text: this.$tc('label.status', 1), value: 'status', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.codigo', 1), value: 'cod_acao_previa', sortable: true, width: '10%',
        },
        {
          text: this.$tc('label.nome_fantasia', 1), value: 'des_nome_fantasia', sortable: true, width: '20%',
        },
        {
          text: this.$tc('label.divisao', 1), value: 'des_divisao', sortable: true, width: '10%',
        },
        {
          text: this.$tc('label.periodo_orcamentario', 1), value: 'des_periodo_orcamentario', sortable: true, width: '15%',
        },
        {
          text: this.$tc('label.valor', 1), value: 'vlr_total', sortable: true, width: '10%',
        },
      ],
      selected: [],
    };
  },
  computed: {
    ...generateComputed('ACAO_PREVIA', [
      'canEdit',
    ]),
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  methods: {
    getMoney,
    filtrar(filtros = null) {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar(filtros);
      }, 5E2);
    },
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },

    aplicaFiltros(filtros) {
      const filtrosAplicados = {
        ...filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.filtrar(filtrosAplicados);
    },

    buscar(filtros = null) {
      const params = filtros || {
        ...this.filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };
      this.carregarDados(params);
    },

    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
        this.$forceUpdate();
      }, 2E2);
    },

    carregarDados(params) {
      buscarAcoesPrevias(params, this.$resource)
        .then((response) => {
          this.totalPage = response.data.quantidadeRegistrosPagina;
          this.acoesPrevias = response.data.resposta;
          this.acoesPrevias.forEach((a) => {
            this.verificarUsuarioAprovador(a);
            this.verificarUsuarioSolicitante(a);
            this.buscarStatusFluxo(a);
          });
          this.pararEsperar();
        }, (err) => {
          this.pararEsperar();
          this.$error(this, err);
        });
    },

    acaoAprovada(acao) {
      const { status } = acao;
      return status === 'APROVADO';
    },
    acaoReprovado(acao) {
      const { status } = acao;
      return status === 'REPROVADO';
    },
    acaoEmCadastro(acao) {
      const { status } = acao;
      return status === 'EM_CADASTRO';
    },
    acaoCancelada(acao) {
      const { status } = acao;
      return status === 'CANCELADO';
    },
    acaoAguardandoAprovacao(acao) {
      const { status } = acao;
      return status === 'AGUARDANDO_APROVACAO';
    },
    acaoEmAnalise(acao) {
      const { status } = acao;
      return status === 'EM_ANALISE';
    },
    editarAcao(idAcao) {
      this.$router.push({ name: 'editarAcaoPrevia', params: { idAcao, from: 'acao-previa' } });
    },
    detalharAcao(idAcao) {
      this.$router.push({ name: 'detalharAcaoPrevia', params: { idAcao, from: 'acao-previa' } });
    },
    abrirJustificativaAprovar(item) {
      const justObrigatoria = false;
      this.abrirJustificativaAvaliar(item, this.aprovar, justObrigatoria);
    },
    abrirJustificativaReprovar(item) {
      const justObrigatoria = true;
      this.abrirJustificativaAvaliar(item, this.reprovar, justObrigatoria);
    },
    abrirJustificativaAnalise(item) {
      const justObrigatoria = true;
      this.abrirJustificativaAvaliar(item, this.enviarAnalise, justObrigatoria);
    },
    abrirJustificativaAvaliar(item, acaoJustificativa, justObrigatoria) {
      this.justificativaObrigatoria = justObrigatoria;
      this.actionJustificativa = acaoJustificativa;
      this.justificativa = '';
      this.justificativaSomenteLeitura = false;
      this.acaoSelecionada = item;
      this.$refs.modalJustificativa.open();
    },
    aprovar(justificativa) {
      const idAcaoPrevia = this.acaoSelecionada.id;
      this.workflowAcaoPreviaResource.aprovarPasso({ idAcaoPrevia },
        { observacao: justificativa })
        .then(() => {
          this.buscarStatusFluxo(this.acaoSelecionada, true, () => {
            if (this.acaoSelecionada.fluxo.statusFluxo === 'APROVADO') {
              this.acaoSelecionada.status = 'APROVADO';
              this.$forceUpdate();
            }
          });
          this.acaoSelecionada.aprovadorFluxo = false;
          this.$forceUpdate();
          this.$toast(this.$t('message.acao_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    reprovar(justificativa) {
      const idAcaoPrevia = this.acaoSelecionada.id;
      this.workflowAcaoPreviaResource.reprovar({ idAcaoPrevia },
        { observacao: justificativa })
        .then(() => {
          this.acaoSelecionada.status = 'REPROVADO';
          this.buscarStatusFluxo(this.acaoSelecionada);
          this.acaoSelecionada.aprovadorFluxo = false;
          this.$toast(this.$t('message.acao_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    confirmarCancelarAcao(acao) {
      const content = this.$t('message.deseja_cancelar_acao');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              this.cancelarAcao(acao);
            }
          },
        ));
    },
    cancelarAcao(acao) {
      const idAcaoPrevia = acao.id;
      this.workflowAcaoPreviaResource.cancelar({ idAcaoPrevia }, { observacao: 'CANCELADO' })
        .then(() => {
          this.buscar();
          this.$toast(this.$t('message.fluxo_cancelado_sucesso'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    confirmarExecutarAnalise(acao) {
      this.acaoSelecionada = acao;
      const content = this.$t('message.deseja_executar_analise_acao');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              this.executarAnalise();
            }
          },
        ));
    },
    enviarAnalise(justificativa) {
      const idAcaoPrevia = this.acaoSelecionada.id;
      this.workflowAcaoPreviaResource.enviarAnalise({ idAcaoPrevia },
        { observacao: justificativa })
        .then(() => {
          this.acaoSelecionada.status = 'EM_ANALISE';
          this.buscarStatusFluxo(this.acaoSelecionada);
          this.acaoSelecionada.aprovadorFluxo = false;
          this.$toast(this.$t('message.acao_enviada_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificarUsuarioAprovador(acao) {
      if (acao.status === 'AGUARDANDO_APROVACAO') {
        const idAcaoPrevia = acao.id;
        skipLoading();
        this.workflowAcaoPreviaResource.aprovador({ idAcaoPrevia })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              acao.aprovadorFluxo = true;
              this.$forceUpdate();
            }
          });
      }
    },
    verificarUsuarioSolicitante(acao) {
      if (acao.status === 'EM_CADASTRO' || acao.status === 'EM_ANALISE' || acao.status === 'AGUARDANDO_APROVACAO') {
        const idAcaoPrevia = acao.id;
        skipLoading();
        this.workflowAcaoPreviaResource.solicitante({ idAcaoPrevia })
          .then((response) => {
            acao.solicitanteFluxo = response.data.usuarioHabilitado;
            this.$forceUpdate();
          });
      }
    },
    buscarStatusFluxo(acao, recarregando, cb) {
      if (acao.status === 'REPROVADO' || acao.status === 'EM_ANALISE' || recarregando) {
        const idAcaoPrevia = acao.id;
        skipLoading();
        this.workflowAcaoPreviaResource.status({ idAcaoPrevia })
          .then((response) => {
            acao.fluxo = response.data;
            if (cb) cb();
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    abrirJustificativa(acao) {
      this.justificativa = acao.fluxo.passoAtual.observacao;
      this.justificativaSomenteLeitura = true;
      this.$refs.modalJustificativa.open();
    },
    executarAnalise() {
      const idAcao = this.acaoSelecionada.id;
      this.acaoPreviaResources.executarAnalise({ idAcao }, { idAcao })
        .then(() => {
          this.$router.push({ name: 'editarAcaoPrevia', params: { idAcao, from: 'acao-previa' } });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    mostrarAcompanhamento(item) {
      return item.status !== 'EM_CADASTRO' && item.status !== 'CANCELADO';
    },
    mostrarBotaoCancelarAcao(item) {
      return this.canEdit && item.solicitanteFluxo && this.acaoAguardandoAprovacao(item);
    },
    abrirAcompanhamento(item) {
      const idAcaoPrevia = item.id;
      this.workflowAcaoPreviaResource.statusPasso({ idAcaoPrevia }, { idAcaoPrevia })
        .then((res) => {
          if (res.data.passos
            && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
