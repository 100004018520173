<template>
  <v-expansion-panel class="SimulacaoDpmGrupoCulturasPainel">
    <v-expansion-panel-header class="pb-0 pt-0">
      <span class="header">
        {{ grupo.grupoCultura }}
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
        :headers="cabecalho"
        :items="grupo.marcas"
        dense
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-footer>
        <template v-slot:item.pontoUnitario="{ item }">
          {{getNumber(item.pontoUnitario)}}
        </template>
        <template v-slot:item.volumeMeta="{ item }">
          {{getNumber(item.volumeMeta)}}
        </template>
        <template v-slot:item.volumeFaturado="{ item }">
          {{getNumber(item.volumeFaturado)}}
        </template>
        <template v-slot:item.volumeFatCart="{ item }">
          {{getNumber(item.volumeFatCart)}}
        </template>
        <template v-slot:item.volumeSimulado="{ item }">
          <input-decimal
            v-model="item.volumeSimulado"
            single-line
            hide-details
            dense
            align-right
            @input="volumeSimuladoAlterado(item)"
            class="py-0 mt-0 mb-1"
            />
        </template>
        <template v-slot:item.pontosMeta="{ item }">
          {{getNumber(item.pontosMeta)}}
        </template>
        <template v-slot:item.pontosSimulacao="{ item }">
          {{getNumber(item.pontosSimulacao)}}
        </template>
        <template v-slot:item.pontosFaturamento="{ item }">
          {{getNumber(item.pontosFaturamento)}}
        </template>
        <template v-slot:item.pontosFatCart="{ item }">
          {{getNumber(item.pontosFatCart)}}
        </template>
        <template v-slot:body.append>
          <tr>
            <td class="text-right" colspan="9" style="font-weight:bold">
              {{getNumber(totalPontosMeta)}}
            </td>
            <td class="text-right" style="font-weight:bold">
              {{getNumber(totalPontosFaturamento)}}
            </td>
            <td class="text-right" style="font-weight:bold">
              {{getNumber(totalPontosFatCart)}}
            </td>
            <td class="text-right" style="font-weight:bold">
              {{getNumber(totalPontosSimulacao)}}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { getNumber } from '../../../../produto/common/functions/helpers';
import InputDecimal from '../../../../produto/shared-components/inputs/InputDecimal';

export default {
  props: {
    grupo: {
      type: Object,
      required: true,
    },
    painelAtivo: Boolean,
  },
  components: {
    InputDecimal,
  },
  data() {
    return {
      cabecalho: [
        {
          text: this.$tc('label.segmento', 1),
          value: 'segmentos',
          align: 'start',
        },
        {
          text: this.$t('label.mix'),
          value: 'mix',
          align: 'start',
        },
        {
          text: this.$t('label.marca'),
          value: 'marca',
          align: 'start',
        },
        {
          text: this.$t('label.ponto_unitario'),
          value: 'pontoUnitario',
          align: 'end',
        },
        {
          text: this.$t('label.volume_meta'),
          value: 'volumeMeta',
          align: 'end',
        },
        {
          text: this.$t('label.volume_faturado'),
          value: 'volumeFaturado',
          align: 'end',
        },
        {
          text: this.$t('label.volume_projetado'),
          value: 'volumeFatCart',
          align: 'end',
        },
        {
          text: this.$t('label.volume_simulado'),
          value: 'volumeSimulado',
          align: 'end',
        },
        {
          text: this.$t('label.pontos_meta'),
          value: 'pontosMeta',
          align: 'end',
        },
        {
          text: this.$t('label.pontos_faturamento'),
          value: 'pontosFaturamento',
          align: 'end',
        },
        {
          text: this.$t('label.pontos_projetado'),
          value: 'pontosFatCart',
          align: 'end',
        },
        {
          text: this.$t('label.pontos_simulacao'),
          value: 'pontosSimulacao',
          align: 'end',
        },
      ],
      totalVolumeMeta: 0,
      totalVolumeFaturado: 0,
      totalVolumeFatCart: 0,
      totalPontosMeta: 0,
      totalPontosFaturamento: 0,
      totalPontosFatCart: 0,
      totalVolumeSimulado: 0,
      totalPontosSimulacao: 0,
    };
  },
  methods: {
    getNumber,
    volumeSimuladoAlterado(item) {
      const { volumeSimulado, pontoUnitario } = item;
      item.pontosSimulacao = volumeSimulado * pontoUnitario;

      this.calculaVariaveis();
      setTimeout(() => this.$forceUpdate());
    },
    calculaTotais() {
      this.totalVolumeMeta = this.grupo.marcas
        .map((m) => m.volumeMeta)
        .reduce((p, a) => p + a, 0);
      this.totalVolumeFaturado = this.grupo.marcas
        .map((m) => m.volumeFaturado)
        .reduce((p, a) => p + a, 0);
      this.totalVolumeFatCart = this.grupo.marcas
        .map((m) => m.volumeFatCart)
        .reduce((p, a) => p + a, 0);
      this.totalPontosMeta = this.grupo.marcas
        .map((m) => m.pontosMeta)
        .reduce((p, a) => p + a, 0);
      this.totalPontosFaturamento = this.grupo.marcas
        .map((m) => m.pontosFaturamento)
        .reduce((p, a) => p + a, 0);
      this.totalPontosFatCart = this.grupo.marcas
        .map((m) => m.pontosFatCart)
        .reduce((p, a) => p + a, 0);
      this.totalVolumeSimulado = this.grupo.marcas
        .map((m) => m.volumeSimulado || 0)
        .reduce((p, a) => p + a, 0);
      this.totalPontosSimulacao = this.grupo.marcas
        .map((m) => m.pontosSimulacao || 0)
        .reduce((p, a) => p + a, 0);
    },
    calculaVariaveis() {
      this.calculaTotais();
      this.$emit('recalculo-variaveis');
    },
  },
  mounted() {
    this.calculaVariaveis();
  },
};
</script>
<style scoped>
.v-expansion-panel span.header {
  min-width: 100px;
}
.preview {
  font-size:.8rem;
}
</style>
<style>
.SimulacaoDpmGrupoCulturasPainel .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
.SimulacaoDpmGrupoCulturasPainel.v-expansion-panel .v-expansion-panel-header--active:before {
  background-color: var(--v-primary-lighten1);
}
.SimulacaoDpmGrupoCulturasPainel.v-expansion-panel .v-expansion-panel-header--active span.header,
.SimulacaoDpmGrupoCulturasPainel.v-expansion-panel .v-expansion-panel-header--active i.v-icon {
  color: var(--v-primary-darken1) !important;
}
</style>
