<template>
  <v-data-table
    v-model="apuracoesSelecionadas"
    show-select
    :headers="headers"
    item-key="idApuracao"
    :items="apuracoes"
    :footer-props="{
      itemsPerPageOptions: [10, 25, 50],
    }"
    class="elevation-0"
    :no-data-text="$t('label.tabela_sem_conteudo')">

    <template v-slot:item.idAcao="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ item.idAcao }}</span>
        </template>
        <span>{{ item.descricaoAcao }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item.baseCalculo="{ item }">
      {{ item.baseCalculo ? $t(`configuracao.${item.baseCalculo}`) : '' }}
    </template>

    <template v-slot:item.verba="{ item }">
      {{ exibirVerba(item) }}
    </template>

    <template v-slot:item.vlrConfApuracao="{ item }">
      <input-money
        :id="`contrato_apuracao_apurado_${item.idApuracao}`"
        v-model="item.vlrConfApuracao"
        :disabled="!podeEditarValorPagamento(item)"
        class="v-text-field">
      </input-money>
    </template>

    <template v-slot:item.aportado="{ item }">
      {{ getMoney(item.aportado) }}
    </template>

    <template v-slot:item.dataInicio="{ item }">
      {{ `${converterMesAno(item.dataInicio)} ${$tc('label.a', 2)} ${converterMesAno(item.dataFim)}`}}
    </template>

    <template v-slot:item.vlrRealizado="{ item }">
      <input-money
        :id="`contrato_apuracao_realizado_${item.idApuracao}`"
        v-model="item.vlrRealizado"
        :disabled="!podeEditarValorRealizado(item)"
        class="v-text-field">
      </input-money>
    </template>

    <template v-slot:item.pago="{ item }">
      {{ getMoney(item.pago) }}
    </template>

    <template v-slot:item.notas="{ item }">
      <v-tooltip v-if="item.temNota" top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" @click.stop="exportar(item)">notes</v-icon>
        </template>
        <span>{{ $t('message.visualizar_notas_clique_aqui') }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item.vlrAAportar="{ item }">
      <input-money
        :id="`contrato_apuracao_aportar_${item.idApuracao}`"
        v-model="item.vlrAAportar"
        :disabled="!podeEditarValorPagamento(item)"
        :rules="[value =>  value <= item.maxAAportar || $t('message.valor_aportar_nao_permitido'),]"
        class="v-text-field">
      </input-money>
    </template>

    <template v-slot:item.vlrAPagar="{ item }">
      <input-money :id="`contrato_apuracao_aportar_${item.idApuracao}`"
        v-model="item.vlrAPagar"
        :disabled="!podeEditarValorPagamento(item)"
        :rules="[value =>  value <= item.maxAPagar || $t('message.valor_pagar_maior_que_apurado'),]"
        class="v-text-field">
      </input-money>
    </template>

    <template v-slot:item.possuiMeta="{ item }">
      <v-menu
        v-if="item.possuiMetaIntervalo"
        :id="`contrato_apuracao_meta_intervalo_${item.idApuracao}`"
        :close-on-content-click="false"
        :nudge-width="400"
        bottom
        open-on-hover
        offset-y>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">attach_money</v-icon>
        </template>

        <v-card>
          <v-data-table
            :headers="headersMetaIntervalo"
            :items="getMetasIntervalo(item)"
            hide-default-footer
            :no-data-text="$t('label.tabela_sem_conteudo')">
            <template v-slot:item.vlrMetaDe="{ item }">
              <span v-text="exibirMeta(item.vlrMetaDe, item.calculoApuracao)"></span>
            </template>
            <template v-slot:item.vlrMetaAte="{ item }">
              <span
                  v-if="item.vlrMetaAte"
                  v-text="exibirMeta(item.vlrMetaAte, item.calculoApuracao)">
              </span>
            </template>
            <template v-slot:item.vlrBonificacao="{ item }">
              <span v-text="exibirPagamento(item.vlrBonificacao, item.formaBonificacao)"></span>
            </template>
          </v-data-table>
        </v-card>
      </v-menu>
      <span v-else-if="item.possuiMeta">{{ exibirMeta(item.meta, item.calculoApuracao) }}</span>
    </template>

  </v-data-table>
</template>

<script>
import { buscarApuracaoPorContrato } from '../../../common/resources/apuracao/apuracao-contrato';
import {
  convertToMoney, convertToPercent, getPercent, convertToNumberFormat, getMoney,
} from '../../../common/functions/helpers';
import exportacao from '../../../common/functions/exportacao';
import InputMoney from '../../../shared-components/inputs/InputMoney';
import PopoverLista from '../../../shared-components/PopoverLista';

export default {
  name: 'ApuracaoContratoFinalizarLoteIndustriaTabela',
  components: { InputMoney, PopoverLista },
  props: {
    idContrato: {
      type: Number,
    },
    filtros: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      configuracaoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),
      configuracao: {},
      esperar: false,
      apuracoes: [],
      headers: [],
      apuracoesSelecionadas: [],
      campoPopover: [
        {
          label: this.$tc('label.ipi', 1),
          campo: 'ipi',
          formatarCampos: getMoney,
        },
        {
          label: this.$tc('label.icms', 1),
          campo: 'icms',
          formatarCampos: getMoney,
        },
        {
          label: this.$tc('label.pis_cofins', 1),
          campo: 'pisCofins',
          formatarCampos: getMoney,
        },
        {
          label: this.$tc('label.devolucao', 1),
          campo: 'devolucao',
          formatarCampos: getMoney,
        },
      ],
      headersMetaIntervalo: [
        {
          text: this.$t('label.meta_de'), value: 'vlrMetaDe', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.meta_ate'), value: 'vlrMetaAte', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.verba'), value: 'vlrBonificacao', sortable: false, align: 'center',
        },
      ],
    };
  },
  watch: {
    apuracoesSelecionadas(val) {
      this.$emit('ApuracaoContratoFinalizarLoteIndustriaTabela__apuracoesSelecionadas',
        this.idContrato, val, this.finalizaPagamento, this.fechamentoParcial);
    },
  },
  computed: {
    fechamentoParcial() {
      const { passo3 } = this.configuracao;
      return passo3 && passo3.fechamentoParcial;
    },
    finalizaPagamento() {
      const { passo1 } = this.configuracao;
      return passo1 && passo1.fluxo
        && passo1.fluxo.endsWith('LIQUIDACAO');
    },
    verbaPercentual() {
      if (this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'PERCENTUAL') {
        return true;
      }
      return false;
    },
  },
  methods: {
    getPercent,
    getMoney,
    convertToMoney,
    convertToNumberFormat,
    convertToPercent,
    atualizaHeaders() {
      this.headers = [
        { text: this.$tc('label.cod_acao', 1), value: 'idAcao', sortable: false },
        { text: this.$tc('label.cod_apuracao', 1), value: 'idApuracao', sortable: false },
        { text: this.$tc('label.periodo_apuracao', 1), value: 'dataInicio', sortable: false },
        { text: this.$tc('label.tipo_acao', 1), value: 'tipo', sortable: false },
        { text: this.$tc('label.base_calculo', 1), value: 'baseCalculo', sortable: false },
        { text: this.$tc('label.verba', 1), value: 'verba', sortable: false },
        { text: this.$tc('label.realizado', 1), value: 'vlrRealizado', sortable: false },
        { text: this.$tc('label.valor_apurado', 1), value: 'vlrConfApuracao', sortable: false },
      ];

      if (this.finalizaPagamento) {
        if (this.fechamentoParcial) {
          this.headers.push({ text: this.$tc('label.valor_pago', 1), value: 'pago', sortable: false });
        }
        this.headers.push({ text: this.$tc('label.valor_pagar', 1), value: 'vlrAPagar', sortable: false });
      } else {
        if (this.fechamentoParcial) {
          this.headers.push({ text: this.$tc('label.valor_aportado', 1), value: 'aportado', sortable: false });
        }
        this.headers.push({ text: this.$tc('label.valor_aportar', 1), value: 'vlrAAportar', sortable: false });
      }

      this.headers.push({ text: this.$tc('label.meta', 1), value: 'possuiMeta', sortable: false });
      this.headers.push({ text: this.$tc('label.nota', 2), value: 'notas', sortable: false });
    },
    exportar(item) {
      const params = {
        id_apuracao: item.idApuracao,
      };
      exportacao.exportar(null, 'notas_sellin_memoria_apuracao', this, params);
    },
    exibirVerba(item) {
      if (item.isMetaNA || item.isMetaMinima) {
        return this.exibirPagamento(item.valorBonificacao, item.formaBonificacao);
      } if (item.possuiMetaIntervalo) {
        let vlrBonificacao = 0;
        const meta = item.metasIntervalo
          .filter((m) => item.vlrConfApuracao >= m.vlrMetaDe
                && item.vlrConfApuracao <= item.vlrMetaAte);
        if (meta && meta.length > 0) {
          vlrBonificacao = meta.vlrBonificacao;
        }
        return this.exibirPagamento(vlrBonificacao, item.formaBonificacao);
      }
      return null;
    },
    exibirMeta(valor, calculoApuracao) {
      if (calculoApuracao === 'SELLIN_VOLUME' || calculoApuracao === 'SELLOUT_VOLUME') {
        return this.convertToNumberFormat(valor);
      }
      return this.convertToMoney(valor);
    },
    exibirPagamento(valor, formaBonificacao) {
      if (formaBonificacao === 'PERCENTUAL') {
        return this.convertToPercent(valor);
      }
      return this.convertToMoney(valor);
    },
    getContrato() {
      return {
        id: this.idContrato,
        fechamentoParcial: this.fechamentoParcial,
        finalizaPagamento: this.finalizaPagamento,
        apuracoes: this.apuracoes,
      };
    },
    formatarVerba(item) {
      if (item.formaBonificacao === 'FIXO') {
        return this.getMoney(item.verba);
      }
      return this.getPercent(item.verba);
    },
    filtrar() {
      if (this.esperar) return;

      this.esperar = true;
      setTimeout(() => {
        this.buscarApuracoes();
      }, 5E2);
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    exportarNotas(item) {
      const params = {
        id_apuracao: item.idApuracao,
        id_fornecedor: item.idFornecedor,
      };

      if (item.calculoBonificacao === 'SELLIN') {
        exportacao.exportar(null, 'notas_sellin_memoria', this, params);
      } else if (nota.calculoBonificacao === 'SELLOUT') {
        exportacao.exportar(null, 'notas_sellout_memoria', this, params);
      }
    },
    getMetasIntervalo(item) {
      return item.metasIntervalo;
    },
    buscarApuracoes() {
      const params = { ...this.filtros };
      params.cod_contrato = this.idContrato;

      return buscarApuracaoPorContrato(params, this.$resource)
        .then((response) => {
          this.apuracoes = response.data;
          this.pararEsperar();
        })
        .catch((err) => {
          this.$error(this, err);
          this.pararEsperar();
        });
    },
    carregarConfiguracaoContrato() {
      if (!this.idContrato) return;
      this.configuracaoResource.buscarConfigSnapshot({ idContrato: this.idContrato })
        .then((res) => {
          this.configuracao = res.data;
          this.filtrar();
          this.atualizaHeaders();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    converterMesAno(val) {
      this.moment.locale('pt-BR');
      return this.moment(val, 'DD/MM/YYYY HH:mm:ss').format('MMM/YY');
    },
    podeEditarValorRealizado(item) {
      return item.indHabilitaEdicaoValorRealizado
          && (item.status === 'AGUARDANDO_APURACAO' || item.status === 'EM_ANALISE');
    },
    podeEditarValorPagamento(item) {
      return item.indHabilitaEdicaoValorPagamento
          && (item.status === 'AGUARDANDO_APURACAO' || item.status === 'EM_ANALISE');
    },
  },
  mounted() {
    this.carregarConfiguracaoContrato();
  },
};
</script>
<style>
  .select--sem--linha > .v-input__control > .v-input__slot:before {
    width: 0% !important;
  }
  .select--sem--linha > .v-input__control > .v-input__slot:after {
    border-style: none;
  }
</style>
