<template>
  <v-dialog v-model="dialogRating" transition="dialog-bottom-transition" width="700" persistent>
    <v-card>
      <v-form ref="formRating" lazy-validation v-model="valid">
        <v-container fluid>
          <v-row>
            <span class="headline">{{ $tc('label.rating', 1) }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogRating = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-row>
          <v-row v-if="!somenteLeitura || permiteAlteracao">
            <v-col cols="12" sm="6" md="4">
              <v-select
                id="indicador_rating"
                name="indicador_rating"
                item-text="descricao"
                item-value="id"
                return-object
                clearable
                :items="ratingsSelect"
                v-model="rating"
                :rules="[rules.required]"
                :label="`${$t('label.rating')} *`"
                :disabled="somenteLeitura && !permiteAlteracao">
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <input-decimal
                :label="`${$t('label.pontuacao', 1)} *`"
                :negative="false"
                v-model="pontuacao"
                :disabled="somenteLeitura && !permiteAlteracao"
                class="v-text-field"
                :rules="[rules.required]"
                :required="true"/>
            </v-col>
          </v-row>
          <v-row justify="end" v-if="!somenteLeitura || permiteAlteracao">
            <v-btn text @click.native="limpar">{{ $t('label.limpar') }}</v-btn>
            <v-btn color="primary darken-1" text @click.native="salvar">{{ $t('label.salvar') }}</v-btn>
          </v-row>
          <v-row>
            <v-data-table
              :headers="headers"
              :items="ratings"
              hide-default-footer
              :no-data-text="$t('message.nenhum_intervalo')"
              style="width: 100%;">
              <template v-slot:item.action="{ item }">
                <v-tooltip bottom v-if="!somenteLeitura || permiteAlteracao">
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mx-0" v-on="on" @click="editar(item)">
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('label.editar') }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="!somenteLeitura || permiteAlteracao">
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mx-0" v-on="on" @click="remover(item)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $tc('label.remover') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import InputDecimal from '../../../../produto/shared-components/inputs/InputDecimal';

export default {
  name: 'IndicadorRating',
  components: { InputDecimal },
  props: {
    ratings: {
      type: Array,
      default: () => ([]),
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    permiteAlteracao: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parametrizacaoPeriodoPasso3: this.$api.parametrizacaoPeriodoPasso3(this.$resource),
      rating: {},
      ratingsSelect: [],
      pontuacao: 0,
      dialogRating: false,
      headers: [
        {
          text: this.$t('label.rating'), value: 'descricao', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.pontuacao'), value: 'pontuacao', sortable: false, align: 'center',
        },
        {
          text: '', value: 'action', sortable: false, align: 'center',
        },
      ],
      valid: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    open() {
      this.buscarRating();
      this.dialogRating = true;
      this.limpar();
    },
    editar(item) {
      this.rating = item;
      this.pontuacao = item.pontuacao;
    },
    buscarRating() {
      this.parametrizacaoPeriodoPasso3.buscarRating()
        .then((response) => {
          this.ratingsSelect = response.body;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    remover(item) {
      this.ratings.splice(this.ratings.indexOf(item), 1);
    },
    limpar() {
      if (this.$refs.formRating) {
        this.resetarFormulario();
      } else {
        setTimeout(() => {
          this.resetarFormulario();
        }, 2E2);
      }
    },
    resetarFormulario() {
      this.$refs.formRating.reset();
      setTimeout(() => {
        this.$refs.formRating.reset();
      }, 2E2);
    },
    salvar() {
      if (!this.$refs.formRating.validate()) return;
      const rating = { ...this.rating };
      rating.pontuacao = this.pontuacao;

      const i = this.ratings.findIndex((r) => r.id === rating.id);

      if (i > -1) {
        this.ratings.splice(i, 1, rating);
      } else {
        this.ratings.push(rating);
      }

      this.limpar();
    },
  },
};
</script>
