<template>
  <v-form ref="form">
    <v-card style="margin-bottom:1em" class="RelatorioStatusAtingimentoFiltros">
      <v-card-title>
        <span class="title">{{ $tc('title.filtro', 2) }}</span>
      </v-card-title>

      <v-container fluid grid-list-md>
        <metadados-container-layout
          v-if="metadadosRelatorioContrato != null"
          :metadados-entidade="metadadosRelatorioContrato"
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :explode-hierarquia="true"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="filtrosRelatorioStatusAtingimento"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.filtrar')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { mapGetters } from 'vuex';
import { copyObject } from '../../../../produto/common/functions/helpers';

import MetadadosContainerLayout from '../../../../produto/shared-components/metadados/MetadadosContainerLayout';
import RelatorioStatusAtingimentoFiltrosCampos from './RelatorioStatusAtingimentoFiltrosCampos';

export default {
  name: 'RelatorioStatusAtingimentoFiltros',
  mixins: [
    RelatorioStatusAtingimentoFiltrosCampos,
  ],
  components: {
    MetadadosContainerLayout,
  },
  computed: {
    ...mapGetters('metadados', [
      'getContratoMetadado',
    ]),
    filtrosFixosClass() {
      return {
        'primary--text': true,
        'mr-2': true,
      };
    },
  },
  data() {
    return {
      configuracaoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),
      resources: this.$api.planejamentoContratoCadastro(this.$resource),

      carregouCampos: false,

      metadadosRelatorioContrato: null,

      ordenacaoCampos: [
        'periodo_planejamento',
        'tipo_contrato',
        'cluster',
        'divisao',
      ],

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      filtrosRelatorioStatusAtingimento: {},
    };
  },
  methods: {
    resetaFiltros() {
      this.filtrosRelatorioStatusAtingimento = {};
      this.$refs.container.refresh();
    },
    aplicarFiltros() {
      if (!this.$refs.form.validate()
          || !this.filtroPeriodoValido(this.filtrosRelatorioStatusAtingimento)) {
        return;
      }
      const parametros = {
        ...this.$refs.container.getValoresDependencias(),
        ...this.$refs.container.getValoresCamposPadrao(),
        ...this.$refs.container.getValoresCamposDinamicos(),
      };
      this.$emit('RelatorioStatusAtingimentoFiltros__AplicaFiltros', parametros);
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados && filtrosAplicados.periodo_planejamento) {
        for (let i = 0; i < filtrosAplicados.periodo_planejamento.length; i += 1) {
          const dtHoje = this.moment();
          const dtFinal = this.moment(filtrosAplicados.periodo_planejamento[i].dataFim, 'DD-MM-YYYY');

          if (dtFinal.isBefore(dtHoje)) {
            this.$toast(this.$t('message.periodo_terminou_antes_data_atual'));
            return false;
          }
        }
      }
      return true;
    },
  },
  mounted() {
    const metadados = this.getContratoMetadado;
    this.metadadosRelatorioContrato = copyObject(metadados);
  },
};
</script>
<style>
  .RelatorioStatusAtingimentoFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioStatusAtingimentoFiltros__Opcoes div.v-input__slot,
  .RelatorioStatusAtingimentoFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioStatusAtingimentoFiltros__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioStatusAtingimentoFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
