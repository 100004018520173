import getBasePath from '../../../produto/common/functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'parametrizacao-periodo');

const grupoActions = {
  obterPasso2: { method: 'GET', url: `${basePath}/passo2/{id}` },
  salvarRascunhoPasso2: { method: 'PUT', url: `${basePath}/passo2/{id}/rascunho` },
  salvarPasso2: { method: 'PUT', url: `${basePath}/passo2/{id}` },
};

export default (resource) => resource(`${basePath}`, {}, grupoActions);
