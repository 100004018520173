import ClassificacaoConfiguracao from './ClassificacaoConfiguracao';

export default class Classificacao {
  constructor(classificacao = {}) {
    this._id = classificacao.id;
    this._idExtensao = classificacao.idExtensao;
    this._idExterno = classificacao.idExterno;
    this._idNivelExtensao = classificacao.idNivelExtensao;
    this._nomExtensao = classificacao.nomExtensao;
    this._pontos = classificacao.pontos || 0;
    this._ordem = classificacao.ordem || 0;
    this._cardsRebate = classificacao.cardsRebate;

    this._classificacoesConfiguracao = [];
    if (classificacao.classificacoesConfiguracao
      && classificacao.classificacoesConfiguracao.length > 0) {
      classificacao.classificacoesConfiguracao.forEach((i) => {
        this._classificacoesConfiguracao.push(new ClassificacaoConfiguracao(i));
      });
    }
  }

  get ordem() {
    return this._ordem;
  }

  set ordem(value) {
    this._ordem = value;
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get idExtensao() {
    return this._idExtensao;
  }

  set idExtensao(value) {
    this._idExtensao = value;
  }

  get idExterno() {
    return this._idExterno;
  }

  set idExterno(value) {
    this._idExterno = value;
  }

  get idNivelExtensao() {
    return this._idNivelExtensao;
  }

  set idNivelExtensao(value) {
    this._idNivelExtensao = value;
  }

  get classificacoesConfiguracao() {
    return this._classificacoesConfiguracao;
  }

  set classificacoesConfiguracao(value) {
    this._classificacoesConfiguracao = value;
  }

  get nomExtensao() {
    return this._nomExtensao;
  }

  set nomExtensao(value) {
    this._nomExtensao = value;
  }

  get pontos() {
    return this._pontos;
  }

  get cardsRebate() {
    return this._cardsRebate;
  }

  set cardsRebate(value) {
    this._cardsRebate = value;
  }

  set pontos(value) {
    this._pontos = value;
  }

  get classificacaoConfigurada() {
    return this._pontos > 0 && this._classificacoesConfiguracao.length > 0;
  }

  get idsExtensoesCliente() {
    return this._classificacoesConfiguracao
      .flatMap((c) => c.idsExtensoesCliente)
      .filter((c, i, s) => s.indexOf(c) === i);
  }

  removerItem(item) {
    for (let i = 0; i < this._classificacoesConfiguracao.length; i += 1) {
      if (this._classificacoesConfiguracao[i].index === item.index) {
        this._classificacoesConfiguracao.splice(i, 1);
      }
    }
  }

  retornarConfiguracao(item) {
    return this._classificacoesConfiguracao.find((i) => i.index === item.index);
  }

  validarConflitosVinculos(classificacaoConfiguracao) {
    let conflito = false;
    for (let i = 0; i < this._classificacoesConfiguracao.length && !conflito; i += 1) {
      const classificacoesValidacao = this._classificacoesConfiguracao[i];
      if (classificacoesValidacao.index !== classificacaoConfiguracao.index) {
        conflito = classificacoesValidacao.validarConflitoVinculo(classificacaoConfiguracao);
      }
    }
    return conflito;
  }

  toJSON() {
    return {
      id: this._id,
      pontos: this._pontos,
      idExtensao: this._idExtensao,
      ordem: this._ordem,
      classificacoesConfiguracao: this._classificacoesConfiguracao,
    };
  }
}
