<template>
  <v-expansion-panel v-if="rateioManualPorFornecedor">
    <v-expansion-panel-header class="headline">{{ $tc('label.pagamento', 1)}}</v-expansion-panel-header>
    <v-expansion-panel-content>
      <planejamento-acao-form-previa-tabela
        v-if="acao.idAcaoTradePrevia"
        class-table="elevation-0"
        :id-acao-trade-previa="acao.idAcaoTradePrevia">
      </planejamento-acao-form-previa-tabela>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import PlanejamentoAcaoFormPreviaTabela from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormPreviaTabela';

export default {
  name: 'ApuracaoAcaoDetalhesInforAcaoPrevia',
  components: { PlanejamentoAcaoFormPreviaTabela },
  props: {
    acao: {
      type: Object,
      default: {},
    },
    configuracao: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rateioManualPorFornecedor() {
      return (this.configuracao.passo6
        && this.configuracao.passo6.liquidacao
        && this.configuracao.passo6.liquidacao.templateCalculo
        && this.configuracao.passo6.liquidacao.templateCalculo === 'MANUAL_POR_FORNECEDOR');
    },
  },
};
</script>
