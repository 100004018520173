<template>
  <v-dialog v-model="dialogRange" transition="dialog-bottom-transition" width="700" persistent>
    <v-card>
      <v-form ref="formRange" lazy-validation v-model="valid">
        <v-container fluid>
          <v-row>
            <span class="headline">{{ $tc('label.range', 1) }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogRange = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-row>
          <v-row v-if="!somenteLeitura || permiteAlteracao">
            <v-col cols="12" sm="6" md="4">
              <input-decimal
                :label="`${$tc('label.de', 2)} *`"
                v-model="range.valorDe"
                class="v-text-field"
                :rules="[rules.required]"
                :required="true"/>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <input-decimal
                :label="$tc('label.ate', 2)"
                v-model="range.valorAte"
                :rules="[rules.valorAteMenor]"
                class="v-text-field"/>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <input-decimal
                :negative="false"
                :label="`${$t('label.pontuacao', 1)} *`"
                v-model="range.pontuacao"
                class="v-text-field"
                :rules="[rules.required]"
                :required="true"/>
            </v-col>
          </v-row>
          <v-row justify="end" v-if="!somenteLeitura || permiteAlteracao">
            <v-btn text @click.native="limpar">{{ $t('label.limpar') }}</v-btn>
            <v-btn color="primary darken-1" text @click.native="salvar">{{ $t('label.salvar') }}</v-btn>
          </v-row>
          <v-row>
            <v-data-table
              :headers="headers"
              :items="ranges"
              hide-default-footer
              :no-data-text="$t('message.nenhum_intervalo')"
              style="width: 100%;">
              <template v-slot:item.valorDe="{ item }">
                {{getNumber(item.valorDe)}}
              </template>
              <template v-slot:item.valorAte="{ item }">
                {{getNumber(item.valorAte)}}
              </template>
              <template v-slot:item.pontuacao="{ item }">
                {{getNumber(item.pontuacao)}}
              </template>
              <template v-slot:item.action="{ item }">
                <v-tooltip bottom v-if="!somenteLeitura || permiteAlteracao">
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mx-0" v-on="on" @click="editar(item)">
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('label.editar') }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="!somenteLeitura || permiteAlteracao">
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mx-0" v-on="on" @click="remover(item)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $tc('label.remover') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import InputDecimal from '../../../../produto/shared-components/inputs/InputDecimal';
import { getNumber } from '../../../../produto/common/functions/helpers';
import { numberBetweenValues } from '../../../../produto/common/functions/numeric';

export default {
  name: 'IndicadorRange',
  components: { InputDecimal },
  props: {
    ranges: {
      type: Array,
      default: () => ([]),
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    permiteAlteracao: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      range: {
        index: -1,
        valorDe: 0,
        valorAte: 0,
        pontuacao: 0,
      },
      dialogRange: false,
      headers: [
        {
          text: this.$tc('label.de', 2), value: 'valorDe', sortable: false, align: 'center',
        },
        {
          text: this.$tc('label.ate', 2), value: 'valorAte', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.pontuacao'), value: 'pontuacao', sortable: false, align: 'center',
        },
        {
          text: '', value: 'action', sortable: false, align: 'center',
        },
      ],
      index: 0,
      valid: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorAteMenor: () => this.range.valorAte > this.range.valorDe
            || this.range.valorAte === 0 || this.$t('errors.valor_de_maior_ate'),
      },
    };
  },
  watch: {
    ranges(val) {
      if (val) {
        this.preencherIndex();
      }
    },
  },
  methods: {
    open() {
      this.limpar();
      this.dialogRange = true;
    },
    numberBetweenValues,
    getNumber,
    preencherIndex() {
      this.index = 0;
      this.ranges.forEach((range) => {
        this.index += 1;
        range.index = this.index;
      });
    },
    editar(item) {
      this.range = { ...item };
    },
    remover(item) {
      this.ranges.splice(this.ranges.indexOf(item), 1);
    },
    limpar() {
      if (!this.$refs.formRange || !this.$refs.formRange.value) return;

      this.range = {
        index: -1,
        valorDe: 0,
        valorAte: 0,
        pontuacao: 0,
      };

      this.$refs.formRange.resetValidation();
    },
    resetarValidation() {
      this.$refs.formRange.reset();
      setTimeout(() => {
        this.$refs.formRange.reset();
      }, 2E2);
    },
    salvar() {
      if (!this.$refs.formRange.validate() || this.intervaloEmConflito()) return;
      const range = { ...this.range };
      if (!range.valorAte) {
        range.valorAte = null;
      }

      if (range.index > 0) {
        const i = this.ranges.findIndex((r) => r.index === range.index);
        this.ranges.splice(i, 1, range);
      } else {
        this.index += 1;
        range.index = this.index;
        this.ranges.push(range);
      }

      this.limpar();
    },
    intervaloEmConflito() {
      for (let i = 0; i < this.ranges.length; i += 1) {
        const intervalo = this.ranges[i];
        if (this.range.index !== intervalo.index
            && this.validarConflito(intervalo.valorDe, intervalo.valorAte)) {
          this.$toast(this.$t('errors.valor.intervalos.conflito'));
          return true;
        }
      }
      return false;
    },
    validarConflito(valorDe, valorAte) {
      if (!this.range.valorAte) {
        return this.validarConflitoEntreValorInfinito(valorDe, valorAte);
      }
      return this.validarConflitoEntreValores(valorDe, valorAte);
    },
    validarConflitoEntreValores(valorDe, valorAte) {
      return this.numberBetweenValues(this.range.valorDe, valorDe, valorAte)
          || this.numberBetweenValues(this.range.valorAte, valorDe, valorAte)
          || this.numberBetweenValues(valorAte, this.range.valorDe,
            this.range.valorAte)
          || this.numberBetweenValues(valorDe, this.range.valorDe,
            this.range.valorAte);
    },
    validarConflitoEntreValorInfinito(valorDe, valorAte) {
      return !valorAte
          || this.numberBetweenValues(this.range.valorDe, valorDe, valorAte)
          || this.range.valorDe < valorDe;
    },
  },
};
</script>
