import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('orcamento', 'extrato');

const contaCorrenteAjusteVerbaActions = {
  tiposVerba: { method: 'GET', url: `${basePath}/tipo-verba` },
  periodos: { method: 'GET', url: `${basePath}/tipo-verba/{id}/periodo` },
  contasCorrentes: { method: 'GET', url: `${basePath}/tipo-verba/{idTipoVerba}/periodo/{idPeriodo}/conta-corrente` },
  contaCorrente: { method: 'GET', url: `${basePath}/conta-corrente?{&ids*}` },
  linhasInvestimentos: { method: 'GET', url: `${basePath}/conta-corrente/linha-investimento?{&ids*}` },
  eventos: { method: 'GET', url: `${basePath}/conta-corrente/eventos?{&idsContaCorrente*}` },
  detalhesEvento: { method: 'GET', url: `${basePath}/conta-corrente/detalhes?{&idsContaCorrente*}` },
};

export default (resource) => resource(`${basePath}`, {}, contaCorrenteAjusteVerbaActions);
