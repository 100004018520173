import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    podeSolicitarAprovacao() {
      // return !this.somenteLeitura && this.statusAcordo === 'EM_CADASTRO' && this.edicao
      //    && this.canAccessCRUD && this.workflowHabilitado && this.usuarioSolicitanteFluxo;
      return this.edicao && this.contratoEdicao.passoGeral && this.possuiFluxoAprovacao
        && this.contratoEdicao.passoGeral.status === 'EM_CADASTRO' && this.solicitanteFluxo;
    },
    podeAprovar() {
      return this.contratoEdicao.passoGeral
        && this.contratoEdicao.passoGeral.status === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo;
    },
    podeFinalizar() {
      return this.contratoEdicao.passoGeral && !this.possuiFluxoAprovacao
        && this.contratoEdicao.passoGeral.status !== 'APROVADO';
    },
  },
  methods: {
    exibicaoReprovarPersonalizada() {
      return this.getAllRoles.filter((el) => el === 'REPROVAR_DPM').length > 0;
    },
  },
};
