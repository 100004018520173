var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"Container_Tabela",attrs:{"fluid":"","px-0":"","py-2":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-container',{staticClass:"Container_Tabela py-3",attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"id":"tabela_notas","headers":_vm.headers,"items":_vm.listaNotasInconsistencia,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
               itemsPerPageOptions: [10, 20, 30, 40, 50],
              }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openReprocessarDialog(item)}}},on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("cached")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('label.reprocessar', 1)))])])]}},{key:"item.lote",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.lote)+" ")]}},{key:"item.dataRemessa",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.data_remessa)+" ")]}},{key:"item.dataEmissao",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.data_emissao)+" ")]}},{key:"item.numeroNf",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.numero_nf)+" ")]}},{key:"item.numeroPedido",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.numero_pedido)+" ")]}},(!_vm.isSellOut)?{key:"item.nomeCliente",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome_cliente)+" ")]}}:null,(!_vm.isSellOut)?{key:"item.divisaoRegional",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.divisao_regional)+" ")]}}:null,(!_vm.isSellOut)?{key:"item.divisaoTerritorio",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.divisao_territorio)+" ")]}}:null,{key:"item.nomeCultura",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome_cultura)+" ")]}},{key:"item.nomeProduto",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome_produto)+" ")]}},{key:"item.statusPedido",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.status_pedido)+" ")]}},{key:"item.tipoDocumento",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipo_documento)+" ")]}},{key:"item.idExecucao",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_execucao)+" ")]}},{key:"item.valor",fn:function(ref){
              var item = ref.item;
return [_c('v-menu',{key:item.id,attrs:{"close-on-content-click":true,"transition":"scale-transition","origin":"top right","offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""}},on),[_c('v-icon',[_vm._v("attach_money")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{staticClass:"pa-0",attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.valor_total')))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.getMoney(item.valor_total))+" ")])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.valor_liquido')))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.getMoney(item.valor_liquido))+" ")])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.valor_total'))+" "+_vm._s(_vm.$tc('label.dolar', 1).toLowerCase()))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.getMoney(item.valor_total_dolar, 0, 2, '$')))])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.valor_liquido'))+" "+_vm._s(_vm.$tc('label.dolar', 1).toLowerCase()))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.getMoney(item.valor_liq_dolar, 0, 2, '$')))])],1)],1)],1)],1)],1)],1)]}},{key:"item.erro",fn:function(ref){
              var item = ref.item;
return [_c('v-menu',{key:item.id + 99,attrs:{"close-on-content-click":true,"transition":"scale-transition","origin":"top right","offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""}},on),[_c('v-icon',[_vm._v("info")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{staticClass:"pa-0"},[_vm._l((item.erros.value.slice(1, -1).split(', ')),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$tc('label.erro', 1))+" "+_vm._s(index + 1))]),_c('v-list-item-title',[_vm._v(_vm._s(item.slice(1, -1)))])],1)],1)}),_c('v-divider')],2)],1)],1)],1)]}}],null,true)})],1)],1)],1)],1)],1),_c('confirm',{ref:"reprocessarDialog",attrs:{"message":_vm.dialog.content,"persistent":true},on:{"agree":function($event){return _vm.reprocessarInconsistencia()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }