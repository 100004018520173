<template>
  <v-expansion-panel class="ApuracaoDpmGrupoCulturasPainel">
    <v-expansion-panel-header class="pb-0 pt-0">
      <span class="header">
        {{ grupo.grupoCultura }}
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-0">
      <v-data-table
        :headers="cabecalho"
        :items="grupo.marcas"
        dense
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-footer>
        <template v-slot:item.pontoUnitario="{ item }">
          {{getNumber(item.pontoUnitario)}}
        </template>
        <template v-slot:item.volumeMeta="{ item }">
          {{getNumber(item.volumeMeta)}}
        </template>
        <template v-slot:item.volumeFaturado="{ item }">
          {{getNumber(item.volumeFaturado)}}
        </template>
        <template v-slot:item.volumeFatCart="{ item }">
          {{getNumber(item.volumeFatCart)}}
        </template>
        <template v-slot:item.pontosMeta="{ item }">
          {{getNumber(item.pontosMeta)}}
        </template>
        <template v-slot:item.pontosFaturamento="{ item }">
          {{getNumber(item.pontosFaturamento)}}
        </template>
        <template v-slot:item.pontosFatCart="{ item }">
          {{getNumber(item.pontosFatCart)}}
        </template>
        <template v-slot:body.append>
          <tr>
            <td class="text-right" colspan="5" style="font-weight:bold">
              {{getNumber(totalVolumeMeta)}}
            </td>
            <td class="text-right" style="font-weight:bold">
              {{getNumber(totalVolumeFaturado)}}
            </td>
            <td class="text-right" style="font-weight:bold">
              {{getNumber(totalVolumeFatCart)}}
            </td>
            <td class="text-right" style="font-weight:bold">
              {{getNumber(totalPontosMeta)}}
            </td>
            <td class="text-right" style="font-weight:bold">
              {{getNumber(totalPontosFaturamento)}}
            </td>
            <td class="text-right" style="font-weight:bold">
              {{getNumber(totalPontosFatCart)}}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { getNumber } from '../../../../produto/common/functions/helpers';

export default {
  props: {
    grupo: {
      type: Object,
      required: true,
    },
    painelAtivo: Boolean,
  },
  methods: {
    getNumber,
  },
  computed: {
    totalVolumeMeta() {
      return this.grupo.marcas
        .map((m) => m.volumeMeta)
        .reduce((p, a) => p + a, 0);
    },
    totalVolumeFaturado() {
      return this.grupo.marcas
        .map((m) => m.volumeFaturado)
        .reduce((p, a) => p + a, 0);
    },
    totalVolumeFatCart() {
      return this.grupo.marcas
        .map((m) => m.volumeFatCart)
        .reduce((p, a) => p + a, 0);
    },
    totalPontosMeta() {
      return this.grupo.marcas
        .map((m) => m.pontosMeta)
        .reduce((p, a) => p + a, 0);
    },
    totalPontosFaturamento() {
      return this.grupo.marcas
        .map((m) => m.pontosFaturamento)
        .reduce((p, a) => p + a, 0);
    },
    totalPontosFatCart() {
      return this.grupo.marcas
        .map((m) => m.pontosFatCart)
        .reduce((p, a) => p + a, 0);
    },
  },
  data() {
    return {
      cabecalho: [
        {
          text: this.$tc('label.segmento', 1),
          value: 'segmentos',
          align: 'start',
        },
        {
          text: this.$t('label.mix'),
          value: 'mix',
          align: 'start',
        },
        {
          text: this.$t('label.marca'),
          value: 'marca',
          align: 'start',
        },
        {
          text: this.$t('label.ponto_unitario'),
          value: 'pontoUnitario',
          align: 'end',
        },
        {
          text: this.$t('label.volume_meta'),
          value: 'volumeMeta',
          align: 'end',
        },
        {
          text: this.$t('label.volume_faturado'),
          value: 'volumeFaturado',
          align: 'end',
        },
        {
          text: this.$t('label.volume_fat_cart'),
          value: 'volumeFatCart',
          align: 'end',
        },
        {
          text: this.$t('label.pontos_meta'),
          value: 'pontosMeta',
          align: 'end',
        },
        {
          text: this.$t('label.pontos_faturamento'),
          value: 'pontosFaturamento',
          align: 'end',
        },
        {
          text: this.$t('label.pontos_fat_cart'),
          value: 'pontosFatCart',
          align: 'end',
        },
      ],
    };
  },
};
</script>
<style scoped>
.v-expansion-panel span.header {
  min-width: 100px;
}
.preview {
  font-size:.8rem;
}
</style>
<style>
.ApuracaoDpmGrupoCulturasPainel .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
.ApuracaoDpmGrupoCulturasPainel.v-expansion-panel .v-expansion-panel-header--active:before {
  background-color: var(--v-primary-lighten1);
}
.ApuracaoDpmGrupoCulturasPainel.v-expansion-panel .v-expansion-panel-header--active span.header,
.ApuracaoDpmGrupoCulturasPainel.v-expansion-panel .v-expansion-panel-header--active i.v-icon {
  color: var(--v-primary-darken1) !important;
}
</style>
