var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"id":"campo_pesquisar","append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},on:{"input":_vm.filtrar},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","align-self":"center"}},[_c('v-row',[(_vm.canAccessCRUD && _vm.tipoImportacao)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"id":"btn_importar","icon":""},on:{"click":_vm.importarDados}},on),[_c('v-icon',[_vm._v("backup")])],1)]}}],null,false,2024458152)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.importar')))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"id":"btn_exportar","icon":"","disabled":_vm.bloquearBotaoExportacao},on:{"click":function($event){return _vm.exportar()}}},on),[_c('v-icon',[_vm._v("get_app")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('label.exportar')))])]),(_vm.canAccessCRUD && _vm.selected.length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"text":"","icon":"","id":"btn_ativar_inativar"},on:{"click":function($event){return _vm.ativarInativar()}}},on),[_c('v-icon',[_vm._v("block")])],1)]}}],null,false,3616128480)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.ativar_inativa')))])]):_vm._e()],1)],1)],1),_c('v-data-table',{attrs:{"id":"tabela_fornecedores","headers":_vm.headersDinamico,"items":_vm.fornecedores,"options":_vm.pagination,"show-select":"","server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
      itemsPerPageOptions: [10, 25, 50],
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
    var isSelected = ref.isSelected;
    var select = ref.select;
return [_c('tr',{key:item.name,class:{ 'TableRow--disabled': !item.indAtivo }},[_c('td',[_c('v-simple-checkbox',{attrs:{"value":isSelected},on:{"input":function($event){return select($event)}}})],1),_c('td',{staticClass:"justify-center px-0",staticStyle:{"width":"1%","text-align":"center"}},[(_vm.canAccessCRUD)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editar(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.editar')))])]):_vm._e(),(!_vm.canAccessCRUD)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.ver(item)}}},on),[_c('v-icon',[_vm._v("equalizer")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.mais_detalhes')))])]):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.idExterno))]),_c('td',[_vm._v(_vm._s(item.desRazaoSocial))]),_c('td',[_vm._v(_vm._s(item.desNomeFantasia))]),_c('td',[_vm._v(_vm._s(item.desCnpj))]),_c('td',[_vm._v(_vm._s(item.tipoPessoa))]),_vm._l((Object.keys(_vm.headersDinamicoObrigatorio)),function(entidade,indice){return _c('td',{key:indice},[_vm._v(" "+_vm._s(_vm.getNomesSeparadosPorVirgula(entidade, 'nomExtensao', item))+" ")])})],2)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }