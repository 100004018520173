var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":""},model:{value:(_vm.exibir),callback:function ($$v) {_vm.exibir=$$v},expression:"exibir"}},[_c('v-card',{staticClass:"mt-3",staticStyle:{"height":"100%"}},[_c('v-card-title',[_c('div',{staticClass:"card-subtitle"},[_vm._v(" "+_vm._s(((_vm.$tc('label.distribuicao_valor', 2)) + " :: " + _vm.tiposVerbas + " :: " + _vm.periodosOrcamentarios))+" ")])]),_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.$t('message.apuracao_rateio_pagamento')))])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('input-money',{attrs:{"label":_vm.$tc('label.valor_pagamento', 1),"disabled":true},model:{value:(_vm.vlrConfApuracao),callback:function ($$v) {_vm.vlrConfApuracao=$$v},expression:"vlrConfApuracao"}})],1),(!(this.statusApuracao && (this.statusApuracao === 'REPROVADO' || this.statusApuracao === 'CANCELADO')))?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('input-money',{attrs:{"label":_vm.$tc('label.vlr_reservado', 1),"disabled":true},model:{value:(_vm.vlrReservado),callback:function ($$v) {_vm.vlrReservado=$$v},expression:"vlrReservado"}})],1):_vm._e(),(!_vm.somenteLeitura && this.vlrConfApuracao !== this.vlrReservado)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('input-money',{attrs:{"label":_vm.$t('label.valor_pendente_ajuste'),"disabled":true},model:{value:(_vm.pendenteReserva),callback:function ($$v) {_vm.pendenteReserva=$$v},expression:"pendenteReserva"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.cabecalhoTabelaFiltrados,"dense":"","items":_vm.contas,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"hide-default-footer":true,"footer-props":{
                itemsPerPageOptions: [25, 50],
              }},scopedSlots:_vm._u([{key:"item.descricao",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.descricaoLinha)+" ")]}},{key:"item.nome_conta",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomeConta)+" ")]}},{key:"item.tipo_investimento",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipoInvestimento)+" ")]}},{key:"item.saldo",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.saldo))+" ")]}},{key:"item.saldo_ajustado",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.saldoAjustado))+" ")]}},{key:"item.vlr_planejado",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.vlrPlanejado))+" ")]}},{key:"item.vlr_reservado_atual",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.vlrReservadoAtual))+" ")]}},{key:"item.vlr_reservado",fn:function(ref){
              var item = ref.item;
return [_c('input-money',{class:{ 'valor-reservado-invalido': item.vlrReservadoInvalido},attrs:{"dense":"","disabled":_vm.somenteLeitura},on:{"input":function () { return _vm.valorReservadoAlterado(item); }},model:{value:(item.vlrReservado),callback:function ($$v) {_vm.$set(item, "vlrReservado", $$v)},expression:"item.vlrReservado"}})]}}])})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.cancelarRateio}},[_vm._v(_vm._s(_vm.somenteLeitura || this.vlrConfApuracao === this.vlrReservado ? _vm.$t('label.voltar') : _vm.$t('label.cancelar')))]),(!_vm.somenteLeitura && this.vlrConfApuracao !== this.vlrReservado)?_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.podeSalvar},on:{"click":_vm.salvar}},[_vm._v(_vm._s(_vm.$t('label.salvar')))]):_vm._e()],1),_c('confirm',{ref:"confirmCancelar",attrs:{"message":_vm.$t('message.cancelar_sem_salvar'),"persistent":true},on:{"agree":_vm.fecharModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }