<template lang="html">
  <v-data-table
    class="elevation-1"
    id="tabela_dados"
    :headers="headers"
    :items="listaDados"
    :options.sync="pagination"
    :server-items-length="totalPage"
    :no-data-text="$t('label.tabela_sem_conteudo')"
    :footer-props="{
      itemsPerPageOptions: [10, 25, 50],
    }"
    >
    <template v-slot:top>
      <v-toolbar color="white" class="elevation-0">
        <v-toolbar-title>{{$tc('title.relatorio', 1)}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="listaDados.length" icon @click="requestExportacao">
          <v-icon>get_app</v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.periodo_planejamento="{ item }">
      {{ item.periodo_planejamento }}
    </template>
    <template v-slot:item.estrutura_venda_diretoria="{ item }">
      {{ item.estrutura_venda_diretoria }}
    </template>
    <template v-slot:item.estrutura_venda_regional="{ item }">
      {{ item.estrutura_venda_regional }}
    </template>
    <template v-slot:item.estrutura_venda_rtv="{ item }">
      {{ item.estrutura_venda_rtv }}
    </template>
    <template v-slot:item.nome_fantasia="{ item }">
      {{ item.nome_fantasia }}
    </template>
    <template v-slot:item.tipo_dpm="{ item }">
      {{ item.tipo_dpm }}
    </template>
    <template v-slot:item.classificacao_dpm="{ item }">
      {{ item.classificacao_dpm }}
    </template>
    <template v-slot:item.ano="{ item }">
      {{ item.ano }}
    </template>
    <template v-slot:item.status="{ item }">
      {{ $tc(`status_entidade.${item.status.toLowerCase()}`, 1) }}
    </template>
    <template v-slot:item.passo_fluxo="{ item }">
      {{ item.passo_fluxo }}
    </template>
  </v-data-table>
</template>

<script type="text/javascript">
import { listarRelatorioStatusAdesao } from '@/common/resources/relatorio-contrato';
import { removeEmptyProperties } from '../../../../produto/common/functions/helpers';
import exportacao from '../../../../produto/common/functions/exportacao';

export default {
  components: {
  },
  props: {
    filtros: Object,
  },
  data() {
    return {
      filtrosAplicados: this.filtros,
      pagination: {},
      listaDados: [],
      totalPage: 0,
      esperar: false,
      headers: [
        { text: this.$tc('label.periodo_planejamento', 1), value: 'periodo_planejamento', sortable: true },
        { text: this.$tc('label.estrutura_venda_diretoria', 1), value: 'estrutura_venda_diretoria', sortable: true },
        { text: this.$tc('label.regional', 1), value: 'estrutura_venda_regional', sortable: true },
        { text: this.$tc('label.rtv', 1), value: 'estrutura_venda_rtv', sortable: true },
        { text: this.$tc('label.holding', 1), value: 'nome_fantasia', sortable: true },
        { text: this.$tc('label.tipo_contrato', 1), value: 'tipo_dpm', sortable: true },
        { text: this.$tc('label.classificacao', 1), value: 'classificacao_dpm', sortable: true },
        { text: this.$tc('label.ano', 1), value: 'ano', sortable: true },
        { text: this.$tc('label.status', 1), value: 'status', sortable: true },
        { text: this.$tc('label.aprovador', 1), value: 'passo_fluxo', sortable: true },
      ],
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscar();
      },
    },
    pagination: {
      handler() {
        this.buscar();
      },
      deep: true,
    },
  },
  methods: {
    buscar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscarDados();
      }, 5E2);
    },
    buscarDados() {
      if (!this.filtros) {
        this.pararEsperar();
        return;
      }
      const params = {
        ...this.filtrosAplicados,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      listarRelatorioStatusAdesao(params, this.$resource)
        .then((response) => {
          this.listaDados = response.data.resposta;
          this.listaDados.forEach((c) => {
            this.montaArvoreInvertidaDivisao(c);
          });
          this.totalPage = response.data.quantidadeRegistrosPagina;
          this.pararEsperar();
        })
        .catch((err) => {
          this.pararEsperar();
          this.$error(this, err);
        });
    },
    montaArvoreInvertidaDivisao(contrato) {
      const { hier } = contrato;
      if (!hier || hier.length === 0) {
        return;
      }

      const itemHier = (name, children) => ({ name, children });

      const converteArvore = (children) => {
        children = children.map((c) => {
          const item = itemHier(c.nome, converteArvore(c.filhos));
          if (item.children.length === 0) {
            item.disabled = true;
          }
          return item;
        });
        return children;
      };

      const tree = hier.map((h) => {
        const base = itemHier(h.nome, h.filhos);
        base.children = converteArvore(base.children);
        return base;
      });

      contrato.treeDivisao = tree;
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    requestExportacao() {
      const params = { ...this.filtrosAplicados };
      const filtroTratado = removeEmptyProperties(params);
      exportacao.exportar(null, 'relatorio_status_adesao', this, filtroTratado);
    },
  },
};
</script>

<style>
.Container_Tabela table.v-table tbody td:first-child,.Container_Tabela  table.v-table tbody td:not(:first-child),
  .Container_Tabela table.v-table tbody th:first-child, .Container_Tabela table.v-table tbody th:not(:first-child),
  .Container_Tabela table.v-table thead td:first-child, .Container_Tabela table.v-table thead td:not(:first-child),
  .Container_Tabela table.v-table thead th:first-child, .Container_Tabela table.v-table thead th:not(:first-child) {
  padding: 0 11px;
}
</style>
