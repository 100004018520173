<template>
  <div v-if="canAccessPage">
    <parametrizacao-periodo-tabela
      @ParametrizacaoPeriodoTabela_EDITAR="editar"
      @ParametrizacaoPeriodoTabela_ALTERAR="alterar"
      @ParametrizacaoPeriodoTabela_VISUALIZAR="visualizar">
    </parametrizacao-periodo-tabela>

    <v-tooltip left v-if="canAccessCRUD">
      <template v-slot:activator="{ on }">
        <v-btn @click="$router.push({ name: 'novoParametrizacaoPeriodo' })"
               fixed fab bottom right color="primary" v-on="on" class="v-btn__fab">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>{{$t('label.nova_parametrizacao')}}</span>
    </v-tooltip>

  </div>
</template>

<script>
import { generateComputed } from '../../../produto/common/functions/roles-computed-generator';
import ParametrizacaoPeriodoTabela from './ParametrizacaoPeriodoTabela';

export default {
  name: 'ParametrizacaoPeriodoList',
  components: { ParametrizacaoPeriodoTabela },
  computed: {
    ...generateComputed('PARAM PERIODO', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
  },
  methods: {
    editar(item) {
      this.$router.push({ name: 'editarParametrizacaoPeriodo', params: { id: item.id } });
    },
    alterar(item) {
      this.$router.push({ name: 'alterarParametrizacaoPeriodo', params: { id: item.id } });
    },
    visualizar(item) {
      this.$router.push({ name: 'verParametrizacaoPeriodo', params: { id: item.id } });
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
};
</script>
