<template>
   <div class="RelatorioExportacoesAdama">
      <v-spacer></v-spacer>
    <relatorio-exportacao-acao-pagamento
     v-if="acessoRelatorioAcaoPgto"
     @RelatorioExportacaoAcaoPagamentoAdama__AplicaFiltros="aplicaFiltros"
    >
    </relatorio-exportacao-acao-pagamento>
    <relatorio-exportacao-extrato-rebate
      v-if="acessoRelatorioExtratoRebate">
    </relatorio-exportacao-extrato-rebate>
    <relatorio-exportacao-saldo
     v-if="acessoRelatorioExtratoSaldo"
     @RelatorioExportacaoSaldoAdama__AplicaFiltros="aplicaFiltros">
    </relatorio-exportacao-saldo>
    <relatorio-exportacao-status-acoes
     v-if="acessoRelatorioStatusAcao"
     @RelatorioExportacaoStatusAcoesAdama__AplicaFiltros="aplicaFiltros">
    </relatorio-exportacao-status-acoes>
   </div>
</template>
<script>

import { mapGetters } from 'vuex';
import { removeEmptyProperties } from '../../../produto/common/functions/helpers';
import RelatorioExportacaoAcaoPagamento from './pagamento/RelatorioExportacaoAcaoPagamento';
import RelatorioExportacaoExtratoRebate from './extrato-rebate/RelatorioExportacaoExtratoRebate';
import RelatorioExportacaoSaldo from './saldo/RelatorioExportacaoSaldo';
import RelatorioExportacaoStatusAcoes from './status-acoes/RelatorioExportacaoStatusAcoes';
import exportacao from '../../../produto/common/functions/exportacao';

export default {
  name: 'RelatorioExportacoesForm',
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    acessoRelatorioAcaoPgto() {
      return this.getAllRoles.includes('REL_ACAO_PAGAMENTO');
    },
    acessoRelatorioExtratoRebate() {
      return this.getAllRoles.includes('REL_EXTRATO_REBATE');
    },
    acessoRelatorioExtratoSaldo() {
      return this.getAllRoles.includes('REL_SALDO');
    },
    acessoRelatorioStatusAcao() {
      return this.getAllRoles.includes('REL_STATUS_ACAO');
    },
  },
  components: {
    RelatorioExportacaoAcaoPagamento,
    RelatorioExportacaoExtratoRebate,
    RelatorioExportacaoSaldo,
    RelatorioExportacaoStatusAcoes,
  },
  methods: {
    aplicaFiltros(filtrosAplicados, tipoRelatorio, modulo) {
      if (!filtrosAplicados) {
        return;
      }
      this.filtros = {
        ...filtrosAplicados,
      };
      this.requestExportacao(this.filtros, tipoRelatorio, modulo);
    },
    requestExportacao(filtros, tipoRelatorio, modulo = 'job') {
      const filtroTratado = removeEmptyProperties(filtros);
      this.bloquearExportacao = true;

      exportacao.exportar(() => {
        this.bloquearExportacao = false;
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
  },
  data() {
    return {
      filtros: null,
      bloquearExportacao: false,
    };
  },
};
</script>

<style>
.RelatorioExportacoesAdama {
  padding-bottom: 60px;
}
</style>
