<template>
  <v-col cols="12" md="7" v-bind:class="{ 'rodape-acoes': alturaTela <= 768}" style="text-align:right">
    <slot name="acoes-padrao" />
    <v-btn @click="salvarAntesDeFinalizar()"
      dark
      class="mr-3"
      color="red"
      v-show="ultimoPasso && podeFinalizar">
      {{ $tc('label.finalizar', 1) }}
    </v-btn>
    <v-btn @click="salvarAntesDeSolicitar()"
      dark
      class="mr-3"
      color="red"
      v-show="ultimoPasso && podeSolicitarAprovacao"
      :disabled="!permiteSalvar">
      {{ $t('label.solicitar_aprovacao') }}
    </v-btn>
    <v-btn @click="abrirJustificativaAprovar()"
      dark
      class="mr-3"
      color="primary"
      v-show="ultimoPasso && podeAprovar">
      {{ $t('label.aprovar') }}
    </v-btn>
    <v-btn @click="abrirJustificativaReprovar()"
      dark
      color="red"
      class="mr-3"
      v-show="ultimoPasso && podeAprovar && exibicaoReprovarPersonalizada()">
      {{ $t('label.reprovar') }}
    </v-btn>
    <v-btn @click="abrirJustificativaAnalise()"
      dark
      class="mr-3"
      color="accent"
      v-show="ultimoPasso && podeAprovar">
      {{ $tc('label.enviar_analise', 1) }}
    </v-btn>
    <confirm
      ref="confirmDialog"
      :persistent="true"/>
    <planejamento-acao-form-usuario-cliente
      ref="modalUsuarioCliente"
      :title="indModoVarejo ? $tc('title.selecao_aprovador_fornecedor', 1) : $tc('title.selecao_aprovador_cliente', 1)"
      :passoCliente="passoCliente"
      @selecionarUsuarioCliente="selecionaAprovadorPassoClienteFornecedor"/>
    <planejamento-acao-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"/>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex';
import BotoesFluxoMixin from '@/spa/planejamento-contrato/form/BotoesFluxoMixin';
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import PlanejamentoAcaoFormUsuarioCliente from '../../planejamento-acao/form/PlanejamentoAcaoFormUsuarioCliente';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';

export default {
  mixins: [BotoesFluxoMixin],
  props: {
    ultimoPasso: Boolean,
    permiteSalvar: Boolean,
    edicao: Boolean,
    contratoEdicao: Object,
    contratoSalvar: {
      type: Function,
      default: () => ({}),
    },
    contratoFormatar: {
      type: Function,
      default: () => ({}),
    },
    contratoValidar: {
      type: Function,
      default: () => Promise.resolve(true),
    },
  },
  data() {
    return {
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      solicitanteFluxo: false,
      passoCliente: {},
      aprovadorFluxo: false,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      msgFluxoSemAprovador: '',
      possuiFluxoAprovacao: false,
      alturaTela: window.outerHeight,
    };
  },
  components: {
    PlanejamentoAcaoFormUsuarioCliente,
    PlanejamentoAcaoJustificativa,
    Confirm,
  },
  computed: {
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
  },
  methods: {
    carregaInformacoesFluxo(idContrato) {
      if (this.contratoEdicao.passoGeral.status === 'EM_CADASTRO') {
        this.verificarUsuarioSolicitante(idContrato);
      } else {
        this.verificarUsuarioAprovador(idContrato);
        if (this.possuiFluxoAprovacao) {
          this.buscarStatusFluxo(idContrato);
        }
      }
    },
    verificarUsuarioSolicitante(idContrato) {
      if (this.contratoEdicao.passoGeral && this.contratoEdicao.passoGeral.status === 'EM_CADASTRO') {
        this.workflowContratoResource.solicitante({ idContrato })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
            this.emitirPodeSolicitarContrato();
          });
      }
    },
    salvarAntesDeSolicitar() {
      const isSolicitandoAprovacao = true;
      this.contratoSalvar(isSolicitandoAprovacao).then((idContrato) => {
        if (idContrato === null) {
          return;
        }
        this.contratoValidar(true).then((isValido) => {
          if (isValido) {
            this.solicitarAprovacao();
          }
        });
      });
    },
    solicitarAprovacao() {
      const resource = this.indModoVarejo
        ? this.workflowContratoResource.buscaPassosFornecedor
        : this.workflowContratoResource.buscaPassosClientes;
      const { idContrato } = this.$route.params;
      resource({ idContrato })
        .then((response) => {
          const passosCliente = response.data;
          if (passosCliente.length === 0) {
            this.iniciaFluxo();
          } else if (passosCliente.length > 1) {
            const msg = this.indModoVarejo ? this.$t('errors.multiplos_passos_fornecedor')
              : this.$t('errors.multiplos_passos_cliente');
            this.$toast(msg);
          } else if (!passosCliente[0].usuarios.length) {
            this.msgFluxoSemAprovador = this.indModoVarejo
              ? this.$t('message.nao_tem_fornecedor_aprovador')
              : this.$t('message.nao_tem_cliente_aprovador');
            setTimeout(() => this.$refs.confirmDialog
              .openWithParams(
                this.$t('title.atencao'),
                this.msgFluxoSemAprovador,
                (ok) => {
                  if (ok) {
                    this.iniciaFluxo();
                  }
                },
              ));
          } else {
            // Implementado suporte apenas para um passo cliente
            const [passoCliente] = passosCliente;
            this.passoCliente = passoCliente;
            setTimeout(() => this.$refs.modalUsuarioCliente.open());
          }
        });
    },
    salvarAntesDeFinalizar() {
      const isSolicitandoAprovacao = false;
      this.contratoSalvar(isSolicitandoAprovacao).then((idContrato) => {
        if (idContrato === null) {
          return;
        }
        this.contratoValidar(true).then((isValido) => {
          if (isValido) {
            this.finalizar();
          }
        });
      });
    },
    finalizar() {
      this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          this.$t('message.deseja_finalizar_contrato'),
          (ok) => {
            if (ok) {
              const contrato = this.contratoFormatar();
              this.planejamentoContratoResource.finalizar(contrato)
                .then(() => {
                  this.$router.push({ name: 'planejamento-contrato' });
                  this.$toast(this.$t('message.contrato_finalizado'));
                })
                .catch((err) => {
                  this.$error(this, err);
                });
            }
          },
        );
    },
    iniciaFluxo() {
      const { idContrato } = this.$route.params;
      this.workflowContratoResource.iniciaFluxo({ idContrato }, { idContrato })
        .then(() => {
          this.$router.push({ name: 'planejamento-contrato' });
          this.$toast(this.$t('message.solicitacao_aprovacao'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificaSePossuiFluxoAprovacao(id) {
      const params = {
        idContrato: id,
        fluxo: 'PLANEJAMENTO',
      };
      this.workflowContratoResource.buscarFluxoTrade(params)
        .then((res) => {
          const fluxoPlanejamento = res.data;
          this.possuiFluxoAprovacao = fluxoPlanejamento.indPossuiFluxoAprovacao;
          this.emitirPodeSolicitarContrato();
        });
    },
    emitirPodeSolicitarContrato() {
      setTimeout(() => {
        this.$emit('PlanejamentoContratoFormFluxoAprovacao__podeSolicitarAprovacaoContrato',
          this.podeSolicitarAprovacao);
      }, 3E2);
    },
    selecionaAprovadorPassoClienteFornecedor(idPasso, idUsuario) {
      const { idContrato } = this.$route.params;
      const params = {
        idContrato,
        idPasso,
        idUsuario,
      };
      const resource = this.indModoVarejo
        ? this.workflowContratoResource.associaUsuarioFornecedorAprovador
        : this.workflowContratoResource.associaUsuarioClienteAprovador;
      resource(params, params)
        .then(() => this.iniciaFluxo())
        .catch((err) => this.$error(this, err));
    },
    verificarUsuarioAprovador(idContrato) {
      if (this.contratoEdicao.passoGeral && this.contratoEdicao.passoGeral.status === 'AGUARDANDO_APROVACAO') {
        this.workflowContratoResource.aprovador({ idContrato })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            }
          });
      }
    },
    abrirJustificativaAprovar() {
      this.justificativaObrigatoria = false;
      this.actionJustificativa = this.aprovar;
      setTimeout(() => this.$refs.modalJustificativa.open());
    },
    aprovar(justificativa) {
      const { idContrato } = this.$route.params;
      this.workflowContratoResource.aprovarPasso({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-contrato' });
          this.$toast(this.$t('message.contrato_aprovado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaReprovar() {
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.reprovar;
      setTimeout(() => this.$refs.modalJustificativa.open());
    },
    reprovar(justificativa) {
      const { idContrato } = this.$route.params;
      this.workflowContratoResource.reprovar({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-contrato' });
          this.$toast(this.$t('message.contrato_reprovado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaAnalise() {
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.enviarAnalise;
      setTimeout(() => this.$refs.modalJustificativa.open());
    },
    enviarAnalise(justificativa) {
      const { idContrato } = this.$route.params;
      this.workflowContratoResource.enviarAnalise({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-contrato' });
          this.$toast(this.$t('message.contrato_enviado_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarStatusFluxo(idContrato) {
      this.workflowContratoResource.status({ idContrato })
        .then((response) => {
          this.contratoEdicao.fluxo = response.data;
          if (this.contratoEdicao.fluxo
            && this.contratoEdicao.fluxo.statusFluxo
            && (this.contratoEdicao.fluxo.statusFluxo === 'AGUARDANDO_APROVACAO'
            || this.contratoEdicao.fluxo.statusFluxo === 'EM_ANALISE'
            || this.contratoEdicao.fluxo.statusFluxo === 'REPROVADO')) {
            this.$emit('PlanejamentoContratoFormFluxoAprovacao__temFluxoContrato', true);
          }
        });
    },
  },
  mounted() {
    const { idContrato } = this.$route.params;
    if (idContrato) {
      this.verificaSePossuiFluxoAprovacao(idContrato);
    }
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-height: 800px) {
  .rodape-acoes {
    button {
      font-size: 0.88em !important;
    }
  }
}
</style>
