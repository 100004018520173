<template>
  <div>
    <fornecedor-varejo-form
      v-if="indModoVarejo"/>
    <fornecedor-industria-form
      v-else/>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';

import FornecedorIndustriaForm from '@/spa/fornecedor/industria/FornecedorIndustriaForm';
import FornecedorVarejoForm from './varejo/FornecedorVarejoForm';

export default {
  name: 'FornecedorForm',
  components: {
    FornecedorVarejoForm,
    FornecedorIndustriaForm,
  },
  computed: {
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
  },
};
</script>
