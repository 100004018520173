import getBasePath from '../functions/api-resource';

const basePath = getBasePath('negociacao', 'dashboard-negociacao');

const negociacaoActions = {
  listarDashNegociacoes: { method: 'GET', url: `${basePath}` },
  listarNegociacoes: { method: 'GET', url: `${basePath}/negociacoes` },
  listarTiposNegociacoes: { method: 'GET', url: `${basePath}/tipos-negociacoes` },
  listarMarcas: { method: 'GET', url: `${basePath}/marcas?marca={nome}` },
  listarFamilia: { method: 'GET', url: `${basePath}/familia?familia={nome}` },
  listarLinhaProduto: { method: 'GET', url: `${basePath}/linha-produto?linhaProduto={nome}` },
  listarPeriodos: { method: 'GET', url: `${basePath}/periodo` },
  statusEntidade: { method: 'GET', url: `${basePath}/status-entidade` },
  negociacoesAgAprovacao: { method: 'GET', url: `${basePath}/cliente` },
  periodosCliente: { method: 'GET', url: `${basePath}/cliente/periodos` },
};

export default (resource) => resource(`${basePath}`, {}, negociacaoActions);
