<template>
  <div>
    <v-row justify="start">
      <v-col cols="12" sm="6" md="3" class="ml-3">
        <v-text-field
          id="campo_pesquisar"
          v-model="searchQuery"
          @input="filtrar"
          append-icon="search"
          :label="$t('label.pesquisar')"
          single-line
          clearable
          hide-details>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3" align-self="center">
        <v-row>
          <v-tooltip bottom v-if="canAccessCRUD && tipoImportacao">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_importar" icon class="mx-0" v-on="on" @click="importarDados">
                <v-icon>backup</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.importar') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn id="btn_exportar" icon :disabled="bloquearBotaoExportacao" class="mx-0" v-on="on" @click="exportar()">
                <v-icon>get_app</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.exportar') }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="canAccessCRUD && selected.length">
            <template v-slot:activator="{ on }">
              <v-btn text icon id="btn_ativar_inativar" class="mx-0" v-on="on" @click="ativarInativar()">
                <v-icon>block</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.ativar_inativa') }}</span>
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>

    <v-data-table
      id="tabela_fornecedores"
      v-model="selected"
      :headers="headersDinamico"
      :items="fornecedores"
      :options.sync="pagination"
      show-select
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }"
      >
      <template v-slot:item="{ item, isSelected, select }">
        <tr :key="item.name" :class="{ 'TableRow--disabled': !item.indAtivo }">
          <td>
            <v-simple-checkbox :value="isSelected" @input="select($event)"></v-simple-checkbox>
          </td>
          <td class="justify-center px-0" style="width: 1%; text-align: center;">
            <v-tooltip bottom v-if="canAccessCRUD">
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click.stop="editar(item)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.editar') }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!canAccessCRUD">
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click="ver(item)">
                  <v-icon>equalizer</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.mais_detalhes') }}</span>
            </v-tooltip>
          </td>
          <td>{{ item.idExterno }}</td>
          <td>{{ item.desRazaoSocial }}</td>
          <td>{{ item.desNomeFantasia }}</td>
          <td>{{ item.desCnpj }}</td>
          <td>{{ item.tipoPessoa }}</td>
          <td v-for="(entidade, indice) in Object.keys(headersDinamicoObrigatorio)"
              v-bind:key="indice">
            {{ getNomesSeparadosPorVirgula(entidade, 'nomExtensao', item) }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FornecedorIndustriaContato from '../../../produto/spa/fornecedor/industria/FornecedorIndustriaContato';
import * as metadados from '../../../produto/common/functions/metadados';

export default {
  name: 'FornecedorIndustriaListDados',
  props: {
    canAccessCRUD: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fornecedorResources: this.$api.fornecedor(this.$resource),
      bloquearBotaoExportacao: false,
      fornecedores: [],
      fornecedorSelecionado: {},
      esperar: false,
      pagination: {},
      totalPage: 0,
      searchQuery: '',
      headers: [
        {
          text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
        { text: this.$tc('label.codigo', 1), value: 'id_externo', sortable: true },
        { text: this.$tc('label.razao_social', 1), value: 'des_razao_social', sortable: true },
        { text: this.$tc('label.nome_comercial', 1), value: 'des_nome_fantasia', sortable: true },
        { text: this.$tc('label.cpf_cnpj', 1), value: 'des_cnpj', sortable: true },
        { text: this.$tc('label.tipo_pessoa', 1), value: 'tipo_pessoa', sortable: true },
      ],
      vinculo: null,
      selected: [],
    };
  },
  components: {
    FornecedorIndustriaContato,
  },
  computed: {
    ...mapGetters('metadados', [
      'getFornecedorMetadado',
    ]),

    ...mapGetters('importacao', {
      tiposImportacao: 'getTiposImportacao',
    }),

    metadadosFornecedor() {
      if (this.getFornecedorMetadado && this.getFornecedorMetadado.mapaEntidades) {
        return this.getFornecedorMetadado;
      }
      return undefined;
    },

    headersDinamico() {
      const headers = Object.assign([], this.headers);
      if (!this.metadadosFornecedor) return headers;

      Object.keys(this.metadadosFornecedor.mapaEntidades).forEach((entidade) => {
        const mapeamentoEntidade = this.metadadosFornecedor.mapaEntidades[entidade];
        if (mapeamentoEntidade.depObrigatoria) {
          headers.push({
            text: mapeamentoEntidade.entidadeEstrangeira,
            value: mapeamentoEntidade.label,
            sortable: false,
          });
        }
      });

      return headers;
    },

    headersDinamicoObrigatorio() {
      const headers = {};
      Object.keys(this.metadadosFornecedor.mapaEntidades).forEach((entidade) => {
        const mapeamentoEntidade = this.metadadosFornecedor.mapaEntidades[entidade];
        if (mapeamentoEntidade.depObrigatoria) {
          headers[entidade] = mapeamentoEntidade;
        }
      });
      return headers;
    },

    tipoImportacao() {
      return this.tiposImportacao.find((el) => el.nome === 'importacao_forca_vendas');
    },
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },

    searchQuery() {
      this.pagination.page = 1;
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  methods: {
    ...metadados,
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },

    buscar() {
      const params = {
        filter: this.searchQuery,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.fornecedorResources.listarFiltrado(params).then((response) => {
        // this.montarObjetoFornecedor(response.data.resposta);
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.fornecedores = response.data.resposta;
        this.pararEsperar();
        this.fornecedores.forEach((k) => {
          if (k.tipoPessoa === 'JURIDICA') {
            k.tipoPessoa = 'JURÍDICA';
          } else {
            k.tipoPessoa = 'FÍSICA';
          }
        });
        this.selected = [];
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },

    montarObjetoFornecedor(dados) {
      this.fornecedores = dados
        .map((data) => metadados.montarEstruturaDinamica(data, this.getFornecedorMetadado, data));
    },

    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },

    editar(item) {
      this.$router.push({ name: 'editarFornecedor', params: { id: item.id } });
    },

    getSearchQuery() {
      return this.searchQuery;
    },

    ver(item) {
      this.$router.push({ name: 'verFornecedor', params: { id: item.id, somenteLeitura: true } });
    },

    ativarInativar() {
      this.fornecedorResources.ativarMassa(this.selected)
        .then(() => {
          this.filtrar();
          this.$toast(this.$t('message.atualizado_confira_tabela'));
        }, (err) => {
          this.$error(this, err);
        });
    },

    importarDados() {
      this.$emit('TABELA_IMPORTAR_DADOS');
    },

    exportar() {
      this.bloquearBotaoExportacao = true;
      this.$emit('TABELA_EXPORTAR_DADOS',
        () => { this.bloquearBotaoExportacao = false; });
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
