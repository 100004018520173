import getBasePath from '../functions/api-resource';

const basePath = getBasePath('negociacao', 'contrato');

const contratoActions = {
  atualizar: { method: 'PUT', url: `${basePath}` },
  gravar: { method: 'POST', url: `${basePath}` },
  listarClientes: { method: 'GET', url: `${basePath}/clientes` },
  listarContratos: { method: 'GET', url: `${basePath}?descricao={descricao}` },
  obter: { method: 'GET', url: `${basePath}/{id}` },
  upload: { method: 'POST', url: `${basePath}/arquivo/{id}/upload` },
};

export default (resource) => resource(`${basePath}`, {}, contratoActions);
