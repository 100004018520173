<template lang="html">
  <v-card class="mb-3">
    <v-card-title>
      <div class="title"
        v-text="`${objetoRateio.descricao} - ${unidadeNegocio} - ${periodoOrcamentario}`"/>
      <v-spacer></v-spacer>
      <v-icon v-show="detalhesAbertos"
        @click="fixaDetalhes"
        :class="detalhesFixosClass"
        style="margin-right: 10px">
        aspect_ratio
      </v-icon>
      <v-icon v-show="!detalhesAbertos" @click="toggleDetalhes">keyboard_arrow_down</v-icon>
      <v-icon v-show="detalhesAbertos" @click="toggleDetalhes">keyboard_arrow_up</v-icon>
    </v-card-title>
    <v-container fluid grid-list-md v-show="detalhesAbertos">
      <metadados-container-layout
        v-if="parametrosAplicados"
        :metadados-entidade="metadadosParametrizado"
        :layout-class="layoutClass"
        :input-layout="inputLayout"
        :ignora-obrigatorios="true"
        :objeto="objetoRateio"
        :campos-formulario="camposFormulario"
        :ordenacao-campos="ordenacaoFormulario"
        :explode-hierarquia="true"
        :somente-leitura="true">
        <template v-slot:antes>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              :label="$t('label.tipo_verba')"
              v-model="configuracao.nome"
              disabled>
            </v-text-field>
          </v-col>
        </template>
      </metadados-container-layout>
    </v-container>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';

import { skipLoading } from '../../../common/functions/loading';
import MetadadosContainerLayout from '../../../shared-components/metadados/MetadadosContainerLayout';

export default {
  name: 'OrcamentoRateioDetalhes',
  props: {
    rateio: Object,
    configuracao: Object,
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      extensaoResources: this.$api.extensao(this.$resource),
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      ordenacaoFormulario: this.configuracao.colunasResumo,
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'descricao',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'planejado',
            tipoCampo: 'DINHEIRO',
          },
          {
            labelCampo: 'saldo',
            tipoCampo: 'DINHEIRO',
          },
          {
            labelCampo: 'adendo',
            tipoCampo: 'DINHEIRO',
          },
          {
            labelCampo: 'reducao',
            tipoCampo: 'DINHEIRO',
          },
          {
            labelCampo: 'reservado',
            tipoCampo: 'DINHEIRO',
          },
          {
            labelCampo: 'comprometido',
            tipoCampo: 'DINHEIRO',
          },
          {
            labelCampo: 'apurado',
            tipoCampo: 'DINHEIRO',
          },
          {
            labelCampo: 'consumido',
            tipoCampo: 'DINHEIRO',
          },
          {
            labelCampo: 'status',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'remanescente',
            tipoCampo: 'DINHEIRO',
          },
          {
            labelCampo: 'centro_custo',
            nomCampoId: 'idCentroCusto',
            tipoCampo: 'LISTA',
            async: {
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'cliente',
            nomCampoId: 'idCliente',
            tipoCampo: 'LISTA',
            async: {
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'produto',
            nomCampoId: 'idProduto',
            tipoCampo: 'LISTA',
            async: {
              itemValue: 'id',
              itemText: 'codigoNome',
            },
          },
        ],
      },

      parametrosAplicados: false,
      metadadosParametrizado: null,

      objetoRateio: {},

      detalhesAbertos: false,
      detalhesFixados: false,

      workspace: {},
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getOrcamentoMetadado',
    ]),
    detalhesFixosClass() {
      return {
        'primary--text': this.detalhesFixados,
      };
    },
    unidadeNegocio() {
      return this.objetoRateio.unidadenegocio
        ? this.objetoRateio.unidadenegocio.nomExtensao
        : '';
    },
    periodoOrcamentario() {
      const { periodo, periodoOrcamentario } = this.objetoRateio;
      if (!periodo) {
        return '';
      }
      return `${periodo.descricao} / ${periodoOrcamentario.map((p) => p.descricao).join(', ')}`;
    },
  },
  methods: {
    carregarWorkspaceFiltros() {
      const entidade = 'rateio';
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          this.detalhesAbertos = this.workspace.indAberto || false;
          this.detalhesFixados = this.workspace.indAberto || false;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    toggleDetalhes() {
      this.detalhesAbertos = !this.detalhesAbertos;
    },
    fixaDetalhes() {
      this.detalhesFixados = !this.detalhesFixados;
      this.workspace.indAberto = this.detalhesFixados;

      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'rateio';
      this.workspaceFiltrosResources
        .inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'rateio';
      this.workspaceFiltrosResources
        .atualizar({ entidade }, this.workspace)
        .catch((err) => {
          this.$error(this, err);
        });
    },
    montaObjetoRateio() {
      this.objetoRateio = {
        ...this.rateio,
        ...this.rateio.mapaExtensoes,
        ...this.rateio.mapaCamposDinamicos,
      };
      const { status } = this.objetoRateio;
      this.objetoRateio.status = this.$tc(`status_entidade.${status.toLowerCase()}`, 1);
    },
  },
  mounted() {
    const metadados = this.getOrcamentoMetadado;
    this.metadadosParametrizado = this.configuracao.aplicaParametrizacaoResumo(metadados);
    this.montaObjetoRateio();
    this.carregarWorkspaceFiltros();
    this.parametrosAplicados = true;
  },
};
</script>
