<template>
  <v-dialog v-model="dialogRecursao" persistent max-width="600">
    <v-card v-if="entidadeRecursivaEmFiltro">
      <v-card-title>
        <span class="headline">
          {{ $t('title.filtros_da_entidade', { entidade: entidadeRecursivaEmFiltro.entidadeEstrangeira }) }}
        </span>
      </v-card-title>
      <v-card-text>
        <metadados-extensao v-for="(metadados, index) in dependenciasRecursivas"
          :metadados="metadados"
          v-model="filtroRecursao[metadados.label]"
          :objeto-container="objeto"
          :key="index"
          :get-valores-dependencias="getValoresDependencias"
          :ignora-filtro-recursivo="true"
          :ignora-obrigatorios="true"
          @MetadadosExtensao_ValorSelecionado="valorSelecionado" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click.native="cancelarFiltroAmostragem">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="accent" @click.native="aplicarFiltroAmostragem" :disabled="!campoFiltrado()">
          {{ $t(`label.filtrar`) }}
          <v-icon right dark>filter_list</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import MetadadosExtensao from './MetadadosExtensao';

export default {
  components: {
    MetadadosExtensao,
  },
  props: {
    metadadosEntidade: {
      type: Object,
      default: {},
    },
    objeto: Object,
    callbackFiltro: Function,
  },
  data() {
    return {
      dialogRecursao: false,
      entidadeRecursivaEmFiltro: null,
      dependenciasRecursivas: [],

      filtroRecursao: {},
    };
  },
  computed: {
    raizRecursao() {
      const [raiz] = this.dependenciasRecursivas
          .filter((dependencia) => dependencia.indiceRecursao === 0),
        { label } = raiz;
      return label;
    },
  },
  methods: {
    filtraAmostragemRecursiva(metadadosExtensao) {
      this.entidadeRecursivaEmFiltro = metadadosExtensao;
      this.determinaDependenciasRecursivas();

      this.dialogRecursao = true;
      setTimeout(() => this.carregaValoresPrevios());
    },
    carregaValoresPrevios() {
      const filtro = this.filtroRecursao,
        { objeto } = this;

      this.dependenciasRecursivas
        .filter((dependencia) => {
          const { label } = dependencia,
            valor = objeto[label];
          return valor != null && Object.keys(valor).length !== 0;
        })
        .forEach((dependencia) => {
          const { label } = dependencia;
          if (dependencia.manyToMany) {
            filtro[label] = [...objeto[label]];
          } else {
            filtro[label] = { ...objeto[label] };
          }
        });

      this.filtroRecursao = { ...filtro };
    },
    campoFiltrado() {
      const entidade = this.filtroRecursao[this.raizRecursao];
      return entidade != null && Object.keys(entidade) !== 0;
    },
    valorSelecionado(alteracao) {
      const { dependentes, label } = alteracao.metadados,
        { selecao } = alteracao,
        [dependente] = dependentes;

      if (selecao) {
        if (alteracao.metadados.manyToMany) {
          this.filtroRecursao[label] = [...selecao];
        } else {
          this.filtroRecursao[label] = { ...selecao };
        }
      } else {
        delete this.filtroRecursao[label];
      }

      this.limpaDependentes(dependente);
    },
    limpaDependentes(dependencia) {
      const listaDependentes = this.getDependentes(dependencia);
      const objeto = this.filtroRecursao;
      listaDependentes.forEach((entidade) => {
        delete objeto[entidade.label];
      });
      this.filtroRecursao = { ...objeto };
    },
    getValoresDependencias(dependente) {
      if (!dependente) return [];

      const listaDependencias = this.getDependencias(dependente),
        valores = [],
        objeto = this.filtroRecursao;

      listaDependencias
        .filter((entidade) => {
          const { label } = entidade;
          return objeto[label] && Object.keys(objeto[label]).length !== 0;
        })
        .forEach((entidade) => {
          const { label, idNivelExtensao, indiceRecursao } = entidade;
          const { id } = objeto[label];
          valores.push({ id, idNivelExtensao, indiceRecursao });
        });

      if (valores.length === 0) {
        return [];
      }

      let menorIndiceRecursao = Number.MAX_SAFE_INTEGER;
      let menorDependencia = null;
      valores.forEach((valor) => {
        if (valor.indiceRecursao < menorIndiceRecursao) {
          menorIndiceRecursao = valor.indiceRecursao;
          menorDependencia = valor;
        }
      });
      return [menorDependencia];
    },
    getDependentes(dependencia) {
      const listaDependentes = [];
      const dependencias = [...this.dependenciasRecursivas];
      let referencia = dependencia;

      while (referencia !== null) {
        const [dependente] = dependencias
          .filter((dependenciaRec) => { // eslint-disable-line no-loop-func
            const { label } = dependenciaRec;
            return referencia === label;
          });

        if (dependente) {
          listaDependentes.push(dependente);
          const [ref] = dependente.dependentes;
          referencia = ref;
        } else {
          referencia = null;
        }
      }

      return listaDependentes;
    },
    getDependencias(dependente) {
      const listaDependencias = [];
      const dependencias = [...this.dependenciasRecursivas];
      let referencia = dependente;

      while (referencia !== null) {
        const [dependencia] = dependencias
          .filter((dependenciaRec) => { // eslint-disable-line no-loop-func
            const { label } = dependenciaRec;
            return referencia === label;
          });

        if (dependencia) {
          listaDependencias.push(dependencia);
          const [ref] = dependencia.dependencias;
          referencia = ref;
        } else {
          referencia = null;
        }
      }

      return listaDependencias;
    },
    determinaDependenciasRecursivas() {
      const metadados = this.metadadosEntidade,
        { entidade } = metadados;

      const dependenciasIndiretas = Object.values(metadados.mapaEntidades)
        .filter((dependencia) => dependencia.dependentes.indexOf(entidade) < 0);

      const eleitasFiltro = {};

      let dependente = this.entidadeRecursivaEmFiltro.label;
      do {
        const [novaEleita] = dependenciasIndiretas
        // eslint-disable-next-line no-loop-func
          .filter((dependencia) => dependencia.dependentes.indexOf(dependente) >= 0)
          .filter((dependencia) => !eleitasFiltro[dependencia.label]);
        eleitasFiltro[novaEleita.label] = novaEleita;

        if (novaEleita.dependencias.length > 0) {
          dependente = novaEleita.label;
        } else {
          dependente = null;
        }
      } while (dependente);

      this.dependenciasRecursivas = [
        this.entidadeRecursivaEmFiltro,
        ...Object.values(eleitasFiltro),
      ].reverse();
    },
    aplicarFiltroAmostragem() {
      const copia = { ...this.filtroRecursao };
      this.callbackFiltro(copia);

      this.dialogRecursao = false;
      this.entidadeRecursivaEmFiltro = null;
      this.filtroRecursao = {};
    },
    cancelarFiltroAmostragem() {
      this.dialogRecursao = false;
      this.entidadeRecursivaEmFiltro = null;
      this.filtroRecursao = {};
    },
  },
};
</script>
