export default class Passo2 {
  constructor(id, listaCliente = [], listaFornecedor = [], listaProduto = [], listaPadrao = [],
    listaDinamica = [], listaCarteiraCliente = [], indCalculoAutomatico = false, indDescricaoAutomatica = false, template, valor = '') {
    this.id = id;
    this.listaCliente = listaCliente;
    this.listaFornecedor = listaFornecedor;
    this.listaProduto = listaProduto;
    this.listaPadrao = listaPadrao;
    this.listaDinamica = listaDinamica;
    this.listaCarteiraCliente = listaCarteiraCliente;
    this.indCalculoAutomatico = indCalculoAutomatico;
    this.indDescricaoAutomatica = indDescricaoAutomatica;
    this.template = template;
    this.valor = valor;
  }

  static setListaDinamica(listaDinamica) {
    this.listaDinamica = listaDinamica;
  }
}
