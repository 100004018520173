export default class Passo2 {
  constructor(id, indAcessoCliente = false, listaDivisao = [], listaEstrutura = [],
    indHabilitarCalculoRoi = false, indPermitirClienteVisualizarRoi = false) {
    this.id = id;
    this.indAcessoCliente = indAcessoCliente;
    this.listaDivisao = listaDivisao;
    this.listaEstrutura = listaEstrutura;
    this.indHabilitarCalculoRoi = indHabilitarCalculoRoi;
    this.indPermitirClienteVisualizarRoi = indPermitirClienteVisualizarRoi;
  }
}
