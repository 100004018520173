var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"headline"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$tc('label.apuracao', 1)))]),_c('popover-lista',{attrs:{"objeto":_vm.acaoFormatada,"campos":_vm.listaCamposPopover()}})],1)]),_c('v-expansion-panel-content',[_c('card-info',{attrs:{"campos":_vm.listaCampos(),"objeto":_vm.acaoFormatada}},[_c('template',{slot:"antes"},[(_vm.acao.metas)?_c('v-col',{staticClass:"pr-2",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":400,"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-select',{staticClass:"select--sem--linha",attrs:{"items":_vm.items,"append-icon":"","label":_vm.$tc('label.meta', 1),"multiple":"","readonly":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-icon',_vm._g({},on),[_vm._v(_vm._s(data.item))])]}}],null,true),model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})]}}],null,false,1532818691),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers(),"items":_vm.acao.metas,"hide-default-footer":"","no-data-text":_vm.$t('label.tabela_sem_conteudo')},scopedSlots:_vm._u([{key:"item.meta_de",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.exibirMeta(item.vlrMetaDe))}})]}},{key:"item.meta_ate",fn:function(ref){
var item = ref.item;
return [(item.vlrMetaAte)?_c('span',{domProps:{"textContent":_vm._s(_vm.exibirMeta(item.vlrMetaAte))}}):_vm._e()]}},{key:"item.bonificacao_pagamento",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.exibirPagamento(item.vlrBonificacao))}})]}}],null,false,2927588700)})],1)],1)],1):_vm._e()],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }