<template>
  <div>
    <dashboard-inicial-varejo
      v-if="indModoVarejo"/>
    <dashboard-inicial-industria
      v-else/>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';

import DashboardInicialVarejo from './varejo/DashInicialVarejo';
import DashboardInicialIndustria from './industria/DashInicialIndustria';

export default {
  name: 'DashboardInicial',
  components: {
    DashboardInicialVarejo,
    DashboardInicialIndustria,
  },
  computed: {
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
  },
};
</script>
