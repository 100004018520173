import getBasePath from '../../../produto/common/functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'parametrizacao-periodo');

const grupoActions = {
  obterPasso3: { method: 'GET', url: `${basePath}/passo3/{id}` },
  salvarRascunhoPasso3: { method: 'PUT', url: `${basePath}/passo3/{id}/rascunho` },
  salvarPasso3: { method: 'PUT', url: `${basePath}/passo3/{id}` },
  buscarIndicadores: { method: 'GET', url: `${basePath}/passo3/indicadores` },
  buscarDivisoes: { method: 'GET', url: `${basePath}/passo3/divisao` },
  buscarExtensoes: { method: 'GET', url: `${basePath}/passo3/extensao` },
  buscarRating: { method: 'GET', url: `${basePath}/passo3/rating` },
};

export default (resource) => resource(`${basePath}`, {}, grupoActions);
