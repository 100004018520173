import VueI18n from 'vue-i18n';
import languages from '../common/i18n';

function interpolarDicionario(resDicionario) {
  const lang = languages.messages['pt-br'];
  resDicionario.forEach((e) => {
    const label = e.label.split('.');
    let valor;
    for (let i = 0; i < label.length - 1; i += 1) {
      if (i === 0) {
        if (!lang[label[i]]) lang[label[i]] = {};
        valor = lang[label[i]];
      } else {
        if (!valor[label[i]]) valor[label[i]] = {};
        valor = valor[label[i]];
      }
    }
    valor[label[label.length - 1]] = e.depara;
  });
  languages.messages['pt-br'] = lang;
  return languages;
}

export default (Vue, resDicionario) => {
  Vue.use(VueI18n);
  return new VueI18n(interpolarDicionario(resDicionario));
};
