import Rebate from './Rebate';

export default class Passo6 {
  constructor(passo6 = {}) {
    this._rebates = (passo6.rebates || []).map((c) => new Rebate(c));
  }

  get rebates() {
    return this._rebates;
  }

  set rebates(value) {
    this._rebates = value;
  }

  get estaConfigurado() {
    return this._rebates.filter((cla) => cla.rebateConfigurado).length > 0;
  }

  get idsExtensoesRebate() {
    const idsExtensoesRebate = {};

    this._rebates
      .flatMap((c) => c.idsExtensoesRebate)
      .forEach((ids) => {
        Object.keys(ids).forEach((k) => {
          if (!idsExtensoesRebate[k]) {
            idsExtensoesRebate[k] = ids[k];
          } else {
            idsExtensoesRebate[k] += ` ; ${ids[k]}`;
          }
        });
      });

    return idsExtensoesRebate;
  }

  removerRebate(rebate) {
    for (let i = 0; i < this._rebates.length; i += 1) {
      if (this._rebates[i].idTipoInvestimentos === rebate.idTipoInvestimentos) {
        this._rebates[i].rebatesConfiguracao = [];
        this._rebates.splice(i, 1);
      }
    }
  }

  toJSON() {
    return {
      idPeriodoConfiguracao: this._idPeriodoConfiguracao,
      rebates: this._rebates,
    };
  }
}
