export default class Passo1 {
  constructor(data = {}) {
    this.id = data.id;
    this.nome = data.nome;
    this.descricao = data.descricao;
    this.icone = data.icone;
    this.fluxo = data.fluxo;
    this.tiposAcao = data.tiposAcao || [];
    this.indPermiteDuplicidadeAcao = data.indPermiteDuplicidadeAcao;
  }

  ehNovo() {
    return !this.id;
  }

  setId(id) {
    this.id = id;
  }
}
