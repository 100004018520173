import getBasePath from '../functions/api-resource';

const basePathPlanoComparativo = getBasePath('relatorio', 'plano-comparativo');
const basePathPartInvest = getBasePath('relatorio', 'participacao-investimento');
const baseAnaliseInvest = getBasePath('relatorio', 'analise-investimento');
const basePathFechamento = getBasePath('relatorio', 'fechamento');
const basePathAcompanhamento = getBasePath('relatorio', 'acompanhamento');
const basePathGerencial = getBasePath('relatorio', 'gerencial');
const basePathSimuladorInvestimento = getBasePath('relatorio', 'simulador-investimento');

const apuracaoActions = {
  listarPlanoComparativoMarcas: { method: 'POST', url: `${basePathPlanoComparativo}/marcas` },
  listarPlanoComparativoHierarquiaUsuario: { method: 'POST', url: `${basePathPlanoComparativo}/hierarquia-usuario` },

  listarConsolidado: { method: 'POST', url: `${basePathPartInvest}/consolidado` },
  listarPartInvestTipoHolding: { method: 'POST', url: `${basePathPartInvest}/holding` },
  listarPartInvestTipoCliente: { method: 'POST', url: `${basePathPartInvest}/cliente` },
  listarPartInvestTipoNegociacaoMarca: { method: 'POST', url: `${basePathPartInvest}/tipo-negociacao-marca` },

  listarClientes: { method: 'GET', url: `${basePathGerencial}/clientes` },
  listarContratoAcordo: { method: 'GET', url: `${basePathGerencial}/contrato-acordo` },
  listarTipoAcao: { method: 'GET', url: `${basePathGerencial}/tipo-acao` },
  listarTipoInvestimento: { method: 'GET', url: `${basePathGerencial}/tipo-investimento` },
  listarTipoAcordo: { method: 'GET', url: `${basePathGerencial}/tipo-acordo` },

  listarAnaliseInvestHistorico: { method: 'POST', url: `${baseAnaliseInvest}/historico` },
  listarAnaliseInvestMarca: { method: 'POST', url: `${baseAnaliseInvest}/marca` },

  savingResumo: { method: 'POST', url: `${basePathFechamento}/saving/resumo` },
  savingDetalhe: { method: 'POST', url: `${basePathFechamento}/saving/detalhe` },

  listarAcompanhamentoNegociacoes: { method: 'POST', url: `${basePathAcompanhamento}/negociacao` },
  listarAcompanhamentoApuracoes: { method: 'POST', url: `${basePathAcompanhamento}/apuracao` },

  listarSimuladorInvestimentoGrid: { method: 'POST', url: `${basePathSimuladorInvestimento}/grid` },
  listarSimuladorInvestimentoGrafico: { method: 'POST', url: `${basePathSimuladorInvestimento}/grafico` },
};

export default (resource) => resource('', {}, apuracaoActions);
