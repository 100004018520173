<template>
  <div>
    <span class="title">{{ titulo }}</span>
    <v-card tile class="my-3">
      <v-list-item two-line v-for="(periodo, index) in periodos" :key="index">
        <v-list-item-content>
          <v-list-item-title>{{ periodo.descricao }}</v-list-item-title>
          <v-list-item-subtitle>{{ `${formatDate(periodo.dataInicio)} ${$tc('label.a', 1)} ${formatDate(periodo.dataFim)}` }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ListaPeriodos',
  props: {
    titulo: '',
    periodos: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formatDate(value) {
      return this.moment(value, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YYYY');
    },
  },
};
</script>
