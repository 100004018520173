<template>
  <div>
    <v-container fluid v-if="objetoPassoGeral && apuracao">
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <planejamento-dpm-resumo
            :exibe-cliente-pagador="exibeClientePagador"
            :objeto-passo-geral="objetoPassoGeral"
            :ordenacao-formulario="ordenacaoFormulario"
            :contrato-edicao="contratoEdicao"
            :novo-contrato="false"
            :somente-leitura="true"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <apuracao-dpm-plano-metas
            :plano-metas="apuracao.planoMetas"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <apuracao-dpm-grupos-cultura
            :grupos-cultura="apuracao.gruposCultura"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <apuracao-dpm-produtos-complementares
            :produtos-complementares="apuracao.produtosComplementares"
            />
        </v-col>
      </v-row>
    </v-container>
    <confirm
      ref="dialogApuracao"
      :message="$t('message.apuracao_nao_calculada')"
      :persistent="true"
      hide-disagree
      :agree-label="$t('label.ok')"
      @agree="confirmar">
    </confirm>
  </div>
</template>
<script>
import Confirm from '../../../../produto/shared-components/vuetify/dialog/Confirm';
import PlanejamentoDpmResumo from '../passo-acoes/PlanejamentoDpmResumo';
import ApuracaoDpmPlanoMetas from './ApuracaoDpmPlanoMetas';
import ApuracaoDpmGruposCultura from './ApuracaoDpmGruposCultura';
import ApuracaoDpmProdutosComplementares from './ApuracaoDpmProdutosComplementares';

export default {
  props: {
    edicao: Boolean,
    somenteLeitura: Boolean,
    contratoEdicao: Object,
    tipoContrato: {
      type: Object,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
    getObjetoOutroPasso: {
      type: Function,
      required: true,
    },
  },
  components: {
    PlanejamentoDpmResumo,
    ApuracaoDpmPlanoMetas,
    ApuracaoDpmGruposCultura,
    ApuracaoDpmProdutosComplementares,
    Confirm,
  },
  computed: {
    exibeClientePagador() {
      return this.configuracao.habilitaClientePagador;
    },
    ordenacaoFormulario() {
      const { relacaoCampos } = this.configuracao;
      return relacaoCampos;
    },
    tituloTipoContrato() {
      return this.configuracao.nome;
    },
  },
  data() {
    return {
      planoMetasResource: this.$api.apuracaoPlanoMetas(this.$resource),
      apuracao: null,
      objetoPassoGeral: null,
    };
  },
  methods: {
    getObjetoPasso() {
      return this.apuracao;
    },
    abrePasso() {
      this.objetoPassoGeral = this.getObjetoOutroPasso('geral');

      if (!this.apuracao) {
        this.buscaDadosApuracao();
      }
    },
    buscaDadosApuracao() {
      const { id } = this.contratoEdicao;
      this.planoMetasResource.buscaDadosApuracao({ idContrato: id })
        .then((res) => {
          const dados = { ...res.data };
          if (dados.apuracao === false) {
            this.$refs.dialogApuracao.open();
            return;
          }
          this.apuracao = { ...res.data };
        });
    },
    confirmar() {
      this.$emit('PLANEJAMENTO_CONTRATO_PASSO_ANTERIOR');
    },
  },
};
</script>
