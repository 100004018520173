var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',{staticClass:"ApuracaoDpmGrupoCulturasPainel"},[_c('v-expansion-panel-header',{staticClass:"pb-0 pt-0"},[_c('span',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.grupo.grupoCultura)+" ")])]),_c('v-expansion-panel-content',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.cabecalho,"items":_vm.grupo.marcas,"dense":"","disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.pontoUnitario",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontoUnitario))+" ")]}},{key:"item.volumeMeta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.volumeMeta))+" ")]}},{key:"item.volumeFaturado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.volumeFaturado))+" ")]}},{key:"item.volumeFatCart",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.volumeFatCart))+" ")]}},{key:"item.pontosMeta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontosMeta))+" ")]}},{key:"item.pontosFaturamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontosFaturamento))+" ")]}},{key:"item.pontosFatCart",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontosFatCart))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"},attrs:{"colspan":"5"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totalVolumeMeta))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totalVolumeFaturado))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totalVolumeFatCart))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totalPontosMeta))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totalPontosFaturamento))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totalPontosFatCart))+" ")])])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }