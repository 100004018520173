import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('apuracao_acao', 'edicao');

const apuracaoActions = {
  atualizar: { method: 'PUT', url: `${basePath}` },
  buscarJustificativaAlteracaoValor: { method: 'GET', url: `${basePath}/{idApuracao}/justificativa-alteracao-valor` },
  validaSaldoContaUnica: { method: 'GET', url: `${basePath}/{idApuracao}/validacao/saldo` },
  buscaContasVinculadas: { method: 'GET', url: `${basePath}/{idApuracao}/contas` },
  atualizarDesAtributos: { method: 'PUT', url: `${basePath}/des_atributos` },
  atualizarComplementoInformacoes: { method: 'PUT', url: `${basePath}/complemento_informacoes` },
  resgatar: { method: 'PUT', url: `${basePath}/{idApuracao}/resgate` },
  concluirApuracao: { method: 'PUT', url: `${basePath}/{idApuracao}/concluir` },
  alterarDataFim: { method: 'PUT', url: `${basePath}/{idApuracao}/data-fim` },
  cancelar: { method: 'PUT', url: `${basePath}/{idApuracao}/cancelar` },
};

export default (resource) => resource(`${basePath}`, {}, apuracaoActions);
