export default class Passo2 {
  constructor(passo2 = {}) {
    this._marcas = passo2.marcas || [];
    this._grupoCulturas = passo2.grupoCulturas || [];
    this._marcasSelecao = this._marcas.map((marca) => {
      const marcaSelecao = { ...marca };
      delete marcaSelecao.id;
      return marcaSelecao;
    });
  }

  get grupoCulturas() {
    return this._grupoCulturas;
  }

  set grupoCulturas(value) {
    this._grupoCulturas = value;
  }

  get marcas() {
    return this._marcas;
  }

  set marcas(value) {
    this._marcas = value;
  }

  get marcasSelecao() {
    return this._marcasSelecao;
  }

  set marcasSelecao(value) {
    this._marcasSelecao = value;
  }

  toJSON() {
    return {
      marcas: this._marcas,
      grupoCulturas: this._grupoCulturas,
    };
  }
}
