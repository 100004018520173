<template>
  <div class="PlanejamentoAcaoForm_Rodape">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="12" lg="6" class="pa-0">
        </v-col>
        <planejamento-acao-form-acoes-fluxo-novo
          ref="componenteFluxo"
          :configuracao="configuracao"
          :acao-concluir="concluirAcao"
          :valida-concluir-acao="validaConcluirAcao"
          :permite-salvar="permiteSalvar"
          :acao-edicao="acaoEdicao"
          :acao-salvar="salvar"
          :upload-evidencia-obrigatorio="uploadEvidenciaObrigatorio"
          :ind-tem-evidencia="indTemEvidencia"
          :edicao="edicao">
          <v-btn slot="acoes-padrao" @click="cancelar(true)" class="mr-3">
            {{ $t('label.voltar') }}
          </v-btn>
          <v-btn slot="acoes-padrao" @click="salvarRecarregar()"
            v-if="permiteSalvar"
            class="mr-3"
            dark
            color="primary"
            :disabled="!permiteSalvar"
            :outlined="permiteConcluir">
            {{ $t('label.salvar') }}
          </v-btn>
          <v-btn slot="acoes-padrao" @click="concluirAcao()"
            v-if="permiteSalvar"
            class="mr-3"
            dark
            :color="permiteConcluir ? 'primary' : 'grey'">
            {{ $t('label.concluir') }}
          </v-btn>
        </planejamento-acao-form-acoes-fluxo-novo>
      </v-row>
    </v-container>
    <confirm
      ref="cancelamentoDialog"
      :persistent="true">
    </confirm>
    <confirm
      ref="confirmDialog"
      :persistent="true">
    </confirm>
  </div>
</template>
<script>
import PlanejamentoAcaoFormAcoesFluxoNovo from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormAcoesFluxoNovo';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import AcaoComponenteEvento from './passos-dinamicos/AcaoComponenteEvento';

export default {
  props: {
    definicaoPassos: Object,

    edicao: Boolean,
    somenteLeitura: Boolean,
    acaoEdicao: Object,

    getComponentesPassos: Function,

    callbackAcao: {
      type: Function,
      default: null,
    },

    validaComponentePasso: Function,
    validaComponentePassoSalvar: Function,

    indTemEvidencia: Boolean,
    usuarioCliente: Boolean,
    usuarioClientePodeAcessarPassoContas: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
  },
  components: {
    Confirm,
    PlanejamentoAcaoFormAcoesFluxoNovo,
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      stepForm: 1,
      avancaPasso: () => {},
    };
  },
  computed: {
    exibeAnterior() {
      return this.stepForm !== 1;
    },
    sequenciaPassos() {
      return Object.values(this.passosHabilitados)
        .filter((passo) => passo.habilitado)
        .map((passo) => passo.label);
    },
    passosHabilitados() {
      return this.definicaoPassos.passosHabilitados(this, this.configuracao.configuracao, this.acaoEdicao);
    },
    uploadEvidenciaObrigatorio() {
      return this.configuracao
        && this.configuracao.configuracao
        && this.configuracao.configuracao.passo1
        && this.configuracao.configuracao.passo1.uploadObrigatorio;
    },
    permiteSalvar() {
      if (this.acaoEdicao) {
        if (this.acaoEdicao.passoGeral) {
          return !!(this.acaoEdicao.passoGeral.status === 'EM_CADASTRO');
        }
      }
      return true;
    },
    permiteConcluir() {
      if (this.acaoEdicao) {
        if (this.acaoEdicao.passoGeral) {
          if (this.uploadEvidenciaObrigatorio) {
            return (this.acaoEdicao.passoGeral.status === 'EM_CADASTRO') && (this.indTemEvidencia);
          }
          return true;
        }
      }
      if (this.uploadEvidenciaObrigatorio) {
        return false;
      }
      return true;
    },
  },
  methods: {
    carregaInformacoesFluxo(idAcao) {
      this.$refs.componenteFluxo.carregaInformacoesFluxo(idAcao);
    },
    validaAntesDeAvancar() {
      return this.validaComponentePasso();
    },
    carregarEdicao() {
      const evento = new AcaoComponenteEvento(
        'carregarEdicao',
      );
      this.$emit('PlanejamentoAcaoForm__executarEventoEncadeado', evento);
    },
    cancelar(forceVoltaParaLista = false) {
      if (this.somenteLeitura) {
        this.$router.push({
          name: this.$route.params.from || 'planejamento-acao',
        });
        return;
      }

      this.$refs.cancelamentoDialog
        .openWithParams(
          this.$t('title.atencao'),
          this.$t('message.deseja_cancelar_edicao'),
          (ok) => {
            if (ok) {
              if (forceVoltaParaLista) {
                this.$router.push({
                  name: 'planejamento-acao',
                });
              } else {
                this.$router.push({
                  name: this.$route.params.from || 'planejamento-acao',
                });
              }
            }
          },
        );
    },
    validaAntesDeSalvar(isSolicitandoAprovacao) {
      return this.validaComponentePassoSalvar(isSolicitandoAprovacao);
    },
    salvarRecarregar() {
      this.salvar()
        .then((idAcao) => {
          if (this.callbackAcao) {
            this.callbackAcao(idAcao);
          }
          this.alternaRota(idAcao);
        })
        .catch((err) => {
          if (err) {
            this.$toast(err.data.error);
          }
        });
    },
    salvar(isSolicitandoAprovacao = false) {//eslint-disable-line
      const acao = this.formataAcaoParaEnvio();

      if (!this.edicao) {
        return this.salvarNovo(acao);
      }
      return this.atualizar(acao);
    },
    validaConcluirAcao() {
      if (!this.validaAntesDeAvancar()) {
        return false;
      }
      if (!this.validaAntesDeSalvar(false)) {
        return false;
      }
      return true;
    },
    concluirAcao() {
      if (!this.permiteConcluir) {
        return false;
      }
      if (!this.validaAntesDeAvancar()) {
        return false;
      }
      if (!this.validaAntesDeSalvar(false)) {
        return false;
      }
      this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          'Deseja enviar as informações inseridas no formulário para aprovação?',
          (ok) => {
            if (!ok) {
              return false;
            }
            const acao = this.formataAcaoParaEnvio();
            this.planejamentoAcaoResource.concluirAcao(acao)
              .then((res) => this.alternaRota(res.data))
              .catch((err) => {
                this.$toast(err.data.error);
              });
            return true;
          },
        );
      return false;
    },
    formataAcaoParaEnvio() {
      const componentes = this.getComponentesPassos();
      const acao = {
        id: this.acaoEdicao.id,
      };
      Object.keys(componentes).forEach((k) => {
        const componente = componentes[k];
        if (componente[0].preencherObjetoFormatado) {
          componente[0].preencherObjetoFormatado(acao);
        }
      });
      return acao;
    },
    salvarNovo(acao) {
      return this.planejamentoAcaoResource.salvarAcao(acao)
        .then((res) => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          const idAcao = res.data;
          return idAcao;
        });
    },
    atualizar(acao) {
      return this.planejamentoAcaoResource.atualizarAcao(acao)
        .then((res) => {
          this.$toast(this.$t('message.acao_atualizada'));
          const idAcao = res.data;
          return idAcao;
        });
    },
    alternaRota(idAcao) {
      const routeEdicao = 'editarAcao';

      let { name } = this.$router.currentRoute;
      name = name === routeEdicao
        ? name.concat('_') : routeEdicao;

      this.$router.replace({
        name,
        params: { idAcao, passoFinal: true, from: 'detalharAcao' },
      });
    },
  },
  mounted() {
    this.$root.$on('voltar', () => {
      this.anterior();
    });

    if (this.edicao) {
      this.carregarEdicao();
    }
  },
};
</script>
<style>
  .PlanejamentoAcaoForm_Rodape {
    height: auto !important;
    background-color: #EEEEEE;
    display: flex;
    position: fixed;
    z-index: 4;
    transform: translate(0);
    transition: all .4s cubic-bezier(.25,.8,.5,1);
    bottom: 0px;
    left: 0;
    width: 100%;
  }
  .PlanejamentoAcaoForm_Rodape .v-stepper {
    background: none;
    border: none;
    box-shadow: none;
  }
  .PlanejamentoAcaoForm_Rodape .v-stepper__header,
  .PlanejamentoAcaoForm_Rodape .v-stepper__step {
    height: 56px;
  }
  .PlanejamentoAcaoForm_Rodape__Passo {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
</style>
