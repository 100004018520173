import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'acao-servico');

const planejamentoAcaoServicoActions = {
  buscar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}` },
  salvar: { method: 'POST', url: `${basePath}` },
  atualizar: { method: 'PUT', url: `${basePath}` },
  deletar: { method: 'DELETE', url: `${basePath}` },
};

export default (resource) => resource(`${basePath}`, {}, planejamentoAcaoServicoActions);
