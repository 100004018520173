import Vue from 'vue';
import Vuex from 'vuex';
import contratoPeriodo from './modules/contrato-periodo';
import store from '../produto/vuex/store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ...store.modules,
    contratoPeriodo,
  },
  strict: true,
});
