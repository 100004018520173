const PlanejamentoAcao = () => import('../../../spa/planejamento-acao/PlanejamentoAcao');
const PlanejamentoAcaoList = () => import('../../../spa/planejamento-acao/list/PlanejamentoAcaoList');
const PlanejamentoAcaoForm = () => import('../../../spa/planejamento-acao/form/PlanejamentoAcaoForm');
const PlanejamentoEdicaoAcaoForm = () => import('../../../spa/planejamento-acao/form/PlanejamentoEdicaoAcaoForm');

export default {
  path: 'planejamento-acao',
  component: PlanejamentoAcao,
  children: [
    {
      path: '',
      name: 'planejamento-acao',
      component: PlanejamentoAcaoList,
    },
    {
      path: ':idTipoAcao/nova',
      name: 'novaAcao',
      component: PlanejamentoAcaoForm,
      props: {
        default: false,
        somenteLeitura: false,
        copia: false,
        edicao: false,
        novo: true,
      },
    },
    {
      path: ':idAcao/editar',
      name: 'editarAcao',
      component: PlanejamentoEdicaoAcaoForm,
    },
    {
      path: ':idAcao/editar_',
      name: 'editarAcao_',
      component: PlanejamentoAcaoForm,
      props: {
        somenteLeitura: false,
        edicao: true,
        novo: false,
      },
    },
    {
      path: ':idAcao/detalhar',
      name: 'detalharAcao',
      component: PlanejamentoAcaoForm,
      props: {
        default: false,
        somenteLeitura: true,
        copia: false,
        edicao: false,
        novo: false,
      },
    },
  ],
};
