import getBasePath from '../functions/api-resource';

const basePath = getBasePath('apuracao', 'reivindicacao');

const reivindicacaoActions = {
  justificativa: { method: 'GET', url: `${basePath}/{idReivindicacao}/justificativa` },
  solicitar: { method: 'POST', url: `${basePath}/solicitar/{idApuracao}` },
  iniciaFluxo: { method: 'POST', url: `${basePath}/fluxo/{idReivindicacao}` },
  upload: { method: 'POST', url: `${basePath}/upload` },
  reprovarReivindicacao: { method: 'PUT', url: `${basePath}/{idReivindicacao}/reprovar` },
  aprovarReivindicacao: { method: 'PUT', url: `${basePath}/{idReivindicacao}/aprovar` },
};

export default (resource) => resource(`${basePath}`, {}, reivindicacaoActions);
