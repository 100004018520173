<template>
  <v-data-table
    :headers="cabecalhoTabela"
    dense
    :items="contasCorrentes"
    :no-data-text="$t('label.tabela_sem_conteudo')"
    hide-default-footer
    disable-pagination
    :items-per-page="-1">
    <template v-slot:item.tipo_orcamento="{ item }">
      {{ item.tipoOrcamento }}
    </template>
    <template v-slot:item.descricao="{ item }">
      {{ item.descricaoLinha }}
    </template>
    <template v-slot:item.nome_conta="{ item }">
      {{ item.nomeConta }}
    </template>
    <template v-slot:item.tipo_investimento="{ item }">
      {{ item.tipoInvestimento }}
    </template>
    <template v-slot:item.saldo_atual="{ item }">
      {{ getMoney(item.saldo) }}
    </template>
    <template v-slot:item.saldo_ajustado="{ item }">
      <conta-corrente-tabela-saldo-ajustado :id="item.idContaCorrente"/>
    </template>
    <template v-slot:item.vlr_reservado="{ item }">
      <input-money
        dense
        v-model="item.valor"
        :disabled="somenteLeitura"
        :rules="[rules.maximoPadrao]"
        @input="$emit('ContaCorrenteTabela_atualizaSaldoAjustado', item)"
        @InputMoney_valorAlterado="(valor) => atualizaSaldoAjustado(valor, item)"/>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from 'vuex';
import InputMoney from '../../../../produto/shared-components/inputs/InputMoney';
import { getMoney } from '../../../../produto/common/functions/helpers';
import ContaCorrenteTabelaSaldoAjustado from '../../../../produto/spa/planejamento-acao/form/conta-corrente/ContaCorrenteTabelaSaldoAjustado';

export default {
  name: 'ContaCorrenteTabela',
  components: {
    ContaCorrenteTabelaSaldoAjustado,
    InputMoney,
  },
  props: {
    somenteLeitura: Boolean,
    contasCorrentes: {
      type: Array,
      default: () => ([]),
    },
    exibirSaldo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rules: {
        maximoPadrao: (value) => value <= 99999999.99 || this.$t('message.valor_padrao_maximo'),
      },
    };
  },
  computed: {
    cabecalhoTabela() {
      const cabecalho = [
        { text: this.$tc('label.descricao', 1), value: 'descricao', sortable: false },
        { text: this.$tc('label.tipo_investimento', 1), value: 'tipo_investimento', sortable: false },
        { text: this.$tc('label.conta_corrente', 1), value: 'nome_conta', sortable: false },
        { text: this.$tc('label.periodo', 1), value: 'periodo', sortable: false },
        { text: this.$tc('label.vlr_reservado', 1), value: 'vlr_reservado', sortable: false },
      ];
      if (this.exibirSaldo) {
        cabecalho.splice(4, 0,
          { text: this.$tc('label.saldo_atual', 1), value: 'saldo_atual', sortable: false });
        cabecalho.splice(5, 0,
          { text: this.$tc('label.saldo_ajustado', 1), value: 'saldo_ajustado', sortable: false });
      }
      return cabecalho;
    },
  },
  methods: {
    ...mapActions('acaoSaldoContaCorrente', [
      'alterarSaldoConta',
    ]),
    getMoney,
    atualizaSaldoAjustado(novoValor, contaCorrente) {
      const diferenca = novoValor - (contaCorrente.valor || 0);
      this.alterarSaldoConta({ idContaCorrente: contaCorrente.idContaCorrente, diferenca });
      this.$emit('ContaCorrenteTabela_atualizaSaldoAjustado', contaCorrente);
    },
  },
};
</script>
