import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('workflow', 'execucao/contrato');

const workflowAcaoActions = {
  solicitante: { method: 'GET', url: `${basePath}/solicitante/{idContrato}` },
  buscaPassosClientes: { method: 'GET', url: `${basePath}/{idContrato}/passos/cliente` },
  buscaPassosFornecedor: { method: 'GET', url: `${basePath}/{idContrato}/passos/fornecedor` },
  associaUsuarioClienteAprovador: { method: 'POST', url: `${basePath}/{idContrato}/passos/{idPasso}/cliente/usuario/{idUsuario}` },
  associaUsuarioFornecedorAprovador: { method: 'POST', url: `${basePath}/{idContrato}/passos/{idPasso}/fornecedor/usuario/{idUsuario}` },
  iniciaFluxo: { method: 'POST', url: `${basePath}/{idContrato}` },
  aprovador: { method: 'GET', url: `${basePath}/{idContrato}/passos/aprovador` },
  aprovarPasso: { method: 'PUT', url: `${basePath}/{idContrato}/passos/aprovar` },
  reprovar: { method: 'PUT', url: `${basePath}/{idContrato}/passos/reprovar` },
  cancelar: { method: 'PUT', url: `${basePath}/{idContrato}/cancelar` },
  enviarAnalise: { method: 'PUT', url: `${basePath}/{idContrato}/passos/analise` },
  status: { method: 'GET', url: `${basePath}/{idContrato}/status` },
  statusPasso: { method: 'GET', url: `${basePath}/{idContrato}/passos/status` },
  existeFluxoCadastrado: { method: 'GET', url: `${basePath}/{idContrato}/fluxo-cadastrado` },
  buscarFluxoTrade: { method: 'GET', url: `${basePath}/fluxo-trade` },
};
export default (resource) => resource(`${basePath}`, {}, workflowAcaoActions);

export const pendenciasFluxoContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pendencias', parametros).doGet();
