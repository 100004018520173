<template>
  <v-data-table
    :headers="headers"
    :items="pagamentos"
    :items-per-page="10"
    :class="classTable">
    <template v-slot:item.valorRateio="{ item }">
      {{ getMoney(item.valorRateio) }}
    </template>
  </v-data-table>
</template>

<script>
import { getMoney } from '@/produto/common/functions/helpers';

export default {
  name: 'ApuracaoAcaoDetalhesPagamentosClientesTabela',
  props: {
    idAcao: Number,
    classTable: {
      type: String,
      default: 'elevation-1',
    },
  },
  data() {
    return {
      rateioProporcionalResource: this.$api.rateioProporcional(this.$resource),
      pagamentos: [],
      headers: [
        { text: this.$tc('label.codigo', 1), value: 'idExterno' },
        { text: this.$tc('label.nome', 1), value: 'nomCliente' },
        { text: this.$tc('label.cpf_cnpj', 1), value: 'desCnpj' },
        { text: this.$tc('label.valor', 1), value: 'valorRateio' },
      ],
    };
  },
  watch: {
    idAcaoTradePrevia() {
      this.buscarPagamento();
    },
  },
  methods: {
    getMoney,
    buscarPagamento() {
      const param = {
        idAcao: this.idAcao,
        indClientesComRateio: true,
      };
      this.rateioProporcionalResource.buscarRateiosProporcionalClienteComRateio(param)
        .then((res) => {
          this.pagamentos = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    if (this.idAcao) {
      this.buscarPagamento();
    }
  },
};
</script>
