var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"mb-1 WorkflowFormPassos__subtitulo"},[_vm._v(_vm._s(_vm.$tc('label.passos_workflow', 1)))]),_c('v-stepper',{staticClass:"WorkflowFormPassos",attrs:{"vertical":""},model:{value:(_vm.passoAberto),callback:function ($$v) {_vm.passoAberto=$$v},expression:"passoAberto"}},_vm._l((_vm.passosFluxo),function(passo,index){return _c('div',{key:index},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-stepper-step',{attrs:{"step":(index + 1),"editable":""}},[_vm._v(" "+_vm._s(((_vm.$tc('label.passos', 1)) + " " + (index + 1) + " / " + (_vm.passosFluxo.length)))+" ")])],1),_c('v-spacer'),_c('div',{staticClass:"mt-3 pt-6 mr-5"},[_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.passoAberto === (index +1)),expression:"passoAberto === (index +1)"}],staticClass:"WorkflowFormPassos__opt WorkflowFormPassos__opt__moveup",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","disabled":_vm.primeiroPasso(index)},on:{"click":function($event){return _vm.moverPassoAcima(index)}}},on),[_c('v-icon',[_vm._v("keyboard_arrow_up")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('label.mover_passo', 1)))])]),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.passoAberto === (index +1)),expression:"passoAberto === (index +1)"}],staticClass:"WorkflowFormPassos__opt WorkflowFormPassos__opt__movedown",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","disabled":_vm.ultimoPasso(index)},on:{"click":function($event){return _vm.moverPassoAbaixo(index)}}},on),[_c('v-icon',[_vm._v("keyboard_arrow_down")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('label.mover_passo', 1)))])]),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.passoAberto === (index +1)),expression:"passoAberto === (index +1)"}],staticClass:"WorkflowFormPassos__opt WorkflowFormPassos__opt__remove",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":"","disabled":_vm.unicoPasso()},on:{"click":function($event){return _vm.removerPasso(index)}}},on),[_c('v-icon',[_vm._v("remove")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('label.remover_passo', 1)))])]),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.passoAberto === (index +1)),expression:"passoAberto === (index +1)"}],staticClass:"WorkflowFormPassos__opt WorkflowFormPassos__opt__add",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.adicionarPasso()}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('label.adicionar_passo', 1)))])])],1)],1),_c('v-stepper-content',{attrs:{"step":(index + 1)}},[_c('workflow-form-passo',{attrs:{"passo":passo,"condicionais":_vm.condicionais}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"disabled":!_vm.podeRetornar},on:{"click":function($event){return _vm.retornaPasso()}}},[_vm._v(" "+_vm._s(_vm.$tc('label.anterior', 1))+" ")]),_c('v-btn',{staticStyle:{"margin-left":"8px"},attrs:{"color":"primary","disabled":!_vm.podeAvancar},on:{"click":function($event){return _vm.avancaPasso()}}},[_vm._v(" "+_vm._s(_vm.$tc('label.proximo', 1))+" ")])],1)],1)],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }