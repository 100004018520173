import jwtDecode from 'jwt-decode';

const removeString = (remove, string) => string.replace(remove, '');

const reduceTradelinksRoles = (resourceAccess, keyExample) => Object
  .keys(resourceAccess).reduce((acc, curr) => {
    const key = removeString(`${keyExample}_`, curr);
    return (curr.indexOf(keyExample) !== -1)
      ? Object.assign(acc, { [key]: resourceAccess[curr] })
      : acc;
  }, {});

const mutations = {
  setAccess(state, access) {
    if (access) {
      state.access = access;
    }
  },
  setAllRoles(state, access) {
    const resourceRoles = Object.keys(access.resource_access)
      .reduce((acc, curr) => acc.concat(access.resource_access[curr].roles), []);
    state.allRoles = resourceRoles.concat(access.realm_access.roles);
  },
  setRoles(state, access) {
    const resourceRoles = reduceTradelinksRoles(access.resource_access, 'tradelinks');

    const roles = Object.assign(resourceRoles, { realm_access: access.realm_access }, {});
    if (roles && Object.keys(roles).length) {
      state.roles = roles;
    }
  },
  setToken(state, token) {
    state.token = token;
  },
  setUsuario(state, name) {
    state.name = name;
  },
};

const actions = {
  setToken({ commit }, token) {
    if (!token) {
      return;
    }
    commit('setToken', token);
    const decoded = jwtDecode(token),
      access = {
        realm_access: decoded.realm_access,
        resource_access: decoded.resource_access,
      },
      { name } = decoded;
    commit('setAccess', access);
    commit('setAllRoles', access);
    commit('setRoles', access);
    commit('setUsuario', name);
  },
};

const getters = {
  getAccess(state) {
    return state.access;
  },
  getAllRoles(state) {
    return state.allRoles;
  },
  getRoles(state) {
    return state.roles;
  },
  getToken(state) {
    return state.token;
  },
  getUsuario(state) {
    return state.name;
  },
};

const state = {
  access: {},
  allRoles: [],
  roles: [],
  token: null,
  name: '',
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
