import getBasePath from '../functions/api-resource';

const basePath = getBasePath('workflow_estrutural', 'execucao');

const workflowAcordoCrescimentoActions = {
  aprovarAcordo: { method: 'POST', url: `${basePath}/acordo{/id}` },
  aprovarContrato: { method: 'POST', url: `${basePath}/contrato{/id}/acordos` },
  acordo: { method: 'GET', url: `${basePath}/acordo{/id}/passos/status` },
  cancelarAcordo: { method: 'PUT', url: `${basePath}/acordo{/id}/cancelar` },
  aprovarPassosAcordo: { method: 'PUT', url: `${basePath}/acordo{/idAcordo}/passos/aprovar` },
  reprovarAcordo: { method: 'PUT', url: `${basePath}/acordo{/idAcordo}/passos/reprovar` },
  enviarAcordoAnalise: { method: 'PUT', url: `${basePath}/acordo{/idAcordo}/passos/analise` },
  contrato: { method: 'GET', url: `${basePath}/contrato{/id}/passos/status` },
  orcamento: { method: 'GET', url: `${basePath}/orcamento{/id}/passos/status` },
  pagamento: { method: 'GET', url: `${basePath}/pagamento{/id}/passos/status` },
  statusPassosApuracao: { method: 'GET', url: `${basePath}/apuracao{/id}/passos/status` },
  aprovarPassosPagamento: { method: 'PUT', url: `${basePath}/pagamento{/id}/passos/aprovar` },
  reprovarPassosPagamento: { method: 'PUT', url: `${basePath}/pagamento{/id}/passos/reprovar` },
  aprovarPassosApuracao: { method: 'PUT', url: `${basePath}/apuracao{/idApuracao}/passos/aprovar` },
  enviarApuracaoAnalise: { method: 'PUT', url: `${basePath}/apuracao{/idApuracao}/passos/analise` },
  reprovarPassosApuracao: { method: 'PUT', url: `${basePath}/apuracao{/idApuracao}/passos/reprovar` },
  solicitantesPasso: { method: 'GET', url: `${basePath}/{idTipo}/solicitante/{passo}` },
  aprovadoresPasso: { method: 'GET', url: `${basePath}/{idTipo}/aprovador/{passo}` },
  aprovadoresPassosClientes: { method: 'GET', url: `${basePath}/passo/{idPassoExecucao}/aprovador/cliente` },
  buscarPassos: { method: 'GET', url: `${basePath}/{idTipo}/passos` },
  transferirPasso: { method: 'POST', url: `${basePath}/fluxo/{idFluxo}/passo/{idPasso}/transferencia` },
  transferirPassoCliente: { method: 'POST', url: `${basePath}/passo/{idPasso}/acordo/{idAcordo}/usuario-cliente/{idUsuarioCliente}/transferencia` },
  transferirPassos: { method: 'POST', url: `${basePath}/tipo/{idTipo}/passos/transferencia` },
};

export default (resource) => resource(`${basePath}`, {}, workflowAcordoCrescimentoActions);
