var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto",attrs:{"color":_vm.type.color,"dark":"","height":"120px"}},[(_vm.thumbnail)?_c('v-img',{attrs:{"src":_vm.thumbnail,"height":"120","position":"top center"}},[(hover)?_c('div',{staticStyle:{"background":"rgba(0, 0, 0, 0.5)"}},[_c('v-card-title',{staticClass:"py-1",staticStyle:{"height":"75px"}},[_c('div',{staticClass:"mt-2",staticStyle:{"display":"flex","height":"100%"}},[_c('div',[_c('img',{staticClass:"mr-2 CardFile--img",attrs:{"src":_vm.type.icon}})]),_c('div',[_c('div',{staticClass:"font-weight-bold subtitle-1",staticStyle:{"margin-top":"4px"}},[_vm._v(_vm._s(_vm.file.displayedName))])])])]),_c('v-card-actions',{staticStyle:{"height":"45px"},attrs:{"align-end":""}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.habilitarExclusao)?_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":_vm.openDialogRemove}},on),[_c('v-icon',[_vm._v("delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.remover')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":_vm.download}},on),[_c('v-icon',[_vm._v("cloud_download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.exportar')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.hasViewer)?_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":_vm.openFile}},on),[_c('v-icon',[_vm._v("visibility")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.visualizar')))])]),_c('v-spacer')],1)],1):_vm._e()]):_vm._e(),(!_vm.thumbnail)?_c('v-card-title',{staticClass:"py-1",staticStyle:{"height":"75px"}},[_c('div',{staticClass:"mt-2",staticStyle:{"display":"flex","height":"100%"}},[_c('div',[_c('img',{staticClass:"mr-2 CardFile--img",attrs:{"src":_vm.type.icon}})]),_c('div',[_c('div',{staticClass:"font-weight-bold subtitle-1",staticStyle:{"margin-top":"4px"}},[_vm._v(_vm._s(_vm.file.displayedName))])])])]):_vm._e(),(!_vm.thumbnail)?_c('v-card-actions',{attrs:{"align-end":""}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.habilitarExclusao)?_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":_vm.openDialogRemove}},on),[_c('v-icon',[_vm._v("delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.remover')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":_vm.download}},on),[_c('v-icon',[_vm._v("cloud_download")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.exportar')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.hasViewer)?_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":_vm.openFile}},on),[_c('v-icon',[_vm._v("visibility")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.visualizar')))])]),_c('v-spacer')],1):_vm._e()],1)}}])}),(_vm.hasViewer)?_c('view-file',{ref:"viewer",attrs:{"file":_vm.file,"type":_vm.type,"uri":_vm.uri},on:{"download":_vm.download}}):_vm._e(),_c('confirm',{ref:"dialogConfirm",attrs:{"message":_vm.message},on:{"agree":_vm.agreeConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }