<template>
  <div v-if="canAccessPage">
    <dashboard-cliente-filtro
      v-model="abrirFiltro"
      @DashboardClienteFiltro__AplicaFiltros="aplicarFiltros">
    </dashboard-cliente-filtro>

    <v-container fluid grid-list-md pa-0>
      <v-row align="center" justify="end" class="font-weight-black" style="min-height: 50px">
        <v-col cols="12" class="text-right" xs1>
          <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <dashboard-cliente-card-totalizador
            :filtros="filtros"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="8" class="pt-0">
          <v-row>
            <v-col cols="12" class="px-3">
              <v-card style="width: 100%;">
                <dashboard-cliente-graficos :filtros="filtros"/>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="pt-0">
          <v-row>
            <v-col cols="12" d-flex >
              <dashboard-pendencias-planejamento
                :filtros="filtros"
                :dashCliente="true" />
            </v-col>
            <v-col cols="12" d-flex >
              <dashboard-pendencias-apuracao
                :filtros="filtros"
                :dashCliente="true" />
            </v-col>
            <v-col cols="12" d-flex >
              <dashboard-pendencias-ajuste-verba
                :filtros="filtros"
                :dashCliente="true" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script type="text/javascript">
import moment from 'moment';
import DashboardPendenciasAjusteVerba from '@/spa/dashboard-inicial/DashboardPendenciasAjusteVerba';
import { generateComputed } from '../../../../common/functions/roles-computed-generator';

import DashboardClienteGraficos from './DashClienteGraficos';
import DashboardClienteCardTotalizador from './DashClienteCardTotalizador';
import DashboardPendenciasApuracao from '../../DashboardPendenciasApuracao';
import DashboardPendenciasPlanejamento from '../../DashboardPendenciasPlanejamento';

import DashboardClienteFiltro from './DashClienteFiltro';

export default {
  name: 'DashboardCliente',
  data() {
    return {
      filtros: null,
      abrirFiltro: false,
    };
  },
  components: {
    DashboardClienteCardTotalizador,
    DashboardClienteGraficos,
    DashboardClienteFiltro,
    DashboardPendenciasApuracao,
    DashboardPendenciasPlanejamento,
    DashboardPendenciasAjusteVerba,
  },
  computed: {
    ...generateComputed('ACESSO_CLIENTE', [
      'getAllRoles',
    ]),
    canAccessPage() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('ACESSO_CLIENTE')).length;
    },
  },
  methods: {
    aplicarFiltros(filtrosAplicados) {
      if (!filtrosAplicados || !this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      if (!filtrosAplicados.data_inicio && !filtrosAplicados.data_fim) {
        filtrosAplicados.data_inicio = moment(new Date(new Date().getFullYear(), 0, 1), 'DD-MM-YYYY')
          .format('YYYY-MM-DD');
        filtrosAplicados.data_fim = moment(new Date(new Date().getFullYear(), 11, 31), 'DD-MM-YYYY')
          .format('YYYY-MM-DD');
      }
      this.filtros = {
        ...filtrosAplicados,
      };
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio || filtrosAplicados.data_fim) {
        let dtInicio = '';
        let dtFinal = '';
        dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');
        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
  },
};
</script>
