<template>
  <div>
    <v-row justify="start">
      <v-col cols="12" sm="6" md="3" class="ml-3">
        <v-text-field
          id="campo_pesquisar"
          v-model="searchQuery"
          @input="filtrar"
          append-icon="search"
          :label="$t('label.pesquisar')"
          single-line
          clearable
          hide-details>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select
          v-model="statusFiltro"
          :items="statusOptions"
          :label="$t('label.status')"
          @change="filtrar"
          hide-details>
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3" align-self="center">
        <v-row>
          <v-tooltip bottom v-show="canAccessCRUD && selected.length">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_ativar_inativar" icon class="mx-0" v-on="on" @click="ativarInativar()">
                <v-icon>block</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.ativar_inativa') }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="canAccessCRUD && tipoImportacao">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_importar" icon class="mx-0" v-on="on" @click="importarDados">
                <v-icon>backup</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.importar') }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="canAccessCRUD">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_exportar" icon :disabled="bloquearBotaoExportacao" class="mx-0" v-on="on" @click="exportar()">
                <v-icon>get_app</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.exportar') }}</span>
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>

    <v-data-table
      id="tabela_clientes"
      v-model="selected"
      :headers="headersDinamico"
      :items="clientes"
      class="custom-pagination-left"
      show-select
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }"
      >
      <template v-slot:item="{ item, isSelected, select }">
        <tr :key="item.idExterno" :class="{ 'TableRow--disabled': !item.indAtivo }">
          <td>
            <v-simple-checkbox :value="isSelected" @input="select($event)"></v-simple-checkbox>
          </td>
          <td class="justify-center px-0">
            <v-tooltip bottom v-if="canAccessCRUD">
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click.stop="editar(item)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.editar') }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!canAccessCRUD">
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click="ver(item)">
                  <v-icon>equalizer</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.mais_detalhes') }}</span>
            </v-tooltip>
          </td>
          <td>{{ item.idExterno }}</td>
          <td>{{ item.nomCliente }}</td>
          <td>{{ item.desCnpj }}</td>
          <td>
            <v-btn id="btn_modal_carteira" icon class="mx-0" v-if="configCarteira === 'IMPLICITA' && vinculo != null"
              @click.stop="abrirModalCarteira(item)">
              <v-icon>account_circle</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn id="btn_modal_info_banco" icon class="mx-0" @click.stop="abrirModalInfoBanco(item)">
              <v-icon>account_balance_wallet</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn id="btn_modal_contato" icon class="mx-0" @click.stop="abrirModalContato(item)">
              <v-icon>people</v-icon>
            </v-btn>
          </td>
          <td v-for="(extensao, indice) in Object.keys(headersDinamicoObrigatorio)"
              v-bind:key="indice">
            {{ getNomesSeparadosPorVirgula(extensao, 'nomExtensao', item) }}
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogCarteiraDivisao" persistent max-width="1000">
      <cliente-carteira-divisao
          :cliente="clienteSelecionado"
          :carteiras="carteiras"
          :canAccessCRUD="canAccessCRUD"
        @fechar="fecharDialogCarteira"
        @carteira_adicionada="abrirModalCarteira(clienteSelecionado)"
        @carteira_alterada="abrirModalCarteira(clienteSelecionado)">
      </cliente-carteira-divisao>
    </v-dialog>

    <v-dialog v-model="dialogCarteiraFuncionario" persistent max-width="1000">
      <cliente-carteira-funcionario
          :cliente="clienteSelecionado"
          :carteiras="carteiras"
          :canAccessCRUD="canAccessCRUD"
        @fechar="fecharDialogCarteira"
        @carteira_adicionada="abrirModalCarteira(clienteSelecionado)">
      </cliente-carteira-funcionario>
    </v-dialog>

    <v-dialog v-model="dialogInfoBanco" persistent max-width="800">
      <cliente-info-banco :cliente="clienteSelecionado"
        @fechar="fecharDialogInfoBanco">
      </cliente-info-banco>
    </v-dialog>

    <v-dialog v-model="dialogContato" persistent max-width="800">
      <cliente-contato :cliente="clienteSelecionado"
        @fechar="fecharDialogContato">
      </cliente-contato>
    </v-dialog>

  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ClienteCarteiraDivisao from './ClienteCarteiraDivisao';
import ClienteCarteiraFuncionario from './ClienteCarteiraFuncionario';
import ClienteInfoBanco from './ClienteInfoBanco';
import ClienteContato from './ClienteContato';
import * as metadados from '../../common/functions/metadados';

export default {
  name: 'ClienteListDados',
  props: {
    canAccessCRUD: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clienteResources: this.$api.cliente(this.$resource),
      clienteCarteiraImplicitaResources: this.$api.clienteCarteiraImplicita(this.$resource),
      parametrizacaoClienteResources: this.$api.parametrizacaoCliente(this.$resource),

      bloquearBotaoExportacao: false,
      statusFiltro: 'Ativo',
      statusOptions: ['Todos', 'Ativo', 'Inativo'],
      clientes: [],
      clienteSelecionado: {},
      carteiras: [],
      esperar: false,
      pagination: {},
      totalPage: 0,
      searchQuery: '',
      headers: [
        {
          text: this.$tc('label.codigo', 1), width: '8%', value: 'id_externo', sortable: true,
        },
        { text: this.$tc('label.nome', 1), value: 'nom_cliente', sortable: true },
        { text: this.$tc('label.cnpj_cpf', 1), value: 'des_cnpj', sortable: true },
        {
          text: this.$tc('label.vinculo_industria', 1), width: '7%', value: 'vinculo_industria', sortable: false,
        },
        {
          text: this.$tc('label.informacoes_bancarias', 1), width: '9%', value: 'informacoes_bancarias', sortable: false,
        },
        {
          text: this.$tc('label.contato', 1), width: '7%', value: 'contato', sortable: false,
        },
      ],
      configCarteira: null,
      vinculo: null,
      dialogCarteiraDivisao: false,
      dialogCarteiraFuncionario: false,
      dialogInfoBanco: false,
      dialogContato: false,
      selected: [],
    };
  },
  components: {
    ClienteCarteiraDivisao,
    ClienteCarteiraFuncionario,
    ClienteInfoBanco,
    ClienteContato,
  },
  computed: {
    ...mapGetters('metadados', [
      'getClienteMetadado',
      'getCarteiraClienteMetadado',
    ]),
    ...mapGetters('importacao', {
      tiposImportacao: 'getTiposImportacao',
    }),
    metadadosCliente() {
      if (this.getClienteMetadado && this.getClienteMetadado.mapaEntidades) {
        return this.getClienteMetadado;
      }
      return undefined;
    },
    headersDinamico() {
      const headers = Object.assign([], this.headers);
      if (!this.metadadosCliente) return headers;

      Object.keys(this.metadadosCliente.mapaEntidades)
        .sort(this.ordenarMetadados)
        .forEach((entidade) => {
          const mapeamentoEntidade = this.metadadosCliente.mapaEntidades[entidade];
          if (mapeamentoEntidade.depObrigatoria) {
            headers.push({
              text: mapeamentoEntidade.entidadeEstrangeira,
              value: mapeamentoEntidade.label,
              sortable: false,
              class: 'ClienteListDados_headers_min_width',
            });
          }
        });

      // Ações
      headers.unshift({
        text: '',
        value: 'acoes',
        sortable: false,
      });

      return headers;
    },
    headersDinamicoObrigatorio() {
      const headers = {};
      Object.keys(this.metadadosCliente.mapaEntidades)
        .sort(this.ordenarMetadados)
        .forEach((entidade) => {
          const mapeamentoEntidade = this.metadadosCliente.mapaEntidades[entidade];
          if (mapeamentoEntidade.depObrigatoria) {
            headers[entidade] = mapeamentoEntidade;
          }
        });
      return headers;
    },
    tipoImportacao() {
      return this.tiposImportacao.find((el) => el.nome === 'importacao_produto');
    },
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    searchQuery() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.pagination.page = 1;
        this.filtrar();
      }, 500);
    },
  },
  methods: {
    ...metadados,
    ordenarMetadados(chave1, chave2) {
      const ind1 = this.metadadosCliente.mapaEntidades[chave1].indiceRecursao !== undefined
        ? this.metadadosCliente.mapaEntidades[chave1].indiceRecursao : 50000;
      const ind2 = this.metadadosCliente.mapaEntidades[chave2].indiceRecursao !== undefined
        ? this.metadadosCliente.mapaEntidades[chave2].indiceRecursao : 50000;
      return ind1 > ind2 ? -1 : 1;
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },
    buscar() {
      const params = {
        filter: this.searchQuery,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };
      if (this.statusFiltro === 'Ativo') {
        params.status = true;
      } else if (this.statusFiltro === 'Inativo') {
        params.status = false;
      }
      this.clienteResources.listarFiltrado(params).then((response) => {
        this.montarObjetoProduto(response.data.resposta);
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
        this.selected = [];
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    montarObjetoProduto(dados) {
      this.clientes = dados
        .map((data) => metadados.montarEstruturaDinamica(data, this.getClienteMetadado, data));
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    editar(item) {
      this.$router.push({ name: 'editarCliente', params: { id: item.id } });
    },
    getSearchQuery() {
      return this.searchQuery;
    },
    ver(item) {
      this.$router.push({ name: 'verCliente', params: { id: item.id } });
    },
    verificaCarteira() {
      const chave = 'CARTEIRA';
      this.parametrizacaoClienteResources.buscar({ chave }).then((response) => {
        if (response.data) {
          this.configCarteira = response.data.valor;
          this.verificaVinculoCarteira();
        }
      }, (err) => {
        this.$error(this, err);
      });
    },
    verificaVinculoCarteira() {
      const chave = 'VINCULO_CARTEIRA_CLIENTE';
      this.parametrizacaoClienteResources.buscar({ chave }).then((response) => {
        if (response.data) {
          this.verificaVinculoControlador();
        }
      }, (err) => {
        this.$error(this, err);
      });
    },
    verificaVinculoControlador() {
      const chave = 'CONTROLADOR_CARTEIRA_CLIENTE';
      this.parametrizacaoClienteResources.buscar({ chave }).then((response) => {
        if (response.data) {
          this.vinculo = response.data.valor;
        }
      }, (err) => {
        this.$error(this, err);
      });
    },
    abrirModalCarteira(item) {
      this.clienteSelecionado = item;
      const idCliente = item.id;
      this.clienteCarteiraImplicitaResources.pesquisar({ idCliente }).then((response) => {
        if (response.data) {
          this.montarObjetoCarteira(response.data);
          if (this.vinculo && this.vinculo === 'DIVISAO') {
            this.dialogCarteiraDivisao = true;
          } else if (this.vinculo && this.vinculo === 'FUNCIONARIO') {
            this.dialogCarteiraFuncionario = true;
          }
        }
      }, (err) => {
        this.$error(this, err);
      });
    },
    montarObjetoCarteira(dados) {
      this.carteiras = dados
        .map((data) => metadados
          .montarEstruturaDinamica(data, this.getCarteiraClienteMetadado, data));
    },
    fecharDialogCarteira() {
      if (this.vinculo && this.vinculo === 'DIVISAO') {
        this.dialogCarteiraDivisao = false;
      } else if (this.vinculo && this.vinculo === 'FUNCIONARIO') {
        this.dialogCarteiraFuncionario = false;
      }
    },
    abrirModalInfoBanco(item) {
      this.clienteSelecionado = item;
      this.dialogInfoBanco = true;
    },
    fecharDialogInfoBanco() {
      this.dialogInfoBanco = false;
    },
    abrirModalContato(item) {
      this.clienteSelecionado = item;
      this.dialogContato = true;
    },
    fecharDialogContato() {
      this.dialogContato = false;
    },
    toggleAll() {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.clientes.slice();
      }
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    ativarInativar() {
      this.clienteResources.ativarMassa(this.selected)
        .then(() => {
          this.filtrar();
          this.$toast(this.$t('message.atualizado_confira_tabela'));
        }, (err) => {
          this.$error(this, err);
        });
    },
    importarDados() {
      this.$emit('TABELA_IMPORTAR_DADOS');
    },
    exportar() {
      this.bloquearBotaoExportacao = true;
      this.$emit('TABELA_EXPORTAR_DADOS',
        () => { this.bloquearBotaoExportacao = false; });
    },
  },
  mounted() {
    this.verificaCarteira();
    this.filtrar();
  },
};
</script>

<style>
  .ClienteListDados_headers_min_width {
    min-width: 100px;
  }
</style>
