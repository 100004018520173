<template>
  <div class="RelatorioStatusAtingimento">
    <relatorio-status-atingimento-filtros
      ref="filtros"
      @RelatorioStatusAtingimentoFiltros__AplicaFiltros="aplicaFiltros"
    />
    <relatorio-status-atingimento-tabela
      ref="tabela"
      :filtros="filtros"
    />
  </div>
</template>
<script>
import RelatorioStatusAtingimentoFiltros from './RelatorioStatusAtingimentoFiltros';
import RelatorioStatusAtingimentoTabela from './RelatorioStatusAtingimentoTabela';

export default {
  components: {
    RelatorioStatusAtingimentoFiltros,
    RelatorioStatusAtingimentoTabela,
  },
  data() {
    return {
      filtros: null,
    };
  },
  methods: {
    aplicaFiltros(filtrosAplicados) {
      if (!filtrosAplicados) {
        return;
      }
      this.filtros = {
        ...filtrosAplicados,
      };
    },
  },
};
</script>
<style>
.RelatorioStatusAtingimento {
  padding-bottom: 60px;
}
.RelatorioStatusAtingimento__Modal .headline {
  width: 100%;
  text-align: center;
}
.RelatorioStatusAtingimento__Lista {
  margin-top: 20px;
}
</style>
