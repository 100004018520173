<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ `${$t('title.configuracao')} - ${$t(indicador.descricao)}` }}</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-form ref="formIndicadores" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <input-decimal
                :label="`${$tc('label.peso', 1)} *`"
                v-model="indicador.peso"
                :disabled="somenteLeitura"
                :rules="[rules.numeroValido]"
                class="v-text-field"/>
            </v-col>
          </v-row>
          <v-row v-if="!somenteLeitura || alterarFinalizado">
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                id="parametrizacao_periodo_passo3_regional"
                name="parametrizacao_periodo_passo3_regional"
                item-text="nome"
                item-value="id"
                return-object
                multiple
                chips
                clearable
                :items="divisoes"
                v-model="indicadorConfiguracao.divisoes"
                :label="$tc('label.divisao', 1)"
                :disabled="somenteLeitura && !alterarFinalizado">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                id="parametrizacao_periodo_passo3_extensao_produto"
                name="parametrizacao_periodo_passo3_extensao_produto"
                item-text="nomExtensao"
                item-value="id"
                return-object
                multiple
                chips
                clearable
                :items="extensoesProduto"
                v-model="indicadorConfiguracao.extensoesProduto"
                :label="$t('label.grupo_culturas')"
                :disabled="somenteLeitura && !alterarFinalizado">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                id="parametrizacao_periodo_passo3_extensao_cluster"
                name="parametrizacao_periodo_passo3_extensao_cluster"
                item-text="nomExtensao"
                item-value="id"
                return-object
                multiple
                chips
                clearable
                :items="extensoesCluster"
                v-model="indicadorConfiguracao.extensoesCluster"
                :label="`${$t('label.cluster')}`"
                :disabled="somenteLeitura && !alterarFinalizado">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                id="parametrizacao_periodo_passo3_extensao_cliente"
                name="parametrizacao_periodo_passo3_extensao_cliente"
                item-text="nomExtensao"
                item-value="id"
                return-object
                multiple
                chips
                clearable
                :items="extensoesCliente"
                v-model="indicadorConfiguracao.extensoesCliente"
                :label="alterarFinalizado ? `${$t('label.holding')} *` : `${$t('label.holding')}`"
                :rules="[rules.requiredParaAlteracao]"
                :disabled="somenteLeitura && !alterarFinalizado">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="indicador.ehSegmento">
              <v-autocomplete
                id="parametrizacao_periodo_passo3_extensao_segmento"
                name="parametrizacao_periodo_passo3_extensao_segmento"
                item-text="nomExtensao"
                item-value="id"
                return-object
                multiple
                chips
                clearable
                :items="extensoesSegmento"
                v-model="indicadorConfiguracao.extensoesSegmento"
                :label="$tc('label.segmento', 1)"
                :disabled="somenteLeitura && !alterarFinalizado">
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="end" v-if="!somenteLeitura || alterarFinalizado">
            <v-btn color="secondary" @click="limpar" class="mr-3">{{ $t('label.limpar') }}</v-btn>
            <v-btn color="primary" @click="adicionar" class="mr-3">{{ edicaoConfiguracao ?
                                                                   $t('label.salvar') :
                                                                   $t('label.adicionar') }}</v-btn>
          </v-row>
        </v-form>
        <v-row>
          <v-data-table
            style="width: 100%;"
            :headers="headers"
            :items="indicador.indicadoresConfiguracao">
            <template v-slot:item.action="{ item }">
              <v-btn
                text
                icon
                @click="editar(item)"
                v-if="!somenteLeitura || (alterarFinalizado && itemPossuiExtensoes(item))">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn text icon @click="excluir(item)" v-if="!somenteLeitura">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.nomesDivisoes="{ item }">
              {{ item.divisoes && item.divisoes.length ? item.nomesDivisoes : $t('label.todos') }}
            </template>
            <template v-slot:item.nomesExtensoesProduto="{ item }">
              {{ item.extensoesProduto && item.extensoesProduto.length ? item.nomesExtensoesProduto : $t('label.todos') }}
            </template>
            <template v-slot:item.nomesExtensoesCluster="{ item }">
              {{ item.extensoesCluster && item.extensoesCluster.length ? item.nomesExtensoesCluster : $t('label.todos') }}
            </template>
            <template v-slot:item.nomesExtensoesCliente="{ item }">
              {{ item.extensoesCliente && item.extensoesCliente.length ? item.nomesExtensoesCliente : $t('label.todos') }}
            </template>
            <template v-slot:item.nomesExtensoesSegmento="{ item }">
              {{ item.extensoesSegmento && item.extensoesSegmento.length ? item.nomesExtensoesSegmento : $t('label.todos') }}
            </template>
            <template v-slot:item.range="{ item }">
              <v-btn text icon @click="abrirRange(item)">
                <v-icon v-if="alterarFinalizado && !itemPossuiExtensoes(item)">search</v-icon>
                <v-icon v-else>add_circle</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.rating="{ item }">
              <v-btn text icon @click="abrirRating(item)">
                <v-icon v-if="alterarFinalizado && !itemPossuiExtensoes(item)">search</v-icon>
                <v-icon v-else>add_circle</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </v-card>
    <indicador-range
      ref="indicadorRange"
      :somente-leitura="somenteLeitura"
      :permite-alteracao="alterarFinalizado && itemRange.possuiExtensoes"
      :ranges="itemRange.ranges">
    </indicador-range>
    <indicador-rating
      ref="indicadorRating"
      :somente-leitura="somenteLeitura"
      :permite-alteracao="alterarFinalizado && itemRange.possuiExtensoes"
      :ratings="itemRange.ratings">
    </indicador-rating>
  </v-dialog>
</template>

<script>
import Indicador from './Indicador';
import InputDecimal from '../../../../produto/shared-components/inputs/InputDecimal';
import IndicadorConfiguracao from './IndicadorConfiguracao';
import IndicadorRange from './IndicadorRange';
import IndicadorRating from './IndicadorRating';

export default {
  name: 'IndicadorCadastro',
  components: { IndicadorRating, IndicadorRange, InputDecimal },
  props: {
    indicador: {
      type: Indicador,
      default: () => (new Indicador()),
    },
    idPeriodo: {
      type: Number,
      default: null,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parametrizacaoPeriodoPasso3: this.$api.parametrizacaoPeriodoPasso3(this.$resource),
      indicadorConfiguracao: new IndicadorConfiguracao(),
      dialog: false,
      divisoes: [],
      itemRange: {},
      extensoesProduto: [],
      extensoesCliente: [],
      extensoesCluster: [],
      extensoesSegmento: [],
      index: 0,
      carregado: false,
      edicaoConfiguracao: false,
      rules: {
        numeroValido: (value) => {
          let msg = this.$t('message.numero_invalido');
          if (!value || value < 0) {
            msg = this.$t('errors.valor.minimo', { min: '0.01' });
          }
          return (!!value && !Number.isNaN(value.toString()) && Number(value) > 0) || msg;
        },
        requiredParaAlteracao: (value) => (!this.alterarFinalizado
                                 || (this.alterarFinalizado && !!value && value.length > 0))
                                 || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    headers() {
      const headers = [];
      headers.push({ text: '', sortable: false, value: 'action' });
      headers.push({ text: this.$tc('label.divisao', 1), value: 'nomesDivisoes' });
      headers.push({ text: this.$t('label.grupo_culturas'), value: 'nomesExtensoesProduto' });
      headers.push({ text: this.$t('label.cluster'), value: 'nomesExtensoesCluster' });
      headers.push({ text: this.$t('label.holding'), value: 'nomesExtensoesCliente' });
      if (this.indicador.ehSegmento) {
        headers.push({ text: this.$tc('label.segmento', 1), value: 'nomesExtensoesSegmento' });
      }
      if (this.indicador.exibirRating) {
        headers.push({ text: this.$t('label.rating'), value: 'rating' });
      } else {
        headers.push({ text: this.$t('label.range'), value: 'range' });
      }
      return headers;
    },
  },
  watch: {
    indicador(val) {
      if (val) {
        this.preencherIndex();
      }
    },
  },
  methods: {
    abrirRange(item) {
      this.itemRange = item;
      if (this.itemRange.extensoesCliente && this.itemRange.extensoesCliente.length) {
        this.itemRange.possuiExtensoes = true;
      }
      this.$refs.indicadorRange.open();
    },
    abrirRating(item) {
      this.itemRange = item;
      if (this.itemRange.extensoesCliente && this.itemRange.extensoesCliente.length) {
        this.itemRange.possuiExtensoes = true;
      }
      this.$refs.indicadorRating.open();
    },
    limpar() {
      this.indicadorConfiguracao = new IndicadorConfiguracao();
      this.edicaoConfiguracao = false;
      this.$refs.formIndicadores.resetValidation();
    },
    excluir(item) {
      this.indicador.removerItem(item);
      this.limpar();
    },
    editar(item) {
      this.edicaoConfiguracao = true;
      this.indicadorConfiguracao = new IndicadorConfiguracao(this.indicador
        .retornarConfiguracao(item));
    },
    adicionar() {
      if (this.validarConflito()) {
        this.$toast(this.$t('message.configuracao_conflito'));
        return;
      }
      if (!this.$refs.formIndicadores.validate()) {
        return;
      }
      if (!this.indicadorConfiguracao.index) {
        this.index += 1;
        this.indicadorConfiguracao.index = this.index;
        this.indicador.indicadoresConfiguracao.push(this.indicadorConfiguracao);
      } else {
        const indicadorConf = this.indicador.retornarConfiguracao(this.indicadorConfiguracao);
        indicadorConf.divisoes = this.indicadorConfiguracao.divisoes;
        indicadorConf.extensoesProduto = this.indicadorConfiguracao.extensoesProduto;
        indicadorConf.extensoesSegmento = this.indicadorConfiguracao.extensoesSegmento;
        indicadorConf.extensoesCliente = this.indicadorConfiguracao.extensoesCliente;
        indicadorConf.extensoesCluster = this.indicadorConfiguracao.extensoesCluster;
      }
      this.limpar();
    },
    validarConflito() {
      return this.indicador.validarConflitosVinculos(this.indicadorConfiguracao);
    },
    open() {
      this.dialog = true;
      if (!this.carregado) {
        this.carregado = true;
        this.buscarDivisoes();
        this.buscarExtensoesProduto();
        this.buscarExtensoesSegmento();
      }
      this.buscarExtensoesCliente();
      this.buscarExtensoesCluster();
    },
    close() {
      if (this.indicador.peso >= 0) {
        this.dialog = false;
      }
      setTimeout(() => {
        this.limpar();
      }, 4E2);
    },
    preencherIndex() {
      this.index = 0;
      this.indicador.indicadoresConfiguracao.forEach((ind) => {
        this.index += 1;
        ind.index = this.index;
      });
    },
    buscarDivisoes() {
      this.parametrizacaoPeriodoPasso3.buscarDivisoes()
        .then((response) => {
          this.divisoes = response.body;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarExtensoesProduto() {
      const param = { mnemonico: 'GRUPO_CULTURA' };
      this.buscarExtensoes(param,
        (response) => {
          this.extensoesProduto = response.body;
        });
    },
    buscarExtensoesSegmento() {
      const param = { mnemonico: 'SEGMENTO' };
      this.buscarExtensoes(param,
        (response) => {
          this.extensoesSegmento = response.body;
        });
    },
    buscarExtensoesCliente() {
      const param = {
        mnemonico: 'HOLDING',
        id: this.idPeriodo,
      };
      this.buscarExtensoes(param,
        (response) => {
          this.extensoesCliente = response.body;
        });
    },
    buscarExtensoesCluster() {
      const param = { mnemonico: 'CLUSTER' };
      this.buscarExtensoes(param,
        (response) => {
          this.extensoesCluster = response.body;
        });
    },
    buscarExtensoes(param, cb) {
      this.parametrizacaoPeriodoPasso3.buscarExtensoes(param)
        .then(cb)
        .catch((err) => {
          this.$error(this, err);
        });
    },
    itemPossuiExtensoes(item) {
      if (item.extensoesCliente && item.extensoesCliente.length) {
        return true;
      }
      return false;
    },
  },
};
</script>
