var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ApuracaoDpmProdutosComplementares"},[_c('div',{staticClass:"mt-2 mb-2 title-float accent--text"},[_vm._v(" "+_vm._s(((_vm.$tc('label.produto_complementar', 2)) + "/" + (_vm.$tc('label.servico', 2))))+" ")]),_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-expansion-panels',{model:{value:(_vm.painel),callback:function ($$v) {_vm.painel=$$v},expression:"painel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header'),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.cabecalho,"items":_vm.produtosComplementares,"dense":"","disable-filtering":"","disable-pagination":"","disable-sort":"","fixed-header":"","height":400,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.pontoUnitario",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontoUnitario))+" ")]}},{key:"item.volumeMeta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.volumeMeta))+" ")]}},{key:"item.volumeFaturado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.volumeFaturado))+" ")]}},{key:"item.volumeFatCart",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.volumeFatCart))+" ")]}},{key:"item.volumeSimulado",fn:function(ref){
var item = ref.item;
return [_c('input-decimal',{staticClass:"py-0 mt-0 mb-1",attrs:{"single-line":"","hide-details":"","dense":"","align-right":""},on:{"input":function($event){return _vm.volumeSimuladoAlterado(item)}},model:{value:(item.volumeSimulado),callback:function ($$v) {_vm.$set(item, "volumeSimulado", $$v)},expression:"item.volumeSimulado"}})]}},{key:"item.pontosMeta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontosMeta))+" ")]}},{key:"item.pontosSimulacao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontosSimulacao))+" ")]}},{key:"item.pontosFaturamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontosFaturamento))+" ")]}},{key:"item.pontosFatCart",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontosFatCart))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"},attrs:{"colspan":"9"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totalPontosMeta))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totalPontosSimulacao))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totalPontosFaturamento))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totalPontosFatCart))+" ")])])]},proxy:true}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }