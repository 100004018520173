<template>
  <v-menu
    :close-on-content-click="false"
    v-model="pickerData"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :disabled="somenteLeitura"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-if="!filled"
        v-on="on"
        v-model="valorApresentavel"
        :label="inputLabel"
        :id="inputId"
        :disabled="somenteLeitura"
        :required="campoObrigatorio"
        :rules="[rules.requiredDate]"
        clearable
        @click:clear="limpaData"
        prepend-icon="event"
        readonly
      ></v-text-field>
      <v-text-field
        v-if="filled"
        v-on="on"
        v-model="valorApresentavel"
        :label="inputLabel"
        :id="inputId"
        :disabled="somenteLeitura"
        :required="campoObrigatorio"
        :rules="[rules.requiredDate]"
        @click:clear="limpaData"
        append-icon="event"
        v-mask="'##/##/####'"
        :return-masked-value="true"
        @input="dataAlterada(true)"
        filled
        readonly
      ></v-text-field>
    </template>

    <v-date-picker
      locale="pt-br"
      color="primary"
      v-model="valorInterno"
      :disabled="somenteLeitura"
      :required="campoObrigatorio"
      :type="tipoSelecao"
      :allowed-dates="datasPermitidas"
      :min="dtaMinima"
      :max="dtaMaxima"
      @input="dataAlterada(false)">
    </v-date-picker>
  </v-menu>
</template>
<script>
import moment from 'moment';
import metadadosCampoDinamicoComum from './metadadosCampoDinamicoComum';

export default {
  name: 'MetadadosCampoDinamicoData',
  props: {
    value: {
      type: [String],
    },
    isDataInicio: {
      type: Boolean,
      default: false,
    },
    somenteLeitura: {
      type: Boolean,
      default: true,
    },
    isDataFim: {
      type: Boolean,
      default: false,
    },
    isVigenciaTrimestral: {
      type: Boolean,
      default: false,
    },
    tipoSelecao: {
      type: String,
      default: 'date',
    },
    filled: {
      type: Boolean,
      default: false,
    },
    mascaraBarra: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    metadadosCampoDinamicoComum,
  ],
  watch: {
    value: {
      immediate: true,
      handler(alterado) {
        this.valorInterno = alterado;
        setTimeout(() => this.alteraValorApresentavel());
      },
    },
  },
  computed: {
    formatoData() {
      if (this.mascaraBarra) return 'DD/MM/YYYY';
      const { desAtributos } = this.metadados;
      if (!desAtributos || !desAtributos.formatoData) return 'DD-MM-YYYY';
      return desAtributos.formatoData;
    },
    dtaMinima() {
      const { desAtributos } = this.metadados;
      if (!desAtributos || !desAtributos.dtaMinima) return null;
      switch (desAtributos.dtaMinima) {
        case 'atual': {
          return moment().format('YYYY-MM-DD');
        }
        case 'amanha': {
          return moment().add(1, 'days').format('YYYY-MM-DD');
        }
        default:
          return null;
      }
    },
    dtaMaxima() {
      const { desAtributos } = this.metadados;
      if (!desAtributos || !desAtributos.dtaMaxima) return null;
      switch (desAtributos.dtaMaxima) {
        case 'atual': {
          return moment().format('YYYY-MM-DD');
        }
        case 'amanha': {
          return moment().add(1, 'days').format('YYYY-MM-DD');
        }
        default:
          return null;
      }
    },
  },
  data() {
    return {
      valorInterno: null,
      valorApresentavel: null,
      pickerData: null,
      rules: {
        requiredDate: () => {
          if (!this.campoObrigatorio) {
            return true;
          }
          const msg = this.$t('message.campo_obrigatorio');
          const val = this.valorInterno;
          return !!(val) || msg;
        },
      },
    };
  },
  methods: {
    alteraValorApresentavel() {
      if (!this.valorInterno) {
        this.valorApresentavel = null;
      } else if (this.tipoSelecao === 'date') {
        this.valorApresentavel = moment(this.valorInterno, 'YYYY-MM-DD').format(this.formatoData);
      } else {
        if (this.isDataInicio) {
          this.valorApresentavel = moment(this.valorInterno, 'YYYY-MM').startOf('month').format(this.formatoData);
        }
        if (this.isDataFim) {
          this.valorApresentavel = moment(this.valorInterno, 'YYYY-MM').endOf('month').format(this.formatoData);
        }
      }
    },
    limpaData() {
      this.valorInterno = null;
      this.emiteValorAlterado(null);
      setTimeout(() => this.alteraValorApresentavel());
    },
    dataAlterada(bool) {
      if (bool) {
        this.valorInterno = moment(this.valorApresentavel, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
      this.emiteValorAlterado(this.valorInterno);
      this.pickerData = false;
      if (!bool) {
        setTimeout(() => this.alteraValorApresentavel());
      }
    },
    datasPermitidas(val) {
      if (this.isVigenciaTrimestral) {
        const valor = parseInt(val.split('-')[1], 10);
        if (this.isDataInicio) {
          return valor === 1 || valor === 4 || valor === 7 || valor === 10;
        }
        return valor === 3 || valor === 6 || valor === 9 || valor === 12;
      }
      return true;
    },
  },
};
</script>
