const validarConflitoIds = (ids1, ids2) => (ids1.length === 0 && ids2.length === 0)
  || ids1.filter((value) => ids2.indexOf(value) !== -1).length > 0;

export default class ClassificacaoConfiguracao {
  constructor(classificacaoConfiguracao = {}) {
    this._id = classificacaoConfiguracao.id;
    this._divisoes = classificacaoConfiguracao.divisoes
      ? [...classificacaoConfiguracao.divisoes] : [];
    this._extensoesProduto = classificacaoConfiguracao.extensoesProduto
      ? [...classificacaoConfiguracao.extensoesProduto] : [];
    this._extensoesCliente = classificacaoConfiguracao.extensoesCliente
      ? [...classificacaoConfiguracao.extensoesCliente] : [];
    this._index = classificacaoConfiguracao.index || 0;

    this._idsDivisoes = this._divisoes.map((d) => d.id);
    this._idsExtensoesCliente = this._extensoesCliente.map((d) => d.id);
    this._idsExtensoesProduto = this._extensoesProduto.map((d) => d.id);

    this._pontuacao = classificacaoConfiguracao.pontuacao || 0;
    this._pontuacaoTotal = classificacaoConfiguracao.pontuacaoTotal || 0;
    this._crescimento = classificacaoConfiguracao.crescimento || 0;
    this._crescimentoTotal = classificacaoConfiguracao.crescimentoTotal || 0;
    this._crescimentoSegmento = classificacaoConfiguracao.crescimentoSegmento || 0;
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get divisoes() {
    return this._divisoes;
  }

  set divisoes(value) {
    this._divisoes = value;
    this._idsDivisoes = this._divisoes.map((d) => d.id);
  }

  get extensoesProduto() {
    return this._extensoesProduto;
  }

  set extensoesProduto(value) {
    this._extensoesProduto = value;
    this._idsExtensoesProduto = this._extensoesProduto.map((d) => d.id);
  }

  get extensoesCliente() {
    return this._extensoesCliente;
  }

  set extensoesCliente(value) {
    this._extensoesCliente = value;
    this._idsExtensoesCliente = this._extensoesCliente.map((d) => d.id);
  }

  get nomesDivisoes() {
    return this._divisoes.map((d) => d.nome).join(', ');
  }

  get nomesExtensoesProduto() {
    return this._extensoesProduto.map((e) => e.nomExtensao).join(', ');
  }

  get nomesExtensoesCliente() {
    return this._extensoesCliente.map((e) => e.nomExtensao).join(', ');
  }

  get index() {
    return this._index;
  }

  set index(value) {
    this._index = value;
  }

  get idsDivisoes() {
    return this._idsDivisoes;
  }

  get idsExtensoesCliente() {
    return this._idsExtensoesCliente;
  }

  get idsExtensoesProduto() {
    return this._idsExtensoesProduto;
  }

  get pontuacao() {
    return this._pontuacao;
  }

  set pontuacao(value) {
    this._pontuacao = value;
  }

  get crescimento() {
    return this._crescimento;
  }

  set crescimento(value) {
    this._crescimento = value;
  }

  get pontuacaoTotal() {
    return this._pontuacaoTotal;
  }

  set pontuacaoTotal(value) {
    this._pontuacaoTotal = value;
  }

  get crescimentoTotal() {
    return this._crescimentoTotal;
  }

  set crescimentoTotal(value) {
    this._crescimentoTotal = value;
  }

  get crescimentoSegmento() {
    return this._crescimentoSegmento;
  }

  set crescimentoSegmento(value) {
    this._crescimentoSegmento = value;
  }

  validarConflitoVinculo(indicadorConf) {
    const conflitoDivisoes = validarConflitoIds(this._idsDivisoes, indicadorConf.idsDivisoes);
    const conflitoCliente = validarConflitoIds(this._idsExtensoesCliente, indicadorConf.idsExtensoesCliente);
    const conflitoProduto = validarConflitoIds(this._idsExtensoesProduto, indicadorConf.idsExtensoesProduto);

    return conflitoDivisoes && conflitoCliente && conflitoProduto;
  }

  toJSON() {
    const idsDivisoes = this._idsDivisoes;
    const idsExtensoesCliente = this._idsExtensoesCliente;
    const idsExtensoesProduto = this._idsExtensoesProduto;
    const pontuacao = this._pontuacao;
    const pontuacaoTotal = this._pontuacaoTotal;
    const crescimento = this._crescimento;
    const crescimentoTotal = this._crescimentoTotal;
    const crescimentoSegmento = this._crescimentoSegmento;
    return {
      id: this._id,
      idsDivisoes,
      idsExtensoesCliente,
      idsExtensoesProduto,
      pontuacao,
      pontuacaoTotal,
      crescimento,
      crescimentoTotal,
      crescimentoSegmento,
    };
  }
}
