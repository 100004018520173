var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$tc('label.perfil', 2)))])]),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),(_vm.grupos)?_c('v-data-table',{attrs:{"headers":_vm.cabecalho,"items":_vm.gruposFiltrados,"options":_vm.pagination,"server-items-length":_vm.grupos.length,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
      itemsPerPageOptions: [5, 10, 15],
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
    var item = ref.item;
return [(_vm.canAccessCRUD)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"id":("grupo-tabela-button-edit-" + (item.name)),"icon":""},on:{"click":function($event){return _vm.edit(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.editar')))])]):_vm._e(),(!_vm.canAccessCRUD)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":("grupo-tabela-button-view-" + (item.name)),"icon":""},on:{"click":function($event){return _vm.visualizar(item)}}},on),[_c('v-icon',[_vm._v("search")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.visualizar_perfil')))])]):_vm._e(),(_vm.canAccessCRUD)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":("grupo-tabela-button-delete-" + (item.name)),"icon":""},on:{"click":function($event){return _vm.excluir(item)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.excluir_perfil')))])]):_vm._e()]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,91983514)}):_vm._e(),_c('confirm',{ref:"dialogExcluir",attrs:{"persistent":true,"message":_vm.$t('message.tem_certeza_exluir_perfil', {param: _vm.grupo.name}),"title":_vm.$t('label.atencao'),"agree-label":_vm.$t('label.sim'),"disagree-label":_vm.$t('label.nao')},on:{"agree":function($event){return _vm.confirmarExclusao()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }