var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-card',{staticClass:"card-size",attrs:{"height":"100%"}},[_c('v-card-title',[_c('h2',{staticClass:"headline mb-0",domProps:{"textContent":_vm._s(_vm.title)}})]),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","align-self":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.canAccessCRUD && _vm.selected.length > 0),expression:"canAccessCRUD && selected.length > 0"}],staticClass:"mx-0",attrs:{"icon":"","id":"tipo-investimento-botao-ativar-inativar"},on:{"click":_vm.ativarInativarRegistros}},on),[_c('v-icon',[_vm._v("block")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('label.ativar_inativa')))])]),(_vm.canAccessCRUD)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"id":"btn_exportar","icon":"","disabled":_vm.bloquearBotaoExportacao},on:{"click":function($event){return _vm.exportar()}}},on),[_c('v-icon',[_vm._v("get_app")])],1)]}}],null,false,1762258399)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.exportar')))])]):_vm._e()],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.regioes,"options":_vm.pagination,"show-select":"","server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
        itemsPerPageOptions: [10, 25, 50],
      },"item-class":_vm.onItemClass},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(!_vm.somenteLeitura)?{key:"item.acoes",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onEditarRegiao(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.editar')))])])]}}:null,{key:"item.cod_regiao",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.codRegiao)+" ")]}},{key:"item.nom_regiao",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomRegiao)+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }