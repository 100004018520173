<template lang="html">
  <v-card class="mb-3">
    <v-card-title>
      <div class="title">{{ $tc('label.linha_investimento', 2) }}</div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon
            id="rateio-botao-exportar"
            v-on="on"
            :disabled="bloquearBotaoExportacao"
            @click="exportarDados">
            <v-icon>get_app</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('label.exportar_conteudo') }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="canAddDivisao && novasDivisoes.length && configuracao.rateioDivisao">
        <template v-slot:activator="{ on }">
          <v-btn icon
            id="rateio-botao-nova-divisao"
            v-on="on"
            @click="abrirModalNovaDivisao">
            <v-icon>sync_problem</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('message.rateio_novas_divisoes') }}</span>
      </v-tooltip>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-container fluid grid-list-md>
      <v-row v-if="configuracao.rateioDivisao">
        <v-col cols="12" sm="3" d-flex>
          <v-select
            id="filtro_divisao"
            name="filtro_divisao"
            :items="divisoes"
            item-text="nome"
            item-value="id"
            :label="`${$tc('label.divisao', 1)}`"
            v-model="divisao">
          </v-select>
        </v-col>
        <v-col cols="12" sm="1">
          <v-btn @click="filtrarDivisao" class="mt-3"
            color="accent" small>
            {{ $t('label.aplicar') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="!configuracao.rateioDivisao ||
                  (configuracao.rateioDivisao && divisoes.length)">
        <spreadsheet
          ref="spreadsheet"
          v-if="planilhaPronta"
          :toolbar="false"
          :sheetsbar="false"
          :rows="rowsNumber"
          :data="datasource"
          :colunas="colunas"
          :estilos="estilos"
          :formulas="formulas"
          :selectRange="celulaInicial"
          :tamanhoColunas="tamanhoColunas"
          :frozenRows="1"
          :autocomplete="false"/>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="voltar()">
        {{ $t('label.voltar') }}
      </v-btn>
      <v-btn @click="salvar()"
        color="primary"
        v-if="configuracao.rateioDivisao || configuracao.permiteVerbaParcial">
        {{ $t('label.salvar') }}
      </v-btn>
    </v-card-actions>
    <orcamento-rateio-validacao ref="validacao"/>
    <modal-rateio-nova-divisao
      :lista="novasDivisoes"
      @ModalRateioNovaDivisao_adicionar="adicionarNovasDivisoes"
      ref="modalRateioNovaDivisao">
    </modal-rateio-nova-divisao>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';

import Spreadsheet from '../../../shared-components/spreadsheet/Spreadsheet';
import OrcamentoRateioSpreadsheetPreenchimento from './OrcamentoRateioSpreadsheetPreenchimento';
import OrcamentoRateioSpreadsheetEstilizacao from './OrcamentoRateioSpreadsheetEstilizacao';
import OrcamentoRateioValidacao from './OrcamentoRateioValidacao';
import ModalRateioNovaDivisao from './ModalRateioNovaDivisao';

export default {
  name: 'OrcamentoRateioSpreadsheet',
  props: {
    rateio: Object,
    configuracao: Object,
    divisoes: Array,
    divisaoSelecionada: Number,
    celulaInicial: String,
  },
  mixins: [
    OrcamentoRateioSpreadsheetEstilizacao,
    OrcamentoRateioSpreadsheetPreenchimento,
  ],
  components: {
    Spreadsheet,
    OrcamentoRateioValidacao,
    ModalRateioNovaDivisao,
  },
  computed: {
    ...mapGetters('metadados', [
      'getLinhaInvestimentoMetadado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canAddDivisao() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ORC_RATEIO_ADD_DIV').length;
    },
    saltoPrimeiraLinha() {
      return this.configuracao.permiteVerbaParcial ? 6 : 5;
    },
  },
  data() {
    return {
      bloquearBotaoExportacao: false,
      extensaoResources: this.$api.extensao(this.$resource),
      rateioOrcamentoResources: this.$api.orcamentoRateio(this.$resource),

      planilhaPronta: false,
      rateioComRemanescente: false,
      divisao: this.divisaoSelecionada,
      novasDivisoes: [],
      saltoOutrasLinhas: 6,
      saltoAAportar: 5,
    };
  },
  methods: {
    exportarDados() {
      this.$refs.spreadsheet.exportarExcel();
    },
    salvar() {
      const itens = this.lerTodosItensRateio();
      if (!this.configuracao.permiteVerbaParcial
        || this.validarValoresAportados(itens.itensAporte)) {
        const parametros = {
          idDivisao: this.divisao,
          itens: itens.itensRateio,
          itensAporte: itens.itensAporte,
        };

        this.rateioOrcamentoResources.atualizarRateio(parametros)
          .then(() => {
            this.$toast(this.$t('message.rateio_atualizado'));
            if (this.configuracao.permiteVerbaParcial) {
              this.$emit('OrcamentoRateioSpreadsheet__carregarRateio', this.rateio.idOrcamento, true);
            } else {
              this.recarregaRateio();
            }
          })
          .catch((err) => {
            this.$refs.validacao.apresentaErrosServidor(err);
          });
      }
    },
    recarregaRateio() {
      const ultimaCelula = this.$refs.spreadsheet.getActiveCell();
      this.$emit('OrcamentoRateioSpreadsheet__recarregaRateio', ultimaCelula);
    },
    voltar() {
      this.$router.push({ name: 'orcamento' });
    },
    montaPlanilha() {
      this.montaColunas();
      this.preencheDados(this.rateio.linhasInvestimento);
      this.calculaQtdLinhasSpreadsheet();
      this.aplicaEstiloColunas();
      setTimeout(() => {
        this.planilhaPronta = true;
      });
    },
    getDatasource() {
      return this.$refs.spreadsheet.getDataSource();
    },
    lerItensRateioLinha(linhaInvestimento, rateio, indiceLeitura) {
      const { periodos } = linhaInvestimento;
      const linhaPlanilha = this.getDatasource()[indiceLeitura];
      const { desdobramentos } = rateio;

      const itensRateio = [];

      periodos.forEach((periodo) => {
        const hash = this.controleColunas.label[periodo];
        const valorRateio = linhaPlanilha[hash];

        const objDesdobramento = desdobramentos
          .filter((d) => d.periodo === periodo)[0];

        const { idContaCorrente, idDesdobramento } = objDesdobramento;

        itensRateio.push({
          idContaCorrente,
          valorRateio,
          idDesdobramento,
        });
      });

      return itensRateio;
    },
    lerTodosItensRateio() {
      const { linhasInvestimento } = this.rateio;
      let idxReferencial = 0;
      const itensRateio = [];
      const itensAporte = [];
      for (let i = 0; i < linhasInvestimento.length; i += 1) {
        const linha = linhasInvestimento[i];

        if (this.configuracao.permiteVerbaParcial) {
          const indiceLeitura = idxReferencial + this.saltoAAportar;
          const itensAporteLinha = this.lerValoresAportados(linha, indiceLeitura);
          itensAporte.push(...itensAporteLinha);
        }

        let ultimaLinha = idxReferencial;
        if (this.configuracao.rateioDivisao) {
          const { rateios } = linha;
          for (let j = 0; j < rateios.length; j += 1) {
            const rateio = rateios[j];
            const indiceLeitura = j === 0
              ? ultimaLinha + this.saltoPrimeiraLinha
              : ultimaLinha + this.saltoOutrasLinhas;

            const itensRateioLinha = this.lerItensRateioLinha(linha, rateio, indiceLeitura);
            ultimaLinha = indiceLeitura;

            itensRateio.push(...itensRateioLinha);
          }
        }
        idxReferencial = ultimaLinha + this.saltoOutrasLinhas;
      }
      return {
        itensRateio,
        itensAporte,
      };
    },
    lerValoresAportados(linhaInvestimento, indiceLeitura) {
      const { periodos } = linhaInvestimento;
      const linhaPlanilha = this.getDatasource()[indiceLeitura];
      const { desdobramentos } = linhaInvestimento;

      const itensRateio = [];

      periodos.forEach((periodo) => {
        const hash = this.controleColunas.label[periodo];
        const valorRateio = linhaPlanilha[hash];

        const objDesdobramento = desdobramentos
          .filter((d) => d.periodo === periodo)[0];

        if (valorRateio !== 0) {
          const { idContaCorrente, idDesdobramento } = objDesdobramento;
          itensRateio.push({
            idContaCorrente,
            valor: valorRateio,
            valorRateio: valorRateio + objDesdobramento.planejado,
            idDesdobramento,
          });
        }
      });

      return itensRateio;
    },
    filtrarDivisao() {
      if (!this.divisao) return;
      let ultimaCelula = null;
      if (this.$refs.spreadsheet) {
        ultimaCelula = this.$refs.spreadsheet.getActiveCell();
      }
      this.$emit('OrcamentoRateioSpreadsheet__atualizarWorkspace', this.divisao, ultimaCelula);
    },
    verificarNovasDivisoes() {
      if (this.canAddDivisao) {
        const { idOrcamento } = this.rateio;
        this.rateioOrcamentoResources.buscarNovasDivisoes({ idOrcamento })
          .then((res) => {
            this.novasDivisoes = res.data || [];
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      }
    },
    abrirModalNovaDivisao() {
      this.$refs.modalRateioNovaDivisao.open();
    },
    adicionarNovasDivisoes() {
      const { idOrcamento } = this.rateio;
      this.rateioOrcamentoResources.adicionarNovasDivisoes({ idOrcamento },
        this.novasDivisoes)
        .then(() => {
          this.$emit('OrcamentoRateioSpreadsheet__recarregarDivisoes', this.rateio.idOrcamento);
          this.novasDivisoes = [];
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    validarValoresAportados(valoresAportados = []) {
      let totalAportes = 0;
      let possuiValorNegativo = false;

      valoresAportados.forEach((rateio) => {
        if (rateio.valor < 0) {
          possuiValorNegativo = true;
        }
        totalAportes += rateio.valor;
      });

      if (possuiValorNegativo) {
        this.$toast(this.$t('errors.aporte.valor_aportar_negativo'));
        return false;
      }

      if (totalAportes === 0 && !this.configuracao.rateioDivisao) {
        this.$toast(this.$t('errors.aporte.valor_aportar_lote_obrigatorio'));
        return false;
      }
      if (totalAportes > this.rateio.remanescente) {
        this.$toast(this.$t('errors.aporte.valor_aportado_excede_remanescente'));
        return false;
      }

      return true;
    },
  },
  mounted() {
    this.metadados = this.getLinhaInvestimentoMetadado;
    this.montaPlanilha();
    this.verificarNovasDivisoes();
  },
};
</script>
