const mutations = {
  alterarPlanejamentoPeriodo(state, planejamentoPeriodo) {
    state.planejamentoPeriodo = planejamentoPeriodo;
  },
};

const actions = {
  setPlanejamentoPeriodo({ commit }, planejamentoPeriodo) {
    return commit('alterarPlanejamentoPeriodo', planejamentoPeriodo);
  },
};

const getters = {
  getPlanejamentoPeriodo(state) {
    return state.planejamentoPeriodo;
  },
};

const state = {
  planejamentoPeriodo: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
