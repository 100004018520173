import { render, staticRenderFns } from "./SimulacaoDpmGruposCulturaPainel.vue?vue&type=template&id=523a5a73&scoped=true&"
import script from "./SimulacaoDpmGruposCulturaPainel.vue?vue&type=script&lang=js&"
export * from "./SimulacaoDpmGruposCulturaPainel.vue?vue&type=script&lang=js&"
import style0 from "./SimulacaoDpmGruposCulturaPainel.vue?vue&type=style&index=0&id=523a5a73&scoped=true&lang=css&"
import style1 from "./SimulacaoDpmGruposCulturaPainel.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "523a5a73",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VDataTable,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader})
