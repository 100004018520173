import moment from 'moment';
import PlanejamentoContratoFormCampos from '../../../produto/spa/planejamento-contrato/form/passo-geral/PlanejamentoContratoFormCampos';
import PlanejamentoContratoFormAcoes from './passo-acoes/PlanejamentoContratoFormAcoes';
import PlanejamentoContratoFormApuracao from './passo-apuracao/PlanejamentoContratoFormApuracao';
import SimulacaoDpm from './simulacao/SimulacaoDpm';

const passoApuracaoHabilitado = (ref, contrato) => {
  const { status } = contrato.passoGeral || {};
  if (!status || status !== 'APROVADO') {
    return false;
  }

  return true;
};

const passoSimulacaoHabilitado = (ref, contrato = {}) => {
  if (ref.getAllRoles.indexOf('SIMULADOR') < 0) {
    return false;
  }
  const { dtaVigenciaFim } = contrato.passoGeral || {};
  return dtaVigenciaFim
    ? moment(dtaVigenciaFim, 'YYYY-MM-DD').isAfter(moment())
    : false;
};

const contratoAprovado = (contrato = {}) => {
  const { status } = contrato.passoGeral || {};

  if (status && status === 'APROVADO') {
    return true;
  }

  return false;
};

export default {
  componentes: {
    PlanejamentoContratoFormCampos,
    PlanejamentoContratoFormAcoes,
    PlanejamentoContratoFormApuracao,
    SimulacaoDpm,
  },
  passosHabilitados(ref, contrato) {
    return {
      geral: {
        habilitado: true,
        label: ref.$tc('label.geral', 1),
        componente: 'planejamento-contrato-form-campos',
      },
      acoes: {
        habilitado: true,
        label: ref.$tc('title.plano_metas', 1),
        componente: 'planejamento-contrato-form-acoes',
      },
      apuracao: {
        habilitado: passoApuracaoHabilitado(ref, contrato),
        label: ref.$tc('title.apuracao', 1),
        componente: 'planejamento-contrato-form-apuracao',
      },
      simulacao: {
        habilitado: (passoSimulacaoHabilitado(ref, contrato) && contratoAprovado(contrato)),
        label: ref.$tc('label.simulacao', 1),
        componente: 'simulacao-dpm',
      },
    };
  },
};
