<template>
  <v-container fluid class="py-0 DPMPlanoMetas" v-if="planoMetas">
    <v-row>
      <v-col cols="12" md="6" sm="12" class="py-0">
        <planejamento-dpm-resumo
          :exibe-cliente-pagador="exibeClientePagador"
          :objeto-passo-geral="objetoPassoGeral"
          :ordenacao-formulario="ordenacaoFormulario"
          :somente-leitura="somenteLeitura"
          :contrato-edicao="contratoEdicao"
          :novo-contrato="novoContrato"
          :passo-aberto="passoAberto"
          ref="resumo"
          />
      </v-col>
      <v-col cols="12" md="6" sm="12" class="py-0">
        <planejamento-dpm-classificacao
          ref="classificacao"
          :objeto-passo-geral="objetoPassoGeral"
          :somente-leitura="somenteLeitura"
          :indicadores="planoMetas.indicadores"
          :classificacao="planoMetas.classificacao"
          :grupos-cultura="planoMetas.gruposCultura"
          :produtos-servicos="planoMetas.produtosServicos"
          :ano-atual-periodo="planoMetas.anoAtualPeriodo"
          :ano-anterior-periodo="planoMetas.anoAnteriorPeriodo"
          :realizado-marcas="planoMetas.realizadoMarcas"
          :segmentos-habilitados="segmentosHabilitados"
          />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <planejamento-dpm-desafio-plano-metas
          ref="desafioPlanoMetas"
          :somente-leitura="somenteLeitura"
          :objeto-passo-geral="objetoPassoGeral"
          :somenteLeitura="somenteLeitura"
          :ano-atual-periodo="planoMetas.anoAtualPeriodo"
          :ano-anterior-periodo="planoMetas.anoAnteriorPeriodo"
          :grupos-cultura="planoMetas.gruposCultura"
          :produtos-servicos="planoMetas.produtosServicos"
          :realizado-marcas="planoMetas.realizadoMarcas"
          :segmentos-habilitados="segmentosHabilitados"
          :crescimentoSegmentos="planoMetas.crescimentoSegmentos"
          @crescimentoSegmentos="setCrescimentoSegmentos"
          @classificacao="(calculos) => $refs.classificacao.atualiza(calculos)"
          />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <planejamento-dpm-grupo-culturas
          :somente-leitura="somenteLeitura"
          :objeto-passo-geral="objetoPassoGeral"
          :somenteLeitura="somenteLeitura"
          :grupos-cultura="planoMetas.gruposCultura"
          :ano-atual-periodo="planoMetas.anoAtualPeriodo"
          :ano-anterior-periodo="planoMetas.anoAnteriorPeriodo"
          :segmentos-habilitados="segmentosHabilitados"
          @RECALCULO_VARIAVEIS="() => recalculaVariaveisMeta()"
          />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <planejamento-dpm-produtos-complementares
          :somente-leitura="somenteLeitura"
          :objeto-passo-geral="objetoPassoGeral"
          :somenteLeitura="somenteLeitura"
          :produtos-servicos="planoMetas.produtosServicos"
          :ano-atual-periodo="planoMetas.anoAtualPeriodo"
          :ano-anterior-periodo="planoMetas.anoAnteriorPeriodo"
          :segmentos-habilitados="segmentosHabilitados"
          @RECALCULO_VARIAVEIS="() => recalculaVariaveisMeta()"
          />
      </v-col>
    </v-row>
    <v-row v-if="possuiDeclaracoes">
      <v-col cols="12">
        <planejamento-dpm-declaracao-compra
          ref="declaracoes"
          :somente-leitura="somenteLeitura"
          :objeto-passo-geral="objetoPassoGeral"
          :somenteLeitura="somenteLeitura"
          :ano-atual-periodo="planoMetas.anoAtualPeriodo"
          :ano-anterior-periodo="planoMetas.anoAnteriorPeriodo"
          :declaracoes-compras="planoMetas.declaracoesCompras"
          :produtos-servicos="planoMetas.produtosServicos"
          :grupos-cultura="planoMetas.gruposCultura"
          />
      </v-col>
    </v-row>

    <fab-scroll
      />
  </v-container>
</template>
<script>
import PlanejamentoDpmResumo from './PlanejamentoDpmResumo';
import PlanejamentoDpmClassificacao from './PlanejamentoDpmClassificacao';
import PlanejamentoDpmDesafioPlanoMetas from './PlanejamentoDpmDesafioPlanoMetas';
import PlanejamentoDpmGrupoCulturas from './PlanejamentoDpmGrupoCulturas';
import PlanejamentoDpmProdutosComplementares from './PlanejamentoDpmProdutosComplementares';
import PlanejamentoDpmDeclaracaoCompra from './PlanejamentoDpmDeclaracaoCompra';

import FabScroll from '../../../../produto/shared-components/FabScroll';

export default {
  props: {
    edicao: Boolean,
    novoContrato: Boolean,
    somenteLeitura: Boolean,
    contratoEdicao: Object,
    passoAberto: Number,
    tipoContrato: {
      type: Object,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
    objetoPassoGeral: {
      type: Object,
      required: true,
    },
  },
  computed: {
    exibeClientePagador() {
      return this.configuracao.habilitaClientePagador;
    },
    ordenacaoFormulario() {
      const { relacaoCampos } = this.configuracao;
      return relacaoCampos;
    },
    possuiDeclaracoes() {
      const { declaracoesCompras } = this.planoMetas;
      return declaracoesCompras && declaracoesCompras.length > 0;
    },
  },
  components: {
    PlanejamentoDpmResumo,
    PlanejamentoDpmClassificacao,
    PlanejamentoDpmDesafioPlanoMetas,
    PlanejamentoDpmGrupoCulturas,
    PlanejamentoDpmProdutosComplementares,
    PlanejamentoDpmDeclaracaoCompra,
    FabScroll,
  },
  data() {
    return {
      planoMetasResource: this.$api.planejamentoPlanoMetas(this.$resource),
      planoMetas: null,
      idPeriodo: null,

      grupoCulturaPrincipal: null,
      segmentosHabilitados: ['fungicida', 'inseticida', 'herbicida'],
    };
  },
  watch: {
    objetoPassoGeral: {
      handler() {
        this.inicializarPlanoMetas();
      },
      deep: true,
    },
  },
  methods: {
    setCrescimentoSegmentos(gruposPrincipais) {
      this.planoMetas.crescimentoSegmentos = gruposPrincipais;
    },
    validarAcoes() {
      return true;
    },
    recalculaVariaveisMeta() {
      this.$refs.desafioPlanoMetas.calculaValores();
      this.$refs.classificacao.calculaValores();
      if (this.$refs.declaracoes) {
        this.$refs.declaracoes.atualizaCompraAtualAdama();
      }
    },
    getObjetoFormatado() {
      const {
        divisao,
        cliente,
        holding,
      } = this.objetoPassoGeral;

      const objeto = {
        idDivisao: divisao.id,
        ...this.planoMetas,
        ...this.$refs.desafioPlanoMetas.objetoMixCalculado(),
        ...this.$refs.resumo.objTermoEPgto(),
      };

      if (holding) {
        objeto.idHolding = holding.id;
      } else {
        objeto.idCliente = cliente.id;
      }

      return objeto;
    },
    buscarConfigPlanoMetas() {
      const {
        divisao,
        cliente,
        holding,
      } = this.objetoPassoGeral;

      const parametros = {
        idPeriodo: this.idPeriodo,
        idDivisao: divisao.id,
        idTipoContrato: this.tipoContrato.id,
      };
      if (holding) {
        parametros.idHolding = holding.id;
      } else {
        parametros.idCliente = cliente.id;
      }

      this.planoMetasResource.buscarNovo(parametros)
        .then((res) => {
          this.planoMetas = res.data;
        });
    },
    inicializarPlanoMetas() {
      const { periodoPlanejamento } = this.objetoPassoGeral;
      this.idPeriodo = periodoPlanejamento.id;

      setTimeout(() => this.$vuetify.goTo(0));

      if (this.novoContrato) {
        this.buscarConfigPlanoMetas();
      } else {
        const { passoAcoes } = this.contratoEdicao;
        this.planoMetas = { ...passoAcoes.planoMetas };
      }
    },
    abrePasso() {
    },
    getObjetoPasso() {
      return { ...this.planoMetas };
    },
  },
  mounted() {
    this.inicializarPlanoMetas();
  },
};
</script>
<style>
  .DPMPlanoMetas input[id^="InputDecimal"] {
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
