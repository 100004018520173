<template>
  <v-data-table
    :headers="headers"
    :items="pagamentos"
    :items-per-page="10"
    :class="classTable">
    <template v-slot:item.valor="{ item }">
      {{ getMoney(item.valor) }}
    </template>
  </v-data-table>
</template>

<script>
import { getMoney } from '@/produto/common/functions/helpers';

export default {
  name: 'PlanejamentoAcaoFormPreviaTabela',
  props: {
    idAcaoTradePrevia: Number,
    classTable: {
      type: String,
      default: 'elevation-1',
    },
  },
  data() {
    return {
      planejamentoAcaoPreviaResource: this.$api.planejamentoAcaoPrevia(this.$resource),
      pagamentos: [],
      headers: [
        { text: this.$tc('label.codigo', 1), value: 'id_externo' },
        { text: this.$tc('label.razao_social', 1), value: 'des_razao_social' },
        { text: this.$tc('label.nome_fantasia', 1), value: 'des_nome_fantasia' },
        { text: this.$tc('label.cpf_cnpj', 1), value: 'des_cnpj' },
        { text: this.$tc('label.valor', 1), value: 'valor' },
      ],
    };
  },
  watch: {
    idAcaoTradePrevia() {
      this.buscarPagamento();
    },
  },
  methods: {
    getMoney,
    buscarPagamento() {
      const param = {
        idAcaoTradePrevia: this.idAcaoTradePrevia,
      };
      this.planejamentoAcaoPreviaResource.buscarPagamentos(param)
        .then((res) => {
          this.pagamentos = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    if (this.idAcaoTradePrevia) {
      this.buscarPagamento();
    }
  },
};
</script>
