import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('workflow', '');

const workflowActions = {
  atualizar: { method: 'PUT', url: `${basePath}cadastro` },
  ativar: { method: 'PUT', url: `${basePath}cadastro/ativacao/{id}/true` },
  gravar: { method: 'POST', url: `${basePath}cadastro` },
  buscaUnica: { method: 'GET', url: `${basePath}cadastro/{id}` },
  inativar: { method: 'PUT', url: `${basePath}cadastro/ativacao/{id}/false` },
  buscarTipos: { method: 'GET', url: `${basePath}cadastro/tipos` },
  buscarCondicionais: { method: 'GET', url: `${basePath}cadastro/condicional/{id}` },
  listar: { method: 'GET', url: `${basePath}cadastro?tamanhoPagina={size}&numeroPagina={page}` },
  listarAtivos: { method: 'GET', url: `${basePath}?tamanhoPagina=40&numeroPagina=1&nome={nome}&indAtivo=true` },
  buscarDescricoes: { method: 'GET', url: `${basePath}cadastro/passo/descricao{/idTipo}` },
};

export default (resource) => resource(`${basePath}`, {}, workflowActions);

export const atualizar = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'cadastro', parametros).doPut(parametros);
export const gravar = (parametros, resource) => resourceBuilder.buildPOST(resource, basePath, 'cadastro', parametros).doPost(parametros);
