<template lang="html">
  <v-form v-model="valid"
          ref="formEndereco"
          autocomplete="off"
          lazy-validation>
    <v-row>
      <v-col cols="12" sm="6" md="2">
        <v-text-field
            id="cep"
            name="cep"
            required
            :tabindex="tabindex"
            v-model="endereco.nomCep"
            :label="`${$t('label.cep')} *`"
            :counter="9"
            v-mask="'#####-###'"
            :rules="[rules.required]"
            :disabled="somenteLeitura"
            @input="buscarCEP"
            @blur="sairCampoCep">
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="8">
        <v-text-field
            id="endereco"
            name="endereco"
            required
            :tabindex="tabindex"
            v-model="endereco.nomEndereco"
            :label="`${$t('label.endereco')} *`"
            :counter="100"
            maxlength="100"
            :rules="[rules.required]"
            :disabled="somenteLeitura || !habilitarLogradouro">
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-text-field
            id="numero"
            name="numero"
            required
            :tabindex="tabindex"
            v-model="endereco.numNumero"
            :label="`${$t('label.numero')} *`"
            type="number"
            min="0"
            :rules="[rules.required]"
            :disabled="somenteLeitura">
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-text-field
            id="complemento"
            name="complemento"
            :tabindex="tabindex"
            v-model="endereco.complemento"
            :label="$t('label.complemento')"
            :disabled="somenteLeitura">
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-text-field
            id="bairro"
            name="bairro"
            required
            :tabindex="tabindex"
            v-model="endereco.nomBairro"
            :label="`${$t('label.bairro')} *`"
            :counter="100"
            maxlength="100"
            :rules="[rules.required]"
            :disabled="somenteLeitura || !habilitarLogradouro">
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-text-field
            id="estado"
            name="estado"
            required
            :tabindex="-1"
            v-model="endereco.nomUf"
            :label="`${$t('label.uf')} *`"
            :disabled="true">
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-text-field
            id="cidade"
            name="cidade"
            required
            :tabindex="-1"
            v-model="endereco.nomeCidade"
            :label="`${$t('label.cidade')} *`"
            :disabled="true">
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { prepareResponseData } from '../../common/functions/http';

export default {
  name: 'Endereco',
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
    tabindex: {
      type: Number,
      default: 1,
    },
    required: {
      type: Boolean,
      default: true,
    },
    dataVvRules: {
      type: String,
      default: 'required',
    },
  },
  data() {
    return {
      valid: true,
      endereco: {
        nomEndereco: null,
        nomBairro: null,
        nomCep: null,
        nomeCidade: null,
        nomUf: null,
        complemento: null,
        numNumero: null,
        idCidade: null,
        idEstado: null,
      },
      enderecoResultado: null,
      cepEncontrado: false,
      resourceCep: this.$api.cep(this.$resource),
      resourceEndereco: this.$api.endereco(this.$resource),
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    habilitarLogradouro() {
      return this.cepEncontrado && !this.enderecoResultado;
    },
  },
  watch: {
    id(id) {
      if (id) this.carregarEndereco(id);
      else this.cleanEndereco();
    },
  },
  methods: {
    buscarCEP() {
      if (this.endereco.nomCep && this.endereco.nomCep.length === 9) {
        this.resourceCep.obter({ cep: this.endereco.nomCep })
          .then(this.preencherCliente)
          .catch((err) => {
            this.cleanEndereco();
            this.$toast(err.data.error);
          });
      }
    },
    sairCampoCep() {
      if (!this.endereco.nomCep || this.endereco.nomCep.length < 9) {
        this.cleanEndereco();
      }
    },
    buscarCidades(row) {
      return this.fetchCidadeFunction({ idEstado: this.loja.idEstado, nome: row.nome });
    },
    cleanEndereco() {
      this.cepEncontrado = false;
      this.endereco = {
        nomEndereco: null,
        nomBairro: null,
        nomeCidade: null,
        nomUf: null,
        complemento: null,
        numNumero: null,
        idCidade: null,
        idEstado: null,
      };
    },
    preencherCliente(response) {
      this.cepEncontrado = true;
      this.endereco = response.data;
      this.enderecoResultado = this.endereco.nomEndereco;
    },
    cleanEstado() {
      this.endereco.idEstado = null;
    },
    setEstado(hit) {
      this.endereco.nomEstado = hit.nomEstado;
      this.endereco.idEstado = hit.id;
      this.cleanCidade();
    },
    changeCidade() {
      this.endereco.idCidade = null;
    },
    cleanCidade() {
      this.endereco.idCidade = null;
      this.endereco.nomCidade = '';
    },
    setCidade(hit) {
      this.endereco.nomeCidade = hit.nomCidade;
      this.endereco.idCidade = hit.id;
    },
    isValido(formValido = false) {
      if (this.$refs.formEndereco.validate() && formValido) {
        this.$emit('ENDERECO_valido', this.endereco);
      } else {
        this.$emit('ENDERECO_invalido', this.endereco);
      }
    },
    resetValidation() {
      this.$refs.formEndereco.resetValidation();
    },
    prepareResponseData,
    carregarEndereco(id) {
      this.resourceEndereco.obter({ id })
        .then(this.preencherCliente)
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    if (this.id) {
      this.carregarEndereco(this.id);
    }
  },
};
</script>

<style lang="scss">
  .CLIENTEAREAATUACAO__NoContent {
    text-align: center;
  }
  .md-theme-default.md-input-container.md-input-invalid label,
  .md-theme-default.md-input-container.md-input-invalid input,
  .md-theme-default.md-input-container.md-input-invalid textarea,
  .md-theme-default.md-input-container.md-input-invalid .md-error,
  .md-theme-default.md-input-container.md-input-invalid .md-count,
  .md-theme-default.md-input-container.md-input-invalid .md-icon:not(.md-icon-delete),
  .md-theme-default.md-input-container.md-input-focused.md-input-required label:after {
    color: #ef5350 !important;
  }
  .md-theme-default.md-input-container.md-input-invalid:after {
    background-color: #ef5350 !important;
  }
</style>
