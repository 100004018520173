export default class PeriodoConfiguracao {
  constructor(id = null) {
    this._id = id;
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get passo1() {
    return this._passo1;
  }

  set passo1(value) {
    this._passo1 = value;
  }

  get passo2() {
    return this._passo2;
  }

  set passo2(value) {
    this._passo2 = value;
  }

  get passo3() {
    return this._passo3;
  }

  set passo3(value) {
    this._passo3 = value;
  }

  get passo4() {
    return this._passo4;
  }

  set passo4(value) {
    this._passo4 = value;
  }

  get passo5() {
    return this._passo5;
  }

  set passo5(value) {
    this._passo5 = value;
  }

  get passo6() {
    return this._passo6;
  }

  set passo6(value) {
    this._passo6 = value;
  }

  get passo7() {
    return this._passo7;
  }

  set passo7(value) {
    this._passo7 = value;
  }
}
