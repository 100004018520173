<template lang="html">
  <div>
    <v-autocomplete
      :id="inputId"
      :name="metadados.label"
      class="Metadados__Extensao custom-autocomplete"
      v-model="objetoInterno"
      :disabled="somenteLeitura"
      :chips="metadados.manyToMany"
      :multiple="metadados.manyToMany"
      :items="itens"
      :search-input.sync="trigger"
      :no-data-text="$tc('message.nenhum_registro', 1)"
      :item-text="identificador"
      item-value="id"
      :rules="[rules.requiredNome]"
      :label="label"
      :required="campoObrigatorio"
      :append-icon="appendIcon"
      :clearable="!metadados.manyToMany && !somenteLeitura"
      :loading="exibeBarraLoading"
      return-object
      @click:append="triggerClick"
      @click.native="buscaExtensoes"
      @input="valorSelecionado">
      <v-slide-x-reverse-transition
        slot="append-outer"
        mode="out-in"
        v-if="exibeIconeFiltro">
        <v-icon @click="filtraAmostragem">filter_list</v-icon>
      </v-slide-x-reverse-transition>
      <template v-slot:selection="data">
        <v-chip
          v-if="metadados.manyToMany"
          :input-value="data.selected"
          close
          class="chip--select-multi"
          @click="data.select"
          @click:close="removeChipSelecao(data.item)"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span class="trunc-ellipsis" v-on="on">
                {{ identificador(data.item) }}
              </span>
            </template>

            {{ identificador(data.item) }}
          </v-tooltip>
        </v-chip>
        <span v-if="!metadados.manyToMany">{{ identificador(data.item) }}</span>
      </template>
    </v-autocomplete>
    <div style="cursor: pointer"
      class="mr-2"
      v-if="novoCadastro && !somenteLeitura && permiteCadastro">
      <span
        v-text="$t('label.nao_encontrou_clique_aqui', {text: metadados.entidadeEstrangeira.toLowerCase()})"
        @click="abrirCadastro"/>
    </div>
  </div>
</template>
<script>
import {
  mapGetters,
} from 'vuex';
import { skipLoading } from '../../common/functions/loading';

export default {
  name: 'MetadadosExtensao',
  props: {
    metadados: {
      type: Object,
      required: true,
    },
    objetoContainer: {
      type: Object,
      required: true,
    },
    ignoraFiltroRecursivo: {
      type: Boolean,
      default: false,
    },
    ignoraObrigatorios: {
      type: Boolean,
      default: false,
    },
    getValoresDependencias: {
      type: Function,
      default: () => [],
    },
    value: {
      type: [Object, Array],
    },
    novoCadastro: {
      type: Boolean,
      default: false,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    exibeLoadingOverlay: {
      type: Boolean,
      default: true,
    },
    funcaoBusca: Function,
  },
  computed: {
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    inputId() {
      return `metadados-extensao-${this.metadados.label}`;
    },
    label() {
      const label = this.metadados.entidadeEstrangeira;
      return label + (this.campoObrigatorio ? ' *' : '');
    },
    exibeIconeFiltro() {
      return !this.ignoraFiltroRecursivo && this.metadados.recursiva;
    },
    campoObrigatorio() {
      return !this.ignoraObrigatorios && this.metadados.depObrigatoria;
    },
    valorDefault() {
      const { manyToMany } = this.metadados;
      return manyToMany ? [] : {};
    },
    appendIcon() {
      if (this.somenteLeitura) {
        return '';
      }
      return 'arrow_drop_down';
    },
    permiteCadastro() {
      return this.metadados
        && this.metadados.desAtributos && this.metadados.desAtributos.permiteCadastro;
    },
    escondeCodigoExtensao() {
      return this.metadados
        && this.metadados.desAtributos && this.metadados.desAtributos.escondeCodigo;
    },
    filtroAdicional() {
      return this.metadados
        && this.metadados.desAtributos && this.metadados.desAtributos.filtroAdicional
        ? this.metadados.desAtributos.filtroAdicional : {};
    },
  },
  data() {
    return {
      itens: [],
      objetoInterno: null,

      trigger: null,
      timeoutTrigger: null,

      resourcesExtensao: this.$api.extensao(this.$resource),

      exibeBarraLoading: false,

      rules: {
        requiredNome: () => {
          if (!this.campoObrigatorio) {
            return true;
          }

          const msg = this.$t('message.campo_obrigatorio');

          const val = this.objetoInterno;
          if (this.metadados.manyToMany) {
            return (val && val.length && val.length > 0) || msg;
          }
          return !!(val) || msg;
        },
      },
    };
  },
  watch: {
    trigger(val) {
      if (!this.metadados.manyToMany) {
        const selecao = this.objetoInterno,
          vlrSelecao = this.identificador(selecao);

        if (vlrSelecao === val) {
          return;
        }
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscaExtensoes(val);
      }, 500);
    },
    value: {
      immediate: true,
      handler(alterado, anterior) {
        const selecaoAtual = alterado || this.valorDefault,
          selecaoAnterior = anterior || this.valorDefault;

        if (this.metadados.manyToMany) {
          if (this.listasDiferentes(this.objetoInterno || [], selecaoAtual)) {
            this.itens = [...selecaoAtual];
            this.objetoInterno = [...selecaoAtual];
          }
          return;
        }

        if (selecaoAtual.id === (this.objetoInterno || {}).id) return;

        if (Object.keys(selecaoAtual).length === 0) {
          this.removeModelo(selecaoAnterior);
        } else {
          this.objetoInterno = { ...selecaoAtual };
          this.aplicaModelo(selecaoAtual);
        }
      },
    },
  },
  methods: {
    listasDiferentes(lista1, lista2) {
      if (lista1.length === 0 || lista2.length === 0) {
        return true;
      }
      const hashItens = lista1.map((i) => i.id).join(''),
        hashModelo = lista2.map((i) => i.id).join('');
      return hashItens !== hashModelo;
    },
    filtraAmostragem(e) {
      e.stopPropagation();
      this.$emit('MetadadosExtensao_FiltraAmostragemRecursiva', this.metadados);
    },
    triggerClick() {
      const id = this.inputId;
      setTimeout(() => document.getElementById(id).click());
    },
    identificador(item) {
      const obj = item || {};
      return this.escondeCodigoExtensao
        ? obj.nomExtensao
        : `${obj.idExterno} - ${obj.nomExtensao}`;
    },
    removeChipSelecao(item) {
      const selecao = this.objetoInterno;
      const index = selecao.map((s) => s.id).indexOf(item.id);
      if (index >= 0) {
        selecao.splice(index, 1);
        this.valorSelecionado(selecao);
      }
    },
    buscaPadraoExtensoes(filtro) {
      const {
        idNivelExtensao,
        indiceRecursao,
        dependencias,
      } = this.metadados;

      const idsDependencias = this.getValoresDependencias(dependencias[dependencias.length - 1])
        .map((dependencia) => dependencia.id) || [];

      const params = {
        nomExtensao: filtro,
        idExterno: filtro,
        idNivelExtensao,
        indiceRecursao,
        idsDependencias,
        ...this.filtroAdicional,
      };
      if (this.metadados.desAtributos
       && this.metadados.desAtributos.quantidadeRegistroSelect) {
        params.tamanhoPagina = this.metadados.desAtributos.quantidadeRegistroSelect;
      }
      return this.resourcesExtensao.listarAtivos(params);
    },
    buscaExtensoes(filtro) {
      if (typeof filtro !== 'string') {
        filtro = null;
      }

      if (!this.exibeLoadingOverlay) {
        this.exibeBarraLoading = true;
        skipLoading();
      }

      let promiseBusca;
      if (!this.funcaoBusca) {
        promiseBusca = this.buscaPadraoExtensoes(filtro);
      } else {
        promiseBusca = this.funcaoBusca(filtro);
      }

      promiseBusca
        .then((response) => this.trataResposta(response))
        .catch((err) => {
          this.$toast(err.data.error);
        })
        .finally(() => {
          this.exibeBarraLoading = false;
        });
    },
    trataResposta(response) {
      this.itens = Array.isArray(response.data)
        ? response.data : response.data.resposta;
      if (this.metadados.manyToMany) {
        const selecao = this.objetoInterno || this.valorDefault;
        this.itens = [...selecao, ...this.itens];
      }
    },
    aplicaModelo(selecaoAtual) {
      if (this.metadados.manyToMany) {
        this.itens = [...selecaoAtual];
      } else {
        this.itens.push(selecaoAtual);
      }
    },
    removeModelo(selecaoAnterior) {
      if (this.metadados.manyToMany) {
        let indice = 0;
        for (; indice < this.itens.length; indice += 1) {
          if (selecaoAnterior.id === valor.id) {
            break;
          }
        }
        this.itens.splice(indice, 1);
      } else {
        this.itens = [];
      }
    },
    refresh() {
      this.$forceUpdate();
    },
    valorSelecionado(selecao) {
      this.$emit('input', selecao);
      this.$emit('MetadadosExtensao_ValorSelecionado', { selecao, metadados: this.metadados });
    },
    abrirCadastro() {
      this.$emit('MetadadosExtensao_abrirCadastro', this.metadados);
    },
  },
};
</script>
<style>
.Metadados__Extensao .chip--select-multi {
  width: 195px;
}
.Metadados__Extensao .chip--select-multi .v-chip__content span.trunc-ellipsis {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}
</style>
