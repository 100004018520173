<template>
  <v-menu
    ref="data_fim"
    :close-on-content-click="false"
    v-model="menuDataFim"
    id="data_fim"
    name="data_fim"
    :nudge-right="40"
    lazy
    :readonly="false"
    transition="scale-transition"
    offset-y
    full-width
    max-width="290px"
    min-width="290px"
    >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        :return-masked-value="true"
        v-mask="'##-##-####'"
        :rules="[rules.validDate]"
        v-model="dataFormatada"
        :label="label"
        :disabled="somenteLeitura"
        clearable
        @click:clear="limpaData"
        prepend-icon="event"
      >
      </v-text-field>
    </template>
    <v-date-picker
      locale="pt-br"
      color="primary"
      v-model="filtrosExportacaoPagamento[tipoData]"
      @input="menuDataFim = false"
    >
    </v-date-picker>
  </v-menu>
</template>
<script>
import { parseDateYYYYMMDD, formatDateDDMMYYYY } from '@/produto/common/functions/date-utils';

export default {
  name: 'RelatorioExportacaoData',
  props: {
    filtrosExportacaoPagamento: {
      type: Object,
    },
    label: {
      type: String,
    },
    tipoData: {
      type: String,
    },
    somenteLeitura: {
      type: Boolean,
    },
  },
  data() {
    return {
      menuDataInicio: false,
      menuDataFim: false,
      rules: {
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
      },
    };
  },
  computed: {
    dataFormatada: {
      get() {
        return formatDateDDMMYYYY(this.filtrosExportacaoPagamento[this.tipoData]);
      },
      set(newValue) {
        this.filtrosExportacaoPagamento[this.tipoData] = parseDateYYYYMMDD(newValue);
      },
    },
  },
  methods: {
    limpaData() {
      this.valorInterno = null;
      this.emiteValorAlterado(null);
      setTimeout(() => this.alteraValorApresentavel());
    },
  },
};
</script>
<style lang="scss">
  .SegmentacaoFormConfiguracao__row {
    padding-bottom: 0px !important;
  }
</style>
