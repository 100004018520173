const validarConflitoIds = (ids1, ids2) => (ids1.length === 0 && ids2.length === 0)
  || ids1.filter((value) => ids2.indexOf(value) !== -1).length > 0;

export default class RebateConfiguracao {
  constructor(rebateConfiguracao = {}) {
    this._id = rebateConfiguracao.id;
    this._classificacao = rebateConfiguracao.classificacao
      ? rebateConfiguracao.classificacao : {};
    this._extensoesProduto = rebateConfiguracao.extensoesProduto
      ? [...rebateConfiguracao.extensoesProduto] : [];
    this._extensoesCliente = rebateConfiguracao.extensoesCliente
      ? [...rebateConfiguracao.extensoesCliente] : [];
    this._index = rebateConfiguracao.index || 0;

    this._idsClassificacoes = [this._classificacao.id];
    this._idsExtensoesCliente = this._extensoesCliente.map((d) => d.id);
    this._idsExtensoesProduto = this._extensoesProduto.map((d) => d.id);

    this._tipoValor = rebateConfiguracao.tipoValor;
    this._valor = rebateConfiguracao.valor || 0;
    this._percProvisao = rebateConfiguracao.percProvisao || 0;
    this._impostos = rebateConfiguracao.impostos || 0;
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get classificacao() {
    return this._classificacao;
  }

  set classificacao(value) {
    this._classificacao = value;
    this._idsClassificacoes = [this._classificacao.id];
  }

  get extensoesProduto() {
    return this._extensoesProduto;
  }

  set extensoesProduto(value) {
    this._extensoesProduto = value;
    this._idsExtensoesProduto = this._extensoesProduto.map((d) => d.id);
  }

  get extensoesCliente() {
    return this._extensoesCliente;
  }

  set extensoesCliente(value) {
    this._extensoesCliente = value;
    this._idsExtensoesCliente = this._extensoesCliente.map((d) => d.id);
  }

  get nomesClassificacoes() {
    return [this._classificacao.nomExtensao];
  }

  get nomesExtensoesProduto() {
    return this._extensoesProduto.map((e) => e.nomExtensao).join(', ');
  }

  get nomesExtensoesCliente() {
    return this._extensoesCliente.map((e) => e.nomExtensao).join(', ');
  }

  get index() {
    return this._index;
  }

  set index(value) {
    this._index = value;
  }

  get idsClassificacoes() {
    return this._idsClassificacoes;
  }

  get idsExtensoesCliente() {
    return this._idsExtensoesCliente;
  }

  get idsExtensoesProduto() {
    return this._idsExtensoesProduto;
  }

  get tipoValor() {
    return this._tipoValor;
  }

  set tipoValor(value) {
    this._tipoValor = value;
  }

  get valor() {
    return this._valor;
  }

  set valor(value) {
    this._valor = value;
  }

  get percProvisao() {
    return this._percProvisao;
  }

  set percProvisao(value) {
    this._percProvisao = value;
  }

  get impostos() {
    return this._impostos;
  }

  set impostos(value) {
    this._impostos = value;
  }

  validarConflitoVinculo(indicadorConf) {
    const conflitoClassificacoes = validarConflitoIds(this._idsClassificacoes, indicadorConf.idsClassificacoes);
    const conflitoCliente = validarConflitoIds(this._idsExtensoesCliente, indicadorConf.idsExtensoesCliente);
    const conflitoProduto = validarConflitoIds(this._idsExtensoesProduto, indicadorConf.idsExtensoesProduto);

    return conflitoClassificacoes && conflitoCliente && conflitoProduto;
  }

  toJSON() {
    const idsClassificacoes = this._idsClassificacoes;
    const idsExtensoesCliente = this._idsExtensoesCliente;
    const idsExtensoesProduto = this._idsExtensoesProduto;
    const tipoValor = this._tipoValor;
    const valor = this._valor;
    const percProvisao = this._percProvisao;
    const impostos = this._impostos;
    return {
      id: this._id,
      idsClassificacoes,
      idsExtensoesCliente,
      idsExtensoesProduto,
      tipoValor,
      valor,
      percProvisao,
      impostos,
    };
  }
}
