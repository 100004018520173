<template>
  <div>
    <v-container fluid px-0 py-2 class='Container_Tabela'>
      <v-row align="center" justify="center">
        <v-container fluid grid-list-md class="Container_Tabela py-3">
          <v-row>
            <v-col cols="12">
            <v-data-table
              id="tabela_notas"
              :headers="headers"
              :items="listaNotasInconsistencia"
              :options.sync="pagination"
              :server-items-length="totalPage"
              :no-data-text="$t('label.tabela_sem_conteudo')"
              :footer-props="{
                 itemsPerPageOptions: [10, 20, 30, 40, 50],
                }"
              >
              <template v-slot:item.acoes="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon
                      v-on="on"
                      @click.stop="openReprocessarDialog(item)">
                      <v-icon color="green">cached</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $tc('label.reprocessar', 1) }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.lote="{ item }">
                {{ item.lote }}
              </template>
              <template v-slot:item.dataRemessa="{ item }">
                {{ item.data_remessa }}
              </template>
              <template v-slot:item.dataEmissao="{ item }">
                {{ item.data_emissao }}
              </template>
              <template v-slot:item.numeroNf="{ item }">
                {{ item.numero_nf }}
              </template>
              <template v-slot:item.numeroPedido="{ item }">
                {{ item.numero_pedido }}
              </template>
              <template v-if="!isSellOut" v-slot:item.nomeCliente="{ item }">
                {{ item.nome_cliente }}
              </template>
              <template v-if="!isSellOut" v-slot:item.divisaoRegional="{ item }">
                {{ item.divisao_regional }}
              </template>
              <template v-if="!isSellOut" v-slot:item.divisaoTerritorio="{ item }">
                {{ item.divisao_territorio }}
              </template>
              <template v-slot:item.nomeCultura="{ item }">
                {{ item.nome_cultura }}
              </template>
              <template v-slot:item.nomeProduto="{ item }">
                {{ item.nome_produto }}
              </template>
              <template v-slot:item.statusPedido="{ item }">
                {{ item.status_pedido }}
              </template>
              <template v-slot:item.tipoDocumento="{ item }">
                {{ item.tipo_documento }}
              </template>
              <template v-slot:item.idExecucao="{ item }">
                {{ item.id_execucao }}
              </template>
              <template v-slot:item.valor="{ item }">
                <v-menu
                  :key="item.id"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  origin="top right"
                  offset-x
                  left>
                  <template v-slot:activator="{ on }">
                    <v-btn text icon v-on="on">
                      <v-icon>attach_money</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-list two-line class="pa-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('label.valor_total') }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ getMoney(item.valor_total) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('label.valor_liquido') }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ getMoney(item.valor_liquido) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('label.valor_total') }} {{ $tc('label.dolar', 1).toLowerCase() }}</v-list-item-title>
                            <v-list-item-subtitle>{{ getMoney(item.valor_total_dolar, 0, 2, '$') }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('label.valor_liquido') }} {{ $tc('label.dolar', 1).toLowerCase() }}</v-list-item-title>
                            <v-list-item-subtitle>{{ getMoney(item.valor_liq_dolar, 0, 2, '$') }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
              <template v-slot:item.erro="{ item }">
                <v-menu
                  :key="item.id + 99"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  origin="top right"
                  offset-x
                  left>
                  <template v-slot:activator="{ on }">
                    <v-btn text icon v-on="on">
                      <v-icon>info</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-list class="pa-0">
                        <v-list-item
                          v-for="(item, index) in item.erros.value.slice(1, -1).split(', ')"
                          :key="index">
                          <v-list-item-content>
                            <v-list-item-subtitle>{{$tc('label.erro', 1)}} {{ index + 1 }}</v-list-item-subtitle>
                            <v-list-item-title>{{ item.slice(1, -1) }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
    <confirm
      ref="reprocessarDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="reprocessarInconsistencia()">
    </confirm>
  </div>
</template>

<script>
import {
  getMoney,
} from '../../produto/common/functions/helpers';
import Confirm from '../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  name: 'NotasListaInconsistenciaDetalhado',
  components: {
    Confirm,
  },
  props: {
    tipoErro: String,
    pesquisaTabela: String,
  },
  data() {
    return {
      resourcesReprocessamento: this.$api.notas(this.$resource),
      menu: false,
      listaNotasInconsistencia: [],
      totalPage: 0,
      inconsistenciaSelecionada: {},
      esperarDigitar: false,
      pagination: {
        rowsPerPage: 10,
        page: 1,
        sortBy: ['lote', 'numero_nota_fiscal'],
        descending: true,
      },
      dialog: {
        from: '',
        title: this.$t('label.atencao'),
        content: '',
        action() {},
      },
      headers: [],
      headers_pedido: [
        {
          text: this.$tc('label.acoes', 2), value: 'acoes', sortable: false, width: '1%', align: 'center',
        },
        {
          text: this.$t('label.lote'), value: 'lote', sortable: true, width: '10%',
        },
        { text: this.$tc('label.data_remessa', 1), value: 'dataRemessa', sortable: true },
        { text: this.$tc('label.numero_pedido', 1), value: 'numeroPedido', sortable: true },
        { text: this.$tc('label.cliente', 1), value: 'nomeCliente', sortable: true },
        { text: this.$tc('label.gr', 1), value: 'divisaoRegional', sortable: true },
        { text: this.$tc('label.territorio', 1), value: 'divisaoTerritorio', sortable: true },
        { text: this.$tc('label.cultura', 1), value: 'nomeCultura', sortable: true },
        { text: this.$tc('label.produto', 1), value: 'nomeProduto', sortable: true },
        { text: this.$tc('label.pedido', 1), value: 'statusPedido', sortable: true },
        { text: this.$tc('label.documento', 1), value: 'tipoDocumento', sortable: true },
        { text: this.$tc('label.execucao', 1), value: 'idExecucao', sortable: true },
        {
          text: this.$tc('label.valor', 2), value: 'valor', sortable: false, width: '2%', align: 'center',
        },
        {
          text: this.$tc('label.erro', 2), value: 'erro', sortable: false, width: '2%', align: 'center',
        },
      ],
      headers_sellin: [
        {
          text: this.$tc('label.acoes', 2), value: 'acoes', sortable: false, width: '1%', align: 'center',
        },
        {
          text: this.$t('label.lote'), value: 'lote', sortable: true, width: '10%',
        },
        { text: this.$tc('label.data_emissao', 1), value: 'dataEmissao', sortable: true },
        { text: this.$tc('label.numero_nota_fiscal', 1), value: 'numeroNf', sortable: true },
        { text: this.$tc('label.cliente', 1), value: 'nomeCliente', sortable: true },
        { text: this.$tc('label.gr', 1), value: 'divisaoRegional', sortable: true },
        { text: this.$tc('label.territorio', 1), value: 'divisaoTerritorio', sortable: true },
        { text: this.$tc('label.cultura', 1), value: 'nomeCultura', sortable: true },
        { text: this.$tc('label.produto', 1), value: 'nomeProduto', sortable: true },
        { text: this.$tc('label.status', 1), value: 'status', sortable: true },
        {
          text: this.$tc('label.valor', 2), value: 'valor', sortable: false, width: '2%', align: 'center',
        },
        {
          text: this.$tc('label.erro', 2), value: 'erro', sortable: false, width: '2%', align: 'center',
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.buscar();
      },
      deep: true,
    },
  },
  computed: {
    isSellIn() {
      if (this.tipoErro === 'SELLIN') {
        return true;
      }
      return false;
    },

    isDevolucao() {
      if (this.tipoErro === 'DEVOLUCAO') {
        return true;
      }
      return false;
    },

    isSellOut() {
      if (this.tipoErro === 'SELLOUT') {
        return true;
      }
      return false;
    },
    isInconsistencia() {
      if (this.visao === 'Inconsistências') {
        return true;
      }
      return false;
    },
  },
  methods: {
    getMoney,

    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },

    buscar() {
      const params = {
        filtro: this.pesquisaTabela,
        tipoErro: this.tipoErro,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.descending,
        colunaOrdenacao: this.pagination.sortBy,
      };

      this.resourcesReprocessamento.listarNotasInconsistenciaDetalhado(params).then((response) => {
        this.listaNotasInconsistencia = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
      }, (err) => {
        this.$error(this, err);
      });
    },

    reprocessarInconsistencia() {
      const inconsistencia = this.inconsistenciaSelecionada;

      const params = {
        tipoErro: this.tipoErro,
        numeroLote: inconsistencia.lote,
        idInconsistencia: inconsistencia.id,
      };

      this.resourcesReprocessamento.reprocessarInconsistencia(params).then((response) => {
        this.$emit('InconsistenciaReprocessada', response);
        setTimeout(() => {
          this.buscar();
          this.$forceUpdate();
        }, 2E2);
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },

    openReprocessarDialog(ref, from) {
      this.inconsistenciaSelecionada = ref;
      this.dialog.content = this.$t('message.inconsistencia_reprocessar');
      this.dialog.action = this.reprocessarInconsistencia;
      this.dialog.from = from;
      setTimeout(() => this.$refs.reprocessarDialog.open());
    },

    pararEsperar() {
      setTimeout(() => {
        this.esperarDigitar = false;
      }, 2E2);
    },

    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },

  },
  mounted() {
    if (this.tipoErro != null && this.tipoErro.length) {
      if (this.tipoErro === 'SELLIN' || this.tipoErro === 'DEVOLUCAO') {
        this.headers = this.headers_sellin;
      } else {
        this.headers = this.headers_pedido;
      }
      if (this.isSellOut) {
        this.headers.splice(3, 2);
      }
      this.buscar();
    }
  },
};
</script>

<style>
.Container_Tabela table.v-table tbody td:first-child,.Container_Tabela  table.v-table tbody td:not(:first-child),
  .Container_Tabela table.v-table tbody th:first-child, .Container_Tabela table.v-table tbody th:not(:first-child),
  .Container_Tabela table.v-table thead td:first-child, .Container_Tabela table.v-table thead td:not(:first-child),
  .Container_Tabela table.v-table thead th:first-child, .Container_Tabela table.v-table thead th:not(:first-child) {
  padding: 0 11px;
}
.Barra_Pesquisa {
  width: 33%;
}
</style>
