var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"title justify-center mt-5",staticStyle:{"text-align":"center"}},[_c('p',[_vm._v(_vm._s(_vm.$tc('title.nota_debito', 2)))])]),_c('v-container',{staticClass:"Pendencias pt-0",attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',{staticClass:"mb-3 px-0",attrs:{"column":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":("" + (_vm.$tc('label.campo_pesquisar_vencimento', 1))),"single-line":"","hide-details":"","clearable":"","solo":""},on:{"input":_vm.filtrar},model:{value:(_vm.pesquisaCards),callback:function ($$v) {_vm.pesquisaCards=$$v},expression:"pesquisaCards"}},[_c('v-menu',{ref:"menu",attrs:{"slot":"append-outer","close-on-content-click":false,"return-value":_vm.filtroAnoMes,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.filtroAnoMes=$event},"update:return-value":function($event){_vm.filtroAnoMes=$event}},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin":"0"},attrs:{"text":"","icon":""}},on),[_c('v-icon',[_vm._v("calendar_today")])],1)]}}]),model:{value:(_vm.menuFiltroData),callback:function ($$v) {_vm.menuFiltroData=$$v},expression:"menuFiltroData"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","type":"month","color":"primary","no-title":"","scrollable":"","allowed-dates":_vm.dataValidaSelecao},model:{value:(_vm.filtroAnoMes),callback:function ($$v) {_vm.filtroAnoMes=$$v},expression:"filtroAnoMes"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.limparFiltroMesNotas()}}},[_vm._v(" "+_vm._s(_vm.$tc('label.limpar', 1))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.filtraMesNotas(_vm.filtroAnoMes)}}},[_vm._v(" "+_vm._s(_vm.$tc('label.filtrar', 1))+" ")])],1)],1)],1)],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"d-flex":"","align":"start","justify":"center"}},[_c('v-data-iterator',{attrs:{"items":_vm.dados,"server-items-length":_vm.totalPage,"options":_vm.pagination,"hide-default-footer":_vm.pagination.escondePaginacao,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
              itemsPerPageOptions: [10, 25, 50],
            }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',{attrs:{"align":"start","justify":"center"}},_vm._l((props.items),function(item,index){return _c('v-col',{key:index,staticClass:"pt-0 pl-0",attrs:{"cols":"12","md":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return _c('v-card',{staticClass:"Card_Pendencia",class:("elevation-" + (hover ? 5 : 1)),attrs:{"fill-height":""}},[_c('v-card-title',{staticClass:"Card_Title pa-0"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('h3',[_vm._v(_vm._s(("#" + (item.idExterno) + " :: " + (item.tipo) + " :: " + (_vm.getMoney(item.valor)))))]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},nativeOn:{"click":function($event){return _vm.abrirDetalhamento(item)}}},on),[_c('v-icon',[_vm._v("info")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('label.detalhe', 2)))])])],1)],1),_c('v-card-text',{staticClass:"Card_Content"},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[(item.extensoes)?_c('span',[_vm._v(_vm._s(("" + (item.extensoes)))+" "),_c('br')]):_vm._e(),_c('span',[_vm._v(_vm._s(((_vm.$t('label.cnpj')) + ": " + (item.desCnpj))))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('span',[_vm._v(_vm._s(((_vm.$t('label.vencimento')) + ": " + (item.vencimento))))])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},nativeOn:{"click":function($event){return _vm.abrirModalConfirmacaoImpressao(item)}}},on),[_c('v-icon',[_vm._v("print")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('label.imprimir'))+" "+_vm._s(_vm.$tc('label.nota_debito', 1))+" ")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},nativeOn:{"click":function($event){return _vm.abrirModalConfirmacaoExportacao(item)}}},on),[_c('v-icon',[_vm._v("get_app")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('label.exportar'))+" "+_vm._s(_vm.$tc('label.nota_debito', 1))+" ")])])],1)],1)],1)],1)],1)],1)],1)}}],null,true)})],1)}),1)]}}])})],1),_c('confirm',{ref:"modalConfirmacao",attrs:{"message":_vm.mensagemConfirmacao},on:{"agree":_vm.validarAcao}})],1)],1),(_vm.visualizaTodasNotas)?_c('v-row',[_c('v-spacer'),_c('v-btn',{staticStyle:{"margin":"0"},attrs:{"color":"accent"},on:{"click":_vm.abrirTodasNotas}},[_vm._v(" "+_vm._s(_vm.$t('label.consultar_todas'))+" ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }