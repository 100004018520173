import Home from '../../produto/spa/Home';
import NotFound from '../../produto/spa/NotFound';
import coreRoutes from '../../produto/config/router/core-router';
import parametrizacaoPeriodo from './parametrizacao-periodo';
import relatorioDpm from './relatorio-dpm';
import ajusteProvisao from './ajuste-provisao';
import exportacoesRelatorio from './exportacoes-relatorios';
import acaoPrevia from './acao-previa';

import { hideLoading } from '../../produto/common/functions/loading';

const configureGuards = (router) => {
  router.afterEach((route) => {
    if (route.params.menu) {
      hideLoading();
    }
  });
};

// sobrescreve a definicao de rota para ajuste de verba
const adamaRoutes = [
  ...coreRoutes,
  parametrizacaoPeriodo,
  relatorioDpm,
  ajusteProvisao,
  exportacoesRelatorio,
  acaoPrevia,
];

export const routes = [
  {
    path: '/',
    name: 'inicio',
    component: Home,
    redirect: '/planejamento-contrato',
    children: adamaRoutes,
  },
  {
    path: '*',
    component: NotFound,
  },
];

export const VueRouterObject = {
  routes,
  saveScrollPosition: true,
};

export default (VueRouter) => {
  const vueRouter = new VueRouter(VueRouterObject);
  configureGuards(vueRouter);
  return vueRouter;
};
