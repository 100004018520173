<template>
  <div class="CardPagamentoContainer">
    <v-container fluid px-0 py-2 class="Container_Nivel2_Pagamento">
      <v-row justify="start" class="mx-0">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            v-model="pesquisaCards"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.pesquisar', 1)}`"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3" align-self="center">
          <v-tooltip bottom v-if="canEdit">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_importar"
                icon
                class="mx-0 pt-1"
                v-on="on"
                @click="abrirModalImportacao">
                <v-icon>backup</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.importar') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row justify="center" class="mx-0">
        <v-container fluid grid-list-md class="Container_Cards">
          <v-data-iterator
            :items="lista"
            :options.sync="pagination"
            :hide-default-footer="12 >= totalPage"
            :server-items-length="totalPage"
            :footer-props="{
              itemsPerPageOptions: rowsPerPageItems,
            }">
            <template v-slot:default="props">
              <v-row justify="start" class="mx-0">
                <v-col
                  v-for="item in props.items"
                  :key="item.id"
                  cols="12"
                  sm="3">
                  <v-hover v-slot:default="{ hover }">
                    <v-card :class="`Card_Pagamento_${item.status} elevation-${hover ? 5 : 1}`" fill-height class="clickable Card_Pagamento">
                      <v-card-title class="Card_Title pa-2 justify-center" @click="selectItem(item)">
                        <popover-lista :campos="camposPopover"
                          :offset-x="false"
                          :offset-y="true" :id="`popover_pagamento_${item.id}`"
                          :objeto="item"
                          style="width:100%" >
                          <template slot="ativadorPopover">
                            <p v-if="isVisaoStatus" style="width: 100%;" class="text-center mb-0">
                              <span style="width: 100%;" class="text-center mb-0">
                                {{ item.codPagador }} - {{ item.nomePagador }}<br />
                              </span>
                              <span v-if="item.id !== item.identificador" style="width: 100%;" class="text-center mb-0">
                                {{ item.idContrato }} - {{ item.idAcao }} - {{ item.identificador }} - {{ item.id }} :: {{ item.nomTipoAcao }}<br />
                              </span>
                              <span v-else style="width: 100%;" class="text-center mb-0">
                                {{ item.idContrato }} - {{ item.idAcao }} - {{ item.identificador }} :: {{ item.nomTipoAcao }}
                              </span>
                            </p>
                            <p v-else style="width: 100%;" class="text-center mb-0">
                              <span style="width: 100%;" class="text-center mb-0">
                                {{ item.nomTipoAcao }}<br />
                              </span>
                              <span v-if="item.id !== item.identificador" style="width: 100%;" class="text-center mb-0">
                                {{ item.idContrato }} - {{ item.idAcao }} - {{ item.identificador }} - {{ item.id }}<br />
                              </span>
                              <span v-else style="width: 100%;" class="text-center mb-0">
                                {{ item.idContrato }} - {{ item.idAcao }} - {{ item.identificador }}
                              </span>
                            </p>
                          </template>
                        </popover-lista>
                      </v-card-title>
                      <v-card-text @click="selectItem(item)" class="Card_Content py-0 headline font-weight-light">
                        <v-row justify="center" align="center">
                          <v-col cols="12" justify="center" align="center">
                            <p nowrap>
                              {{ getMoney(item.valor) }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
            <template v-slot:no-data>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
            <template v-slot:no-results>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-row>
    </v-container>
    <modal-importacao-pagamento
      ref="modalImportacaoPagamento"
      @RECARREGAR_PAGAMENTO="buscar">
    </modal-importacao-pagamento>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  getMoney,
  getPercent,
} from '../../../common/functions/helpers';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import { listarPagamentosNivel2, listarPagamentosNivel2Simplificado } from '../../../common/resources/liquidacao/pagamento';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import PopoverLista from '../../../shared-components/PopoverLista';
import ModalImportacaoPagamento from './ModalImportacaoPagamento';

export default {
  name: 'PagamentoDadosNivel2Agrupado',
  components: {
    Confirm,
    PopoverLista,
    ModalImportacaoPagamento,
  },
  props: {
    filtros: Object,
    objetoAcao: Object,
  },
  data() {
    return {
      status: null,
      idContrato: null,
      origem: null,
      pesquisaCards: '',
      objetoSelecionado: '',
      lista: [],
      filtrosAplicados: {},
      rowsPerPageItems: [12],
      pagination: {
        itemsPerPage: 12,
      },
      totalPage: 0,
      buscando: false,
      esperarDigitar: false,
      acaoConfirmacao: null,
      idPagamento: null,
      numNota: null,
    };
  },
  watch: {
    filtroLiquidacaoContrato: {
      handler() {
        this.filtrosAplicados = this.filtroLiquidacaoContrato;
        this.filtrar();
      },
    },
    pagination: {
      handler() {
        if (this.filtroLiquidacaoContrato) {
          this.filtrar();
        }
      },
      deep: true,
    },
  },
  computed: {
    isVisaoStatus() {
      return !!this.status;
    },
    camposPopover() {
      return [
        {
          campo: 'descricaoAcao',
          label: this.$t('label.descricao_acao'),
        },
        {
          campo: 'idContrato',
          label: this.$t('label.cod_contrato'),
        },
        {
          campo: 'idAcao',
          label: this.$t('label.cod_acao'),
        },
        {
          campo: 'identificador',
          label: this.$tc('label.cod_apuracao', 1),
        },
        {
          campo: 'id',
          label: this.$tc('label.cod_pagamento', 1),
        },
        {
          campo: 'status',
          label: this.$t('label.status'),
          formatarCampos: this.i18nStatus,
        },
      ];
    },
    isVisaoContrato() {
      return !!this.idContrato;
    },
    ...mapGetters('filtros', [
      'filtroLiquidacaoContratoNivel2',
      'filtroLiquidacaoContrato',
    ]),
    ...generateComputed('CTRT_PGTO', [
      'canEdit',
    ]),
  },
  methods: {
    getMoney,
    getPercent,
    ...mapActions('filtros', [
      'setFiltroLiquidacaoContratoNivel2',
    ]),
    selectItem(item) {
      this.consultar(listarPagamentosNivel2Simplificado, (response) => {
        const listaSimplificada = response.data;

        const params = {
          id: item.id,
          idAcao: item.idAcao,
          objetoAcao: item,
          liquidacoes: listaSimplificada,
        };

        if (this.isVisaoStatus) {
          params.status = this.status;
          this.$router.push({ name: 'detalharPagamentoContratoStatus', params });
        }

        const query = { origem: this.origem };

        if (this.isVisaoContrato) {
          params.idContrato = this.idContrato;
          this.$router.push({ name: 'detalharPagamentoContratoContrato', params, query });
        }
      });
    },
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },
    buscar() {
      this.consultar(listarPagamentosNivel2, (response) => {
        this.lista = response.data.resposta;
        this.rowsPerPageItems = [4, 8, 12, 16, 20, 24, 28, 32, 36, 40];
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.preencherFiltrosNivel1Vuex();
        setTimeout(this.finalizandoBusca);
      });
    },
    consultar(consulta, sucesso) {
      if (this.objetoSelecionado) {
        if (this.buscando) return;
        this.buscando = true;

        const params = {
          ...this.filtrosAplicados,
          visao: 'visao_contrato',
          filtro: this.pesquisaCards,
          tamanhoPagina: this.pagination.itemsPerPage,
          numeroPagina: this.pagination.page,
        };

        if (this.isVisaoStatus) {
          params.status = this.status;
        } else if (this.isVisaoContrato) {
          params.cod_contrato = this.idContrato;
        }

        consulta(params, this.$resource)
          .then(sucesso)
          .catch((err) => {
            this.finalizandoBusca();
            this.$error(this, err);
          });
      }
    },
    finalizandoBusca() {
      this.buscando = false;
    },
    validarAcao() {
      if (this.acaoConfirmacao) {
        this.acaoConfirmacao();
      }
    },
    preencherFiltrosRouter() {
      this.status = this.$route.params.status;
      this.idContrato = this.$route.params.idContrato;
      this.origem = this.$route.query.origem;
    },
    preencherFiltrosNivel2() {
      this.pagination.page = this.filtroLiquidacaoContratoNivel2.pagina;
      this.pagination.itemsPerPage = this.filtroLiquidacaoContratoNivel2.quantidatePorPagina;
    },
    preencherFiltrosNivel1Vuex() {
      const pagina = this.pagination.page;
      const quantidatePorPagina = this.pagination.itemsPerPage;

      this.setFiltroLiquidacaoContratoNivel2({
        pagina,
        quantidatePorPagina,
      });
    },
    abrirModalImportacao() {
      if (this.$refs.modalImportacaoPagamento) {
        this.$refs.modalImportacaoPagamento.toggle();
      }
    },
  },
  beforeMount() {
    this.preencherFiltrosRouter();
    this.objetoSelecionado = this.objetoAcao;
  },
  mounted() {
    if (this.filtroLiquidacaoContratoNivel2) {
      this.preencherFiltrosNivel2();
    }
    if (this.filtroLiquidacaoContrato && this.objetoSelecionado) {
      this.filtrosAplicados = this.filtroLiquidacaoContrato;
      this.filtrar();
    }
  },
};
</script>

<style>
.Container_Nivel2_Pagamento .Card_Pagamento {
  min-height: 146px !important;
  height: 100%;
}
.Container_Nivel2_Pagamento .Card_Title, .Container_Nivel2_Pagamento .Card_Content {
  color: #757575;
}
.clickable {
  cursor: pointer;
}
</style>
<style src="../../../assets/css/card-status-pagamento.css" lang="css"></style>
