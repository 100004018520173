<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="400"
    transition="scale-transition"
    offset-x
    left>
    <template v-slot:activator="{ on: menuFunction }">
      <v-list-item v-on="menuFunction" icon
        :color="corIcone"
        v-if="layoutGrid"
        class="AcaoFluxo__listItem">
        <v-list-item-action>
          <v-icon>{{ icone }}</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ textoAcao }}</v-list-item-title>
      </v-list-item>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn v-on="{ ...tooltip, ...menuFunction }" icon :color="corIcone">
            <v-icon>{{ icone }}</v-icon>
          </v-btn>
        </template>
        <span>{{ textoAcao }}</span>
      </v-tooltip>
    </template>

      <v-card>

      <v-card-title>
        <div>
          <h3 class="headline mb-0">{{ textoAcao }}</h3>
        </div>
      </v-card-title>

        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  id="Acaofluxo_justificativa"
                  index="1"
                  ref="textareaJustificativa"
                  name="Acaofluxo_justificativa"
                  v-model="justificativa"
                  auto-grow
                  autofocus
                  rows="1"
                  :rules="[required]"
                  :disabled="bloquearCampos"
                  :label="validarJustificativa ? `${$t('label.justificativa')} *` : $t('label.justificativa')"
                  :counter="500"
                  maxlength="500">
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <metadados-container-layout v-if="mostrarCamposDinamicos"
                :metadados-entidade="metadadosCampos"
                :layout-class="layoutClass"
                :input-layout="inputLayout"
                :objeto="acao"
                :somenteLeitura="!mostrarRodape"
                ref="container">
              </metadados-container-layout>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="acaoProrrogacao">
                <metadados-campo-dinamico-data
                  :metadados="cfgCampoProrrogacao"
                  v-model="dtaProrrogacao"/>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

      <v-card-actions
        v-if="mostrarRodape">
        <v-spacer></v-spacer>

          <v-tooltip bottom class="mr-3">
            <template v-slot:activator="{ on }">
              <v-btn v-if="icone !== 'cancel'" icon v-on="on" @click="cancelar" :disabled="bloquearCampos">
                <v-icon>cancel</v-icon>
              </v-btn>
              <v-btn v-if="icone === 'cancel'" color="primary darken-1" text @click="cancelar" :disabled="bloquearCampos">Não</v-btn>
            </template>
            <span>{{ $t('label.cancelar') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-if="icone !== 'cancel'" v-on="on" icon :color="corIcone" @click="executarAcao" :disabled="bloquearCampos">
                <v-icon>{{ icone }}</v-icon>
              </v-btn>
              <v-btn v-if="icone === 'cancel'" text color="primary darken-1" @click="executarAcao" :disabled="bloquearCampos">Sim</v-btn>
            </template>
            <span>{{ textoAcao }}</span>
          </v-tooltip>
        </v-card-actions>

    </v-card>

    <v-card v-if="exibirPerguntaProximo">
      <v-card-text>
        {{ $t('message.deseja_seguir_proxima_apuracao') }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="naoIrProximo" color="primary">
          {{ $t('label.nao') }}
        </v-btn>

        <v-btn @click="irProximo" color="accent">
          {{ $t('label.sim') }}
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import MetadadosContainerLayout from './metadados/MetadadosContainerLayout';
import MetadadosCampoDinamicoData from './metadados/campos-dinamicos/MetadadosCampoDinamicoData';

export default {
  name: 'AcaoFluxo',
  props: {
    acao: Object,
    configuracao: {
      type: Object,
      default: null,
    },
    corIcone: {
      type: String,
      default: null,
    },
    icone: {
      type: String,
      default: '',
    },
    disabled: false,
    status: {
      type: String,
      default: '',
    },
    perguntarProximo: {
      type: Boolean,
      default: false,
    },
    validarJustificativa: {
      type: Boolean,
      default: false,
    },
    textoAcao: {
      type: String,
      default: '',
    },
    mostrarRodape: {
      type: Boolean,
      default: true,
    },
    mostrarCamposDinamicos: {
      type: Boolean,
      default: false,
    },
    acaoProrrogacao: {
      type: Boolean,
      default: false,
    },
    observacaoAcao: {
      type: String,
      default: undefined,
    },
    layoutGrid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      valid: false,
      bloquearCampos: false,
      justificativa: '',
      dtaProrrogacao: null,
      exibirPerguntaProximo: false,
      metadados: null,
      layoutClass: {
        wrap: true,
      },
      inputLayout: {
        xs12: true,
        sm6: true,
        md12: true,
      },

      cfgCampoProrrogacao: {
        vlrObrigatorio: true,
        nomCampo: this.$t('label.data_prorrogacao'),
        desAtributos: {
          dtaMinima: 'amanha',
        },
      },
    };
  },
  watch: {
    menu(val) {
      if (val) {
        setTimeout(() => this.$refs.textareaJustificativa.focus(), 5E2);
        this.$emit('AcaoFluxo_menuAberto');
      }
    },
  },
  components: {
    MetadadosContainerLayout,
    MetadadosCampoDinamicoData,
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    metadadosCampos() {
      if (this.getAcaoMetadado && this.getAcaoMetadado.mapaCamposDinamicos) {
        const metadados = { ...this.getAcaoMetadado };
        const resultado = {};
        const campos = metadados.mapaCamposDinamicos;
        if (this.configuracao.passo3) {
          this.configuracao.passo3.listaDinamica.forEach((a) => {
            Object.keys(campos).forEach((key) => {
              if (key === a.label && a.indHabilitaAprovacaoApuracao) {
                resultado[key] = campos[key];
              }
            });
          });
        }
        metadados.mapaCamposDinamicos = resultado;
        metadados.mapaEntidades = {};
        metadados.dicionario = {};
        return metadados;
      }
      return undefined;
    },
  },
  methods: {
    preencheJustificativa(observacao) {
      this.bloquearCampos = true;
      this.justificativa = observacao;
    },
    required(v) {
      return !this.validarJustificativa || !!v || this.$t('label.campo_obrigatorio');
    },
    cancelar() {
      this.fechar();
    },
    fechar() {
      this.justificativa = '';
      this.dtaProrrogacao = null;
      this.menu = false;
      this.exibirPerguntaProximo = false;
      this.desbloquearCampos();
    },
    naoIrProximo() {
      this.$emit('AcaoFluxo_executarAcao',
        this.justificativa,
        false,
        this.getValoresMetadados(),
        this.dtaProrrogacao);
      this.fechar();
    },
    irProximo() {
      this.$emit('AcaoFluxo_executarAcao',
        this.justificativa,
        true,
        this.getValoresMetadados(),
        this.dtaProrrogacao);
      this.fechar();
    },
    acaoExecutada() {
      this.exibirPerguntaProximo = true;
    },
    desbloquearCampos() {
      this.bloquearCampos = false;
    },
    executarAcao() {
      if (!this.$refs.form.validate()) return;
      this.bloquearCampos = true;
      if (this.perguntarProximo) {
        this.acaoExecutada();
      } else {
        this.naoIrProximo();
      }
    },
    getStatus(status) {
      return {
        APROVADO: this.$tc('status_entidade.aprovado', 1),
        AGUARDANDO_APROVACAO: this.$tc('status_entidade.aprovado', 1),
        EM_ANALISE: this.$tc('status_entidade.em_analise', 1),
        REPROVADO: this.$tc('status_entidade.reprovado', 1),
      }[status];
    },
    getValoresMetadados() {
      if (this.mostrarCamposDinamicos) {
        return { ...this.$refs.container.getValoresCamposDinamicos() };
      }
      return undefined;
    },
    preencherJustificativa() {
      if (this.observacaoAcao) {
        this.justificativa = this.observacaoAcao;
      }
    },
  },
  beforeMount() {
    this.preencherJustificativa();
  },
};
</script>
