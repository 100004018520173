var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-card',{staticClass:"card-size",attrs:{"height":"100%"}},[_c('v-card-title',[_c('h2',{staticClass:"headline mb-0",domProps:{"textContent":_vm._s(_vm.title)}})]),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},on:{"input":_vm.filtrar},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),(!_vm.somenteLeitura)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.canAccessCRUD)?_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length),expression:"selected.length"}],staticClass:"mx-0 mt-5",attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.dialogConfirmarInativacao()}}},on),[_c('v-icon',[_vm._v("block")])],1):_vm._e()]}}],null,false,2745552100)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.ativar_inativa')))])]):_vm._e()],1),_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.listaAlcadas,"item-class":_vm.onItemClass,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
        itemsPerPageOptions: [10, 25, 50],
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
      var item = ref.item;
return [(_vm.canAccessCRUD)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onEditarAlcada(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.editar')))])]):_vm._e()]}},{key:"item.fluxo",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.fluxo.nome)+" ")]}},{key:"item.nom_estrutura_usuario",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.estruturaUsuario.nomEstruturaUsuario)+" ")]}},{key:"item.vlr_limite_inicial",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertToMoney(item.vlrLimiteInicial))+" ")]}},{key:"item.vlr_limite_final",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.vlrLimiteFinal ? _vm.convertToMoney(item.vlrLimiteFinal) : '')+" ")]}},{key:"item.workflow",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.workflow.descricao)+" ")]}},{key:"item.ind_ativo",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.indAtivo ? _vm.$t('label.sim') : _vm.$t('label.nao'))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('confirm',{ref:"dialogConfirmaInativacao",attrs:{"message":_vm.$t('message.deseja_ativar_inativar_registros', {quantidade: _vm.selected.length}),"persistent":true},on:{"agree":_vm.ativarInativarRegistros}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }