<template>
  <parametrizacao-periodo-passo
    tem-proximo
    tem-anterior
    :somente-leitura="somenteLeitura"
    @PARAMETRIZACAO_PERIODO_PASSO_VOLTAR="voltarPasso"
    @PARAMETRIZACAO_PERIODO_PASSO_PROXIMO="salvarEProximoPasso"
    @PARAMETRIZACAO_PERIODO_PASSO_SALVAR_RASCUNHO="salvarRascunho">
    <v-form ref="formGatilhos" lazy-validation>
      <v-container fluid>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ $tc('title.gatilho', 2) }}</v-toolbar-title>
        </v-toolbar>
        <v-row class="mx-4 my-2">
          <v-col cols="12" sm="8" md="6" lg="3" xl="2">
            <input-decimal
              :negative="false"
              :label="`${$tc('label.atingimento_marca_forte', 2)} *`"
              :precision="2"
              v-model="passo5.atingimentoMarcasFortes"
              :disabled="somenteLeitura"
              suffix="%"
              class="v-text-field"/>
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-col cols="12" sm="8" md="6" lg="3" xl="2">
            <input-decimal
              :negative="false"
              :label="`${$tc('label.atingimento_total', 2)} *`"
              :precision="2"
              v-model="passo5.atingimentoTotal"
              :disabled="somenteLeitura"
              suffix="%"
              class="v-text-field"/>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </parametrizacao-periodo-passo>
</template>

<script>
import ParametrizacaoPeriodoPasso from '../ParametrizacaoPeriodoPasso';
import ParametrizacaoPeriodo from '../ParametrizacaoPeriodo';
import Passo5 from './Passo5';
import InputDecimal from '../../../../produto/shared-components/inputs/InputDecimal';

export default {
  name: 'ParametrizacaoPeriodoPasso5',
  components: {
    InputDecimal,
    ParametrizacaoPeriodoPasso,
  },
  props: {
    idPeriodo: {
      type: Number,
      default: null,
    },
    periodoConfiguracao: {
      type: ParametrizacaoPeriodo,
      default: null,
    },
    exibindo: false,
    somenteLeitura: false,
  },
  data() {
    return {
      parametrizacaoPeriodoPasso5: this.$api.parametrizacaoPeriodoPasso5(this.$resource),
      passo5: new Passo5(),
      carregado: false,
    };
  },
  watch: {
    exibindo(val) {
      if (val && !this.carregado) {
        this.buscarPasso();
      }
    },
  },
  methods: {
    buscarPasso() {
      if (!this.idPeriodo) return;
      const id = this.idPeriodo;
      this.parametrizacaoPeriodoPasso5.obterPasso5({ id })
        .then((response) => {
          this.passo5 = new Passo5(response.body);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_PERIODO_PASSO5_VOLTAR');
    },
    salvarEProximoPasso() {
      if (this.somenteLeitura) {
        this.proximoPasso();
      } else if (this.$refs.formGatilhos.validate()) {
        this.parametrizacaoPeriodoPasso5.salvarPasso5({ id: this.idPeriodo }, this.passo5)
          .then(() => {
            this.proximoPasso();
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    proximoPasso() {
      this.$emit('PARAMETRIZACAO_PERIODO_PASSO5_PROXIMO', this.passo5);
    },
    salvarRascunho() {
      this.parametrizacaoPeriodoPasso5.salvarRascunhoPasso5({ id: this.idPeriodo }, this.passo5)
        .then(() => {
          this.$toast(this.$t('message.rascunho_salvo'));
          this.buscarPasso();
          this.$emit('PARAMETRIZACAO_PERIODO_PASSO5_RASCUNHO');
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    this.buscarPasso();
  },
};
</script>
