<template>
  <v-card style="margin-bottom:1em" class="RelatorioExportacoesSaldoFiltros">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.exportacao-extrat-rebate-adama', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px">
          fa-thumbtack
        </v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-select
              id="id_tipo_verba"
              name="id_tipo_verba"
              :items="tiposVerbas"
              v-model="filtrosExportacaoExtratoRebate.idTipoVerba"
              item-text="nome"
              item-value="id"
              :label="`${$tc('label.tipo_verba', 1)} *`"
              @change="burcarPeriodos"
              :rules="[rules.required]"
              >
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              id="id_periodos"
              name="id_periodos"
              :items="periodos"
              v-model="filtrosExportacaoExtratoRebate.idPeriodo"
              item-text="descricao"
              item-value="id"
              :label="`${$tc('label.periodo_orcamentario', 1)} *`"
              :disabled="!filtrosExportacaoExtratoRebate.idTipoVerba"
              :rules="[rules.required]"
              >
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" >
             <v-autocomplete
              id="campo_nome_fantasia"
              name="campo_nome_fantasia"
              v-model="filtrosExportacaoExtratoRebate.idsNomeFantasia"
              :items="listaNomesFantasia"
              @click.native="buscarNomesFantasia"
              :search-input.sync="buscaListaNomesFantasia"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomExtensao"
              item-value="id"
              :label="`${$tc('label.nome_fantasia', 1)}`"
              clearable
              multiple
              >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" >
             <v-autocomplete
              id="campo_estrutura_venda"
              name="campo_estrutura_venda"
              v-model="filtrosExportacaoExtratoRebate.idsEstruturaVenda"
              :items="listaEstruturasVenda"
              @click.native="buscarEstrurasVenda"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomeComposto"
              item-value="id"
              :label="`${$tc('label.divisao', 1)}`"
              clearable
              multiple
              >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" >
             <v-autocomplete
              id="campo_grupo_cultura"
              name="campo_grupo_cultura"
              v-model="filtrosExportacaoExtratoRebate.idsGrupoCultura"
              :items="listaGruposCultura"
              @click.native="buscarGruposCultura"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomComposto"
              item-value="id"
              :label="`${$tc('label.grupo_cultura', 1)}`"
              clearable
              multiple
              >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { skipLoading } from '../../../../produto/common/functions/loading';
import { removeEmptyProperties } from '../../../../produto/common/functions/helpers';

export default {
  name: 'RelatorioExportacaoExtratoRebate',
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      exportacaoExtratoRebateResource: this.$api.exportacaoExtratoRebate(this.$resource),
      extensaoAdama: this.$api.extensaoAdama(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      exportacaoAcaoPagamentoResource: this.$api.exportacaoAcaoPagamento(this.$resource),

      buscaListaNomesFantasia: null,
      mostrarFiltros: false,
      filtrosFixados: false,
      workspace: {},
      filtrosExportacaoExtratoRebate: {},
      tiposVerbas: [],
      periodos: [],
      listaNomesFantasia: [],
      listaEstruturasVenda: [],
      listaGruposCultura: [],
      entidadeWorkspace: 'extrato-rateio-adama',
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  watch: {
    buscaListaNomesFantasia(val) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (val != null) this.buscarNomesFantasia(val);
      }, 500);
    },
  },
  methods: {
    carregarWorkspaceFiltros() {
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          this.filtrosFixados = this.workspace.indAberto || false;
          this.filtrosAbertos = this.filtrosFixados;
          this.filtrosExportacaoExtratoRebate = this.workspace.filtros || {};
          this.mostrarFiltros = this.filtrosFixados;
        }).catch((err) => {
          this.$error(this, err);
        }).finally(() => {
          this.carregouCampos = true;
        });
    },
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    resetaFiltros() {
      this.filtrosExportacaoExtratoRebate = {};
      this.$refs.form.reset();
    },
    aplicarFiltros() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let quantidadeRegistros = 0;
      this.exportacaoExtratoRebateResource
        .salvarFiltrosExecucaoExtratoRebate(removeEmptyProperties(this.filtrosExportacaoExtratoRebate))
        .then((response) => {
          this.resetaFiltros();
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > 0) {
            this.$toast(this.$t('message.relatorio_estrato_rebate_adama_processado'));
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosExportacaoExtratoRebate;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    busrcarTiposDeVerba() {
      this.orcamentoTipoVerbaResource
        .buscarAtivos().then((response) => {
          this.tiposVerbas = response.data;
        }).catch((err) => {
          this.$toast(err.data.error);
        });
    },
    burcarPeriodos() {
      const param = {
        idTipoVerba: this.filtrosExportacaoExtratoRebate.idTipoVerba,
      };
      this.exportacaoExtratoRebateResource
        .buscarPeriodosAnoFiscal(param)
        .then((response) => {
          this.periodos = response.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarNomesFantasia(busca = null) {
      if (typeof busca !== 'string') {
        busca = null;
      }
      const param = {
        autocomplete: busca,
      };
      return this.extensaoAdama.buscarNomesFantasiaRelatorio(param)
        .then((response) => {
          this.listaNomesFantasia = response.data;
        });
    },
    buscarEstrurasVenda(autocomplete) {
      this.planejamentoAcaoResource
        .buscarDivisoesPorVisao({ autocomplete }).then((response) => {
          this.listaEstruturasVenda = response.data.map((item) => {
            item.nomeComposto = item.idExterno.concat(' - ').concat(item.nome);
            return item;
          });
        });
    },
    buscarGruposCultura(autocomplete) {
      this.exportacaoExtratoRebateResource.buscarGruposCutura({ autocomplete }).then((response) => {
        this.listaGruposCultura = response.data;
      });
    },
  },
  beforeMount() {
    this.busrcarTiposDeVerba();
    this.carregarWorkspaceFiltros();
  },
};
</script>
