<template>
  <v-card class="elevation-2">
    <v-card-title class="title historico_header px-3 py-2">
      <div class="title" v-if="item.usuarioAprovador">
        <span v-if="usuarioCliente && item.usuarioAprovador.grupo !== 'Cliente'">
          {{$t('label.avaliado_por') + ' ' + item.usuarioAprovador.grupo}}
        </span>
        <span v-else>
          {{$t('label.avaliado_por') + ' ' + item.usuarioAprovador.nome + ' ' +
        item.usuarioAprovador.sobrenome}}
        </span>
        <span v-if="item.idCondicional === 2" ><br/>{{ cliente }}</span>
      </div>
      <div class="title" v-if="aguardandoAprovacao(item)">
        {{$t('label.aprovadores')}}
      </div>
      <div class="title" v-if="transferido(item)">
        {{$t('label.aprovador_anterior')}}
      </div>
      <v-spacer></v-spacer>
      <slot name="historico"></slot>
    </v-card-title>

    <v-card-text class="historico_body">
      <v-row v-if="aguardandoAprovacao(item) || transferido(item)">
        <v-list two-line>

          <v-list-item v-if="item.usuariosAprovadores.usuarios"
                       v-for="(usuario, uIndex) in item.usuariosAprovadores.usuarios"
                       :key="`${usuario.id}-${uIndex}`">
            <v-list-item-avatar>
              <v-icon v-if="uIndex === 0" color="primary">person</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                  v-if="transferido(item) && item.idCondicional === 2">
                {{item.usuarioTransferenciaPasso.usuarioCliente}}
              </v-list-item-title>
              <v-list-item-title v-else-if="usuarioCliente">{{usuario.grupo}}</v-list-item-title>
              <v-list-item-title v-else>{{usuario.nome}}</v-list-item-title>
              <v-list-item-subtitle>{{$tc('label.usuario', 1)}}</v-list-item-subtitle>
              <v-list-item-subtitle
                  v-if="item.idCondicional === 2">
                {{ cliente }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="item.usuariosAprovadores.grupos"
                       v-for="(grupo, pIndex) in item.usuariosAprovadores.grupos"
                       :key="`${grupo.id}-${pIndex}`">
            <v-list-item-avatar>
              <v-icon v-if="pIndex === 0" color="primary">group_work</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-if="!indModoVarejo || grupo.nome !== 'Fornecedor'">{{grupo.nome}}</v-list-item-title>
              <v-list-item-title v-else-if="nomeFornecedorAprovador">{{ grupo.nome }} - {{ nomeFornecedorAprovador }}</v-list-item-title>
              <v-list-item-title v-else>{{ grupo.nome }}</v-list-item-title>
              <v-list-item-subtitle>{{$tc('label.perfil', 1)}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="item.usuariosAprovadores.subgrupos"
                       v-for="(subgrupo, eIndex) in item.usuariosAprovadores.subgrupos"
                       :key="`${subgrupo.id}-${eIndex}`">
            <v-list-item-avatar>
              <v-icon v-if="eIndex === 0" color="primary">recent_actors</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{subgrupo.nome}}</v-list-item-title>
              <v-list-item-subtitle>{{$tc('label.estrutura_usuario', 0)}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-row>

      <v-row v-if="acaoPossuiClienteAprovador(item)" class="px-3 py-2">
        <span><b>{{$t('label.aprovador')}}:&nbsp;</b></span>
        <span><b>{{clienteAprovador.nome}}</b></span>
      </v-row>

       <v-row v-if="acaoPossuiClienteAprovador(item)" class="px-3 py-2">
        <span><b>{{$t('label.email')}}:&nbsp;</b></span>
        <span><b>{{clienteAprovador.email }}</b></span>
      </v-row>

      <v-row v-if="item.sla" class="px-3 py-2">
        <span><b>{{$t('label.sla')}}:&nbsp;</b></span>
        <span>{{item.sla}}</span>
      </v-row>
      <v-row v-if="transferido(item)" class="px-3 py-2">
        <span><b>{{$t('label.transferido_por')}}:&nbsp;</b></span>
        <span>{{item.usuarioTransferenciaPasso.usuarioTransferencia}}</span>
      </v-row>
      <v-row v-if="transferido(item)" class="px-3 py-2">
        <span><b>{{$t('label.data_transferencia')}}:&nbsp;</b></span>
        <span>{{item.usuarioTransferenciaPasso.dtaTransferencia}}</span>
      </v-row>
      <v-row v-if="!aguardandoAprovacao(item) && !transferido(item)" class="px-3 py-2">
        <span><b>{{$t('label.data')}}:&nbsp;</b></span>
        <span v-if="item.dtaAvaliacao">{{toDate(item.dtaAvaliacao)}}</span>
        <span v-if="!item.dtaAvaliacao">{{$t('label.data_indefinida')}}</span>
      </v-row>
      <v-row v-if="item.observacao" class="px-3 py-2">
        <span><b>{{$tc('label.observacao', 1)}}:&nbsp;</b></span>
        <span>{{item.observacao}}</span>
      </v-row>
      <v-row v-if="item.dados" v-for="(dados, xIndex) in item.dados" :key="xIndex" class="px-3 py-2">
        <span> {{dados}} </span>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import PopoverLista from '../PopoverLista';

export default {
  components: {
    PopoverLista,
  },
  name: 'AcompanhamentoItem',
  props: {
    item: {
      type: Object,
      default: {},
    },
    cliente: null,
    entidade: String,
    idEntidade: Number,
  },
  data() {
    return {
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      nomeFornecedorAprovador: null,
      clienteAprovador: '',
    };
  },
  watch: {
    item: {
      handler() {
        if (this.entidade === 'ACAO') {
          this.getClienteAprovador();
        }
        if (this.indModoVarejo) {
          this.getNomeFornecedorAprovador();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    usuarioCliente() {
      return this.usuarioLogado.tipo === 'CLIENTE';
    },
  },
  methods: {
    acaoPossuiClienteAprovador(passo) {
      let acaoPossuiClienteAprovador = false;
      if (passo.usuariosAprovadores != null && passo.usuariosAprovadores.grupos != null) {
        const item = passo.usuariosAprovadores.grupos;
        item.forEach((i) => {
          if (i.nome.indexOf('Cliente') !== -1) {
            acaoPossuiClienteAprovador = true;
          }
        });
      }
      return acaoPossuiClienteAprovador;
    },
    aguardandoAprovacao(passo) {
      return passo.statusPasso === 'AGUARDANDO_APROVACAO';
    },
    transferido(passo) {
      return passo.statusPasso === 'TRANSFERIDO';
    },
    toDate(data) {
      return this.moment(data, 'DD/MM/YYYY hh:mm:ss').format('DD-MM-YYYY HH:mm:ss');
    },
    getNomeFornecedorAprovador() {
      if (!this.indModoVarejo) {
        return '';
      }
      return new Promise((resolve, reject) => {
        const id = this.idEntidade;
        if (!this.entidade) {
          return;
        }
        let workflowResource = null;

        if (this.entidade === 'ACAO') {
          workflowResource = this.workflowAcaoResource;
        } else {
          workflowResource = this.workflowApuracaoAcaoResource;
        }

        workflowResource.nomeFornecedorAprovador({ id }).then((response) => {
          this.nomeFornecedorAprovador = response.bodyText;
          this.$forceUpdate();
          resolve();
        }).catch(reject);
      });
    },
    getClienteAprovador() {
      return new Promise((resolve, reject) => {
        const id = this.idEntidade;
        this.workflowAcaoResource.buscarClienteAprovador({ id }).then((response) => {
          this.clienteAprovador = response.body;
          this.$forceUpdate();
          resolve();
        }).catch(reject);
      });
    },
  },
  mounted() {
    if (this.entidade === 'ACAO') {
      this.getClienteAprovador();
    }
    if (this.indModoVarejo) {
      this.getNomeFornecedorAprovador();
    }
  },
};
</script>
