<style>
.FormPrincipal {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.title {
  margin: 30px;
  margin-left: 10px;
}

.filled {
  background-color: #eeeeee !important;
  height: 52px;
  padding-top: 18px;
  padding-left: 6px
}

.Card_Principal {
  margin: auto;
  width: 98%;
}
.activeRadio  {
  color: rgb(255, 255, 255) !important;
  padding: 30px !important;
  background-color: #9D1D96 !important;
}

.activeRadio > input  {
  color: rgb(255, 255, 255) !important;
  border-color: #f0eaf1;
  background-color: rgb(255, 255, 255) !important;
}

.radioBtn {
  margin: 10px;
}

.activeRadio .trucksicons  {
  color: rgb(255, 255, 255) !important;
  border-color: #f0eaf1;
  background-color: rgb(255, 255, 255) !important;
}

.activeRadio .v-input--selection-controls {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(255, 255, 255) !important;
}

.active .trucksicons{
  border: 2px solid rgb(255, 255, 255) !important;
}

.active > input{
  border: 2px solid rgb(255, 255, 255) !important;
}

.inactiveRadio {
  padding: 30px !important;
}
.white-radio .v-input--selection-controls__input:checked + .v-input--selection-controls__ripple {
  background-color: white !important;
  border-color: white !important;
}
.white-radio .v-input--selection-controls__ripple {
  background-color: transparent !important;
}

.white-radio .v-input--selection-controls__input:checked {
  background-color: transparent !important;
}

.white-radio .v-input--selection-controls__input:checked .v-input--selection-controls__ripple {
  background-color: white !important;
}

.container-conta-corrente {
  background-color: #f0eaf1;
  margin-top: 16px;
}

.dropzone .dz-message {
  margin: 10px 0 0 0;
}

.bg-warning {
  text-align: start;
  vertical-align: middle;
  line-height: 45px;
  color: #F89E18;
  border-color: #F89E18;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px 2px;
  background-color: #fcedd7;
  height: 50px;
  margin: 15px;
}
.bg-warning i {
  padding-left: 30px;
  padding-right: 15px;
}

.bg-error {
  text-align: start;
  vertical-align: middle;
  height: 80px;
  padding: 10px;
}

.bg-error i {
  padding-left: 30px;
  padding-right: 15px;
}

fieldset {
  height: 40px;
  padding: 0px;
}

.inputTabela .v-input__slot {
  height: 40px;
  border-radius: 5px;
}

.inputTabela .v-input__control{
  height: 40px;
}

.inputTabela > input{
  padding: 100px;
}

.inputTabela .v-text-field__slot  {
  height: 40px;
}

.saldo .v-input__control{
  height: 40px;
}

.v-data-table__wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: lightgray;
}
.btnNovaSolicitacao {
  color: #752157 !important;
  margin-right: 30px;
}
.btnNovaSolicitacaoDisabled {
  color: #97768c !important;
  pointer-events: none;
  margin-right: 30px;
}
.custom-container .container {
  max-width: 100%;
}
</style>
<template>
<div class="PlanejamentoAcaoFormCampos">
  <v-form ref="formGeral"
          class="FormPrincipal"
          v-model="formGeralValido"
          lazy-validation
          autocomplete="off">
    <div class="title-float title d-flex space-between flex-row" style="justify-content: space-between;">
      <div style="width: 300px;">
        <h2 slot="titulo-acao">
          {{ tituloTipoAcao }}
        </h2>
      </div>
      <div>
        <v-btn style="height: 40px;"
        slot="acoes-padrao"
        @click="novaSolicitacao()"
        dark
        :class="isEdicao ? 'btnNovaSolicitacao' : 'btnNovaSolicitacaoDisabled'"
              outlined>
              <v-icon>add</v-icon>
              {{ $t('label.nova_solicitacao') }}
              </v-btn>
      </div>
    </div>
    <v-card class="Card_Principal">
      <div class="title-float title mt-5 ml-5">
        <h3 slot="titulo-acao">
          {{ 'Configuração e pagamento' }}
        </h3>
      </div>
      <v-subheader slot="subtitulo-acao">
        {{subtituloTipoAcao}}
      </v-subheader>

      <v-container fluid grid-list-md class="pt-0 pl-3">
        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <v-text-field
            id="descricao"
              :rules="[rules.required]"
              filled
              v-model="acao.descricao"
              :disabled="somenteLeitura || configuracao.indDescricaoAutomatica"
              :label="`${$tc('label.descricao_planejamento_acao', 1)} *`"/>
          </v-col>
        </v-row>
      </v-container>
      <v-card-title class="pb-1 pt-0">
        <div class="card-title">
          {{$tc('label.configuracao_planejamento_acao', 1)}}
        </div>
      </v-card-title>

      <v-container fluid grid-list-md class="my-2">
        <v-row>
          <v-col cols="12" class="pa-0 pr-md-2">
               <v-container fluid grid-list-md>
                <v-row>
                  <v-col cols="12" md="4" class="py-0">
                    <v-autocomplete
                      filled
                      autocomplete="off"
                      id="planejamento-autocomplete-divisao"
                      class="custom-autocomplete"
                      v-model="acao.divisao"
                      return-object
                      :rules="rules.requiredFunction('divisao', 'nome')"
                      :items="divisoesFiltradas"
                      :label="`${$tc('label.divisao', 1)} *`"
                      :no-data-text="$tc('message.nenhum_registro', 1)"
                      item-text="nome"
                      item-value="id"
                      required
                      :disabled="somenteLeitura || desabilitaDivisao"
                      :clearable="true"
                      @input="divisaoAlterada"
                      @change="divisaoAlterada"
                      @click:clear="() => divisaoAlterada()"
                      @click:append="() => triggerSelecao('planejamento-autocomplete-divisao')"
                      @click.native="(i) => buscaAutocomplete(i, buscarDivisoes)">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    v-show="!esconderEntidade(acao.unidadeNegocio)"
                    cols="12" md="4" class="py-0">
                    <v-autocomplete
                      filled
                      autocomplete="off"
                      id="planejamento-autocomplete-unidadenegocio"
                      class="custom-autocomplete"
                      v-model="acao.unidadeNegocio"
                      return-object
                      :items="unidadesNegocioDivisao"
                      :rules="rules.requiredFunction('unidadeNegocio', 'nomExtensao')"
                      :label="`${$tc('label.unidade_negocio', 1)} *`"
                      :no-data-text="$tc('message.nenhum_registro', 1)"
                      item-text="nomExtensao"
                      item-value="id"
                      required
                      :disabled="somenteLeitura || desabilitaUnidadeNegocio"
                      :clearable="true"
                      @input="extensaoAlterada"
                      @click:append="() => triggerSelecao('planejamento-autocomplete-unidadenegocio')"
                      @click.native="(i) => buscaAutocomplete(i, buscarUnidadesNegocioDivisao)">
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-0 pl-md-2">
                    <planejamento-acao-form-foco-acao
                      ref="formFocoAcao"
                      :isFilled="true"
                      :acao="acao"
                      :nova-acao="novaAcao"
                      :tipo-acao="tipoAcao"
                      :somente-leitura="somenteLeitura"
                      :configuracao="configuracao"
                      @PlanejamentoAcaoFormFocoAcao__alteradoFoco="alteradoFoco"
                      @PlanejamentoAcaoForm__contasCorrentes="buscarContasCorrentes"
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <metadados-campo-dinamico-data
                      :mascaraBarra="true"
                      :metadados="definicaoDtaInicio"
                      v-model="acao.dtaInicio"
                      :somente-leitura="somenteLeitura"
                      :objeto-container="acao"
                      :tipo-selecao="'date'"
                      @input="emitirCampoProdutoAlterado"
                      :filled="true"/>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <input-money
                    class="filled"
                    :label="$tc('label.valor_total_acao', 1)"
                    :disabled="somenteLeitura"
                    v-model="acao.valor"
                    :rules="[rules.required, rules.minimoPadrao]"
                    :isFilled="true">
                    </input-money>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <v-text-field
                      :label="$tc('label.status', 1)"
                      v-model="acao.status"
                      disabled
                      :somente-leitura="true"
                      filled>
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="container-conta-corrente" v-if="contasCorrentes && mostrarBlocoCc">
                  <div class="card-title pb-2 pl-3 pt-3">
                    {{ $tc('title.campo_conta_corrente', 2)}}
                  </div>
                  <div cols="12" md="4" class="pb-0 pl-4 pr-4">
                    <div fluid grid-list-md>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-select
                            id="planejamento-cc-autocomplete-tipoverba"
                            :label="`${$t('label.tipo_verba')} *`"
                            :items="tiposVerbas"
                            item-text="nome"
                            item-value="id"
                            v-model="idTipoVerba"
                            :disabled="somenteLeitura"
                            filled
                            @change="tipoVerbaSelecionado()"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-autocomplete
                            id="planejamento-cc-autocomplete-periodo"
                            class="custom-autocomplete"
                            v-model="periodo"
                            :rules="[rules.required]"
                            return-object
                            item-text="descricao"
                            item-value="id"
                            :items="periodos"
                            :label="`${$tc('label.selecione_periodo', 1)} *`"
                            :no-data-text="$tc('message.nenhum_registro', 1)"
                            :clearable="true"
                            filled
                            :disabled="somenteLeitura || !idTipoVerba"
                            :placeholder="(somenteLeitura
                            || !idTipoVerba)
                              ? null
                              : $tc('message.digite_para_pesquisar', 1)"
                              @change="periodoSelecionado()">
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field class="saldo"
                            :label="`${$t('label.saldo_disponivel')}`"
                            :value="formatarMoeda(saldo)"
                            :disabled="true"
                            style="font-size: 24px; font-weight: bold;padding-left: 10px; background-color: aliceblue;"
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </v-container>
              <v-card-title class="pb-0">
                <div class="card-title" slot="titulo-acao">
                  {{ 'Tipo de pagamento' }}
                </div>
              </v-card-title>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-radio-group class="radioGroup pl-3" inline v-model="tipoPagamento" :disabled="somenteLeitura">
                  <v-row>
                    <v-btn v-for="tipo in tiposPagamento" outlined @click="tipoPagamento = tipo.codTipo" :class="{ activeRadio: tipoPagamento === tipo.codTipo, inactiveRadio: tipoPagamento !== tipo.codTipo }" class="radioBtn"><v-radio class="white-radio" active-class="active" :value="tipo.codTipo"></v-radio>{{ tipo.descricao }}</v-btn>
                  </v-row>
                </v-radio-group>
              </v-col>
              <v-card-title class="pb-0">
                <div class="card-title" slot="titulo-acao">
                  {{ 'Pagamento' }}
                </div>
              </v-card-title>
              <div cols="12" md="4" class="pb-0 pl-4 pr-4">
                <div class="bg-warning" style="display: flex;">
                  <img src="../../../assets/img/warning.svg" width="24px" height="24px" style="height: 100%; margin-left: 10px; margin-right: 10px;">
                  <div>
                    <span>{{ 'Preencher o campo' }}<strong>{{ ' "valor do pagamento" ' }}</strong>{{ 'com os valores a serem recebidos por cada CNPJ' }}</span>
                  </div>
                </div>
                <v-data-table
                  :headers="headers"
                  :items="rateios"
                  :height="200"
                  :no-data-text="$t('label.tabela_sem_conteudo')"
                  hide-default-footer
                  disable-pagination
                  :items-per-page="-1">
                  <template v-slot:item.valorRateioOriginal="{ item }">
                    {{ getMoney(item.valorRateioOriginal) }}
                  </template>
                  <template v-slot:item.valorRateio="{ item }">
                    <input-money class="inputTabela"
                      dense
                      v-model="item.valorRateio"
                      :disabled="somenteLeitura"
                      @InputMoney_valorAlterado="(valor) => atualizaValorRateio(valor, item)"
                      />
                  </template>
                </v-data-table>
              </div>
              <div class="border" style="margin: 20px; width: 98%; box-shadow: 0px 0px 1px 1px lightgray" v-if="(this.qtdeArquivosAnexados > 0 || ['Em cadastro', 'Em analise', 'Aprovado'].indexOf(this.acao.status) >= 0 || this.novaAcao) && this.configuracao.uploadObrigatorioArquivo">
                <v-card-title class="pb-0" v-if="this.qtdeArquivosAnexados > 0 || ['Em cadastro', 'Em analise'].indexOf(this.acao.status) >= 0 || this.novaAcao">
                  <div class="card-title" slot="titulo-acao">
                    {{ 'Evidências' }}
                  </div>
                </v-card-title>
                <v-container class="custom-container" fluid grid-list-md>
                  <planejamento-acao-form-anexo-arquivo-novo
                    ref="formAnexoArquivo"
                    v-if="(['Em cadastro', 'Em analise', 'Aprovado'].indexOf(this.acao.status) >= 0 && !this.novaAcao)"
                    @FormAnexoArquivo__qtde="atualizaQtdeArquivosAnexados"
                    :acao="acaoEdicao"
                    :configuracao="configuracao"
                    :nova-acao="!isEdicao"/>
                    <div v-else class="bg-error" style="display: flex; border: 2px solid #9D1D96; background-color: #f9e1fa; border-radius: 5px;">
                      <div style="background-color: #ddbae0; border-radius: 50%; width: 60px; height: 60px; text-align: center; margin-right: 10px;">
                        <img src="../../../assets/img/error.svg" width="24px" height="24px" style="height: 100%; margin-left: 10px; margin-right: 10px;">
                      </div>
                      <div>
                        <h3 style="font-size: 20px; font-weight: bold; color: #9D1D96;">Atenção!</h3>
                        <h3 style="color: #9D1D96;">Para adicionar um arquivo de evidências, clique primeiro no botão <span style="font-weight: bold; color: #9D1D96;">"Salvar"</span> e depois adicione um arquivo.</h3>
                      </div>
                    </div>
                </v-container>
              </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-form>
  <v-dialog v-model="showModal" max-width="400">
    <v-card>
      <v-card-title style="background-color: lightgray;">
        <div class="card-title" slot="titulo-acao">
          <h2>{{ 'Atenção!' }}</h2>
        </div>
      </v-card-title>
      <v-container>
        <div style="display: flex;">
          <v-icon color="#ed1607" style="margin: 10px;">error</v-icon>
          <span style="margin: auto; width: 500px;">
            <strong>{{ $t('label.valores_cnpj_nao_conferem') }}</strong>
          </span>
        </div>
        <br>
        <span>
          {{ 'Por favor, revise os valores e clique no botão "Concluir" novamente.' }}
        </span>
        <div style="margin: auto; width: 20px; padding-top: 15px;padding-bottom: 15px;">
          <button @click="fecharModal()"><strong style="color: #752157;">Fechar</strong></button>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</div>

</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { getMoney } from '@/produto/common/functions/helpers';
import PlanejamentoAcaoFormFocoAcao from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormFocoAcao';
import PlanejamentoAcaoFormRoi from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormRoi';
import MetadadosCampoDinamicoData from '../../../shared-components/metadados/campos-dinamicos/MetadadosCampoDinamicoData';
import MetadadosContainerLayout from '../../../shared-components/metadados/MetadadosContainerLayout';
import PlanejamentoAcaoFormCamposDefinicao from './PlanejamentoAcaoFormCamposDefinicao';
import PlanejamentoAcaoFormOrigemAcao from './PlanejamentoAcaoFormOrigemAcao';
import PlanejamentoAcaoFormAnexoArquivoNovo from './PlanejamentoAcaoFormAnexoArquivoNovo';
import PlanejamentoAcaoFormDetalhesAcao from './PlanejamentoAcaoFormDetalhesAcao';
import AcaoComponenteEvento from './passos-dinamicos/AcaoComponenteEvento';
import InputMoney from '../../../shared-components/inputs/InputMoney';
import TextFieldMoney from '../../../shared-components/inputs/TextFieldMoney';
import {
  buscarContasCorrentesElegiveis,
} from '../../../common/resources/planejamento/planejamento-acao-cadastro';

export default {
  name: 'PlanejamentoAcaoFormCamposNovo',
  components: {
    MetadadosCampoDinamicoData,
    MetadadosContainerLayout,
    PlanejamentoAcaoFormFocoAcao,
    PlanejamentoAcaoFormOrigemAcao,
    PlanejamentoAcaoFormRoi,
    PlanejamentoAcaoFormAnexoArquivoNovo,
    PlanejamentoAcaoFormDetalhesAcao,
    InputMoney,
    TextFieldMoney,
  },
  mixins: [
    PlanejamentoAcaoFormCamposDefinicao,
  ],
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acaoEdicao: Object,
    tipoAcao: {
      type: Object,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      mostrarBlocoCc: true,
      idTipoVerba: null,
      rateioProporcionalResource: this.$api.rateioProporcional(this.$resource),
      extratoContaCorrente: this.$api.extratoContaCorrente(this.$resource),
      tipoBeneficioResources: this.$api.tipoBeneficio(this.$resource),
      tiposPagamento: null,
      tipoPagamento: 'NOTA_CREDITO',
      qtdeArquivosAnexados: 0,
      formGeralValido: false,
      contasCorrentes: null,
      contas: {},
      tiposVerbas: [],
      idContaFinal: null,
      periodos: [],
      periodo: null,
      idPeriodo: null,
      rateios: [],
      linhasInvestimentos: null,
      headers: [
        { text: this.$tc('label.razao_social', 1), value: 'nomCliente', sortable: false },
        { text: this.$tc('label.cnpj', 1), value: 'desCnpj', sortable: false },
        { text: this.$tc('label.valor_pagamento', 1), value: 'valorRateio', sortable: false },
      ],
      saldo: 0,
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getProdutoMetadado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    tituloTipoAcao() {
      return this.tipoAcao != null ? this.tipoAcao.nome : '';
    },
    subtituloTipoAcao() {
      return this.tipoAcao != null ? this.tipoAcao.descricao : '';
    },
    usuarioCliente() {
      return this.usuarioLogado.tipo === 'CLIENTE';
    },
    ordenacaoFormulario() {
      const { relacaoCampos } = this.configuracao;
      return relacaoCampos;
    },
    isEdicao() {
      return !this.novaAcao;
    },
    exibeJustificativaCancelamento() {
      const { passoGeral } = this.acaoEdicao;
      if (!passoGeral || passoGeral.status !== 'CANCELADO') return false;
      return passoGeral.cancelouAprovada;
    },
    infoCancelamento() {
      const { passoGeral } = this.acaoEdicao;
      const {
        justificativaCancelamento,
        perfilCancelamento,
        usuarioCancelamento,
      } = passoGeral;

      const responsavelCancelamento = this.usuarioLogado.tipo !== 'FUNCIONARIO'
        ? perfilCancelamento : usuarioCancelamento;

      return {
        justificativaCancelamento,
        responsavelCancelamento,
      };
    },
    visualizaSecaoRoi() {
      return this.configuracao.indHabilitarCalculoRoi && (!this.usuarioCliente
        || (this.usuarioCliente && this.configuracao.indPermitirClienteVisualizarRoi));
    },
    isDetalheAcaoTemplate() {
      return this.configuracao.configuracao.passo3.template === 'DETALHE_ACAO';
    },
    isAcaoVigenciaTrimestral() {
      return this.configuracao.indVigenciaTrimestral;
    },
  },
  methods: {
    getMoney,
    buscarTiposPagamento() {
      this.tipoBeneficioResources
        .listarAtivos()
        .then((response) => {
          this.tiposPagamento = response.data;
          this.filtrarTiposPagamento();
        });
    },
    filtrarTiposPagamento() {
      this.tiposPagamento = this.tiposPagamento.filter((tipo) => this.configuracao.configuracao.passo5.apuracao.pagamentos.includes(tipo.id));
    },
    divisaoAlterada() {
      this.rateios = [];
    },
    checkHolding(conta) {
      return conta.descricaoPosse === this.acao.holding.nomExtensao;
    },
    checkLinhaInvestimento(linha) {
      return linha.descricao.toUpperCase().includes(this.tipoAcao.nome.toUpperCase());
    },
    buscarValorContaCorrente() {
      const param = {
        idTipoVerba: this.idTipoVerba,
        idPeriodo: this.periodo.id,
      };
      this.saldo = 0;
      this.idContaFinal = null;
      this.extratoContaCorrente.contasCorrentes(param)
        .then((res) => {
          this.contas = res.data.filter(this.checkHolding);
          const parametro = { ids: this.contas[0].idsContas };

          this.extratoContaCorrente.linhasInvestimentos(parametro)
            .then((reslinha) => {
              parametro.ids = reslinha.data.filter(this.checkLinhaInvestimento)[0].idConta;
              this.idContaFinal = parametro.ids;
              this.linhasInvestimentos = reslinha.data;
              this.extratoContaCorrente.contaCorrente(parametro)
                .then((ress) => {
                  if (this.acaoEdicao && !this.novaAcao) {
                    if (this.acaoEdicao.passoGeral.status !== 'APROVADO') {
                      this.saldo = ress.data.vlrSaldo;
                    } else {
                      this.saldo = this.acaoEdicao.passoGeral.saldoDisponivelCriacao;
                      if (this.saldo === 0) this.mostrarBlocoCc = false;
                    }
                  } else {
                    this.saldo = ress.data.vlrSaldo;
                  }
                }, (err) => {
                  this.$error(this, err);
                });
            }, (err) => {
              this.$error(this, err);
            });
        }, (err) => {
          this.saldo = 0;
          this.$error(this, err);
        });
    },
    buscarPeriodos() {
      const idTipoVerba = this.idTipoVerba; //eslint-disable-line
      this.saldo = 0;

      if (!idTipoVerba) return;
      const param = { id: idTipoVerba };
      this.extratoContaCorrente.periodos(param)
        .then((res) => {
          this.periodos = res.data;
          this.buscarValorContaCorrente();
        }, (err) => {
          this.$error(this, err);
        });
    },
    tipoVerbaSelecionado() {
      this.buscarPeriodos();
    },
    periodoSelecionado() {
      this.buscarValorContaCorrente();
    },
    carregarTiposVerbas() {
      const { listaIdVerbaInvestimento } = this.configuracao;
      const { idTipoVerba } = this.contasCorrentes.contas[this.contasCorrentes.contas.length - 1];
      const param = idTipoVerba ? { idTipoVerba } : {};

      listaIdVerbaInvestimento.forEach((idOrcamentoVerbaInvestimento) => {
        this.orcamentoTipoVerbaResource
          .buscarPorIdVerbaInvestimento({ idOrcamentoVerbaInvestimento, ...param })
          .then(({ data }) => {
            if (data) {
              this.tiposVerbas.push(data);
              this.buscarPeriodos();
            }
          });
      });
    },
    fecharModal() {
      this.showModal = false;
    },
    novaSolicitacao() {
      this.$router.push({ name: 'novaAcao', params: { idTipoAcao: this.tipoAcao.id } });
      setTimeout(() => {
        window.location.reload();
      }, 200);
    },
    atualizaValorRateio(novoValor, item) {
      const diferenca = novoValor - (item.valorRateio || 0);
      this.$emit('RateioProporcionalTabela_atualizaSaldoPendente', diferenca);
    },
    emitirCampoProdutoAlterado() {
      const evento = new AcaoComponenteEvento('campoProdutoAlterado');
      this.$emit('PlanejamentoAcaoForm__evento', evento);
    },
    emitirFocoAlterado() {
      const evento = new AcaoComponenteEvento('focoAlterado');
      this.$emit('PlanejamentoAcaoForm__evento', evento);
    },
    getObjetoPasso() {
      return this.getObjetoNatural();
    },
    preencherObjetoFormatado(acao) {
      acao.passoGeral = this.getObjetoPassoGeralFormatado();
      acao.passoComportamento = this.getObjetoPassoComportamentoFormatado();
      acao.passoContas = this.getObjetoPassoContasFormatado();
      acao.passosDinamicos = this.getObjetoPassosDinamicos();
      acao.ordemPassosDinamicos = this.getOrdemPassosDinamicos();
    },
    validaSalvar(isSolicitandoAprovacao = false) {
      if (!this.validacaoCustomizada(isSolicitandoAprovacao)) {
        return false;
      }
      if (this.isDetalheAcaoTemplate) {
        if (!this.validaSecaoDetalheAcao()) {
          return false;
        }
      }
      if (!this.validaValor()) {
        return false;
      }
      if (!this.validaRateio()) {
        return false;
      }
      if (!this.validaEvidencias()) {
        return false;
      }
      return true;
    },
    validaRateio() {
      let somaRateio = 0;
      this.rateios.forEach((v) => {
        somaRateio += v.valorRateio;
      });
      if ((somaRateio.toFixed(2) < this.acao.valor) || (somaRateio.toFixed(2) > this.acao.valor)) {
        this.showModal = true;
        return false;
      }
      return true;
    },
    validaValor() {
      if (this.acao.valor > this.saldo) {
        this.$toast(this.$t('label.valor_indisponivel'));
        return false;
      }
      return true;
    },
    validaEvidencias() {
      if (this.configuracao.uploadObrigatorioArquivo && this.qtdeArquivosAnexados < 1) {
        this.$toast(this.$t('errors.campos_obrigatorios.nao_informados'));
        return false;
      }
      return true;
    },
    valida() {
      const resultadoFormGeral = this.$refs.formGeral.validate();

      if (!resultadoFormGeral) {
        this.$toast(this.$t('errors.campos_obrigatorios.nao_informados'));
      }
      if (this.visualizaSecaoRoi
          && this.isDetalheAcaoTemplate) {
        return resultadoFormGeral
            && this.validaSecaoDetalheAcao()
            && this.validarDatas();
      }
      if (this.visualizaSecaoRoi) {
        return resultadoFormGeral
            && this.validarDatas();
      }
      if (this.isDetalheAcaoTemplate) {
        return resultadoFormGeral
            && this.validaSecaoDetalheAcao()
            && this.validarDatas();
      }

      return resultadoFormGeral
          && this.validarDatas();
    },
    filtrarCamposFormulario() {
      if (this.configuracao.valorAcaoCalculadoAutomaticamente && !this.isDetalheAcaoTemplate) {
        this.camposFormulario.padrao = this.camposFormulario.padrao.filter((c) => c.labelCampo !== 'valor');
      }
    },
    alteraValorAcao(val) {
      this.acao.valor = val;
      this.$emit('PlanejamentoAcaoForm__CampoValorAlterado');
    },
    esconderEntidade(entidade) {
      if (entidade && entidade.desAtributos) {
        const json = JSON.parse(entidade.desAtributos.value) || {};
        return !!(json && json.esconderEntidade);
      }
      return false;
    },
    extensaoAlterada() {
      this.emitirCampoProdutoAlterado();
      if (this.$refs.formFocoAcao) {
        this.$refs.formFocoAcao.limpaFocoAcao();
      }
    },
    valorDinamicoAlterado(alteracao) {
      const { mapaEntidades } = this.getProdutoMetadado;
      const dependenciasProduto = Object.keys(mapaEntidades);

      const { label } = alteracao;
      if (label === 'produto' || dependenciasProduto.indexOf(label) >= 0) {
        this.emitirCampoProdutoAlterado();
      }

      if (label === 'valor') {
        const evento = new AcaoComponenteEvento('campoValorAlterado');
        this.$emit('PlanejamentoAcaoForm__evento', evento);
      }
    },
    getObjetoNatural() {
      const objeto = { ...this.acao, ...this.getFocoOuOrigem() };
      if (this.isDetalheAcaoTemplate) {
        objeto.acaoServicos = this.$refs.detalheAcao.getServicos();
      }
      objeto.contaCorrente = {};

      if (this.contasCorrentes && this.contasCorrentes.contas) {
        const ultimaConta = this.contasCorrentes.contas[this.contasCorrentes.contas.length - 1];
        if (ultimaConta) {
          objeto.contaCorrente.contas = ultimaConta;
        }
      }
      return objeto;
    },
    getFocoOuOrigem() {
      const objeto = {};

      if (this.indModoVarejo) {
        const { fornecedor, grupoFornecedor, fornecedorPagador } = this.acao;
        if (this.$refs.formOrigemAcao.exibeOrigemGrupoFornecedor) {
          objeto.grupoFornecedor = grupoFornecedor;
        } else {
          objeto.fornecedor = fornecedor;
        }
        if (this.$refs.formOrigemAcao.exibeFornecedorPagador) {
          objeto.fornecedorPagador = fornecedorPagador;
        }
      } else {
        const {
          cliente,
          holding,
          regional,
          extensaoCliente,
          clientePagador,
          fornecedorPagador,
        } = this.acao;
        if (holding && holding.id) {
          objeto.holding = holding;
        }
        if (cliente && cliente.id) {
          objeto.cliente = cliente;
        }
        if (regional && regional.id) {
          objeto.regional = regional;
        }
        if (clientePagador && clientePagador.id) {
          objeto.clientePagador = clientePagador;
        }
        if (extensaoCliente && extensaoCliente.id) {
          objeto.extensaoCliente = extensaoCliente;
        }
        if (fornecedorPagador && fornecedorPagador.id) {
          objeto.fornecedorPagador = fornecedorPagador;
        }
        objeto.focoAcao = this.$refs.formFocoAcao.getTextoFoco();
      }
      return objeto;
    },
    getObjetoPassoGeralFormatado() {
      const { idConfiguracao } = this.configuracao;
      const {
        id,
        descricao,
        divisao,
        unidadeNegocio,
        valor,
      } = this.acao;

      const objetoFormatado = {
        id,
        idAcaoTipo: this.tipoAcao.id,
        idConfiguracao,
        descricao,
        valor,
        saldoDisponivelCriacao: this.saldo,
        idDivisao: divisao.id,
        idUnidadeNegocio: unidadeNegocio.id,
        mapaExtensoes: {},
      };
      this.formataVigenciaAberta(objetoFormatado);

      if (this.indModoVarejo) {
        this.formataOrigemAcao(objetoFormatado);
      } else {
        this.formataFocoAcao(objetoFormatado);
      }
      if (this.isDetalheAcaoTemplate) {
        objetoFormatado.acaoServicos = this.$refs.detalheAcao.getServicos();
      }
      return objetoFormatado;
    },
    getObjetoPassoComportamentoFormatado() {
      const objetoFormatado = {
        tipoPagamento: this.tipoPagamento,
      };

      this.formataVigenciaAberta(objetoFormatado);
      return objetoFormatado;
    },
    getObjetoPassosDinamicos() {
      const objetoFormatado = {
        passoRateioCliente: { rateios: this.rateios },
      };
      return objetoFormatado;
    },
    getOrdemPassosDinamicos() {
      return { 1: 'passoRateioCliente' };
    },
    getObjetoPassoContasFormatado() {
      const objetoReservas = this.contasCorrentes.contas[this.contasCorrentes.contas.length - 1];
      const objetoFormatado = {
        idTipoVerba: this.idTipoVerba,
        idsPeriodos: [this.periodo.id],
        reservas: [{
          dtaFim: objetoReservas.dataFim,
          dtaInicio: objetoReservas.dataInicio,
          ordem: 1,
          periodoApuracao: this.tipoPagamento,
          valor: this.acao.valor,
          contas: [{
            idContaCorrente: this.idContaFinal,
            valor: this.acao.valor,
          }],
        }],
        workspace: {
          filtrosAbertos: true,
          filtrosUtilizados: {},
        },
      };

      this.formataVigenciaAberta(objetoFormatado);
      return objetoFormatado;
    },
    formataVigenciaAberta(objetoFormatado) {
      if (this.isAcaoVigenciaTrimestral) {
        objetoFormatado.dtaInicio = moment(this.acao.dtaInicio, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        objetoFormatado.dtaFim = moment(this.acao.dtaInicio, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      } else {
        objetoFormatado.dtaInicio = this.acao.dtaInicio;
        objetoFormatado.dtaFim = this.acao.dtaInicio;
      }
    },
    formataFocoAcao(objetoFormatado) {
      const {
        cliente,
        holding,
        regional,
        extensaoCliente,
        clientePagador,
        fornecedorPagador,
      } = this.$refs.formFocoAcao.getAcao();

      if (holding && holding.id) {
        objetoFormatado.idHolding = holding.id;
      }
      if (cliente && cliente.id) {
        objetoFormatado.idCliente = cliente.id;
      }
      if (regional && regional.id) {
        objetoFormatado.idRegional = regional.id;
      }
      if (clientePagador && clientePagador.id) {
        objetoFormatado.idClientePagador = clientePagador.id;
      }
      if (extensaoCliente && extensaoCliente.id) {
        objetoFormatado.idExtensaoCliente = extensaoCliente.id;
      }
      if (fornecedorPagador && fornecedorPagador.id) {
        objetoFormatado.idFornecedorPagador = fornecedorPagador.id;
      }
    },
    formataOrigemAcao(objetoFormatado) {
      const { fornecedor, grupoFornecedor, fornecedorPagador } = this.acao;
      if (this.$refs.formOrigemAcao.exibeOrigemGrupoFornecedor) {
        objetoFormatado.idGrupoFornecedor = grupoFornecedor.id;
      } else {
        objetoFormatado.idFornecedor = fornecedor.id;
      }
      if (this.$refs.formOrigemAcao.exibeFornecedorPagador) {
        objetoFormatado.idFornecedorPagador = fornecedorPagador.id;
      }
    },
    validaSecaoDetalheAcao() {
      return this.$refs.detalheAcao.validaSecaoDetalheAcao();
    },
    configuraMetadadoCruzados() {
      const configuracoes = [
        {
          entidade: 'produto',
          metadadosCampo: this.getProdutoMetadado,
        },
      ];
      configuracoes.forEach((cfg) => {
        const campos = this.camposFormulario.padrao;
        const campo = campos.filter((c) => c.labelCampo === cfg.entidade)[0];
        campo.metadadosCampo = cfg.metadadosCampo;
      });
    },
    aplicaParametrizacoes() {
      this.metadadosParametrizado = this.configuracao.aplicaParametrizacao(this.getAcaoMetadado);
      this.configuracao
        .parametrizaCamposOpcionais(this.camposFormulario.padrao);

      if (this.isDetalheAcaoTemplate) {
        const campoValor = this.camposFormulario.padrao.filter((c) => c.labelCampo === 'valor')[0];
        campoValor.desAtributos = { desabilitado: true };
      }
    },
    preencherExtensoesCarteiras() {
      this.configuracao.extensoesCarteira.forEach((e) => {
        const extensao = this.acao[e.label];
        extensao.descricao = `${extensao.idExterno} - ${extensao.nomExtensao}`;
        this.listasExtensoesCarteira[e.label] = [extensao];
      });
    },
    montaObjetoAcao() {
      Object.assign(this.acao,
        {
          ...this.acaoEdicao.passoGeral,
          ...this.acaoEdicao.passoGeral.mapaExtensoes,
          ...this.acaoEdicao.passoGeral.mapaCamposDinamicos,
          ...this.acaoEdicao.passoComportamento,
        });
      this.tipoPagamento = this.acao.tipoPagamento; //eslint-disable-line
      this.periodo = this.acaoEdicao.passoContas.periodos[0]; //eslint-disable-line
      this.idTipoVerba = this.acaoEdicao.passoContas.tipoVerba.id; //eslint-disable-line
      this.preencherExtensoesCarteiras();
      const { status } = this.acao;
      this.acao.status = this.$tc(`status_entidade.${status.toLowerCase()}`, 1);

      if (this.indModoVarejo) {
        setTimeout(this.$refs.formOrigemAcao.setAcaoEdicao);
      } else {
        setTimeout(this.$refs.formFocoAcao.setAcaoEdicao);
      }

      if (this.visualizaSecaoRoi) {
        setTimeout(this.$refs.formRoi.setAcaoEdicao);
      }

      this.unidadesNegocioDivisao = [this.acao.unidadeNegocio];
      this.divisoesFiltradas = [this.acao.divisao];
    },
    validacaoCustomizada(isSolicitandoAprovacao) {
      if (!isSolicitandoAprovacao) {
        return true;
      }
      if (this.configuracao.uploadObrigatorioArquivo && this.qtdeArquivosAnexados === 0) {
        this.$toast(this.$t('message.upload_arquivo_obrigatorio_solicitacao'));
        return false;
      }
      return true;
    },
    validarDatas() {
      const dataInicial = moment(this.acao.dtaInicio);
      const dataFinal = moment(this.acao.dtaFim);

      if (dataFinal.isBefore(dataInicial)) {
        this.$toast(this.$t('message.data_inicial_maior_final'));
        return false;
      }

      return true;
    },
    preencherValoresAutomaticos() {
      if (this.configuracao.indDescricaoAutomatica) {
        this.acao.descricao = this.configuracao.descricaoAutomatica;
      }

      if (this.configuracao.indVigenciaAutomatica) {
        this.acao.dtaInicio = this.moment().format('YYYY-MM-DD');
        this.acao.dtaFim = this.acao.dtaInicio;
      }
    },
    alteradoFoco(isFocoRegional) {
      if (this.visualizaSecaoRoi) {
        this.$refs.formRoi.setFocoRegional(isFocoRegional);
        if (this.acao.divisao && (this.acao.cliente || this.acao.holding)) {
          this.$refs.formRoi.focoAlterado();
        } else {
          this.$refs.formRoi.setValoresZerados();
        }
      }
      this.emitirFocoAlterado();
      this.emitirCampoProdutoAlterado();
    },
    atualizaQtdeArquivosAnexados(qtde) {
      this.qtdeArquivosAnexados = qtde;
      this.$emit('PlanejamentoAcaoForm__AtualizaIndTemEvidencia', qtde > 0);
    },
    buscarContasCorrentes(onLoad = false) {
      this.periodo = null;
      this.idTipoVerba = null;
      this.saldo = 0;
      const parametros = {
        idDivisao: this.acao.divisao.id,
        idAcaoConfiguracaoSnapshot: this.configuracao.configuracao.id,
        idCliente: (this.acao.cliente || {}).id,
        idHolding: (this.acao.holding || {}).id,
        idUnidadeNegocio: this.acao.unidadeNegocio.id,
        idExtensaoCliente: (this.acao.extensaoCliente || {}).id,
        idsExtensoesCarteira: (this.configuracao.extensoesCarteira),
      };
      buscarContasCorrentesElegiveis(parametros, this.$resource)
        .then((res) => {
          this.contasCorrentes = res.data;
          if (!this.isEdicao || !onLoad) {
            this.rateios = [];
            this.buscarRateios();
          } else {
            this.buscarRateiosAtivos(this.acaoEdicao.id);
          }
          this.carregarTiposVerbas();
          this.idTipoVerba = this.contasCorrentes.contas[this.contasCorrentes.contas.length - 1].idTipoVerba;
          if (this.acaoEdicao.passoContas) {
            if (this.acaoEdicao.passoContas.periodos[0]) {
              this.periodo = this.acaoEdicao.passoContas.periodos[0]; //eslint-disable-line
            }
          } else {
            this.periodo = {
              id: this.contasCorrentes.contas[this.contasCorrentes.contas.length - 1].idPeriodo,
              nome: this.contasCorrentes.contas[this.contasCorrentes.contas.length - 1].periodo,
            };
          }
        });
    },
    formatarMoeda(valor) {
      return parseFloat(valor).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    },
    getContasCorrentes() {
      return this.acaoEdicao.passoContas.reservas[0].contas.map((e) => e.idContaCorrente);
    },
    buscarRateios() {
      const idsContasCorrente = this.contasCorrentes.contas;
      if (idsContasCorrente.length < 1) {
        return;
      }
      const param = {
        idNomeFantasia: this.acao.holding.id,
        idsContasCorrente: this.contas.idsContas,
        idDivisao: this.acao.divisao.id,
      };
      this.rateioProporcionalResource.buscarRateiosProporcional(param)
        .then((res) => {
          this.rateios = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarRateiosAtivos(idAcao) {
      const idsContasCorrente = this.contasCorrentes.contas;
      if (idsContasCorrente.length < 1) {
        return;
      }
      const param = {
        idAcao,
        idNomeFantasia: this.acao.holding.id,
        idsContasCorrente: this.contas.idsContas,
        idDivisao: this.acao.divisao.id,
      };
      this.rateioProporcionalResource.buscarRateiosProporcionalClienteAtivos(param)
        .then((res) => {
          this.rateios = res.data;
        })
        .catch((err) => {
          this.$toast(err);
        });
    },
    buscarRateiosCliente(idAcao) { //eslint-disable-line
      const params = {
        idAcao,
        idDivisao: this.acaoEdicao.passoGeral.divisao.id,
      };
      this.rateioProporcionalResource.buscarRateiosProporcionalCliente(params)
        .then((res) => {
          this.rateios = res.data;
        })
        .catch((err) => {
          this.$toast(err);
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (!this.somenteLeitura && this.novaAcao) {
      this.buscarDivisoesUsuario();
    }
    this.filtrarCamposFormulario();
    this.aplicaParametrizacoes();

    if (this.isEdicao) {
      this.montaObjetoAcao();
      this.buscarContasCorrentes(true);
    } else {
      this.preencherValoresAutomaticos();
    }

    this.configuraMetadadoCruzados();

    this.parametrosAplicados = true;
    if (this.isEdicao) {
      this.rateios = [];
    }
    this.buscarTiposPagamento();
  },
};
</script>
