<template lang="html">
  <v-form ref="form" lazy-validation autocomplete="off">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :label="`${$tc('label.tipo_verba', 1)}  *`"
            v-model="ajusteVerba.tipoVerba"
            :items="tiposVerba"
            item-value="id"
            item-text="nome"
            return-object
            clearable
            :disabled="somenteLeitura"
            :search-input.sync="tipoVerbaInput"
            @input="tipoVerbaAlterado"
            required
            :rules="[rules.required]"
            @click.native="buscarTiposVerba()">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :label="`${$tc('label.orcamento', 1)}  *`"
            v-model="ajusteVerba.orcamento"
            :items="orcamentos"
            item-value="id"
            item-text="descricao"
            return-object
            clearable
            :search-input.sync="orcamentoInput"
            :disabled="!ajusteVerba.tipoVerba || somenteLeitura"
            @input="orcamentoAlterado"
            required
            :rules="[rules.required]"
            @click.native="buscarOrcamentos()">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :label="`${$tc('label.periodo_orcamentario', 1)} *`"
            v-model="ajusteVerba.periodoOrcamentario"
            :items="periodosOrcamentarios"
            item-value="id"
            item-text="descricao"
            return-object
            clearable
            :search-input.sync="periodoOrcamentarioInput"
            :disabled="!ajusteVerba.orcamento || somenteLeitura"
            required
            :rules="[rules.required]"
            @click.native="buscarPeriodosOrcamentarios()"
            @input="periodoOrcamentarioAlterado">
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="ajusteVerba.periodoOrcamentario">
        <v-col cols="12" sm="6" md="4">
          <input-money
            :label="`${$t('label.valor_planejado')}`"
            :disabled="true"
            v-model="ajusteVerba.periodoOrcamentario.planejado">
          </input-money>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <input-money
            :label="`${$t('label.saldo_disponivel')}`"
            :disabled="true"
            v-model="ajusteVerba.periodoOrcamentario.saldo">
          </input-money>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            id="status"
            name="status"
            :value="$tc(`status_entidade.${ajusteVerba.status.toLowerCase()}`, 1)"
            :disabled="true"
            :label="$tc('label.status', 1)"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="ajusteVerba.periodoOrcamentario">
        <v-col>
          <v-text-field
            id="justificativa"
            name="justificativa"
            v-model="ajusteVerba.justificativa"
            :label="`${$tc('label.justificativa', 1)} *`"
            :disabled="somenteLeitura"
            :rules="[rules.required]"
            :counter="150"
            maxlength="150"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" v-if="exibeAlertaLinhasComFluxoExistente">
        <v-col>
          <v-alert
            outlined
            type="warning"
            prominent
            border="left"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t('message.ajuste_verba.linhas_com_fluxo_andamento') }}
              </v-col>
              <v-col class="shrink">
                <v-btn color="accent" @click="abrirModal">{{ $t('label.consultar') }}</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="pr-0 mr-0">
        <v-col cols="12" class="mr-0 pr-0 pb-0">
          <v-row justify="end">
            <v-btn
              @click="aplicaPeriodo"
              class="mr-0"
              color="accent"
              v-if="!somenteLeitura"
              :disabled="!ajusteVerba.periodoOrcamentario">
              {{$tc('label.aplicar_periodo', 1)}}
              <v-icon right>refresh</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogLinhasComFluxoAndamento" max-width="600px">
        <v-card>
          <v-card-title>
            <div class="title">
              {{ $tc('title.ajuste_verba_reducao_massiva', 1) }}
            </div>
          </v-card-title>
          <v-row centered>
            <v-col cols="12" sm="6">
              <v-autocomplete
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :label="`${$tc('label.orcamento', 1)}  *`"
                v-model="ajusteVerba.orcamento"
                :items="orcamentos"
                item-value="id"
                item-text="descricao"
                return-object
                clearable
                :search-input.sync="orcamentoInput"
                disabled
                @input="orcamentoAlterado"
                required
                :rules="[rules.required]"
                @click.native="buscarOrcamentos()">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :label="`${$tc('label.periodo_orcamentario', 1)} *`"
                v-model="ajusteVerba.periodoOrcamentario"
                :items="periodosOrcamentarios"
                item-value="id"
                item-text="descricao"
                return-object
                clearable
                :search-input.sync="periodoOrcamentarioInput"
                disabled
                required
                :rules="[rules.required]"
                @click.native="buscarPeriodosOrcamentarios()"
                @input="periodoOrcamentarioAlterado">
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-data-table
            ref="tabela"
            :headers="headers"
            :items="linhasComFluxoReducao"
            dense
            item-key="id"
            :options.sync="pagination"
            :server-items-length="totalPage"
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :footer-props="{
              itemsPerPageOptions: [10, 20, 30],
            }">
            <template v-slot:item.vlrAjuste="{ item }">
              {{ getMoney(item.vlrAjuste) }}
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
    </v-container>
  </v-form>
</template>
<script>
import InputMoney from '../../../shared-components/inputs/InputMoney';
import { getMoney } from '../../../common/functions/helpers';

export default {
  name: 'AjusteVerbaReducaoMassivaFormResumo',
  data() {
    return {
      resource: this.$api.ajusteVerbaReducaoMassiva(this.$resource),

      tiposVerba: [],
      orcamentos: [],
      periodosOrcamentarios: [],
      linhasComFluxoReducao: [],
      tipoVerbaInput: null,
      orcamentoInput: null,
      periodoOrcamentarioInput: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      dialogLinhasComFluxoAndamento: false,
      pagination: {},
      totalPage: 0,
    };
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    edicao: {
      type: Boolean,
      default: false,
    },
    ajusteVerba: Object,
  },
  components: {
    InputMoney,
  },
  watch: {
    tipoVerbaInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      if (val === (this.ajusteVerba.tipoVerba || {}).nome) {
        return;
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarTiposVerba(val);
      }, 500);
    },
    orcamentoInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      if (val === (this.ajusteVerba.orcamento || {}).descricao) {
        return;
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarOrcamentos(val);
      }, 500);
    },
    periodoOrcamentarioInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      if (val === (this.ajusteVerba.periodoOrcamentario || {}).descricao) {
        return;
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarPeriodosOrcamentarios(val);
      }, 500);
    },
    pagination: {
      handler() {
        this.carregarLinhasQuePossuemFluxoReducaoEmAndamento();
      },
      deep: true,
    },
  },
  computed: {
    exibeAlertaLinhasComFluxoExistente() {
      return this.totalPage > 0
        && !this.somenteLeitura;
    },
    isRateioDivisao() {
      return this.ajusteVerba.linhas
        && this.ajusteVerba.linhas.length
        && this.ajusteVerba.linhas[0].divisao !== undefined;
    },
    headers() {
      const headers = [];

      if (this.isRateioDivisao) {
        headers.push({
          text: this.$tc('label.divisao', 1), sortable: false, value: 'divisao', width: 70,
        });
      } else {
        headers.push({
          text: this.$t('label.holding'), sortable: false, value: 'holding', width: 70,
        });
      }

      headers.push({
        text: this.$tc('label.valor', 1), sortable: false, value: 'vlrAjuste', width: 30,
      });
      return headers;
    },
  },
  methods: {
    getMoney,
    buscarTiposVerba(filtro) {
      const params = filtro ? { filtro } : {};
      this.resource.buscarTiposVerba(params)
        .then((r) => {
          this.tiposVerba = r.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    tipoVerbaAlterado(tipoVerba) {
      if (this.ajusteVerba.orcamento) {
        this.ajusteVerba.orcamento = null;
      }
      if (this.somenteLeitura || !tipoVerba) return;
      this.$emit('AjusteVerbaReducaoMassivaFormResumo__VerbaSelecionada', tipoVerba);
    },
    buscarOrcamentos(filtro) {
      const params = { idTipoVerba: this.ajusteVerba.tipoVerba.id };
      if (filtro) {
        params.filtro = filtro;
      }

      this.resource.buscarOrcamentos(params)
        .then((res) => {
          this.orcamentos = res.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    orcamentoAlterado() {
      if (this.ajusteVerba.periodoOrcamentario) {
        this.ajusteVerba.periodoOrcamentario = null;
      }
    },
    buscarPeriodosOrcamentarios(filtro) {
      const params = {
        idTipoVerba: this.ajusteVerba.tipoVerba.id,
        idOrcamento: this.ajusteVerba.orcamento.id,
      };
      if (filtro) {
        params.filtro = filtro;
      }

      this.resource.buscarPeriodosOrcamentarios(params)
        .then((response) => {
          this.periodosOrcamentarios = response.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    periodoOrcamentarioAlterado() {
      const params = {
        idTipoVerba: this.ajusteVerba.tipoVerba.id,
        idOrcamento: this.ajusteVerba.orcamento.id,
        idPeriodo: this.ajusteVerba.periodoOrcamentario.id,
      };
      this.resource.buscarPlanejadoSaldoPeriodo(params)
        .then((response) => {
          this.ajusteVerba.periodoOrcamentario.planejado = response.data.planejado;
          this.ajusteVerba.periodoOrcamentario.saldo = response.data.saldo;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    aplicaPeriodo() {
      if (!this.valida()) return;
      this.$emit('AjusteVerbaReducaoMassivaFormResumo__AplicaPeriodo', this.ajusteVerba);
      this.carregarLinhasQuePossuemFluxoReducaoEmAndamento();
    },
    abrirModal() {
      this.dialogLinhasComFluxoAndamento = true;
    },
    fecharModal() {
      this.dialogLinhasComFluxoAndamento = false;
    },
    carregarLinhasQuePossuemFluxoReducaoEmAndamento() {
      const params = {
        page: this.pagination.page || 1,
        size: this.pagination.itemsPerPage || 10,
        idOrcamento: this.ajusteVerba.orcamento.id,
        idPeriodo: this.ajusteVerba.periodoOrcamentario.id,
      };
      this.resource.carregarLinhasQuePossuemFluxoReducaoEmAndamento(params)
        .then((res) => {
          this.linhasComFluxoReducao = res.data.resposta;
          this.totalPage = res.data.quantidadeRegistrosPagina;
        })
        .catch((err) => this.$error(this, err));
    },
    valida() {
      return this.$refs.form.validate();
    },
    carregaDetalhamento() {
      this.tiposVerba = [this.ajusteVerba.tipoVerba];
      this.orcamentos = [this.ajusteVerba.orcamento];
      this.periodosOrcamentarios = [this.ajusteVerba.periodoOrcamentario];
      this.periodoOrcamentarioAlterado();
    },
  },
  mounted() {
    if (this.edicao) {
      this.carregaDetalhamento();
    }
  },
};
</script>
