export default class Passo3 {
  constructor(id, listaCliente = [], listaFornecedor = [], listaProduto = [], listaPadrao = [],
    listaPadraoLiquidacao = [], listaLiquidacaoManual = [], listaDinamica = [],
    listaCarteiraCliente = [], pagamentoAcao = 'CLIENTE', indCalculoAutomatico = false,
    indDescricaoAutomatica = false, template, valor) {
    this.id = id;
    this.listaCliente = listaCliente;
    this.listaCarteiraCliente = listaCarteiraCliente;
    this.listaFornecedor = listaFornecedor;
    this.listaProduto = listaProduto;
    this.listaPadrao = listaPadrao;
    this.listaPadraoLiquidacao = listaPadraoLiquidacao;
    this.listaLiquidacaoManual = listaLiquidacaoManual;
    this.listaDinamica = listaDinamica;
    this.pagamentoAcao = pagamentoAcao;
    this.indCalculoAutomatico = indCalculoAutomatico;
    this.indDescricaoAutomatica = indDescricaoAutomatica;
    this.template = template;
    this.valor = valor;
  }

  static setListaDinamica(listaDinamica) {
    this.listaDinamica = listaDinamica;
  }
}
