var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"card-size"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},on:{"input":_vm.filtrar},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.lista,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
        itemsPerPageOptions: [10, 25, 50],
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
      var item = ref.item;
return [(_vm.canAccessCRUD)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onEditar(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.editar')))])]):_vm._e()]}},{key:"item.id_externo",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.idExterno)+" ")]}},{key:"item.nom_concorrente",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomConcorrente)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }