import { buscarProdutosSelecao } from '../../common/resources/produto';

export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      tipoContratoResource: this.$api.tipoContrato(this.$resource),
      produtoResource: this.$api.produto(this.$resource),
      divisaoResource: this.$api.divisao(this.$resource),
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource
                .buscarAtivosDeContrato({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'tipo_contrato',
            nomCampoId: 'id_contrato_tipo',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction:
                (autocomplete) => this.tipoContratoResource.buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'LISTA_CHECKBOX',
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$t('status_entidade.apuracao_previa'),
                  valor: 'APURACAO_PREVIA',
                },
                {
                  texto: this.$t('status_entidade.aguardando_apuracao'),
                  valor: 'AGUARDANDO_APURACAO',
                },
                {
                  texto: this.$t('status_entidade.aguardando_aprovacao'),
                  valor: 'AGUARDANDO_APROVACAO',
                },
                {
                  texto: this.$tc('status_entidade.aprovado', 1),
                  valor: 'APROVADO',
                },
                {
                  texto: this.$t('status_entidade.cancelado'),
                  valor: 'CANCELADO',
                },
                {
                  texto: this.$tc('status_entidade.em_analise', 1),
                  valor: 'EM_ANALISE',
                },
                {
                  texto: this.$tc('status_entidade.reprovado', 1),
                  valor: 'REPROVADO',
                },
              ],
            },
          },
          {
            labelCampo: 'data_inicio_apuracao',
            nomCampo: this.$t('label.data_inicio'),
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim_apuracao',
            nomCampo: this.$t('label.data_fim'),
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'cod_acao',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'cod_contrato',
            tipoCampo: 'NUMERICO',
          },
          {
            labelCampo: 'minhas_aprovacoes_pendentes',
            tipoCampo: 'BOOLEAN',
          },
          {
            labelCampo: 'unidadenegocio',
            nomCampoId: 'id_unidadenegocio',
            tipoCampo: 'LISTA_CHECKBOX',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarUnidadesNegocioEntidadesControlador({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'produto',
            nomCampoId: 'id_produto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  // Filtro utilizado para filtrar a combo de Produtos por UN do usuário logado
                  filtrarPorExtensao: true,
                  ...this.$refs.filtrosRapidos.getValoresDependencias(),
                };
                return buscarProdutosSelecao(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'codigoNome',
            },
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.divisaoResource.selecao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
        ],
      },
    };
  },
};
