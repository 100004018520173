<template>
  <v-container fluid class="pa-0">
    <v-row justify="end">

      <v-tooltip left v-if="mostrarAcompanhamento">
        <template v-slot:activator="{ on }">
          <v-btn text icon
                  v-on="on"
                  @click.native="abrirAcompanhamento">
            <v-icon >assignment_turned_in</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('label.acompanhamento') }}</span>
      </v-tooltip>

      <acao-fluxo
        ref="acaoAprovar"
        icone="thumb_up"
        :texto-acao="$t('label.aprovar')"
        :status="status"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="aprovar">
      </acao-fluxo>

      <acao-fluxo
        ref="acaoEmAnalise"
        icone="announcement"
        :texto-acao="$t('label.solicitar_analise')"
        :status="status"
        :validar-justificativa="true"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="enviarAnalise">
      </acao-fluxo>

      <acao-fluxo
        ref="acaoReprovar"
        icone="thumb_down"
        :texto-acao="$t('label.reprovar')"
        :status="status"
        :validar-justificativa="true"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="reprovar">
      </acao-fluxo>

    </v-row>
    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
    />
  </v-container>
</template>

<script>
import AcaoFluxo from '@/shared-components/AcaoFluxo';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import Acompanhamento from '../../shared-components/workflow/Acompanhamento';

export default {
  name: 'OrcamentoBotoesFluxo',
  props: {
    idOrcamento: Number,
    status: String,
    exibirAcompanhamento: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    AcaoFluxo,
    Confirm,
    Acompanhamento,
  },
  data() {
    return {
      workflowOrcamentoResource: this.$api.workflowOrcamento(this.$resource),

      solicitanteFluxo: false,
      aprovadorFluxo: false,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      justificativa: '',
      statusPassos: {},
    };
  },
  computed: {
    ...generateComputed('ORCAMENTO', [
      'canEdit',
    ]),
    podeAprovar() {
      return this.status === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo;
    },
    mostrarAcompanhamento() {
      return this.exibirAcompanhamento && this.status !== 'CANCELADO';
    },
  },
  methods: {
    carregaInformacoesFluxo() {
      if (this.idOrcamento && this.status) {
        this.verificarUsuarioAprovador(this.idOrcamento, this.status);
      }
    },
    verificarUsuarioAprovador(idOrcamento, status) {
      if (status === 'AGUARDANDO_APROVACAO') {
        this.workflowOrcamentoResource.aprovador({ idOrcamento })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            }
          });
      }
    },
    reprovar(justificativa) {
      const { idOrcamento } = this;
      this.workflowOrcamentoResource.reprovar({ idOrcamento },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$tc('message.orcamento_reprovado', 1));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    aprovar(justificativa) {
      const { idOrcamento } = this;
      this.workflowOrcamentoResource.aprovar({ idOrcamento },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$tc('message.orcamento_aprovado', 1));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    enviarAnalise(justificativa) {
      const { idOrcamento } = this;
      this.workflowOrcamentoResource.enviarAnalise({ idOrcamento },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$tc('message.orcamento_enviado_analise', 1));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirAcompanhamento() {
      const { idOrcamento } = this;
      this.workflowOrcamentoResource.statusPasso({ idOrcamento }, { idOrcamento })
        .then((res) => {
          if (res.data.passos
            && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
  },
  mounted() {
    this.carregaInformacoesFluxo();
  },
};
</script>
