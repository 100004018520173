<template>
  <div class="PlanejamentoAcaoFormRoi">
  </div>
</template>
<script>

export default {
  data() {
    return {
    };
  },
  methods: {
    validaSecaoRoi() {
      return true;
    },
    getDadosRoi: () => ({}),
    focoAlterado() {
    },
  },
};
</script>
