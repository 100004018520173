import getBasePath from '../../produto/common/functions/api-resource';

const basePath = getBasePath('api');
export const basePathJob = getBasePath('job');
const basePathPlanejamento = getBasePath('planejamento_acao', 'adama/cadastro/');

const exportacaoExtratoRebateActions = {
  salvarFiltrosExecucaoExtratoRebate: { method: 'POST', url: `${basePathJob}adama/extrato-rebate` },
  buscarPeriodosOrcamentarios: { method: 'GET', url: `${basePath}ano-fiscal-adama/periodo?idTipoVerba={idTipoVerba}` },
  buscarPeriodosAnoFiscal: { method: 'GET', url: `${basePath}ano-fiscal-adama/periodo-ano-fiscal?idTipoVerba={idTipoVerba}` },
  buscarGruposCutura: { method: 'GET', url: `${basePathPlanejamento}campos/grupo_cultura` },

};

export default (resource) => resource(`${basePath}`, {}, exportacaoExtratoRebateActions);
