<template>
  <div id="autocomplete-box">
    <v-text-field
        id="spreadsheet-autocomplete-input"
        name="spreadsheet-autocomplete-input"
        ref="autocompleteInput"
        v-model="campoBusca"
        :loading="loading"
        :label="label"
        :counter="50"
        maxlength="50"
        @keyup.native="onInputKeyUp"
        autocomplete="off">
    </v-text-field>

    <v-list dense v-if="!loading">
      <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :id="`spreadsheet-autocomplete-menu-item-${index}`"
          class="spreadsheet-autocomplete-menu-item"
          @click="hit(item)">
        <v-list-item-content>
          <v-list-item-title>{{printFunction(item)}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { skipLoading } from '../../common/functions/loading';

export default {
  data() {
    return {
      campoBusca: '',
      items: [],
      timeout: null,
      maxRows: 6,
      hovered: null,
      posicao: -1,
      searchPrevented: false,
      loading: false,
    };
  },
  watch: {
    texto(newVal) {
      if (newVal !== undefined && newVal !== null) {
        window.setTimeout(() => this.campoBusca = newVal);
        this.listenKeyup();
        this.onSearch();
      } else {
        this.unlistenKeyup();
      }

      this.items = [];
      this.posicao = -1;
      this.hovered = null;
      this.searchPrevented = false;
      this.campoBusca = '';
    },
  },
  props: {
    texto: String,
    funcaoBusca: Function,
    indiceVigente: Number,
    atributo: String,
    atributoExibicao: {
      type: String,
      default: null,
    },
    label: String,
  },
  methods: {
    printFunction(item) {
      return item[this.atributoExibicao || this.atributo];
    },
    listenKeyup() {
      document.onkeydown = this.onDocumentKeyboard;
    },
    unlistenKeyup() {
      document.onkeydown = null;
    },
    onDocumentKeyboard(event) {
      // Ignora eventos keyboard quando o foco não se faz
      // presente nos elementos gerenciaveis pelo componente
      const currElement = document.activeElement;
      if (currElement &&
        !(currElement.id === 'spreadsheet-autocomplete-input' ||
          currElement.classList.contains('spreadsheet-autocomplete-menu-item') ||
          currElement.tagName === 'BODY')) {
        return true;
      }

      // MOVIMENTA
      if (event.keyCode == '38' || event.keyCode == '40') {
        event.preventDefault();
        event.returnValue = false;
        window.setTimeout(() => this.onArrowKey(event));
        return false;

        // SELECIONA COM ENTER
      } else if (event.keyCode == '13') {
        this.preventSearch();
        this.hit(this.hovered);
        return false;

        // FECHA COM ESC
      } else if (event.keyCode == '27') {
        this.$emit('SPREADSHEET_AUTOCOMPLETE_CLOSE');
        return false;
      }

      return true;
    },
    hit(item) {
      this.$emit('SPREADSHEET_AUTOCOMPLETE', item !== null ?
        { value: item[this.atributo], item } :
        this.campoBusca);
    },
    busca() {
      this.loading = true;
      skipLoading();

      const filtro = this.campoBusca === '' ? null : this.campoBusca;
      this.funcaoBusca(filtro, this.indiceVigente)
        .then(obj => {
          const { data } = obj;
          const resposta = Array.isArray(data) ?
            data : data.resposta;
          if (resposta.length !== 0) {
            this.items = resposta.length > this.maxRows ?
              resposta.slice(0, this.maxRows) :
              resposta;
          } else {
            this.items = [];
          }
          this.posicao = -1;
          this.loading = false;
        }).catch(err => {
          this.$error(this, err);
          this.loading = false;
        });
    },
    onArrowKey(event) {
      if (event.keyCode == '38') {
        this.preventSearch();

        if (this.posicao === 0) {
          this.getInput().focus();
          this.clearHover();
          return;
        }
        this.posicao--;
        this.resetHover();
      }
      else if (event.keyCode == '40' && this.items.length > 0) {
        this.getInput().blur();
        this.preventSearch();

        if (this.posicao < this.maxRows - 1) {
          this.posicao++;
          this.resetHover();
        }
      }
    },
    onInputKeyUp(event) {
      if (!this.validInputKeys(event)) return;

      if (this.searchPrevented) {
        this.searchPrevented = false;
        return;
      }

      this.onSearch();
    },
    onSearch() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(this.busca);
    },
    clearHover() {
      this.hovered = null;
      this.getItems()
        .forEach(element => element.classList.remove('hovered'));
    },
    resetHover() {
      if (this.items.length === 0) return;

      this.clearHover();
      this.hovered = this.items[this.posicao];

      if (this.getCurrentItem() !== null) {
        this.getCurrentItem().classList.add('hovered');
      }
    },
    validInputKeys(event) {
      const charCode = parseInt(event.keyCode);
      if (charCode > 31 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
        return false;
      }
      return true;
    },
    getItems() {
      const elements = document.getElementsByClassName('spreadsheet-autocomplete-menu-item');
      return Array.from(elements);
    },
    getCurrentItem() {
      return document.getElementById(`spreadsheet-autocomplete-menu-item-${this.posicao}`);
    },
    getInput() {
      return document.getElementById('spreadsheet-autocomplete-input');
    },
    preventSearch() {
      this.searchPrevented = false;
    },
  },
};
</script>

<style>
#autocomplete-area {
  position: relative;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 15;
  top: 0;
  left: 0;
}

#autocomplete-box {
  width: auto;
  min-width: 230px;
  max-width: 400px;
  height: auto;
  z-index: 16;
  box-shadow: 0 1px 5px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);
  border-radius: 2px;
  background: white;
  position: absolute;
  display: block;
  padding: 16px;
}

#autocomplete-box .md-count {
  display: none;
}

#autocomplete-box .md-input-container {
  margin: 0;
  min-height: 35px;
}

#autocomplete-box .md-list-item:hover {
  background-color: hsla(0, 0%, 60%, .2);
  cursor: pointer;
}

#autocomplete-area.show {
  display: block !important;
}

#autocomplete-box .hovered {
  background-color: hsla(0, 0%, 60%, .2);
  cursor: pointer;
}

#autocomplete-box button:hover {
  background-color: initial !important;
}
</style>
