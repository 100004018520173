<template>
  <div>
    <div class="mt-2 mb-2 title-float accent--text d-flex align-center">
      {{$tc('label.apuracao_plano_metas', 1)}}
    </div>
    <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12" md="12" class="pa-0">
          <v-card>
            <v-data-table
              :headers="cabecalhoGrupo"
              :items="linhasGrupo"
              disable-filtering
              disable-pagination
              disable-sort
              hide-default-footer>
              <template v-slot:item.statusAdesao="{ item }">
                {{item.metaMinima ? $t('label.dpm_minimo') : $t('label.nao_aderiu')}}
              </template>
              <template v-slot:item.meta="{ item }">
                {{getNumber(item.meta)}}
              </template>
              <template v-slot:item.faturado="{ item }">
                {{getNumber(item.faturado)}}
              </template>
              <template v-slot:item.pedidos="{ item }">
                {{getNumber(item.pedidos)}}
              </template>
              <template v-slot:item.projetado="{ item }">
                {{getNumber(item.projetado)}}
              </template>
              <template v-slot:item.simulado="{ item }">
                {{getNumber(item.simulado)}}
              </template>
              <template v-slot:item.atingimentoFaturado="{ item }">
                {{getPercent(item.atingimentoFaturado)}}
              </template>
              <template v-slot:item.atingimentoProjetado="{ item }">
                {{getPercent(item.atingimentoProjetado)}}
              </template>
              <template v-slot:item.atingimentoSimulado="{ item }">
                {{getPercent(item.atingimentoSimulado)}}
              </template>
              <template v-slot:item.statusGrupo="{ item }">
                 <div :class="classeStatusGrupo(item.statusGrupo)" v-if="item.metaMinima">
                  <span class="white--text">{{labelStatusGrupo(item.statusGrupo)}}</span>
                </div>
                <span class="text-center" v-else>-</span>
              </template>
              <template v-slot:body.append>
                <tr>
                  <td class="text-right" colspan="3" style="font-weight:bold">
                    {{getNumber(totais.metaTotal)}}
                  </td>
                  <td class="text-right" style="font-weight:bold">
                    {{getNumber(totais.faturadoPontuacao)}}
                  </td>
                  <td class="text-right" style="font-weight:bold">
                    {{getNumber(totais.pedidosPontuacao)}}
                  </td>
                  <td class="text-right" style="font-weight:bold">
                    {{getNumber(totais.projetadoPontuacao)}}
                  </td>
                  <td class="text-right" style="font-weight:bold">
                    {{getNumber(totais.simuladoPontuacao)}}
                  </td>
                  <td class="text-right" style="font-weight:bold">
                    {{getPercent(totais.atingimentoFaturado)}}
                  </td>
                  <td class="text-right" style="font-weight:bold">
                    {{getPercent(totais.atingimentoProjetado)}}
                  </td>
                  <td class="text-right" style="font-weight:bold">
                    {{getPercent(totais.atingimentoSimulado)}}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { getPercent, getNumber } from '../../../../produto/common/functions/helpers';
import InputMoney from '../../../../produto/shared-components/inputs/InputMoney';

export default {
  props: {
    planoMetas: Object,
    gruposCultura: Array,
    produtosComplementares: Array,
  },
  components: {
    InputMoney,
  },
  computed: {
    gatilhoMF() {
      const { atingimentoMarcasFortes } = this.planoMetas.gatilhos;
      return atingimentoMarcasFortes;
    },
    gatilhoDPM() {
      const { atingimentoTotal } = this.planoMetas.gatilhos;
      return atingimentoTotal;
    },
  },
  data() {
    return {
      cabecalhoGrupo: [
        {
          text: this.$t('label.grupo_cultura'),
          value: 'grupoCultura',
          align: 'start',
        },
        {
          text: this.$t('label.status_adesao'),
          value: 'statusAdesao',
          align: 'start',
        },
        {
          text: this.$tc('label.meta', 1),
          value: 'meta',
          align: 'end',
        },
        {
          text: this.$t('label.faturado'),
          value: 'faturado',
          align: 'end',
        },
        {
          text: this.$t('label.pedido'),
          value: 'pedidos',
          align: 'end',
        },
        {
          text: this.$t('label.projetado'),
          value: 'projetado',
          align: 'end',
        },
        {
          text: this.$t('label.simulado'),
          value: 'simulado',
          align: 'end',
        },
        {
          text: this.$t('label.atingimento_faturado'),
          value: 'atingimentoFaturado',
          align: 'end',
        },
        {
          text: this.$t('label.atingimento_projetado'),
          value: 'atingimentoProjetado',
          align: 'end',
        },
        {
          text: this.$t('label.atingimento_simulado'),
          value: 'atingimentoSimulado',
          align: 'end',
        },
        {
          text: this.$t('label.status_atingimento'),
          value: 'statusGrupo',
          align: 'start',
        },
      ],
      linhasGrupo: [],
      totais: {},
    };
  },
  methods: {
    getPercent,
    getNumber,
    classeStatusGrupo(statusGrupo) {
      return {
        'label-verde': statusGrupo === 'ATINGIU',
        'label-laranja': statusGrupo === 'CUMPRIR_CARTEIRA',
        'label-marrom': statusGrupo === 'CUMPRIR_SIMULACAO',
        'label-vermelho': statusGrupo === 'MAIS_PEDIDOS',
        'text-center': true,
      };
    },
    labelStatusGrupo(statusGrupo) {
      switch (statusGrupo) {
        case 'ATINGIU':
          return this.$t('label.status_atingiu');
        case 'CUMPRIR_SIMULACAO':
          return this.$t('label.cumprir_simulacao');
        case 'CUMPRIR_CARTEIRA':
          return this.$t('label.status_cumprir_carteira');
        default:
          return this.$t('label.status_mais_pedidos');
      }
    },
    preencherPlanoMetas() {
      const { linhas, totais } = this.planoMetas;

      this.preencheDadosTabela(linhas);
      this.preencheTotais(totais);
    },
    preencheDadosTabela(linhas) {
      this.linhasGrupo = linhas.map((linha) => ({
        ...linha,
        simulado: 0,
        atingimentoSimulado: 0,
      }));
    },
    preencheTotais(totais) {
      const {
        faturadoPontuacao,
        pedidosPontuacao,
        metaTotal,
        projetadoPontuacao,
        atingimentoFaturado,
        atingimentoProjetado,
      } = totais;
      this.totais = {
        metaTotal,
        faturadoPontuacao,
        pedidosPontuacao,
        projetadoPontuacao,
        simuladoPontuacao: 0,
        atingimentoFaturado,
        atingimentoProjetado,
        atingimentoSimulado: 0,
      };
    },
    calculaAtingimentoGrupos() {
      this.linhasGrupo.forEach((linhaGrupo) => {
        const {
          grupoCultura,
          meta,
          projetado,
        } = linhaGrupo;
        const { marcas } = this.gruposCultura.find((gc) => gc.grupoCultura === grupoCultura);
        const simuladoGrupo = marcas.map((m) => m.pontosSimulacao || 0)
          .reduce((a, b) => a + b, 0);

        linhaGrupo.simulado = simuladoGrupo;
        linhaGrupo.atingimentoSimulado = simuladoGrupo && meta
          ? ((simuladoGrupo + projetado) / meta) * 100
          : 0;
      });
    },
    calcularTotais() {
      const totalSimuladoGC = this.linhasGrupo.map((gc) => gc.simulado)
        .reduce((a, b) => a + b, 0);
      const totalSimuladoPC = this.produtosComplementares.map((pc) => pc.pontosSimulacao || 0)
        .reduce((a, b) => a + b, 0);

      this.totais.simuladoPontuacao = totalSimuladoGC + totalSimuladoPC;

      const { simuladoPontuacao, metaTotal } = this.totais;
      this.totais.atingimentoSimulado = simuladoPontuacao && metaTotal
        ? (simuladoPontuacao / metaTotal) * 100
        : 0;
    },
    calcularStatusGrupos() {
      const {
        atingimentoSimulado: atingSimuladoDpm,
        atingimentoProjetado: atingProjetadoDpm,
        atingimentoFaturado: atingFaturadoDpm,
      } = this.totais;

      this.linhasGrupo.forEach((linhaGrupo) => {
        const {
          atingimentoFaturado: atingFaturadoGrupo,
          atingimentoProjetado: atingProjetadoGrupo,
          atingimentoSimulado: atingSimuladoGrupo,
        } = linhaGrupo;

        const atingiu = atingFaturadoGrupo >= this.gatilhoMF
          && atingFaturadoDpm >= this.gatilhoDPM;
        if (atingiu) {
          linhaGrupo.statusGrupo = 'ATINGIU';
          return;
        }

        const cumprirCarteira = atingProjetadoGrupo >= this.gatilhoMF
          && atingProjetadoDpm >= this.gatilhoDPM;
        if (cumprirCarteira) {
          linhaGrupo.statusGrupo = 'CUMPRIR_CARTEIRA';
          return;
        }

        const cumpriSimulacao = atingSimuladoGrupo >= this.gatilhoMF
          && atingSimuladoDpm >= this.gatilhoDPM;
        if (cumpriSimulacao) {
          linhaGrupo.statusGrupo = 'CUMPRIR_SIMULACAO';
          return;
        }

        linhaGrupo.statusGrupo = 'MAIS_PEDIDOS';
      });
    },
    calcularAtingimento() {
      this.calculaAtingimentoGrupos();
      this.calcularTotais();
      // calcula status apenas apos calcular totais
      this.calcularStatusGrupos();
    },
  },
  mounted() {
    this.preencherPlanoMetas();
  },
};
</script>
<style scoped>
.label-laranja {
  background-color: rgb(241, 196, 15) !important;
  border-color: rgb(241, 196, 15) !important;
}
.label-verde {
  background-color: rgb(26, 188, 156) !important;
  border-color: rgb(26, 188, 156) !important;
}
.label-marrom {
  background-color: rgb(148, 59, 0) !important;
  border-color: rgb(148, 59, 0) !important;
}
.label-vermelho {
  background-color: rgb(231, 76, 60) !important;
  border-color: rgb(231, 76, 60) !important;
}
</style>
