<template>
  <v-form v-model="valid" lazy-validation ref="form">
    <slot></slot>
    <v-row justify="end" class="my-4">
      <v-btn @click="openCancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
      <v-btn v-if="temAnterior && !somenteLeitura" color="secondary" @click="openVoltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
      <v-btn v-if="temAnterior && somenteLeitura" color="secondary" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
      <v-btn v-if="!somenteLeitura || alterarFinalizado" color="secondary" @click="salvarRascunho" class="mr-3">{{ $t('label.salvar_rascunho') }}</v-btn>
      <v-btn v-if="temProximo" color="primary" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
      <v-btn v-if="!temProximo && !somenteLeitura" color="primary" @click="finalizar" class="mr-3">{{ $t('label.finalizar') }}</v-btn>
      <v-btn v-if="!temProximo && alterarFinalizado" color="primary" @click="aplicarAlteracoes" class="mr-3">{{ $tc('label.aplicar_alteracao', 2) }}</v-btn>
    </v-row>
    <confirm
      ref="confirmDialog"
      :message="textoDialog"
      @agree="agreeDialog">
    </confirm>
  </v-form>
</template>

<script>
import Confirm from '../../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  name: 'ParametrizacaoPeriodoPasso',
  components: {
    Confirm,
  },
  props: {
    temProximo: {
      type: Boolean,
      default: false,
    },
    temAnterior: {
      type: Boolean,
      default: false,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
    naoValidaForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      agreeDialog: () => {},
      textoDialog: '',
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    openCancelar() {
      this.textoDialog = this.$t('message.configuracao_cancelar');
      this.agreeDialog = this.cancelar;
      this.$refs.confirmDialog.open();
    },
    cancelar() {
      this.$router.push({ name: 'parametrizacao-periodo' });
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_PERIODO_PASSO_VOLTAR');
    },
    salvarRascunho() {
      this.$emit('PARAMETRIZACAO_PERIODO_PASSO_SALVAR_RASCUNHO');
    },
    openVoltarPasso() {
      this.textoDialog = this.$t('message.configuracao_voltar_passo');
      this.agreeDialog = this.voltarPasso;
      this.$refs.confirmDialog.open();
    },
    proximoPasso() {
      if (this.$refs.form.validate() || this.naoValidaForm) {
        this.$emit('PARAMETRIZACAO_PERIODO_PASSO_PROXIMO');
      }
    },
    finalizar() {
      if (this.validate()) {
        this.$emit('PARAMETRIZACAO_PERIODO_PASSO_FINALIZAR');
      }
    },
    aplicarAlteracoes() {
      if (this.validate()) {
        this.$emit('PARAMETRIZACAO_PERIODO_PASSO_APLICAR_ALTERACOES');
      }
    },
  },
};
</script>
