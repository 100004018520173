<template lang="html">
  <v-form ref="form" lazy-validation autocomplete="off">
    <v-dialog v-model="dialogCadastroHierarquia" persistent max-width="800px">
      <hierarquia ref="hierarquia"
        :objeto="objeto"
        :somenteLeitura="somenteLeitura"
        @HIERARQUIA_INSERIDA="hierarquiaAdicionada"
        @HIERARQUIA_CANCELAR="hierarquiaCancelar"/>
    </v-dialog>
    <v-card>
      <v-card-title>
        {{ isNovoCadastro ? $t('title.cadastrar_novo_produto') : $t('title.editar_produto') }}
      </v-card-title>
      <div class="subtitle-1 ml-4">{{$t('label.preencha_campos_abaixo')}}</div>

      <v-container grid-list-xl fluid>
        <metadados-container-layout
          :metadados-entidade="metadadosProduto"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="produto"
          :somenteLeitura="somenteLeitura"
          :novoCadastro="true"
          v-if="metadadosProduto"
          @MetadadosContainerLayout_abrirCadastro="novaHierarquia"
          ref="container">
          <v-col cols="12" sm="6" md="3" slot="antes">
            <v-text-field
              class="Form-text__invalid"
              id="produto_codigo"
              name="produto_codigo"
              :disabled="somenteLeitura"
              v-model="produto.idExterno"
              :label="`${$t('label.cod_produto')} *`"
              :counter="50"
              maxlength="50"
              :rules="[rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" slot="antes">
            <v-text-field
              class="Form-text__invalid"
              id="produto_nome"
              name="produto_nome"
              :disabled="somenteLeitura"
              v-model="produto.nomProduto"
              :label="`${$t('label.nome')} *`"
              :counter="100"
              maxlength="100"
              :rules="[rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" slot="antes">
            <v-text-field
              class="Form-text__invalid"
              id="produto_ean"
              name="produto_ean"
              :disabled="somenteLeitura"
              v-model="produto.ean"
              :label="$t('label.ean')"
              :counter="20"
              maxlength="20">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" slot="antes">
            <v-text-field
              class="Form-text__invalid"
              id="produto_volume"
              name="produto_volume"
              type="tel"
              min="0"
              :disabled="somenteLeitura"
              v-model="produto.volume"
              :label="`${$tc('label.volume', 1)} *`"
              :counter="12"
              :rules="[rules.required, rules.numeroValido]"
              :maxlength="12">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" slot="antes">
            <v-text-field
              class="Form-text__invalid"
              id="produto_peso"
              name="produto_peso"
              type="tel"
              min="0"
              :disabled="somenteLeitura"
              v-model="produto.peso"
              :label="`${$tc('label.peso', 1)} *`"
              :rules="[rules.required, rules.numeroValido]"
              :counter="12"
              :maxlength="12">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" slot="antes">
            <v-autocomplete
              id="produto_unidade_medida"
              name="produto_unidade_medida"
              v-model="produto.idUnidadeMedida"
              :disabled="somenteLeitura"
              :items="listaUnidadesMedida"
              :search-input.sync="buscaListaUnidadesMedida"
              @click.native="buscarUnidadesMedida"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomUnidade"
              item-value="id"
              :label="`${$tc('label.unidade_medida', 1)} *`"
              clearable
              :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
        </metadados-container-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cancel">{{ $t('label.cancelar') }}</v-btn>
        <v-btn v-if="!somenteLeitura" @click.native="abrirDialogSalvar" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>
    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', {text: $tc('label.produto', 1)})"
      :persistent="true"
      @agree="salvar">
    </confirm>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MetadadosContainerLayout from '../../shared-components/metadados/MetadadosContainerLayout';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import Hierarquia from './Hierarquia';

export default {
  name: 'ProdutoForm',
  props: {
    copia: {
      type: Boolean,
      default: false,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buscaListaUnidadesMedida: null,
      dialog: {
        ref: 'dialog1',
        from: 'saveRegisterFromButton',
        title: this.$t('label.atencao'),
        content: '',
        action() {},
      },
      maiorIndice: null,
      menorIndice: null,
      objeto: {
        indAtivo: true,
      },
      notFoundNewHierarchy: false,
      dialogCadastroHierarquia: false,
      dialogConfirm: false,
      disableButton: false,
      formInvalido: false,
      idProduto: null,
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      layoutClass: { wrap: true },
      listaUnidadesMedida: [],
      produto: {
        ean: '',
        peso: '',
        volume: '',
        idUnidadeMedida: '',
        idExterno: '',
        nomProduto: '',
      },
      produtoSalvar: {},
      resourceProduto: this.$api.produto(this.$resource),
      resourceMetadado: this.$api.metadado(this.$resource),
      rules: {
        required: (value) => (!!value && value.toString().trim().length > 0) || this.$t('message.campo_obrigatorio'),
        requiredMultiplaSelecao: (value) => (value && value.length > 0) || this.$t('message.campo_obrigatorio'),
        numeroValido: (value) => (!!value && !Number.isNaN(value.replace(',', '.').toString()) && Number(value.replace(',', '.')) >= 0) || this.$t('message.numero_invalido'),
      },
      unidadeMedidaResources: this.$api.unidadeMedida(this.$resource),
    };
  },
  watch: {
    buscaListaUnidadesMedida(val) {
      if (this.produto && this.produto.idUnidadeMedida) {
        const selecao = this.listaUnidadesMedida
          .filter((item) => item.id === this.produto.idUnidadeMedida);
        if (selecao.length > 0 && selecao[0].nomUnidade === val) {
          return;
        }
      }

      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (val != null) this.buscarUnidadesMedida(val);
      }, 500);
    },
  },
  components: {
    Confirm,
    Hierarquia,
    MetadadosContainerLayout,
  },
  computed: {
    ...mapGetters('metadados', [
      'getProdutoMetadado',
    ]),
    ...generateComputed('Prd', [
      'canAccessPage',
    ]),
    isNovoCadastro() {
      return !this.$route.params.id;
    },
    isPessoaJuridica() {
      return this.cliente.tipoPessoa === 'JURIDICA';
    },
    mapaCamposDinamicos() {
      if (this.getProdutoMetadado && this.getProdutoMetadado.mapaCamposDinamicos) {
        return this.getProdutoMetadado.mapaCamposDinamicos;
      }
      return [];
    },
    metadadosProduto() {
      if (this.getProdutoMetadado && this.getProdutoMetadado.mapaEntidades) {
        return this.getProdutoMetadado;
      }
      return undefined;
    },
    nomeCompleto() {
      return `${this.produto.nome} ${this.produto.sobrenome}`;
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setProdutoMetadado',
    ]),
    abrirDialogSalvar() {
      if (!this.$refs.form.validate()) return;
      this.abrirSalvar();
    },
    buscarMetadadosLabels() {
      return this.$api.nivelProduto(this.$resource).labels().then((resultado) => {
        this.tipos = resultado.data;
        this.maiorIndice = Math.max(...this.tipos.map((tipo) => tipo.indice));
        this.menorIndice = Math.min(...this.tipos.map((tipo) => tipo.indice));
        this.tipos.forEach((tipo) => {
          if (tipo.indice === this.menorIndice) {
            this.objeto.label = tipo.label;
            this.objeto.tipo = tipo.id;
            this.objeto.indice = tipo.indice;
          }
        });
        this.notFoundNewHierarchy = true;
      });
    },
    abrirSalvar() {
      this.$refs.confirmDialog.open();
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
    buscarUnidadesMedida(busca = null) {
      const param = {
        nom_unidade: busca,
        tamanhoPagina: 50,
        numeroPagina: 0,
      };
      return this.unidadeMedidaResources.listarAtivos(param)
        .then((response) => {
          this.listaUnidadesMedida = response.data.resposta;
        });
    },
    cancel() {
      this.$router.push({ name: 'produto' });
    },
    carregarProduto(id) {
      this.resourceProduto.buscar({ id })
        .then((res) => {
          this.produto = { ...this.produto, ...res.body };
          this.$emit('PRODUTOFORM_Carregado', this.produto);
          if (this.produto.idUnidadeMedida) {
            this.definirUnidadeMedida();
          }
          this.produto.volume = this.formatNumber(this.produto.volume);
          this.produto.peso = this.formatNumber(this.produto.peso);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    formatNumber(item) {
      return (item || '').toString().replace('.', ',');
    },
    definirUnidadeMedida() {
      this.buscarUnidadesMedida().then(() => {
        this.produto.regiao = this.listaUnidadesMedida
          .find((t) => t.id === this.produto.idUnidadeMedida) || {};
        this.$forceUpdate();
      });
    },
    closeDialog(ref, cb) {
      this.$refs[ref].close();
      if (cb) {
        cb();
      }
    },
    hierarquiaAdicionada(hierarquia, label) {
      this.produto[label] = hierarquia;
      this.dialogCadastroHierarquia = false;
      this.$refs.container.refresh();
      this.$toast(this.$t('message.atualizado_com_sucesso'));
    },
    hierarquiaCancelar() {
      this.dialogCadastroHierarquia = false;
    },
    limparExtensao(id) {
      if (entidade.manyToMany) {
        return;
      }
      this.produto[id] = null;
    },
    novaHierarquia() {
      this.dialogCadastroHierarquia = true;
      setTimeout(() => {
        this.$refs.hierarquia.setarTipos(this.tipos);
        this.$refs.hierarquia.setarObjeto(this.objeto);
      }, 5E2);
      setTimeout(() => {
        this.$refs.hierarquia.buscarMetadadosLabels();
      }, 5E2);
    },
    requestAtualizar(object, resource) {
      const { id } = object;

      resource({ id }, object)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
          this.$router.push({ name: 'produto' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    requestGravar(object, resource) {
      resource(object)
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.$router.push({ name: 'produto' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    requestPopularHeader() {
      if (this.getProdutoMetadado) {
        return;
      }
      this.setProdutoMetadado({
        resource: this.resourceMetadado.listarProduto,
      });
    },
    salvar() {
      this.produtoSalvar = {
        ...this.produto,
        ...this.$refs.container.getValoresDependencias(),
      };
      this.produtoSalvar.ean = this.produto.ean;
      this.produtoSalvar.peso = this.produto.peso;
      this.produtoSalvar.volume = this.produto.volume;
      this.produtoSalvar.idUnidadeMedida = this.produto.idUnidadeMedida;
      if (this.$route.params.id) {
        this.requestAtualizar(this.produtoSalvar, this.resourceProduto.atualizar);
      } else {
        this.requestGravar(this.produtoSalvar, this.resourceProduto.gravar);
      }
    },
    selecionaFetchExtensao(entidade) {
      return entidade.manyToMany
        ? this.prepareFetchExtensaoManyToMany(entidade.idNivelExtensao,
          this.produto[this.autocompleteModelAccess(entidade.campo)], entidade.campo)
        : this.prepareFetchExtensao(entidade.idNivelExtensao);
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.idProduto = parseInt(this.$route.params.id, 10);
    }
    if (!this.isNovoCadastro) {
      this.carregarProduto(this.$route.params.id);
    }
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
        this.requestPopularHeader();
      }, 1E3);
      return;
    }
    this.requestPopularHeader();
    this.buscarMetadadosLabels();
  },
};
</script>

<style lang="css">
  .CLIENTEFORM__md-button {
    margin-top: 10px !important;
  }
</style>
