import getBasePath from '../../produto/common/functions/api-resource';

const basePath = getBasePath('api');
const basePathExtensao = getBasePath('api', 'extensao');
const basePathTipoAcao = getBasePath('api', 'tipo-acao/adama');

const exportacaoAcaoPagamentoActions = {
  buscarTiposAcoesAtivos: { methods: 'GET', url: `${basePathTipoAcao}/ativos` },
  listarTiposPagamentos: { method: 'GET', url: `${basePath}tipo-beneficio/tipos-pagamento` },
  listarTiposInvestimentos: { method: 'GET', url: `${basePath}tipo-investimento/tipos-linha-orcamento` },
  listarPeriodosOrcamentarios: { method: 'GET', url: `${basePath}ano-fiscal/periodo?tipo=PLANEJAMENTO_ORCAMENTARIO` },
  buscarNomesFantasias: { method: 'GET', url: `${basePathExtensao}/selecao/mnemonico/{mnemonico}/` },
};

export default (resource) => resource(`${basePath}`, {}, exportacaoAcaoPagamentoActions);
