import moment from 'moment';

moment.locale('pt-br');

export const formatDateDDMMYYYY = (date) => {
  if (!date) return null;
  return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
};

export const formatDateMMYYYY = (date) => {
  if (!date) return null;
  return moment(date, 'YYYY-MM-DD').format('MM-YYYY');
};

export const formatDateMonthYYYY = (date) => {
  if (!date) return null;
  return moment(date, 'YYYY-MM-DD').format('MMM-YYYY');
};

export const parseDateYYYYMMDD = (date) => {
  if (!date || date.length !== 10) return null;
  const retorno = moment(date, 'DD-MM-YYYY');
  if (retorno.isValid()) return retorno.format('YYYY-MM-DD');
  return null;
};

export const parseDateYYYYMM = (date) => {
  if (!date || date.length !== 10) return null;
  const retorno = moment(date, 'DD-MM-YYYY');
  if (retorno.isValid()) return retorno.format('YYYYMM');
  return null;
};

export const formatlastDayOfTheMonth = (date, format = 'DD-MM-YYYY') => {
  if (!date) {
    return null;
  }
  return moment(date, 'MM-YYYY').endOf('month').format(format);
};

export const formatFirstDayOfTheMonth = (date, format = 'DD-MM-YYYY') => {
  if (!date) {
    return null;
  }
  return moment(date, 'MM-YYYY').startOf('month').format(format);
};

export const formatDatePattern = (date, format = 'DD/MM/YYYY') => {
  if (!date) {
    return null;
  }
  return moment(date, format).format('DD/MM/YYYY');
};

export const validarIntervalos = (dtaInicio, dtaFim, dtaInicio2, dtaFim2) => {
  const momentInicio = moment(dtaInicio);
  const momentFim = moment(dtaFim);
  const momentInicio2 = moment(dtaInicio2);
  const momentFim2 = moment(dtaFim2);
  return momentInicio.isBetween(momentInicio2, momentFim2, null, '[]')
    || momentFim.isBetween(momentInicio2, momentFim2, null, '[]')
    || (momentInicio.isSameOrBefore(momentInicio2)
      && momentFim.isSameOrAfter(momentFim2));
};

export const validarIntervalosIguais = (dtaInicio, dtaFim, dtaInicio2, dtaFim2) => {
  const momentInicio = moment(dtaInicio);
  const momentFim = moment(dtaFim);
  const momentInicio2 = moment(dtaInicio2);
  const momentFim2 = moment(dtaFim2);
  return (momentInicio.isSame(momentInicio2)
      && momentFim.isSame(momentFim2));
};

export const maiorIgualDataAtual = (data) => {
  const momentdata = moment(data);
  return moment().isSameOrBefore(momentdata);
};

export const maiorDataAtual = (data) => {
  const momentdata = moment(data);
  return moment().isBefore(momentdata);
};

export const menorIgualDataAtual = (data) => {
  const momentdata = moment(data);
  return moment().isSameOrAfter(momentdata);
};
