<template>
  <v-card style="margin-bottom:1em" >
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.status_acao_exportacao', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px">
          fa-thumbtack
        </v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-select
              id="id_tipo_verba"
              name="id_tipo_verba"
              :items="tiposVerbas"
              v-model="filtrosExportacaoStatusAcoes.id_tipo_verba"
              item-text="nome"
              item-value="id"
              :label="`${$tc('label.tipo_verba', 1)} *`"
              @change="burcarPeriodos"
              :rules="[rules.required]"
              >
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              id="id_periodos"
              name="id_periodos"
              :items="periodos"
              v-model="filtrosExportacaoStatusAcoes.id_periodo"
              item-text="descricao"
              item-value="id"
              :label="`${$tc('label.periodo', 1)} *`"
              :disabled="!filtrosExportacaoStatusAcoes.id_tipo_verba"
              :rules="[rules.required]"
              >
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" >
             <v-autocomplete
              id="campo_estrutura_venda"
              name="campo_estrutura_venda"
              v-model="filtrosExportacaoStatusAcoes.ids_estrutura_venda"
              :items="listaEstruturasVenda"
              @click.native="buscarEstrurasVenda"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nome"
              item-value="id"
              :label="`${$tc('label.divisao', 1)}`"
              clearable
              multiple
              >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" >
             <v-autocomplete
              id="campo_unidades_negocio"
              name="campo_unidades_negocio"
              v-model="filtrosExportacaoStatusAcoes.ids_unidade_negocio"
              @click.native="buscarUnidadesNegocio"
              :items="listaUnidadesNegocios"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomExtensao"
              item-value="id"
              :label="`${$tc('label.unidadenegocio', 1)}`"
              clearable
              multiple
              >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
             <v-autocomplete
              id="campo_nome_fantasia"
              name="campo_nome_fantasia"
              v-model="filtrosExportacaoStatusAcoes.ids_nome_fantasia"
              :items="listaNomesFantasia"
              @click.native="buscarNomesFantasia"
              :search-input.sync="buscaListaNomesFantasia"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomExtensao"
              item-value="id"
              :label="`${$tc('label.nome_fantasia', 1)}`"
              clearable
              multiple
              >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
             <v-autocomplete
              id="campo_linha_investimento"
              name="campo_linha_investimento"
              v-model="filtrosExportacaoStatusAcoes.ids_tipo_investimento"
              :items="listaTiposInvestimentos"
              @click.native="buscarTiposInvestimentos"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nome"
              item-value="id"
              :label="`${$tc('label.tipo_linha_investimento', 1)}`"
              clearable
              multiple
              >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>

import { countRelatorioAcoes } from '../../../../common/resources/count-relatorios-exportacoes'; // eslint-disable-line

export default {
  name: 'RelatorioExportacaoStatusAcoes',
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      exportacaoExtratoRebateResource: this.$api.exportacaoExtratoRebate(this.$resource),
      extensaoAdama: this.$api.extensaoAdama(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      exportacaoAcaoPagamentoResource: this.$api.exportacaoAcaoPagamento(this.$resource),

      buscaListaNomesFantasia: null,
      mostrarFiltros: false,
      filtrosFixados: false,
      workspace: {},
      filtrosExportacaoStatusAcoes: {},
      tiposVerbas: [],
      periodos: [],
      listaEstruturasVenda: [],
      listaNomesFantasia: [],
      listaTiposInvestimentos: [],
      listaUnidadesNegocios: [],
      entidadeWorkspace: 'extrato-status-acoes',
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  watch: {
    buscaListaNomesFantasia(val) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (val != null) this.buscarNomesFantasia(val);
      }, 500);
    },
  },
  methods: {
    carregarWorkspaceFiltros() {
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          this.filtrosFixados = this.workspace.indAberto || false;
          this.filtrosAbertos = this.filtrosFixados;
          this.filtrosExportacaoStatusAcoes = this.workspace.filtros || {};
          this.mostrarFiltros = this.filtrosFixados;
        }).catch((err) => {
          this.$error(this, err);
        }).finally(() => {
          this.carregouCampos = true;
        });
    },
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    resetaFiltros() {
      this.filtrosExportacaoStatusAcoes = {};
      this.$refs.form.reset();
    },
    aplicarFiltros() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const params = {
        ...this.filtrosExportacaoStatusAcoes,
      };

      let quantidadeRegistros = 0;
      countRelatorioAcoes(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > 0) {
            this.$emit('RelatorioExportacaoStatusAcoesAdama__AplicaFiltros',
              params,
              'status_acoes_adama',
              'job');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosExportacaoStatusAcoes;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    busrcarTiposDeVerba() {
      this.orcamentoTipoVerbaResource
        .buscarAtivos().then((response) => {
          this.tiposVerbas = response.data;
        }).catch((err) => {
          this.$toast(err.data.error);
        });
    },
    burcarPeriodos() {
      const param = {
        idTipoVerba: this.filtrosExportacaoStatusAcoes.id_tipo_verba,
      };
      this.exportacaoExtratoRebateResource
        .buscarPeriodosAnoFiscal(param)
        .then((response) => {
          this.periodos = response.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarUnidadesNegocio(autocomplete) {
      const parametros = {
        autocomplete,
      };
      this.planejamentoAcaoResource
        .buscarUnidadesNegocioEntidadesControlador(parametros)
        .then((response) => {
          this.listaUnidadesNegocios = response.data;
        }).catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarNomesFantasia(busca = null) {
      if (typeof busca !== 'string') {
        busca = null;
      }
      const param = {
        autocomplete: busca,
      };
      return this.extensaoAdama.buscarNomesFantasiaRelatorio(param)
        .then((response) => {
          this.listaNomesFantasia = response.data;
        }).catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarEstrurasVenda(autocomplete) {
      this.planejamentoAcaoResource
        .buscarDivisoesPorVisao({ autocomplete }).then((response) => {
          this.listaEstruturasVenda = response.data;
        }).catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarTiposInvestimentos() {
      this.exportacaoAcaoPagamentoResource.listarTiposInvestimentos().then((response) => {
        this.listaTiposInvestimentos = response.data;
      }).catch((err) => {
        this.$toast(err.data.error);
      });
    },
  },
  beforeMount() {
    this.busrcarTiposDeVerba();
    this.carregarWorkspaceFiltros();
  },
};
</script>
