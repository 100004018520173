import getBasePath from '../../produto/common/functions/api-resource';
import resourceBuilder from '../../produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', 'adama/relatorio');

const planejamentoAcaoActions = {
};

export default (resource) => resource(`${basePath}`, {}, planejamentoAcaoActions);

export const listarRelatorioDadosDpm = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'dados-dpm', parametros).doGet();

export const listarRelatorioStatusAdesao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'status-adesao', parametros).doGet();

export const listarRelatorioStatusAtingimento = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'status-atingimento', parametros).doGet();
