import getBasePath from '../functions/api-resource';

const basePath = getBasePath('workflow_estrutural', 'execucao/apuracao/reivindicacao');

const workflowApuracaoReivindicacaoActions = {
  aprovador: { method: 'GET', url: `${basePath}/{idReivindicacao}/passos/aprovador` },
  aprovar: { method: 'PUT', url: `${basePath}/{idReivindicacao}/passos/aprovar` },
  cancelar: { method: 'PUT', url: `${basePath}/{idReivindicacao}/cancelar` },
  gravar: { method: 'POST', url: `${basePath}/{idReivindicacao}` },
  pendentes: { method: 'GET', url: `${basePath}/busca-fluxo-pendente` },
  reprovar: { method: 'PUT', url: `${basePath}/{idReivindicacao}/passos/reprovar` },
  status: { method: 'GET', url: `${basePath}/{idReivindicacao}/status` },
  statusPasso: { method: 'GET', url: `${basePath}?idReivindicacao={idReivindicacao}/passos/status` },
  minhasAprovacoes: { method: 'GET', url: `${basePath}/fluxos/aprovador` },
};

export default (resource) => resource(`${basePath}`, {}, workflowApuracaoReivindicacaoActions);
