export const env = process.env.NODE_ENV;
export const nonProdEnvStr = [
  'development',
  'testing',
  'homolog',
];

export const resourceTable = {
  api: {
    dev: '/api/',
    prod: '/tradelinks_cadastro/api/',
  },
  public: {
    dev: '/public/',
    prod: '/tradelinks_cadastro/public/',
  },
  job: {
    dev: '/job/',
    prod: '/tradelinks_job/api/',
  },
  negociacao: {
    dev: '/negociacao/',
    prod: '/tradelinks_verbas_estruturais/api/negociacao/',
  },
  orcamento: {
    dev: '/orcamento/',
    prod: '/tradelinks_orcamento/api/',
  },
  planejamento_acao: {
    dev: '/planejamento_acao/',
    prod: '/tradelinks_planejamento_acao/api/',
  },
  planejamento_tatico: {
    dev: '/planejamento_tatico/',
    prod: '/tradelinks_planejamento_tatico/api/',
  },
  planejamento: {
    dev: '/planejamento/',
    prod: '/tradelinks_verbas_estruturais/api/planejamento/',
  },
  usuario: {
    dev: '/usuario/',
    prod: '/tradelinks_usuario/api/',
  },
  workflow_tatico: {
    dev: '/workflow_tatico/',
    prod: '/tradelinks_workflow/api/workflow/',
  },
  workflow_estrutural: {
    dev: '/workflow_estrutural/',
    prod: '/tradelinks_verbas_estruturais/api/workflow/',
  },
  workflow: {
    dev: '/workflow/',
    prod: '/tradelinks_workflow/api/',
  },
  apuracao_acao: {
    dev: '/apuracao_acao/',
    prod: '/tradelinks_apuracao_acao/api/',
  },
  apuracao: {
    dev: '/apuracao/',
    prod: '/tradelinks_verbas_estruturais/api/apuracao/',
  },
  execucao: {
    dev: '/execucao/',
    prod: '/tradelinks_execucao/api/',
  },
  execucao_acao_tatica: {
    dev: '/execucao_tatico/',
    prod: '/tradelinks_execucao_tatico/api/',
  },
  pagamento_verbas_estruturais: {
    dev: '/pagamento_verbas_estruturais/',
    prod: '/tradelinks_verbas_estruturais/api/pagamento/',
  },
  pagamento_tatico: {
    dev: '/pagamento_tatico/',
    prod: '/tradelinks_pagamento_tatico/api/',
  },
  pagamento_gestao: {
    dev: '/pagamento/',
    prod: '/tradelinks_pagamento/api/',
  },
  relatorio: {
    dev: '/relatorio/',
    prod: '/tradelinks_relatorio/api/',
  },
  recebimento: {
    dev: '/recebimento/',
    prod: '/tradelinks_liquidacao_acao/api/recebimento/',
  },
  pagamento: {
    dev: '/pagamento/',
    prod: '/tradelinks_liquidacao_acao/api/pagamento/',
  },
  liquidacao: {
    dev: '/liquidacao/',
    prod: '/tradelinks_liquidacao_acao/api/',
  },
};
// (process.env.NODE_ENV && ['development', 'testing'].indexOf(process.env.NODE_ENV) !== -1)

export default (type, api = '') => {
  if (!type) {
    throw Error('[api-resource] Error! type is not defined.');
  }

  if (!resourceTable.hasOwnProperty(type)) {
    throw Error('[api-resource] Error! type is defined BUT'
      + ` it was not found on resourceTable: ${type}`);
  }

  const auxEnv = nonProdEnvStr.indexOf(env) !== -1
    ? 'dev'
    : 'prod';

  return `${(resourceTable[type])[auxEnv]}${api}`;
};
