<template lang="html">
  <v-dialog v-model="showModal" max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ titulo }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-timeline dense class="mx-3 my-1 pt-0">
          <v-timeline-item
              :color="colorPalette[0]"
              fill-dot
              small
              right
              v-if="fluxo.cabecalhoFluxo">

            <v-card class="elevation-2">
              <v-card-title class="title historico_header px-3 py-2">{{$t('label.solicitado_por') + ' ' + fluxo.cabecalhoFluxo.usuarioSolicitante.nome + ' '
                + fluxo.cabecalhoFluxo.usuarioSolicitante.sobrenome}}</v-card-title>
              <v-card-text>
                <v-row class="px-3 py-2">
                  <span><b>{{$t('label.descricao')}}:&nbsp;</b></span>
                  <span>{{fluxo.cabecalhoFluxo.descricao}}</span>
                </v-row>
                <v-row class="px-3 py-2">
                  <span><b>{{$t('label.data_solicitacao')}}:&nbsp;</b></span>
                  <span>{{toDate(fluxo.cabecalhoFluxo.dtaSolicitacao)}}</span>
                </v-row>
                <v-row
                  v-if="fluxo && fluxo.cabecalhoFluxo && fluxo.cabecalhoFluxo.observacaoAcao"
                  class="px-3 py-2">
                  <span><b>{{$t('label.justificativa')}}:&nbsp;</b></span>
                  <span>{{ fluxo.cabecalhoFluxo.observacaoAcao }}</span>
                </v-row>
              </v-card-text>
            </v-card>

          </v-timeline-item>

          <v-timeline-item
            v-for="(item, index) in fluxo.passos"
            :color="styles(item.statusPasso)" fill-dot small right
            v-if="exibePasso(item)" :key="index">

            <template slot="icon">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on" style="cursor: default;">&nbsp;&nbsp;</span>
                </template>
                <span>
                  {{$tc(`status_entidade.${item.statusPasso.toLowerCase()}`, 1)}}
                </span>
              </v-tooltip>
            </template>

            <acompanhamento-item
                :item="item"
                :cliente="fluxo.cliente"
                :entidade="entidade"
                :id-entidade="fluxo.idEntidade">
            </acompanhamento-item>

          </v-timeline-item>
        </v-timeline>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" @click.native="fechar">{{ $t('label.fechar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import AcompanhamentoItem from './AcompanhamentoItem';

export default {
  data() {
    return {
      showModal: false,
      colorPalette: [
        'teal accent-4', // Verde
        'red lighten-1', // Vermelho
        'orange darken-2', // Laranja
        'amber lighten-1', // Amarelo
        'blue-grey lighten-2', // Cinza
        'blue darken-1', // Azul
      ],
    };
  },
  props: {
    fluxo: Object,
    entidade: String,
  },
  components: {
    AcompanhamentoItem,
  },
  computed: {
    titulo() {
      const tituloBase = this.$t('label.acompanhamento_fluxo');
      return this.fluxo && this.fluxo.cabecalhoFluxo
        ? `${tituloBase}  - ${this.fluxo.cabecalhoFluxo.tipoFluxo}`
        : tituloBase;
    },
  },
  methods: {
    open() {
      this.showModal = true;
    },
    fechar() {
      this.showModal = false;
    },
    toDate(data) {
      return this.moment(data, 'DD/MM/YYYY hh:mm:ss').format('DD-MM-YYYY HH:mm:ss');
    },
    exibePasso(passo) {
      return passo.statusPasso !== 'CONDICAO_NAO_ATENDIDA' && passo.statusPasso !== 'TRANSFERIDO';
    },
    styles(status) {
      let colorIndex = 0;
      if (status === 'ENCERRADO') {
        colorIndex = 5;
      } else if (status === 'EM_CADASTRO') {
        colorIndex = 4;
      } else if (status === 'EM_FLUXO') {
        colorIndex = 4;
      } else if (status === 'CANCELADO') {
        colorIndex = 3;
      } else if (status === 'AGUARDANDO_APROVACAO') {
        colorIndex = 2;
      } else if (status === 'REPROVADO') {
        colorIndex = 1;
      } else if (status === 'AGUARDANDO_EXECUCAO_METAS') {
        colorIndex = 6;
      } else if (status === 'AGUARDANDO_APURACAO') {
        colorIndex = 5;
      } else if (status === 'AGUARDANDO_PAGAMENTO') {
        colorIndex = 5;
      } else if (status === 'APURACAO_PREVIA') {
        colorIndex = 4;
      } else if (status === 'EM_ANALISE') {
        colorIndex = 3;
      } else if (status === 'EM_REIVINDICACAO') {
        colorIndex = 2;
      } else {
        colorIndex = 0;
      }

      return this.color || this.colorPalette[colorIndex];
    },
  },
  mounted() {
  },
};
</script>
