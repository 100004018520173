export default class Passo5 {
  constructor(passo5) {
    if (passo5) {
      this._id = passo5.id;
      this._idPeriodoConfiguracao = passo5.idPeriodoConfiguracao;
      this._atingimentoMarcasFortes = passo5.atingimentoMarcasFortes;
      this._atingimentoTotal = passo5.atingimentoTotal;
    }
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get idPeriodoConfiguracao() {
    return this._idPeriodoConfiguracao;
  }

  set idPeriodoConfiguracao(value) {
    this._idPeriodoConfiguracao = value;
  }

  get atingimentoMarcasFortes() {
    return this._atingimentoMarcasFortes;
  }

  set atingimentoMarcasFortes(value) {
    this._atingimentoMarcasFortes = value;
  }

  get atingimentoTotal() {
    return this._atingimentoTotal;
  }

  set atingimentoTotal(value) {
    this._atingimentoTotal = value;
  }

  toJSON() {
    return {
      id: this._id,
      idPeriodoConfiguracao: this._idPeriodoConfiguracao,
      atingimentoMarcasFortes: this._atingimentoMarcasFortes,
      atingimentoTotal: this._atingimentoTotal,
    };
  }
}
