import getBasePath from '../../../produto/common/functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'parametrizacao-periodo');

const grupoActions = {
  listar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}` },
  finalizar: { method: 'PUT', url: `${basePath}/{id}` },
};

export default (resource) => resource(`${basePath}`, {}, grupoActions);
