<template>
<div>
  <v-card style="width: 100%;">
    <v-card-title>
      <h3 class="headline mb-0">{{ $tc('label.nota_debito', 2) }}</h3>
    </v-card-title>
    <v-data-table
      class="tableNotasDebito"
      :headers="headers"
      :items="notas"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item.acoes="{ item }">
        <v-menu bottom
          id="more_vert"
          origin="center center"
          transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="detalhesApuracao(item)">
              <v-list-item-action>
                <v-icon>details</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                {{ $tc('label.detalhe', 2) }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isNotaIntegrada(item)" @click="imprimirNotas(item)">
              <v-list-item-action>
                <v-icon>print</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                  {{ $t('label.imprimir') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="exportarNotas(item)">
              <v-list-item-action>
                <v-icon>get_app</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                  {{ $t('label.exportar') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="canReenviarNotaDebito && notaComErro(item)"
              @click="modalConfirmacaoReenviar(item)">
              <v-list-item-action>
                <v-icon>replay</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                  {{ $t('label.reenviar') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.cod_nota_debito="{ item }">
        <div style="white-space: nowrap">
          {{ item.numero }}
        </div>
      </template>
      <template v-slot:item.statusIntegracao="{ item }">
        <div style="white-space: nowrap">
          {{ $tc(`status_integracao.${item.statusIntegracao}`, 1) }}
          <v-tooltip left v-if="notaComErroOuPendenteIntegracao(item)">
            <template v-slot:activator="{ on: tooltip }">
              <v-btn v-on="{ ...tooltip }" icon text>
                <v-icon>info</v-icon>
              </v-btn>
            </template>
            <span>{{ formataMensagemDeResposta(item.respostaIntegracao) }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.cnpj="{ item }">
        <div style="white-space: nowrap">
          {{ item.cnpj }}
        </div>
      </template>
      <template nowrap v-slot:item.valor_base="{ item }">
        <div style="white-space: nowrap">
          {{ getMoney(item.valorBase) }}
        </div>
      </template>
      <template nowrap v-slot:item.recebimento="{ item }">
        <div style="white-space: nowrap">
          {{ getMoney(item.recebimento) }}
        </div>
      </template>
      <template v-slot:item.data_vencimento="{ item }">
        <div style="white-space: nowrap">
          {{ item.dtaVencimento }}
        </div>
      </template>
      <template v-slot:item.dataContabilizacao="{ item }">
        <div style="white-space: nowrap">
          {{ item.dataContabilizacao }}
        </div>
      </template>
    </v-data-table>
  </v-card>

  <confirm
      ref="modalConfirmacaoReenvio"
      :message="$t('message.reenviar_novamente_nota_debito')"
      @agree="reenviaNotaParaIntegracao"
      @disagree="$refs.modalConfirmacaoReenvio.close()">
    </confirm>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getMoney } from '../../../common/functions/helpers';
import { buscaNotas } from '../../../common/resources/notas-debito-consulta';
import exportacao from '../../../common/functions/exportacao';
import download from '../../../common/resources/downloads';
import getBasePath from '../../../common/functions/api-resource';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';

export default {
  props: {
    exportacao: false,
  },
  data() {
    return {
      resource: this.$api.notasDebitoConsulta(this.$resource),
      notas: [],

      pagination: {
        rowsPerPage: 10,
      },
      totalPage: 0,
      lastPagination: {},
      filtros: null,
      bloquearBotaoExportacao: false,
      notaReenvio: null,
    };
  },
  components: {
    Confirm,
  },
  watch: {
    pagination: {
      handler(pagination) {
        if (this.mesmaPagina(pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = pagination;
        if (this.filtros === null) {
          return;
        }
        this.filtrar();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    usuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
    usuarioFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    isUsuarioExternoMaster() {
      return this.usuarioLogado.indUsuarioExternoMaster;
    },
    headers() {
      const headers = [];
      headers.push({
        text: '', width: '2%', value: 'acoes', sortable: false,
      });
      headers.push({ text: this.$tc('label.numero', 1), value: 'cod_nota_debito', sortable: false });
      headers.push({ text: this.$tc('label.status_integracao', 1), value: 'statusIntegracao', sortable: false });
      headers.push({
        text: this.$tc('label.fornecedor', 1), width: '12%', value: 'fornecedor', sortable: false,
      });
      headers.push({ text: this.$tc('label.categoria', 1), value: 'categoria', sortable: false });
      headers.push({ text: this.$tc('label.cnpj', 1), value: 'cnpj', sortable: false });
      headers.push({ text: this.$tc('label.unidade_negocio', 1), value: 'canal', sortable: false });
      headers.push({ text: this.$tc('label.tipo_acao', 1), value: 'tipoAcao', sortable: false });
      if (this.usuarioFuncionario) {
        headers.push({ text: this.$tc('label.tipo_uso_integrado', 2), value: 'tipoUso', sortable: false });
      }
      headers.push({ text: this.$tc('label.descricao', 1), value: 'descricaoAcao', sortable: false });
      headers.push({ text: this.$tc('label.tipo_pagamento', 1), value: 'tipoPagamento', sortable: false });
      headers.push({ text: this.$tc('label.valor_base', 1), value: 'valor_base', sortable: false });
      headers.push({ text: this.$tc('label.recebimento', 1), value: 'recebimento', sortable: false });
      headers.push({ text: this.$tc('label.data_vencimento', 1), value: 'data_vencimento', sortable: false });
      headers.push({ text: this.$tc('label.data_contabilizacao', 1), value: 'dataContabilizacao', sortable: false });
      return headers;
    },
    canReenviarNotaDebito() {
      if (!this.getAllRoles) {
        return false;
      }
      return this.getAllRoles
        .filter((el) => el === 'NOTA_DEBITO_REENVIO').length > 0;
    },
  },
  methods: {
    getMoney,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    filtrar(filtrosAplicados = null) {
      if (this.usuarioFornecedor && !this.isUsuarioExternoMaster) {
        return;
      }

      if (filtrosAplicados) {
        this.filtros = filtrosAplicados;
      }

      const parametros = {
        ...this.filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };

      buscaNotas(parametros, this.$resource).then((res) => {
        const { resposta, quantidadeRegistrosPagina } = res.data;

        this.totalPage = quantidadeRegistrosPagina;
        this.notas = resposta;
      });
    },
    formataMensagemDeResposta(retornoIntegracao) {
      const mensagem = JSON.parse(retornoIntegracao || '{}');

      return mensagem.userMessage || retornoIntegracao;
    },
    isNotaIntegrada(nota) {
      if (nota.statusIntegracao === 'INTEGRADO') {
        return true;
      }
      return false;
    },
    notaComErroOuPendenteIntegracao(nota) {
      return nota && nota.respostaIntegracao
        && ['ERRO', 'PENDENTE_INTEGRACAO'].includes(nota.statusIntegracao);
    },
    notaComErro(nota) {
      return nota && nota.statusIntegracao === 'ERRO';
    },
    modalConfirmacaoReenviar(nota) {
      this.notaReenvio = nota;
      this.$refs.modalConfirmacaoReenvio.open();
    },
    reenviaNotaParaIntegracao() {
      if (!this.notaReenvio) {
        return;
      }

      const param = {
        idNotaDebito: this.notaReenvio.idRecebimento,
      };

      this.resource.reenviarNotaParaIntegracao(param, param)
        .then(() => {
          this.filtrar();
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    detalhesApuracao(item) {
      const {
        idApuracao,
        idContrato,
        idAcao,
      } = item;

      const rota = !idContrato
        ? 'detalharApuracaoOrigem'
        : 'detalharApuracaoContratoOrigem';
      this.$router.push({
        name: rota,
        params: {
          id: idApuracao,
          idContrato,
          idAcao,
          origem: 'NOTA_DEBITO',
          from: 'NOTA_DEBITO',
        },
      });
    },
    exportarNotas(item) {
      const {
        idApuracao,
        idFornecedor,
      } = item;
      const tipoNota = item.calculoBonificacao === 'SELLIN' ? 'SELLIN' : 'SELLOUT';

      this.resource.verificarDadosExportacao({ idApuracao, idFornecedor, tipoNota })
        .then((response) => {
          if (response.body) {
            const params = {
              id_apuracao: item.idApuracao,
              id_fornecedor: item.idFornecedor,
            };

            if (tipoNota === 'SELLIN') {
              exportacao.exportar(null, 'notas_sellin_memoria', this, params);
            } else if (tipoNota === 'SELLOUT') {
              exportacao.exportar(null, 'notas_sellout_memoria', this, params);
            }
          } else {
            this.$toast(this.$t('message.sem_dados_exportacao'));
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    imprimirNotas(item) {
      const { idRecebimento, numero } = item;

      const basePath = getBasePath('recebimento', 'relatorio');
      const resource = download(this.$http);

      resource.downloadGet(basePath, {
        param: `?numNota=${numero}`,
        url: `nota-debito/${idRecebimento}`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
      });
    },
    exportarDados() {
      this.$emit('TABELA_EXPORTAR_DADOS');
    },
  },
};
</script>
<style>
  .tableNotasDebito .v-data-table__wrapper table {
    width: 150%;
  }
</style>
