import { skipLoading } from '../../common/functions/loading';

export default {
  data() {
    return {
      workflowOrcamentoResource: this.$api.workflowOrcamento(this.$resource),
      statusPassos: {},
      orcamentoSelecionado: null,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      justificativa: '',
      justificativaSomenteLeitura: false,
    };
  },
  methods: {
    aprovar(justificativa) {
      const idOrcamento = this.orcamentoSelecionado.id;
      this.workflowOrcamentoResource.aprovar({ idOrcamento },
        { observacao: justificativa })
        .then(() => {
          this.buscarStatusFluxo(this.orcamentoSelecionado, true, () => {
            if (this.orcamentoSelecionado.fluxo.statusFluxo === 'APROVADO') {
              this.orcamentoSelecionado.status = 'APROVADO';
              this.$forceUpdate();
            }
          });
          this.orcamentoSelecionado.aprovadorFluxo = false;
          this.$forceUpdate();
          this.$toast(this.$t('message.orcamento_aprovado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    reprovar(justificativa) {
      const idOrcamento = this.orcamentoSelecionado.id;
      this.workflowOrcamentoResource.reprovar({ idOrcamento },
        { observacao: justificativa })
        .then(() => {
          this.orcamentoSelecionado.status = 'REPROVADO';
          this.buscarStatusFluxo(this.orcamentoSelecionado);
          this.orcamentoSelecionado.aprovadorFluxo = false;
          this.$toast(this.$t('message.orcamento_reprovado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    enviarAnalise(justificativa) {
      const idOrcamento = this.orcamentoSelecionado.id;
      this.workflowOrcamentoResource.enviarAnalise({ idOrcamento },
        { observacao: justificativa })
        .then(() => {
          this.orcamentoSelecionado.status = 'EM_ANALISE';
          this.buscarStatusFluxo(this.orcamentoSelecionado);
          this.orcamentoSelecionado.aprovadorFluxo = false;
          this.$toast(this.$t('message.orcamento_enviado_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    confirmarExecutarAnalise(orcamento) {
      this.orcamentoSelecionado = orcamento;
      const content = this.$t('message.deseja_executar_analise');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              this.executarAnalise();
            }
          },
        ));
    },
    executarAnalise() {
      const idOrcamento = this.orcamentoSelecionado.id;
      this.orcamentoResource.executarAnalise({ idOrcamento }, { idOrcamento })
        .then(() => this.editarOrcamento(idOrcamento))
        .catch((err) => {
          this.$toast(err);
        });
    },
    verificarUsuarioSolicitante(orcamento) {
      const { status, id } = orcamento;
      if (['EM_CADASTRO', 'EM_ANALISE', 'AGUARDANDO_APROVACAO'].indexOf(status) < 0) {
        return Promise.resolve();
      }

      skipLoading();
      return this.workflowOrcamentoResource.solicitante({ idOrcamento: id })
        .then((response) => {
          orcamento.solicitanteFluxo = response.data.usuarioHabilitado;
        });
    },
    verificarUsuarioAprovador(orcamento) {
      const { status, id } = orcamento;
      if (status !== 'AGUARDANDO_APROVACAO') {
        return Promise.resolve();
      }

      skipLoading();
      return this.workflowOrcamentoResource.aprovador({ idOrcamento: id })
        .then((response) => {
          orcamento.aprovadorFluxo = response.data.usuarioHabilitado;
        });
    },
    buscarStatusFluxo(orcamento, recarregando, cb) {
      const { status, id } = orcamento;
      if (status !== 'REPROVADO' && status !== 'EM_ANALISE' && !recarregando) {
        return;
      }

      skipLoading();
      this.workflowOrcamentoResource.status({ idOrcamento: id })
        .then((response) => {
          orcamento.fluxo = response.data;
          if (cb) cb();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirAcompanhamento(item) {
      const idOrcamento = item.id;
      this.workflowOrcamentoResource
        .existeFluxoCadastrado({ idOrcamento })
        .then((res) => {
          const existeFluxo = res.data;
          if (!existeFluxo) {
            const msg = this.$t('message.orcamento_finalizado_sem_fluxo');
            this.$toast(msg);
            return;
          }
          this.exibeModalAcompanhamento(idOrcamento);
        });
    },
    exibeModalAcompanhamento(id) {
      const idOrcamento = id;
      this.workflowOrcamentoResource.statusPasso({ idOrcamento }, { idOrcamento })
        .then((res) => {
          const { passos } = res.data;
          if (passos
            && (passos[0].statusPasso === 'EM_ANALISE'
              || passos[0].statusPasso === 'REPROVADO')) {
            passos.splice(1);
          }
          this.statusPassos = res.data;
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    confirmarCancelarAcao(orcamento) {
      const content = this.$t('message.deseja_cancelar_orcamento');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              this.cancelarOrcamento(orcamento);
            }
          },
        ));
    },
    cancelarOrcamento(orcamento) {
      const idOrcamento = orcamento.id;

      const fn = this.orcamentoEmCadastro(orcamento)
        ? this.orcamentoResource.cancelar({ idOrcamento }, { idOrcamento })
        : this.workflowOrcamentoResource.cancelar({ idOrcamento }, { observacao: 'CANCELADO' });

      fn.then(() => {
        this.buscarOrcamentos();
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    mostrarDetalhar(item) {
      return !this.orcamentoAprovado(item) && !this.orcamentoEmCadastro(item);
    },
    mostrarCancelar(item) {
      if (this.orcamentoEmCadastro(item)) {
        return this.canEdit;
      } if (this.orcamentoAgAprovacao(item)) {
        return item.solicitanteFluxo;
      }
      return false;
    },
    mostrarAcompanhamento(item) {
      return item.status !== 'EM_CADASTRO' && item.status !== 'CANCELADO';
    },
    orcamentoAgAprovacao(orcamento) {
      const { status } = orcamento;
      return status === 'AGUARDANDO_APROVACAO';
    },
    orcamentoAprovado(orcamento) {
      const { status } = orcamento;
      return status === 'APROVADO';
    },
    orcamentoEmCadastro(orcamento) {
      const { status } = orcamento;
      return status === 'EM_CADASTRO';
    },
    orcamentoEmAnalise(orcamento) {
      const { status } = orcamento;
      return status === 'EM_ANALISE';
    },
    orcamentoCancelado(orcamento) {
      const { status } = orcamento;
      return status === 'CANCELADO';
    },
    abrirJustificativaAprovar(item) {
      this.abrirJustificativaAvaliar(item, this.aprovar, false);
    },
    abrirJustificativaReprovar(item) {
      this.abrirJustificativaAvaliar(item, this.reprovar, true);
    },
    abrirJustificativaAnalise(item) {
      this.abrirJustificativaAvaliar(item, this.enviarAnalise, true);
    },
    abrirJustificativaAvaliar(item, acaoJustificativa, obrigatorio) {
      this.justificativaObrigatoria = obrigatorio;
      this.actionJustificativa = acaoJustificativa;
      this.justificativa = '';
      this.justificativaSomenteLeitura = false;
      this.orcamentoSelecionado = item;
      this.$refs.modalJustificativa.open();
    },
    abrirJustificativa(orcamento) {
      this.justificativa = orcamento.fluxo.passoAtual.observacao;
      this.justificativaSomenteLeitura = true;
      this.$refs.modalJustificativa.open();
    },
  },
};
