<template>
  <div class="AcaoPreviaList" style="padding-bottom: 60px;">
    <acao-previa-list-filtros
      ref="filtros"
      @AcaoPreviaListFiltros__AplicaFiltros="aplicaFiltros">
    </acao-previa-list-filtros>
    <acao-previa-list-dados
      ref="dados"
      :filtros="filtrosAplicados"
      @refazer_busca="aplicaFiltros({})">
    </acao-previa-list-dados>

    <v-tooltip left v-if="canEdit">
      <template v-slot:activator="{ on }">
        <v-btn fixed fab bottom right
         class="v-btn__fab"
         color="primary"
         v-on="on"
         @click.native="criarNovaAcaoPrevia">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>{{$t('label.adicionar')}}</span>
    </v-tooltip>
  </div>
</template>
<script>
import { generateComputed } from '../../produto/common/functions/roles-computed-generator';

import AcaoPreviaListDados from './AcaoPreviaListDados';
import AcaoPreviaListFiltros from './AcaoPreviaListFiltros';

export default {
  name: 'AcaoPreviaList',
  data() {
    return {
      filtrosAplicados: {},
    };
  },
  components: {
    AcaoPreviaListFiltros,
    AcaoPreviaListDados,
  },
  computed: {
    ...generateComputed('ACAO_PREVIA', [
      'canAccessPage',
      'canEdit',
    ]),
  },
  methods: {
    aplicaFiltros(filtros = {}) {
      this.filtrosAplicados = filtros;
      this.$refs.dados.aplicaFiltros(filtros);
    },
    criarNovaAcaoPrevia() {
      this.$router.push({ name: 'novaAcaoPrevia' });
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
