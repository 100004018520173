export default {
  computed: {
    appendIcon() {
      return !this.somenteLeitura ? 'arrow_drop_down' : '';
    },
  },
  data() {
    return {
      centroCustoResource: this.$api.centroCusto(this.$resource),
      produtoResource: this.$api.produto(this.$resource),
      tipoBeneficioResource: this.$api.tipoBeneficio(this.$resource),
      anoFiscalResource: this.$api.anoFiscal(this.$resource),

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredFunctionOpcional: (objLabel, valueLabel, obrigatorio) => [
          () => {
            if (!obrigatorio) {
              return true;
            }

            const objeto = this.contrato[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
        requiredFunction: (objLabel, valueLabel) => [
          () => {
            const objeto = this.contrato[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
      },
      carregado: false,
      contrato: {},
      parametrosAplicados: false,
      metadadosParametrizado: null,

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm6: true,
        md3: true,
      },

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'status',
            tipoCampo: 'TEXTO',
            vlrObrigatorio: true,
            desAtributos: {
              desabilitado: true,
            },
          },
          {
            labelCampo: 'valor',
            tipoCampo: 'DINHEIRO',
            vlrObrigatorio: true,
            desAtributos: {
              desabilitado: false,
            },
          },
          {
            labelCampo: 'tipo_pagamento',
            nomCampoId: 'id_tipo_pagamento',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: (autocomplete) => this.tipoBeneficioResource
                .listarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'periodo_apuracao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            desAtributos: {
              lista: [
                {
                  valor: 'ANUAL',
                  texto: this.$tc('label.anual', 1),
                },
                {
                  valor: 'TRIMESTRAL',
                  texto: this.$tc('label.trimestral', 1),
                },
                {
                  valor: 'MENSAL',
                  texto: this.$tc('label.mensal', 1),
                },
              ],
              textoItem: 'texto',
              valorItem: 'valor',
            },
          },
          {
            labelCampo: 'produto',
            nomCampoId: 'idProduto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresDependencias(),
                };
                return this.produtoResource.selecao(parametros);
              },
              itemValue: 'id',
              itemText: 'nomProduto',
            },
          },
        ],
      },
    };
  },
  methods: {
    iniciaObjetoContrato() {
      this.contrato = {
        status: this.$t('status_entidade.em_cadastro'),
        divisao: null,
        unidadeNegocio: null,
        holding: null,
        cliente: null,
        clientePagador: null,
        grupoFornecedor: null,
        fornecedor: null,
        fornecedorPagador: null,
        valorBase: 0,
      };

      setTimeout(() => {
        this.carregado = true;
      });
    },
    setStatusContrato(statusContrato) {
      this.contrato.status = this.$tc(`status_entidade.${statusContrato.toLowerCase()}`, 1);
    },
  },
};
