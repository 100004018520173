import RelatorioExportacoes from '../../spa/relatorios/exportacoes/RelatoriosExportacoes';
import RealatorioExportacoesForm from '../../spa/relatorios/exportacoes/RealatorioExportacoesForm';

export default {
  path: 'relatorio-exportacao-adama',
  component: RelatorioExportacoes,
  children: [
    {
      path: '',
      name: 'relatorio-exportacao-adama',
      component: RealatorioExportacoesForm,
    },
  ],
};
