<template>
  <div class="SimulacaoDpm">
    <v-container fluid v-if="objetoPassoGeral && simulacao">
      <v-row>
        <v-col class="py-0" cols="12">
          <v-alert type="error" border="left" colored-border elevation="2">
            <h3>{{$t('label.simulacao')}}</h3>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <planejamento-dpm-resumo
            :exibe-cliente-pagador="exibeClientePagador"
            :objeto-passo-geral="objetoPassoGeral"
            :ordenacao-formulario="ordenacaoFormulario"
            :contrato-edicao="contratoEdicao"
            :novo-contrato="false"
            :somente-leitura="true"
            />
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <planejamento-dpm-classificacao
            ref="classificacao"
            :objeto-passo-geral="objetoPassoGeral"
            :somente-leitura="somenteLeitura"
            :indicadores="objetoPassoAcoes.indicadores"
            :classificacao="objetoPassoAcoes.classificacao"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <simulacao-dpm-plano-metas
            :plano-metas="simulacao.planoMetas"
            :grupos-cultura="simulacao.gruposCultura"
            :produtos-complementares="simulacao.produtosComplementares"
            ref="planoMetas"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <simulacao-dpm-grupos-cultura
            :grupos-cultura="simulacao.gruposCultura"
            @recalculo-variaveis="$refs.planoMetas.calcularAtingimento()"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <simulacao-dpm-produtos-complementares
            :produtos-complementares="simulacao.produtosComplementares"
            @recalculo-variaveis="$refs.planoMetas.calcularAtingimento()"
            />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PlanejamentoDpmResumo from '../passo-acoes/PlanejamentoDpmResumo';
import PlanejamentoDpmClassificacao from '../passo-acoes/PlanejamentoDpmClassificacao';
import SimulacaoDpmPlanoMetas from './SimulacaoDpmPlanoMetas';
import SimulacaoDpmGruposCultura from './SimulacaoDpmGruposCultura';
import SimulacaoDpmProdutosComplementares from './SimulacaoDpmProdutosComplementares';

export default {
  props: {
    edicao: Boolean,
    somenteLeitura: Boolean,
    contratoEdicao: Object,
    tipoContrato: {
      type: Object,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
    getObjetoOutroPasso: {
      type: Function,
      required: true,
    },
  },
  components: {
    PlanejamentoDpmResumo,
    PlanejamentoDpmClassificacao,
    SimulacaoDpmPlanoMetas,
    SimulacaoDpmGruposCultura,
    SimulacaoDpmProdutosComplementares,
  },
  computed: {
    exibeClientePagador() {
      return this.configuracao.habilitaClientePagador;
    },
    ordenacaoFormulario() {
      const { relacaoCampos } = this.configuracao;
      return relacaoCampos;
    },
    tituloTipoContrato() {
      return this.configuracao.nome;
    },
  },
  data() {
    return {
      simulacao: null,
      objetoPassoGeral: null,
      objetoPassoAcoes: null,
    };
  },
  methods: {
    abrePasso() {
      this.objetoPassoGeral = this.getObjetoOutroPasso('geral');
      this.objetoPassoAcoes = this.getObjetoOutroPasso('acoes');
      if (!this.simulacao) {
        this.simulacao = {
          ...this.getObjetoOutroPasso('apuracao'),
        };
      }
    },
  },
};
</script>
<style>
.SimulacaoDpm input[id^="InputDecimal"] {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
