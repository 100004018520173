<template>
  <div>
    <h3 class="mb-1 WorkflowFormPassos__subtitulo">{{$tc('label.passos_workflow', 1)}}</h3>
    <v-stepper class="WorkflowFormPassos" v-model="passoAberto" vertical>
      <div :key="index"
        v-for="(passo, index) in passosFluxo">
        <v-row>
          <v-col cols="6">
            <v-stepper-step
              :step="(index + 1)"
              editable>
              {{`${$tc('label.passos', 1)} ${index + 1} / ${passosFluxo.length}`}}
            </v-stepper-step>
          </v-col>
          <v-spacer></v-spacer>
          <div class="mt-3 pt-6 mr-5">
            <v-tooltip bottom
              class="WorkflowFormPassos__opt WorkflowFormPassos__opt__moveup"
              v-show="passoAberto === (index +1)">
              <template v-slot:activator="{ on }">
              <v-btn icon text
                v-on="on"
                :disabled="primeiroPasso(index)"
                @click="moverPassoAcima(index)">
                <v-icon>keyboard_arrow_up</v-icon>
              </v-btn>
              </template>
              <span>{{ $tc('label.mover_passo', 1) }}</span>
            </v-tooltip>

            <v-tooltip bottom
              class="WorkflowFormPassos__opt WorkflowFormPassos__opt__movedown"
              v-show="passoAberto === (index +1)">
              <template v-slot:activator="{ on }">
              <v-btn icon text
                v-on="on"
                :disabled="ultimoPasso(index)"
                @click="moverPassoAbaixo(index)">
                <v-icon>keyboard_arrow_down</v-icon>
              </v-btn>
              </template>
              <span>{{ $tc('label.mover_passo', 1) }}</span>
            </v-tooltip>

            <v-tooltip bottom
              class="WorkflowFormPassos__opt WorkflowFormPassos__opt__remove"
              v-show="passoAberto === (index +1)">
              <template v-slot:activator="{ on }">
              <v-btn icon text
                v-on="on"
                :disabled="unicoPasso()"
                @click="removerPasso(index)">
                <v-icon>remove</v-icon>
              </v-btn>
              </template>
              <span>{{ $tc('label.remover_passo', 1) }}</span>
            </v-tooltip>

            <v-tooltip bottom
              class="WorkflowFormPassos__opt WorkflowFormPassos__opt__add"
              v-show="passoAberto === (index +1)">
              <template v-slot:activator="{ on }">
              <v-btn icon text
                v-on="on"
                @click="adicionarPasso()">
                <v-icon>add</v-icon>
              </v-btn>
              </template>
              <span>{{ $tc('label.adicionar_passo', 1) }}</span>
            </v-tooltip>
          </div>
        </v-row>
        <v-stepper-content :step="(index + 1)">
          <workflow-form-passo
            :passo="passo"
            :condicionais="condicionais" />
          <v-row>
            <v-col cols="12">
              <v-btn
                @click="retornaPasso()"
                :disabled="!podeRetornar">
                {{$tc('label.anterior', 1)}}
              </v-btn>
              <v-btn
                color="primary"
                style="margin-left: 8px;"
                @click="avancaPasso()"
                :disabled="!podeAvancar">
                {{$tc('label.proximo', 1)}}
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </div>
    </v-stepper>
  </div>
</template>
<script>
import WorkflowFormPasso from './WorkflowFormPasso';

export default {
  components: {
    WorkflowFormPasso,
  },
  props: {
    passosFluxo: Array,
    condicionais: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    podeRetornar() {
      return this.maisDeUmPasso && this.passoAberto > 1;
    },
    podeAvancar() {
      return this.maisDeUmPasso && this.passoAberto !== this.passosFluxo.length;
    },
    maisDeUmPasso() {
      return this.passosFluxo.length > 1;
    },
  },
  data() {
    return {
      passoAberto: 1,
    };
  },
  methods: {
    removerPasso(index) {
      this.passosFluxo.splice(index, 1);
      if (index !== 0) {
        this.retornaPasso();
      } else {
        this.passoAberto = 1;
      }
    },
    criarPassoVazio() {
      return {
        descricao: null,
        selecionaAprovador: false,
        sla: null,
        usuariosAprovadores: {
          usuarios: [],
          grupos: [],
          subgrupos: [],
        },
      };
    },
    moverPassoAcima(index) {
      const passo = this.passosFluxo.splice(index, 1)[0];
      this.passosFluxo.splice(index - 1, 0, passo);

      this.retornaPasso();
    },
    moverPassoAbaixo(index) {
      const passo = this.passosFluxo.splice(index, 1)[0];
      this.passosFluxo.splice(index + 1, 0, passo);

      this.avancaPasso();
    },
    unicoPasso() {
      return this.passosFluxo.length === 1;
    },
    primeiroPasso(index) {
      return index === 0;
    },
    ultimoPasso(index) {
      return index === this.passosFluxo.length - 1;
    },
    avancaPasso() {
      this.passoAberto += 1;
    },
    retornaPasso() {
      this.passoAberto -= 1;
    },
    adicionarPasso() {
      this.passosFluxo.push(this.criarPassoVazio());
      setTimeout(() => this.avancaPasso());
    },
  },
  beforeMount() {
    if (!this.passosFluxo.length) {
      this.passosFluxo.push(this.criarPassoVazio());
    }
  },
};
</script>
<style>
.WorkflowFormPassos__subtitulo {
  width: 100%;
  padding: 16px;
}
.WorkflowFormPassos {
  box-shadow: none;
}
.WorkflowFormPassos > div .v-stepper__content {
  padding-left: 0;
}
.WorkflowFormPassos > div:not(:last-child) .v-stepper__content {
  border-left: 1px solid rgba(0,0,0,.12) !important;
}
.WorkflowFormPassos > div {
  position: relative;
}
.WorkflowFormPassos__opt {
  position: absolute !important;
  top: 10px;
}
.WorkflowFormPassos__opt__add {
  right: 10px;
}
.WorkflowFormPassos__opt__remove {
  right: 40px;
}
.WorkflowFormPassos__opt__movedown {
  right: 70px;
}
.WorkflowFormPassos__opt__moveup {
  right: 100px;
}
</style>
