var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"9"}},[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$t('title.carteira_cliente')))])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[(_vm.itensSelecionados && _vm.itensSelecionados.length && _vm.hasErrors)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"slot":"activator","large":"","color":"yellow darken-3"},slot:"activator"},on),[_vm._v("warning")])]}}],null,false,1399138297)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.InativarCarteiraCliente')))])]):(_vm.itensSelecionados && _vm.itensSelecionados.length)?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":_vm.requestAtivarInativar}},[_c('v-icon',[_vm._v("block")])],1):_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},on:{"input":_vm.filtrar},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",class:{ 'controls-puller': _vm.carteiras.length > 5 },attrs:{"must-sort":true,"headers":_vm.headersDinamico,"items":_vm.carteiras,"item-key":"id","options":_vm.pagination,"show-select":"","server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
      itemsPerPageOptions: [10, 25, 50],
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"grey--text text--darken-1",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.verClientes(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("people_outline")])],1),_c('v-list-item-title',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(_vm.$t('label.visualizar_cliente')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.verDivisoes(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("filter_list")])],1),_c('v-list-item-title',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(_vm.$t('label.visualizar_divisao')))])],1),(!_vm.somenteLeitura)?_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(_vm.$t('label.editar_carteira_cliente')))])],1):_vm._e()],1)],1)]}},{key:"item.id_externo",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.idExterno)+" ")]}},(_vm.temUnidadeNegocio)?{key:"item.unidadenegocio",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.unidadenegocio)+" ")]}}:null,{key:"item.ind_ativo",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.indAtivo ? _vm.$t('label.ativo') : _vm.$t('label.inativo'))+" ")]}}],null,true),model:{value:(_vm.itensSelecionados),callback:function ($$v) {_vm.itensSelecionados=$$v},expression:"itensSelecionados"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }