<template>
  <div>
    <v-hover>
      <v-card
        class="mx-auto"
        slot-scope="{ hover }"
        dark
        height="80px"
        width="97%"
        color="white darken-4"
        style="display: flex; justify-content: space-between; padding: 0px;">
        <v-card-title class="py-1" style="height: 75px;">
          <div style="display: flex; height: 100%;" class="mt-2">
            <div>
              <div class="font-weight-bold subtitle-1" style="margin-top: 4px; color: rgb(100, 100, 100);">{{ file.displayedName }}</div>
              <!-- <div class="subtitle-1" style="margin-top: 4px">{{ file.size }}</div> -->
            </div>
          </div>
        </v-card-title>
        <v-card-actions align-end>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on"
              @click="openFile" v-if="hasViewer"><v-icon style="color: #752157;">visibility</v-icon></v-btn>
            </template>
            <span>{{ $t('label.visualizar') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on"
              @click="download"><v-icon style="color: #752157;">cloud_download</v-icon></v-btn>
            </template>
            <span>{{ $t('label.exportar') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on"><v-icon style="color: #007335;">check_circle</v-icon></v-btn>
            </template>
            <span>{{ $t('label.concluido') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on"
              @click="openDialogRemove" v-if="habilitarExclusao" style="color:  rgb(100, 100, 100);"><v-icon>delete</v-icon></v-btn>
            </template>
            <span>{{ $t('label.remover') }}</span>
          </v-tooltip>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-hover>
    <view-file ref="viewer" :file="file" :type="type" v-if="hasViewer" :uri="uri" @download="download"></view-file>
    <confirm ref="dialogConfirm"
             :message="message"
             @agree="agreeConfirm">
    </confirm>
  </div>
</template>

<script>
import downloads from '../../common/resources/downloads';
import ViewFile from './viewer/ViewFile';
import { skipLoading } from '../../common/functions/loading';
import Confirm from '../vuetify/dialog/Confirm';

export default {
  name: 'CardFileNovo',
  props: {
    file: {
      defaut: () => {},
    },
    uri: '',
    habilitarExclusao: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typesFiles: {
        pdf: { color: 'red darken-4', icon: 'static/img/icon_3_pdf_x16.png', pdf: true },
        xls: { color: 'green darken-4', icon: 'static/img/icon_1_excel_x16.png' },
        xlsx: { color: 'green darken-4', icon: 'static/img/icon_1_excel_x16.png' },
        jpg: { color: 'red darken-4', icon: 'static/img/icon_1_image_x16.png', image: 'jpg' },
        jpeg: { color: 'red darken-4', icon: 'static/img/icon_1_image_x16.png', image: 'jpeg' },
        png: { color: 'red darken-4', icon: 'static/img/icon_1_image_x16.png', image: 'png' },
        gif: { color: 'red darken-4', icon: 'static/img/icon_1_image_x16.png', image: 'gif' },
        bmp: { color: 'red darken-4', icon: 'static/img/icon_1_image_x16.png', image: 'bmp' },
        doc: { color: 'blue darken-4', icon: 'static/img/icon_1_word_x16.png' },
        docx: { color: 'blue darken-4', icon: 'static/img/icon_1_word_x16.png' },
        zip: { color: 'grey lighten-4', icon: 'static/img/icon_2_zip_x16.png' },
        war: { color: 'grey lighten-4', icon: 'static/img/icon_2_zip_x16.png' },
        rar: { color: 'grey lighten-4', icon: 'static/img/icon_2_zip_x16.png' },
        mp4: { color: 'red darken-4', icon: 'static/img/icon_1_video_x16.png' },
        mp3: { color: 'red darken-4', icon: 'static/img/icon_1_video_x16.png' },
        avi: { color: 'red darken-4', icon: 'static/img/icon_1_video_x16.png' },
        mkv: { color: 'red darken-4', icon: 'static/img/icon_1_video_x16.png' },
        wmv: { color: 'red darken-4', icon: 'static/img/icon_1_video_x16.png' },
        flv: { color: 'red darken-4', icon: 'static/img/icon_1_video_x16.png' },
        ppt: { color: 'orange darken-4', icon: 'static/img/icon_1_powerpoint_x16.png' },
        pptx: { color: 'orange darken-4', icon: 'static/img/icon_1_powerpoint_x16.png' },
      },
      message: '',
      agreeConfirm: () => {},
      thumbnail: null,
      tentativaThumbnail: 0,
      extension: '',
      type: { color: 'light-blue darken-4', icon: 'static/img/icon_2_file_x16.png' },
    };
  },
  components: {
    Confirm,
    ViewFile,
    SheetFooter: {
      functional: true,
      render(h, { children }) {
        return h('v-sheet', {
          staticClass: 'mt-auto align-center d-flex',
          props: {
            color: 'rgba(0, 0, 0, .36)',
            dark: true,
            height: 50,
          },
        }, children);
      },
    },
  },
  computed: {
    hasViewer() {
      return this.type.image || this.type.pdf;
    },
  },
  methods: {
    setType() {
      const type = this.typesFiles[this.extension ? this.extension.toLowerCase() : ''];
      if (type) {
        this.type = type;
      }
    },
    openDialogRemove() {
      this.message = this.$t('message.remover_arquivo_nome', { fileName: this.file.displayedName });
      this.$refs.dialogConfirm.open();
      this.agreeConfirm = this.remove;
    },
    remove() {
      const file = this.file.name;
      this.$http.delete(`${this.uri}/delete?file=${file}`)
        .then(() => {
          this.$toast(this.$t('message.arquivo_removido'));
          this.$emit('CardFile_remove');
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    openFile() {
      this.$refs.viewer.open();
    },
    setExtension() {
      this.extension = this.file.name.split('.').pop();
      this.setType();
    },
    downloadThumbnail() {
      const file = this.file.name;
      skipLoading();
      this.$http.get(`${this.uri}/thumbnail/download?file=${file}`)
        .then((response) => {
          if (response.data) {
            this.thumbnail = `data:image/jpg;base64,${response.data}`;
          } else {
            this.tentativaThumbnail += 1;
            if (this.tentativaThumbnail < 4) {
              setTimeout(() => this.downloadThumbnail(), 1E3);
            }
          }
        });
    },
    download() {
      const file = this.file.name;
      const fileName = this.file.displayedName;
      const resource = downloads(this.$http);

      resource.downloadGet(this.uri, {
        param: `?file=${file}&fileName=${fileName}`,
        url: 'download',
      })
        .then(() => {
          this.$toast(this.$t('message.download_efetuado'));
        })
        .catch(() => {
          this.$toast('Erro ao baixar. Tente novamente.');
        });
    },
  },
  mounted() {
    this.setExtension();
    this.downloadThumbnail();
  },
};
</script>

<style scoped>
  .CardFile--img {
    max-width: 16px !important;
    height: 16px !important;
  }
</style>
