<template>
  <div class="AjustVerbaList" style="padding-bottom: 60px;">
    <ajuste-verba-list-filtros
      ref="filtros"
      @AjusteVerbaListFiltros__AplicaFiltros="aplicaFiltros">
    </ajuste-verba-list-filtros>
    <ajuste-verba-list-dados
      ref="dados"
      :filtros="filtrosAplicados"
      :ordenacao="['tipoAjuste', 'tipoVerba', 'orcamento']"
      @refazer_busca="aplicaFiltros({})">
    </ajuste-verba-list-dados>
    <ajuste-verba-list-botoes></ajuste-verba-list-botoes>
  </div>
</template>
<script>
import AjusteVerbaListBotoes from '@/spa/ajuste-verba/AjusteVerbaListBotoes';
import { generateComputed } from '../../common/functions/roles-computed-generator';

import AjusteVerbaListFiltros from './AjusteVerbaListFiltros';
import AjusteVerbaListDados from './AjusteVerbaListDados';

export default {
  name: 'AjusteVerbaList',
  data() {
    return {
      filtrosAplicados: {},
    };
  },
  components: {
    AjusteVerbaListBotoes,
    AjusteVerbaListFiltros,
    AjusteVerbaListDados,
  },
  computed: {
    ...generateComputed('AJUSTE_VERBA', [
      'canAccessPage',
    ]),
  },
  methods: {
    aplicaFiltros(filtros = {}) {
      this.filtrosAplicados = filtros;
      this.$refs.dados.aplicaFiltros(filtros);
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
