import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('usuario', 'perfil');

const perfilActions = {
  ...genericActions(basePath),
  buscar: { method: 'GET', url: `${basePath}/{id}` },
  selecao: { method: 'GET', url: `${basePath}/selecao` },
};

export default (resource) => resource(`${basePath}`, {}, perfilActions);
