import getBasePath from '../functions/api-resource';

const basePath = getBasePath('orcamento', 'linha-investimento');

const linhaIvestimentoActions = {
  atualizar: { method: 'PUT', url: `${basePath}` },
  linhasInvestimento: { method: 'GET', url: `${basePath}/linhas-investimento/{codigo}` },
  buscarPorOrcamento: { method: 'GET', url: `${basePath}/orcamento/{codigo}?descricao={descricao}` },
  transferir: { method: 'PUT', url: `${basePath}/transferir/{object}` },
  saldoDisponivel: { method: 'GET', url: `${basePath}/{codigo}/saldo-planejado-disponivel?anoMesInicio={anoMesInicio}&anoMesFim={anoMesFim}` },
};

export default (resource) => resource(`${basePath}`, {}, linhaIvestimentoActions);
