<template>
  <v-data-table
    :headers="headers"
    :items="rateios"
    :class="classTable"
    :no-data-text="$t('label.tabela_sem_conteudo')"
    hide-default-footer
    disable-pagination
    :items-per-page="-1">
     <template v-slot:item.valorRateioOriginal="{ item }">
      {{ getMoney(item.valorRateioOriginal) }}
    </template>
    <template v-slot:item.valorRateio="{ item }">
      <input-money
        dense
        v-model="item.valorRateio"
        :disabled="somenteLeitura"
        :rules="[rules.maximoPadrao]"
        @InputMoney_valorAlterado="(valor) => atualizaValorRateio(valor, item)"
         />
    </template>
  </v-data-table>
</template>
<script>
import { getMoney } from '@/produto/common/functions/helpers';
import InputMoney from '@/produto/shared-components/inputs/InputMoney';

export default {
  name: 'PlanejamentoAcaoFormRateioTabela',
  components: {
    InputMoney,
  },
  props: {
    idAcaoTradePrevia: Number,
    somenteLeitura: Boolean,
    classTable: {
      type: String,
      default: 'elevation-1',
    },
    rateios: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      planejamentoAcaoPreviaResource: this.$api.planejamentoAcaoPrevia(this.$resource),
      headers: [
        { text: this.$tc('label.codigo', 1), value: 'idExterno', sortable: false },
        { text: this.$tc('label.nome', 1), value: 'nomCliente', sortable: false },
        { text: this.$tc('label.cpf_cnpj', 1), value: 'desCnpj', sortable: false },
        { text: this.$tc('label.pagamento_calculado_rateio_adama', 1), value: 'valorRateioOriginal', sortable: false },
        { text: this.$tc('label.pagamento', 1), value: 'valorRateio', sortable: false },
      ],
      rules: {
        maximoPadrao: (value) => value <= 99999999.99 || this.$t('message.valor_padrao_maximo'),
      },
    };
  },
  methods: {
    getMoney,
    atualizaValorRateio(novoValor, item) {
      const diferenca = novoValor - (item.valorRateio || 0);
      this.$emit('RateioProporcionalTabela_atualizaSaldoPendente', diferenca);
    },
  },
};
</script>
