const ApuracaoAcao = () => import('../../../spa/apuracao-acao/ApuracaoAcao');
const ApuracaoAcaoListNivel1 = () => import('../../../spa/apuracao-acao/ApuracaoAcaoListNivel1');
const ApuracaoAcaoListNivel2 = () => import('../../../spa/apuracao-acao/ApuracaoAcaoListNivel2');
const ApuracaoAcaoDetalhes = () => import('../../../spa/apuracao-acao/ApuracaoAcaoDetalhes');

export default {
  path: 'apuracao-acao',
  component: ApuracaoAcao,
  children: [
    {
      path: '',
      name: 'apuracao-acao',
      component: ApuracaoAcaoListNivel1,
    },
    {
      path: 'status/:status',
      name: 'listarApuracoesAcaoNivel2Status',
      component: ApuracaoAcaoListNivel2,
    },
    {
      path: 'grupo-fornecedor/:idGrupoFornecedor',
      name: 'listarApuracoesAcaoNivel2Grupo',
      component: ApuracaoAcaoListNivel2,
    },
    {
      path: 'fornecedor/:idFornecedor',
      name: 'listarApuracoesAcaoNivel2Fornecedor',
      component: ApuracaoAcaoListNivel2,
    },
    {
      path: 'fornecedor-pagador/:idFornecedorPagador',
      name: 'listarApuracoesAcaoNivel2FornecedorPagador',
      component: ApuracaoAcaoListNivel2,
    },
    {
      path: 'holding/:idHolding',
      name: 'listarApuracoesAcaoNivel2Holding',
      component: ApuracaoAcaoListNivel2,
    },
    {
      path: 'cliente/:idCliente',
      name: 'listarApuracoesAcaoNivel2Cliente',
      component: ApuracaoAcaoListNivel2,
    },
    {
      path: ':mnemonico/:idExterno',
      name: 'listarApuracoesAcaoNivel2Extensao',
      component: ApuracaoAcaoListNivel2,
    },
    {
      path: 'regional/:idRegional',
      name: 'listarApuracoesAcaoNivel2Regional',
      component: ApuracaoAcaoListNivel2,
    },
    {
      path: 'status/:status/acao/:idAcao/apuracao/:id',
      name: 'detalharApuracaoAcaoStatus',
      component: ApuracaoAcaoDetalhes,
    },
    {
      path: 'grupo-fornecedor/:idGrupoFornecedor/acao/:idAcao/apuracao/:id',
      name: 'detalharApuracaoAcaoGrupo',
      component: ApuracaoAcaoDetalhes,
    },
    {
      path: 'fornecedor/:idFornecedor/acao/:idAcao/apuracao/:id',
      name: 'detalharApuracaoAcaoFornecedor',
      component: ApuracaoAcaoDetalhes,
    },
    {
      path: 'fornecedor-pagador/:idFornecedorPagador/acao/:idAcao/apuracao/:id',
      name: 'detalharApuracaoAcaoFornecedorPagador',
      component: ApuracaoAcaoDetalhes,
    },
    {
      path: 'holding/:idHolding/acao/:idAcao/apuracao/:id',
      name: 'detalharApuracaoAcaoHolding',
      component: ApuracaoAcaoDetalhes,
    },
    {
      path: 'cliente/:idCliente/acao/:idAcao/apuracao/:id',
      name: 'detalharApuracaoAcaoCliente',
      component: ApuracaoAcaoDetalhes,
    },
    {
      path: ':mnemonico/:idExterno/acao/:idAcao/apuracao/:id',
      name: 'detalharApuracaoAcaoExtensao',
      component: ApuracaoAcaoDetalhes,
    },
    {
      path: 'regional/:idRegional/acao/:idAcao/apuracao/:id',
      name: 'detalharApuracaoAcaoRegional',
      component: ApuracaoAcaoDetalhes,
    },
    {
      path: ':id/detalhamento/origem/:origem',
      name: 'detalharApuracaoOrigem',
      component: ApuracaoAcaoDetalhes,
    },
  ],
};
