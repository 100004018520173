import getBasePath from '../functions/api-resource';

const basePath = getBasePath('api', 'workspace-filtros/{entidade}');

const workspaceFiltrosActions = {
  pesquisar: { method: 'GET', url: `${basePath}` },
  pesquisarPorIdEntidade: { method: 'GET', url: `${basePath}?idEntidade={idEntidade}` },
  inserir: { method: 'POST', url: `${basePath}` },
  atualizar: { method: 'PUT', url: `${basePath}` },
};

export default (resource) => resource(`${basePath}`, {}, workspaceFiltrosActions);
