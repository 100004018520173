<template lang="html">
  <v-tabs
    v-model="tabSelecionada"
    class="relatorio-list__tabs"
    grow
    color="accent"
    background-color="rgba(0,0,0,0)">
    <v-tab class="white-text" href="#tab-dados-dpm" v-if="canAccessRelatorioDadosDpm">
      {{ $t('title.dados_dpm') }}
    </v-tab>
    <v-tab class="white-text" href="#tab-status-adesao" v-if="canAccessRelatorioStatusAdesao">
      {{ $t('title.status_adesao') }}
    </v-tab>
    <v-tab class="white-text" href="#tab-status-atingimento" v-if="canAccessRelatorioStatusAtingimento">
      {{ $t('title.status_atingimento') }}
    </v-tab>
    <v-tab class="white-text" href="#tab-status-atingimento-historico" v-if="canAccessRelatorioStatusAtingimentoHistorico">
      {{ $t('title.status_atingimento_historico') }}
    </v-tab>

    <v-tabs-items v-model="tabSelecionada">
      <v-tab-item id="tab-dados-dpm" v-if="canAccessRelatorioDadosDpm">
        <relatorio-dpm ref="tab-dados-dpm"/>
      </v-tab-item>

      <v-tab-item id="tab-status-adesao" v-if="canAccessRelatorioStatusAdesao">
        <relatorio-status-adesao ref="tab-status-adesao"/>
      </v-tab-item>

      <v-tab-item id="tab-status-atingimento" v-if="canAccessRelatorioStatusAtingimento">
        <relatorio-status-atingimento ref="tab-status-atingimento"/>
      </v-tab-item>

      <v-tab-item id="tab-status-atingimento-historico" v-if="canAccessRelatorioStatusAtingimentoHistorico">
        <relatorio-status-atingimento-historico ref="tab-status-atingimento-historico"/>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import { mapGetters } from 'vuex';
import RelatorioDpm from './dados-dpm/RelatorioDPM';
import RelatorioStatusAdesao from './status-adesao/RelatorioStatusAdesao';
import RelatorioStatusAtingimento from './status-atingimento/RelatorioStatusAtingimento';
import RelatorioStatusAtingimentoHistorico from './status-atingimento-historico/RelatorioStatusAtingimentoHistorico';

export default {
  data() {
    return {
      tabSelecionada: null,
    };
  },
  components: {
    RelatorioDpm,
    RelatorioStatusAdesao,
    RelatorioStatusAtingimento,
    RelatorioStatusAtingimentoHistorico,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canAccessRelatorioDadosDpm() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('REL_CONTRATO_DADOS_DPM')).length;
    },
    canAccessRelatorioStatusAdesao() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('REL_CONTRATO_STATUS_ADESAO')).length;
    },
    canAccessRelatorioStatusAtingimento() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('REL_CONTRATO_STATUS_ATINGIMENTO')).length;
    },
    canAccessRelatorioStatusAtingimentoHistorico() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('REL_CONTRATO_STATUS_ATINGIMENTO_HISTORICO')).length;
    },
  },
};
</script>

<style scoped>
@import '../../../produto/assets/vendors/amcharts/plugins/export/export.css';
</style>
<style lang="scss">
.relatorio-list__tabs a.v-tab {
  color: var(--v-accent) !important;
}
.relatorio-list__tabs .v-tabs-items {
  background: none;
}
.relatorio-list__tabs div.v-slide-group__wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

</style>
