<template>
  <div id="treelist" class="TreeList__height"></div>
</template>

<script>
export default {
  name: 'TreeList',
  props: {
    dataSource: Array,
    colunaChave: String,
    colunaChavePai: String,
    colunaTemItem: String,
    load: Function,
    colunas: Array,
    searchPanel: {
      type: Boolean,
      default: false,
    },
    cacheEnabled: {
      type: Boolean,
      default: true,
    },
    searchPlaceholder: String,
  },
  data() {
    return {
      treeList: null,
    };
  },
  watch: {
    dataSource(valor) {
      if (this.treeList) {
        this.treeList.dataSource = valor;
        this.treeList.refresh();
      }
    },
  },
  methods: {
    startTreeList() {
      this.treeList = $('#treelist').dxTreeList({
        dataSource: this.prepararDataSource(),
        cacheEnabled: this.cacheEnabled,
        searchPanel: {
          visible: this.searchPanel,
        },
        sorting: {
          clearText: this.$t('label.ordernar_limpar'),
          ascendingText: this.$t('label.ordernar_crescente'),
          descendingText: this.$t('label.ordenar_decrescente'),
        },
        remoteOperations: {
          filtering: !!this.load,
        },
        keyExpr: this.colunaChave,
        parentIdExpr: this.colunaChavePai,
        hasItemsExpr: this.colunaTemItem,
        rootValue: '',
        noDataText: this.$t('label.tabela_sem_conteudo'),
        columns: this.colunas,
      }).dxTreeList('instance');

      if (this.searchPanel && this.searchPlaceholder) {
        document.querySelector('.dx-placeholder').setAttribute('data-dx_placeholder', this.searchPlaceholder);
      }
    },
    reload() {
      if (!this.treeList) return;
      const nodes = [];
      this.treeList.forEachNode((linha) => {
        nodes.push(linha);
      });
      const size = nodes.length;
      if (size === 0) this.treeList.refresh();
      else {
        nodes.forEach((linha, idx) => {
          if (idx === size - 1) {
            this.treeList.collapseRow(linha.key).then(() => {
              setTimeout(() => this.treeList.refresh(), 5E2);
            });
          } else {
            this.treeList.collapseRow(linha.key);
          }
        });
      }
    },
    prepararDataSource() {
      if (this.load) {
        return {
          load: this.load,
        };
      }
      return this.dataSource;
    },
  },
  mounted() {
    if (window.DevExpress) {
      this.startTreeList();
      return;
    }
    $.getScript('static/js/devextreme/dx.custom.js', () => {
      this.startTreeList();
    });
  },
};
</script>

<style>
  .TreeList__height > div > div.dx-treelist-rowsview.dx-treelist-nowrap.dx-empty {
    min-height: 57px;
  }

  #treelist > div > div.dx-treelist-rowsview.dx-treelist-nowrap > div > table > tbody > tr.dx-row.dx-virtual-row {
    display: none;
  }
</style>

<style src="../../assets/vendors/devextreme/css/dx.common.css"></style>
<style src="../../assets/vendors/devextreme/css/material.custom.css"></style>
