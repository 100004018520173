<template>
  <div>
    <v-container fluid px-0 py-2 class="Container_Nivel2_Recebimento">
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-text-field
            v-model="pesquisaCards"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.pesquisar', 1)}`"
            class="Barra_Pesquisa"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-container fluid grid-list-md class="Container_Cards">
          <v-data-iterator
            :items="lista"
            :options.sync="pagination"
            :hide-default-footer="12 >= totalPage"
            :server-items-length="totalPage"
            :footer-props="{
              itemsPerPageOptions: rowsPerPageItems,
            }">
            <template v-slot:default="props">
              <v-row justify="start" class="mx-0">
                <v-col
                  v-for="item in props.items"
                  :key="item.codApuracao"
                  cols="12"
                  sm="3">
                  <v-hover v-slot:default="{ hover }">
                    <v-card :class="`elevation-${hover ? 5 : 1}`" fill-height class="clickable Card_Recebimento">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-card-title class="Card_Title pa-1 justify-center font-weight-medium"  @click="selectItem(item)">
                            <v-row justify="center" align="center">
                              <v-col cols="12" class="pa-1">
                                <v-row justify="center" align="center">{{ item.codAcao }} - {{ item.codApuracao }}</v-row>
                                <v-row justify="center" align="center">{{ item.titulo | truncate(25) }}</v-row>
                              </v-col>
                            </v-row>
                          </v-card-title>
                        </template>
                        <div>
                          {{ $t('label.descricao') }}: {{ item.descricaoAcao | truncate(25) }}<br />
                        </div>
                      </v-tooltip>
                      <v-card-text class="Card_Content py-0 headline font-weight-light" @click="selectItem(item)">
                        <v-row justify="center" align="center">
                          <v-col cols="12" class="pa-1">
                            <v-row justify="center" align="center">{{ getMoney(item.valorRecebimento) }}</v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="Card_Actions pa-1">
                        <v-row align="center" justify="end">
                          <v-col cols="12" sm="2" style="text-align: end;">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon @click.native="abrirModalConfirmacao('exportar', item)" v-on="on">print</v-icon>
                              </template>
                              <div>
                                {{ $t('label.exportar') }} {{ $tc('label.nota_debito', 1) }}
                              </div>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
            <template v-slot:no-data>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
            <template v-slot:no-results>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-row>
    </v-container>
    <confirm
      ref="modalConfirmacao"
      :message="mensagemConfirmacao"
      @agree="validarAcao">
    </confirm>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  getMoney,
  getPercent,
} from '../../../common/functions/helpers';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import { listarRecebimentosNivel2, listarRecebimentosNivel2Simplificado } from '../../../common/resources/liquidacao/recebimento';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import getBasePath from '../../../common/functions/api-resource';
import exportacao from '../../../common/resources/downloads';

export default {
  name: 'RecebimentoDadosNivel2Agrupado',
  components: {
    Confirm,
  },
  props: {
    objetoAcao: Object,
  },
  data() {
    return {
      pesquisaCards: '',
      mensagemConfirmacao: '',
      objetoSelecionado: '',
      lista: [],
      filtrosAplicados: {},
      rowsPerPageItems: [12],
      pagination: {
        itemsPerPage: 12,
      },
      totalPage: 0,
      buscando: false,
      esperarDigitar: false,
      idFornecedor: null,
      origem: null,
      acaoConfirmacao: null,
      idRecebimento: null,
      numNota: null,
    };
  },
  watch: {
    filtroLiquidacaoAcao: {
      handler() {
        this.filtrosAplicados = this.filtroLiquidacaoAcao;
        this.filtrar();
      },
    },
    pagination: {
      handler() {
        if (this.filtroLiquidacaoAcao) {
          this.filtrar();
        }
      },
      deep: true,
    },
  },
  filters: {
    truncate(value, limit) {
      if (value.length > limit) {
        value = value.substring(0, (limit - 3)).concat('...');
      }
      return value;
    },
  },
  computed: {
    ...generateComputed('REC_ACAO', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
    ...mapGetters('filtros', [
      'filtroLiquidacaoAcaoNivel2',
      'filtroLiquidacaoAcao',
    ]),
  },
  methods: {
    getMoney,
    getPercent,
    ...mapActions('filtros', [
      'setFiltroLiquidacaoAcaoNivel2',
    ]),
    selectItem(item) {
      this.consultar(listarRecebimentosNivel2Simplificado, (response) => {
        const listaSimplificada = response.data;

        const params = {
          id: item.codRecebimento,
          idAcao: item.codAcao,
          objeto_acao: item,
          liquidacoes: listaSimplificada,
        };

        const query = { origem: this.origem };

        params.idFornecedor = this.idFornecedor;
        this.$router.push({ name: 'detalharRecebimento', params, query });
      });
    },
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },
    buscar() {
      this.consultar(listarRecebimentosNivel2, (response) => {
        this.lista = response.data.resposta;
        this.rowsPerPageItems = [4, 8, 12, 16, 20, 24, 28, 32, 36, 40];
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.preencherFiltrosNivel1Vuex();
        setTimeout(this.finalizandoBusca);
      });
    },
    consultar(consulta, sucesso) {
      if (this.objetoSelecionado) {
        if (this.buscando) return;
        this.buscando = true;

        const params = {
          ...this.filtrosAplicados,
          filtro: this.pesquisaCards,
          tamanhoPagina: this.pagination.itemsPerPage,
          numeroPagina: this.pagination.page,
        };

        params.id_fornecedor = this.idFornecedor;
        consulta(params, this.$resource)
          .then(sucesso)
          .catch((err) => {
            this.finalizandoBusca();
            this.$error(this, err);
          });
      }
    },
    finalizandoBusca() {
      this.buscando = false;
    },
    abrirModalConfirmacao(acao, item) {
      this.idRecebimento = item.codRecebimento;
      this.numNota = item.numNota;
      if (acao === 'cancelar') {
        this.mensagemConfirmacao = this.$t('message.deseja_cancelar_recebimento');
        this.acaoConfirmacao = null;
      } else if (acao === 'reenviar') {
        this.mensagemConfirmacao = this.$t('message.deseja_reenviar_recebimento');
        this.acaoConfirmacao = null;
      } else if (acao === 'exportar') {
        this.mensagemConfirmacao = this.$t('message.deseja_exportar');
        this.acaoConfirmacao = this.exportar;
      } else {
        this.mensagemConfirmacao = this.$t('message.deseja_editar_item');
        this.acaoConfirmacao = null;
      }
      setTimeout(() => this.$refs.modalConfirmacao.open());
    },
    exportar() {
      const { idRecebimento } = this;
      const { numNota } = this;
      const basePath = getBasePath('recebimento', 'relatorio');
      const resource = exportacao(this.$http);

      resource.downloadGet(basePath, {
        param: `?numNota=${numNota}`,
        url: `nota-debito/${idRecebimento}`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
      });
    },
    validarAcao() {
      if (this.acaoConfirmacao) {
        this.acaoConfirmacao();
      }
    },
    preencherFiltrosNivel2() {
      this.pagination.page = this.filtroLiquidacaoAcaoNivel2.pagina;
      this.pagination.itemsPerPage = this.filtroLiquidacaoAcaoNivel2.quantidatePorPagina;
    },
    preencherFiltrosRouter() {
      this.idFornecedor = this.$route.params.idFornecedor;
      this.origem = this.$route.query.origem;
    },
    preencherFiltrosNivel1Vuex() {
      const pagina = this.pagination.page;
      const quantidatePorPagina = this.pagination.itemsPerPage;

      this.setFiltroLiquidacaoAcaoNivel2({
        pagina,
        quantidatePorPagina,
      });
    },
  },
  beforeMount() {
    this.preencherFiltrosRouter();
    this.objetoSelecionado = this.objetoAcao;
  },
  mounted() {
    if (this.filtroLiquidacaoAcaoNivel2) {
      this.preencherFiltrosNivel2();
    }
    if (this.filtroLiquidacaoAcao && this.objetoSelecionado) {
      this.filtrosAplicados = this.filtroLiquidacaoAcao;
      this.filtrar();
    }
  },
};
</script>

<style>
.Container_Nivel2_Recebimento .Card_Recebimento {
  min-height: 146px !important;
  height: 100%;
}
.Container_Nivel2_Recebimento .Card_Title, .Container_Nivel2_Recebimento .Card_Content {
  color: #757575;
}
.clickable {
  cursor: pointer;
}
</style>
