import getBasePath from '../functions/api-resource';

const basePath = getBasePath('orcamento', 'transferencia');

const transferenciaActions = {
  gravar: { method: 'POST', url: `${basePath}` },
  obter: { method: 'GET', url: `${basePath}/{id}` },
  solicitante: { method: 'GET', url: `${basePath}/solicitante` },
};

export default (resource) => resource(`${basePath}`, {}, transferenciaActions);
