import RebateConfiguracao from './RebateConfiguracao';

export default class Rebate {
  constructor(rebate = {}) {
    this._id = rebate.id;
    this._idTipoInvestimento = rebate.idTipoInvestimento;
    this._idExterno = rebate.idExterno;
    this._nomTipoInvestimento = rebate.nomTipoInvestimento;

    this._rebatesConfiguracao = [];
    if (rebate.rebatesConfiguracao
      && rebate.rebatesConfiguracao.length > 0) {
      rebate.rebatesConfiguracao.forEach((i) => {
        this._rebatesConfiguracao.push(new RebateConfiguracao(i));
      });
    }
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get idTipoInvestimento() {
    return this._idTipoInvestimento;
  }

  set idTipoInvestimento(value) {
    this._idTipoInvestimento = value;
  }

  get idExterno() {
    return this._idExterno;
  }

  set idExterno(value) {
    this._idExterno = value;
  }

  get rebatesConfiguracao() {
    return this._rebatesConfiguracao;
  }

  set rebatesConfiguracao(value) {
    this._rebatesConfiguracao = value;
  }

  get nomTipoInvestimento() {
    return this._nomTipoInvestimento;
  }

  set nomTipoInvestimento(value) {
    this._nomTipoInvestimento = value;
  }

  get rebateConfigurado() {
    return this._rebatesConfiguracao.length > 0;
  }

  get idsExtensoesRebate() {
    return this._rebatesConfiguracao
      .flatMap((c) => c.idsClassificacoes)
      .filter((c, i, s) => s.indexOf(c) === i)
      .map((id) => {
        const classif = {};
        classif[id] = this._nomTipoInvestimento;
        return classif;
      });
  }

  removerItem(item) {
    for (let i = 0; i < this._rebatesConfiguracao.length; i += 1) {
      if (this._rebatesConfiguracao[i].index === item.index) {
        this._rebatesConfiguracao.splice(i, 1);
      }
    }
  }

  retornarConfiguracao(item) {
    return this._rebatesConfiguracao.find((i) => i.index === item.index);
  }

  validarConflitosVinculos(rebateConfiguracao) {
    let conflito = false;
    for (let i = 0; i < this._rebatesConfiguracao.length && !conflito; i += 1) {
      const rebatesValidacao = this._rebatesConfiguracao[i];
      if (rebatesValidacao.index !== rebateConfiguracao.index) {
        conflito = rebatesValidacao.validarConflitoVinculo(rebateConfiguracao);
      }
    }
    return conflito;
  }

  toJSON() {
    return {
      id: this._id,
      idExterno: this._idExterno,
      idTipoInvestimento: this._idTipoInvestimento,
      nomTipoInvestimento: this.nomTipoInvestimento,
      rebatesConfiguracao: this._rebatesConfiguracao,
    };
  }
}
