var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$tc('label.cliente', 1)))]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},on:{"input":_vm.filtrar},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.clientes,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
      itemsPerPageOptions: [10, 25, 50],
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.id_externo",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.idExterno)+" ")]}},{key:"item.nom_cliente",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomCliente)+" ")]}},{key:"item.des_cnpj",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.documento)+" ")]}},{key:"item.acoes",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(_vm.canAccessPage)?_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.verCliente(item)}}},on),[_c('v-icon',[_vm._v("people_outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.visualizar_cliente')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(_vm.canAccessCRUD)?_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.editCliente(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.editar_cliente')))])])]}}])}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent"},nativeOn:{"click":function($event){return _vm.cancelar($event)}}},[_vm._v(_vm._s(_vm.$t('label.cancelar')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }