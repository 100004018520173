<template>
  <v-container fluid class="pa-0">
    <v-row justify="end">

      <v-tooltip left v-if="mostrarAcompanhamento">
        <template v-slot:activator="{ on }">
          <v-btn text icon
                  v-on="on"
                  @click.native="abrirAcompanhamento">
            <v-icon >assignment_turned_in</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('label.acompanhamento') }}</span>
      </v-tooltip>

      <acao-fluxo
        ref="acaoAprovar"
        icone="thumb_up"
        :texto-acao="$t('label.aprovar')"
        :status="status"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="aprovar">
      </acao-fluxo>

      <acao-fluxo
        ref="acaoEmAnalise"
        icone="announcement"
        :texto-acao="$t('label.solicitar_analise')"
        :status="status"
        :validar-justificativa="true"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="enviarAnalise">
      </acao-fluxo>

      <acao-fluxo
        ref="acaoReprovar"
        icone="thumb_down"
        :texto-acao="$t('label.reprovar')"
        :status="status"
        :validar-justificativa="true"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="reprovar">
      </acao-fluxo>

    </v-row>
    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
    />
  </v-container>
</template>

<script>
import AcaoFluxo from '@/shared-components/AcaoFluxo';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import Acompanhamento from '../../shared-components/workflow/Acompanhamento';

export default {
  name: 'AjusteVerbaBotoesFluxo',
  props: {
    idAjusteVerba: Number,
    status: String,
    exibirAcompanhamento: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    AcaoFluxo,
    Confirm,
    Acompanhamento,
  },
  data() {
    return {
      workflowAjusteVerbaResource: this.$api.workflowAjusteVerba(this.$resource),

      solicitanteFluxo: false,
      aprovadorFluxo: false,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      justificativa: '',
      statusPassos: {},
    };
  },
  computed: {
    ...generateComputed('ACAO', [
      'canEdit',
    ]),
    podeAprovar() {
      return this.status === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo;
    },
    mostrarAcompanhamento() {
      return this.exibirAcompanhamento && this.status !== 'CANCELADO';
    },
  },
  methods: {
    carregaInformacoesFluxo() {
      if (this.idAjusteVerba && this.status) {
        this.verificarUsuarioAprovador(this.idAjusteVerba, this.status);
      }
    },
    verificarUsuarioAprovador(idAjuste, status) {
      if (status === 'AGUARDANDO_APROVACAO') {
        this.workflowAjusteVerbaResource.aprovador({ idAjuste })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            }
          });
      }
    },
    reprovar(justificativa) {
      const idAjuste = this.idAjusteVerba;
      this.workflowAjusteVerbaResource.reprovar({ idAjuste },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$tc('message.ajuste_verba_reprovado', 1));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    aprovar(justificativa) {
      const idAjuste = this.idAjusteVerba;
      this.workflowAjusteVerbaResource.aprovarPasso({ idAjuste },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$tc('message.ajuste_verba_aprovado', 1));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    enviarAnalise(justificativa) {
      const idAjuste = this.idAjusteVerba;
      this.workflowAjusteVerbaResource.enviarAnalise({ idAjuste },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$tc('message.ajuste_verba_enviado_analise', 1));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirAcompanhamento() {
      const idAjuste = this.idAjusteVerba;
      this.workflowAjusteVerbaResource.statusPasso({ idAjuste }, { idAjuste })
        .then((res) => {
          if (res.data.passos
            && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
  },
  mounted() {
    this.carregaInformacoesFluxo();
  },
};
</script>
