var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FilialTabela"},[_c('v-card',{staticClass:"card-size"},[_c('v-card-title',[_c('h3',{staticClass:"headline mb-0",domProps:{"textContent":_vm._s(_vm.title)}})]),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{staticClass:"mt-1 ml-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.canEdit && _vm.selected.length > 0),expression:"canEdit && selected.length > 0"}],staticClass:"mx-0",attrs:{"icon":"","id":"tipo-investimento-botao-ativar-inativar"},on:{"click":_vm.dialogConfirmarInativacao}},on),[_c('v-icon',[_vm._v("block")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('label.ativar_inativa')))])])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headersDinamico,"items":_vm.filiais,"show-select":"","options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
        itemsPerPageOptions: [10, 25, 50],
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
      var isSelected = ref.isSelected;
      var select = ref.select;
return [_c('tr',{key:item.name,class:{ 'TableRow--disabled': !item.ativo }},[_c('td',[_c('v-simple-checkbox',{attrs:{"value":isSelected},on:{"input":function($event){return select($event)}}})],1),(!_vm.somenteLeitura)?_c('td',{staticClass:"justify-center px-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.onEditarFilial(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.editar')))])])],1):_vm._e(),_c('td',[_vm._v(_vm._s(item.nome))]),_c('td',[_vm._v(_vm._s(item.idExterno))]),_c('td',[_vm._v(_vm._s(item.regiao))]),_vm._l((Object.keys(_vm.metadadosFilial.mapaEntidades)),function(entidade,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item.getNomesSeparadosPorVirgula(entidade, 'nomExtensao'))+" ")])})],2)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('confirm',{ref:"dialogConfirmaInativacao",attrs:{"message":_vm.$t('message.deseja_ativar_inativar_registros', {quantidade: _vm.selected.length}),"persistent":true},on:{"agree":_vm.ativarInativar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }