import { render, staticRenderFns } from "./MetadadosExtensao.vue?vue&type=template&id=bbbdfe52&lang=html&"
import script from "./MetadadosExtensao.vue?vue&type=script&lang=js&"
export * from "./MetadadosExtensao.vue?vue&type=script&lang=js&"
import style0 from "./MetadadosExtensao.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAutocomplete,VChip,VIcon,VSlideXReverseTransition,VTooltip})
