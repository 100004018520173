export default {
  data() {
    return {
      controleLayout: {
        exibirCards: false,
        agrupamentos: {
          tipoContrato: true,
          holding: false,
          extensoes: false,
          fornecedor: false,
          cliente: false,
        },
      },
    };
  },
};
