<template>
  <filtro-rapido v-model="abrirFiltro"
    ref="filtrosRapidos"
    :campos-formulario="camposFormulario"
    :metadados="getAcaoMetadado"
    :filtro-padrao="filtroPadrao"
    :ordenacao-campos="ordenacaoCampos"
    :entidade-workspace="entidadeWorkspace"
    @FiltroRapido__AplicaFiltros="(p) => $emit('NotasDebitoFiltros__AplicaFiltros', p)"></filtro-rapido>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import FiltroRapido from '../../../shared-components/filtro-rapido/FiltroRapido';
import NotasDebitoListFiltrosCampos from './NotasDebitoListFiltrosCampos';

export default {
  props: {
    value: false,
  },
  mixins: [
    NotasDebitoListFiltrosCampos,
  ],
  components: {
    FiltroRapido,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      configuracaoResource: this.$api.notasDebitoContabilizacaoConfiguracao(this.$resource),

      abrirFiltro: false,
      ordenacaoCampos: null,
      entidadeWorkspace: 'notas_debito',
      filtroPadrao: {},
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
    ]),
    carregaCamposFiltros() {
      this.configuracaoResource
        .buscarCamposFiltro()
        .then((res) => {
          this.ordenacaoCampos = res.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  watch: {
    value(val) {
      this.abrirFiltro = val;
    },
    abrirFiltro(val) {
      this.$emit('input', val);
    },
  },
  mounted() {
    this.carregaCamposFiltros();
  },
  beforeMount() {
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
  },
};
</script>
<style>
  .NotasDebitoFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .NotasDebitoFiltros__Opcoes div.v-input__slot,
  .NotasDebitoFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .NotasDebitoFiltros__Opcoes div.v-messages {
    display: none;
  }
  .NotasDebitoFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
