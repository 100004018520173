export default {
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    informacoesModal: Array,
    tituloModal: String,
  },
  methods: {
    criarEditor() {
        kendo.spreadsheet.registerEditor(`modal${this.id}`, this.funcaoModal(this.informacoesModal, this.tituloModal));
    },
    funcaoModal(i, titulo) {
        var context, dlg, model;
        return {
            edit: function(options) {
                context = options;
                open();   
            },
            icon: "k-icon k-i-info"
        };

        function open() {
            create();
            dlg.open();
            dlg.center();
        }

        function create() {
            model = kendo.observable({
                value: "",
            });
            var linha = context.range._ref.row+1;
            var coluna = context.range._ref.col;
            var elemento = "<div data-visible='true' data-role='window' data-modal='true' data-resizable='false' data-title='"+titulo+"'>";
            i.forEach((item) => {
                if (item.range === String.fromCharCode(coluna+65)+""+linha) {
                    var textos = item.value;
                    if (textos) {
                        textos.forEach((t) => {
                            elemento += "<span>"+t+"</span><br/>";
                        });
                    }
                }
            });
            elemento += "</div>";
            var el = $(elemento);
            kendo.bind(el, model);
            dlg = el.getKendoWindow();
        }
    },
    modalHabilitado() {
        return this.modal;
    },
    iniciaModal() {
        this.criarEditor();
        const sheet = this.grid.activeSheet();
        this.informacoesModal.forEach((item) => {
            sheet.range(item.range).editor(`modal${this.id}`);
            sheet.range(item.range).enable(true);
        });
    },
  },
};
