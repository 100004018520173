<template>
  <div>
    <v-card v-show="possuiAlgumCampo">
      <v-card-title>
        <div class="card-subtitle">
          {{this.$tc('label.filtro_produto_utilizado', 1)}}
        </div>
        <v-spacer></v-spacer>
        <v-icon @click="toggleFiltros()"
          v-show="cardAberto">
          keyboard_arrow_up
        </v-icon>
        <v-icon @click="toggleFiltros()"
          v-show="!cardAberto">
          keyboard_arrow_down
        </v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="cardAberto">
        <v-row style="PlanejamentoAcaoFormContaCorrenteVinculos">
          <v-col cols="12" sm="4" md="3" lg="2" xl="1"
            v-for="(campo, index) in camposDeFiltro"
            :key="index">
            <v-checkbox
              v-model="campo.selecionado"
              :label="campo.nome"
              :disabled="campo.indisponivel || somenteLeitura"
              @change="selecaoVinculo"
              hide-details
              class="mt-0"/>
            <span class="accent--text"
              style="font-style:italic;display:block"
              v-for="(v,index) in valoresCampo(campo.label)" :key="index">
              {{v}}
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('metadados', [
      'getProdutoMetadado',
    ]),
    possuiAlgumCampo() {
      return this.camposDeFiltro.length > 0;
    },
  },
  props: {
    configuracao: Object,
    somenteLeitura: Boolean,
    objetoPassoGeral: Object,
  },
  data() {
    return {
      camposDeFiltro: [],
      cardAberto: true,
    };
  },
  methods: {
    toggleFiltros(cardAberto) {
      if (cardAberto) {
        this.cardAberto = cardAberto;
      } else {
        this.cardAberto = !this.cardAberto;
      }
    },
    desmarcaCampos(camposNaoSelecionados) {
      this.camposDeFiltro
        .filter((c) => camposNaoSelecionados.indexOf(c.campo) >= 0)
        .forEach((campo) => {
          campo.selecionado = false;
        });
    },
    marcaCampos(camposSelecionados) {
      this.camposDeFiltro
        .filter((c) => camposSelecionados.indexOf(c.campo) < 0)
        .forEach((campo) => {
          campo.selecionado = false;
        });
    },
    /**
     * Retorna um objeto com os filtros selecionados,
     * no seguinte formato:
     * {
     *    id_marca: 100,
     *    id_familia: 4944
     * }
     */
    valoresFiltroSelecionados() {
      const objetoValores = {};

      this.camposDeFiltro
        .filter((filtro) => filtro.selecionado)
        .forEach((filtro) => {
          const { campo, label } = filtro;
          const objetoForm = this.objetoPassoGeral[label];
          if (!objetoForm) return;
          objetoValores[campo] = Array.isArray(objetoForm)
            ? objetoForm.map((o) => o.id)
            : objetoForm.id;
        });

      return objetoValores;
    },
    filtrosAbertos() {
      return this.cardAberto;
    },
    campoEstaIndisponivel(label) {
      const objetoForm = this.objetoPassoGeral[label];
      if (!objetoForm) return true;

      return Array.isArray(objetoForm)
        ? objetoForm.length === 0
        : !objetoForm.id;
    },
    carregaDependenciasRecursivas(labelsCamposVinculo) {
      const { mapaEntidades } = this.getProdutoMetadado;
      const dependencias = Object.values(mapaEntidades);
      const entidade = 'PRODUTO';

      const campoBaseHier = dependencias
        .filter((dependencia) => dependencia.dependentes.indexOf(entidade) >= 0)
        .filter((dependencia) => dependencia.recursiva)[0];

      if (!campoBaseHier) return [];

      const dependenciasRecursivas = [campoBaseHier];
      const [labelRef] = campoBaseHier.dependencias;
      let labelReferencia = labelRef;

      while (labelReferencia != null) {
        const campoPai = mapaEntidades[labelReferencia];
        dependenciasRecursivas.push(campoPai);
        const [dependencia] = campoPai.dependencias;
        labelReferencia = dependencia;
      }

      return dependenciasRecursivas
        .filter((dependencia) => labelsCamposVinculo.indexOf(dependencia.label) >= 0)
        .map((dependencia) => this.campoExtensao(dependencia))
        .reverse();
    },
    carregaDependenciasDiretasNaoRecursivas(labelsCamposVinculo) {
      const { mapaEntidades } = this.getProdutoMetadado;
      const dependencias = Object.values(mapaEntidades);
      const entidade = 'PRODUTO';

      return dependencias
        .filter((dependencia) => dependencia.dependentes.indexOf(entidade) >= 0)
        .filter((dependencia) => !dependencia.recursiva)
        .filter((dependencia) => labelsCamposVinculo.indexOf(dependencia.label) >= 0)
        .map((dependencia) => this.campoExtensao(dependencia));
    },
    campoExtensao(dependencia) {
      const campo = {
        ...dependencia,
        nome: dependencia.entidadeEstrangeira,
        indisponivel: this.campoEstaIndisponivel(dependencia.label),
      };
      if (!campo.indisponivel) {
        campo.selecionado = true;
      }
      return campo;
    },
    campoProduto() {
      const campo = {
        nome: this.$tc('label.produto', 1),
        campo: 'id_produto',
        label: 'produto',
        indisponivel: this.campoEstaIndisponivel('produto'),
      };
      if (!campo.indisponivel) {
        campo.selecionado = true;
      }
      return campo;
    },
    /**
     * Monta um array de filtros - conforme parametrizacao -
     * seguindo a seguinte ordem:
     *
     *  - dependencias diretas nao recursivas
     *  - dependencias recursivas (do pai ao filho)
     *  - produto
     */
    carregaLabelsFiltrosProduto(idTipoVerba) {
      this.camposDeFiltro = [];

      const { vinculosTiposVerba } = this.configuracao;
      const labels = vinculosTiposVerba[idTipoVerba] || [];
      if (labels.length === 0) return;

      this.camposDeFiltro.push(
        ...this.carregaDependenciasDiretasNaoRecursivas(labels),
        ...this.carregaDependenciasRecursivas(labels),
      );

      if (labels.indexOf('produto') >= 0) {
        this.camposDeFiltro.push(this.campoProduto());
      }
    },
    valoresCampo(label) {
      const objetoForm = this.objetoPassoGeral[label];
      if (!objetoForm) return [];
      return Array.isArray(objetoForm)
        ? objetoForm.map((o) => this.formataValor(o))
        : [this.formataValor(objetoForm)];
    },
    formataValor(o) {
      let str = o.nomExtensao || o.nomProduto;
      str = str.toLowerCase();
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    selecaoVinculo() {
      this.$emit('PLANEJAMENTO_ACAO_VINCULOS_TOGGLE');
    },
  },
};
</script>
