import AcaoPrevia from '../../spa/acao-previa/AcaoPrevia';
import AcaoPreviaList from '../../spa/acao-previa/AcaoPreviaList';
import AcaoPreviaForm from '../../spa/acao-previa/AcaoPreviaForm';
import AcaoPreviaEdicaoForm from '../../spa/acao-previa/AcaoPreviaEdicaoForm';

export default {
  path: 'acao-previa',
  component: AcaoPrevia,
  children: [
    {
      path: '',
      name: 'acao-previa',
      component: AcaoPreviaList,
    },
    {
      path: 'nova',
      name: 'novaAcaoPrevia',
      component: AcaoPreviaForm,
      props: {
        default: false,
        somenteLeitura: false,
        copia: false,
        edicao: false,
        novo: true,
      },
    },
    {
      path: ':idAcao/editar',
      name: 'editarAcaoPrevia',
      component: AcaoPreviaEdicaoForm,
    },
    {
      path: ':idAcao/editar_',
      name: 'editarAcaoPrevia_',
      component: AcaoPreviaForm,
      props: {
        somenteLeitura: false,
        edicao: true,
        novo: false,
      },
    },
    {
      path: ':idAcao/detalhar',
      name: 'detalharAcaoPrevia',
      component: AcaoPreviaForm,
      props: {
        default: false,
        somenteLeitura: true,
        copia: false,
        edicao: false,
        novo: false,
      },
    },
  ],
};
