import planejamentoDefinicaoPassos from '@/produto/spa/planejamento-acao/form/planejamentoDefinicaoPassos';
import PlanejamentoAcaoFormPrevia from './PlanejamentoAcaoFormPrevia';
import PlanejamentoAcaoFormRateio from './PlanejamentoAcaoFormRateio';

const habilitaAcaoPrevia = (configuracao, acao) => !!((acao && acao.passoGeral
    && (acao.passoGeral.status === 'APROVADO' || acao.passoGeral.status === 'CANCELADO')
    && acao.idAcaoTradePrevia)
    && (configuracao.passo6
      && configuracao.passo6.liquidacao
      && configuracao.passo6.liquidacao.templateCalculo
      && configuracao.passo6.liquidacao.templateCalculo === 'MANUAL_POR_FORNECEDOR'));

const habilitaRateioProporcional = (configuracao) => (
  configuracao.passo6
                && configuracao.passo6.liquidacao
                && configuracao.passo6.liquidacao.templateCalculo
                && configuracao.passo6.liquidacao.templateCalculo === 'PROPORCIONAL_POR_CLIENTE_ADAMA');

export default {
  componentes: {
    ...planejamentoDefinicaoPassos.componentes,
    PlanejamentoAcaoFormPrevia,
    PlanejamentoAcaoFormRateio,
  },
  passosHabilitados(ref, configuracao, acao) { // eslint-disable-line
    return {
      ...planejamentoDefinicaoPassos.passosHabilitados(ref, configuracao, acao),
      acaoPrevia: {
        habilitado: habilitaAcaoPrevia(configuracao, acao),
        label: ref.$tc('label.pagamento', 1),
        componente: 'planejamento-acao-form-previa',
      },
      rateioPagamento: {
        habilitado: habilitaRateioProporcional(configuracao),
        label: ref.$tc('label.pagamento', 1),
        componente: 'planejamento-acao-form-rateio',
      },
    };
  },
};
