import getBasePath from '../../../produto/common/functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'parametrizacao-periodo');

const grupoActions = {
  obterPasso6: { method: 'GET', url: `${basePath}/passo6/{id}` },
  salvarRascunhoPasso6: { method: 'PUT', url: `${basePath}/passo6/{id}/rascunho` },
  salvarPasso6: { method: 'PUT', url: `${basePath}/passo6/{id}` },
  buscarExtensoes: { method: 'GET', url: `${basePath}/passo6/extensao` },
  buscarClassificacoes: { method: 'GET', url: `${basePath}/passo6/{id}/classificacao` },
  atualizarMarcasFortes: { method: 'PUT', url: `${basePath}/passo6/{idPeriodo}/{idMarca}/marca_pendente_integracao` },
};

export default (resource) => resource(`${basePath}`, {}, grupoActions);
