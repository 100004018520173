<template>
  <v-row class="mt-6">
    <v-col cols="12" md="3" v-for="(rebate, index) in rebates" :key="`card-rebate-${index}`">
      <v-card
        class="mx-auto"
        max-width="344"
        :color="rebate.rebateConfigurado ? 'primary' : null"
        :dark="rebate.rebateConfigurado"
        outlined>
        <v-card-title class="headline">{{ $t(rebate.nomTipoInvestimento) }}</v-card-title>
        <v-card-actions>
          <v-btn text icon @click="abrirCadastro(rebate)">
            <v-icon>settings_applications</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <rebate-configuracoes
      ref="rebateCadastro"
      :id-periodo="idPeriodo"
      :somente-leitura="somenteLeitura"
      :alterar-finalizado="alterarFinalizado"
      :alterar-percentuais="alterarPercentuais"
      :rebate="rebateTemp"
      @RebateConfiguracoes_configAlteradas="$emit('RebateCards_configAlteradas')">
    </rebate-configuracoes>
  </v-row>
</template>

<script>
import Confirm from '../../../../produto/shared-components/vuetify/dialog/Confirm';
import { convertToNumberFormat } from '../../../../produto/common/functions/numeric';
import Rebate from './Rebate';
import RebateConfiguracoes from './RebateConfiguracoes';

export default {
  name: 'RebatesCards',
  components: {
    RebateConfiguracoes,
    Confirm,
  },
  props: {
    rebates: {
      type: Array,
      default: () => [],
    },
    idPeriodo: {
      type: Number,
      default: null,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
    alterarPercentuais: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rebateTemp: new Rebate(),
    };
  },
  methods: {
    convertToNumberFormat,
    abrirCadastro(rebate) {
      this.rebateTemp = rebate;
      this.$refs.rebateCadastro.open();
    },
    abrirRemoverRebate(rebate) {
      this.$emit('RebatesCards_remover', rebate);
    },
  },
};
</script>
