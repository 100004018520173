<template lang="html">
  <div>
    <v-card-title primary-title class="card-header mt-4">
      {{ $tc('title.formulario', 1) }}
      <v-spacer />
      <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.ordenacao')}`" />
    </v-card-title>
    <v-row>
      <v-col cols="12" >
        <draggable :list="parametrizacao.lista" id="config_contrato_formulario_lista">
          <transition-group>
            <div class="list-group-item" v-for="(element, indice) in parametrizacao.lista" :key="`lista-${indice}`"  style="cursor: grab">{{element.nome}}</div>
          </transition-group>
        </draggable>
      </v-col>
    </v-row>

    <v-row justify="end" class="my-4">
      <v-btn id="config_contrato_passo5_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
      <v-btn id="config_contrato_passo5_botao_voltar" color="primary" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
      <v-btn id="config_contrato_passo5_botao_proximo" color="primary" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import draggable from 'vuedraggable';
import Passo5 from './Passo5';
import TooltipAjuda from '../TooltipAjuda';

export default {
  name: 'ParametrizacaoContratoPasso5',
  data() {
    return {
      paramContratoResources: this.$api.parametrizacaoContrato(this.$resource),

      parametrizacao: new Passo5(),
      lista: [],

      naoOrdenaveis: [
        'descricao',
        'holding',
        'unidadenegocio',
        'periodo_planejamento',
        'periodo_apuracao',
        'tipo_pagamento',
        'base_calculo',
        'valor_consumido',
        'meta_beneficio',
        'forma_bonificacao',
        'investimento_vendas',
      ],
    };
  },
  props: {
    id: Number,
    passo: Number,
  },
  components: {
    draggable,
    TooltipAjuda,
  },
  methods: {
    cancelar() {
      this.$emit('PARAMETRIZACAO_CONTRATO_CANCELAR');
    },
    obterValoresDoBanco(objetoBanco, lista) {
      objetoBanco.forEach((itemBanco) => {
        lista.forEach((item) => {
          if (item.label === itemBanco.label) {
            item.ordenacao = itemBanco.ordenacao || item.ordenacao;
          }
        });
      });
      return lista;
    },
    prepararListas() {
      this.parametrizacao.lista.forEach((item, index) => {
        item.ordenacao = index + 1;
      });
    },
    prepararPasso() {
      if (this.id) {
        this.parametrizacao.id = this.id;
        const params = {
          id: this.id,
        };

        this.paramContratoResources.obterPasso5(params)
          .then((response) => {
            this.lista = [...response.data.lista];
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    proximoPasso() {
      this.prepararListas();
      this.$emit('PARAMETRIZACAO_CONTRATO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
      this.$emit('PARAMETRIZACAO_CONTRATO_CONTINUAR', this.passo + 1, this.id);
    },
    ordenarListas(lista) {
      return lista.sort((a, b) => a.ordenacao - b.ordenacao);
    },
    setLista(lista) {
      if (!lista) return;

      this.parametrizacao.lista = this.removeItensNaoOrdenaveis([...lista]);
      this.parametrizacao.lista = this.obterValoresDoBanco(this.lista, this.parametrizacao.lista);

      this.ordenarListas(this.parametrizacao.lista);
      this.$forceUpdate();
    },
    removeItensNaoOrdenaveis(lista) {
      return lista.filter((item) => this.naoOrdenaveis.indexOf(item.label) < 0);
    },
    voltarPasso() {
      this.prepararListas();
      this.$emit('PARAMETRIZACAO_CONTRATO_VOLTAR', this.passo - 1);
    },
  },
  mounted() {
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  @import '~@/config/theme/theme.scss';
  .list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  .card-header {
    background-color: $primary-color;
    color: #ffffff;
  }
</style>
