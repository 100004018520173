<template lang="html">
  <div>
    <v-label>{{ label }}</v-label>
    <v-container fluid grid-list-md>
      <v-row align="center">
        <div class="pa-1" v-for="(item, index) in items"
             :key="index">
          <v-card :color="value === item[itemValue] ? 'primary' : 'primary lighten-2'"
                  class="BtnGroup__Card"
                  @click="() => selectedItem = item[itemValue]"
                  :disabled="disabled"
                  :rules="rules">
            <v-card-title>
                        <span class="white--text">
                          {{ item[itemText] }}
                        </span>
            </v-card-title>
          </v-card>
        </div>
      </v-row>
    </v-container>
    <div class="BtnGroup__Msg">
      <v-autocomplete
        id="aux_btn_group"
        class="custom-autocomplete"
        v-model="value"
        item-text="nome"
        item-value="valor"
        :rules="rules"
        :disabled="disabled">
      </v-autocomplete>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: String,
      default: null,
    },
    itemValue: {
      type: String,
      default: null,
    },
    itemText: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    hasErrors() {
      if (!this.required) {
        return false;
      }

      return this.value == null;
    },
    validaCampo() {
      if (this.hasErrors) {
        return this.$t('message.campo_obrigatorio');
      }
      return null;
    },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  watch: {
    selectedItem(val) {
      if (val) {
        this.$emit('BtnGroup_Selecionar', val);
      }
    },
  },
};
</script>

<style src="./BtnGroup.css" lang="css"></style>
