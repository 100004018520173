<template lang="html">
  <div
    tabindex="100"
    @keydown.up.prevent="onArrowUpPress"
    @keydown.down.prevent="onArrowDownPress">

    <v-card class="card-size">
      <v-card-title>
        <h2 class="headline mb-0"
            v-text="title">
        </h2>
      </v-card-title>

      <v-row justify="start" align="center">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            id="usuario-tabela-input-search"
            v-model="searchQuery"
            @input="filtrar"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
        <div class="mt-1 ml-2" v-if="canAccessCRUD">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon
                id="usuario-tabela-button-config"
                class="mx-0"
                v-on="on"
                @click="abrirConfiguracao">
                <v-icon>settings</v-icon>
              </v-btn>
            </template>
            <span>{{ $tc('label.configuracao', 1) }}</span>
          </v-tooltip>
        </div>
        <div class="mt-1 ml-2">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon
                     id="usuario-tabela-download"
                     class="mx-0"
                     v-on="on"
                     @click="realizarDownload">
                <v-icon>file_download</v-icon>
              </v-btn>
            </template>
            <span>{{ $tc('label.download', 1) }}</span>
          </v-tooltip>
        </div>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="registros"
        :options.sync="pagination"
        :item-class="onItemClass"
        :server-items-length="totalPage"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:item.acoes="{ item }">
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn icon
                v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="canAccessCRUD || isUsuarioExternoMaster"
                @click="edit(item)">
                <v-list-item-action>
                  <v-icon>edit</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.editar') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!canAccessCRUD"
                @click="ver(item)">
                <v-list-item-action>
                  <v-icon>dehaze</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.visualizar') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="canAccessCRUD || isUsuarioExternoMaster"
                @click="redefinirSenha(item)">
                <v-list-item-action>
                  <v-icon>lock</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.redefinir_senha') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.nome_sobrenome="{ item }">
          {{ item.nomeSobrenome }}
        </template>
        <template v-slot:item.nom_usuario="{ item }">
          {{ item.nomUsuario }}
        </template>
        <template v-slot:item.nom_perfil="{ item }">
          {{ item.nomPerfil }}
        </template>
        <template v-slot:item.nom_estrutura_usuario="{ item }" v-if="isUsuarioFuncionario">
          {{ item.nomEstruturaUsuario }}
        </template>
        <template v-slot:item.divisoes="{ item }" v-if="isUsuarioFuncionario">
          {{ getNomesDivisoesSeparadosPorVirgula(item.divisoes) }}
        </template>
        <template v-if="implantacao.indVarejo" v-slot:item.grupofornecedor="{ item }">
          {{ montaTexto(item.grupofornecedor) }}
        </template>
        <template v-slot:item.dta_ultimo_acesso="{ item }">
          {{ item.dtaUltimoAcesso }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script type="text/javascript">
import {
  mapGetters,
} from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import exportacao from '../../common/resources/exportacoes';

export default {
  data() {
    return {
      usuarioResources: this.$api.usuario(this.$resource),
      headers: [
        {
          text: this.$tc('label.acoes', 2), sortable: false, align: 'center', value: 'acoes',
        },
        { text: this.$t('label.nome'), sortable: true, value: 'nome' },
        { text: this.$t('label.email'), sortable: true, value: 'email' },
        { text: this.$t('label.login'), sortable: true, value: 'nom_usuario' },
        { text: this.$tc('label.nome_perfil', 1), value: 'nom_perfil' },
      ],
      pagination: {},
      totalPage: 0,
      esperar: false,
      searchQuery: '',
      registros: [],
    };
  },
  props: {
    title: String,
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar(true);
      },
      deep: true,
    },
    searchQuery() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar(false);
      }, 500);
    },
    paginationSize(valor) {
      this.$emit('PAGINACAO_ALTERANDO_TAMANHO_PAGINA', valor);
    },
  },
  computed: {
    ...mapGetters('implantacao', [
      'implantacao',
      'indModoVarejo',
    ]),
    ...generateComputed('Usr', [
      'canAccessCRUD',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    isContentAvailable() {
      return !!this.tableData && !!this.tableData.length;
    },
    usuarioFilteredList() {
      return this.tableData.map((linha) => {
        const row = { ...linha };
        row.representante = {};
        row.representante.dependencias = this.stringToArray(linha);
        row.superioresImediatosArray = this.parseStringSuperioresImediatos(linha);
        row.canaisArray = this.parseStringCanal(linha);

        return row;
      });
    },
    filteredList() {
      return [];
    },
    isUsuarioExternoMaster() {
      return this.usuarioLogado.indUsuarioExternoMaster;
    },
    isUsuarioFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
  },
  methods: {
    onItemClass(...args) {
      const [usuario] = args;
      return !usuario.indAtivo ? 'TableRow--disabled' : '';
    },
    buscar(trocaPagina) {
      if (this.searchQuery && !trocaPagina) {
        this.pagination.page = 1;
      }
      const params = {
        filtro: this.searchQuery,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      if (!this.isUsuarioFuncionario) {
        params.indUsuarioExterno = true;
      }

      if (params.colunaOrdenacao === 'nome') {
        params.colunaOrdenacao = 'u.nome';
      }

      this.usuarioResources.listar(params).then((response) => {
        this.registros = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    filtrar(trocaPagina) {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar(trocaPagina);
      }, 5E2);
    },
    montaTexto(item) {
      if (!item) {
        return '';
      }
      const array = item.split('|');
      return (`${array[2]}`) || '';
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    abrirConfiguracao() {
      this.$emit('USUARIOTABELA_CONFIGURACAO');
    },
    filterDatasource() {
      this.showLoading = true;
      this.paginationPage = 1;

      const pagination = {
        size: this.paginationSize,
        page: this.paginationPage,
        filter: this.searchQuery,
      };

      this.$emit('TABELA_FILTRAR_PAGINA', pagination, this.closeLoading);
    },
    edit(row) {
      this.$emit('USUARIOTABELA_EDIT', row);
    },
    ver(row) {
      this.$emit('USUARIOTABELA_VER', row);
    },
    redefinirSenha(row) {
      this.$emit('USUARIOTABELA_RESET_SENHA', row);
    },
    getNomesDivisoesSeparadosPorVirgula(nomeObjeto) {
      if (!nomeObjeto) {
        return '';
      }
      return nomeObjeto.split(';;').join(', ');
    },
    stringToArray(linha) {
      const canaisVendaRepresentanteObj = !linha.canaisVendaRepresentante ? []
        : linha.canaisVendaRepresentante.split(',').map((cat) => {
          const canaisVendaRepresentanteStr = cat.split(':');
          return {
            id: parseInt(canaisVendaRepresentanteStr[0], 10),
            nomExtensao: canaisVendaRepresentanteStr[1],
            indAtivo: canaisVendaRepresentanteStr[2],
          };
        });

      return {
        canaisVenda: canaisVendaRepresentanteObj,
      };
    },
    realizarDownload() {
      const parametros = {
        limit: 500000,
        arquivoCsv: true,
        filtro: this.searchQuery,
        ind_ativo: true,
      };

      const resource = exportacao(this.$http);

      resource.download('relatorio_usuario',
        { limit: 5000, parametrosConsulta: parametros }, 'relatorio')
        .then(() => this.$toast(this.$t('message.exportacao_download')))
        .catch(() => this.$toast('Erro ao exportar. Tente novamente.'));
    },
  },
  mounted() {
    this.filtrar(false);
    if (this.isUsuarioFuncionario) {
      this.headers.push({ text: this.$tc('label.estrutura_usuario', 1), value: 'nom_estrutura_usuario' },
        { text: this.$tc('label.divisao', 1), value: 'divisoes' });
    }
    if (this.implantacao.indVarejo) {
      this.headers.push({ text: this.$tc('label.grupo_fornecedor', 1), value: 'grupofornecedor', sortable: false });
    }
    this.headers.push({ text: this.$tc('label.tipo', 1), sortable: true, value: 'tipo' });
    this.headers.push({ text: this.$tc('label.dta_ultimo_acesso', 1), value: 'dta_ultimo_acesso' });
  },
};
</script>
