import getBasePath from '../functions/api-resource';

const basePath = getBasePath('planejamento', 'dashboard-orcamento');

const orcamentoActions = {
  anos: { method: 'GET', url: `${basePath}/anos` },
  fluxos: { method: 'GET', url: `${basePath}/fluxos` },
  buscarPlanejado: { method: 'GET', url: `${basePath}/planejado` },
  linhasInvestimento: { method: 'GET', url: `${basePath}/linhas-investimento/{codigo}` },
};

export default (resource) => resource(`${basePath}`, {}, orcamentoActions);
