import { toColumnName } from '../../common/functions/helpers';

export default {
  data() {
    return {
      // relacao de ids das extensoes selecionadas na planilha
      extensoesLinha: [],
    };
  },
  methods: {
    identificadorLinha(linha) {
      return linha.uid;
    },
    trataAlteracao(alteracao) {
      const { field, items } = alteracao;
      if (!this.ehColunaExtensao(field)) {
        return;
      }

      const label = field;
      const linha = items[0],
        codNomExtensao = linha[label],
        identificador = this.identificadorLinha(linha);

      const indiceLinha = this.getIndiceLinha(identificador);
      this.preencheSelecao(label, codNomExtensao, indiceLinha);
      this.limpaDependentes(label, indiceLinha);
    },
    onAutocomplete(selecao) {
      const { item, celula } = selecao;
      if (!item || !item.idNivelExtensao) return;

      const indiceLinha = parseInt(celula.replace(/^\D+/g, ''), 10);

      const { label } = item;
      this.limpaDependentes(label, indiceLinha);
    },
    preencheSelecao(label, codNomExtensao, indice) {
      let linha = this.extensoesLinha[indice];
      if (!linha) {
        linha = {};
        this.extensoesLinha[indice] = linha;
      }
      linha[label] = codNomExtensao;
    },
    limpaDependentes(dependencia, indiceLinha) {
      const dependentes = this.getDependentes(dependencia);
      dependentes
        .map((dependente) => dependente)
        .filter((label) => this.colunaExistente(label))
        .forEach((label) => {
          const indiceCol = this.getIndiceColPorLabel(label);
          const celula = `${toColumnName(indiceCol)}${indiceLinha}`;

          $logger.log(`${label} clean`, celula);

          const range = `${celula}:${celula}`;
          this.$refs.spreadsheet.preencheCelula(range, null);
        });
    },
    colunaExistente(label) {
      return this.colunas.filter((c) => c.field === label).length > 0;
    },
    getIndiceColPorLabel(label) {
      let i = 0;
      for (; i < this.colunas.length; i += 1) {
        const coluna = this.colunas[i];
        if (coluna.field === label
          || coluna.field === this.controleColunas.label[label]) {
          break;
        }
      }
      return i;
    },
    getIndiceLinha(identificador) {
      const datasource = this.$refs.spreadsheet.getDataSource();
      return datasource.indexOf(datasource.find((c) => c.uid === identificador)) + 2;
    },
    getDependentes(dependencia) {
      const { mapaEntidades } = this.metadadosParametrizado;
      const dependencias = Object.values(mapaEntidades);

      const listaDependentes = [...mapaEntidades[dependencia].dependentes];

      let referencia = listaDependentes[0];
      while (referencia !== null) {
        const dependente = dependencias
          .filter((dependenciaRec) => { // eslint-disable-line no-loop-func
            const { label } = dependenciaRec;
            return referencia === label;
          })[0];

        if (dependente) {
          listaDependentes.push(dependente.label);
          const [ref] = dependente.dependentes;
          referencia = ref;
        } else {
          referencia = null;
        }
      }

      return listaDependentes;
    },
  },
};
