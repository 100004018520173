import AjusteVerba from '../../spa/ajuste-verba/AjusteVerba';

const AjusteVerbaList = () => import('../../spa/ajuste-verba/AjusteVerbaList');
const AjusteVerbaAdendoReducao = () => import('../../spa/ajuste-verba/operacoes/AjusteVerbaAdendoReducao');
const AjusteVerbaTransferencia = () => import('../../spa/ajuste-verba/operacoes/AjusteVerbaTransferencia');
const AjusteVerbaReducaoMassivaForm = () => import('../../spa/ajuste-verba/operacoes/AjusteVerbaReducaoMassivaForm');

export default {
  path: 'ajuste-verba',
  component: AjusteVerba,
  children: [
    {
      path: '',
      name: 'ajuste-verba',
      component: AjusteVerbaList,
    },
    {
      path: 'ajuste/:id/detalhar',
      name: 'verAjusteVerba',
      component: AjusteVerbaAdendoReducao,
      props: {
        somenteLeitura: true,
        novo: false,
      },
    },
    {
      path: 'transferencia/:id/detalhar',
      name: 'verAjusteVerbaTransferencia',
      component: AjusteVerbaTransferencia,
      props: {
        somenteLeitura: true,
        novo: false,
      },
    },
    {
      path: 'reducao-massiva/:id/detalhar',
      name: 'verReducaoMassiva',
      component: AjusteVerbaReducaoMassivaForm,
      props: {
        somenteLeitura: true,
        edicao: false,
        novo: false,
      },
    },
    {
      path: 'ajuste/:id/editar',
      name: 'editarAjusteVerba',
      component: AjusteVerbaAdendoReducao,
      props: {
        somenteLeitura: false,
        novo: false,
      },
    },
    {
      path: 'transferencia/:id/editar',
      name: 'editarAjusteVerbaTransferencia',
      component: AjusteVerbaTransferencia,
      props: {
        somenteLeitura: false,
        novo: false,
      },
    },
    {
      path: 'reducao-massiva/:id/editar',
      name: 'editarReducaoMassiva',
      component: AjusteVerbaReducaoMassivaForm,
      props: {
        somenteLeitura: false,
        edicao: true,
        novo: false,
      },
    },
    {
      path: 'ajuste/novo',
      name: 'novoAdendoReducao',
      component: AjusteVerbaAdendoReducao,
      props: {
        somenteLeitura: false,
        novo: true,
      },
    },
    {
      path: 'transferencia/novo',
      name: 'novaTransferencia',
      component: AjusteVerbaTransferencia,
      props: {
        somenteLeitura: false,
        novo: true,
      },
    },
    {
      path: 'reducao-massiva/novo',
      name: 'novaReducaoMassiva',
      component: AjusteVerbaReducaoMassivaForm,
      props: {
        somenteLeitura: false,
        edicao: false,
        novo: true,
      },
    },
  ],
};
