<template>
  <div>
    <v-container grid-list-xl fluid>
      <v-row justify-end>
        <v-col cols="12" sm="6" md="5">
          <h3 class="headline mb-0">{{ $t('title.cadastrar_pre_usuario') }}</h3>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="6" md="4">
          <v-switch
            v-model="indUsuarioJaCadastrado"
            color="primary"
            class="my-auto"
            @change="executarBusca"
            :label="$t('label.exibir_usuario_ja_cadastrado')"
            single-line
            hide-details>
          </v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="search"
            class="my-auto pt-0"
            @input="executarBusca"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="usuarios"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item.nom_estrutura_usuario="{ item }"> {{ item.nomEstruturaUsuario }}</template>
      <template v-slot:item.nom_perfil="{ item }"> {{ item.nomPerfil }}</template>
      <template v-slot:item.ind_usuario_ja_cadastrado="{ item }"> {{ item.indUsuarioJaCadastrado ? $t('label.sim') : $t('label.nao') }}</template>
      <template v-slot:item.acao="{ item }">
        <v-tooltip bottom v-if="!item.indUsuarioJaCadastrado">
          <template v-slot:activator="{ on }">
            <v-btn icon class="mx-0" v-on="on" @click="excluirItem(item)">
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.excluir') }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!item.indUsuarioJaCadastrado">
          <template v-slot:activator="{ on }">
            <v-btn icon class="mx-0" v-on="on" @click="editItem(item)">
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.editar') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <confirm
      ref="cancelDialog"
      :message="$t('message.deseja_excluir', {text: $t('label.cadastrar_pre_usuario')})"
      :persistent="true"
      @agree="excluirUsuario">
    </confirm>
  </div>
</template>

<script>
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'CalendarioTabela',
  components: {
    Confirm,
  },
  data() {
    return {
      search: '',
      resources: this.$api.usuarioPreCadastro(this.$resource),
      totalPage: 0,
      indUsuarioJaCadastrado: false,
      esperar: false,
      usuario: {},
      pagination: {},
      headers: [
        { text: this.$tc('label.email', 1), value: 'email', sortable: true },
        { text: this.$tc('label.matricula', 1), value: 'matricula', sortable: true },
        { text: this.$tc('label.estrutura_usuario', 1), value: 'nom_estrutura_usuario', sortable: true },
        { text: this.$tc('label.perfil', 1), value: 'nom_perfil', sortable: true },
        { text: this.$tc('label.divisao', 2), value: 'divisoes', sortable: true },
        { text: this.$t('label.usuario_ja_cadastrado'), value: 'ind_usuario_ja_cadastrado', sortable: true },
        { text: '', value: 'acao', sortable: false },
      ],
      usuarios: [],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  methods: {
    executarBusca() {
      this.pagination.page = 1;
      this.filtrar();
    },
    excluirUsuario() {
      const params = {
        id: this.usuario.id,
      };
      this.resources.excluir({ id: this.usuario.id }, params).then(() => {
        this.$toast(this.$t('message.atualizado_confira_tabela'));
        this.filtrar();
      }, (err) => {
        this.$error(this, err);
      });
    },
    excluirItem(usuario) {
      this.usuario = usuario;
      this.$refs.cancelDialog.open();
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },
    editItem(item) {
      this.$emit('edit', item.id);
    },
    buscar() {
      const params = {
        filtro: this.search,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
        indUsuarioJaCadastrado: this.indUsuarioJaCadastrado,
      };

      this.resources.listar(params).then((response) => {
        this.usuarios = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
