import AjusteProvisao from '../../spa/relatorios/provisao/AjusteProvisao';
import AjusteProvisaoList from '../../spa/relatorios/provisao/AjusteProvisaoList';

export default {
  path: 'ajuste-provisao',
  component: AjusteProvisao,
  children: [
    {
      path: '',
      name: 'ajuste-provisao',
      component: AjusteProvisaoList,
    },
  ],
};
