import getBasePath from '../../produto/common/functions/api-resource';
import resourceBuilder from '../../produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', 'acao_previa');

const acaoPreviaActions = {
  buscarAcaoPrevia: { methods: 'GET', url: `${basePath}/{idAcao}` },
  verificarSeUsuarioPossuiAcesso: { methods: 'GET', url: `${basePath}/acesso/{idAcao}` },
  salvarAcao: { method: 'POST', url: `${basePath}` },
  atualizarAcao: { method: 'PUT', url: `${basePath}` },
  executarAnalise: { method: 'PUT', url: `${basePath}/{idAcao}/executar_analise` },
  cancelarAcao: { method: 'PUT', url: `${basePath}/{idAcao}/cancelar` },
  buscarTiposVerba: { method: 'GET', url: `${basePath}/tipo_verba` },
  buscarOrcamentos: { method: 'GET', url: `${basePath}/orcamento` },
  buscarPeriodosOrcamentarios: { method: 'GET', url: `${basePath}/periodo_orcamentario` },
  buscarLinhasInvestimento: { method: 'GET', url: `${basePath}/linha_investimento` },
  buscarFornecedores: { method: 'GET', url: `${basePath}/fornecedor` },
  buscarConfigVigente: { methods: 'GET', url: `${basePath}/configuracao/vigente` },
  buscarConfigSnapshot: { methods: 'GET', url: `${basePath}/configuracao/snapshot/{idAcao}` },
};

export default (resource) => resource(`${basePath}`, {}, acaoPreviaActions);

export const buscarAcoesPrevias = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'acoes', parametros).doGet();
