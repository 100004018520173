<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title>
        <h2 style="font-size: 18px; width: 100%" class="mb-0">{{ alcada.ehEdicao() ? $t('title.editar_alcada') : $t('title.cadastrar_nova_alcada') }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              id="alcada_fluxo"
              name="alcada_fluxo"
              v-model="alcada.fluxo"
              :items="listaFluxos"
              :search-input.sync="tipoFluxo"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nome"
              item-value="id"
              :rules="[rules.required]"
              :label="`${$tc('label.fluxo', 1)} *`"
              clearable
              return-object>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              id="alcada_descricao"
              name="alcada_descricao"
              v-model="alcada.descricao"
              class="Form-text__invalid"
              :label="`${$tc('label.descricao', 1)} *`"
              :counter="50"
              maxlength="50"
              :rules="[rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-switch
              color="primary"
              :label="$t('label.sem_valor_final')"
              v-model="alcada.indSemValorFim"
              :disabled="false"
              class="mt-3">
            </v-switch>
          </v-col>
          <v-col cols="12" sm="6">
            <input-money
                  :label="`${$tc('label.valor_inicial', 1)}`"
                  v-model="alcada.vlrLimiteInicial"
                  :rules="[rules.required]"
                  :disabled="false"/>
          </v-col>
          <v-col cols="12" sm="6" md="6" v-if="!alcada.indSemValorFim">
            <input-money
                  :label="`${$tc('label.valor_final', 1)}`"
                  v-model="alcada.vlrLimiteFinal"
                  :rules="[rules.required, rules.valorFinalMaiorInicial]"
                  :disabled="false"/>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              id="alcada_estrutura_usuario"
              name="alcada_estrutura_usuario"
              v-model="alcada.estruturaUsuario"
              :items="listaEstruturaUsuarios"
              :search-input.sync="estruturaDeUsuario"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomEstruturaUsuario"
              item-value="id"
              :rules="[rules.required]"
              :label="`${$tc('label.estrutura_usuario', 1)} *`"
              clearable
              return-object>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              id="alcada_workflow"
              name="alcada_workflow"
              v-model="alcada.idsWorkflows"
              :items="listaWorkflows"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="descricao"
              item-value="id"
              :label="`${$tc('label.workflow')}`"
              clearable
              multiple>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cancel">{{ $t('label.cancelar') }}</v-btn>
        <v-btn @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="save()">
    </confirm>
  </v-form>
</template>

<script>
import InputMoney from '../../shared-components/inputs/InputMoney';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import Alcada from './Alcada';

export default {
  name: 'AlcadaForm',
  data() {
    return {
      alcada: new Alcada(),
      alcadaResources: this.$api.alcada(this.$resource),
      estruturaUsuarioResources: this.$api.estruturaUsuario(this.$resource),
      listaFluxos: [],
      listaWorkflows: [],
      listaEstruturaUsuarios: [],
      tipoFluxo: null,
      estruturaDeUsuario: null,
      inputLayout: {
        xs12: true,
        sm12: true,
        md12: true,
        sm6: false,
        md3: false,
        md4: false,
        md6: false,
        md9: false,
      },
      layoutClass: { wrap: true },
      dialog: {
        content: '',
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorFinalMaiorInicial: () => {
          const valorFinal = this.alcada.vlrLimiteFinal;
          return (valorFinal > this.alcada.vlrLimiteInicial) || this.$t('errors.valor_inicial_mayor_final');
        },
      },
    };
  },
  watch: {
    tipoFluxo(filtro) {
      this.buscarTiposFluxo(filtro);
      this.buscarWorkflow(filtro);
    },
    estruturaDeUsuario(filtro) {
      return this.buscarEstruturasDeUsuario(filtro);
    },
  },
  components: {
    Confirm,
    InputMoney,
  },
  methods: {
    cancel() {
      this.limparFormulario();
      this.$emit('FORMULARIO_CADASTRO_ALCADA_CANCELAR');
    },
    editar(alcada) {
      this.alcada = new Alcada({ ...alcada });
      if (this.alcada && this.alcada.fluxo) {
        this.buscarTiposFluxo(this.alcada.fluxo.nome);
      } else {
        this.buscarTiposFluxo();
      }
      if (this.alcada && this.alcada.estruturaUsuario) {
        this.buscarEstruturasDeUsuario(this.alcada.estruturaUsuario.nomEstruturaUsuario);
      } else {
        this.buscarEstruturasDeUsuario();
      }
      if (this.alcada && this.alcada.fluxo) {
        this.buscarWorkflow(this.alcada.fluxo.nome);
      } else {
        this.buscarWorkflow();
      }
    },
    limparFormulario() {
      this.alcada = new Alcada();
      setTimeout(() => this.$refs.form.reset(), 1E2);
    },
    buscarTiposFluxo(filtro = null) {
      const params = {
        nome: filtro,
      };
      this.alcadaResources.listarTiposFluxo(params)
        .then((response) => {
          this.listaFluxos = response.data.resposta;
          if (!this.alcada.id) {
            this.$refs.form.resetValidation();
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    setarTipoFluxo() {
      this.alcada.idWorkflowTipo = this.alcada.fluxo.id;
      delete this.alcada.fluxo;
    },
    buscarWorkflow(filtro = null) {
      const params = {
        descricao: filtro,
      };
      this.alcadaResources.listarWorkflow(params)
        .then((response) => {
          this.listaWorkflows = response.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarEstruturasDeUsuario(filtro = null) {
      const params = {
        autocomplete: filtro,
      };
      this.estruturaUsuarioResources.selecao(params)
        .then((resultado) => {
          this.listaEstruturaUsuarios = resultado.data;
          if (!this.alcada.id) {
            this.$refs.form.resetValidation();
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    setarEstruturaUsuario() {
      this.alcada.idEstruturaUsuario = this.alcada.estruturaUsuario.id;
      this.alcada.nomEstruturaUsuario = this.alcada.estruturaUsuario.nomEstruturaUsuario;
      delete this.alcada.estruturaUsuario;
    },
    validarCampos() {
      if (!!this.alcada.vlrLimiteFinal && this.alcada.vlrLimiteFinal > 0) {
        this.$refs.form.validate();
      }
    },
    closeDialog(ref, cb) {
      this.$refs[ref].close();
      this.$refs[ref].active = false;
      if (cb) {
        cb();
      }
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.dialog.content = this.$t('message.salvar');
      this.$refs.confirmDialog.open();
    },
    openDialog(ref) {
      this.$refs[ref].open();
      this.$refs[ref].active = true;
    },
    save() {
      let salvarOuAtualizar;
      let inclusao = true;
      this.setarTipoFluxo();
      this.setarEstruturaUsuario();
      if (this.alcada.indSemValorFim) {
        this.alcada.vlrLimiteFinal = null;
      }
      this.$emit('FORMULARIO_CADASTRO_ALCADA_SALVAR', this.alcada, this.clearForm);
      if (this.alcada.id != null) {
        inclusao = false;
        salvarOuAtualizar = this.alcadaResources.atualizar(this.alcada);
      } else {
        salvarOuAtualizar = this.alcadaResources.gravar(this.alcada);
      }

      salvarOuAtualizar
        .then(() => this.$toast(
          this.$t(
            inclusao
              ? 'message.adicionado_confira_tabela'
              : 'message.atualizado_confira_tabela',
          ),
          'snack',
          3e3,
        ))
        .then(() => {
          this.$emit(
            'FORMULARIO_CADASTRO_ALCADA_SALVAR',
            { ...this.alcada },
          );
        })
        .then(() => this.limparFormulario())
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  computed: {
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  mounted() {
    this.buscarTiposFluxo();
    this.buscarEstruturasDeUsuario();
    this.buscarWorkflow();
  },
};
</script>

<style>
</style>
