import { buscarClusters } from '../../../../common/resources/dpm/planejamento-contrato-adama';

export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      planoContratoAdamaResource: this.$api.planoContratoAdama(this.$resource),
      anoFiscalResource: this.$api.anoFiscal(this.$resource),
      extensaoResource: this.$api.extensao(this.$resource),
      tipoContratoResource: this.$api.tipoContrato(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'periodo_planejamento',
            nomCampoId: 'id_periodo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction:
                (autocomplete) => this.planoContratoAdamaResource
                  .buscarPeriodosVigentePlanejamento({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'tipo_contrato',
            nomCampoId: 'id_contrato_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction:
                (autocomplete) => this.tipoContratoResource.buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'cluster',
            nomCampoId: 'id_cluster',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresCamposPadrao(),
                };
                return buscarClusters(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarDivisoesPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
        ],
      },
    };
  },
};
