<template>
  <v-form ref="formDPMAcoes"
          lazy-validation>
    <planejamento-dpm-plano-metas
      v-if="objetoPassoGeral"
      ref="planoMetas"
      :edicao="edicao"
      :tipo-contrato="tipoContrato"
      :configuracao="configuracao"
      :objeto-passo-geral="objetoPassoGeral"
      :contrato-edicao="contratoEdicao"
      :novo-contrato="novoContrato"
      :somente-leitura="somenteLeitura"
      :passo-aberto="passoAberto"
      />
  </v-form>
</template>
<script>
import PlanejamentoDpmPlanoMetas from './PlanejamentoDpmPlanoMetas';

export default {
  props: {
    edicao: Boolean,
    passoAberto: Number,
    novoContrato: Boolean,
    somenteLeitura: Boolean,
    contratoEdicao: Object,
    tipoContrato: {
      type: Object,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
    getObjetoOutroPasso: {
      type: Function,
      required: true,
    },
  },
  components: {
    PlanejamentoDpmPlanoMetas,
  },
  data() {
    return {
      planoMetasResource: this.$api.planejamentoPlanoMetas(this.$resource),
      isClassificacaoValida: false,
      objetoPassoGeral: null,
    };
  },
  methods: {
    abrePasso() {
      this.objetoPassoGeral = this.getObjetoOutroPasso('geral');
      setTimeout(() => this.$refs.planoMetas.abrePasso());
    },
    valida() {
      const valido = this.$refs.formDPMAcoes.validate();
      if (!valido) {
        this.$toast(this.$t('message.valor_padrao_maximo'));
      }
      return valido && this.$refs.planoMetas.validarAcoes();
    },
    getObjetoFormatado() {
      return this.$refs.planoMetas.getObjetoFormatado();
    },
    validar() {
      return this.valida();
    },
    validacaoAssincrona(finalizacao) {
      if (!finalizacao) {
        return Promise.resolve(true);
      }
      return this.canalPossuiClassificacaoValida();
    },
    canalPossuiClassificacaoValida() {
      return this.planoMetasResource.isClassificacaoValida({ idDpm: this.objetoPassoGeral.id })
        .then((res) => {
          let isValido = false;
          if (res.body) {
            isValido = true;
            return isValido;
          }
          this.$toast(this.$t('message.classificacao_invalida'));
          return isValido;
        });
    },
    getObjetoPasso() {
      return this.$refs.planoMetas.getObjetoPasso();
    },
  },
};
</script>
