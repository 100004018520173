import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'contrato/cadastro');

const planejamentoContratoActions = {
  buscarTiposContrato: { method: 'GET', url: `${basePath}/campos/tipo_contrato` },
  buscarGrupoFornecedorContrato: { method: 'GET', url: `${basePath}/campos/{idContrato}/grupo_fornecedor` },
  salvar: { method: 'POST', url: basePath },
  atualizar: { method: 'PUT', url: basePath },
  finalizar: { method: 'PUT', url: `${basePath}/finalizar` },
  executarAnalise: { method: 'PUT', url: `${basePath}/{idContrato}/executarAnalise` },
  cancelarContrato: { method: 'PUT', url: `${basePath}/{idContrato}/cancelarContrato` },
  verificarDadosExportacao: { methods: 'GET', url: `${basePath}/verificar-dados-exportacao` },
  salvarAcao: { method: 'POST', url: `${basePath}/{idContrato}/salvar-acao` },
  deletarAcao: { method: 'DELETE', url: `${basePath}/{idContrato}/{idAcao}` },
  cancelarAcao: { method: 'PUT', url: `${basePath}/{idAcao}/cancelarAcao` },
  atualizarAcao: { method: 'PUT', url: `${basePath}/{idContrato}/atualizar-acao` },
  executarAnaliseAcao: { method: 'PUT', url: `${basePath}/{idContrato}/acao/{idAcao}/executar-analise` },
  atualizarValorBase: { method: 'PUT', url: `${basePath}/atualizar-valor-base` },
};

export default (resource) => resource(`${basePath}`, {}, planejamentoContratoActions);
