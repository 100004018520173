<template>
  <v-row class="mt-6">
    <v-col cols="12" md="3" v-for="(classificacao, index) in classificacoes" :key="`card-classificacao-${index}`">
      <v-card
        class="mx-auto"
        max-width="344"
        :color="classificacao.classificacaoConfigurada ? 'primary' : null"
        :dark="classificacao.classificacaoConfigurada"
        outlined>
        <v-card-title class="headline">{{ $t(classificacao.nomExtensao) }}</v-card-title>
        <v-card-text>
          <v-row justify="center">
            <span class="title font-weight-regular">{{ `${$tc('label.ponto', 2)}: ${convertToNumberFormat(classificacao.pontos)}` }}</span>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text icon @click="abrirCadastro(classificacao)">
            <v-icon>settings_applications</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text icon @click="abrirRemoverClassificacao(classificacao)" v-if="!somenteLeitura">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <classificacao-configuracoes
      ref="classificacaoCadastro"
      :id-periodo="idPeriodo"
      :somente-leitura="somenteLeitura"
      :alterar-finalizado="alterarFinalizado"
      :classificacao="classificacaoTemp">
    </classificacao-configuracoes>
  </v-row>
</template>

<script>
import Confirm from '../../../../produto/shared-components/vuetify/dialog/Confirm';
import { convertToNumberFormat } from '../../../../produto/common/functions/numeric';
import Classificacao from './Classificacao';
import ClassificacaoConfiguracoes from './ClassificacaoConfiguracoes';

export default {
  name: 'ClassificacoesCards',
  components: {
    ClassificacaoConfiguracoes,
    Confirm,
  },
  props: {
    classificacoes: {
      type: Array,
      default: () => [],
    },
    idPeriodo: {
      type: Number,
      default: null,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      classificacaoTemp: new Classificacao(),
    };
  },
  methods: {
    convertToNumberFormat,
    abrirCadastro(classificacao) {
      this.classificacaoTemp = classificacao;
      this.$refs.classificacaoCadastro.open();
    },
    abrirRemoverClassificacao(classificacao) {
      this.$emit('ClassificacoesCards_remover', classificacao);
    },
  },
};
</script>
