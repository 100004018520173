import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'termo-aceite');

const termoAceiteActions = {
  ...genericActions(basePath),
  listarFiltrado: { method: 'GET', url: `${basePath}?tamanhoPagina={tamanhoPagina}&numeroPagina={numeroPagina}&filtro={filter}` },
  buscar: { method: 'GET', url: `${basePath}/{id}` },
  ativacao: { method: 'PUT', url: `${basePath}/ativacao/{codigo}` },
  buscarTermosLogin: { method: 'GET', url: `${basePath}/login` },
  aceitarTermos: { method: 'POST', url: `${basePath}/aceito` },
  listarRealizados: { method: 'GET', url: `${basePath}/realizados?tamanhoPagina={tamanhoPagina}&numeroPagina={numeroPagina}&filtro={filter}` },
};

export default (resource) => resource(`${basePath}`, {}, termoAceiteActions);
