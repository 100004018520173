const validarConflitoIds = (ids1, ids2) => (ids1.length === 0 && ids2.length === 0)
  || ids1.filter((value) => ids2.indexOf(value) !== -1).length > 0;

export default class IndicadorConfiguracao {
  constructor(indicadorConfiguracao = {}) {
    this._id = indicadorConfiguracao.id;
    this._divisoes = indicadorConfiguracao.divisoes
      ? [...indicadorConfiguracao.divisoes] : [];
    this._extensoesProduto = indicadorConfiguracao.extensoesProduto
      ? [...indicadorConfiguracao.extensoesProduto] : [];
    this._extensoesCliente = indicadorConfiguracao.extensoesCliente
      ? [...indicadorConfiguracao.extensoesCliente] : [];
    this._extensoesSegmento = indicadorConfiguracao.extensoesSegmento
      ? [...indicadorConfiguracao.extensoesSegmento] : [];
    this._ranges = indicadorConfiguracao.ranges
      ? [...indicadorConfiguracao.ranges] : [];
    this._ratings = indicadorConfiguracao.ratings
      ? [...indicadorConfiguracao.ratings] : [];
    this._index = indicadorConfiguracao.index || 0;
    this._extensoesCluster = indicadorConfiguracao.extensoesCluster
      ? [...indicadorConfiguracao.extensoesCluster] : [];

    this._idsDivisoes = this._divisoes.map((d) => d.id);
    this._idsExtensoesCliente = this._extensoesCliente.map((d) => d.id);
    this._idsExtensoesProduto = this._extensoesProduto.map((d) => d.id);
    this._idsExtensoesSegmento = this._extensoesSegmento.map((d) => d.id);
    this._idsExtensoesCluster = this._extensoesCluster.map((d) => d.id);
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get divisoes() {
    return this._divisoes;
  }

  set divisoes(value) {
    this._divisoes = value;
    this._idsDivisoes = this._divisoes.map((d) => d.id);
  }

  get extensoesProduto() {
    return this._extensoesProduto;
  }

  set extensoesProduto(value) {
    this._extensoesProduto = value;
    this._idsExtensoesProduto = this._extensoesProduto.map((d) => d.id);
  }

  get extensoesCliente() {
    return this._extensoesCliente;
  }

  set extensoesCliente(value) {
    this._extensoesCliente = value;
    this._idsExtensoesCliente = this._extensoesCliente.map((d) => d.id);
  }

  get extensoesCluster() {
    return this._extensoesCluster;
  }

  set extensoesCluster(value) {
    this._extensoesCluster = value;
    this._idsExtensoesCluster = this._extensoesCluster.map((d) => d.id);
  }

  get extensoesSegmento() {
    return this._extensoesSegmento;
  }

  set extensoesSegmento(value) {
    this._extensoesSegmento = value;
    this._idsExtensoesSegmento = this._extensoesSegmento.map((d) => d.id);
  }

  get nomesDivisoes() {
    return this._divisoes.map((d) => d.nome).join(', ');
  }

  get nomesExtensoesProduto() {
    return this._extensoesProduto.map((e) => e.nomExtensao).join(', ');
  }

  get nomesExtensoesSegmento() {
    return this._extensoesSegmento.map((e) => e.nomExtensao).join(', ');
  }

  get nomesExtensoesCliente() {
    return this._extensoesCliente.map((e) => e.nomExtensao).join(', ');
  }

  get nomesExtensoesCluster() {
    return this._extensoesCluster.map((e) => e.nomExtensao).join(', ');
  }

  get index() {
    return this._index;
  }

  set index(value) {
    this._index = value;
  }

  get ranges() {
    return this._ranges;
  }

  set ranges(value) {
    this._ranges = value;
  }

  get idsDivisoes() {
    return this._idsDivisoes;
  }

  get idsExtensoesCliente() {
    return this._idsExtensoesCliente;
  }

  get idsExtensoesCluster() {
    return this._idsExtensoesCluster;
  }

  get idsExtensoesProduto() {
    return this._idsExtensoesProduto;
  }

  get idsExtensoesSegmento() {
    return this._idsExtensoesSegmento;
  }

  get ratings() {
    return this._ratings;
  }

  set ratings(value) {
    this._ratings = value;
  }

  get temConfiguracao() {
    return (this._ranges && this._ranges.length > 0)
      || (this._ratings && this._ratings.length > 0);
  }

  validarConflitoVinculo(indicadorConf) {
    const conflitoDivisoes = validarConflitoIds(this._idsDivisoes, indicadorConf.idsDivisoes);
    const conflitoCliente = validarConflitoIds(this._idsExtensoesCliente, indicadorConf.idsExtensoesCliente);
    const conflitoProduto = validarConflitoIds(this._idsExtensoesProduto, indicadorConf.idsExtensoesProduto);
    const conflitoSegmento = validarConflitoIds(this._idsExtensoesSegmento, indicadorConf.idsExtensoesSegmento);
    const conflitoCluster = validarConflitoIds(this._idsExtensoesCluster, indicadorConf.idsExtensoesCluster);

    return conflitoDivisoes && conflitoCliente && conflitoProduto && conflitoSegmento && conflitoCluster;
  }

  toJSON() {
    const idsDivisoes = this._idsDivisoes;
    const idsExtensoesCliente = this._idsExtensoesCliente;
    const idsExtensoesCluster = this._idsExtensoesCluster;
    const idsExtensoesProduto = this._idsExtensoesProduto;
    const idsExtensoesSegmento = this._idsExtensoesSegmento;
    const ranges = this._ranges;
    const ratings = this._ratings;
    return {
      id: this._id,
      idsDivisoes,
      idsExtensoesCliente,
      idsExtensoesCluster,
      idsExtensoesProduto,
      idsExtensoesSegmento,
      ranges,
      ratings,
    };
  }
}
