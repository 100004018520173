<template>
  <div>
    <v-container pa-0 fluid>
      <v-row align="center" justify="space-between" class="font-weight-black Barra_Breadcrumbs">
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" class="text-right" style="margin-top: -4em;">
          <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <v-tooltip v-model="showTooltip" activator="#MetadadosCampoDinamico-tipo_acao" left max-width="100" nudge-left="10">
      <span>{{ $t('message.selecione_tipo_acao_para_pesquisar') }}</span>
    </v-tooltip>

    <apuracao-contrato-finalizar-lote-filtros
      v-model="abrirFiltro"
      @ApuracaoContratoFinalizarLoteFiltros__AplicaFiltros="aplicarFiltros"
      @ApuracaoContratoFinalizarLoteFiltros_conteinerCarregado="abrirTooltip">
    </apuracao-contrato-finalizar-lote-filtros>

    <v-container pa-0 mt-3 fluid v-resize="onResize" :style="`height: ${alturaCorpo}px; overflow-y: auto;`">
      <v-row>
        <v-expansion-panel v-for="(contrato, i) in contratos"
            :key="i">
          <v-expansion-panel-header>
            <v-checkbox
                v-model="contrato.selecionado"
                hide-details
                class="shrink pt-0 mt-0"
                @click.stop="(e) => selecionarContrato(contrato, e)">
              </v-checkbox>
              <span class="subheading">
                {{`${contrato.idContrato} :: ${contrato.origemContrato} :: ${getMoney(contrato.vlrRecebimento)}`}}
              </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <apuracao-contrato-finalizar-lote-tabela
              :id-contrato="contrato.idContrato"
              :filtros="filtros"
              @ApuracaoContratoFinalizarLoteTabela__recebimentoSemValor
                  ="contratoComRecebimentoSemValor(contrato)">
            </apuracao-contrato-finalizar-lote-tabela>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-row>

      <p v-if="mostrarNaoEncontrado" class="text-xs-center">{{ $t('label.tabela_sem_conteudo') }}</p>

      <v-row id="ApuracaoContratoFinalizarLote__Rodape" class="ApuracaoContratoFinalizarLote__Rodape pa-2"
                :style="`width: ${tamanhoRodape}px`" :column="xs">
        <div :class="{ 'text-xs-center': xs }">
          <v-pagination
            v-if="totalPagina > 1"
            v-model="numeroPagina"
            :length="totalPagina"
            :total-visible="$vuetify.breakpoint.mdAndUp ? 9 : 5"
            @input="buscarContratos(filtros)">
          </v-pagination>
        </div>
        <v-spacer v-if="!xs"></v-spacer>
        <v-btn v-if="contratosSelecionados.length" @click="finalizarSelecionados">{{ $t('label.finalizar_selecionados') }}</v-btn>
        <v-btn color="primary" v-if="contratos.length" @click="finalizarTudo">{{ $t('label.finalizar_tudo') }}</v-btn>
      </v-row>
    </v-container>

    <confirm ref="modalConfirm" :message="$tc('message.finalizar_apuracao_lote', 1)" @agree="finalizarApuracoes"></confirm>
    <confirm ref="modalRecebimentoSemValor" :message="$tc('message.finalizar_apuracao_sem_recebimento', 1)" @agree="$refs.modalConfirm.open()"></confirm>

  </div>
</template>

<script>
import ApuracaoContratoFinalizarLoteFiltros from './ApuracaoContratoFinalizarLoteFiltros';
import { buscarTotalApuradoPorContrato, finalizarApuracaoLote } from '../../common/resources/apuracao/apuracao-contrato';
import { getMoney } from '../../common/functions/helpers';
import ApuracaoContratoFinalizarLoteTabela from './ApuracaoContratoFinalizarLoteTabela';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'ApuracaoContratoFinalizarLote',
  components: {
    Confirm,
    ApuracaoContratoFinalizarLoteTabela,
    ApuracaoContratoFinalizarLoteFiltros,
  },
  data() {
    return {
      resource: this.$api.apuracaoContrato(this.$resource),
      abrirFiltro: false,
      showTooltip: false,
      filtrosCarregador: false,
      idContrato: null,
      contratos: [],
      contratosSelecionados: [],
      contratosRecebimentoSemValor: [],
      totalPagina: 1,
      tamanhoPagina: 10,
      numeroPagina: 1,
      filtros: {},
      tamanhoTela: window.innerWidth,
      tamanhoFiltros: 0,
      alturaTela: window.innerHeight,
      alturaRodaPe: 0,
      eventoClickCheckbox: null,
      jaBuscou: false,
    };
  },
  watch: {
    abrirFiltro(val) {
      if (!val) {
        this.showTooltip = false;
      }
    },
  },
  computed: {
    alturaCorpo() {
      return this.alturaTela - 150 - this.alturaRodaPe;
    },
    tamanhoRodape() {
      return this.tamanhoTela - (this.abrirFiltro && this.lg ? this.tamanhoFiltros : 0);
    },
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
    lg() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    mostrarNaoEncontrado() {
      return !this.contratos.length && this.jaBuscou;
    },
  },
  methods: {
    contratoComRecebimentoSemValor(contrato) {
      if (!(this.contratosRecebimentoSemValor
        .indexOf(contrato.idContrato) >= 0)) {
        this.contratosRecebimentoSemValor.push(contrato.idContrato);
      }
    },
    finalizarSelecionados() {
      this.filtros.contratosSelecionados = this.contratosSelecionados;
      if (this.selecionadoContratosRecebimentoSemValor(this.contratosSelecionados)) {
        setTimeout(() => this.$refs.modalRecebimentoSemValor.open());
      } else {
        setTimeout(() => this.$refs.modalConfirm.open());
      }
    },
    finalizarTudo() {
      this.filtros.contratosSelecionados = [];
      if (this.contratosRecebimentoSemValor.length) {
        setTimeout(() => this.$refs.modalRecebimentoSemValor.open());
      } else {
        setTimeout(() => this.$refs.modalConfirm.open());
      }
    },
    selecionadoContratosRecebimentoSemValor(contratosSelecionados) {
      if (!this.contratosRecebimentoSemValor.length) {
        return false;
      }

      for (let i = 0; i < contratosSelecionados.length; i += 1) {
        if (this.contratosRecebimentoSemValor
          .indexOf(contratosSelecionados[i]) >= 0) {
          return true;
        }
      }
      return false;
    },
    finalizarApuracoes() {
      const filtros = this.prepararParametros();
      const query = this.prepararQueryParam();
      finalizarApuracaoLote(query, this.$resource, filtros)
        .then(() => {
          this.limparArraysControladores();
          this.aplicarFiltros(this.filtros);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    prepararQueryParam() {
      const query = { ...this.filtros };

      query.idContrato = query.cod_contrato;

      delete query.cod_contrato;
      delete query.id_acao_tipo;
      delete query.id_grupo_fornecedor;
      delete query.data_inicio_apuracao;
      delete query.data_fim_apuracao;
      delete query.grupo_fornecedor;
      delete query.status;

      return query;
    },
    prepararParametros() {
      const filtros = { ...this.filtros };

      filtros.idTipoAcao = [filtros.id_acao_tipo];
      filtros.idGrupoFornecedor = filtros.id_grupo_fornecedor;
      filtros.dtaInicioApuracao = filtros.data_inicio_apuracao;
      filtros.dtaFimApuracao = filtros.data_fim_apuracao;

      delete filtros.id_acao_tipo;
      delete filtros.id_grupo_fornecedor;
      delete filtros.data_inicio_apuracao;
      delete filtros.data_fim_apuracao;
      delete filtros.grupo_fornecedor;

      return filtros;
    },
    selecionarContrato(contrato, e) {
      if (e) e.stopPropagation();
      contrato.selecionado = !contrato.selecionado;
      if (contrato.selecionado) {
        this.contratosSelecionados.push(contrato.idContrato);
      } else {
        const index = this.contratosSelecionados.indexOf(contrato.idContrato);
        if (index >= 0) {
          this.contratosSelecionados.splice(index, 1);
        }
      }
    },
    onResize() {
      this.alturaRodaPe = document.getElementById('ApuracaoContratoFinalizarLote__Rodape').offsetHeight;
      this.tamanhoTela = window.innerWidth;
      this.alturaTela = window.innerHeight;
    },
    getMoney,
    aplicarFiltros(filtros) {
      this.numeroPagina = 1;
      this.contratos = [];
      this.buscarContratos(filtros);
    },
    limparArraysControladores() {
      this.contratosSelecionados = [];
      this.contratosRecebimentoSemValor = [];
    },
    buscarContratos(filtros) {
      this.showTooltip = false;
      filtros.tamanhoPagina = this.tamanhoPagina;
      filtros.numeroPagina = this.numeroPagina;
      this.filtros = filtros;
      return buscarTotalApuradoPorContrato(filtros, this.$resource)
        .then((response) => {
          this.contratos = response.data.resposta;
          this.limparArraysControladores();
          this.totalPagina = Math
            .ceil(response.data.quantidadeRegistrosPagina / this.quantidadePagina);
          this.jaBuscou = true;
        })
        .catch((err) => {
          this.$error(this, err);
          this.jaBuscou = true;
        });
    },
    abrirTooltip() {
      this.tamanhoFiltros = document.getElementById('ApuracaoContratoFinalizarLoteFiltros__filtro_rapido').offsetWidth;
      setTimeout(() => {
        this.filtrosCarregador = true;
        this.showTooltip = true;
      }, 5E2);
    },
  },
  beforeMount() {
    this.idContrato = this.$router.currentRoute.params.idContrato;
  },
  mounted() {
    this.alturaRodaPe = document.getElementById('ApuracaoContratoFinalizarLote__Rodape').offsetHeight;
    this.abrirFiltro = true;
  },
};
</script>

<style scoped>
  .ApuracaoContratoFinalizarLote__Rodape {
    display: flex;

    position: fixed;
    z-index: 2;
    transform: translate(0);
    transition: all .4s cubic-bezier(.25,.8,.5,1);
    bottom: 0px;
    left: 0;
  }
</style>
