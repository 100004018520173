<style>
.PlanejamentoAcaoFormPrevia .title-float {
  font-weight: 400;
  font-size: 20px;
}
</style>
<template>
  <div class="PlanejamentoAcaoFormPrevia">
    <planejamento-acao-form-resumo
      v-if="objetoPassoGeral"
      :exibe-cliente-pagador="exibeClientePagador"
      :exibe-fornecedor-pagador="exibeFornecedorPagador"
      :objeto-passo-geral="objetoPassoGeral"
      :ordenacao-formulario="ordenacaoFormulario"
      :somenteLeitura="somenteLeitura"
      ref="resumo">
      <div class="card-title" slot="titulo-acao">
        {{ $t('label.criacao_acao')}} :: {{ tituloTipoAcao }} :: {{$tc('title.resumo', 1)}}
      </div>
    </planejamento-acao-form-resumo>

    <div class="mt-2 mb-2 title-float accent--text">
      {{$tc('label.pagamento', 1)}}
    </div>

    <planejamento-acao-form-previa-tabela
      v-if="objetoPassoGeral && objetoPassoGeral.idAcaoTradePrevia"
      :id-acao-trade-previa="objetoPassoGeral.idAcaoTradePrevia">
    </planejamento-acao-form-previa-tabela>

  </div>
</template>

<script>
import PlanejamentoAcaoFormResumo from '@/produto/spa/planejamento-acao/form/PlanejamentoAcaoFormResumo';
import PlanejamentoAcaoFormPassosDinamicosMixin from '@/produto/spa/planejamento-acao/form/passos-dinamicos/PlanejamentoAcaoFormPassosDinamicosMixin';
import PlanejamentoAcaoFormPreviaTabela from '@/spa/planejamento-acao/form/PlanejamentoAcaoFormPreviaTabela';

export default {
  name: 'PlanejamentoAcaoFormPrevia',
  components: { PlanejamentoAcaoFormPreviaTabela, PlanejamentoAcaoFormResumo },
  mixins: [PlanejamentoAcaoFormPassosDinamicosMixin],
  methods: {
    abrePasso() {
      this.preencherPassosGeral();
    },
  },
};
</script>
