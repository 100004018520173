import getBasePath from '../../../produto/common/functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'parametrizacao-periodo');

const grupoActions = {
  obterPasso1: { method: 'GET', url: `${basePath}/passo1/{id}` },
  salvarRascunhoPasso1: { method: 'POST', url: `${basePath}/passo1/rascunho` },
  atualizarRascunhoPasso1: { method: 'PUT', url: `${basePath}/passo1/{id}/rascunho` },
  salvarPasso1: { method: 'POST', url: `${basePath}/passo1` },
  atualizarPasso1: { method: 'PUT', url: `${basePath}/passo1/{id}` },
  buscarAnosFiscais: { method: 'GET', url: `${basePath}/passo1/ano-fiscal` },
  buscarTiposContratos: { method: 'GET', url: `${basePath}/passo1/tipo-contrato` },
  buscarExtensoesElegiveis: { method: 'GET', url: `${basePath}/passo1/elegibilidade?{&idsContratoTipo*}` },
};

export default (resource) => resource(`${basePath}`, {}, grupoActions);
