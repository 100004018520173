import Indicador from './Indicador';

export default class Passo3 {
  constructor(passo3) {
    this._indicadores = [];
    if (passo3 && passo3.indicadores) {
      passo3.indicadores.forEach((i) => {
        this._indicadores.push(new Indicador(i));
      });
    }
  }

  get idPeriodoConfiguracao() {
    return this._idPeriodoConfiguracao;
  }

  set idPeriodoConfiguracao(value) {
    this._idPeriodoConfiguracao = value;
  }

  get indicadores() {
    return this._indicadores;
  }

  set indicadores(value) {
    this._indicadores = value;
  }

  get estaConfigurado() {
    return !this._indicadores || this._indicadores.length === 0
      || this._indicadores.filter((ind) => !ind.indicadorConfigurado).length === 0;
  }

  get idsExtensoesCliente() {
    return this._indicadores
      .flatMap((c) => c.idsExtensoesCliente)
      .filter((c, i, s) => s.indexOf(c) === i);
  }

  removerIndicador(indicador) {
    for (let i = 0; i < this._indicadores.length; i += 1) {
      if (this._indicadores[i].id === indicador.id) {
        this._indicadores[i].peso = 0;
        this._indicadores[i].indicadoresConfiguracao = [];
        this._indicadores.splice(i, 1);
      }
    }
  }

  toJSON() {
    return {
      idPeriodoConfiguracao: this._idPeriodoConfiguracao,
      indicadores: this._indicadores,
    };
  }
}
