<template>
  <div v-if="gruposCultura.length > 0">
    <div class="mt-2 mb-2 title-float accent--text">
      {{$t('label.grupo_culturas')}}
    </div>
    <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-expansion-panels
            multiple
            focusable
            v-model="paineisAtivos">
            <apuracao-dpm-grupos-cultura-painel
              v-for="(grupo, index) in gruposCultura"
              :key="index"
              :grupo="grupo"
              :painel-ativo="paineisAtivos.indexOf(index) >= 0"
              />
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ApuracaoDpmGruposCulturaPainel from './ApuracaoDpmGruposCulturaPainel';

export default {
  components: {
    ApuracaoDpmGruposCulturaPainel,
  },
  props: {
    gruposCultura: Array,
  },
  data() {
    return {
      paineisAtivos: [],
    };
  },
};
</script>
