<template>
  <transition name="slide-fade"
    mode="out-in"
    v-if="carregouMetadados">
    <router-view></router-view>
  </transition>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getOrcamentoMetadado',
      'getLinhaInvestimentoMetadado',
      'getProdutoMetadado',
      'getClienteMetadado',
      'getCentroCustoMetadado',
      'getDivisaoMetadado',
    ]),
    carregouMetadados() {
      const orcamentoMetadados = this.getOrcamentoMetadado;
      const linhaInvestimentoMetadados = this.getLinhaInvestimentoMetadado;
      const produtoMetadados = this.getProdutoMetadado;
      const clienteMetadados = this.getClienteMetadado;
      const cecoMetadados = this.getCentroCustoMetadado;
      const divisaoMetadados = this.getDivisaoMetadado;

      if (orcamentoMetadados != null
        && linhaInvestimentoMetadados != null
        && produtoMetadados != null
        && clienteMetadados != null
        && cecoMetadados != null
        && divisaoMetadados != null) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setOrcamentoMetadado',
      'setLinhaInvestimentoMetadado',
      'setProdutoMetadado',
      'setClienteMetadado',
      'setCentroCustoMetadado',
      'setDivisaoMetadado',
    ]),
  },
  beforeMount() {
    this.setOrcamentoMetadado({
      resource: this.metadadoResource.definicaoOrcamento,
    });
    this.setLinhaInvestimentoMetadado({
      resource: this.metadadoResource.definicaoLinhaInvestimento,
    });
    this.setCentroCustoMetadado({
      resource: this.metadadoResource.definicaoCentroCusto,
    });
    this.setClienteMetadado({
      resource: this.metadadoResource.listarCliente,
    });
    this.setProdutoMetadado({
      resource: this.metadadoResource.listarProduto,
    });
    this.setDivisaoMetadado({
      resource: this.metadadoResource.listarDivisao,
    });
  },
};
</script>
