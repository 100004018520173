import IndicadorConfiguracao from './IndicadorConfiguracao';

export default class Indicador {
  constructor(indicador = {}) {
    this._id = indicador.idIndicador || indicador.id;
    this._descricao = indicador.descricao;
    this._idExterno = indicador.idExterno;
    this._peso = indicador.peso || 0;
    this._indicadoresConfiguracao = [];
    if (indicador.indicadorConfiguracao && indicador.indicadorConfiguracao.length > 0) {
      indicador.indicadorConfiguracao.forEach((i) => {
        this._indicadoresConfiguracao.push(new IndicadorConfiguracao(i));
      });
    }
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get idExterno() {
    return this._idExterno;
  }

  set idExterno(value) {
    this._idExterno = value;
  }

  get descricao() {
    return this._descricao;
  }

  set descricao(value) {
    this._descricao = value;
  }

  get peso() {
    return this._peso;
  }

  set peso(value) {
    this._peso = value;
  }

  get bloqueado() {
    return this._peso > 0 || this.configuracaoConcluida;
  }

  get indicadorConfigurado() {
    return this._peso > 0 && this.configuracaoConcluida;
  }

  get indicadoresConfiguracao() {
    return this._indicadoresConfiguracao;
  }

  set indicadoresConfiguracao(value) {
    this._indicadoresConfiguracao = value;
  }

  get ehSegmento() {
    return this._idExterno === 'PROPORCAO_SEGMENTO';
  }

  get exibirRating() {
    return this._idExterno === 'RISCO';
  }

  get idsExtensoesCliente() {
    return this._indicadoresConfiguracao
      .flatMap((c) => c.idsExtensoesCliente)
      .filter((c, i, s) => s.indexOf(c) === i);
  }

  get configuracaoConcluida() {
    return this._indicadoresConfiguracao && this._indicadoresConfiguracao.length > 0
      && this._indicadoresConfiguracao.filter((ind) => !ind.temConfiguracao).length === 0;
  }

  removerItem(item) {
    for (let i = 0; i < this._indicadoresConfiguracao.length; i += 1) {
      if (this._indicadoresConfiguracao[i].index === item.index) {
        this._indicadoresConfiguracao.splice(i, 1);
      }
    }
  }

  retornarConfiguracao(item) {
    return this._indicadoresConfiguracao.find((i) => i.index === item.index);
  }

  validarConflitosVinculos(indicadorConfiguracao) {
    let conflito = false;
    for (let i = 0; i < this._indicadoresConfiguracao.length && !conflito; i += 1) {
      const indicadoresValidacao = this._indicadoresConfiguracao[i];
      if (indicadoresValidacao.index !== indicadorConfiguracao.index) {
        conflito = indicadoresValidacao.validarConflitoVinculo(indicadorConfiguracao);
      }
    }
    return conflito;
  }

  toJSON() {
    return {
      idIndicador: this._id,
      peso: this._peso,
      indicadorConfiguracao: this._indicadoresConfiguracao,
    };
  }
}
