import getBasePath from '../functions/api-resource';

const basePath = getBasePath('planejamento_tatico', 'conta-corrente');

const contaCorrenteActions = {
  divisao: { method: 'GET', url: `${basePath}/divisao/{id}` },
  divisaoTransferencia: { method: 'GET', url: `${basePath}/divisao/{id}/transferencia` },
  funcionario: { method: 'GET', url: `${basePath}/funcionario/{id}` },
  cliente: { method: 'GET', url: `${basePath}/cliente/{id}` },
  transferir: { method: 'PUT', url: `${basePath}/divisao/{idOrigem}/transfere/{idDestino}` },
  transferencia: { method: 'GET', url: `${basePath}/{id}/transferencia?idAnoFiscal={idAnoFiscal}` },
  transferirVerbaFuncionarios: { method: 'PUT', url: `${basePath}/funcionario/{idOrigem}/transfere/{idDestino}` },
  buscarSaldoDoUsuario: { method: 'GET', url: `${basePath}/saldo/usuario` },
  buscarExtratoDaDivisao: { method: 'GET', url: `${basePath}/divisao/{id}/extrato?tamanhoPagina={size}&numeroPagina={page}` },
  buscarExtratoDoCliente: { method: 'GET', url: `${basePath}/cliente/{id}/extrato?tamanhoPagina={size}&numeroPagina={page}` },
  buscarSaldoDivisao: { method: 'GET', url: `${basePath}/saldo-divisao-hierarquia?tamanhoPagina={size}&numeroPagina={page}` },
  buscarSaldoCliente: { method: 'GET', url: `${basePath}/saldo-cliente?tamanhoPagina={size}&numeroPagina={page}` },
  buscarSaldoDivisaoPorAdmin: { method: 'GET', url: `${basePath}/saldo-divisao-admin?tamanhoPagina={size}&numeroPagina={page}` },
  buscarlSaldoClientePorAdmin: { method: 'GET', url: `${basePath}/saldo-cliente-admin?tamanhoPagina={size}&numeroPagina={page}` },
  filtrarClienteDaHierarquia: { method: 'GET', url: `${basePath}/cliente-hierarquia` },
  buscarSaldoMateriaisDivisao: { method: 'GET', url: `${basePath}/saldo/{idDivisao}` },
};

export default (resource) => resource(`${basePath}`, {}, contaCorrenteActions);
