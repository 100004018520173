import getBasePath from '../functions/api-resource';

const basePath = getBasePath('orcamento', 'orcamento/rateio');

const rateioActions = {
  buscaRateio: { method: 'GET', url: `${basePath}/{idOrcamento}` },
  atualizarRateio: { method: 'PUT', url: `${basePath}/{idOrcamento}` },
  buscarDivisoes: { method: 'GET', url: `${basePath}/{idOrcamento}/divisoes` },
  buscarNovasDivisoes: { method: 'GET', url: `${basePath}/{idOrcamento}/novas-divisoes` },
  adicionarNovasDivisoes: { method: 'PUT', url: `${basePath}/{idOrcamento}/novas-divisoes` },
};

export default (resource) => resource(`${basePath}`, {}, rateioActions);
