var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"9"}},[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$tc('label.estrutura_pai', 1)))])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-autocomplete',{attrs:{"id":"estrutura_usuario_pai","name":"estrutura_usuario_pai","items":_vm.listaUsuarioPai,"search-input":_vm.buscaListaUsuarioPai,"no-data-text":_vm.$tc('message.nenhum_registro', 1),"item-text":"nomEstruturaUsuario","item-value":"id","label":("" + (_vm.$tc('label.estrutura_usuario', 1))),"placeholder":_vm.$tc('label.digite_para_buscar', 1),"clearable":"","return-object":""},on:{"update:searchInput":function($event){_vm.buscaListaUsuarioPai=$event},"update:search-input":function($event){_vm.buscaListaUsuarioPai=$event},"click:append":_vm.triggerEstruturaPai,"change":_vm.setEstruturaUsuarioPai},nativeOn:{"click":function($event){return _vm.buscarEstruturaUsuarioAutocomplete($event)}},model:{value:(_vm.nomeEstruturaUsuarioPai),callback:function ($$v) {_vm.nomeEstruturaUsuarioPai=$$v},expression:"nomeEstruturaUsuarioPai"}})],1)],1),_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.tableData,"no-data-text":_vm.$t('label.tabela_sem_conteudo')},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomEstruturaUsuario)+" ")]}},{key:"item.estrutura_pai",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomeEstruturaUsuarioPai)+" ")]}},{key:"item.verba_estrutural",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"verbas-estruturais",attrs:{"color":"primary","id":"estruturaPaiTabela_verbaEstrutural","name":"estruturaPaiTabela_verbaEstrutural"},on:{"change":function($event){return _vm.inativarOutros(item)}},model:{value:(item.verbaEstrutural),callback:function ($$v) {_vm.$set(item, "verbaEstrutural", $$v)},expression:"item.verbaEstrutural"}})]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [(!_vm.somenteLeitura)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeRowItem(item)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.remover')))])]):_vm._e()]}}])}),_c('confirm',{ref:"dialogConfirmaRemover",attrs:{"message":_vm.$t('message.deseja_remover_estrutura_pai'),"persistent":true},on:{"agree":_vm.removerEstruturaUsuarioPai}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }