<template>
  <div v-if="canAccessPage">
    <div style="text-align: center" class="title justify-center">
      <p class="mb-0">{{ titulo }}</p>
    </div>
    <v-container fluid grid-list-md class="DashboardPendencias_AjusteVerba">
      <v-data-iterator
        :items="dados"
        :server-items-length="totalPage"
        :options.sync="pagination"
        :hide-default-footer="escondePaginacao"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50],
        }">
        <template v-slot:default="props">
          <v-row align="start" justify="center">
            <v-col
              cols="12"
              v-for="(item) in props.items"
              :key="item.idAjusteVerba">
              <v-hover>
                <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 5 : 1}`" class="Card_Pendencia fill-height">
                  <v-card-title class="Card_Title">
                    <v-container fluid>
                      <v-row justify="space-between">
                        <span>
                          {{ `${item.tipoVerba} :: ${item.orcamento}` }}
                        </span>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn text icon
                                  v-on="on"
                                  @click.native="abrirDetalhamento(item)">
                              <v-icon >info</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $tc('label.detalhe', 2) }}</span>
                        </v-tooltip>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-card-text class="Card_Content">
                    <v-container fluid>
                      <v-row justify="center" align="center">
                        <v-col cols="12" class="pa-0" v-if="item.divisao">
                          <span>{{ item.divisao }}</span>
                        </v-col>
                        <v-col cols="12" class="pa-0" v-else>
                          <span v-if="item.holding">{{ item.holding }}</span>
                          <span v-else>{{ item.cliente }}</span>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                          <span>
                            {{ $t(`label.${item.tipoAjuste.toLowerCase()}`) }} {{ `:: ${getMoney(item.valorAjuste)}` }}
                          </span>
                        </v-col>
                        <v-col cols="12" md="7" class="pa-0">
                          <span>{{ `${item.periodoOrcamentario}` }}</span>
                        </v-col>
                        <v-col cols="12" md="5" class="pa-0">
                          <v-spacer></v-spacer>
                          <ajuste-verba-botoes-fluxo
                            :key="item.id"
                            :idAjusteVerba="item.id"
                            :status="item.status"
                            @recarregar="buscarDados()"
                            :exibir-acompanhamento="false"/>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  getMoney,
  getPercent,
} from '../../common/functions/helpers';
import AjusteVerbaBotoesFluxo from '../ajuste-verba/AjusteVerbaBotoesFluxo';
import { pendenciasFluxoAjusteVerba } from '../../common/resources/workflow-ajuste-verba';
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  name: 'dash-inicial-pendencias-ajuste-verba',
  components: {
    AjusteVerbaBotoesFluxo,
  },
  props: {
    filtros: Object,
    dashFornecedor: {
      type: Boolean,
      default: false,
    },
    dashCliente: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    titulo() {
      if (this.title) {
        return this.title;
      }
      return this.$tc('title.pendencia_ajuste_verba', 2);
    },
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...generateComputed('AJUSTE_VERBA', [
      'canAccessPage',
    ]),
  },
  data() {
    return {
      dados: [],
      escondePaginacao: true,
      pagination: {
        itemsPerPage: 5,
      },
      totalPage: 0,
      filtrosAplicados: this.filtros,

      realizouPrimeiraBusca: false,
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscarDados();
        setTimeout(() => {
          this.realizouPrimeiraBusca = true;
        });
      },
    },
    pagination: {
      handler() {
        if (this.realizouPrimeiraBusca) {
          this.buscarDados();
        }
      },
      deep: true,
    },
  },
  methods: {
    getMoney,
    getPercent,

    buscarDados() {
      const params = {
        ...this.filtrosAplicados,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };

      pendenciasFluxoAjusteVerba(params, this.$resource)
        .then((res) => {
          const { data } = res;

          if (!data.resposta.length && this.pagination.page > 1) {
            this.pagination.page -= 1;
            this.buscarDados();
            return;
          }
          this.dados = data.resposta;
          this.totalPage = data.quantidadeRegistrosPagina;
          this.escondePaginacao = (this.totalPage / this.pagination.itemsPerPage) <= 1;
          this.$forceUpdate();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    abrirDetalhamento(ajusteVerba) {
      if (ajusteVerba.tipoAjuste === 'TRANSFERENCIA') {
        this.$router.push({ name: 'verAjusteVerbaTransferencia', params: { id: ajusteVerba.id, from: 'dashboard-inicial' } });
      } else if (ajusteVerba.tipoAjuste === 'REDUCAO_MASSIVA') {
        this.$router.push({ name: 'verReducaoMassiva', params: { id: ajusteVerba.id, from: 'dashboard-inicial' } });
      } else {
        this.$router.push({ name: 'verAjusteVerba', params: { id: ajusteVerba.id, from: 'dashboard-inicial' } });
      }
    },
  },
};
</script>
<style>
.DashboardPendencias_AjusteVerba {
  padding-top: 0px;
}
.DashboardPendencias_AjusteVerba .Card_Pendencia .Card_Title {
  padding: 7px 7px 0px 7px ;
}
.DashboardPendencias_AjusteVerba .Card_Pendencia .Card_Title span {
  font-weight: 700;
  margin: 0px;
  font-size: 16px;
}
.DashboardPendencias_AjusteVerba .Card_Pendencia .Card_Content {
  padding: 7px 7px 0px 7px ;
}
.DashboardPendencias_AjusteVerba .Card_Pendencia .Card_Content span {
  font-size: 14px;
}
.DashboardPendencias_AjusteVerba .Card_Pendencia .Card_Title button span i,
.DashboardPendencias_AjusteVerba .Card_Pendencia .Card_Title span,
.DashboardPendencias_AjusteVerba .Card_Pendencia .Card_Content span,
.DashboardPendencias_AjusteVerba .Card_Pendencia .Card_Content button span i {
  color: rgba(0,0,0,.70) !important;
}
.DashboardPendencias_AjusteVerba .Card_Pendencia .Card_Actions {
  padding-top: 0px;
}
</style>
