<style>
.PlanejamentoAcaoList {
  padding-bottom: 60px;
}

</style>
<template>
  <div class="PlanejamentoAcaoList">
    <planejamento-acao-filtros
      ref="filtros"
      @PlanejamentoAcaoFiltros__AplicaFiltros="aplicaFiltros">
    </planejamento-acao-filtros>
    <planejamento-acao-dados
      ref="dados"
      @PlanejamentoAcaoDados__AplicaFiltros="alterarAgrupamento">
    </planejamento-acao-dados>

    <planejamento-acao-cards-acoes></planejamento-acao-cards-acoes>
  </div>
</template>
<script>

import PlanejamentoAcaoFiltros from '@/spa/planejamento-acao/list/PlanejamentoAcaoFiltros';
import PlanejamentoAcaoDados from '@/spa/planejamento-acao/list/PlanejamentoAcaoDados';
import PlanejamentoAcaoCardsAcoes from '@/spa/planejamento-acao/list/PlanejamentoAcaoCardsAcoes';
import { generateComputed } from '../../../common/functions/roles-computed-generator';

export default {
  components: {
    PlanejamentoAcaoFiltros,
    PlanejamentoAcaoDados,
    PlanejamentoAcaoCardsAcoes,
  },
  computed: {
    ...generateComputed('ACAO', [
      'canAccessPage',
      'canEdit',
    ]),
    ...generateComputed('ACESSO_CLIENTE', [
      'getAllRoles',
    ]),
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      exibeModalNovaAcao: false,
      filtroTipo: null,
      filtros: {},
      quantidadePagina: 1,
    };
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        if (!this.getAllRoles
            || !this.getAllRoles.filter((el) => el.startsWith('ACESSO_CLIENTE')).length) {
          this.$router.push({ name: 'inicio' });
          this.$toast(this.$t('message.acesso_negado'));
        }
      }
    },
    criarNovaAcao() {
      this.exibeModalNovaAcao = true;
    },
    fecharModalNovaAcao() {
      this.exibeModalNovaAcao = false;
    },
    alterarAgrupamento() {
      this.$refs.dados.aplicaFiltros(this.filtros);
    },
    aplicaFiltros(filtros = {}) {
      this.filtros = filtros;
      setTimeout(() => this.$refs.dados.aplicaFiltros(filtros), 500);
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
