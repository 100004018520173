import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('planejamento_tatico', 'acao-tipo');

const contaCorrenteAjusteVerbaActions = {
  buscarAtivos: { method: 'GET', url: `${basePath}?tamanhoPagina={tamanhoPagina}&numeroPagina={numeroPagina}&filtro={filter}&indAtivo=true{&idsExtensoes*}` },
  buscarAtivosPorTipoVerba: { method: 'GET', url: `${basePath}?tamanhoPagina={tamanhoPagina}&numeroPagina={numeroPagina}&filtro={filter}&indAtivo=true{&idsExtensoes*}` },
};

export default (resource) => resource(`${basePath}`, {}, contaCorrenteAjusteVerbaActions);
