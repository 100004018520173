<template lang="html">
  <v-form ref="form" lazy-validation autocomplete="off">
    <v-card>
      <v-card-title>
        <h2 class="headline mb-0">{{ $tc('title.fornecedor', 1) }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <metadados-container-layout
          v-if="metadadosFornecedor != null"
          :metadados-entidade="metadadosFornecedor"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="fornecedor"
          :formulario-filtros="false"
          :somenteLeitura="somenteLeitura"
          :novoCadastro="true"
          @MetadadosContainerLayout_abrirCadastro="abrirCadastro"
          @MetadadosContainerLayout_save="validarForm"
          @MetadadosContainerLayout_form_Invalido="validarForm"
          ref="container">
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-select
              id="tipo_pessoa" name="tipo_pessoa"
              :rules="[rules.required]"
              :items="tiposPessoa"
              v-model="tipoPessoa"
              item-text="text"
              item-value="value"
              @change="limparCampoDocumento"
              :label="`${$tc('label.tipo_pessoa', 1)} *`"
              :disabled="somenteLeitura">
            </v-select>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              id="campo_codigo"
              name="campo_codigo"
              v-model="fornecedor.idExterno"
              :label="`${$tc('label.cod_fornecedor', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :counter="10"
              maxlength="10"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              id="campo_razao_social"
              name="campo_razao_social"
              v-model="fornecedor.desRazaoSocial"
              :label="`${$tc('label.razao_social', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :counter="100"
              maxlength="100"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              id="campo_nome_comercial"
              name="campo_nome_fantasia"
              v-model="fornecedor.desNomeFantasia"
              :label="`${isPessoaJuridica ? $tc('label.nome_comercial', 1) + ' *' : $tc('label.nome_comercial', 1)}`"
              :rules="[rules.pessoaJuridica]"
              :disabled="somenteLeitura"
              :counter="100"
              maxlength="100"
            >
          </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes" v-if="isPessoaJuridica">
            <v-text-field
              id="campo_cnpj"
              name="campo_cnpj"
              v-model="fornecedor.desCnpj"
              :label="`${$tc('label.cnpj', 1)} *`"
              :rules="[rules.required, rules.documento]"
              :disabled="somenteLeitura"
              :maxlength="18"
              v-mask="['##.###.###/####-##']"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes" v-if="!isPessoaJuridica">
            <v-text-field
              id="campo_cpf"
              name="campo_cpf"
              v-model="fornecedor.desCnpj"
              :label="`${$tc('label.cpf', 1)} *`"
              :rules="[rules.required, rules.documento]"
              :disabled="somenteLeitura"
              :maxlength="14"
              v-mask="['###.###.###-##']"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_ie"
              name="campo_ie"
              v-model="fornecedor.inscricaoEstadual"
              :label="`${isPessoaJuridica ? $tc('label.inscricao_estadual', 1) + ' *' : $tc('label.inscricao_estadual', 1)}`"
              :rules="[rules.pessoaJuridica]"
              :disabled="somenteLeitura"
              :counter="50"
              maxlength="50"
            >
            </v-text-field>
          </v-col>
           <v-col cols="12" sm="4" md="4" slot="antes"  v-if="!isPessoaJuridica" >
            <v-text-field
              id="campo_pis"
              name="campo_pis"
              v-model="fornecedor.pis"
              :label="`${$tc('label.pis', 1) + ' *'}`"
              :rules="[rules.required, rules.validatePis]"
              :disabled="somenteLeitura"
              v-mask="'###.####.###-#'"
              >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes" v-if="!isPessoaJuridica" >
           <v-menu
              ref="data_inicio_ano"
              :close-on-content-click="false"
              v-model="menuDataNascimento"
              id="dp_data_nascimento"
              name="dp_data_nascimento"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  ref="dataNascimento"
                  class="Form-text__invalid"
                  required
                  v-mask="'##/##/####'"
                  :rules="[rules.required, rules.validateNascimento]"
                  v-model="dataNascimentoFormatada"
                  :label="`${$t('label.dta_nascimento')} *`"
                  prepend-icon="event">
                </v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                color="primary"
                v-model="fornecedor.dta_nascimento"
                @input="menuDataNascimento = false"
                >
              </v-date-picker>
            </v-menu>
          </v-col>
           <v-col cols="12" sm="4" md="4" slot="antes">
             <v-autocomplete
              id="campo_nome_fantasia"
              name="campo_nome_fantasia"
              v-model="fornecedor.id_nome_fantasia"
              :disabled="somenteLeitura"
              :items="listaNomesFantasia"
              :search-input.sync="buscarListaNomesFantasia"
              @click.native="buscarNomesFantasia"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomeFantasia"
              item-value="id"
              :label="`${$tc('label.nome_fantasia', 1)} *`"
              clearable
              :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="nomBanco"
              name="nomBanco"
              v-model="fornecedor.nomBanco"
              :label="`${$tc('label.banco', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              type="text"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="agencia"
              name="agencia"
              v-model="fornecedor.vlrAgencia"
              :label="`${$tc('label.agencia', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              type="text"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="conta_corrente"
              name="conta_corrente"
              v-model="fornecedor.vlrConta"
              :rules="[rules.required]"
              :label="`${$tc('label.conta_corrente', 1)} *`"
              :disabled="somenteLeitura"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_contato_nome"
              name="campo_contato_nome"
              v-model="fornecedor.contato.nomContato"
              :label="`${$tc('label.contato', 1)}`"
              :disabled="somenteLeitura"
              :counter="100"
              maxlength="100"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_contato_email"
              name="campo_contato_email"
              v-model="fornecedor.contato.vlrEmail"
              :label="`${$tc('label.email', 1)}`"
              :disabled="somenteLeitura"
              :counter="100"
              maxlength="100"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="depois">
            <v-text-field
              id="campo_contato_telefone"
              name="campo_contato_telefone"
              v-model="fornecedor.contato.vlrTelefone"
              :label="`${$tc('label.telefone', 1)}`"
              :disabled="somenteLeitura"
              return-masked-value
              v-mask="'(##) #####-####'"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="depois">
            <v-switch
              color="primary"
              :label="$t('label.ativar_inativa')"
              v-model="fornecedor.indAtivo"
              :disabled="somenteLeitura"
              class="mt-3">
            </v-switch>
          </v-col>
          <v-col cols="12" slot="depois">
            <endereco
              ref="endereco"
              @ENDERECO_valido="enderecoValido"
              @ENDERECO_invalido="enderecoInvalido"
              :somenteLeitura="somenteLeitura"
              :id="fornecedor.idEndereco"
              >
            </endereco>
          </v-col>
        </metadados-container-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="canAccessCRUD" id="btn_cancelar_novo_fornecedor" @click.native="openCancelarDialog">{{ $t('label.cancelar') }}</v-btn>
        <v-btn v-else id="btn_voltar_fornecedor" @click.native="cancelar">{{ $t('label.voltar') }}</v-btn>
        <v-btn v-if="canAccessCRUD" id="btn_salvar_novo_fornecedor" @click.native="validarForm" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>

      <v-dialog v-model="dialogCadastroExtensao" persistent max-width="800px">
        <fornecedor-nova-extensao
          ref="fornecedorNovaExtensao"
          :somenteLeitura="somenteLeitura"
          :objeto="novaExtensao"
          @cancelar="fecharCadastroExtensao"
          @atualizarGrid="setarCadastroExtensao"
        />
      </v-dialog>
    </v-card>

    <confirm v-if="canAccessCRUD"
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', { text: this.$tc('title.fornecedor', 1).toLowerCase() })"
      @agree="salvar">
    </confirm>

    <confirm
      ref="cancelarDialog" :title="$t('title.atencao')"
      :message="$t('message.tem_certeza_cancelar')"
      @agree="cancelar">
    </confirm>
  </v-form>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { skipLoading } from '../../../produto/common/functions/loading';
import MetadadosContainerLayout from '../../../produto/shared-components/metadados/MetadadosContainerLayout';
import { generateComputed } from '../../../produto/common/functions/roles-computed-generator';
import Fornecedor from '../../../produto/spa/fornecedor/Fornecedor';
import Endereco from '../../../produto/spa/endereco/Endereco';
import Confirm from '../../../produto/shared-components/vuetify/dialog/Confirm';
import FornecedorNovaExtensao from '../../../produto/spa/fornecedor/FornecedorNovaExtensao';

export default {
  name: 'FornecedorIndustriaForm',
  components: {
    MetadadosContainerLayout,
    FornecedorNovaExtensao,
    Endereco,
    Confirm,
  },
  data() {
    return {
      menuDataNascimento: false,
      buscarListaNomesFantasia: null,
      listaNomesFantasia: [],
      metadadoResource: this.$api.metadado(this.$resource),
      fornecedorResource: this.$api.fornecedor(this.$resource),
      tipoBeneficioResources: this.$api.tipoBeneficio(this.$resource),
      extensaoAdama: this.$api.extensaoAdama(this.$resource),

      fornecedor: new Fornecedor(),
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        validateNascimento: (value) => {
          if (!!value && !!this.tipoPessoa && this.tipoPessoa === 'FISICA') {
            if (value.length === 10) {
              const today = moment(new Date()).format('YYYY-MM-DD');
              const newDate = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
              if (!moment(newDate).isBefore(today, 'day')) {
                return this.$t('message.data_nascimento_invalida');
              }
            } else {
              return this.$t('message.data_nascimento_invalida');
            }
          }

          return !!value;
        },
        pessoaJuridica: (value) => {
          if (this.isPessoaJuridica) {
            if (value) {
              return !!value;
            }
            return this.$t('message.campo_obrigatorio_pessoa_juridica');
          }
          return true;
        },
        documento: (value) => {
          if (this.isPessoaJuridica) {
            if (value && value.replace(/\D+/g, '').length !== 14) {
              return this.$t('message.cnpj_tamanho');
            }
            return !!value;
          }
          if (!this.isPessoaJuridica) {
            if (value && value.replace(/\D+/g, '').length !== 11) {
              return this.$t('message.cpf_tamanho');
            }
            return !!value;
          }
          return !!value;
        },
        validatePis: (value) => {
          if (!this.isPessoaJuridica) {
            if (value && value.replace(/\D+/g, '').length !== 11) {
              return this.$t('message.pis_tamanho');
            }
            return !!value;
          }
          return true;
        },
      },
      dialogCadastroExtensao: false,
      novaExtensao: {},
      tiposPagamento: [],
      tipoPessoa: {
        text: 'Jurídica',
        value: 'JURIDICA',
      },
      tiposPessoa: [
        {
          text: 'Física',
          value: 'FISICA',
        },
        {
          text: 'Jurídica',
          value: 'JURIDICA',
        },
      ],
      somenteLeitura: false,
    };
  },
  computed: {
    ...generateComputed('FORN', [
      'canAccessCRUD',
    ]),
    ...mapGetters('metadados', [
      'getFornecedorMetadado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    metadadosFornecedor() {
      if (this.getFornecedorMetadado && this.getFornecedorMetadado.mapaEntidades) {
        return this.getFornecedorMetadado;
      }
      return undefined;
    },
    isPessoaJuridica() {
      return !!this.tipoPessoa
             && (this.tipoPessoa.value === 'JURIDICA' || this.tipoPessoa === 'JURIDICA');
    },
    dataNascimentoFormatada: {
      get() {
        return !this.fornecedor.dta_nascimento ? null : moment(this.fornecedor.dta_nascimento, 'YYYY-MM-DD').format('DD/MM/YYYY');
      },
      set(newValue) {
        if (moment(newValue, 'DD/MM/YYYY', true).isValid()) {
          this.fornecedor.dta_nascimento = moment(newValue, 'DD/MM/YYYY').format('YYYY-MM-DD');
        } else {
          this.fornecedor.dta_nascimento = null;
        }
      },
    },
  },
  watch: {
    buscarListaNomesFantasia(val) {
      if (this.fornecedor && this.fornecedor.id_nome_fantasia) {
        const selecao = this.listaNomesFantasia
          .filter((item) => item.id === this.fornecedor.id_nome_fantasia);
        if (selecao.length > 0 && selecao[0].nomeFantasia === val) {
          return;
        }
      }

      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (val != null) this.buscarNomesFantasia(val);
      }, 500);
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setFornecedorMetadado',
    ]),
    carregarFornecedor() {
      const { id } = this.$route.params;
      this.somenteLeitura = this.$route.params.somenteLeitura;
      skipLoading();
      this.fornecedorResource.buscar({ id })
        .then((res) => {
          this.fornecedor = { ...this.fornecedor, ...res.body };
          this.tipoPessoa = this.fornecedor.tipoPessoa;
          if (this.fornecedor.id_nome_fantasia) {
            this.listaNomesFantasia = [{
              id: this.fornecedor.id_nome_fantasia,
              nomeFantasia: this.fornecedor.nome_fantasia,
            }];
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    enderecoValido(endereco) {
      this.fornecedor.endereco = endereco;
      this.openSaveDialog();
    },
    enderecoInvalido(endereco) {
      this.fornecedor.endereco = endereco;
    },
    limparCampoDocumento() {
      if (this.fornecedor.desCnpj) {
        this.fornecedor.desCnpj = null;
      }
      this.$refs.form.resetValidation();
      this.$refs.endereco.resetValidation();
    },
    validarForm() {
      this.$refs.endereco.isValido(this.$refs.form.validate());
    },
    openSaveDialog() {
      this.$refs.confirmDialog.open();
    },
    openCancelarDialog() {
      this.$refs.cancelarDialog.open();
    },
    buscarNomesFantasia(busca = null) {
      if (typeof busca !== 'string') {
        busca = null;
      }
      const param = {
        filtro: busca,
        limit: 20,
      };
      return this.extensaoAdama.buscarNomesFantasia(param)
        .then((response) => {
          this.listaNomesFantasia = response.data;
        });
    },
    salvar() {
      this.fornecedor.idsProdutos = [];
      this.fornecedor.listaprodutos.forEach((value) => {
        this.fornecedor.idsProdutos.push(value.id);
      });

      this.fornecedor.indModoVarejo = this.indModoVarejo;
      this.fornecedor.tipoPessoa = this.tipoPessoa.value || this.tipoPessoa;
      if (this.fornecedor.desNomeFantasia === null
          || this.fornecedor.desNomeFantasia === undefined) {
        this.fornecedor.desNomeFantasia = this.fornecedor.desRazaoSocial;
      }

      if (this.fornecedor.tipoPessoa === 'FISICA') {
        this.fornecedor.dta_nascimento = moment(this.fornecedor.dta_nascimento).format('YYYY-MM-DD');
      }
      const objArmazenamento = {
        ...this.fornecedor,
        ...this.$refs.container.getValoresDependencias(),
      };

      if (this.fornecedor.id) {
        this.atualizar(objArmazenamento);
      } else {
        this.inserir(objArmazenamento);
      }
    },
    inserir(obj) {
      this.fornecedorResource.gravar(obj)
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.$router.push({ name: this.$route.params.from || 'fornecedor' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    atualizar(obj) {
      const { id } = obj;
      this.fornecedorResource.atualizar({ id }, obj)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
          this.$router.push({ name: this.$route.params.from || 'fornecedor' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancelar() {
      this.$router.push({ name: this.$route.params.from || 'fornecedor' });
    },
    abrirCadastro(metadados) {
      this.novaExtensao = {};
      this.novaExtensao.indAtivo = true;
      this.novaExtensao.idNivelExtensao = metadados.idNivelExtensao;
      this.novaExtensao.metadados = metadados;
      this.$refs.fornecedorNovaExtensao.setNivel(metadados.idNivelExtensao);
      this.dialogCadastroExtensao = true;
    },
    fecharCadastroExtensao() {
      this.dialogCadastroExtensao = false;
    },
    setarCadastroExtensao(obj, metadados) {
      if (metadados.manyToMany) {
        if (!this.fornecedor[metadados.label]) {
          this.fornecedor[metadados.label] = [];
        }
        this.fornecedor[metadados.label].push(obj);
      } else {
        this.fornecedor[metadados.label] = obj;
      }
      this.$refs.container.refresh();
      this.dialogCadastroExtensao = false;
    },
    buscarTiposBeneficios() {
      this.tipoBeneficioResources.listarAtivos().then((response) => {
        this.tiposPagamento = response.data;
      });
    },
  },
  beforeMount() {
    this.buscarTiposBeneficios();
    if (this.$route.params.id) {
      if (this.getFornecedorMetadado && this.getFornecedorMetadado.mapaEntidades) {
        this.carregarFornecedor();
      } else {
        this.setFornecedorMetadado({
          resource: this.metadadoResource.listarFornecedor,
          params: null,
          callback: this.carregarFornecedor,
        });
      }
    }
  },
};
</script>
