import { buscarHoldings } from '../../produto/common/resources/planejamento/planejamento-acao-cadastro';

export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      acaoPreviaResource: this.$api.acaoPrevia(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'holding',
            nomCampoId: 'id_holding',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            filter: () => true,
            async: {
              fetchFunction: (autocomplete) => buscarHoldings({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'periodo_orcamentario',
            nomCampoId: 'periodo_orcamentario',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.acaoPreviaResource
                .buscarPeriodosOrcamentarios({ autocomplete }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarDivisoesPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomeComposto',
            },
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              lista: [
                {
                  valor: 'EM_CADASTRO',
                  texto: this.$tc('label.em_cadastro', 1),
                },
                {
                  valor: 'AGUARDANDO_APROVACAO',
                  texto: this.$tc('label.aguardando_aprovacao', 1),
                },
                {
                  valor: 'APROVADO',
                  texto: this.$tc('label.aprovado', 1),
                },
                {
                  valor: 'REPROVADO',
                  texto: this.$tc('label.reprovado', 1),
                },
                {
                  valor: 'EM_ANALISE',
                  texto: this.$tc('label.em_analise', 1),
                },
                {
                  valor: 'CANCELADO',
                  texto: this.$tc('label.cancelado', 1),
                },
              ],
              textoItem: 'texto',
              valorItem: 'valor',
            },
          },
        ],
      },
    };
  },
};
