<template>
  <div>
    <v-container fluid px-0>
      <v-row align="center" justify="center" class="mx-0">
        <v-col cols="12">
          <v-text-field
            v-model="pesquisaCards"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.pesquisar', 1)}`"
            class="Barra_Pesquisa"
            single-line
            hide-details
            clearable>
          </v-text-field>
        </v-col>

        <v-container fluid class="Barra_Navegacao elevation-1 mt-2 mx-3">
          <v-row align="center" justify="center">
            <v-bottom-navigation
              v-model="visaoSelecionada"
              height="50px"
              class="elevation-0"
              color="transparent"
              @change="trocarVisao">
              <v-col cols="12" sm="3" v-for="aba in abas" :key="aba.valor" style="background-color: white; text-align: center;" class="pt-1">
                <v-btn text small style="color: #0086ff" :value="aba.valor">
                  <span>{{ aba.titulo }}</span>
                  <v-icon>{{ aba.icon }}</v-icon>
                </v-btn>
              </v-col>
            </v-bottom-navigation>
          </v-row>
        </v-container>

        <v-container fluid grid-list-md class="Container_Cards" v-if="listaCards.length">
          <v-data-iterator
            :items="listaCards"
            :options.sync="pagination"
            :hide-default-footer="12 >= totalPage"
            :server-items-length="totalPage"
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :footer-props="{
              itemsPerPageOptions: rowsPerPageItems,
            }">
            <template v-slot:default="props">
              <v-row justify="start">
                <v-col
                  v-for="item in props.items"
                  :key="item.titulo"
                  cols="12"
                  sm="4"
                  md="3">
                  <apuracao-card-status :item="item" v-if="isVisaoStatus"></apuracao-card-status>
                  <apuracao-card-contrato :item="item" v-else></apuracao-card-contrato>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  listarApuracoesContratoPorStatus,
  listarApuracoesPorContrato,
} from '../../common/resources/apuracao/apuracao-contrato';
import ApuracaoCardStatus from './ApuracaoCardStatus';
import ApuracaoCardContrato from './ApuracaoCardContrato';

export default {
  name: 'ApuracaoContratoNivel1',
  components: {
    ApuracaoCardContrato,
    ApuracaoCardStatus,
  },
  data() {
    return {
      pesquisaCards: '',
      visaoSelecionada: 'Status',
      listaCards: [],
      filtrosAplicados: {},
      rowsPerPageItems: [12],
      pagination: {},
      buscando: false,
      esperarDigitar: false,
      abas: [
        {
          titulo: this.$tc('label.status', 1),
          valor: 'Status',
          icon: 'update',
        },
        {
          titulo: this.$tc('label.contrato', 1),
          valor: 'Contrato',
          icon: 'fa-file-contract',
        },
      ],
    };
  },
  watch: {
    filtroApuracaoContrato: {
      handler() {
        this.filtrosAplicados = this.filtroApuracaoContrato;
        this.buscar();
      },
    },
    pagination: {
      handler() {
        if (this.filtroApuracaoContrato) {
          this.buscar();
        }
      },
      deep: true,
    },
  },
  filters: {
    truncate(value, limit) {
      if (value.length > limit) {
        value = value.substring(0, (limit - 3)).concat('...');
      }
      return value;
    },
  },
  computed: {
    ...mapGetters('filtros', [
      'filtroApuracaoContratoNivel1',
      'filtroApuracaoContrato',
    ]),
    isVisaoStatus() {
      return this.visaoSelecionada === 'Status';
    },
    isVisaoContrato() {
      return this.visaoSelecionada === 'Contrato';
    },
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroApuracaoContratoNivel1',
    ]),
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },
    preencherFiltrosNivel1Vuex() {
      const pagina = this.pagination.page;
      const quantidatePorPagina = this.pagination.itemsPerPage;

      this.setFiltroApuracaoContratoNivel1({
        pagina,
        quantidatePorPagina,
      });
    },
    preencherFiltrosNivel1() {
      this.pagination.page = this.filtroApuracaoContratoNivel1.pagina;
      this.pagination.itemsPerPage = this.filtroApuracaoContratoNivel1.quantidatePorPagina;
    },
    trocarVisao() {
      this.pagination.page = 1;
      this.buscar();
    },
    buscar() {
      if (this.buscando) return;
      this.buscando = true;
      const params = {
        ...this.filtrosAplicados,
        filtro: this.pesquisaCards,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };

      let promise = null;

      if (this.isVisaoContrato) {
        promise = listarApuracoesPorContrato(params, this.$resource);
      } else {
        promise = listarApuracoesContratoPorStatus(params, this.$resource);
      }

      promise.then((response) => {
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.listaCards = response.data.resposta;
        this.rowsPerPageItems = [10, 20, 30, 40, 50, 60, 70];
        setTimeout(this.finalizandoBusca);
        this.preencherFiltrosNivel1Vuex();
      })
        .catch((err) => {
          this.finalizandoBusca();
          this.$error(this, err);
        });
    },

    finalizandoBusca() {
      this.buscando = false;
    },
  },
  beforeMount() {
    if (this.$router.currentRoute.params.visao === 'Contrato') {
      this.visaoSelecionada = 'Contrato';
    }
  },
  mounted() {
    if (this.filtroApuracaoContratoNivel1) {
      this.preencherFiltrosNivel1();
    }

    if (this.filtroApuracaoContrato) {
      this.filtrosAplicados = this.filtroApuracaoContrato;
      this.buscar();
    }
  },
};
</script>

<style>
  .ApuracaoContratoNivel1Agrupado__bottom-nav {
    -webkit-transform: translateY(0px) !important;
    transform: translateY(0px) !important;
  }
  .Barra_Navegacao .v-item-group.v-bottom-nav .v-btn,.Barra_Navegacao .v-item-group.v-bottom-nav .v-btn--active {
    padding: 4px;
  }
  .Barra_Pesquisa {
    width: 33%;
  }
  .clickable {
    cursor: pointer;
  }
  .Card_Apuracao {
    min-height: 146px !important;
    height: 100%;
  }
  .Card_Apuracao .Card_Title, .Card_Apuracao .Card_Content {
    color: #757575;
  }
</style>
