<template>
  <div>
    <v-container pa-0 fluid v-if="breadcrumbs.length > 0">
      <v-row align="center" justify="space-between" class="font-weight-black Barra_Breadcrumbs">
        <v-col cols="12" >
          <v-breadcrumbs :items="breadcrumbs" divider="/">
            <template v-slot:item="props">
              <v-breadcrumbs-item
                :disabled="props.item.disabled"
                :class="{
                    'LiquidacaoNivel_breadcrumbs-item': true,
                    'LiquidacaoNivel_breadcrumbs-item_disabled': props.item.disabled,
                  }"
                @click.native="navegarBreadcrumbs(props.item)">
                {{ props.item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
    </v-container>

    <v-card class="Nivel3">
      <div class="text-xs-center Paginacao_Detalhes" v-if="recebimentos.length && recebimentos.length > 1">
        <v-pagination
          v-model="page"
          class="py-2"
          :length="recebimentos.length"
          :total-visible="10"
          >
        </v-pagination>
        <v-divider></v-divider>
      </div>

      <v-row flex align="start" class="mx-0">
        <v-col cols="12" class="px-0">
          <recebimento-detalhes-resumo :acao="acao" :configuracao="configuracao"/>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-expansion-panels v-model="panel" class="Painel_Detalhes_Acao" multiple accordion>
        <recebimento-detalhes-origem :acao="acao" />
        <recebimento-detalhes-apuracao ref="detalhesApuracao" :acao="acao" :configuracao="configuracao"/>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import RecebimentoDetalhesResumo from './RecebimentoDetalhesResumo';
import RecebimentoDetalhesOrigem from './RecebimentoDetalhesOrigem';
import RecebimentoDetalhesApuracao from './RecebimentoDetalhesApuracao';

export default {
  props: {
    apuracoes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      recebimentoResource: this.$api.recebimento(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),

      page: -1,
      panel: [0, 1],
      acao: {},
      configuracao: {},
      objetoAcao: {},
      visao: '',
      breadcrumbs: {},
      recebimentos: [],
      dashInicial: false,
    };
  },
  components: {
    RecebimentoDetalhesResumo,
    RecebimentoDetalhesOrigem,
    RecebimentoDetalhesApuracao,
  },
  computed: {
  },
  watch: {
    page: {
      handler() {
        this.objetoAcao = this.recebimentos[this.page - 1];
        this.setFiltroLiquidacaoAcaoNivel3(this.objetoAcao);
        this.carregarRecebimento(this.objetoAcao.codRecebimento);
      },
    },
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroLiquidacaoAcaoNivel3',
    ]),
    carregarAcao(idAcao) {
      return this.planejamentoAcaoListagemResource.buscarAcao({ idAcao })
        .then((res) => {
          this.acao = {
            ...this.acao,
            ...res.data.passoGeral.mapaExtensoes,
            ...res.data.passoGeral.mapaCamposDinamicos,
            ...res.data.passoComportamento.mapaExcecoes,
          };
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarRecebimento(idRecebimento) {
      this.recebimentoResource.buscarRecebimento({ idRecebimento })
        .then((res) => {
          this.acao = { ...res.data };
        }).then(() => {
          Promise.all([
            this.carregarConfiguracao(this.acao.idAcao),
            this.carregarAcao(this.acao.idAcao),
            this.preencherDreadcrumbsDash(),
          ]).then(() => {
            this.$refs.detalhesApuracao.formatarObjeto();
          });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    preencherDreadcrumbsDash() {
      if (this.dashInicial) {
        this.breadcrumbs.push({
          nivel: 2,
          disabled: true,
          to: '',
          text: `${this.acao.idAcao} - ${this.acao.idApuracao}`,
        });
      }
    },
    carregarConfiguracao(idAcao) {
      return this.configuracaoResource.buscarConfigSnapshot({ idAcao }).then((res) => {
        this.configuracao = res.data;
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    navegarBreadcrumbs(item) {
      if (item.disabled) {
        return;
      }
      if (item.nivel === 2) {
        this.breadcrumbs.splice(2, 1);
      } else {
        this.breadcrumbs.splice(1, 2);
      }

      this.$router.push({
        name: item.to,
        params: {
          visao: this.visao,
          objetoAcao: this.objetoAcao,
          breadcrumbsParam: this.breadcrumbs,
        },
      });
    },
    recarregar() {
      if (this.$route.params.id) {
        this.carregarRecebimento(this.$route.params.id);
      }
    },
  },
  beforeMount() {
    this.objetoAcao = {
      codRecebimento: this.$route.params.id,
      codAcao: this.$route.params.idAcao,
    };

    this.recebimentos = this.$route.params.liquidacoes
      ? this.$route.params.liquidacoes
      : [this.objetoAcao];

    this.origem = this.$route.params.origem;
    this.dashInicial = this.$route.params.from === 'dashboard-inicial';

    if (this.$route.params.breadcrumbsParam) {
      this.breadcrumbs = this.$route.params.breadcrumbsParam;
    }
  },
  mounted() {
    if (this.objetoAcao.codRecebimento) {
      this.setFiltroLiquidacaoAcaoNivel3(this.objetoAcao);
      if (this.recebimentos && this.recebimentos.length > 0) {
        const recebimento = this.recebimentos
          .filter((aa) => aa.codRecebimento === this.objetoAcao.codRecebimento)[0];
        if (recebimento) {
          this.page = this.recebimentos.indexOf(recebimento) + 1;
        }
      } else {
        this.carregarRecebimento(this.objetoAcao.codRecebimento);
      }
    }
  },
};
</script>
<style lang="scss">
  @import 'src/config/theme/theme.scss';

  .Barra_Breadcrumbs {
    .v-breadcrumbs {
      padding: 0.5em 0 1em 0;
    }
    ul:not(.md-list)>li+li {
      margin-top: 0 !important;
    }
  }
  .Paginacao_Detalhes {
    ul:not(.md-list)>li+li {
      margin-top: 0 !important;
    }
  }
  .Nivel3 .card-header {
    background-color: #fff;
    color: #000;
  }
  .Painel_Detalhes_Acao .v-expansion-panel__header__icon{
    margin-right: 1em;
  }
  .Painel_Detalhes_Acao .v-expansion-panel__header{
    padding:0;
    margin-left: 1.5em;
    min-height: 42px;
  }
  .LiquidacaoNivel_breadcrumbs-item_disabled {
    color: grey;
    pointer-events: none;
  }
  .LiquidacaoNivel_breadcrumbs-item {
    color: grey;
    cursor: pointer;
  }
  .LiquidacaoNivel_breadcrumbs-item:hover {
    color: #3f3f3f;
  }
</style>
