<template>
  <div>
    <v-container pa-0 fluid v-if="breadcrumbs.length > 0">
      <v-row align="center" justify="space-between" class="font-weight-black Barra_Breadcrumbs">
        <v-col cols="12">
          <v-breadcrumbs :items="breadcrumbs" divider="/">
            <v-breadcrumbs-item
                slot="item"
                slot-scope="{ item }"
                :to="item.to"
                :key="item.to"
                :disabled="item.disabled"
                @click.native="navegarBreadcrumbs(item)">
              {{ item.text }}
            </v-breadcrumbs-item>
          </v-breadcrumbs>
        </v-col>
      </v-row>
    </v-container>
    <v-card class="Nivel3">
      <div class="text-xs-center Paginacao_Detalhes" v-if="apuracoes.length > 1">
        <v-pagination
            v-model="page"
            class="py-2"
            :length="apuracoes.length"
            :total-visible="totalVisible">
        </v-pagination>
        <v-divider></v-divider>
      </div>

      <v-row flex align="start" class="mx-0">
        <v-col cols="12" class="px-0">
          <apuracao-acao-detalhes-resumo ref="detalhesResumo"
                                         :acao="acao"
                                         :configuracao="configuracao">
            <div slot="depoisTitulo">
              <apuracao-acao-fluxo ref="fluxoApuracao"
                                   v-if="!!acao.idAcao"
                                   :observacaoAcao="acao.observacaoAcao"
                                   :indJustificativaAnalise="acao.indJustificativaAnalise"
                                   :indHabilitaFluxo="acao.indHabilitaFluxo"
                                   :indHabilitaEdicaoAprovacao="acao.indHabilitaEdicaoAprovacao"
                                   :perguntarProximo="page < apuracoes.length"
                                   :acao="acao"
                                   :idAcao="acao.idAcao"
                                   :salvar-antes-solicitar-aprovacao="true"
                                   :exibir-solicitar-aprovacao="evidenciaObrigatoriaEnviada && possuiPagamentoManualAprovado"
                                   :exibir-concluir-aprovacao="evidenciaObrigatoriaEnviada && possuiPagamentoManualAprovado"
                                   :justificativaAlteracaoValorPagamento="justificativaAlteracaoValorPagamento"
                                   :indHabilitarJustificativaAlterarValor="indHabilitarJustificativaAlterarValor"
                                   :valorAtualApuracao="valorAtualApuracao"
                                   @recarregar="recarregar"
                                   @ApuracaoAcaoFluxo_irProximo="page++"
                                   @ApuracaoFluxo__AusenciaSaldo="exibirMensagemSaldo"/>
            </div>
          </apuracao-acao-detalhes-resumo>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-expansion-panels v-model="panel" class="Painel_Detalhes_Acao" multiple accordion>
        <apuracao-acao-detalhes-origem :acao="acao" v-if="indModoVarejo"/>
        <apuracao-acao-detalhes-foco :acao="acao" :campo-cliente="campoCliente" v-else/>
        <apuracao-acao-detalhes-apuracao
          ref="detalhesApuracao"
          :acao="acao"
          :configuracao="configuracao"
          :justificativaAlteracaoValorPagamento="justificativaAlteracaoValorPagamento"
          @ApuracaoDetalhes__AusenciaSaldo="exibirMensagemSaldo"
          @ApuracaoDetalhes__AlteracaoJustificativa="justificativaAlteracaoValorPagamentoAlterada"
          @ApuracaoDetalhes__ApuracaoAlterada="apuracaoAlterada"/>
        <apuracao-acao-complemento-informacoes
            v-if="visualizacaoComplementoInformacoes
           && metadadosComplementoInformacoes
            && exibirConteudo"
            ref="complementoInformacoes"
            :metadados="metadadosComplementoInformacoes"
            :acao="acao"
            :somenteLeitura="!podeAlterarComplementoInformacoes"
            :configuracao="configuracao"/>
        <apuracao-acao-detalhes-pagamento
            ref="detalhesPagamento"
            v-if="indHabilitarPagamentoManual && metadadosPagamentoManual && exibirConteudo"
            :acao="acao"
            :configuracao="configuracao"
            :metadados="metadadosPagamentoManual"
            :somenteLeitura="!podeAlterarPagamento"
            @PagamentoManual__alterado="atualizaSePagamentoManualAprovado"/>
        <apuracao-acao-detalhes-infor-acao-previa
            v-if="exibirConteudo && acao.idAcaoTradePrevia "
            ref="informacaoAcaoPrevia"
            :acao="acao"
            :configuracao="configuracao"/>

        <component v-for="(p, index) in componentesPaineis" :key="index"
                   :is="p.componente"
                   :ref="p.nome"
                   :acao="acao"
                   v-if="exibirConteudo"
                   :configuracao="configuracao">
        </component>

        <apuracao-acao-arquivos
            :acao="acao"
            :configuracao="configuracao"
            v-if="indHabilitarEvidencia && exibirConteudo"
            @ApuracaoAcaoArquivos_itensAlterados="listaArquivosAlterados"
            @ApuracaoAcaoArquivos_uploadSucesso="uploadSucesso"
            @ApuracaoAcaoArquivos_arquivosRemovidos="arquivosRemovidos"></apuracao-acao-arquivos>
      </v-expansion-panels>
    </v-card>
    <v-col style="text-align:right">
      <v-btn dark
             color="primary" v-if="podeAlterarValores || podeAlterarComplementoInformacoes"
             @click="confirmaSalvar">{{ $t('label.salvar') }}
      </v-btn>
    </v-col>
    <confirm
        ref="confirmSalvar"
        :message="$t('message.deseja_salvar_pedido')"
        :persistent="true"
        @agree="salvar"/>
    <confirm
        ref="confirmValorMenorUtilizado"
        hide-disagree
        :agree-label="$t('label.ok')"
        :message="mensagemValorMenorUtilizado"/>
    <v-dialog
        v-model="exibeMensagemSaldo"
        max-width="600">
      <v-card>
        <v-card-title class="headline">{{ $t('title.atencao') }}</v-card-title>
        <v-card-text>
          {{ this.$t('message.saldo_insuficiente_conta', dadosValidacaoSaldo) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 text
                 @click="() => exibeMensagemSaldo = false">{{ $t('label.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ApuracaoAcaoFluxo from '@/spa/apuracao-acao/ApuracaoAcaoFluxo';
import ApuracaoAcaoDetalhesInforAcaoPrevia from '@/spa/apuracao-acao/ApuracaoAcaoDetalhesInforAcaoPrevia';
import apuracaoDefinicaoPaineis from '@/spa/apuracao-acao/apuracaoDefinicaoPaineis';
import ApuracaoAcaoDetalhesResumo from './ApuracaoAcaoDetalhesResumo';
import ApuracaoAcaoDetalhesOrigem from './ApuracaoAcaoDetalhesOrigem';
import ApuracaoAcaoDetalhesApuracao from './ApuracaoAcaoDetalhesApuracao';
import ApuracaoAcaoDetalhesFoco from './ApuracaoAcaoDetalhesFoco';
import ApuracaoAcaoDetalhesPagamento from './ApuracaoAcaoDetalhesPagamento';
import ApuracaoAcaoArquivos from './ApuracaoAcaoArquivos';
import ApuracaoAcaoComplementoInformacoes from './ApuracaoAcaoComplementoInformacoes';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import { getMoney, copyObject } from '../../common/functions/helpers';
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  name: 'ApuracaoAcaoDetalhes',
  components: {
    ApuracaoAcaoDetalhesInforAcaoPrevia,
    ApuracaoAcaoArquivos,
    ApuracaoAcaoDetalhesFoco,
    ApuracaoAcaoFluxo,
    ApuracaoAcaoDetalhesResumo,
    ApuracaoAcaoDetalhesOrigem,
    ApuracaoAcaoDetalhesApuracao,
    ApuracaoAcaoDetalhesPagamento,
    ApuracaoAcaoComplementoInformacoes,
    Confirm,
    ...apuracaoDefinicaoPaineis.componentes,
  },
  data() {
    return {
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoListagem(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      metadadoResource: this.$api.metadado(this.$resource),

      metadadosComplementoInformacoes: null,
      metadadosPagamentoManual: null,
      solicitanteFluxo: false,
      page: -1,
      panel: [0, 1, 2],
      acao: {},
      configuracao: {},
      configuracaoCarregada: false,
      mensagemValorMenorUtilizado: '',
      breadcrumbs: {},
      apuracoes: [],
      objetoAcao: null,
      labelCampoCliente: null,
      justificativaAlteracaoValorPagamento: null,
      dadosValidacaoSaldo: null,
      exibeMensagemSaldo: false,
      valorAtualApuracao: null,
      possuiPagamentoAprovado: false,
      exibirConteudo: false,
      apuracaoDefinicaoPaineis,
    };
  },
  computed: {
    ...generateComputed('APU_ACAO', [
      'canEdit',
    ]),
    ...generateComputed('APU_CONTR', [
      'canAccessCRUD',
    ]),
    paineisDinamicos() {
      return apuracaoDefinicaoPaineis
        .paineisHabilitados(this, this.configuracao, this.acao);
    },
    componentesPaineis() {
      return Object.keys(this.paineisDinamicos)
        .filter((nome) => this.paineisDinamicos[nome].habilitado)
        .map((nome) => ({
          nome,
          componente: this.paineisDinamicos[nome].componente,
        }));
    },
    totalVisible() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 5;
      }
      return 12;
    },
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...mapGetters('metadados', [
      'getApuracaoMetadado',
      'getPagamentoMetadado',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    podeEditar() {
      return this.canEdit || this.canAccessCRUD;
    },
    podeAlterarValores() {
      return this.podeEditar && this.solicitanteFluxo
          && (this.acao.statusApuracao === 'AGUARDANDO_APURACAO' || this.acao.statusApuracao === 'EM_ANALISE')
          && !this.acao.isFluxoParalelo;
    },
    podeAlterarComplementoInformacoes() {
      if (this.acao.temWorkflow) {
        return this.podeEditar && this.solicitanteFluxo
            && (this.acao.statusApuracao === 'AGUARDANDO_APURACAO' || this.acao.statusApuracao === 'EM_ANALISE')
            && !this.acao.isFluxoParalelo;
      }

      return this.podeEditar
          && (this.acao.statusApuracao === 'AGUARDANDO_APURACAO');
    },
    podeAlterarPagamento() {
      return this.podeEditar
          && this.acao.statusApuracao === 'AGUARDANDO_APURACAO'
          && !this.acao.isFluxoParalelo;
    },
    indHabilitarEvidencia() {
      if (!this.configuracao || !this.configuracao.passo5
          || !this.configuracao.passo5.apuracao) {
        return false;
      }
      return this.configuracao.passo5.apuracao.indHabilitarEvidencia;
    },
    visualizacaoComplementoInformacoes() {
      if (!this.configuracao || !this.configuracao.passo3
          || !this.configuracao.passo3.listaDinamica) {
        return false;
      }
      return this.configuracao.passo3.listaDinamica
        .filter((campo) => campo.etapasFluxo
              && campo.etapasFluxo.filter((etapa) => etapa === 'APURACAO').length > 0).length > 0;
    },
    indHabilitarJustificativaAlterarValor() {
      if (!this.configuracao || !this.configuracao.passo5
          || !this.configuracao.passo5.apuracao) {
        return false;
      }
      return this.configuracao.passo5.apuracao.indJustificativaAlterarValor;
    },
    indEvidenciaManualObrigatorio() {
      return this.indHabilitarEvidencia && this.configuracao.passo5.apuracao.indManual
          && this.configuracao.passo5.apuracao.indEvidencia;
    },
    indEvidenciaSelloutObrigatorio() {
      return this.indHabilitarEvidencia && (this.acao.calculoApuracao || '').startsWith('SELLOUT')
          && this.configuracao.passo5.apuracao.indEvidenciaSellout;
    },
    indEvidenciaSellinObrigatorio() {
      return this.indHabilitarEvidencia && (this.acao.calculoApuracao || '').startsWith('SELLIN')
          && this.configuracao.passo5.apuracao.indEvidenciaSellin && !this.acao.indApurado;
    },
    indHabilitarPagamentoManual() {
      if (!this.configuracao || !this.configuracao.passo6
          || !this.configuracao.passo6.liquidacao) {
        return false;
      }
      return this.configuracao.passo6.liquidacao.indLiquidacaoManual;
    },
    possuiPagamentoManualAprovado() {
      return !this.indHabilitarPagamentoManual
          || (this.indHabilitarPagamentoManual && this.possuiPagamentoAprovado);
    },
    evidenciaObrigatoriaEnviada() {
      return (this.acao.temEvidencia && (this.indEvidenciaManualObrigatorio
          || this.indEvidenciaSelloutObrigatorio || this.indEvidenciaSellinObrigatorio))
          || (!this.indEvidenciaManualObrigatorio
              && !this.indEvidenciaSelloutObrigatorio && !this.indEvidenciaSellinObrigatorio);
    },
    campoCliente() {
      if (!this.configuracao.passo3) {
        return '';
      }
      const { listaCliente } = this.configuracao.passo3;
      const { campoCliente } = (listaCliente || []).find((el) => el.label === 'campo_cliente') || {};
      return (campoCliente || {}).label || '';
    },
  },
  watch: {
    page: {
      handler() {
        this.objetoAcao = this.apuracoes[this.page - 1];
        this.setFiltroApuracaoAcaoNivel3(this.objetoAcao);
        this.carregarApuracao(this.objetoAcao.cod_apuracao);
      },
    },
    acao(val) {
      this.bloquearBotaoExportacao = false;
      if (val && val.statusApuracao && val.idApuracao) {
        this.verificarUsuarioSolicitante(val.idApuracao, val.statusApuracao);
      }
    },
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroApuracaoAcaoNivel3',
    ]),
    ...mapActions('metadados', [
      'setApuracaoMetadado',
      'setPagamentoMetadado',
    ]),
    confirmaSalvar() {
      if (this.$refs.detalhesApuracao.validarForm()) {
        if (this.$refs.detalhesApuracao.temValorUtilizado()
            && this.$refs.detalhesApuracao.valorMenorQueValorUtilizado()) {
          this.mensagemValorMenorUtilizado = this.$t('message.valor_menor_ja_utilizado',
            { valor: getMoney(this.acao.vlrUtilizado) });
          this.$refs.confirmValorMenorUtilizado.open();
          return;
        }

        if (this.$refs.detalhesApuracao.vinculoUnico) {
          this.$refs.detalhesApuracao.validaSaldoContaUnica()
            .then((saldoSuficiente) => {
              if (saldoSuficiente) {
                this.$refs.confirmSalvar.open();
              }
            });
          return;
        }

        setTimeout(() => this.$refs.confirmSalvar.open());
      }
    },
    salvar() {
      this.$refs.detalhesApuracao.salvar();
      if (this.visualizacaoComplementoInformacoes) {
        this.$refs.complementoInformacoes.salvar();
      }
    },
    verificarUsuarioSolicitante(idApuracao, status) {
      if (status === 'AGUARDANDO_APURACAO' || status === 'EM_ANALISE') {
        this.workflowApuracaoAcaoResource.solicitante({ idApuracao })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
          });
      }
    },
    listaArquivosAlterados(payload) {
      this.acao.temEvidencia = payload;
    },
    exibirMensagemSaldo(dados) {
      this.dadosValidacaoSaldo = dados;
      this.exibeMensagemSaldo = true;
    },
    justificativaAlteracaoValorPagamentoAlterada(novoTexto) {
      this.justificativaAlteracaoValorPagamento = novoTexto;
    },
    apuracaoAlterada(vlrConfApuracao = 0) {
      this.valorAtualApuracao = vlrConfApuracao;
    },
    carregarAcao(idAcao) {
      this.exibirConteudo = false;
      return this.planejamentoAcaoListagemResource.buscarAcao({ idAcao })
        .then((res) => {
          this.acao = {
            ...this.acao,
            ...res.data.passoGeral.mapaExtensoes,
            ...res.data.passoGeral.mapaCamposDinamicos,
            ...res.data.passoComportamento.mapaExcecoes,
            justificativaCancelamento: res.data.passoGeral.justificativaCancelamento,
            usuarioCancelamento: res.data.passoGeral.usuarioCancelamento,
            dtaCancelamento: res.data.passoGeral.dtaCancelamento,
            idAcaoTradePrevia: res.data.idAcaoTradePrevia,
          };
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    atualizarTela() {
      this.setMetadadosPagamentoManual();
      this.alterarPanel();
    },
    uploadSucesso() {
      this.acao.temEvidencia = true;
      this.$forceUpdate();
    },
    arquivosRemovidos() {
      this.acao.temEvidencia = false;
    },
    alterarPanel() {
      const paineis = [0, 1];
      let painel = 1;

      if (this.visualizacaoComplementoInformacoes) {
        painel += 1;
      }

      if (this.indHabilitarPagamentoManual) {
        painel += 1;
        paineis.push(painel);
      }

      if (this.acao.idAcaoTradePrevia) {
        painel += 1;
      }

      const paineisDinamicos = Object
        .keys(this.paineisDinamicos)
        .filter((nome) => this.paineisDinamicos[nome].habilitado);

      for (let i = 0; i < paineisDinamicos.length; i += 1) {
        painel += 1;
        if (this.paineisDinamicos[paineisDinamicos[i]].iniciaExpandido) {
          paineis.push(painel);
        }
      }

      if (this.acao.temEvidencia) {
        painel += 1;
        paineis.push(painel);
      }

      this.panel = paineis;

      this.exibirConteudo = true;
    },
    carregarApuracao(idApuracao) {
      this.buscarJustificativaAlteracaoValor(idApuracao);
      this.apuracaoAcaoListagemResource.buscarApuracao({ idApuracao })
        .then((res) => {
          this.acao = { ...res.data };
          this.valorAtualApuracao = this.acao.vlrConfApuracao;
        }).then(() => {
          Promise.all([
            this.carregarConfiguracao(this.acao.idAcao),
            this.carregarAcao(this.acao.idAcao),
          ])
            .then(this.atualizarTela)
            .then(() => {
              this.$refs.detalhesApuracao.formatarObjeto();
              this.$refs.detalhesResumo.formatarObjeto();
              setTimeout(() => this.$refs.fluxoApuracao.carregaInformacoesFluxo());
            });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarConfiguracao(idAcao) {
      return this.configuracaoResource.buscarConfigSnapshot({ idAcao }).then((res) => {
        this.configuracao = res.data;
        this.configuracaoCarregada = true;
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarJustificativaAlteracaoValor(idApuracao) {
      this.apuracaoAcaoResource.buscarJustificativaAlteracaoValor({ idApuracao })
        .then((res) => {
          this.justificativaAlteracaoValorPagamento = res.data.justificativa;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    atualizaSePagamentoManualAprovado(possuiPagamentoAprovado = false) {
      this.possuiPagamentoAprovado = possuiPagamentoAprovado;
    },
    navegarBreadcrumbs(item) {
      if (item.disabled) {
        return;
      }
      if (item.nivel === 2) {
        this.breadcrumbs.splice(2, 1);
      } else {
        this.breadcrumbs.splice(1, 2);
      }

      this.$router.push({
        name: item.to,
        params: {
          breadcrumbsParam: this.breadcrumbs,
        },
      });
    },
    recarregar(novoStatus = null) {
      if (this.$route.params.status != null && novoStatus != null) {
        const params = { ...this.$route.params };
        params.status = novoStatus;
        params.id = this.objetoAcao.cod_apuracao;
        params.idAcao = this.objetoAcao.cod_acao;
        this.apuracoes = [this.objetoAcao];
        this.$router.push({ name: 'detalharApuracaoAcaoStatus', params });
        setTimeout(() => window.location.reload());
        return;
      }
      if (this.$route.params.id) {
        this.carregarApuracao(this.$route.params.id);
      }
    },
    montaBreadcrumbsNotaDebito() {
      this.breadcrumbs = [{
        text: this.$tc('title.nota_debito', 2),
        to: 'notas-debito-consulta',
        disabled: false,
      }, {
        text: this.$tc('label.detalhe', 1),
        disabled: true,
      }];
    },
    montaBreadcrumbsDashboardInicial() {
      this.breadcrumbs = [{
        text: this.$tc('label.dashboard_inicial', 2),
        to: 'inicio',
        disabled: false,
      }, {
        text: this.$tc('label.detalhe', 1),
        disabled: true,
      }];
    },
    carregarSeUsuarioPossuirAcesso(idApuracao) {
      this.apuracaoAcaoListagemResource.verificarSeUsuarioPossuiAcesso({ idApuracao })
        .then((res) => {
          if (res.data) {
            this.carregarPagina();
          } else {
            this.$router.push({ name: 'inicio' });
            this.$toast('Acesso negado');
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    carregarPagina() {
      this.setFiltroApuracaoAcaoNivel3(this.objetoAcao);
      if (this.apuracoes && this.apuracoes.length > 0) {
        const apuracao = this.apuracoes
          .filter((aa) => aa.cod_apuracao === this.objetoAcao.cod_apuracao)[0];
        if (apuracao) {
          this.page = this.apuracoes.indexOf(apuracao) + 1;
        }
      } else {
        this.carregarApuracao(this.objetoAcao.cod_apuracao);
      }
    },
    setMetadadosComplementoInformacoes() {
      if (this.getApuracaoMetadado) {
        this.metadadosComplementoInformacoes = copyObject(this.getApuracaoMetadado);
      }
    },
    setMetadadosPagamentoManual() {
      if (this.getPagamentoMetadado) {
        this.metadadosPagamentoManual = copyObject(this.getPagamentoMetadado);
      }
    },
  },
  beforeMount() {
    this.objetoAcao = {
      cod_apuracao: this.$route.params.id,
      cod_acao: this.$route.params.idAcao,
    };

    this.acao = {
      idApuracao: parseInt(this.$route.params.id, 10),
      idAcao: parseInt(this.$route.params.idAcao, 10),
    };

    this.apuracoes = this.$route.params.apuracoes
      ? this.$route.params.apuracoes
      : [this.objetoAcao];

    this.origem = this.$route.params.origem;

    if (this.$route.params.breadcrumbsParam) {
      this.breadcrumbs = this.$route.params.breadcrumbsParam;
    } else if (this.origem && this.origem === 'NOTA_DEBITO') {
      this.montaBreadcrumbsNotaDebito();
    } else if (this.origem && this.origem === 'dashboard-inicial') {
      this.montaBreadcrumbsDashboardInicial();
    }
    this.setApuracaoMetadado({
      resource: this.metadadoResource.listarApuracao,
      params: null,
      callback: this.setMetadadosComplementoInformacoes,
    });
    this.setPagamentoMetadado({
      resource: this.metadadoResource.definicaoAcao,
      params: null,
      callback: this.setMetadadosPagamentoManual,
    });
  },
  mounted() {
    if (this.objetoAcao.cod_apuracao) {
      const idApuracao = this.objetoAcao.cod_apuracao,
        { from } = this.$route.params;
      if (!from) {
        this.carregarSeUsuarioPossuirAcesso(idApuracao);
      } else {
        this.carregarPagina();
      }
    }
  },
};
</script>
<style lang="scss">
@import 'src/config/theme/theme.scss';

.Barra_Breadcrumbs {
  .v-breadcrumbs {
    padding: 0.5em 0 1em 0;
  }

  ul:not(.md-list) > li + li {
    margin-top: 0 !important;
  }
}

.Paginacao_Detalhes {
  ul:not(.md-list) > li + li {
    margin-top: 0 !important;
  }
}

.Nivel3 .card-header {
  background-color: #fff;
  color: #000;
}

.Painel_Detalhes_Acao .v-expansion-panel__header__icon {
  margin-right: 1em;
}

.Painel_Detalhes_Acao .v-expansion-panel__header {
  padding: 0;
  margin-left: 1.5em;
  min-height: 42px;
}
</style>
