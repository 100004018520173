import getBasePath from '../functions/api-resource';

const basePath = getBasePath('orcamento', 'orcamento');

const orcamentoActions = {
  buscarConfigTiposVerba: { methods: 'GET', url: `${basePath}/configuracao` },
  buscarConfigVigente: { methods: 'GET', url: `${basePath}/configuracao/vigente/{idTipoVerba}` },
  buscarConfigSnapshot: { methods: 'GET', url: `${basePath}/configuracao/snapshot/{idOrcamento}` },
  buscarCamposGrid: { methods: 'GET', url: `${basePath}/configuracao/campos/grid/{idTipoVerba}` },
  buscarCamposFiltro: { methods: 'GET', url: `${basePath}/configuracao/campos/filtro` },
};

export default (resource) => resource(`${basePath}`, {}, orcamentoActions);
