<template>
  <div class="RelatorioDPM">
    <relatorio-dpm-filtros
      ref="filtros"
      @RelatorioDPMFiltros__AplicaFiltros="aplicaFiltros"
    />
  </div>
</template>
<script>
import RelatorioDpmFiltros from './RelatorioDPMFiltros';
import { removeEmptyProperties } from '../../../../produto/common/functions/helpers';
import exportacao from '../../../../produto/common/functions/exportacao';

export default {
  components: {
    RelatorioDpmFiltros,
  },
  data() {
    return {
      filtros: null,
    };
  },
  methods: {
    aplicaFiltros(filtrosAplicados = null) {
      if (!filtrosAplicados) {
        return;
      }
      this.filtros = {
        ...filtrosAplicados,
      };
      this.requestExportacao(this.filtros);
    },
    requestExportacao(filtros) {
      const filtroTratado = removeEmptyProperties(filtros);
      exportacao.exportar(null, 'relatorio_dados_dpm_adama', this, filtroTratado, 1000, 240000);
    },
  },
};
</script>
<style>
.RelatorioDPM {
  padding-bottom: 60px;
}
.RelatorioDPM__Modal .headline {
  width: 100%;
  text-align: center;
}
.RelatorioDPM__Lista {
  margin-top: 20px;
}
</style>
