import getBasePath from '../functions/api-resource';

const basePath = getBasePath('execucao', 'execucao');

const execucaoActions = {
  listar: { method: 'GET', url: `${basePath}/meta/{id}` },
  remover: { method: 'DELETE', url: `${basePath}/{idExecucao}` },
};

export default (resource) => resource(`${basePath}`, {}, execucaoActions);
