import getBasePath from '../../functions/api-resource';
import resourceBuilder from '../../functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', '');

const planejamentoAcaoActions = {
  buscarAcao: { methods: 'GET', url: `${basePath}carregamento/{idAcao}` },
  buscarCategoriaAcao: { methods: 'GET', url: `${basePath}listagem/categoria-acao` },
  buscarStatusAtualizado: { methods: 'GET', url: `${basePath}carregamento/status/{idAcao}` },
  verificarSeUsuarioPossuiAcesso: { methods: 'GET', url: `${basePath}listagem/acesso/{idAcao}` },
  buscarFornecedoresPagadoresVinculados: { method: 'GET', url: `${basePath}/listagem/fornecedor-pagador-vinculado` },
};

export default (resource) => resource(`${basePath}`, {}, planejamentoAcaoActions);

export const buscarTiposAcao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/tipo_acao', parametros).doGet();

export const buscarAcoes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/tipo_acao/acoes', parametros).doGet();
