<template>
  <v-container grid-list-md pb-0 fluid>
    <metadados-container-layout
      v-if="parametrosAplicados"
      :metadados-entidade="metadadosParametrizado"
      :layout-class="layoutClass"
      :input-layout="inputLayout"
      :objeto="orcamento"
      :campos-formulario="camposFormulario"
      :ordenacao-campos="ordenacaoFormulario"
      :explode-hierarquia="true"
      :somente-leitura="somenteLeitura"
      @MetadadosContainerLayout_valorAlterado="valorAlteradoResumo"
      ref="container">
    </metadados-container-layout>
    <v-row v-show="!somenteLeitura">
      <v-col cols="12">
        <v-row justify="end">
          <v-btn
            @click="aplicaPeriodo"
            class="mr-3"
            :disabled="!permiteAplicarPeriodo"
            color="accent">
            {{$tc('label.aplicar_periodo', 1)}}
            <v-icon right>refresh</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import MetadadosContainerLayout from '../../shared-components/metadados/MetadadosContainerLayout';
import { buscarProdutosSelecao } from '../../common/resources/produto';

export default {
  name: 'OrcamentoFormResumo',
  components: {
    MetadadosContainerLayout,
  },
  props: {
    edicao: Boolean,
    somenteLeitura: Boolean,
    orcamentoEdicao: Object,
    tipoVerba: Object,
    configuracao: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('metadados', [
      'getOrcamentoMetadado',
      'getProdutoMetadado',
      'getClienteMetadado',
      'getCentroCustoMetadado',
    ]),
    idTipoVerba() {
      return this.tipoVerba.id;
    },
  },
  watch: {
    'orcamento.planejado': function orcamentoPlanejado(novoValor) {
      const { totalDistribuido } = this.orcamento;
      this.calculaValorRemanescente(novoValor, totalDistribuido);
      setTimeout(() => this.notificaAlteracaoPlanejado(novoValor));
    },
  },
  data() {
    return {
      anoFiscalResource: this.$api.anoFiscal(this.$resource),
      centroCustoResource: this.$api.centroCusto(this.$resource),
      clienteResource: this.$api.cliente(this.$resource),
      produtoResource: this.$api.produto(this.$resource),

      parametrosAplicados: false,
      metadadosParametrizado: null,
      permiteAplicarPeriodo: false,

      orcamento: {
        status: this.$t('status_entidade.em_cadastro'),
        totalDistribuido: 0,
      },

      periodoPendente: false,

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      ordenacaoFormulario: this.configuracao.colunasResumo,
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'descricao',
            tipoCampo: 'TEXTO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'periodo',
            nomCampoId: 'idPeriodo',
            tipoCampo: 'LISTA',
            vlrObrigatorio: true,
            desAtributos: {
              dependentes: ['periodo_orcamentario'],
            },
            async: {
              fetchFunction: (autocomplete) => {
                const { idTipoVerba } = this;
                return this.anoFiscalResource.buscar({ idTipoVerba, autocomplete });
              },
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'periodo_orcamentario',
            nomCampoId: 'idsPeriodosOrcamentarios',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            desAtributos: {
              dependencias: ['periodo'],
            },
            async: {
              fetchFunction: (autocomplete) => {
                const idAnoFiscal = this.orcamento.periodo.id,
                  tipo = 'PLANEJAMENTO_ORCAMENTARIO',
                  valido = true;
                return this.anoFiscalResource.buscarPeriodosAnoFiscal({
                  idAnoFiscal,
                  tipo,
                  autocomplete,
                  valido,
                });
              },
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'planejado',
            tipoCampo: 'DINHEIRO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'status',
            tipoCampo: 'TEXTO',
            vlrObrigatorio: true,
            desAtributos: {
              desabilitado: true,
            },
          },
          {
            labelCampo: 'remanescente',
            tipoCampo: 'DINHEIRO',
            desAtributos: {
              desabilitado: true,
            },
          },
          {
            labelCampo: 'centro_custo',
            nomCampoId: 'idCentroCusto',
            tipoCampo: 'LISTA',
            desAtributos: {},
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresDependencias(),
                };
                return this.centroCustoResource.selecao(parametros);
              },
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'cliente',
            nomCampoId: 'idCliente',
            tipoCampo: 'LISTA',
            desAtributos: {},
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresDependencias(),
                };
                return this.clienteResource.selecao(parametros);
              },
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'produto',
            nomCampoId: 'idProduto',
            tipoCampo: 'LISTA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresDependencias(),
                };
                return buscarProdutosSelecao(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'codigoNome',
            },
          },
        ],
      },
    };
  },
  methods: {
    notificaAlteracaoPlanejado(planejado) {
      this.$emit('OrcamentoFormResumo__PlanejadoAlterado', planejado);
    },
    atualizaValorRemanescente(totalDistribuido) {
      this.orcamento.totalDistribuido = totalDistribuido;
      const { planejado } = this.orcamento;
      this.calculaValorRemanescente(planejado, totalDistribuido);
      this.$refs.container.refresh();
    },
    calculaValorRemanescente(planejado, totalDistribuido) {
      const remanescente = planejado - totalDistribuido;
      this.orcamento.remanescente = remanescente < 0 ? 0 : remanescente;
    },
    verificaHabilitacaoPorPeriodo(valor) {
      const { colunasResumo } = this.configuracao;
      if (colunasResumo.indexOf('periodo_orcamentario') >= 0) {
        this.permiteAplicarPeriodo = valor == null
          ? false
          : this.permiteAplicarPeriodo;
        return;
      }
      this.permiteAplicarPeriodo = valor != null;
    },
    verificaHabilitacaoPorPeriodoOrcamentario(valor = []) {
      this.permiteAplicarPeriodo = valor.length > 0;
    },
    valorAlteradoResumo(alteracao) {
      const { label, valor } = alteracao;
      if (label === 'periodo') {
        this.verificaHabilitacaoPorPeriodo(valor);
      } else if (label === 'periodo_orcamentario') {
        this.verificaHabilitacaoPorPeriodoOrcamentario(valor);
        this.$emit('OrcamentoFormResumo__PeriodoPendente');
      }
    },
    aplicaPeriodo() {
      const periodosOrcamentarios = this.orcamento.periodo_orcamentario
        .sort((a, b) => a.competenciaInicio - b.competenciaInicio);
      this.$emit('OrcamentoFormResumo__AplicaPeriodo', periodosOrcamentarios);
    },
    getValoresAutocomplete() {
      return this.$refs.container.getValoresAutocomplete();
    },
    getObjetoResumo() {
      const { idTipoVerba } = this;
      const { idConfiguracao } = this.configuracao;
      const { id } = this.orcamento;

      const resumo = {
        id,
        ...this.$refs.container.getValoresCamposPadrao(),
        idTipoVerba,
        idConfiguracao,
      };

      this.formataExtensoes(resumo);
      this.formataCamposDinamicos(resumo);

      return resumo;
    },
    formataExtensoes(resumo) {
      resumo.mapaExtensoes = {
        ...this.$refs.container.getValoresDependencias(),
      };
    },
    formataCamposDinamicos(resumo) {
      const camposDinamicos = this.$refs.container.getValoresCamposDinamicos();
      if (Object.keys(camposDinamicos).length !== 0) {
        resumo.mapaCamposDinamicos = { ...camposDinamicos };
      }
    },
    montaObjetoOrcamento() {
      this.orcamento = {
        ...this.orcamentoEdicao,
        ...this.orcamentoEdicao.mapaExtensoes,
        ...this.orcamentoEdicao.mapaCamposDinamicos,
      };
      const { status } = this.orcamento;
      this.orcamento.totalDistribuido = 0;
      this.orcamento.status = this.$tc(`status_entidade.${status.toLowerCase()}`, 1);
    },
    configuraMetadadoCruzados() {
      const configuracoes = [
        {
          entidade: 'cliente',
          metadadosCampo: this.getClienteMetadado,
        },
        {
          entidade: 'produto',
          metadadosCampo: this.getProdutoMetadado,
        },
        {
          entidade: 'centro_custo',
          metadadosCampo: this.getCentroCustoMetadado,
        },
      ];

      configuracoes.forEach((cfg) => {
        const campos = this.camposFormulario.padrao;
        const campo = campos.filter((c) => c.labelCampo === cfg.entidade)[0];
        campo.metadadosCampo = cfg.metadadosCampo;
      });
    },
  },
  mounted() {
    const metadados = this.getOrcamentoMetadado;
    this.metadadosParametrizado = this.configuracao.aplicaParametrizacaoResumo(metadados);
    this.configuracao
      .parametrizaCamposOpcionais(this.camposFormulario.padrao, 'RESUMO');

    if (this.edicao) {
      this.montaObjetoOrcamento();
    }
    this.configuraMetadadoCruzados();
    this.parametrosAplicados = true;
  },
};
</script>
