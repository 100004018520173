import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('execucao_acao_tatica', 'reporte-tatico');

const reporteTaticoActions = {
  ...genericActions(basePath),
  listarFiltrado: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}&filtro={filter}` },
  buscar: { method: 'GET', url: `${basePath}/{id}` },
  buscarPelaAcao: { method: 'GET', url: `${basePath}/acao/{id}` },
  removerArquivo: { method: 'DELETE', url: `${basePath}/arquivo/{tipo}` },
  getThumb: { method: 'GET', url: `${basePath}/thumb` },
};

export default (resource) => resource(`${basePath}`, {}, reporteTaticoActions);
