var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',{staticClass:"SimulacaoDpmGrupoCulturasPainel"},[_c('v-expansion-panel-header',{staticClass:"pb-0 pt-0"},[_c('span',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.grupo.grupoCultura)+" ")])]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.cabecalho,"items":_vm.grupo.marcas,"dense":"","disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.pontoUnitario",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontoUnitario))+" ")]}},{key:"item.volumeMeta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.volumeMeta))+" ")]}},{key:"item.volumeFaturado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.volumeFaturado))+" ")]}},{key:"item.volumeFatCart",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.volumeFatCart))+" ")]}},{key:"item.volumeSimulado",fn:function(ref){
var item = ref.item;
return [_c('input-decimal',{staticClass:"py-0 mt-0 mb-1",attrs:{"single-line":"","hide-details":"","dense":"","align-right":""},on:{"input":function($event){return _vm.volumeSimuladoAlterado(item)}},model:{value:(item.volumeSimulado),callback:function ($$v) {_vm.$set(item, "volumeSimulado", $$v)},expression:"item.volumeSimulado"}})]}},{key:"item.pontosMeta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontosMeta))+" ")]}},{key:"item.pontosSimulacao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontosSimulacao))+" ")]}},{key:"item.pontosFaturamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontosFaturamento))+" ")]}},{key:"item.pontosFatCart",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pontosFatCart))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"},attrs:{"colspan":"9"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totalPontosMeta))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totalPontosFaturamento))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totalPontosFatCart))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totalPontosSimulacao))+" ")])])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }