import RelatorioDpm from '../../spa/relatorios/dpm/RelatorioDpm';
import RelatorioDpmList from '../../spa/relatorios/dpm/RelatorioDpmList';

export default {
  path: 'relatorio-dpm',
  component: RelatorioDpm,
  children: [
    {
      path: '',
      name: 'relatorio-dpm',
      component: RelatorioDpmList,
    },
  ],
};
