var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-2 mb-2 title-float accent--text d-flex align-center"},[_vm._v(" "+_vm._s(((_vm.$tc('label.apuracao_plano_metas', 1)) + " - " + (_vm.$tc('label.total_rebate', 1))))+" "),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){_vm.dialogRebate = true}}},[_c('v-icon',[_vm._v("info")])],1)],1),_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"12"}},[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.cabecalhoGrupo,"items":_vm.linhasGrupo,"disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.statusAdesao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.metaMinima ? _vm.$t('label.dpm_minimo') : _vm.$t('label.nao_aderiu'))+" ")]}},{key:"item.meta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.meta))+" ")]}},{key:"item.faturado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.faturado))+" ")]}},{key:"item.pedidos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.pedidos))+" ")]}},{key:"item.projetado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumber(item.projetado))+" ")]}},{key:"item.atingimentoFaturado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPercent(item.atingimentoFaturado))+" ")]}},{key:"item.atingimentoProjetado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPercent(item.atingimentoProjetado))+" ")]}},{key:"item.statusGrupo",fn:function(ref){
var item = ref.item;
return [(item.metaMinima)?_c('div',{class:_vm.classeStatusGrupo(item.statusGrupo)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.labelStatusGrupo(item.statusGrupo)))])]):_c('span',{staticClass:"text-center"},[_vm._v("-")])]}},{key:"body.append",fn:function(){return [(_vm.planoMetas.totais)?_c('tr',[_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"},attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totais.metaTotal))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totais.faturadoPontuacao))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totais.pedidosPontuacao))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getNumber(_vm.totais.projetadoPontuacao))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getPercent(_vm.totais.atingimentoFaturado))+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getPercent(_vm.totais.atingimentoProjetado))+" ")])]):_vm._e()]},proxy:true}])})],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialogRebate),callback:function ($$v) {_vm.dialogRebate=$$v},expression:"dialogRebate"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('label.rebate'))+" ")]),_c('v-container',[_c('v-row',[_c('v-col',[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('label.rebate_info')))])])],1),_c('v-row',_vm._l((_vm.verbas),function(verba,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"6"}},[_c('input-money',{attrs:{"label":verba.nome,"disabled":true},model:{value:(verba.valor),callback:function ($$v) {_vm.$set(verba, "valor", $$v)},expression:"verba.valor"}})],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }