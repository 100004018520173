<template>
  <v-hover v-slot:default="{ hover }">
    <v-card :class="`elevation-${hover ? 5 : 1}`"
      class="Card_Apuracao clickable" fill-height @click="selectItem(item)">
      <v-card-title class="Card_Title pa-2 justify-center">
        {{ $te(item.titulo, 1) ? $tc(item.titulo, 1) : item.titulo }}
      </v-card-title>
      <v-card-text class="Card_Content">
        <v-row justify="center" align="center">
          <p v-bind:style="{color: corValorPercentual(item)}" class="headline font-weight-light">
            {{ getPercent(item.valorPercentual) }}
          </p>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center" align="center">
          <v-col cols="12" :sm="!habilitarFinalizarApuracao ? 6 : 4">
            <v-tooltip bottom v-if="indModoVarejo">
              <template v-slot:activator="{ on }">
                <v-row justify="center" align="center" v-on="on">
                  <v-col cols="12" sm="12" class="py-0" style="text-align: center;">
                    {{ getMoney(item.totalRecebimentos) }} <br />
                    <v-icon>attach_money</v-icon>
                  </v-col>
                </v-row>
              </template>
              <span>{{ $t('label.total_de_recebimento') }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" :sm="!habilitarFinalizarApuracao ? 6 : 4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-row justify="center" align="center" v-on="on">
                  <v-col cols="12" sm="12" class="py-0" style="text-align: center;">
                    {{ item.quantidade }} <br />
                    <v-icon>functions</v-icon>
                  </v-col>
                </v-row>
              </template>
              <span>{{ $t('label.quantidade_apuracao') }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" sm="4" v-if="habilitarFinalizarApuracao">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-row justify="end" align="center" v-on="on">
                  <v-col cols="12" sm="12" class="py-0" style="text-align: center;">
                    <v-btn v-on="on" class="ma-0" icon @click="(e) => abrirFinalizacaoLote(e, item)">
                      <v-icon>done_all</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <span>{{ $tc('label.finalizar_apuracao', 2) }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getMoney,
  getPercent,
} from '../../common/functions/helpers';
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  name: 'ApuracaoCardStatus',
  props: {
    item: Object,
  },
  computed: {
    ...generateComputed('APU_CONTR', [
      'canEdit',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    habilitarFinalizarApuracao() {
      return this.item.status === 'AGUARDANDO_APURACAO'
        && this.canEdit;
    },
  },
  methods: {
    getMoney,
    getPercent,
    selectItem(item) {
      this.$router.push({
        name: 'listarApuracoesContratoNivel2Status',
        params: {
          status: item.status,
        },
      });
    },
    abrirFinalizacaoLote(e, item) {
      e.stopPropagation();
      const routerFinalizarLote = this.indModoVarejo
        ? 'finalizarLoteApuracoesContratoStatus'
        : 'finalizarLoteApuracoesContratoIndustriaStatus';
      this.$router.push({
        name: routerFinalizarLote,
        params: {
          status: item.status,
        },
      });
    },
    corValorPercentual(item) {
      if (item.status === 'APURACAO_PREVIA') {
        return 'rgb(149, 165, 166)';
      } if (item.status === 'AGUARDANDO_APURACAO') {
        return 'rgb(30, 136, 229)';
      } if (item.status === 'AGUARDANDO_APROVACAO') {
        return 'rgb(230, 126, 34)';
      } if (item.status === 'EM_ANALISE') {
        return 'rgb(241, 196, 15)';
      } if (item.status === 'APROVADO') {
        return 'rgb(26, 188, 156)';
      } if (item.status === 'REPROVADO') {
        return 'rgb(231, 76, 60)';
      } if (item.status === 'CANCELADO') {
        return 'rgb(126, 87, 194)';
      }
      return '#757575';
    },
  },
};
</script>

<style>

.Card_Apuracao {
  min-height: 146px !important;
  height: 100%;
}
.Card_Apuracao .Card_Title, .Card_Apuracao .Card_Content {
  color: #757575;
}
</style>
