<template>
  <v-form v-model="valid" ref="form" lazy-validation>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-autocomplete
          id="extrato_tipo_verba"
          name="extrato_tipo_verba"
          :items="tiposVerba"
          item-text="nome"
          item-value="id"
          clearable
          v-model="idTipoVerba"
          @input="inputTipoVerba"
          :label="`${$tc('label.tipo_verba', 1)} *`"
          :rules="[rules.required]">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-autocomplete
          id="extrato_periodos"
          name="extrato_periodos"
          :items="periodos"
          item-text="descricao"
          item-value="id"
          clearable
          :disabled="desabilitarPeriodo"
          v-model="idPeriodo"
          @input="inputPeriodos"
          :label="`${$tc('label.periodo', 1)} *`"
          :rules="[rules.required]">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-autocomplete
          id="extrato_conta_corrente"
          name="extrato_conta_corrente"
          :items="contasCorrentes"
          item-text="descricaoPosse"
          item-value="descricaoPosse"
          clearable
          return-object
          :disabled="desabilitarContaCorrente"
          v-model="contaCorrente"
          @input="inputContaCorrente"
          :label="`${$tc('label.conta_corrente', 1)} *`"
          :rules="[rules.required]">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-autocomplete
          id="extrato_linha_investimento"
          name="extrato_linha_investimento"
          :items="linhasInvestimentos"
          item-text="descricao"
          item-value="id"
          clearable
          return-object
          :disabled="desabilitarLinhasInvestimentos"
          v-model="linhaInvestimento"
          @input="inputLinhaInvestimento"
          :label="$tc('label.linha_de_investimento', 1)">
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6">
      <v-menu
        :close-on-content-click="false"
        v-model="menuDataInicio"
        id="extrato_data_inicio"
        name="extrato_data_inicio"
        :nudge-right="40"
        transition="scale-transition"
        :disabled="desabilitaDatas"
        offset-y
        full-width
        max-width="290px"
        min-width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="on"
            :disabled="desabilitaDatas"
            class="CalendarioForm-data__text"
            :return-masked-value="true"
            mask="##-##-####"
            :rules="[rules.required, rules.validDate]"
            v-model="dateInicioFormatada"
            :label="`${$t('label.data_inicio')} *`"
            prepend-icon="event">
          </v-text-field>
        </template>

        <v-date-picker locale="pt-br" color="primary" v-model="dataInicio" @input="menuDataInicio = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="6">
      <v-menu
        :close-on-content-click="false"
        v-model="menuDataFim"
        id="extrato_data_fim"
        name="extrato_data_fim"
        :nudge-right="40"
        :disabled="desabilitaDatas"
        transition="scale-transition"
        offset-y
        full-width
        max-width="290px"
        min-width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="on"
            :disabled="desabilitaDatas"
            class="CalendarioForm-data__text"
            :return-masked-value="true"
            mask="##-##-####"
            :rules="[rules.required, rules.validDate, rules.dataFimMaiorInicio]"
            v-model="dateFimFormatada"
            :label="`${$t('label.data_fim')} *`"
            prepend-icon="event">
          </v-text-field>
        </template>

        <v-date-picker locale="pt-br" color="primary" :min="dataInicio" v-model="dataFim" @input="menuDataFim = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" align="between">
      <v-row justify="space-between">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              :disabled="desabilitarContaCorrente || bloquearExportacao"
              v-on="on"
              @click="exportar">
              <v-icon>get_app</v-icon>
            </v-btn>
          </template>
          <span>{{$t('label.exportar')}}</span>
        </v-tooltip>
        <v-btn
          color="primary"
          @click="pesquisar"
          :disabled="desabilitarContaCorrente">
          {{$t('label.pesquisar')}}
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
  </v-form>
</template>

<script>
import { formatDateDDMMYYYY, parseDateYYYYMMDD } from '../../../common/functions/date-utils';
import exportacao from '../../../common/functions/exportacao';

export default {
  name: 'ExtratoContaCorrenteFiltros',
  data() {
    return {
      extratoContaCorrente: this.$api.extratoContaCorrente(this.$resource),
      tiposVerba: [],
      periodos: [],
      contasCorrentes: [],
      linhasInvestimentos: [],
      menuDataInicio: false,
      menuDataFim: false,
      idTipoVerba: null,
      idPeriodo: null,
      contaCorrente: null,
      linhaInvestimento: null,
      desabilitarPeriodo: true,
      desabilitaDatas: true,
      bloquearExportacao: false,
      desabilitarContaCorrente: true,
      desabilitarLinhasInvestimentos: true,
      valid: true,
      dataFim: null,
      dataInicio: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
        dataFimMaiorInicio: () => !this.isDataFimMaiorInicio() || this.$t('message.data_inicial_maior_final'),
      },
    };
  },
  computed: {
    dateInicioFormatada: {
      get() {
        return formatDateDDMMYYYY(this.dataInicio);
      },
      set(newValue) {
        this.dataInicio = parseDateYYYYMMDD(newValue);
      },
    },
    dateFimFormatada: {
      get() {
        return formatDateDDMMYYYY(this.dataFim);
      },
      set(newValue) {
        this.dataFim = parseDateYYYYMMDD(newValue);
      },
    },
  },
  methods: {
    exportar() {
      if (this.$refs.form.validate()) {
        const params = {
          ids: this.contaCorrente.idsContas,
          data_inicial: this.dataInicio,
          data_final: this.dataFim,
        };
        if (this.linhaInvestimento) {
          params.ids = [this.linhaInvestimento.idConta];
          params.id_linha = this.linhaInvestimento.id;
        }
        this.bloquearExportacao = true;
        exportacao.exportar(() => {
          this.bloquearExportacao = false;
        }, 'exportacao-extrato', this, params, 5000, 0, 'orcamento');
      }
    },
    isDataFimMaiorInicio() {
      const dtInicio = this.moment(this.dataInicio, 'YYYY-MM-DD');
      const dtFinal = this.moment(this.dataFim, 'YYYY-MM-DD');
      return dtFinal.isBefore(dtInicio);
    },
    buscarTiposVerba() {
      this.extratoContaCorrente.tiposVerba()
        .then((res) => {
          this.tiposVerba = res.data;
          if (this.tiposVerba.length === 1) {
            this.idTipoVerba = this.tiposVerba[0].id;
            this.inputTipoVerba();
          }
        }, (err) => {
          this.$error(this, err);
        });
    },
    buscarPeriodos() {
      if (!this.idTipoVerba) return;
      const param = { id: this.idTipoVerba };
      this.extratoContaCorrente.periodos(param)
        .then((res) => {
          this.periodos = res.data;
          if (this.periodos.length === 1) {
            this.idPeriodo = this.periodos[0].id;
            this.inputPeriodos();
          }
        }, (err) => {
          this.$error(this, err);
        });
    },
    buscarContasCorrente() {
      if (!this.idPeriodo) return;
      const param = {
        idTipoVerba: this.idTipoVerba,
        idPeriodo: this.idPeriodo,
      };
      this.extratoContaCorrente.contasCorrentes(param)
        .then((res) => {
          this.contasCorrentes = res.data;
          if (this.contasCorrentes.length === 1) {
            [this.contaCorrente] = this.contasCorrentes;
            this.inputContaCorrente();
          }
        }, (err) => {
          this.$error(this, err);
        });
    },
    buscarLinhaInvestimento() {
      if (!this.contaCorrente) return;
      const param = {
        ids: this.contaCorrente.idsContas,
      };
      this.extratoContaCorrente.linhasInvestimentos(param)
        .then((res) => {
          this.linhasInvestimentos = res.data;
          if (this.linhasInvestimentos.length === 1) {
            [this.linhaInvestimento] = this.linhasInvestimentos;
            this.inputLinhaInvestimento();
          } else {
            this.$emit('ExtratoContaCorrenteFiltros_contaSelecionada', this.contaCorrente.idsContas);
          }
        }, (err) => {
          this.$error(this, err);
        });
    },
    inputTipoVerba() {
      this.desabilitarPeriodo = !this.idTipoVerba;
      this.desabilitarContaCorrente = true;
      this.desabilitarLinhasInvestimentos = true;
      this.contaCorrente = null;
      this.linhaInvestimento = null;
      this.idPeriodo = null;
      this.buscarPeriodos();
      this.$emit('ExtratoContaCorrenteFiltros_contaDeselecionada');
    },
    inputPeriodos() {
      this.desabilitarContaCorrente = !this.idPeriodo;
      this.desabilitarLinhasInvestimentos = true;
      this.contaCorrente = null;
      this.linhaInvestimento = null;
      this.buscarContasCorrente();
      this.$emit('ExtratoContaCorrenteFiltros_contaDeselecionada');
    },
    inputContaCorrente() {
      this.desabilitaDatas = !this.contaCorrente;
      this.desabilitarLinhasInvestimentos = !this.contaCorrente;
      if (this.contaCorrente) {
        this.preencherData(this.contaCorrente.dtaInicio, this.contaCorrente.dtaFim);
        this.buscarLinhaInvestimento();
      } else {
        this.$emit('ExtratoContaCorrenteFiltros_contaDeselecionada');
      }
    },
    inputLinhaInvestimento() {
      if (this.linhaInvestimento) {
        this.$emit('ExtratoContaCorrenteFiltros_contaSelecionada', [this.linhaInvestimento.idConta], this.linhaInvestimento.id);
        this.preencherData(this.linhaInvestimento.dtaInicio, this.linhaInvestimento.dtaFim);
      } else if (this.contaCorrente) {
        this.$emit('ExtratoContaCorrenteFiltros_contaSelecionada', this.contaCorrente.idsContas);
        this.preencherData(this.contaCorrente.dtaInicio, this.contaCorrente.dtaFim);
      } else {
        this.$emit('ExtratoContaCorrenteFiltros_contaDeselecionada');
      }
    },
    preencherData(dtaInicio, dtaFim) {
      this.dataInicio = this.moment(dtaInicio, 'DD/MM/YYYY HH:mm:ss')
        .format('YYYY-MM-DD');
      this.dataFim = this.moment(dtaFim, 'DD/MM/YYYY HH:mm:ss')
        .format('YYYY-MM-DD');
    },
    pesquisar() {
      if (this.$refs.form.validate()) {
        if (this.linhaInvestimento) {
          this.$emit('ExtratoContaCorrenteFiltros_pesquisar', [this.linhaInvestimento.idConta], this.dataInicio, this.dataFim, this.linhaInvestimento.id);
        } else {
          this.$emit('ExtratoContaCorrenteFiltros_pesquisar', this.contaCorrente.idsContas, this.dataInicio, this.dataFim);
        }
      }
    },
  },
  mounted() {
    this.buscarTiposVerba();
  },
};
</script>
