import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('recebimento', 'nota-debito/consulta');

const notaDebitoActions = {
  buscaStatus: { method: 'GET', url: `${basePath}/status` },
  buscaDivisoes: { method: 'GET', url: `${basePath}/divisao` },
  verificarDadosExportacao: { methods: 'GET', url: `${basePath}/verificar-dados-exportacao` },
};

export const buscaNotas = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, '', parametros).doGet();

export default (resource) => resource(`${basePath}`, {}, notaDebitoActions);
