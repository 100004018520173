<template>
  <v-card>
    <v-card-title>
      <slot name="titulo-acao"></slot>
      <v-spacer></v-spacer>
      <v-icon @click="toggleResumo"
        v-show="resumoAberto">
        keyboard_arrow_up
      </v-icon>
      <v-icon @click="toggleResumo"
        v-show="!resumoAberto">
        keyboard_arrow_down
      </v-icon>
    </v-card-title>

    <v-container fluid grid-list-md class="pt-0 pl-3" v-show="resumoAberto">
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field
            v-model="objetoPassoGeral.descricao"
            :rules="[rules.required]"
            :disabled="true"
            :label="`${$tc('label.descricao', 1)} *`"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" v-if="objetoPassoGeral.valor !== undefined && !objetoPassoGeral.indAcaoAgrupadora">
          <metadados-campo-dinamico-dinheiro
            :metadados="campoValor"
            :objeto-container="objetoPassoGeral"
            v-model="objetoPassoGeral.valor"
            somente-leitura
            />
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-if="objetoPassoGeral.holding"
            v-model="objetoPassoGeral.holding.nomExtensao"
            disabled
            :label="$tc('label.holding', 1)"/>
          <v-text-field
            v-if="objetoPassoGeral.cliente"
            v-model="objetoPassoGeral.cliente.codNome"
            disabled
            :label="$tc('label.cliente', 1)"/>
          <v-text-field
            v-if="objetoPassoGeral.grupoFornecedor"
            v-model="objetoPassoGeral.grupoFornecedor.nomExtensao"
            disabled
            :label="$tc('label.grupo_fornecedor', 1)"/>
          <v-text-field
            v-if="objetoPassoGeral.extensaoCliente"
            v-model="objetoPassoGeral.extensaoCliente.descricao"
            disabled
            :label="objetoPassoGeral.focoAcao"/>
          <v-text-field
            v-if="objetoPassoGeral.fornecedor"
            v-model="objetoPassoGeral.fornecedor.codNome"
            disabled
            :label="$tc('label.fornecedor', 1)"/>
        </v-col>
        <v-col cols="12" md="3" v-if="exibeClientePagador">
          <v-text-field
            v-model="objetoPassoGeral.clientePagador.codNome"
            disabled
            :label="$tc('label.cliente_pagador', 1)"/>
        </v-col>
        <v-col cols="12" md="3" v-if="exibeFornecedorPagador">
          <v-text-field
            v-model="objetoPassoGeral.fornecedorPagador.codNomeCnpj"
            disabled
            :label="$tc('label.fornecedor_pagador', 1)"/>
        </v-col>

        <v-col cols="12" md="3" v-for="campo in camposProduto" :key="campo">
          <v-text-field
            disabled
            :value="montaValorOpcional(campo)"
            :label="montaLabelExtensao(campo)"/>
        </v-col>
        <v-col cols="12" md="3" v-if="exibeProduto()">
          <v-textarea
            disabled
            :rows="qtdeLinhasProduto"
            :value="montaValorProduto()"
            :label="$tc('label.produto', 1)"/>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import MetadadosCampoDinamicoDinheiro from '../../../shared-components/metadados/campos-dinamicos/MetadadosCampoDinamicoDinheiro';

export default {
  components: {
    MetadadosCampoDinamicoDinheiro,
  },
  computed: {
    ...mapGetters('metadados', [
      'getProdutoMetadado',
    ]),
    qtdeLinhasProduto() {
      return this.objetoPassoGeral.produto.length + 1;
    },
    campoValor() {
      return {
        nomCampo: this.$tc('label.valor', 1),
      };
    },
    exibeFocoHolding() {
      return this.focoAcao === 'HOLDING';
    },
  },
  props: {
    exibeClientePagador: {
      type: Boolean,
      required: true,
    },
    exibeFornecedorPagador: {
      type: Boolean,
      required: true,
    },
    objetoPassoGeral: {
      type: Object,
      required: true,
    },
    ordenacaoFormulario: {
      type: Array,
      required: true,
    },
    somenteLeitura: Boolean,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      resumoAberto: false,
      focoAcao: 'HOLDING',
      camposProduto: [],
    };
  },
  methods: {
    toggleResumo() {
      this.resumoAberto = !this.resumoAberto;
    },
    exibeProduto() {
      return this.ordenacaoFormulario.indexOf('produto') >= 0
        && this.objetoPassoGeral.produto != null
        && this.objetoPassoGeral.produto.length !== 0;
    },
    montaValorOpcional(campo) {
      const obj = (this.objetoPassoGeral[campo] || {});
      let { nomExtensao } = obj;
      if (!nomExtensao) {
        nomExtensao = '';
        obj.forEach((o, i) => {
          nomExtensao = nomExtensao.concat(o.nomExtensao);
          if (i < (obj.length - 1)) {
            nomExtensao = nomExtensao.concat(';');
          }
        });
      }
      return nomExtensao;
    },
    montaValorProduto() {
      const produtos = this.objetoPassoGeral.produto || [];
      return produtos.map((p) => `- ${p.nomProduto}`).join('\n');
    },
    montaLabelExtensao(campo) {
      const metadado = Object.values(this.getProdutoMetadado.mapaEntidades)
        .filter((dep) => dep.label === campo)[0];

      return metadado.entidadeEstrangeira;
    },
    setFocoAcao() {
      this.focoAcao = this.objetoPassoGeral.cliente
        ? 'CLIENTE' : 'HOLDING';
    },
    getCamposHabilitadosProduto() {
      const labelsExtensoes = Object.values(this.getProdutoMetadado.mapaEntidades)
        .map((e) => e.label);
      const labelsGerais = [
        ...labelsExtensoes,
      ];
      return labelsGerais
        .filter((l) => this.ordenacaoFormulario.indexOf(l) >= 0);
    },
    montaResumo() {
      this.camposProduto = this.getCamposHabilitadosProduto()
        .filter((label) => {
          const obj = this.objetoPassoGeral[label];
          return obj != null;
        });
      this.setFocoAcao();
    },
  },
};
</script>
