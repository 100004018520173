var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PlanejamentoAcaoFormContaCorrente"},[_c('planejamento-acao-form-resumo',{ref:"resumo",attrs:{"exibe-fornecedor-pagador":_vm.exibeFornecedorPagador,"exibe-cliente-pagador":_vm.exibeClientePagador,"objeto-passo-geral":_vm.objetoPassoGeral,"ordenacao-formulario":_vm.ordenacaoFormulario,"somenteLeitura":_vm.somenteLeitura}},[_c('div',{attrs:{"slot":"titulo-acao"},slot:"titulo-acao"},[_vm._t("titulo-acao")],2)]),_c('div',{staticClass:"mt-2 mb-2 title-float accent--text"},[_vm._v(" "+_vm._s(_vm.$tc("label.conta_corrente", 2))+" ")]),_c('v-card',[_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"id":"planejamento-cc-autocomplete-tipoverba","label":((_vm.$t('label.tipo_verba')) + " *"),"items":_vm.tiposVerbas,"item-text":"nome","item-value":"id","append-icon":_vm.mostrarIconeVerbaBloqueada()},on:{"change":function($event){return _vm.tipoVerbaSelecionado(_vm.formulario.idTipoVerba)}},model:{value:(_vm.formulario.idTipoVerba),callback:function ($$v) {_vm.$set(_vm.formulario, "idTipoVerba", $$v)},expression:"formulario.idTipoVerba"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"id":"planejamento-cc-autocomplete-tipoverba","item-text":"nome","item-value":"id","multiple":"","return-object":"","items":_vm.periodosContas,"label":((_vm.$tc('label.selecione_periodo', 1)) + " *"),"no-data-text":_vm.$tc('message.nenhum_registro', 1),"clearable":true,"placeholder":_vm.somenteLeitura || !_vm.formulario.idTipoVerba
                ? null
                : _vm.$tc('message.digite_para_pesquisar', 1)},model:{value:(_vm.formulario.periodos),callback:function ($$v) {_vm.$set(_vm.formulario, "periodos", $$v)},expression:"formulario.periodos"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"3"}},[(!_vm.somenteLeitura)?_c('v-btn',{on:{"click":function($event){return _vm.buscarContasCorrentes(true)}}},[_vm._v(" "+_vm._s(_vm.$t("label.buscar"))+" ")]):_vm._e()],1)],1),(
          _vm.mostrarFraseVerbaBloqueada &&
            _vm.verbaSelecionada.indBloqueioTemp &&
            !_vm.novaAcao &&
            _vm.acaoEdicao.passoGeral.status === 'EM_CADASTRO'
        )?_c('div',{staticStyle:{"color":"#ff0000ba"}},[_vm._v(" "+_vm._s(_vm.$t("label.impossivel_enviar_acao", { verba: _vm.verbaSelecionada.nome, periodoInicio: _vm.formataData(_vm.eventoCalendario.dataInicio), periodoFim: _vm.formataData(_vm.eventoCalendario.dataFim) }))+" ")]):_vm._e()],1)],1),(_vm.carregando)?_c('div',{staticClass:"text-xs-center mt-5 accent--text"},[_c('v-progress-circular',{attrs:{"indeterminate":true}})],1):_vm._e(),_c('planejamento-acao-form-conta-corrente-vinculos',{ref:"vinculosCampos",staticClass:"mt-3",attrs:{"configuracao":_vm.configuracao,"objeto-passo-geral":_vm.objetoPassoGeral,"somente-leitura":_vm.somenteLeitura},on:{"PLANEJAMENTO_ACAO_VINCULOS_TOGGLE":_vm.refazBuscaContasCorrentes}}),_c('v-card',{staticClass:"mt-3"},[_c('v-card-title',[_vm._v(" "+_vm._s(this.$tc("label.distribuicao_valor", 2))+" ")]),_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('input-money',{attrs:{"label":_vm.$tc('label.valor', 1),"disabled":true},model:{value:(_vm.valorDaAcao),callback:function ($$v) {_vm.valorDaAcao=$$v},expression:"valorDaAcao"}})],1),(_vm.reservas.length > 1)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('input-money',{attrs:{"label":_vm.$tc('label.valor_total', 1),"disabled":true},model:{value:(_vm.valorTotal),callback:function ($$v) {_vm.valorTotal=$$v},expression:"valorTotal"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('input-money',{class:{
              'PlanejamentoAcaoFormContaCorrente__valor-negativo': _vm.formulario.pendente < 0
            },attrs:{"label":_vm.$t('label.pendente_reserva'),"disabled":true},model:{value:(_vm.formulario.pendente),callback:function ($$v) {_vm.$set(_vm.formulario, "pendente", $$v)},expression:"formulario.pendente"}})],1),(_vm.reservas.length === 1)?_c('v-col',{attrs:{"cols":"12"}},[_c('conta-corrente-tabela',{attrs:{"somente-leitura":_vm.somenteLeitura,"contas-correntes":_vm.reservas[0].contas,"exibir-saldo":_vm.exibirSaldo},on:{"ContaCorrenteTabela_atualizaSaldoAjustado":_vm.atualizaSaldoAjustado}})],1):_vm._e()],1)],1)],1),(_vm.reservas.length > 1)?_c('planejamento-acao-reservas-apuracao',{staticClass:"mt-6",attrs:{"reservas":_vm.reservas,"contas-correntes":_vm.contasCorrentes,"somente-leitura":_vm.somenteLeitura,"exibir-saldo":_vm.exibirSaldo},on:{"PlanejamentoAcaoReservasApuracao_valorTotalAlterado":_vm.valorTotalAlterado,"PlanejamentoAcaoReservasApuracao_reservaAlterada":_vm.reservaAlterada}}):_vm._e(),_c('confirm',{ref:"reservadoMaiorLiberadoDialog",attrs:{"message":_vm.$t('message.reservado_maior_liberado'),"persistent":true},on:{"agree":_vm.irProximoPasso}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }