<template>
  <v-row class="mt-6">
    <v-col cols="12" md="3" v-for="(indicador, index) in indicadores" :key="`card-indicador-${index}`">
      <v-card
        class="mx-auto"
        max-width="344"
        :color="indicador.indicadorConfigurado ? 'primary' : null"
        :dark="indicador.indicadorConfigurado"
        outlined>
        <v-card-title class="headline">{{ $t(indicador.descricao) }}</v-card-title>
        <v-card-text>
          <v-row justify="center">
            <span class="title font-weight-regular">{{ `${$t('label.peso')}: ${convertToNumberFormat(indicador.peso)}` }}</span>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text icon @click="abrirCadastro(indicador)">
            <v-icon>settings_applications</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text icon @click="abrirRemoverIndicador(indicador)" v-if="!somenteLeitura">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_excluir_indicador')"
      @agree="remover">
    </confirm>
    <indicador-cadastro
      ref="indicadorCadastro"
      :id-periodo="idPeriodo"
      :somente-leitura="somenteLeitura"
      :alterar-finalizado="alterarFinalizado"
      :indicador="indicadorTemp">
    </indicador-cadastro>
  </v-row>
</template>

<script>
import Confirm from '../../../../produto/shared-components/vuetify/dialog/Confirm';
import IndicadorCadastro from './IndicadorCadastro';
import { convertToNumberFormat } from '../../../../produto/common/functions/numeric';
import Indicador from './Indicador';

export default {
  name: 'IndicadoresCards',
  components: {
    IndicadorCadastro,
    Confirm,
  },
  props: {
    indicadores: {
      type: Array,
      default: () => [],
    },
    idPeriodo: {
      type: Number,
      default: null,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      indicadorTemp: new Indicador(),
    };
  },
  methods: {
    convertToNumberFormat,
    abrirCadastro(indicador) {
      this.indicadorTemp = indicador;
      this.$refs.indicadorCadastro.open();
    },
    abrirRemoverIndicador(indicador) {
      this.indicadorTemp = indicador;
      this.$refs.confirmDialog.open();
    },
    remover() {
      this.$emit('IndicadoresCards_remover', this.indicadorTemp);
    },
    recarregarIndicadores() {
      this.$refs.indicadorCadastro.carregado = false;
    },
  },
};
</script>
