export default {
  name: 'PlanejamentoAcaoFormPassosDinamicosMixin',
  props: {
    somenteLeitura: Boolean,
    tipoAcao: {
      type: Object,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
    passosHabilitados: {
      type: Object,
      required: true,
    },
    getObjetoOutroPasso: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      objetoPassoComportamento: null,
      objetoPassoGeral: null,
      objetoPassoContas: null,
    };
  },
  computed: {
    tituloTipoAcao() {
      return this.tipoAcao != null ? this.tipoAcao.nome : '';
    },
    ordenacaoFormulario() {
      const { relacaoCampos } = this.configuracao;
      return relacaoCampos;
    },
    exibeClientePagador() {
      return this.configuracao.habilitaClientePagador && this.objetoPassoGeral.focoPagamento === 'CLIENTE';
    },
    exibeFornecedorPagador() {
      return this.configuracao.habilitaFornecedorPagador && this.objetoPassoGeral.focoPagamento === 'FORNECEDOR';
    },
    passoComportamentoHabilitado() {
      return this.passosHabilitados.comportamento.habilitado;
    },
    passoContasHabilitado() {
      return this.passosHabilitados.contas.habilitado;
    },
  },
  methods: {
    preencherPassosGeral() {
      this.objetoPassoGeral = this.getObjetoOutroPasso('geral');
    },
    preencherPassosComportamento() {
      if (this.passoComportamentoHabilitado) {
        this.objetoPassoComportamento = this.getObjetoOutroPasso('comportamento');
      }
    },
    preencherPassosContas() {
      if (this.passoContasHabilitado) {
        this.objetoPassoContas = this.getObjetoOutroPasso('contas');
      }
    },
    preencherPassoDinamicoFormatado(acao, numeroPasso, nomePasso, objetoPasso) {
      if (!acao.passosDinamicos) {
        acao.passosDinamicos = {};
        acao.ordemPassosDinamicos = {};
      }

      acao.passosDinamicos[nomePasso] = objetoPasso;
      acao.ordemPassosDinamicos[numeroPasso] = nomePasso;
    },
  },
};
