<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title>
        <h2 class="headline mb-0">{{ $tc('title.fornecedor', 1) }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <metadados-container-layout
          v-if="metadadosFornecedor != null"
          :metadados-entidade="metadadosFornecedor"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="fornecedor"
          :formulario-filtros="false"
          :somenteLeitura="somenteLeitura"
          :novoCadastro="true"
          @MetadadosContainerLayout_abrirCadastro="abrirCadastro"
          @MetadadosContainerLayout_save="validarCadastro"
          @MetadadosContainerLayout_form_Invalido="validarCadastro"
          ref="container">
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              id="campo_codigo"
              name="campo_codigo"
              v-model="fornecedor.idExterno"
              :label="`${$tc('label.cod_fornecedor', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :counter="10"
              maxlength="10"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              id="campo_razao_social"
              name="campo_razao_social"
              v-model="fornecedor.desRazaoSocial"
              :label="`${$tc('label.razao_social', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :counter="100"
              maxlength="100"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              id="campo_nome_fantasia"
              name="campo_nome_fantasia"
              v-model="fornecedor.desNomeFantasia"
              :label="`${$tc('label.nome_fantasia', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :counter="100"
              maxlength="100"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_cnpj"
              name="campo_cnpj"
              v-model="fornecedor.desCnpj"
              :label="`${$tc('label.cnpj', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              v-cpfcnpj
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_ie"
              name="campo_ie"
              v-model="fornecedor.inscricaoEstadual"
              :label="`${$tc('label.inscricao_estadual', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :counter="50"
              maxlength="50"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_prazo_vencimento_contrato"
              name="campo_prazo_vencimento_contrato"
              v-model="fornecedor.prazoVencimentoContrato"
              :label="`${$tc('label.prazo_vencimento_contrato_dias', 1)} *`"
              :rules="[rules.required, rules.numeroValido]"
              :disabled="somenteLeitura"
              type="number"
              min="0"
              v-mask="'###'"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_prazo_vencimento_acao"
              name="campo_prazo_vencimento_acao"
              v-model="fornecedor.prazoVencimentoAcao"
              :label="`${$tc('label.prazo_vencimento_acao_dias', 1)} *`"
              :rules="[rules.required, rules.numeroValido]"
              :disabled="somenteLeitura"
              type="number"
              min="0"
              v-mask="'###'"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-select
              id="tipo_pagamento" name="tipo_pagamento"
              :items="tiposPagamento"
              v-model="fornecedor.idTipoPagamento"
              item-text="descricao"
              item-value="id"
              :label="`${$tc('label.tipo_pagamento', 1)}`"
              :disabled="somenteLeitura"
              :no-data-text="$tc('message.nenhum_registro', 1)">
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-switch
              color="primary"
              :label="$t('label.fornecedor_pagador')"
              v-model="fornecedor.indPagador"
              :disabled="somenteLeitura"
              class="mt-3">
            </v-switch>
          </v-col>
          <v-col cols="12" slot="antes">
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  id="campo_contato"
                  name="campo_contato"
                  v-model="fornecedor.contato.nomContato"
                  :label="`${$tc('label.contato', 1)}`"
                  :disabled="somenteLeitura"
                  :counter="100"
                  maxlength="100"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  id="campo_contato"
                  name="campo_contato"
                  v-model="fornecedor.contato.vlrEmail"
                  :label="`${$tc('label.email', 1)}`"
                  :disabled="somenteLeitura"
                  :counter="100"
                  maxlength="100"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  id="campo_contato"
                  name="campo_contato"
                  v-model="fornecedor.contato.vlrTelefone"
                  :label="`${$tc('label.telefone', 1)}`"
                  :disabled="somenteLeitura"
                  :counter="11"
                  maxlength="11"
                  v-mask="'(##) #####-####'"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" slot="depois">
            <endereco
              ref="endereco"
              @ENDERECO_valido="enderecoValido"
              @ENDERECO_invalido="enderecoInvalido"
              :somenteLeitura="somenteLeitura"
              :id="fornecedor.idEndereco"
              >
            </endereco>
          </v-col>
          <v-col cols="12" slot="depois">
            <v-row>
              <v-col cols="12" md="4">
                <v-autocomplete
                  id="produto_fornecedor"
                  name="produto_fornecedor"
                  v-model="filtroNomeProduto"
                  :filter="filtroProduto"
                  :items="listaProdutos"
                  :search-input.sync="buscaListaProduto"
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  item-text="nomProduto"
                  item-value="idExterno"
                  :label="`${$tc('label.produto', 1)}`"
                  :placeholder="$tc('label.digite_para_buscar', 1)"
                  @change="setProduto"
                  clearable
                  :disabled="somenteLeitura"
                  return-object>
                    <template slot="item" slot-scope="data">
                      {{ data.item.idExterno }} - {{ data.item.nomProduto }}
                    </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4" slot="depois">
            <v-data-table id="produto_fornecedor_tabela"
              :headers="headersProduto"
              :items="fornecedor.listaprodutos"
              hide-default-footer
              :no-data-text="$t('message.nenhum_registro')">
              <template v-slot:item.acoes="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="removerProdutoPeloId(item.id)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('label.remover') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </metadados-container-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="btn_cancelar_novo_fornecedor" @click.native="openCancelarDialog">{{ $t('label.cancelar') }}</v-btn>
        <v-btn id="btn_salvar_novo_fornecedor" @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>

      <v-dialog v-model="dialogCadastroExtensao" persistent max-width="800px">
        <fornecedor-varejo-nova-extensao
          ref="fornecedorNovaExtensao"
          :somenteLeitura="somenteLeitura"
          :objeto="novaExtensao"
          @cancelar="fecharCadastroExtensao"
          @atualizarGrid="setarCadastroExtensao"
        />
      </v-dialog>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', { text: this.$tc('title.fornecedor', 1).toLowerCase() })"
      @agree="validarCadastro">
    </confirm>

    <confirm
      ref="cancelarDialog" :title="$t('title.atencao')"
      :message="$t('message.tem_certeza_cancelar')"
      @agree="cancelar">
    </confirm>
  </v-form>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { skipLoading } from '../../../common/functions/loading';
import MetadadosContainerLayout from '../../../shared-components/metadados/MetadadosContainerLayout';
import FornecedorVarejoNovaExtensao from './FornecedorVarejoNovaExtensao';
import Fornecedor from '../Fornecedor';
import Endereco from '../../endereco/Endereco';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'FornecedorVarejoForm',
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MetadadosContainerLayout,
    FornecedorVarejoNovaExtensao,
    Endereco,
    Confirm,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      fornecedorResource: this.$api.fornecedor(this.$resource),
      produtoResource: this.$api.produto(this.$resource),
      tipoBeneficioResources: this.$api.tipoBeneficio(this.$resource),
      buscaListaProduto: null,
      listaProdutos: [],
      filtroNomeProduto: '',
      headersProduto: [
        {
          text: '', value: 'acoes', width: '2%', sortable: false,
        },
        { text: this.$tc('label.cod_produto', 1), width: '30%', value: 'idExterno' },
        { text: this.$tc('label.nome_produto', 1), width: '60%', value: 'nomProduto' },
      ],

      fornecedor: new Fornecedor(),
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        numeroValido: (value) => {
          const msg = this.$t('message.numero_invalido');
          return (!!value && !Number.isNaN(value.toString()) && Number(value) >= 0) || msg;
        },
      },
      dialogCadastroExtensao: false,
      novaExtensao: {},
      tiposPagamento: [],
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getFornecedorMetadado',
    ]),
    ...mapGetters('implantacao', [
      'indAmbienteProdutivo',
    ]),
    metadadosFornecedor() {
      if (this.getFornecedorMetadado && this.getFornecedorMetadado.mapaEntidades) {
        return this.getFornecedorMetadado;
      }
      return undefined;
    },
  },
  watch: {
    buscaListaProduto(val) {
      if (this.timeoutProduto) {
        window.clearTimeout(this.timeoutProduto);
      }
      this.timeoutProduto = window.setTimeout(() => {
        this.buscarProdutoAutocomplete(val);
      }, 500);
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setFornecedorMetadado',
    ]),
    carregarFornecedor() {
      const { id } = this.$route.params;
      skipLoading();
      this.fornecedorResource.buscar({ id })
        .then((res) => {
          this.fornecedor = { ...this.fornecedor, ...res.body };
          this.buscarProdutoAutocomplete();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    validarCadastro() {
      this.validarEndereco();
      this.validarForm(this.salvar);
    },
    associacaoProdutoValido() {
      if (this.fornecedor.listaprodutos.length) {
        return true;
      }
      return false;
    },
    validarEndereco() {
      this.$refs.endereco.isValido(true);
    },
    enderecoValido(endereco) {
      this.fornecedor.endereco = endereco;
    },
    enderecoInvalido() {
      this.validarForm();
    },
    filtroProduto(item, queryText, itemText) {
      const codProduto = item.idExterno;
      const searchText = queryText.toLowerCase();

      return codProduto.indexOf(searchText) > -1
        || itemText.indexOf(searchText) > -1;
    },
    buscarProdutoAutocomplete(busca = null) {
      const idsProdutosSelecionados = [];
      if (this.fornecedor.listaprodutos) {
        this.fornecedor.listaprodutos.forEach((value) => {
          idsProdutosSelecionados.push(value.id);
        });
      }

      const param = {
        autocomplete: busca,
        exceto: idsProdutosSelecionados,
        limit: 30,
        page: 0,
      };

      this.produtoResource.selecao(param).then((response) => {
        this.listaProdutos = Object.assign([], response.data);
      }, (err) => {
        this.$error(this, err);
      });
    },
    removerProdutoPeloId(id) {
      this.fornecedor.listaprodutos = this.fornecedor
        .listaprodutos.filter((div) => div.id !== parseInt(id, 10));
      this.buscarProdutoAutocomplete();
      this.$forceUpdate();
    },
    setProduto(hit) {
      if (!hit) return;
      window.setTimeout(() => {
        this.filtroNomeProduto = '';
      });
      this.fornecedor.listaprodutos.push(hit);
      this.buscarProdutoAutocomplete();
    },
    validarForm(cb) {
      this.$validator.validateAll()
        .then((isValid) => {
          if (!isValid) {
            return;
          }

          if (cb) {
            cb();
          }
        }).catch();
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.$refs.confirmDialog.open();
    },
    openCancelarDialog() {
      this.$refs.cancelarDialog.open();
    },
    salvar() {
      this.fornecedor.idsProdutos = [];
      this.fornecedor.listaprodutos.forEach((value) => {
        this.fornecedor.idsProdutos.push(value.id);
      });

      const objArmazenamento = {
        ...this.fornecedor,
        ...this.$refs.container.getValoresDependencias(),
      };

      this.exibeAlertaLgpd();
      if (this.fornecedor.id) {
        this.atualizar(objArmazenamento);
      } else {
        this.inserir(objArmazenamento);
      }
    },
    exibeAlertaLgpd() {
      if (this.indAmbienteProdutivo) return;

      const alertaLgpd = this.$t('message.lgpd');
      alert(alertaLgpd); // eslint-disable-line
    },
    inserir(obj) {
      this.exibeAlertaLgpd();
      this.fornecedorResource.gravar(obj)
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.$router.push({ name: this.$route.params.from || 'fornecedor' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    atualizar(obj) {
      this.exibeAlertaLgpd();
      const { id } = obj;
      this.fornecedorResource.atualizar({ id }, obj)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
          this.$router.push({ name: this.$route.params.from || 'fornecedor' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancelar() {
      this.$router.push({ name: this.$route.params.from || 'fornecedor' });
    },
    abrirCadastro(metadados) {
      this.novaExtensao = {};
      this.novaExtensao.indAtivo = true;
      this.novaExtensao.idNivelExtensao = metadados.idNivelExtensao;
      this.novaExtensao.metadados = metadados;
      this.dialogCadastroExtensao = true;
      setTimeout(() => {
        this.$refs.fornecedorNovaExtensao.setNivel(metadados.idNivelExtensao);
      }, 3E2);
    },
    fecharCadastroExtensao() {
      this.dialogCadastroExtensao = false;
    },
    setarCadastroExtensao(obj, metadados) {
      if (metadados.manyToMany) {
        if (!this.fornecedor[metadados.label]) {
          this.fornecedor[metadados.label] = [];
        }
        this.fornecedor[metadados.label].push(obj);
      } else {
        this.fornecedor[metadados.label] = obj;
      }
      this.$refs.container.refresh();
      this.dialogCadastroExtensao = false;
    },
    buscarTiposBeneficios() {
      this.tipoBeneficioResources.listarAtivos().then((response) => {
        this.tiposPagamento = response.data;
      });
    },
  },
  beforeMount() {
    this.buscarTiposBeneficios();
    if (this.$route.params.id) {
      if (this.getFornecedorMetadado && this.getFornecedorMetadado.mapaEntidades) {
        this.carregarFornecedor();
      } else {
        this.setFornecedorMetadado({
          resource: this.metadadoResource.listarFornecedor,
          params: null,
          callback: this.carregarFornecedor,
        });
      }
    }
  },
  mounted() {
    this.buscarProdutoAutocomplete();
  },
};
</script>
