<template>
  <v-expansion-panel class="PlanejamentoDpmGrupoCulturasPainel">
    <v-expansion-panel-header class="pb-0 pt-0">
      <span v-show="painelAtivo" class="header">
        {{ grupo.grupoCultura }}
      </span>
      <v-row v-show="!painelAtivo" align="start" justify="space-between" class="pr-8">
        <span class="header" style="min-width:200px">
          {{ grupo.grupoCultura }}
        </span>
        <span class="grey--text text--darken-1 preview">
          <b>{{`${$t('label.meta_minima')}: `}}</b>{{getNumber(grupo.metaMinima)}}
        </span>
        <span class="grey--text text--darken-1 preview">
          <b>{{`${$t('label.meta_definida')}: `}}</b>{{getNumber(grupo.metaDefinida)}}
        </span>
        <span class="grey--text text--darken-1 preview">
          <b>{{`${$t('label.crescimento')}: `}}</b>{{getPercent(grupo.crescimento)}}
        </span>
        <span class="grey--text text--darken-1 preview">
          <b>{{`${$t('label.status')}: `}}</b>{{$t(`label.${grupo.status}`)}}
        </span>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-0">
      <v-container fluid>
        <v-row class="pl-10 pr-10">
          <v-col cols="12" md="3" sm="6" class="pl-0">
            <v-text-field
              :label="$t('label.meta_minima')"
              :value="getNumber(grupo.metaMinima)" dense readonly/>
          </v-col>
          <v-col cols="12" md="3" sm="6">
            <v-text-field
              :label="$t('label.meta_definida')"
              :value="getNumber(grupo.metaDefinida)"
              dense
              readonly
            />
            <v-menu
              open-on-hover
              right
              offset-x
            >
             <template v-slot:activator="{ on, attrs }">
                 <v-icon small @mouseover="abrirMetaMinima"  v-bind="attrs"  v-on="on"  style="left: 80px;top: -64px;">info</v-icon>
              </template>
               <v-card>
                <v-card-text>
                  <v-data-table
                  :headers="cabecalhoMeta"
                  :items="metas"
                  dense
                  disable-filtering
                  disable-pagination
                  disable-sort
                  hide-default-footer>
                  <template v-slot:item.pontuacaoMeta="{ item }">
                  {{getNumber(item.pontuacaoMeta)}}
                  </template>
                </v-data-table>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="8" md="3" sm="6">
            <v-text-field
              :label="$t('label.crescimento')"
              :value="getPercent(grupo.crescimento)"
              dense
              readonly
            />
            <v-menu
              open-on-hover
              right
              offset-x
            >
            <template v-slot:activator="{ on, attrs }">
                 <v-icon small @mouseover="abrirCrescimento"  v-bind="attrs"  v-on="on"  style="left: 80px;top: -64px;">info</v-icon>
            </template>
              <v-card>
                <v-card-text>
                  <v-data-table
                  :headers="cabecalhoCrescimento"
                  :items="crescimentos"
                  dense
                  disable-filtering
                  disable-pagination
                  disable-sort
                  hide-default-footer>
                </v-data-table>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3" sm="6" class="pr-0">
            <v-text-field
              :label="$t('label.status')"
              :value="$t(`label.${grupo.status}`)"
              dense
              readonly
              />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-data-table
              :headers="cabecalho"
              :items="grupo.marcas"
              dense
              disable-filtering
              disable-pagination
              disable-sort
              hide-default-footer>
              <template v-slot:item.pontoUnitario="{ item }">
                {{getNumber(item.pontoUnitario)}}
              </template>
              <template v-slot:item.volumeRealizado="{ item }">
                {{getNumber(item.volumeRealizado)}}
              </template>
              <template v-slot:item.volumeMeta="{ item }">
                <input-decimal
                  v-model="item.volumeMeta"
                  single-line
                  hide-details
                  dense
                  align-right
                  :rules="[rules.maximoPadrao]"
                  @input="volumeMetaAlterado()"
                  :disabled="somenteLeitura"
                  class="py-0 mt-0 mb-1"
                  />
              </template>
              <template v-slot:item.pontuacaoRealizada="{ item }">
                {{getNumber(item.pontuacaoRealizada)}}
              </template>
              <template v-slot:item.pontuacaoMeta="{ item }">
                {{getNumber(item.pontuacaoMeta)}}
              </template>
              <template v-slot:item.hectaresTratados="{ item }">
                {{getNumber(item.hectaresTratados)}}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { getPercent, getNumber } from '../../../../produto/common/functions/helpers';
import InputDecimal from '../../../../produto/shared-components/inputs/InputDecimal';

export default {
  props: {
    grupo: {
      type: Object,
      required: true,
    },
    painelAtivo: Boolean,
    anoAtualPeriodo: Number,
    anoAnteriorPeriodo: Number,
    somenteLeitura: Boolean,
    segmentosHabilitados: Array,
  },
  components: {
    InputDecimal,
  },
  methods: {
    getPercent,
    getNumber,
    volumeMetaAlterado() {
      setTimeout(() => this.recalculaVariaveis());
    },
    calcularMetasDialog() {
      this.metas = [];
      this.grupo.marcas.forEach((marca) => {
        if (this.segmentosHabilitados.includes(marca.segmentos ? marca.segmentos.toLowerCase() : '')) {
          const metas = this.metas.filter((m) => m.segmento === marca.segmentos);
          if (metas.length > 0) {
            const meta = metas[0];
            meta.pontuacaoMeta += marca.pontuacaoMeta;
          } else {
            this.metas.push({
              segmento: marca.segmentos,
              pontuacaoMeta: marca.pontuacaoMeta,
            });
          }
        }
      });
    },
    calcularCrescimentoDialog() {
      this.crescimentos = [];
      this.grupo.marcas.forEach((marca) => {
        if (this.segmentosHabilitados.includes(marca.segmentos ? marca.segmentos.toLowerCase() : '')) {
          const crescimentos = this.crescimentos.filter((m) => m.segmento === marca.segmentos);
          if (crescimentos.length > 0) {
            const crescimento = crescimentos[0];
            crescimento.pontuacaoRealizada += marca.pontuacaoRealizada;
            crescimento.pontuacaoMeta += marca.pontuacaoMeta;
          } else {
            this.crescimentos.push({
              segmento: marca.segmentos,
              pontuacaoRealizada: marca.pontuacaoRealizada,
              pontuacaoMeta: marca.pontuacaoMeta,
            });
          }
        }
      });
      this.crescimentos.forEach((crescimento) => {
        if (crescimento.pontuacaoRealizada === 0 && crescimento.pontuacaoMeta > 0) {
          crescimento.crescimento = getPercent(100);
        }
        if (crescimento.pontuacaoRealizada === 0 && crescimento.pontuacaoMeta === 0) {
          crescimento.crescimento = getPercent(0);
        }
        if (crescimento.pontuacaoRealizada > 0) {
          crescimento.crescimento = getPercent((((crescimento.pontuacaoMeta - crescimento.pontuacaoRealizada)
          / crescimento.pontuacaoRealizada) * 100).toFixed(2));
        }
      });
    },
    recalculaVariaveis() {
      this.grupo.marcas.forEach((item) => {
        const { volumeMeta, pontoUnitario, litroHectare } = item;
        item.pontuacaoMeta = volumeMeta * pontoUnitario;
        item.hectaresTratados = (volumeMeta === 0 || litroHectare === 0)
          ? 0 : (volumeMeta / litroHectare);
      });

      this.recalculaMetaDefinida();
      this.recalculaCrescimento();
      this.recalculaStatus();

      setTimeout(() => this.$forceUpdate());
      this.$emit('RECALCULO_VARIAVEIS');
    },
    recalculaMetaDefinida() {
      this.grupo.metaDefinida = this.grupo.marcas
        .map((i) => i.pontuacaoMeta)
        .reduce((p, a) => p + a, 0);
    },
    recalculaCrescimento() {
      const { metaDefinida, marcas } = this.grupo;

      const sumPontuacaoMeta = metaDefinida;
      const sumPontuacaoRealizada = marcas
        .map((i) => i.pontuacaoRealizada)
        .reduce((p, a) => p + a, 0);

      if (sumPontuacaoRealizada === 0) {
        this.grupo.crescimento = 0;
      } else {
        const divisor = sumPontuacaoRealizada === 0 ? 1 : sumPontuacaoRealizada;
        const crescimento = ((sumPontuacaoMeta - sumPontuacaoRealizada) / divisor) * 100;
        this.grupo.crescimento = crescimento;
      }
    },
    recalculaStatus() {
      const { metaDefinida, metaMinima } = this.grupo;
      this.grupo.status = metaDefinida < metaMinima
        ? 'nao_aderiu' : 'meta_minima';
    },
    abrirMetaMinima() {
      this.calcularMetasDialog();
      this.metaMinimaDialog = true;
    },
    abrirCrescimento() {
      this.calcularCrescimentoDialog();
      this.crescimentoDialog = true;
    },
  },
  data() {
    return {
      rules: {
        maximoPadrao: (value) => value <= 99999999.99 || this.$t('message.valor_padrao_maximo'),
      },
      cabecalho: [
        {
          text: this.$tc('label.segmento', 1),
          value: 'segmentos',
          align: 'start',
        },
        {
          text: this.$t('label.mix'),
          value: 'mix',
          align: 'start',
        },
        {
          text: this.$t('label.marca'),
          value: 'marca',
          align: 'start',
        },
        {
          text: this.$t('label.ponto_unitario'),
          value: 'pontoUnitario',
          align: 'end',
        },
        {
          text: `${this.$t('label.volume_realizado')} ${this.anoAnteriorPeriodo}`,
          value: 'volumeRealizado',
          align: 'end',
        },
        {
          text: this.$t('label.volume_meta'),
          value: 'volumeMeta',
          align: 'end',
          width: '200',
        },
        {
          text: `${this.$t('label.pontuacao_realizada')} ${this.anoAnteriorPeriodo}`,
          value: 'pontuacaoRealizada',
          align: 'end',
        },
        {
          text: this.$t('label.pontuacao_meta'),
          value: 'pontuacaoMeta',
          align: 'end',
        },
        {
          text: this.$t('label.hectares_tratados'),
          value: 'hectaresTratados',
          align: 'end',
        },
      ],
      cabecalhoMeta: [
        {
          text: this.$tc('label.segmento', 1),
          value: 'segmento',
          align: 'start',
        },
        {
          text: this.$t('label.pontuacao_meta'),
          value: 'pontuacaoMeta',
          align: 'end',
        },
      ],
      cabecalhoCrescimento: [
        {
          text: this.$tc('label.segmento', 1),
          value: 'segmento',
          align: 'start',
        },
        {
          text: this.$tc('label.crescimento', 1),
          value: 'crescimento',
          align: 'end',
        },
      ],
      metaMinimaDialog: false,
      crescimentoDialog: false,
      crescimentos: [],
      metas: [],
    };
  },
  mounted() {
    setTimeout(() => this.recalculaVariaveis());
  },
};
</script>
<style scoped>
.v-expansion-panel span.header {
  min-width: 100px;
}
.preview {
  font-size:.8rem;
}
</style>
<style>
.PlanejamentoDpmGrupoCulturasPainel .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
.PlanejamentoDpmGrupoCulturasPainel.v-expansion-panel .v-expansion-panel-header--active:before {
  background-color: var(--v-primary-lighten1);
}
.PlanejamentoDpmGrupoCulturasPainel.v-expansion-panel .v-expansion-panel-header--active span.header,
.PlanejamentoDpmGrupoCulturasPainel.v-expansion-panel .v-expansion-panel-header--active i.v-icon {
  color: var(--v-primary-darken1) !important;
}
</style>
