import getBasePath from '../functions/api-resource';

const basePath = getBasePath('negociacao', 'contrato');

const contratoLojaActions = {
  vincular: { method: 'PUT', url: `${basePath}/{codigoContrato}/loja` },
  pesquisar: { method: 'GET', url: `${basePath}/{codigoContrato}/loja` },
};

export default (resource) => resource(`${basePath}`, {}, contratoLojaActions);
