import getBasePath from '../../produto/common/functions/api-resource';
import resourceBuilder from '../../produto/common/functions/metadados-resource-builder';

export const countRelatorioSaldoAdama = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('orcamento', 'quantidade-registros-relatorios'),
  'saldo-adama',
  parametros,
).doGet();

export const countRelatorioAcoes = (parametros, resource) => resourceBuilder.buildGET(
  resource,
  getBasePath('planejamento_acao', 'adama/acao/relatorio'),
  'quantidade-registros-relatorios',
  parametros,
).doGet();
