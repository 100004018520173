export default class Passo7 {
  constructor(passo7) {
    this._idsConcorrentesVinculados = [];
    this._concorrentes = [];
    if (passo7) {
      this._idPeriodoConfiguracao = passo7.idPeriodoConfiguracao;

      if (passo7.idsConcorrentesVinculados) {
        this._idsConcorrentesVinculados = passo7.idsConcorrentesVinculados;
      }
      if (passo7.concorrentes) {
        this._concorrentes = passo7.concorrentes;
        this.preencherListaIdsConcorrentesVinculados(passo7.concorrentes);
      }
    }
  }

  get idPeriodoConfiguracao() {
    return this._idPeriodoConfiguracao;
  }

  set idPeriodoConfiguracao(value) {
    this._idPeriodoConfiguracao = value;
  }

  get concorrentes() {
    return this._concorrentes;
  }

  set concorrentes(value) {
    this._concorrentes = value;
  }

  get idsConcorrentes() {
    return this._idsConcorrentesVinculados;
  }

  set idsConcorrentes(value) {
    this._idsConcorrentesVinculados = value;
  }

  preencherListaIdsConcorrentesVinculados(listaConcorrentes) {
    this._idsConcorrentesVinculados = [];
    listaConcorrentes.forEach((concorrente) => {
      if (concorrente.habilitado) {
        this._idsConcorrentesVinculados.push(concorrente.id);
      }
    });
  }

  toJSON() {
    return {
      idPeriodoConfiguracao: this._idPeriodoConfiguracao,
      idsConcorrentes: this._concorrentes,
      idsConcorrentesVinculados: this._idsConcorrentesVinculados,
    };
  }
}
