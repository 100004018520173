<template>
  <parametrizacao-periodo-passo
    ref="parametrizacaoPeriodoPasso"
    tem-proximo
    :somente-leitura="somenteLeitura"
    @PARAMETRIZACAO_PERIODO_PASSO_PROXIMO="salvarEProximoPasso"
    @PARAMETRIZACAO_PERIODO_PASSO_SALVAR_RASCUNHO="salvarRascunho">
    <v-container fluid>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ $t('title.resumo') }}</v-toolbar-title>
      </v-toolbar>
      <v-row class="my-2">
        <v-col cols="12" md="4">
          <v-select
            id="parametrizacao_periodo_passo1_periodo"
            name="parametrizacao_periodo_passo1_periodo"
            :items="anosFiscais"
            item-text="descricao"
            item-value="id"
            v-model="passo1.idAnoFiscal"
            :label="`${$t('label.periodo')} *`"
            :rules="[rules.required]"
            @change="periodoSelecionado"
            :disabled="somenteLeitura">
          </v-select>
        </v-col>
        <v-col cols="12" md="4" offset-md="2">
          <v-select
            id="parametrizacao_periodo_passo1_contrato"
            name="parametrizacao_periodo_passo1_contrato"
            multiple
            chips
            item-text="nome"
            item-value="id"
            :items="tipoContratos"
            v-model="passo1.idsContratoTipo"
            :label="`${$t('label.tipo_contrato')} *`"
            :rules="[rules.requiredList]"
            :disabled="somenteLeitura || !this.passo1.idAnoFiscal"
            @blur="buscarElegiveisAnteriores">
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <lista-periodos
            v-if="periodosPlanejamento.length"
            :periodos="periodosPlanejamento"
            :titulo="$t('title.periodo_planejamento')">
          </lista-periodos>
        </v-col>
        <v-col cols="12" md="6">
          <lista-periodos
            v-if="periodosOrcamentos.length"
            :periodos="periodosOrcamentos"
            :titulo="$t('title.periodo_orcamento')">
          </lista-periodos>
        </v-col>
      </v-row>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ $t('title.elegibilidade_adesao') }}</v-toolbar-title>
      </v-toolbar>
      <v-row class="my-2">
        <v-col cols="12">
          <elegibilidade
            ref="elegibilidade"
            :id-periodo="idPeriodo"
            :somente-leitura="somenteLeitura"
            :alterar-finalizado="alterarFinalizado"
            :ids-extensoes-clientes-classificacao="idsExtensoesClientesClassificacao"
            :ids-extensoes-clientes-indicadores="idsExtensoesClientesIndicadores">
          </elegibilidade>
        </v-col>
      </v-row>
    </v-container>
  </parametrizacao-periodo-passo>
</template>

<script>
import ParametrizacaoPeriodoPasso from '../ParametrizacaoPeriodoPasso';
import PeriodoConfiguracao from '../ParametrizacaoPeriodo';
import Passo1 from './Passo1';
import ListaPeriodos from './ListaPeriodos';
import Elegibilidade from './Elegibilidade';

export default {
  name: 'ParametrizacaoPeriodoPasso1',
  props: {
    idPeriodo: {
      type: Number,
      default: null,
    },
    periodoConfiguracao: {
      type: PeriodoConfiguracao,
      default: null,
    },
    idsExtensoesClientesIndicadores: {
      type: Array,
    },
    idsExtensoesClientesClassificacao: {
      type: Array,
    },
    exibindo: false,
    somenteLeitura: false,
    alterarFinalizado: false,
  },
  data() {
    return {
      anoFiscal: this.$api.anoFiscal(this.$resource),
      parametrizacaoPeriodo: this.$api.parametrizacaoPeriodo(this.$resource),
      parametrizacaoPeriodoPasso1: this.$api.parametrizacaoPeriodoPasso1(this.$resource),
      passo1: new Passo1(),
      carregado: false,
      anosFiscais: [],
      periodosPlanejamento: [],
      periodosOrcamentos: [],
      tipoContratos: [],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredList: (value) => (!!value && value.length > 0) || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  components: {
    Elegibilidade,
    ListaPeriodos,
    ParametrizacaoPeriodoPasso,
  },
  methods: {
    buscarElegiveisAnteriores() {
      this.$refs.elegibilidade.recarregarBusca(this.passo1.idsContratoTipo);
    },
    periodoSelecionado() {
      this.buscarTiposContratos();
      this.buscarPeriodos();
    },
    buscarPasso() {
      if (!this.idPeriodo) return;
      const id = this.idPeriodo;
      this.parametrizacaoPeriodoPasso1.obterPasso1({ id })
        .then((response) => {
          this.passo1 = new Passo1(response.body);
        })
        .then(this.periodoSelecionado)
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarAnosFiscais() {
      const param = { };
      if (this.idPeriodo) {
        param.idPeriodoConfiguracao = this.idPeriodo;
      }
      this.parametrizacaoPeriodoPasso1.buscarAnosFiscais(param)
        .then((response) => {
          this.anosFiscais = response.body;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarPeriodos() {
      const param = {
        idAnoFiscal: this.passo1.idAnoFiscal,
        tamanhoPagina: 20000,
      };
      this.periodosPlanejamento = [];
      this.periodosOrcamentos = [];
      this.anoFiscal.buscarPeriodosAnoFiscal(param)
        .then((response) => {
          const periodos = response.data.resposta;

          periodos.forEach((periodo) => {
            if (periodo.tipo === 'PLANEJAMENTO_ORCAMENTARIO') {
              this.periodosOrcamentos.push(periodo);
            } else {
              this.periodosPlanejamento.push(periodo);
            }
          });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarTiposContratos() {
      const param = { idAnoFiscal: this.passo1.idAnoFiscal };
      if (this.idPeriodo) {
        param.idPeriodoConfiguracao = this.idPeriodo;
      }
      this.parametrizacaoPeriodoPasso1.buscarTiposContratos(param)
        .then((response) => {
          this.tipoContratos = response.body;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarPasso() {
      this.parametrizacaoPeriodoPasso1.salvarPasso1(this.passo1)
        .then((response) => {
          this.passo1.id = response.body;
          this.proximoPasso();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarPasso() {
      const id = this.idPeriodo;
      this.parametrizacaoPeriodoPasso1.atualizarPasso1({ id }, this.passo1)
        .then(() => {
          this.$emit('PARAMETRIZACAO_PERIODO_PASSO1_PROXIMO', this.passo1);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarRascunhoPasso() {
      this.parametrizacaoPeriodoPasso1.salvarRascunhoPasso1(this.passo1)
        .then((response) => {
          this.$toast(this.$t('message.rascunho_salvo'));
          this.$emit('PARAMETRIZACAO_PERIODO_PASSO1_RASCUNHO', response.body);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarRascunhoPasso() {
      const id = this.idPeriodo;
      this.parametrizacaoPeriodoPasso1.atualizarRascunhoPasso1({ id }, this.passo1)
        .then(() => {
          this.$toast(this.$t('message.rascunho_salvo'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarEProximoPasso() {
      this.preencherElegibilidade();
      this.preecherDpmPeriodoAnterior();
      if (!this.idPeriodo) {
        this.salvarPasso();
      } else {
        this.atualizarPasso();
      }
    },
    proximoPasso() {
      this.$emit('PARAMETRIZACAO_PERIODO_PASSO1_PROXIMO', this.passo1);
    },
    preencherElegibilidade() {
      this.passo1.idsElegibilidade = this.$refs.elegibilidade.getElegibilidade();
    },
    preecherDpmPeriodoAnterior() {
      this.passo1.idsDpmPeriodoAnterior = this.$refs.elegibilidade.getDpmPeriodoAnterior();
    },
    salvarRascunho() {
      if (this.$refs.parametrizacaoPeriodoPasso.validate()) {
        this.preencherElegibilidade();
        this.preecherDpmPeriodoAnterior();
        if (!this.idPeriodo) {
          this.salvarRascunhoPasso();
        } else {
          this.atualizarRascunhoPasso();
        }
      }
    },
  },
  mounted() {
    if (!this.carregado) {
      this.buscarPasso();
      this.buscarAnosFiscais();
    }
  },
};
</script>
