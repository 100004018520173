<template>
  <div>
    <v-container fluid px-0 py-2 class='Container_Tabela'>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-text-field
            v-model="pesquisaTabela"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.pesquisar', 1)}`"
            class="Barra_Pesquisa mx-4"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>

        <v-container fluid grid-list-md class="Container_Tabela py-3">
          <v-row>
            <v-col cols="12">
              <v-data-table
                id="tabela_notas"
                :headers="isSellIn || isDevolucao ? headersSellIn : headersPedido"
                :items="listaNotasDetalhado"
                :options.sync="pagination"
                :server-items-length="totalPage"
                :no-data-text="$t('label.tabela_sem_conteudo')"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                }"
                >
                <template v-if="isSellIn || isDevolucao" v-slot:item.data_emissao="{ item }">
                  {{ item.data_emissao || item.dataEmissao }}
                </template>
                <template v-if="isPedidoCarteira || isPedidoRemessa"
                          v-slot:item.data_remessa="{ item }">
                  {{ item.data_remessa || item.dataRemessa }}
                </template>

                <template v-if="isSellIn || isDevolucao"
                          v-slot:item.numero_nota_fiscal="{ item }">
                  {{ item.numero_nota_fiscal || item.numeroNotaFiscal }}
                </template>
                <template v-if="isPedidoCarteira || isPedidoRemessa"
                          v-slot:item.numero_pedido="{ item }">
                  {{ item.numero_pedido || item.numeroPedido }}
                </template>

                <template v-slot:item.cod_produto="{ item }">
                  {{ item.cod_produto || item.codProduto }}
                </template>
                <template v-slot:item.nom_produto="{ item }">
                  {{ item.nome_produto || item.nomeProduto || item.nom_produto }}
                </template>

                <template v-if="isPedidoCarteira || isPedidoRemessa" v-slot:item.cultura="{ item }">
                  {{ item.cultura }}
                </template>

                <template v-slot:item.qtd_item="{ item }">
                  {{ item.qtd_item || item.qtdItem }}
                </template>
                <template v-slot:item.valor_bruto="{ item }">
                  {{ item.valor_bruto ? getMoney(item.valor_bruto) : getMoney(item.valorBruto) }}
                </template>
                <template v-slot:item.valor_liquido="{ item }">
                  {{ item.valor_liquido ? getMoney(item.valor_liquido) : getMoney(item.valorLiquido) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  getMoney,
} from '../../produto/common/functions/helpers';

export default {
  name: 'NotasListaDetalhado',
  components: {
  },
  props: {
    visao: String,
    notaSelecionada: Object,
  },
  data() {
    return {
      resources: this.$api.notas(this.$resource),
      pesquisaTabela: '',
      listaNotasDetalhado: [],
      rowsPerPageItems: [10, 20, 30, 40, 50],
      pagination: {
        rowsPerPage: 10,
        page: 1,
        descending: true,
      },
      totalPage: 0,
      esperarDigitar: false,

      headersSellIn: [
        { text: this.$tc('label.data_emissao', 1), value: 'data_emissao', sortable: true },
        { text: this.$tc('label.numero_nf', 1), value: 'numero_nota_fiscal', sortable: true },
        { text: this.$tc('label.cod_produto', 1), value: 'cod_produto', sortable: true },
        { text: this.$tc('label.nome_produto', 1), value: 'nom_produto', sortable: true },
        { text: this.$tc('label.quantidade_produto', 1), value: 'qtd_item', sortable: true },
        { text: this.$tc('label.valor_bruto', 1), value: 'valor_bruto', sortable: true },
        { text: this.$tc('label.valor_liquido', 1), value: 'valor_liquido', sortable: true },
      ],
      headersPedido: [
        { text: this.$tc('label.data_remessa', 1), value: 'data_remessa', sortable: true },
        { text: this.$tc('label.numero_pedido', 1), value: 'numero_pedido', sortable: true },
        { text: this.$tc('label.cod_produto', 1), value: 'cod_produto', sortable: true },
        { text: this.$tc('label.nome_produto', 1), value: 'nom_produto', sortable: true },
        { text: this.$tc('label.cultura', 1), value: 'cultura', sortable: true },
        { text: this.$tc('label.quantidade_produto', 1), value: 'qtd_item', sortable: true },
        { text: this.$tc('label.valor_bruto', 1), value: 'valor_bruto', sortable: true },
        { text: this.$tc('label.valor_liquido', 1), value: 'valor_liquido', sortable: true },
      ],
    };
  },
  watch: {
    notaSelecionada: {
      handler() {
        this.buscar();
      },
    },
    pagination: {
      handler() {
        this.buscar();
      },
      deep: true,
    },
    pesquisaTabela() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  computed: {
    isSellIn() {
      if (this.visao === 'Faturamento') {
        return true;
      }
      return false;
    },

    isDevolucao() {
      if (this.visao === 'Devoluções') {
        return true;
      }
      return false;
    },

    isPedidoCarteira() {
      if (this.visao === 'Pedido em Carteira') {
        return true;
      }
      return false;
    },

    isPedidoRemessa() {
      if (this.visao === 'Pedido em Remessa') {
        return true;
      }
      return false;
    },
  },
  methods: {
    getMoney,

    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },

    buscar() {
      if (!this.notaSelecionada.ano_mes && !this.notaSelecionada.anoMes) {
        return;
      }
      const params = {
        filtro: this.pesquisaTabela,
        idCliente: this.notaSelecionada.id_cliente || this.notaSelecionada.idCliente,
        idTerritorio: this.notaSelecionada.id_territorio || this.notaSelecionada.idTerritorio,
        anoMes: this.notaSelecionada.ano_mes || this.notaSelecionada.anoMes,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      let promise = null;

      if (this.isSellIn) {
        promise = this.resources.listarNotasSellInDetalhado(params);
      } else if (this.isDevolucao) {
        promise = this.resources.listarNotasDevolucaoDetalhado(params);
      } else if (this.isPedidoCarteira) {
        promise = this.resources.listarNotasPedidoCarteiraDetalhado(params);
      } else {
        promise = this.resources.listarNotasPedidoRemessaDetalhado(params);
      }

      promise.then((response) => {
        this.listaNotasDetalhado = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
      }, (err) => {
        this.$error(this, err);
      });
    },

    pararEsperar() {
      setTimeout(() => {
        this.esperarDigitar = false;
      }, 2E2);
    },

  },
  beforeMount() {
  },
  mounted() {
    if (this.notaSelecionada.ano_mes && this.notaSelecionada.anoMes
      && this.visao && this.visao.length) {
      this.buscar();
    }
  },
};
</script>

<style>
.Container_Tabela table.v-table tbody td:first-child,.Container_Tabela  table.v-table tbody td:not(:first-child),
  .Container_Tabela table.v-table tbody th:first-child, .Container_Tabela table.v-table tbody th:not(:first-child),
  .Container_Tabela table.v-table thead td:first-child, .Container_Tabela table.v-table thead td:not(:first-child),
  .Container_Tabela table.v-table thead th:first-child, .Container_Tabela table.v-table thead th:not(:first-child) {
  padding: 0 11px;
}
.Barra_Pesquisa {
  width: 33%;
}
</style>
