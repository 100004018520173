const Orcamento = () => import('../../spa/orcamento/Orcamento');
const OrcamentoList = () => import('../../spa/orcamento/OrcamentoList');
const OrcamentoForm = () => import('../../spa/orcamento/OrcamentoForm');
const OrcamentoEdicaoForm = () => import('../../spa/orcamento/OrcamentoEdicaoForm');
const OrcamentoRateio = () => import('../../spa/orcamento/rateio/OrcamentoRateio');

export default {
  path: 'orcamento',
  component: Orcamento,
  children: [
    {
      path: '',
      name: 'orcamento',
      component: OrcamentoList,
    },
    {
      path: ':idTipoVerba/novo',
      name: 'novoOrcamento',
      component: OrcamentoForm,
      props: {
        default: false,
        somenteLeitura: false,
        copia: false,
        edicao: false,
        novo: true,
      },
    },
    {
      path: ':idOrcamento/editar',
      name: 'editarOrcamento',
      component: OrcamentoEdicaoForm,
      props: {
        default: false,
        somenteLeitura: false,
        copia: false,
        edicao: true,
        novo: false,
      },
    },
    {
      path: ':idOrcamento/editar_',
      name: 'editarOrcamento_',
      component: OrcamentoForm,
      props: {
        default: false,
        somenteLeitura: false,
        copia: false,
        edicao: true,
        novo: false,
      },
    },
    {
      path: ':idOrcamento',
      name: 'verOrcamento',
      component: OrcamentoForm,
      props: {
        default: true,
        somenteLeitura: true,
        copia: false,
        edicao: false,
        novo: false,
        fluxo: false,
      },
    },
    {
      path: ':id/fluxo',
      name: 'verOrcamentoFluxo',
      component: OrcamentoForm,
      props: {
        default: true,
        somenteLeitura: true,
        copia: false,
        edicao: false,
        novo: false,
        fluxo: true,
      },
    },
    {
      path: ':id/copiar',
      name: 'copiarOrcamento',
      component: OrcamentoForm,
      props: {
        default: true,
        somenteLeitura: false,
        copia: true,
        edicao: false,
        novo: false,
      },
    },
    {
      path: ':idOrcamento/rateio',
      name: 'rateioOrcamento',
      component: OrcamentoRateio,
      props: {
        default: true,
        somenteLeitura: false,
        copia: false,
        edicao: true,
        novo: false,
      },
    },
  ],
};
