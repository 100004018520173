<template>
  <v-card class="PlanejamentoContratoFormCampos__Card--treslinhas">
    <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-autocomplete
            id="planejamento-autocomplete-divisao"
            class="custom-autocomplete"
            v-model="contrato.divisao"
            return-object
            :rules="rules.requiredFunction('divisao', 'nome')"
            :items="divisoesFiltradas"
            :label="`${$tc('label.divisao', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nome"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaDivisao"
            :clearable="true"
            @input="divisaoAlterada"
            @click:append="() => triggerSelecao('planejamento-autocomplete-divisao')"
            @click.native="(i) => buscaAutocomplete(i, buscarDivisoes)">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
            id="planejamento-autocomplete-unidadenegocio"
            class="custom-autocomplete"
            v-model="contrato.unidadeNegocio"
            return-object
            :rules="rules.requiredFunction('unidadeNegocio', 'nomExtensao')"
            :items="unidadesNegocioDivisao"
            :label="`${$tc('label.unidade_negocio', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nomExtensao"
            item-value="id"
            required
            :disabled="somenteLeitura || desabilitaUnidadeNegocio"
            :clearable="true"
            @input="unidadeNegocioAlterada"
            @click:append="() => triggerSelecao('planejamento-autocomplete-unidadenegocio')"
            @click.native="(i) => buscaAutocomplete(i, buscarUnidadesNegocioDivisao)">
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="vigenciaEhAbertaOuHerdada">
        <v-col cols="12" md="6" class="py-0">
          <metadados-campo-dinamico-data
            :metadados="definicaoDtaInicio"
            v-model="contrato.dtaVigenciaInicio"
            :somente-leitura="somenteLeitura"
            :objeto-container="contrato"/>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <metadados-campo-dinamico-data
            :metadados="definicaoDtaFim"
            v-model="contrato.dtaVigenciaFim"
            :somente-leitura="somenteLeitura"
            :objeto-container="contrato"/>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-autocomplete
            id="planejamento-autocomplete-periodo"
            class="custom-autocomplete"
            v-model="contrato.periodoPlanejamento"
            return-object
            :rules="rules.requiredFunction('periodoPlanejamento', 'nome')"
            :items="periodosPlanejamento"
            :label="`${$tc('label.periodo', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nome"
            item-value="id"
            required
            :disabled="somenteLeitura || !novoContrato"
            :clearable="true"
            @click:append="() => triggerSelecao('planejamento-autocomplete-periodo')"
            @click.native="(i) => buscaAutocomplete(i, buscarPeriodosPlanejamento)">
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { buscaAutocomplete, triggerSelecao } from '../../../../produto/common/functions/autocomplete-utils';

export default {
  props: {
    novoContrato: Boolean,
    somenteLeitura: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
    contrato: Object,
    tipoContrato: Object,
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      planoContratoAdamaResource: this.$api.planoContratoAdama(this.$resource),

      definicaoDtaInicio: {
        labelCampo: 'data_inicio',
        nomCampoId: 'dtaInicio',
        tipoCampo: 'DATA',
        vlrObrigatorio: true,
      },
      definicaoDtaFim: {
        labelCampo: 'data_fim',
        nomCampoId: 'dtaFim',
        tipoCampo: 'DATA',
        vlrObrigatorio: true,
      },

      divisoesFiltradas: [],
      divisoesUsuario: [],
      unidadesNegocioDivisao: [],
      periodosPlanejamento: [],
      periodoPlanejamentoModel: null,

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredFunctionOpcional: (objLabel, valueLabel, obrigatorio) => [
          () => {
            if (!obrigatorio) {
              return true;
            }

            const objeto = this.contrato[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
        requiredFunction: (objLabel, valueLabel) => [
          () => {
            const objeto = this.contrato[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
      },
    };
  },
  computed: {
    vigenciaEhAbertaOuHerdada() {
      const { tipoVigencia } = this.configuracao;
      return ['VIGENCIA_ABERTA', 'HERDAR_PERIODO_ACAO']
        .includes(tipoVigencia);
    },
    desabilitaDivisao() {
      return !this.novoContrato || this.divisoesUsuario.length <= 1;
    },
    desabilitaUnidadeNegocio() {
      const { divisao } = this.contrato;
      if (!divisao || !divisao.id || this.unidadesNegocioDivisao.length <= 1) {
        return true;
      }
      return false;
    },
    desabilitaPeriodo() {
      return this.periodosPlanejamento.length === 1;
    },
  },
  watch: {
    periodoPlanejamentoModel: {
      handler() {
        this.contrato.periodoPlanejamento = this.periodoPlanejamentoModel;
        this.setPlanejamentoPeriodo(this.periodoPlanejamentoModel);
      },
    },
  },
  methods: {
    ...mapActions('contratoPeriodo', [
      'setPlanejamentoPeriodo',
    ]),
    buscaAutocomplete,
    triggerSelecao,
    buscarDivisoes(autocomplete) {
      this.divisoesFiltradas = this.divisoesUsuario
        .filter((divisao) => divisao.nome.indexOf(autocomplete) >= 0);
    },
    buscarDivisoesUsuario() {
      this.planejamentoAcaoResource.buscarDivisoes()
        .then((res) => {
          this.divisoesUsuario = res.data;
          this.divisoesFiltradas = res.data;
          if (this.divisoesUsuario.length === 1) {
            const unicaDivisao = this.divisoesUsuario[0];
            this.contrato.divisao = { ...unicaDivisao };
            this.buscarUnidadesNegocioDivisao();
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarUnidadesNegocioDivisao(autocomplete) {
      const idDivisao = this.contrato.divisao.id;
      const parametros = { idDivisao, autocomplete };
      return this.planejamentoAcaoResource
        .buscarUnidadesNegocioDivisao(parametros)
        .then((res) => {
          this.unidadesNegocioDivisao = res.data;
          if (this.unidadesNegocioDivisao.length === 1) {
            const unicaUnidade = this.unidadesNegocioDivisao[0];
            this.contrato.unidadeNegocio = { ...unicaUnidade };
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarPeriodosPlanejamento(autocomplete) {
      const parametros = {
        autocomplete,
        idTipoContrato: this.tipoContrato.id,
      };
      return this.planoContratoAdamaResource
        .buscarPeriodosVigentePlanejamento(parametros)
        .then((res) => {
          this.periodosPlanejamento = res.data;
          if (this.periodosPlanejamento.length === 1) {
            const unicoPeriodo = this.periodosPlanejamento[0];
            this.periodoPlanejamentoModel = { ...unicoPeriodo };
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    divisaoAlterada() {
      if (this.contrato.unidadeNegocio) {
        this.contrato.unidadeNegocio = {};
      }
      this.buscarUnidadesNegocioDivisao();
    },
    unidadeNegocioAlterada() {
      this.$emit('PlanejamentoContratoForm__CampoProdutoAlterado');
      this.limpaFocoContrato();
    },
    validarDatas() {
      const dataInicial = moment(this.contrato.dtaVigenciaInicio);
      const dataFinal = moment(this.contrato.dtaVigenciaFim);

      if (dataFinal.isBefore(dataInicial)) {
        this.$toast(this.$t('message.data_inicial_maior_final'));
        return false;
      }

      return true;
    },
    setContratoEdicao(contrato) {
      const { unidadeNegocio, periodoPlanejamento, divisao } = contrato;
      this.divisoesFiltradas = [divisao];
      this.unidadesNegocioDivisao = [unidadeNegocio];
      if (periodoPlanejamento) {
        this.periodosPlanejamento = [periodoPlanejamento];
      }
    },
  },
  beforeMount() {
    this.setPlanejamentoPeriodo(null);
    if (this.novoContrato) {
      this.buscarPeriodosPlanejamento();
    }
  },
  mounted() {
    if (!this.somenteLeitura && this.novoContrato) {
      this.buscarDivisoesUsuario();
    }
  },
};
</script>
