import getBasePath from '../functions/api-resource';

const basePath = getBasePath('workflow_estrutural', 'execucao/transferencia');

const workflowTransferenciaActions = {
  aprovador: { method: 'GET', url: `${basePath}/{id}/passos/aprovador` },
  aprovar: { method: 'PUT', url: `${basePath}/{id}/passos/aprovar` },
  cancelar: { method: 'PUT', url: `${basePath}/{idAjuste}/cancelar` },
  gravar: { method: 'POST', url: `${basePath}/{id}` },
  pendentes: { method: 'GET', url: `${basePath}/busca-fluxo-pendente` },
  reprovar: { method: 'PUT', url: `${basePath}/{id}/passos/reprovar` },
  status: { method: 'GET', url: `${basePath}/{id}/status` },
  statusPasso: { method: 'GET', url: `${basePath}/{idTransferencia}/passos/status` },
};

export default (resource) => resource(`${basePath}`, {}, workflowTransferenciaActions);
