<template>
  <v-card style="margin-bottom:1em" class="RelatorioExportacoesSaldoFiltros">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.pagamento_exportacao', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px">
          fa-thumbtack
        </v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <v-row >
          <v-col sm="6" md="4" col="12">
            <v-autocomplete
              chips
              clearable
              deletable-chips
              multiple
              id="idStatus"
              name="nomeStatus"
              :items="listaStatus"
              v-model="filtrosExportacaoPagamento.status"
              item-text="texto"
              item-value="valor"
              :label="`${$tc('label.status', 1)} *`"
              :rules="[rules.required, rules.validaStatus]"
            >
            </v-autocomplete>
          </v-col>
          <v-col sm="6" md="4" col="12">
            <relatorio-exportacao-data
              :filtrosExportacaoPagamento="filtrosExportacaoPagamento"
              :label="`${$t('label.data_inicio')}`"
              :tipoData="'data_inicio'"
              >
            </relatorio-exportacao-data>
          </v-col>
          <v-col sm="6" md="4" col="12">
            <relatorio-exportacao-data
              :filtrosExportacaoPagamento="filtrosExportacaoPagamento"
              :label="`${$t('label.data_fim')}`"
              :tipoData="'data_fim'"
              >
            </relatorio-exportacao-data>
          </v-col>
        </v-row >
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :objeto="filtrosExportacaoPagamento"
          ref="container">
        </metadados-container-layout>
        <v-row >
          <v-col sm="6" md="4" col="12">
            <v-autocomplete
              id="campo_nome_fantasia"
              name="campo_nome_fantasia"
              v-model="filtrosExportacaoPagamento.ids_nome_fantasia"
              :items="listaNomesFantasia"
              @click.native="buscarNomesFantasia"
              :search-input.sync="buscaListaNomesFantasia"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomExtensao"
              item-value="id"
              :label="`${$tc('label.nome_fantasia', 1)}`"
              chips
              clearable
              deletable-chips
              multiple
              >
            </v-autocomplete>
          </v-col>
          <v-col sm="6" md="4" col="12">
            <relatorio-exportacao-data
              :filtrosExportacaoPagamento="filtrosExportacaoPagamento"
              :label="`${$t('label.data_inicio_pagamento')}`"
              :tipoData="'data_inicio_pagamento'"
              >
            </relatorio-exportacao-data>
          </v-col>
          <v-col sm="6" md="4" col="12">
            <relatorio-exportacao-data
              :filtrosExportacaoPagamento="filtrosExportacaoPagamento"
              :label="`${$t('label.data_fim_pagamento')}`"
              :tipoData="'data_fim_pagamento'"
              >
            </relatorio-exportacao-data>
          </v-col>
        </v-row >
        <v-row >
          <v-col sm="6" md="4" col="12">
            <v-autocomplete
              id="tipo_pagamento"
              name="tipo_pagamento"
              v-model="filtrosExportacaoPagamento.tipo_pagamento"
              :items="listaTiposPagamentos"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="descricao"
              item-value="codTipo"
              :label="`${$tc('label.tipo_pagamento', 1)}`"
              chips
              deletable-chips
              clearable
              multiple
              @focusin.native="buscarTiposPagamentos">
            </v-autocomplete>
          </v-col>
        </v-row >
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import countRelatorioPagamentos from '@/common/resources/quantidade-registro-relatorio';
import { skipLoading } from '../../../../produto/common/functions/loading';
import MetadadosContainerLayout from '../../../../produto/shared-components/metadados/MetadadosContainerLayout';
import RelatorioExportacaoAcaoPagamentoFiltrosCampos from './RelatorioExportacaoAcaoPagamentoFiltrosCampos';
import RelatorioExportacaoData from './RelatorioExportacaoData';

export default {
  name: 'RelatorioExportacaoAcaoPagamento',
  mixins: [
    RelatorioExportacaoAcaoPagamentoFiltrosCampos,
  ],
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
    RelatorioExportacaoData,
  },
  data() {
    return {
      extensaoAdama: this.$api.extensaoAdama(this.$resource),
      ordenacaoCampos: [
        'tipo_verba',
        'periodo_orcamentario',
        'tipo_linha_investimento',
        'unidadenegocio',
        'divisao_adama',
        'tipo_acao',
      ],
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosExportacaoPagamento: {
        data_inicio: null,
        data_fim: null,
        data_inicio_pagamento: null,
        data_fim_pagamento: null,
        status: [],
      },
      listaTiposPagamentos: [],
      buscaListaNomesFantasia: null,
      listaNomesFantasia: [],
      listaStatus: [
        {
          texto: this.$t('status_entidade_pagamento.aguardando_pagamento'),
          valor: 'AGUARDANDO_PAGAMENTO',
        },
        {
          texto: this.$tc('status_entidade_pagamento.aprovado', 1),
          valor: 'APROVADO',
        },
        {
          texto: this.$tc('status_entidade_pagamento.programado', 1),
          valor: 'PROGRAMADO',
        },
        {
          texto: this.$tc('status_entidade_pagamento.liquidado', 1),
          valor: 'LIQUIDADO',
        },
        {
          texto: this.$t('status_entidade_pagamento.bloqueado'),
          valor: 'BLOQUEADO',
        },
        {
          texto: this.$t('status_entidade_pagamento.cancelado'),
          valor: 'CANCELADO',
        },
      ],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        validaStatus: (value) => (!value || (value.length > 0)) || this.$t('message.campo_obrigatorio'),

      },
    };
  },
  watch: {
    buscaListaNomesFantasia(val) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (val != null) this.buscarNomesFantasia(val);
      }, 500);
    },
  },
  methods: {
    buscarNomesFantasia(busca = null) {
      if (typeof busca !== 'string') {
        busca = null;
      }
      const param = {
        autocomplete: busca,
      };
      return this.extensaoAdama.buscarNomesFantasiaRelatorio(param)
        .then((response) => {
          this.listaNomesFantasia = response.data;
        }).catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarTiposPagamentos() {
      this.exportacaoAcaoPagamentoResource.listarTiposPagamentos()
        .then((response) => {
          this.listaTiposPagamentos = response.data;
        }).catch((err) => {
          this.$toast(err.data.error);
        });
    },
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio || filtrosAplicados.data_fim) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
    filtroPeriodoPagamentoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio_pagamento || filtrosAplicados.data_fim_pagamento) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio_pagamento, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim_pagamento, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio_pagamento && !filtrosAplicados.data_fim_pagamento)
          || (!filtrosAplicados.data_inicio_pagamento && filtrosAplicados.data_fim_pagamento)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
    resetaFiltros() {
      this.filtrosExportacaoPagamento = {};
    },
    preencheFiltrosCustomizados(params) {
      const tiposPagamentos = this.filtrosExportacaoPagamento && this.filtrosExportacaoPagamento.tipo_pagamento;
      const idsNomeFantasia = this.filtrosExportacaoPagamento && this.filtrosExportacaoPagamento.ids_nome_fantasia;
      if (tiposPagamentos) {
        params.tipo_pagamento = tiposPagamentos;
      }
      if (idsNomeFantasia) {
        params.ids_nome_fantasia = idsNomeFantasia;
      }
    },
    aplicarFiltros() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroPeriodoValido(this.$refs.container.getValoresCamposPadrao())) {
        return;
      }
      if (!this.filtroPeriodoPagamentoValido(this.$refs.container.getValoresCamposPadrao())) {
        return;
      }
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };
      this.preencheFiltrosCustomizados(params);

      let quantidadeRegistros = 0;
      countRelatorioPagamentos(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > 0) {
            this.$emit('RelatorioExportacaoAcaoPagamentoAdama__AplicaFiltros',
              params,
              'acao_pagamentos_adama',
              'job');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'relatorio-acao-pagamento';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'relatorio-acao-pagamento';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosAjusteVerba;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
  },
};
</script>
<style>
  .RelatorioExportacoesSaldoFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioExportacoesSaldoFiltros__Opcoes div.v-input__slot,
  .RelatorioExportacoesSaldoFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioExportacoesSaldoFiltros__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioExportacoesSaldoFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
