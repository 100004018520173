export default class Passo3 {
  constructor(id, tipoVigencia = '', listaFluxosTrade = [], fechamentoParcial = false,
    coeficienteCalculo = false) {
    this.id = id;
    this.tipoVigencia = tipoVigencia;
    this.listaFluxosTrade = listaFluxosTrade;
    this.fechamentoParcial = fechamentoParcial;
    this.coeficienteCalculo = coeficienteCalculo;
  }
}
