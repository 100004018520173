<template>
  <div>
    <v-container fluid>
      <v-row align="center" justify="space-between" class="font-weight-black">
        <v-col cols="12" sm="11" class="pa-0">
          <v-breadcrumbs :items="breadcrumbs" divider="/" v-if="!omiteBreadcrumbs">
            <template v-slot:item="props">
              <v-breadcrumbs-item
                :disabled="props.item.disabled"
                :class="{
                  'ApuracaoAcao_breadcrumbs-item': true,
                  'ApuracaoAcao_breadcrumbs-item_disabled': props.item.disabled,
                }"
                @click.native="navegarBreadcrumbs(props.item)">
                {{ props.item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" class="text-right pa-0 pr-3" v-if="exibirFiltro">
          <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <apuracao-acao-filtro
      v-if="exibirFiltro"
      v-model="abrirFiltro"
      :aplicar-filtro-no-carregamento="aplicarFiltroNoCarregamento"
      @ApuracaoAcaoFiltro__AplicaFiltros="aplicarFiltros"
      @ApuracaoAcaoFiltro__filtroCarregado="aplicarFiltroNoCarregamento = false">
    </apuracao-acao-filtro>

    <apuracao-cards-totalizadores
      ref="cards_totalizadores"
      v-if="exibirFiltro"
      :filtros="filtros">
    </apuracao-cards-totalizadores>

    <transition name="slide-fade"
                mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import ApuracaoAcaoFiltro from './ApuracaoAcaoFiltro';
import ApuracaoCardsTotalizadores from './ApuracaoCardsTotalizadores';
import ApuracaoAcaoControleBreadcrumbs from './ApuracaoAcaoControleBreadcrumbs';

export default {
  name: 'ApuracaoAcao',
  components: {
    ApuracaoCardsTotalizadores,
    ApuracaoAcaoFiltro,
    ApuracaoAcaoControleBreadcrumbs,
  },
  mixins: [ApuracaoAcaoControleBreadcrumbs],
  data() {
    return {
      abrirFiltro: false,
      omiteBreadcrumbs: false,
      aplicarFiltroNoCarregamento: true,
      filtros: null,
    };
  },
  computed: {
    ...generateComputed('APU_ACAO', [
      'canAccessPage',
    ]),
    ...generateComputed('ACESSO_CLIENTE', [
      'getAllRoles',
    ]),
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroApuracaoAcao',
      'setFiltroApuracaoAcaoNivel1',
    ]),
    accessForbidden() {
      this.$router.push({ name: 'inicio' });
      this.$toast(this.$t('message.acesso_negado'));
    },
    aplicarFiltros(filtrosAplicados) {
      const filtros = {
        ...filtrosAplicados,
      };
      this.filtros = filtros;
      this.setFiltroApuracaoAcao(filtros);
      this.setFiltroApuracaoAcaoNivel1(null);
    },
  },
  beforeMount() {
    this.setFiltroApuracaoAcao(null);
    this.setFiltroApuracaoAcaoNivel1(null);
    this.setFiltroApuracaoAcaoNivel2(null);

    const { origem } = this.$route.params;
    if (origem) {
      this.omiteBreadcrumbs = true;
    }
  },
  mounted() {
    if (!this.canAccessPage) {
      if (!this.getAllRoles
          || !this.getAllRoles.filter((el) => el.startsWith('ACESSO_CLIENTE')).length) {
        window.setTimeout(() => this.accessForbidden(), 1E3);
      }
    }
  },
};
</script>
<style scoped>
  .ApuracaoAcao_breadcrumbs-item_disabled {
    color: grey;
    pointer-events: none;
  }
  .ApuracaoAcao_breadcrumbs-item {
    color: grey;
    cursor: pointer;
  }
  .ApuracaoAcao_breadcrumbs-item:hover {
    color: #3f3f3f;
  }
</style>
