import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'tipo-investimento');
const basePathPlanejamento = getBasePath('orcamento', 'tipo-investimento');

const tipoInvestimentoActions = {
  ...genericActions(basePath),
  listarAtivos: { method: 'GET', url: `${basePath}?descInvestimento={nome}&indAtivo=true` },
  listarTiposLinhasInvestimentos: { method: 'GET', url: `${basePathPlanejamento}?colunaOrdenacao=desc_investimento` },
  listarLinhasInvestimentos: { method: 'GET', url: `${basePathPlanejamento}/{idTipoInvestimento}/linha-investimento` },
  ativos: { method: 'GET', url: `${basePath}/ativos` },
  ativarMassa: { method: 'PUT', url: `${basePath}/ativacao-massa` },
  buscaTipoInvestimento: { method: 'GET', url: `${basePath}/{idTipoInvestimento}` },
};

export default (resource) => resource(`${basePath}`, {}, tipoInvestimentoActions);
