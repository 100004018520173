<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="headline">{{ $tc('label.pagamento', 1)}}</v-expansion-panel-header>
    <v-expansion-panel-content>
      <apuracao-acao-detalhes-pagamentos-clientes-tabela
        class-table="elevation-0"
        :id-acao="acao.idAcao">
      </apuracao-acao-detalhes-pagamentos-clientes-tabela>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ApuracaoAcaoDetalhesPagamentosClientesTabela from './ApuracaoAcaoDetalhesPagamentosClientesTabela';

export default {
  name: 'ApuracaoAcaoDetalhesPagamentosClientes',
  components: { ApuracaoAcaoDetalhesPagamentosClientesTabela },
  props: {
    acao: {
      type: Object,
      default: {},
    },
  },
};

</script>
