var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:((_vm.item.label || '') + "__Wrapper")},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('metadados-extensao-form',{ref:((_vm.item.label || '') + "Form"),attrs:{"id":_vm.idUnidade,"idNivelExtensao":_vm.item.idNivelExtensao,"tipoCadastro":_vm.$tc(("label." + (_vm.item.label)), 1),"somenteLeitura":_vm.somenteLeitura,"input-layout":_vm.inputLayout},on:{"atualizarGrid":_vm.filtrar,"limparId":_vm.limparId}})],1),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('div',{staticClass:"__ContentTable"},[_c('v-card',{staticClass:"mb-1 mt-2"},[(_vm.item && _vm.item.label)?_c('v-card-title',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$tc(("label." + (_vm.item.label)))))])]):_vm._e(),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},on:{"input":_vm.filtrar},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.unidades,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
              itemsPerPageOptions: [10, 25, 50],
            }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(!_vm.somenteLeitura)?{key:"item.acoes",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editarCampo(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(((_vm.$t('label.editar')) + " " + (_vm.$t(("label." + _vm.labelEntidade))))))])])]}}:null,{key:"item.id_externo",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.idExterno)+" ")]}},{key:"item.nom_extensao",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomExtensao)+" ")]}},{key:"item.ind_ativo",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.indAtivo ? _vm.$t('label.sim') : _vm.$t('label.nao'))+" ")]}}],null,true)})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }