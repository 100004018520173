<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ `${$t('title.configuracao')} - ${rebate.nomTipoInvestimento}` }}</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-form ref="formConfiguracoes" lazy-validation>
          <v-row v-if="!somenteLeitura || alterarFinalizado || alterarPercentuais">
            <v-col cols="12" sm="6" md="3">
              <v-select
                id="parametrizacao_periodo_passo6_classificacao"
                name="parametrizacao_periodo_passo6_classificacao"
                item-text="nomExtensao"
                item-value="id"
                return-object
                chips
                clearable
                :items="classificacoes"
                v-model="rebateConfiguracao.classificacao"
                :label="`${$t('label.classificacao')} *`"
                :rules="[rules.requiredSelect]"
                :disabled="somenteLeitura && !alterarFinalizado"
                required>
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-select
                id="parametrizacao_periodo_passo6_extensao_cliente"
                name="parametrizacao_periodo_passo6_extensao_cliente"
                item-text="nomExtensao"
                item-value="id"
                return-object
                multiple
                chips
                clearable
                :items="extensoesCliente"
                v-model="rebateConfiguracao.extensoesCliente"
                :label="`${$t('label.cluster')} *`"
                :rules="[rules.requiredLista]"
                :disabled="somenteLeitura && !alterarFinalizado">
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-radio-group
                row
                v-model="todasMarcas"
                :rules="[rules.required]"
                :disabled="somenteLeitura"
                id="config_todas_marcas">
                <v-radio label="Marcas específicas" value="nao"></v-radio>
                <v-radio label="Todas" value="sim"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                v-if="todasMarcas === 'nao'"
                id="parametrizacao_periodo_passo6_extensao_produto"
                name="parametrizacao_periodo_passo6_extensao_produto"
                item-text="nomExtensao"
                item-value="id"
                return-object
                multiple
                chips
                clearable
                :items="extensoesProduto"
                :rules="[rules.requiredLista]"
                v-model="rebateConfiguracao.extensoesProduto"
                :label="`${$t('label.marca') + ' *'}`"
                :disabled="((somenteLeitura && !alterarFinalizado)
                  || (rebateConfiguracao.classificacao.length <=0) || (rebateConfiguracao.extensoesCliente.length <=0))"
                @click.native="buscarExtensoesProduto()">
              </v-autocomplete>
              <v-autocomplete
                v-if="todasMarcas === 'sim'"
                id="parametrizacao_periodo_passo6_extensao_produto"
                name="parametrizacao_periodo_passo6_extensao_produto"
                item-text="nomExtensao"
                item-value="id"
                return-object
                multiple
                chips
                clearable
                :items="extensoesProduto"
                v-model="rebateConfiguracao.extensoesProduto"
                :label="`${$t('label.marca')}`"
                :disabled="((somenteLeitura && !alterarFinalizado)
                  || (rebateConfiguracao.classificacao.length <=0) || (rebateConfiguracao.extensoesCliente.length <=0) || todasMarcas === 'sim')"
                @click.native="buscarExtensoesProduto()">
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="!somenteLeitura || alterarFinalizado || alterarPercentuais">
            <v-col cols="12" sm="6" md="3">
              <p>{{ `${$t('label.tipo_valor')} *` }}</p>
              <v-radio-group
                row
                v-model="rebateConfiguracao.tipoValor"
                :rules="[rules.required]"
                id="config_tipo_valor">
                <v-radio :label="$t('label.percentual')" value="PERCENTUAL"></v-radio>
                <v-radio :label="$t('label.unidade_dinheiro_litro')" value="REAL_LITRO"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <input-decimal
                :label="`${$tc('label.valor', 1)} *`"
                v-model="rebateConfiguracao.valor"
                :suffix="sufixo(rebateConfiguracao.tipoValor)"
                :disabled="somenteLeitura && !alterarFinalizado && !alterarPercentuais"
                :rules="[rules.required, rules.numeroValido]"
                class="v-text-field"/>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <input-decimal
                :label="`${$tc('label.perc_provisao', 1)} *`"
                v-model="rebateConfiguracao.percProvisao"
                suffix="%"
                :disabled="somenteLeitura && !alterarFinalizado && !alterarPercentuais"
                :rules="[rules.required, rules.numeroValido]"
                class="v-text-field"/>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <input-decimal
                :label="`${$tc('label.imposto', 2)} *`"
                v-model="rebateConfiguracao.impostos"
                :disabled="somenteLeitura && !alterarFinalizado && !alterarPercentuais"
                :rules="[rules.required, rules.numeroValido]"
                v-if="rebate.idExterno === 'FV'"
                class="v-text-field"/>
            </v-col>
          </v-row>
          <v-row justify="end" v-if="!somenteLeitura || alterarFinalizado || alterarPercentuais">
            <v-btn color="secondary" :disabled="somenteLeitura && !edicaoConfiguracao" @click="limpar" class="mr-3">{{ edicaoConfiguracao ?
                                                                                                                      $t('label.cancelar') :
                                                                                                                      $t('label.limpar') }}</v-btn>
            <v-btn color="primary" @click="adicionar" class="mr-3">{{ edicaoConfiguracao ?
                                                                   $t('label.salvar') :
                                                                   $t('label.adicionar') }}</v-btn>
          </v-row>
        </v-form>
        <br />
        <v-row>
          <v-data-table
            style="width: 100%;"
            :headers="headers"
            :items="rebate.rebatesConfiguracao">
            <template v-slot:item.nomesExtensoesProduto="{ item }">
              {{ item.extensoesProduto && item.extensoesProduto.length ? item.nomesExtensoesProduto : $t('label.todos') }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn text icon @click="editar(item)" v-if="!edicaoConfiguracao && (!somenteLeitura || item.adicionadoAgora || alterarPercentuais)">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn text icon @click="abrirModalExcluirItem(item)" v-if="!somenteLeitura || item.adicionadoAgora">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.valor="{ item }">
              {{ getNumber(item.valor) }} {{ sufixo(item.tipoValor) }}
            </template>
            <template v-slot:item.percProvisao="{ item }">
              {{ getNumber(item.percProvisao) }} %
            </template>
            <template v-slot:item.impostos="{ item }">
              {{ getNumber(item.impostos) }}
            </template>
          </v-data-table>
        </v-row>
      </v-container>
      <confirm ref="confirm" :message="message" @agree="agree"></confirm>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Rebate from './Rebate';
import InputDecimal from '../../../../produto/shared-components/inputs/InputDecimal';
import RebateConfiguracao from './RebateConfiguracao';
import Confirm from '../../../../produto/shared-components/vuetify/dialog/Confirm';
import { getNumber, getPercent } from '../../../../produto/common/functions/helpers';

export default {
  name: 'RebateConfiguracoes',
  components: { InputDecimal, Confirm },
  props: {
    rebate: {
      type: Rebate,
      default: () => (new Rebate()),
    },
    idPeriodo: {
      type: Number,
      default: null,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parametrizacaoPeriodoPasso6: this.$api.parametrizacaoPeriodoPasso6(this.$resource),
      rebateConfiguracao: new RebateConfiguracao(),
      dialog: false,
      classificacoes: [],
      extensoesProduto: [],
      extensoesCliente: [],
      index: 0,
      carregado: false,
      edicaoConfiguracao: false,
      todasMarcas: 'nao',
      message: '',
      itemSelecionado: {},
      agree: () => {},
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredSelect: (value) => (!!value && !!value.id) || this.$t('message.campo_obrigatorio'),
        requiredLista: (value) => (!!value && value.length > 0) || this.$t('message.campo_obrigatorio'),
        numeroValido: (value) => (!!value && !Number.isNaN(value.toString()) && Number(value) >= 0) || this.$t('message.numero_invalido'),
      },
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    headers() {
      const headers = [];
      headers.push({
        text: '', sortable: false, value: 'action', width: '10%',
      });
      headers.push({ text: this.$t('label.classificacao'), value: 'nomesClassificacoes' });
      headers.push({ text: this.$t('label.cluster'), value: 'nomesExtensoesCliente' });
      headers.push({ text: this.$t('label.marca'), value: 'nomesExtensoesProduto' });
      headers.push({ text: this.$tc('label.valor', 1), value: 'valor' });
      headers.push({ text: this.$tc('label.perc_provisao', 1), value: 'percProvisao' });
      if (this.rebate.idExterno === 'FV') {
        headers.push({ text: this.$tc('label.imposto', 2), value: 'impostos' });
      }
      return headers;
    },
    alterarPercentuais() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('ALTERAR_REBATE_FINALIZADO')).length;
    },
  },
  watch: {
    rebate(val) {
      if (val) {
        this.preencherIndex();
      }
    },
  },
  methods: {
    getNumber,
    getPercent,
    limpar() {
      this.rebateConfiguracao = new RebateConfiguracao();
      this.$refs.formConfiguracoes.resetValidation();

      if (this.edicaoConfiguracao) {
        this.edicaoConfiguracao = false;
        this.alterarFinalizado = true;
      }
    },
    abrirModalExcluirItem(item) {
      this.message = this.$t('message.deseja_excluir', { text: this.$tc('label.configuracao', 1).toLowerCase() });
      this.itemSelecionado = item;
      this.agree = this.excluir;
      this.$refs.confirm.open();
    },
    excluir() {
      this.rebate.removerItem(this.itemSelecionado);
      this.$emit('RebateConfiguracoes_configAlteradas');
      this.limpar();
    },
    editar(item) {
      this.edicaoConfiguracao = true;
      this.alterarFinalizado = false;
      console.log(item);

      this.rebateConfiguracao = new RebateConfiguracao(this.rebate.retornarConfiguracao(item));
      console.log(this.rebateConfiguracao);
    },
    adicionar() {
      if (this.validarConflito()) {
        this.$toast(this.$t('message.rebate_configuracao_ja_existe'));
        return;
      }
      if (!this.$refs.formConfiguracoes.validate()) {
        return;
      }
      if (!this.rebateConfiguracao.index) {
        this.index += 1;
        this.rebateConfiguracao.index = this.index;
        this.rebateConfiguracao.adicionadoAgora = true;
        this.rebate.rebatesConfiguracao.unshift(this.rebateConfiguracao);
      } else {
        const rebateConf = this.rebate.retornarConfiguracao(this.rebateConfiguracao);
        rebateConf.classificacao = this.rebateConfiguracao.classificacao;
        rebateConf.extensoesProduto = this.rebateConfiguracao.extensoesProduto;
        rebateConf.extensoesCliente = this.rebateConfiguracao.extensoesCliente;
        rebateConf.tipoValor = this.rebateConfiguracao.tipoValor;
        rebateConf.valor = this.rebateConfiguracao.valor;
        rebateConf.percProvisao = this.rebateConfiguracao.percProvisao;
        rebateConf.impostos = this.rebateConfiguracao.impostos;
      }

      if ((this.somenteLeitura) && (this.rebateConfiguracao.extensoesProduto.length >= 1)) {
        this.rebateConfiguracao.extensoesProduto.forEach((narca) => {
          this.parametrizacaoPeriodoPasso6.atualizarMarcasFortes({ idPeriodo: this.idPeriodo, idMarca: narca.id },
            { idPeriodo: this.idPeriodo, idMarca: narca.id })
            .catch((err) => {
              this.$error(this, err);
            });
        });
      }

      if (this.edicaoConfiguracao) {
        this.alterarFinalizado = true;
        this.edicaoConfiguracao = false;
      }

      this.$emit('RebateConfiguracoes_configAlteradas');
      this.limpar();
    },
    validarConflito() {
      return this.rebate.validarConflitosVinculos(this.rebateConfiguracao);
    },
    open() {
      this.dialog = true;
      if (!this.carregado) {
        this.carregado = true;
        this.buscarClassificacoes();
        this.buscarExtensoesCliente();
      }
    },
    close() {
      this.dialog = false;
      this.carregado = false;

      if (this.edicaoConfiguracao) {
        this.rebateConfiguracao = new RebateConfiguracao();
        this.$refs.formConfiguracoes.resetValidation();

        this.alterarFinalizado = true;
        this.edicaoConfiguracao = false;
      }
    },
    preencherIndex() {
      this.index = 0;
      this.rebate.rebatesConfiguracao.forEach((ind) => {
        this.index += 1;
        ind.index = this.index;
      });
    },
    buscarClassificacoes() {
      const id = this.idPeriodo;
      this.parametrizacaoPeriodoPasso6.buscarClassificacoes({ id }, { id })
        .then((response) => {
          this.classificacoes = (response.body || [])
            .map((c) => ({ id: c.idExtensao, nomExtensao: c.nomExtensao }));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarExtensoesProduto() {
      const param = {
        mnemonico: 'MARCA',
        id: this.idPeriodo,
      };
      this.buscarExtensoes(param,
        (response) => {
          this.extensoesProduto = response.body;
          this.filtrarMarcas();
        });
    },
    filtrarMarcas() {
      const extensoesSalvas = this.rebate.rebatesConfiguracao.map((config) => {
        const extensoes = config.extensoesProduto.map((extensao) => extensao);
        const extensoesCliente = config.extensoesCliente.map((extensaoCliente) => extensaoCliente.id);
        return {
          idClassificacao: config.classificacao.id,
          extensoes,
          extensoesCliente,
        };
      });

      for (let validador = 0; validador < 5; validador += 1) {
        this.extensoesProduto.forEach((extensaoProd) => {
          extensoesSalvas.forEach((ext) => {
            let equals = ext.idClassificacao === this.rebateConfiguracao.classificacao.id;

            if (!equals) return;

            equals = false;
            for (let index = 0; index < ext.extensoesCliente.length; index += 1) {
              for (let index2 = 0; index2 < this.rebateConfiguracao.extensoesCliente.length; index2 += 1) {
                if (ext.extensoesCliente[index] === this.rebateConfiguracao.extensoesCliente[index2].id) {
                  equals = true;
                }
              }
            }

            if (equals) { equals = ext.extensoes.find((extProd) => (extProd.id === extensaoProd.id)); }

            if (equals) {
              const indexOf = this.extensoesProduto.indexOf(extensaoProd);
              this.extensoesProduto.splice(indexOf, 1);
            }
          });
        });
      }
    },
    buscarExtensoesCliente() {
      const param = { mnemonico: 'CLUSTER' };
      this.buscarExtensoes(param,
        (response) => {
          this.extensoesCliente = response.body;
        });
    },
    buscarExtensoes(param, cb) {
      this.parametrizacaoPeriodoPasso6.buscarExtensoes(param)
        .then(cb)
        .catch((err) => {
          this.$error(this, err);
        });
    },
    sufixo(tipoValor) {
      if (tipoValor === 'PERCENTUAL') {
        return '%';
      }
      if (tipoValor === 'REAL_LITRO') {
        return 'R$/L';
      }
      return '';
    },
  },
};
</script>
