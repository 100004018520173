<template>
  <div v-if="gruposCultura.length > 0">
    <div class="mt-2 mb-2 title-float accent--text">
      {{$t('label.grupo_culturas')}}
    </div>
    <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-expansion-panels
            multiple
            focusable
            v-model="paineisAtivos">
            <planejamento-dpm-grupo-culturas-painel
              v-for="(grupo, index) in gruposCultura"
              :somente-leitura="somenteLeitura"
              :key="index"
              :grupo="grupo"
              :painel-ativo="paineisAtivos.indexOf(index) >= 0"
              :ano-atual-periodo="anoAtualPeriodo"
              :ano-anterior-periodo="anoAnteriorPeriodo"
              :segmentos-habilitados="segmentosHabilitados"
              @RECALCULO_VARIAVEIS="() => $emit('RECALCULO_VARIAVEIS')"
              />
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PlanejamentoDpmGrupoCulturasPainel from './PlanejamentoDpmGrupoCulturasPainel';

export default {
  components: {
    PlanejamentoDpmGrupoCulturasPainel,
  },
  props: {
    objetoPassoGeral: {
      type: Object,
      required: true,
    },
    somenteLeitura: Boolean,
    gruposCultura: Array,
    anoAtualPeriodo: Number,
    anoAnteriorPeriodo: Number,
    segmentosHabilitados: Array,
  },
  data() {
    return {
      paineisAtivos: [],
    };
  },
};
</script>
