<template>
  <v-fab-transition>
    <v-btn
      v-show="fab"
      v-scroll="onScroll"
      :style="{ bottom }"
      :aria-label="$t('label.ir_topo')"
      :color="color"
      :title="$t('label.ir_topo')"
      bottom
      dark
      fab
      fixed
      large
      right
      @click="toTop">
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>
  </v-fab-transition>
</template>
<script>
export default {
  props: {
    color: {
      default: 'accent',
      type: String,
    },
    bottom: {
      default: '65px',
      type: String,
    },
    target: {
      default: 100,
      type: Number,
    },
  },
  data: () => ({
    fab: false,
  }),
  methods: {
    onScroll() {
      const top = window.pageYOffset
        || document.documentElement.offsetTop
        || 0;
      this.fab = top > this.target;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
