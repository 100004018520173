import getBasePath from '../../functions/api-resource';

const basePath = getBasePath('planejamento_tatico', 'conta-corrente/origem-verba/{idTipoVerba}/{tipo}');

const contaCorrenteAjusteVerbaActions = {
  buscar: { method: 'GET', url: `${basePath}/` },
  rascunho: { method: 'POST', url: `${basePath}/rascunho` },
  concluir: { method: 'PUT', url: `${basePath}/concluir` },
};

export default (resource) => resource(`${basePath}`, {}, contaCorrenteAjusteVerbaActions);
