var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-3"},[_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',_vm._l((_vm.views),function(view,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto",class:("elevation-" + (hover ? 12 : 2)),attrs:{"max-width":"400","color":view.cor,"dark":""}},[_c('v-card-title',[_c('span',{staticClass:"font-weight-light title"},[_vm._v(_vm._s(_vm.$t('label.view')))])]),_c('v-card-text',{staticClass:"headline font-weight-bold"},[_vm._v(" "+_vm._s(view.nome)+" ")]),_c('v-card-actions',[_c('v-list-item',{staticClass:"grow"},[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"text":"","dark":"","icon":""},on:{"click":function($event){return _vm.criaView(view)}}},on),[_c('v-icon',[_vm._v("create")])],1)]}}],null,true)},[_c('span',[_vm._v("Cria a view se não existir")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"text":"","dark":"","icon":""},on:{"click":function($event){return _vm.refreshView(view)}}},on),[_c('v-icon',[_vm._v("autorenew")])],1)]}}],null,true)},[_c('span',[_vm._v("Atualiza os dados da view")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"text":"","dark":"","icon":""},on:{"click":function($event){return _vm.forceView(view)}}},on),[_c('v-icon',[_vm._v("healing")])],1)]}}],null,true)},[_c('span',[_vm._v("Força recriação da view")])])],1)],1)],1)],1)}}],null,true)})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }