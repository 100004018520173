<template>
  <transition name="slide-fade"
              mode="out-in">
    <router-view></router-view>
  </transition>
</template>

<script>
import { generateComputed } from '../../../produto/common/functions/roles-computed-generator';

export default {
  name: 'ParametrizacaoPeriodo',
  computed: {
    ...generateComputed('PARAM PERIODO', [
      'canAccessPage',
    ]),
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
