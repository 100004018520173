<template>
  <div>
    <v-container fluid grid-list-md class="px-0">
      <v-row>
        <v-col cols="12" md="8" class="py-0">
          <div class="mt-2 mb-2 title-float accent--text">
            {{$tc('label.desafio_plano_metas', 1)}}
          </div>
          <v-card>
            <v-data-table
              :headers="cabecalhoGrupo"
              :items="linhasGrupo"
              disable-filtering
              disable-pagination
              disable-sort
              hide-default-footer>
              <template v-slot:item.anoAnterior="{ item }">
                {{getNumber(item.anoAnterior)}}
              </template>
              <template v-slot:item.anoAtual="{ item }">
                {{getNumber(item.anoAtual)}}
              </template>
              <template v-slot:item.crescimento="{ item }">
                {{getPercent(item.crescimento)}}
              </template>
              <template v-slot:body.append>
                <tr>
                  <td class="text-left" style="font-weight:bold">
                    {{ $t('label.total') }}:
                  </td>
                  <td style="font-weight:bold;text-align:right">
                    {{getNumber(totalRealizado)}}
                  </td>
                  <td style="font-weight:bold;text-align:right">
                    {{getNumber(sumAnoAtual)}}
                  </td>
                  <td style="font-weight:bold;text-align:right">
                    {{getPercent(sumCrescimento)}}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <crescimento-grupo-cultura-principal
          :grupos-cultura-principal="gruposCulturaPrincipal"
          :ano-atual-periodo="anoAtualPeriodo"
          :ano-anterior-periodo="anoAnteriorPeriodo"
          />
          <br>
        </v-col>
        </v-row>
    </v-container>
  </div>
</template>
<script>
import { getPercent, getNumber } from '../../../../produto/common/functions/helpers';
import CrescimentoGrupoCulturaPrincipal from './CrescimentoGrupoCulturaPrincipal';

export default {
  props: {
    objetoPassoGeral: {
      type: Object,
      required: true,
    },
    somenteLeitura: Boolean,
    anoAtualPeriodo: {
      type: Number,
      required: true,
    },
    anoAnteriorPeriodo: {
      type: Number,
      required: true,
    },
    gruposCultura: Array,
    produtosServicos: Array,
    realizadoMarcas: Object,
    segmentosHabilitados: Array,
  },
  computedProps: {
    crescimentoSegmentos: Array,
  },
  computed: {
    totalRealizado() {
      return Object.values(this.realizadoMarcas)
        .map((item) => (item.volume * (item.pontuacaoUnitaria === undefined ? 0 : item.pontuacaoUnitaria)))
        .reduce((p, a) => p + a, 0);
    },
    sumAnoAtual() {
      if (!this.linhasGrupo || this.linhasGrupo.length === 0) {
        return 0;
      }
      return this.linhasGrupo.map((i) => i.anoAtual)
        .reduce((p, a) => p + a, 0)
        + this.sumAnoAtualProdutosServicos;
    },
    sumCrescimento() {
      if (this.totalRealizado === 0) {
        return 0;
      }
      return ((this.sumAnoAtual - this.totalRealizado) / this.totalRealizado) * 100;
    },
    sumAnoAtualProdutosServicos() {
      if (!this.produtosServicos || this.produtosServicos.length === 0) {
        return 0;
      }
      return this.produtosServicos.map((i) => i.pontuacaoMeta)
        .reduce((p, a) => p + a, 0);
    },
    listaMarcas() {
      return this.gruposCultura
        .map((grupo) => grupo.marcas)
        .flat()
        .concat(this.produtosServicos);
    },
  },
  components: {
    CrescimentoGrupoCulturaPrincipal,
  },
  data() {
    return {
      cabecalhoGrupo: [
        {
          text: this.$t('label.grupo_cultura'),
          value: 'grupoCultura',
          align: 'start',
        },
        {
          text: this.anoAnteriorPeriodo.toString(),
          value: 'anoAnterior',
          align: 'end',
        },
        {
          text: this.anoAtualPeriodo.toString(),
          value: 'anoAtual',
          align: 'end',
        },
        {
          text: this.$t('label.crescimento'),
          value: 'crescimento',
          align: 'end',
        },
      ],
      cabecalhoMix: [
        {
          text: this.$t('label.mix'),
          value: 'mix',
          align: 'start',
        },
        {
          text: this.anoAnteriorPeriodo.toString(),
          value: 'anoAnterior',
          align: 'end',
        },
        {
          text: this.anoAtualPeriodo.toString(),
          value: 'anoAtual',
          align: 'end',
        },
      ],
      linhasGrupo: [],
      linhasMix: [],
      mapaMarcasMix: [],
      gruposCulturaPrincipal: [],
    };
  },
  methods: {
    getPercent,
    getNumber,
    emiteCalculosClassificacao() {
      const metaTotal = this.sumAnoAtual;
      const crescimentoTotal = this.sumCrescimento;

      const gruposOrdenados = [...this.linhasGrupo];
      gruposOrdenados.sort((a, b) => {
        if (a.anoAtual < b.anoAtual) {
          return 1;
        } if (a.anoAtual > b.anoAtual) {
          return -1;
        }
        return 0;
      });
      const grupoCulturaPrincipal = gruposOrdenados[0];

      this.$emit('classificacao', {
        metaTotal,
        crescimentoTotal,
        grupoCulturaPrincipal,
      });
    },
    calculaValores() {
      this.calculaValoresGrupos();
      this.calculaValoresMix();
      this.calculaValoresGrupoCulturaPricipais();
      setTimeout(() => this.emiteCalculosClassificacao());
    },
    calculaValoresGrupos() {
      this.linhasGrupo = this.gruposCultura.map((grupo) => {
        const {
          grupoCultura,
          metaDefinida,
          crescimento,
          marcas,
          idGrupoCultura,
        } = grupo;
        const anoAnterior = marcas.map((m) => m.pontuacaoRealizada)
          .reduce((p, a) => p + a, 0);

        grupo.anoAnterior = anoAnterior;
        grupo.anoAtual = metaDefinida;

        return {
          grupoCultura,
          anoAnterior,
          anoAtual: metaDefinida,
          crescimento,
          idGrupoCultura,
        };
      });
    },
    calculaValoresGrupoCulturaPricipais() {
      const gruposOrdenados = [...this.linhasGrupo];
      gruposOrdenados.sort((a, b) => {
        if (a.anoAtual < b.anoAtual) {
          return 1;
        } if (a.anoAtual > b.anoAtual) {
          return -1;
        }
        return 0;
      });
      const culturaPrincipal = gruposOrdenados[0];
      this.gruposCulturaPrincipal = [];
      const marcas = [];
      this.gruposCultura.forEach((grupo) => {
        if (culturaPrincipal.idGrupoCultura === grupo.idGrupoCultura) {
          grupo.marcas.forEach((marca) => {
            marcas.push(marca);
          });
        }
      });
      marcas.forEach((marca) => {
        if (this.segmentosHabilitados.includes(marca.segmentos ? marca.segmentos.toLowerCase() : '')) {
          const gruposCulturaPrincipal = this.gruposCulturaPrincipal.filter((m) => m.segmento === marca.segmentos);
          if (gruposCulturaPrincipal.length > 0) {
            const grupoCulturaPrincipal = gruposCulturaPrincipal[0];
            grupoCulturaPrincipal.anoAnterior += marca.pontuacaoRealizada;
            grupoCulturaPrincipal.anoAtual += marca.pontuacaoMeta;
          } else {
            this.gruposCulturaPrincipal.push({
              segmento: marca.segmentos,
              anoAnterior: marca.pontuacaoRealizada,
              anoAtual: marca.pontuacaoMeta,
            });
          }
        }
      });
      this.gruposCulturaPrincipal.forEach((grupoCulturaPrincipal) => {
        if (grupoCulturaPrincipal.anoAnterior === 0 && grupoCulturaPrincipal.anoAtual > 0) {
          grupoCulturaPrincipal.crescimento = 100;
        }
        if (grupoCulturaPrincipal.anoAnterior === 0 && grupoCulturaPrincipal.anoAtual === 0) {
          grupoCulturaPrincipal.crescimento = 0;
        }
        if (grupoCulturaPrincipal.anoAnterior > 0) {
          grupoCulturaPrincipal.crescimento = (((grupoCulturaPrincipal.anoAtual - grupoCulturaPrincipal.anoAnterior)
          / grupoCulturaPrincipal.anoAnterior) * 100).toFixed(2);
        }
      });
      this.crescimentoSegmentos = this.gruposCulturaPrincipal;
      this.$emit('crescimentoSegmentos', this.crescimentoSegmentos);
    },
    marcasPorMix(mix) {
      if (!this.mapaMarcasMix[mix]) {
        this.mapaMarcasMix[mix] = this.listaMarcas
          .filter((m) => m.mix === mix)
          .map((m) => m.idMarca)
          .reduce((x, y) => (x.includes(y) ? x : [...x, y]), []);
      }
      return this.mapaMarcasMix[mix];
    },
    calculaValoresMix() {
      const sumPontuacaoMeta = this.listaMarcas
        .map((i) => i.pontuacaoMeta)
        .reduce((p, a) => p + a, 0);

      const divisorAnterior = this.totalRealizado === (0 || NaN) ? 1 : this.totalRealizado;
      const divisorAtual = sumPontuacaoMeta === (0 || NaN) ? 1 : sumPontuacaoMeta;

      this.linhasMix = this.listaMarcas
        .map((i) => i.mix)
        .reduce((x, y) => (x.includes(y) ? x : [...x, y]), [])
        .map((mix) => {
          let pontuacaoRealizadaMix = 0,
            pontuacaoMetaMix = 0;

          this.marcasPorMix(mix).forEach((idMarca) => {
            const pontuacaoRealizada = this.getPontuacaoRealizadaMarca(idMarca);
            pontuacaoRealizadaMix += pontuacaoRealizada;
          });

          this.listaMarcas
            .filter((m) => m.mix === mix)
            .map((m) => m.pontuacaoMeta)
            .forEach((pontuacaoMeta) => {
              pontuacaoMetaMix += pontuacaoMeta;
            });

          return {
            mix,
            anoAnterior: (pontuacaoRealizadaMix / divisorAnterior) * 100,
            anoAtual: (pontuacaoMetaMix / divisorAtual) * 100,
          };
        });
      this.linhasMix.sort((a, b) => {
        if (a.mix > b.mix) {
          return 1;
        }
        if (a.mix < b.mix) {
          return -1;
        }
        return 0;
      });
    },
    getPontuacaoRealizadaMarca(idMarca) {
      const marca = this.realizadoMarcas[idMarca];
      if (!marca) {
        return 0;
      }
      const { volume, pontuacaoUnitaria } = marca;
      return volume * pontuacaoUnitaria;
    },
    objetoMixCalculado() {
      const mapaMix = {};
      this.listaMarcas
        .forEach((item) => {
          mapaMix[item.mix] = item.idMix;
        });

      return {
        listaMix: this.linhasMix.map((i) => ({ ...i, idMix: mapaMix[i.mix] })),
      };
    },
  },
};
</script>
