import { skipLoading } from '../../common/functions/loading';

export default {
  data() {
    return {
      workflowOrcamentoResource: this.$api.workflowOrcamento(this.$resource),
      aprovadorAtual: false,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      justificativa: '',
      justificativaSomenteLeitura: false,
    };
  },
  computed: {
    exibeBtnAvaliacao() {
      return !this.novo && this.aprovadorAtual
        && this.orcamentoEdicao.status === 'AGUARDANDO_APROVACAO';
    },
  },
  methods: {
    solicitarAprovacao() {
      const { idOrcamento } = this.$route.params;
      this.workflowOrcamentoResource.iniciaFluxo({ idOrcamento }, { idOrcamento })
        .then(() => {
          this.$router.push({ name: 'orcamento' });
          this.$toast(this.$t('message.orcamento_enviado_aprovacao'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    aprovar(justificativa) {
      const { idOrcamento } = this.$route.params;
      this.workflowOrcamentoResource.aprovar({ idOrcamento },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'orcamento' });
          this.$toast(this.$t('message.orcamento_aprovado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    reprovar(justificativa) {
      const { idOrcamento } = this.$route.params;
      this.workflowOrcamentoResource.reprovar({ idOrcamento },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'orcamento' });
          this.$toast(this.$t('message.orcamento_reprovado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    enviarAnalise(justificativa) {
      const { idOrcamento } = this.$route.params;
      this.workflowOrcamentoResource.enviarAnalise({ idOrcamento },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'orcamento' });
          this.$toast(this.$t('message.orcamento_enviado_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificarUsuarioSolicitante() {
      const { status, id } = this.orcamentoEdicao;
      if (['EM_CADASTRO', 'EM_ANALISE', 'AGUARDANDO_APROVACAO'].indexOf(status) < 0) {
        return Promise.resolve();
      }

      skipLoading();
      return this.workflowOrcamentoResource.solicitante({ idOrcamento: id })
        .then((response) => {
          this.orcamentoEdicao.solicitanteFluxo = response.data.usuarioHabilitado;
        });
    },
    verificarUsuarioAprovador() {
      const { status, id } = this.orcamentoEdicao;
      if (status !== 'AGUARDANDO_APROVACAO') {
        return Promise.resolve();
      }

      skipLoading();
      return this.workflowOrcamentoResource.aprovador({ idOrcamento: id })
        .then((response) => {
          this.aprovadorAtual = response.data.usuarioHabilitado;
        });
    },
    abrirJustificativaAprovar() {
      this.abrirJustificativaAvaliar(this.aprovar, false);
    },
    abrirJustificativaReprovar() {
      this.abrirJustificativaAvaliar(this.reprovar, true);
    },
    abrirJustificativaAnalise() {
      this.abrirJustificativaAvaliar(this.enviarAnalise, true);
    },
    abrirJustificativaAvaliar(acaoJustificativa, obrigatorio) {
      this.justificativaObrigatoria = obrigatorio;
      this.actionJustificativa = acaoJustificativa;
      this.justificativa = '';
      this.justificativaSomenteLeitura = false;
      this.$refs.modalJustificativa.open();
    },
  },
};
