<template>
  <div>
   <metadados-extensao-form
      ref="NivelFornecedorForm"
      :id="id"
      :somenteLeitura="somenteLeitura"
      :idNivelExtensao="objeto.idNivelExtensao"
      :tipoCadastro="'nivel_fornecedor'"
      :mostrarAtivacao="false"
      @atualizarGrid="atualizarGrid"
      @cancelar="cancelar">
      <template slot="contentPosForm">
        <v-col cols="12">
          <v-select
            ref="selectTipo"
            class="Form-text__invalid"
            id="tipo"
            name="tipo"
            clearable
            item-text="desNivel"
            item-value="id"
            :items="extensoes"
            :disabled="true"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :label="`${$tc('label.tipo', 1)} *`"
            v-model="nivel"
            return-object>
          </v-select>
        </v-col>
      </template>
    </metadados-extensao-form>
  </div>
</template>

<script type="text/javascript">
import MetadadosExtensaoForm from '../../shared-components/metadadosExtensao/MetadadosExtensaoForm';

export default {
  name: 'FornecedorNovaExtensao',
  data() {
    return {
      extensaoResources: this.$api.extensao(this.$resource),

      id: null,
      extensoes: [],
      niveisExtensoes: [],
      nivel: {},
    };
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    objeto: {
      type: Object,
      default: {},
    },
  },
  components: {
    MetadadosExtensaoForm,
  },
  methods: {
    setNivel(id) {
      this.nivel = {};
      this.nivel.id = id;
    },
    buscarExtensoesRelacionadasAoFornecedor() {
      const params = {
        ...null,
        idsNiveisExtensao: this.niveisExtensoes,
      };
      this.extensaoResources.listarNiveisExtensao(params).then((resultado) => {
        this.extensoes = resultado.data;
      });
    },
    buscarNiveisExtensaoFornecedor() {
      const params = { ...null, entidade: 'FORNECEDOR' };
      return this.extensaoResources.listarNiveisExtensaoEntidade(params).then((resultado) => {
        this.niveisExtensoes = resultado.data;
      });
    },
    cancelar() {
      this.$emit('cancelar');
    },
    atualizarGrid(obj) {
      this.$emit('atualizarGrid', obj, this.objeto.metadados);
    },
  },
  mounted() {
    this.buscarNiveisExtensaoFornecedor()
      .then(this.buscarExtensoesRelacionadasAoFornecedor);
  },
};
</script>
