var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$tc('label.workflow', 1)))]),_c('div',{staticClass:"flex-grow-1"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.canEdit && _vm.selected.length > 0),expression:"canEdit && selected.length > 0"}],staticClass:"pt-4 mr-5",attrs:{"icon":"","id":"workflow-botao-ativar-inativar"},on:{"click":_vm.ativarDesativarRegistros}},on),[_c('v-icon',[_vm._v("block")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('label.ativar_inativa')))])]),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},on:{"input":_vm.filtrar},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.fluxos,"show-select":"","options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
      itemsPerPageOptions: [10, 25, 50],
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(_vm.canEdit)?{key:"item.acoes",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editarItem(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.editar_produto')))])])]}}:null,{key:"item.tipoFluxo",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{ 'row--disabled': !item.indAtivo }},[_vm._v(" "+_vm._s(item.tipoFluxo)+" ")])]}},{key:"item.descricao",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{ 'row--disabled': !item.indAtivo }},[_vm._v(" "+_vm._s(item.descricao)+" ")])]}},{key:"item.ind_ativo",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{ 'row--disabled': !item.indAtivo }},[_vm._v(" "+_vm._s(item.indAtivo ? _vm.$t('label.sim') : _vm.$t('label.nao'))+" ")])]}},{key:"item.usuarioCriacao",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{ 'row--disabled': !item.indAtivo }},[_vm._v(" "+_vm._s(item.usuarioCriacao)+" ")])]}},{key:"item.dtaCriacao",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{ 'row--disabled': !item.indAtivo }},[_vm._v(" "+_vm._s(item.dtaCriacao)+" ")])]}},{key:"item.usuarioAlteracao",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{ 'row--disabled': !item.indAtivo }},[_vm._v(" "+_vm._s(item.usuarioAlteracao)+" ")])]}},{key:"item.dtaAlteracao",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{ 'row--disabled': !item.indAtivo }},[_vm._v(" "+_vm._s(item.dtaAlteracao)+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }