<template>
  <v-card class="PlanejamentoAcaoFormCampos__Card--treslinhas">
    <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12" >
          <v-select
            id="planejamento-origem-acao"
            v-model="origemAcao"
            :items="origemAcaoConfiguracao"
            :disabled="somenteLeitura || unicaOrigemAcao"
            :label="$t('label.origem_acao')"
            item-text="texto"
            item-value="valor"
            @input="limparOrigem">
          </v-select>
        </v-col>
        <v-col cols="12" >
          <v-autocomplete
            id="planejamento-autocomplete-grupo-fornecedor"
            class="custom-autocomplete"
            v-if="exibeOrigemGrupoFornecedor"
            v-model="acao.grupoFornecedor"
            return-object
            :rules="rules.requiredFunction('grupoFornecedor', 'nomExtensao')"
            :items="gruposFornecedores"
            :label="`${$tc('label.grupo_fornecedor', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nomExtensao"
            item-value="id"
            required
            :disabled="somenteLeitura"
            :placeholder="somenteLeitura ? null : $tc('message.digite_para_pesquisar', 1)"
            :clearable="true"
            @input="grupoFornecedorAlterado"
            @click:append="() => triggerSelecao('planejamento-autocomplete-grupo-fornecedor')"
            @click.native="(i) => buscaAutocomplete(i, buscaGruposFornecedores)"
            :search-input.sync="triggerGrupoFornecedor">
          </v-autocomplete>
          <v-autocomplete
            id="planejamento-autocomplete-fornecedor"
            class="custom-autocomplete"
            v-else
            v-model="acao.fornecedor"
            return-object
            :filter="filtrarFornecedores"
            :rules="rules.requiredFunction('fornecedor', 'codNomeCnpj')"
            :items="fornecedores"
            :label="`${$tc('label.fornecedor', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="codNomeCnpj"
            item-value="id"
            required
            :disabled="somenteLeitura"
            :placeholder="somenteLeitura ? null : $tc('message.digite_para_pesquisar', 1)"
            :clearable="true"
            @input="fornecedorAlterado"
            @click:append="() => triggerSelecao('planejamento-autocomplete-fornecedor')"
            @click.native="(i) => buscaAutocomplete(i, buscaFornecedores)"
            :search-input.sync="triggerFornecedor">
          </v-autocomplete>
        </v-col>
        <v-col cols="12"
          v-if="exibeFornecedorPagador">
          <v-autocomplete
            id="planejamento-autocomplete-fornecedorpagador"
            class="custom-autocomplete"
            v-model="acao.fornecedorPagador"
            return-object
            :rules="rules.requiredFunctionOpcional('fornecedorPagador', 'codNomeCnpj', fornecedorPagadorObrigatorio)"
            :items="fornecedoresPagadores"
            :label="labelFornecedorPagador"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="codNomeCnpj"
            item-value="id"
            :disabled="somenteLeitura || desabilitaFornecedorPagador"
            :placeholder="(somenteLeitura || desabilitaFornecedorPagador) ? null : $tc('message.digite_para_pesquisar', 1)"
            :clearable="true"
            @click:append="() => triggerSelecao('planejamento-autocomplete-fornecedorpagador')"
            @click.native="(i) => buscaAutocomplete(i, buscaFornecedoresPagadores)"
            :search-input.sync="triggerFornecedorPagador">
          </v-autocomplete>
        </v-col>
        <v-col cols="12"
          v-if="exibeFornecedorPagador">
          <v-text-field
            v-model="acao.fornecedorPagador.prazoVencimentoAcao"
            :label="`${$tc('label.prazo_vencimento_dias', 1)}`"
            :disabled="true"/>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { buscaAutocomplete, triggerSelecao } from '../../../common/functions/autocomplete-utils';

export default {
  props: {
    somenteLeitura: Boolean,
    acao: Object,
    configuracao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      triggerGrupoFornecedor: null,
      triggerFornecedor: null,
      triggerFornecedorPagador: null,
      timeoutTrigger: null,
      gruposFornecedores: [],
      fornecedores: [],
      fornecedoresPagadores: [],
      origemAcao: 'FORNECEDOR',
      itensOrigemAcao: [
        {
          valor: 'GRUPO_FORNECEDOR',
          texto: this.$tc('label.grupo_fornecedor', 1),
        },
        {
          valor: 'FORNECEDOR',
          texto: this.$tc('label.fornecedor', 1),
        },
      ],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredFunctionOpcional: (objLabel, valueLabel, obrigatorio) => [
          () => {
            if (!obrigatorio) {
              return true;
            }

            const objeto = this.acao[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
        requiredFunction: (objLabel, valueLabel) => [
          () => {
            const objeto = this.acao[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
      },
    };
  },
  watch: {
    triggerGrupoFornecedor(val) {
      const { grupoFornecedor } = this.acao;
      if (grupoFornecedor && grupoFornecedor.nomExtensao === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscaGruposFornecedores(val);
      }, 500);
    },
    triggerFornecedor(val) {
      const { fornecedor } = this.acao;
      if (fornecedor && fornecedor.codNomeCnpj === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscaFornecedores(val);
      }, 500);
    },
    triggerFornecedorPagador(val) {
      const { fornecedorPagador } = this.acao;
      if (fornecedorPagador && fornecedorPagador.codNomeCnpj === val) {
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscaFornecedoresPagadores(val);
      }, 500);
    },
  },
  computed: {
    desabilitaFornecedorPagador() {
      const { grupoFornecedor } = this.acao;
      if (this.origemAcao === 'FORNECEDOR' || !grupoFornecedor || !grupoFornecedor.id) {
        return true;
      }
      return false;
    },
    origemAcaoConfiguracao() {
      const { origemAcao } = this.configuracao;
      return this.itensOrigemAcao
        .filter((item) => origemAcao.indexOf(item.valor) >= 0);
    },
    exibeOrigemGrupoFornecedor() {
      return this.origemAcao === 'GRUPO_FORNECEDOR';
    },
    unicaOrigemAcao() {
      return this.origemAcaoConfiguracao.length === 1;
    },
    exibeFornecedorPagador() {
      return this.configuracao.habilitaFornecedorPagador;
    },
    labelFornecedorPagador() {
      if (this.configuracao.fornecedorPagadorObrigatorio) {
        return `${this.$tc('label.fornecedor_pagador', 1)} *`;
      }
      return this.$tc('label.fornecedor_pagador', 1);
    },
    fornecedorPagadorObrigatorio() {
      return this.configuracao.fornecedorPagadorObrigatorio;
    },
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    filtrarFornecedores(item, queryText) {
      return item.codNomeCnpj.toLowerCase().indexOf(queryText.toLowerCase()) > -1
        || item.desCnpj.replace(/[./-]/g, '').indexOf(queryText) > -1;
    },
    buscaGruposFornecedores(autocomplete) {
      const parametros = {
        autocomplete,
      };
      this.planejamentoAcaoResource.buscarGruposFornecedores(parametros)
        .then((res) => {
          this.gruposFornecedores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscaFornecedores(autocomplete) {
      const parametros = {
        autocomplete,
      };
      this.planejamentoAcaoResource.buscarFornecedores(parametros)
        .then((res) => {
          this.fornecedores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscaFornecedoresPagadores(autocomplete) {
      const parametros = {
        autocomplete,
        idGrupoFornecedor: this.acao.grupoFornecedor.id,
      };
      this.planejamentoAcaoResource.buscarFornecedoresPagadores(parametros)
        .then((res) => {
          this.fornecedoresPagadores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    grupoFornecedorAlterado() {
      const { fornecedorPagador } = this.acao;
      if (fornecedorPagador && fornecedorPagador.id) {
        this.acao.fornecedorPagador = {};
      }
    },
    fornecedorAlterado(fornecedor) {
      if (fornecedor) {
        this.acao.fornecedorPagador = fornecedor ? { ...fornecedor } : {};
        this.fornecedoresPagadores = [
          this.acao.fornecedorPagador,
        ];
      } else {
        this.acao.fornecedorPagador = {};
        this.fornecedoresPagadores = [];
      }
    },
    limparOrigem() {
      const { fornecedor, fornecedorPagador, grupoFornecedor } = this.acao;
      if (grupoFornecedor && grupoFornecedor.id) {
        this.acao.grupoFornecedor = {};
      }
      if (fornecedorPagador && fornecedorPagador.id) {
        this.acao.fornecedorPagador = {};
      }
      if (fornecedor && fornecedor.id) {
        this.acao.fornecedor = {};
      }
    },
    trataOrigemAcao() {
      if (this.unicaOrigemAcao) {
        this.origemAcao = this.origemAcaoConfiguracao[0].valor;
      }
    },
    setAcaoEdicao() {
      const { fornecedor, grupoFornecedor, fornecedorPagador } = this.acao;
      if (grupoFornecedor) {
        this.origemAcao = 'GRUPO_FORNECEDOR';
        this.gruposFornecedores = [grupoFornecedor];
        this.fornecedoresPagadores = [fornecedorPagador];
      } else {
        this.origemAcao = 'FORNECEDOR';
        this.fornecedores = [fornecedor];
        this.fornecedoresPagadores = [fornecedorPagador];
      }
      if (!this.acao.fornecedorPagador) {
        this.acao.fornecedorPagador = {};
      }
    },
  },
  mounted() {
    this.trataOrigemAcao();
  },
};
</script>
