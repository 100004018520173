<template>
  <v-menu
    v-if="canAccessView"
    v-model="menu"
    :close-on-content-click="false"
    transition="slide-x-transition"
    :nudge-width="200"
    offset-x>
    <template v-slot:activator="{ on }">
      <v-btn text dark icon v-on="on">
        <img src="../../assets/img/calendar_month.svg">
      </v-btn>
    </template>
    <calendario-card v-if="menu"></calendario-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import CalendarioCard from './CalendarioCard';

export default {
  name: 'CalendarioToolbar',
  components: {
    CalendarioCard,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canAccessView() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CAL_VISUALIZAR').length;
    },
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>
