import getBasePath from '../../functions/api-resource';
import resourceBuilder from '../../functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', 'contrato');

const planejamentoContratoActions = {
  buscarContrato: { methods: 'GET', url: `${basePath}/carregamento/{idContrato}` },
  vericaUnicoTipo: { methods: 'GET', url: `${basePath}/listagem/tipo_contrato/unico` },
};

export default (resource) => resource(`${basePath}`, {}, planejamentoContratoActions);

export const buscarTiposContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/tipo_contrato', parametros).doGet();

export const buscarContratos = (parametros, resource) => resourceBuilder
  .buildGET(resource, basePath, 'listagem/tipo_contrato/contratos', parametros)
  .doGet();

export const buscarTotalizadores = (parametros, resource) => resourceBuilder
  .buildGET(resource, basePath, 'listagem/totalizadores', parametros)
  .doGet();
