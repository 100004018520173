<template lang="html">
  <div style="height: 100%">
    <v-card class="card-size" height="100%">
      <v-card-title>
        <h2 class="headline mb-0" v-text="title"></h2>
      </v-card-title>
      <v-row justify="start">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            v-model="searchQuery"
            @input="filtrar"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
        <v-tooltip bottom v-if="!somenteLeitura">
          <template v-slot:activator="{ on }">
            <v-btn
              text
              icon
              class="mx-0 mt-5"
              v-on="on"
              @click="dialogConfirmarInativacao()"
              v-if="canAccessCRUD"
              v-show="selected.length">
              <v-icon>block</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.ativar_inativa') }}</span>
        </v-tooltip>
      </v-row>

      <v-data-table
        v-model="selected"
        show-select
        :headers="headers"
        :items="listaAlcadas"
        :item-class="onItemClass"
        :options.sync="pagination"
        :server-items-length="totalPage"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:item.acoes="{ item }">
          <v-tooltip bottom v-if="canAccessCRUD">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="mx-0"
                v-on="on"
                @click.stop="onEditarAlcada(item)">
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.editar') }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.fluxo="{ item }">
          {{ item.fluxo.nome }}
        </template>
        <template v-slot:item.nom_estrutura_usuario="{ item }">
          {{ item.estruturaUsuario.nomEstruturaUsuario }}
        </template>
        <template v-slot:item.vlr_limite_inicial="{ item }">
          {{ convertToMoney(item.vlrLimiteInicial) }}
        </template>
        <template v-slot:item.vlr_limite_final="{ item }">
          {{ item.vlrLimiteFinal ? convertToMoney(item.vlrLimiteFinal) : '' }}
        </template>
        <template v-slot:item.workflow="{ item }">
          {{ item.workflow.descricao }}
        </template>
        <template v-slot:item.ind_ativo="{ item }">
          {{ item.indAtivo ? $t('label.sim') : $t('label.nao') }}
        </template>
      </v-data-table>
    </v-card>
    <confirm
      ref="dialogConfirmaInativacao"
      :message="$t('message.deseja_ativar_inativar_registros', {quantidade: selected.length})"
      :persistent="true"
      @agree="ativarInativarRegistros">
    </confirm>
  </div>
</template>

<script type="text/javascript">
import { generateComputed } from '../../common/functions/roles-computed-generator';
import Alcada from './Alcada';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import { convertToMoney } from '../../common/functions/helpers';

export default {
  components: {
    Confirm,
  },
  data() {
    return {
      alcadaResources: this.$api.alcada(this.$resource),
      listaAlcadas: [],
      esperar: false,
      headers: [
        {
          text: this.$tc('label.fluxo', 1),
          value: 'fluxo',
          sortable: true,
        },
        {
          text: this.$tc('label.descricao', 1),
          value: 'descricao',
          sortable: true,
        },
        {
          text: this.$tc('label.estrutura_usuario', 1),
          value: 'nom_estrutura_usuario',
          sortable: true,
        },
        {
          text: this.$tc('label.valor_inicial', 1),
          value: 'vlr_limite_inicial',
          sortable: true,
        },
        {
          text: this.$tc('label.valor_final', 1),
          value: 'vlr_limite_final',
          sortable: true,
        },
        {
          text: this.$tc('label.workflow', 1),
          value: 'workflow',
          sortable: true,
        },
        {
          text: this.$t('label.ativo'),
          value: 'ind_ativo',
          sortable: false,
        },
      ],
      pagination: {},
      totalPage: 0,
      searchQuery: '',
      registros: [],
      selected: [],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    searchQuery() {
      this.pagination.page = 1;
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  props: {
    title: String,
  },
  computed: {
    filteredList() {
      return [];
    },
    ...generateComputed('Alcada', [
      'canAccessCRUD',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  methods: {
    convertToMoney,
    onItemClass(...args) {
      const [alcada] = args;
      return !alcada.indAtivo ? 'TableRow--disabled' : '';
    },
    ativarInativarRegistros() {
      const parametros = [];
      this.selected.forEach((item) => {
        parametros.push({ id: item.id, indAtivo: item.indAtivo });
      });

      this.alcadaResources.ativarInativarRegistros(parametros)
        .then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'), 'snack', 3E3);
          this.limparSelecionados();
          this.filtrar();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscar() {
      const params = {
        descricao: this.searchQuery,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.alcadaResources.listar(params).then((response) => {
        this.registros = response.data.resposta;
        this.montarObjetoAlcadas();
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },
    getSearchQuery() {
      return this.searchQuery;
    },
    limparSelecionados() {
      this.selected = [];
    },
    dialogConfirmarInativacao() {
      this.$refs.dialogConfirmaInativacao.open();
    },
    montarObjetoAlcadas() {
      this.listaAlcadas = this.registros.map((data) => {
        const alcada = new Alcada();
        alcada.construirAPartirDaConsultaCompleta(data);
        return alcada;
      });
    },
    onEditarAlcada(alcada) {
      const { id } = alcada;
      this.alcadaResources.obterAlcadaWorkflow({ id }).then((response) => {
        alcada.idsWorkflows = response.data.idsWorkflows;

        if (!alcada.desAtributos) {
          alcada.desAtributos = { regional: false };
        }
        this.$emit('PESQUISA_ALCADA_EDITAR', alcada);

        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    validateConflitosValores(row) {
      this.valuesErro = null;
      let status = true;
      if (!this.rowEdit.indSemValorFim && row.vlrLimiteInicial > row.vlrLimiteFinal) {
        this.valuesErro = 'vlr_inicial_maior_vlr_final';
        status = false;
      }
      return status;
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    mudarOrdenacao(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.listaAlcadas.slice();
    },
  },
  mounted() {
    if (!this.somenteLeitura) {
      this.headers.unshift({
        text: '',
        value: 'acoes',
        sortable: false,
        width: '1%',
        align: 'center',
      });
    }
  },
};
</script>
