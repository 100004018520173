<template lang="html">
  <div>
    <v-form ref="formPa" v-if="canExportPA">
      <p class="title">{{ $tc('title.ajuste_provisao', 1) }}</p>
      <v-card>
        <v-card-title>
          <span class="title">{{ $tc('title.ajuste_provisao_pa', 1) }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                id="ajuste_provisao_pa"
                name="ajuste_provisao_pa"
                :items="listaStatusIntegracao"
                item-text="descricao"
                item-value="chave"
                v-model="objExportacaoPa.statusIntegracao"
                :label="`${$t('label.status_integracao')} *`"
                :rules="[rules.required]">
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-switch
                :label="`${$t('label.alterar_status_ajustes_integrado')} *`"
                v-model="objExportacaoPa.alterarStatusIntegrado"
                color="primary"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn @click.native="abrirDialogExportar(objExportacaoPa)" color="primary">{{ $t('label.exportar') }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
      <p />
    <v-form ref="formFi" v-if="canExportFI">
      <v-card>
        <v-card-title>
          <span class="title">{{ $tc('title.ajuste_provisao_fi', 1) }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                id="ajuste_provisao_fi"
                name="ajuste_provisao_fi"
                :items="listaStatusIntegracao"
                item-text="descricao"
                item-value="chave"
                v-model="objExportacaoFi.statusIntegracao"
                :label="`${$t('label.status_integracao')} *`"
                :rules="[rules.required]">
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-switch
                :label="`${$t('label.alterar_status_ajustes_integrado')} *`"
                v-model="objExportacaoFi.alterarStatusIntegrado"
                color="primary"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn @click.native="abrirDialogExportar(objExportacaoFi)" color="primary">{{ $t('label.exportar') }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <confirm
          ref="confirmDialog"
          :message="$t('message.deseja_exportar')"
          :persistent="true"
          @agree="requestExportacao">
        </confirm>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import exportacao from '../../../produto/common/resources/exportacoes';
import Confirm from '../../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  data() {
    return {
      ajusteProvisaoResource: this.$api.ajusteProvisao(this.$resource),
      objExportacao: null,
      objExportacaoPa: {
        tipo: 'PA',
        exportador: 'exportador_ajuste_provisao_pa',
        statusIntegracao: null,
        alterarStatusIntegrado: true,
      },
      objExportacaoFi: {
        tipo: 'FI',
        exportador: 'exportador_ajuste_provisao_fi',
        statusIntegracao: null,
        alterarStatusIntegrado: true,
      },
      listaStatusIntegracao: [
        {
          descricao: 'Integrado',
          chave: 'INTEGRADO',
        },
        {
          descricao: 'Pendente',
          chave: 'PENDENTE_INTEGRACAO',
        },
      ],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  components: {
    Confirm,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canExportPA() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('REL_EXPORT_PA')).length;
    },
    canExportFI() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('REL_EXPORT_FI')).length;
    },
  },
  methods: {
    abrirDialogExportar(obj) {
      if ((obj.tipo === 'PA' && !this.$refs.formPa.validate())
          || (obj.tipo === 'FI' && !this.$refs.formFi.validate())) return;
      this.objExportacao = { ...obj };
      this.$refs.confirmDialog.open();
    },
    requestExportacao() {
      const parametrosConsulta = {
        status_integracao: this.objExportacao.statusIntegracao,
      };
      const parametros = {
        limit: 500000,
        parametrosConsulta,
        arquivoCsv: true,
      };
      const nomeExportador = this.objExportacao.exportador;
      const resource = exportacao(this.$http);
      resource.download(nomeExportador, parametros, 'planejamento_acao')
        .then(() => {
          if (this.objExportacao.alterarStatusIntegrado) {
            const params = {
              tipo_provisao: this.objExportacao.tipo,
            };
            this.ajusteProvisaoResource.alterarStatusIntegracao(params);
          }
          this.$toast(this.$t('message.exportacao_download'));
        })
        .catch(() => {
          this.$toast('Erro ao exportar. Tente novamente.');
        });
    },
  },
};
</script>

<style lang="scss">

</style>
