import coreResources from '../../produto/common/resources/core-resources';
import planejamentoAcaoPrevia from './planejamento-acao-previa';
import parametrizacaoPeriodo from './parametrizacao-periodo/parametrizacao-periodo';
import parametrizacaoPeriodoPasso1 from './parametrizacao-periodo/parametrizacao-periodo-passo1';
import parametrizacaoPeriodoPasso2 from './parametrizacao-periodo/parametrizacao-periodo-passo2';
import parametrizacaoPeriodoPasso3 from './parametrizacao-periodo/parametrizacao-periodo-passo3';
import parametrizacaoPeriodoPasso4 from './parametrizacao-periodo/parametrizacao-periodo-passo4';
import parametrizacaoPeriodoPasso5 from './parametrizacao-periodo/parametrizacao-periodo-passo5';
import parametrizacaoPeriodoPasso6 from './parametrizacao-periodo/parametrizacao-periodo-passo6';
import parametrizacaoPeriodoPasso7 from './parametrizacao-periodo/parametrizacao-periodo-passo7';
import planejamentoPlanoMetas from './dpm/planejamento-plano-metas';
import planejamentoDpmCadastro from './planejamento-dpm-cadastro';
import apuracaoPlanoMetas from './dpm/apuracao-plano-metas';
import importacoes from './importacoes';
import notas from './notas';
import planoContratoAdama from './dpm/planejamento-contrato-adama';
import relatorioContrato from './relatorio-contrato';
import ajusteProvisao from './ajuste-provisao';
import exportacaoAcaoPagamento from './relatorio-acao-pagamento';
import extensaoAdama from './extensao-adama';
import acaoPrevia from './acao-previa';
import workflowAcaoPrevia from './workflow-acao-previa';
import rateioProporcional from './planejamento-acao-rateio';
import exportacaoExtratoRebate from './relatorio-exportacao-extrato-rebate';

function plugin(Vue) {
  Vue.prototype.$api = {
    ...coreResources,
    parametrizacaoPeriodo,
    parametrizacaoPeriodoPasso1,
    parametrizacaoPeriodoPasso2,
    parametrizacaoPeriodoPasso3,
    parametrizacaoPeriodoPasso4,
    parametrizacaoPeriodoPasso5,
    parametrizacaoPeriodoPasso6,
    parametrizacaoPeriodoPasso7,
    planejamentoPlanoMetas,
    planejamentoAcaoPrevia,
    planejamentoDpmCadastro,
    apuracaoPlanoMetas,
    importacoes,
    notas,
    planoContratoAdama,
    relatorioContrato,
    ajusteProvisao,
    exportacaoAcaoPagamento,
    extensaoAdama,
    acaoPrevia,
    workflowAcaoPrevia,
    rateioProporcional,
    exportacaoExtratoRebate,
  };
}

if (typeof window !== 'undefined' && window.Vue) { // eslint-disable-line no-undef
  window.Vue.use(plugin); // eslint-disable-line no-undef
}

export default plugin;
