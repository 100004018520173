<template>
  <v-menu
    v-model="menuCancelar"
    :close-on-content-click="false"
    :nudge-width="400"
    transition="scale-transition"
    offset-x
    eager
    right>
    <template v-slot:activator="{ on: menu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn v-on="{ ...tooltip, ...menu }" icon @click.stop.prevent>
            <v-icon>cancel</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('label.cancelar_apuracao') }}</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title class="headline">{{ $t('title.cancelar_apuracao') }}</v-card-title>
      <v-card-text v-if="dataValida" v-html="$t('message.deseja_cancelar_apuracao_justificativa')"></v-card-text>
      <v-card-text v-else>{{ $t('message.apuracao_cancelar_vigencia_iniciada') }}</v-card-text>
      <v-form ref="formCancelar" lazy-validation v-if="dataValida">
        <v-row>
          <v-col cols="12">
            <v-textarea
              id="ApuracaoContratoCancelar_justificativa"
              index="1"
              ref="textareaJustificativa"
              name="ApuracaoContratoCancelar_justificativa"
              v-model="justificativa"
              auto-grow
              autofocus
              rows="1"
              :rules="[rules.required]"
              :label="`${$t('label.justificativa')} *`"
              :counter="250"
              maxlength="250">
            </v-textarea>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions v-if="dataValida">
        <v-spacer></v-spacer>
        <v-btn text @click.native="close">{{ $t('label.nao') }}</v-btn>
        <v-btn color="primary darken-1" text @click.native="agree">{{ $t('label.sim') }}</v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="close">{{ $t('label.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { maiorDataAtual, parseDateYYYYMMDD } from '../../common/functions/date-utils';

export default {
  name: 'ApuracaoContratoCancelar',
  props: {
    apuracao: Object,
  },
  data() {
    return {
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      menuCancelar: false,
      dtaInicio: '',
      justificativa: '',
      idApuracao: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    dataValida() {
      return maiorDataAtual(this.dtaInicio);
    },
  },
  watch: {
    menuCancelar(val) {
      if (val) {
        setTimeout(() => this.$refs.textareaJustificativa.focus(), 5E2);
        this.preencherVariaveis();
      }
    },
  },
  methods: {
    close() {
      this.menuCancelar = false;
    },
    agree() {
      if (this.$refs.formCancelar.validate()) {
        const { idApuracao } = this;
        const param = {
          idApuracao,
          justificativa: this.justificativa,
        };
        this.apuracaoAcaoResource.cancelar({ idApuracao }, param)
          .then(() => {
            this.$toast(this.$t('message.atualizado_confira_tabela'));
            this.$emit('ApuracaoContratoCancelar__cancelado', this.justificativa);
            this.close();
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    preencherVariaveis() {
      this.dtaInicio = parseDateYYYYMMDD(this.apuracao.dtaInicio) || this.apuracao.dataInicio;
      this.idApuracao = this.apuracao.idApuracao || this.apuracao.codApuracao;
      this.justificativa = '';
    },
  },
  mounted() {
    this.preencherVariaveis();
  },
};
</script>
