import SpreadsheetAutocomplete from './SpreadsheetAutocomplete';
import Vue from 'vue';

export default {
  props: {
    autocomplete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      datasource: null,
      datasourceMap: {},
      rowIndex: null,
    };
  },
  methods: {
    montaDatasourceMap() {
      this.colunas
        .forEach((objetoColuna, indice) => {
          if (objetoColuna.datasource !== undefined &&
            objetoColuna.atributo !== undefined) {
            this.datasourceMap[indice] = {
              function: objetoColuna.datasource,
              atributo: objetoColuna.atributo,
              atributoExibicao: objetoColuna.atributoExibicao,
              label: objetoColuna.title.replace('*', ''),
              indice,
            };
          }
        });
    },
    autocompleteHabilitado() {
      return this.autocomplete;
    },
    pickDatasource(range) {
      if (range._ref != null && range._ref.col != null && range._ref.row != null) {
        this.datasource = this.datasourceMap[range._ref.col];
        this.rowIndex = range._ref.row + 1;
      } else {
        this.datasource = null;
        this.rowIndex = null;
      }
    },
    selecionado(evt) {
      this.escondeAutocomplete();

      const { value, item } = evt;

      const conteudo = value || '';
      const range = this.determinaCelulaEdicao();

      const celula = range.split(':')[0];
      this.$emit('SPREADSHEET_AUTOCOMPLETE', { celula, item });

      this.preencheCelula(range, conteudo);
    },
    preencheCelula(range, conteudo) {
      this.grid.activeSheet().range(range).values([[conteudo]]);
    },
    montaComponenteVue() {
      const parent = this;
      new Vue({ // eslint-disable-line no-new
        el: '#autocomplete-box',
        template: `
                    <spreadsheet-autocomplete
                      :texto="texto"
                      :funcao-busca="datasource"
                      :atributo="atributo"
                      :indice-vigente="indiceVigente"
                      :atributo-exibicao="atributoExibicao"
                      :label="label"
                      @SPREADSHEET_AUTOCOMPLETE="selecionado"
                      @SPREADSHEET_AUTOCOMPLETE_CLOSE="cancelado"/>
                  `,
        components: { SpreadsheetAutocomplete },
        computed: {
          texto() {
            return parent.texto;
          },
          datasource() {
            return parent.datasource ?
              parent.datasource.function :
              null;
          },
          atributo() {
            return parent.datasource ?
              parent.datasource.atributo :
              null;
          },
          atributoExibicao() {
            return parent.datasource ?
              parent.datasource.atributoExibicao :
              null;
          },
          indiceVigente() {
            return parent.rowIndex;
          },
          label() {
            return parent.datasource ?
              parent.datasource.label :
              null;
          },
        },
        methods: {
          selecionado(evt) {
            parent.selecionado(evt);
          },
          cancelado() {
            parent.escondeAutocomplete();
          }
        },
        created: () => {
          window.setTimeout(() => {
            this.registraAtalho();
            this.divAutocompleteArea()
              .addEventListener('click', this.escondeAutocomplete);
            this.divAutocomplete()
              .addEventListener('click', e => e.stopPropagation());
          });
        },
      });
    },
    iniciaAutocomplete() {
      this.divSpreadsheetArea()
        .appendChild(this.autoCompleteHtml());
      this.montaDatasourceMap();
      this.montaComponenteVue();
    },
    exibeAutocomplete() {
      if (this.datasource === null || this.datasource === undefined) return;

      const cellInput = this.divInput(),
        divSelection = this.divSelection(),
        divActiveCell = this.divActiveCell(),
        top = divActiveCell.offsetTop + 40,
        left = divActiveCell.offsetLeft;

      this.texto = cellInput.textContent;
      cellInput.blur();
      divSelection.blur();

      const autoComplete = this.divAutocomplete();
      autoComplete.style.top = `${top}px`;
      autoComplete.style.left = `${left}px`;

      window.setTimeout(() => {
        this.divAutocompleteArea().classList.toggle('show');
        this.autocompleteInput().focus();
      });
    },
    escondeAutocomplete() {
      this.divAutocompleteArea().classList.toggle('show');
      this.texto = null;
      window.setTimeout(() => this.divSelection().focus());
    },
    registraAtalho() {
      const parent = this;
      document.onkeyup = function (e) {
        e = e || window.event;
        // CTRL ENTER
        if (e.ctrlKey && e.which == 13) {
          parent.exibeAutocomplete();
          return true;
        }
      }
    },
    determinaCelulaEdicao() {
      let indice = this.datasource.indice + 1,
        ret, a, b;
      for (ret = '', a = 1, b = 26; (indice -= a) >= 0; a = b, b *= 26) {
        ret = String.fromCharCode(parseInt((indice % b) / a) + 65) + ret;
      }
      return `${ret}${this.rowIndex}:${ret}${this.rowIndex}`;
    },
    divInput() {
      return document.querySelector('.k-spreadsheet-cell-editor.k-spreadsheet-formula-input');
    },
    divSelection() {
      return document.querySelector('.k-spreadsheet-clipboard');
    },
    divSpreadsheetArea() {
      return document.querySelector('.k-spreadsheet-view');
    },
    divAutocomplete() {
      return document.getElementById('autocomplete-box');
    },
    divAutocompleteArea() {
      return document.getElementById('autocomplete-area');
    },
    divActiveCell() {
      return document.querySelector('.k-spreadsheet-cell.k-spreadsheet-active-cell.k-single');
    },
    autocompleteInput() {
      return document.getElementById('spreadsheet-autocomplete-input');
    },
    autoCompleteHtml() {
      const divAutoCompleteArea = document.createElement('div');
      divAutoCompleteArea.innerHTML = '<div id="autocomplete-box"></div>';
      divAutoCompleteArea.id = 'autocomplete-area';
      return divAutoCompleteArea;
    },
  },
};
