<template lang="html">
  <div>
    <v-form ref="formApuracao" v-model="valid" lazy-validation>

      <v-card-title primary-title class="card-header mt-4">
        {{ $t('title.apuracao') }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.apuracao')}`" />
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="3">
          <v-radio-group v-model="parametrizacao.apuracao.indManual"
            id="config_contrato_apuracao_manual" row>
            <v-radio :label="$t('label.manual')" :value="true"></v-radio>
            <v-radio :label="$t('label.automatico')" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            :label="$t('label.template')"
            v-if="!parametrizacao.apuracao.indManual"
            id="config_contrato_apuracao_template"
            class="Form-text__invalid"
            name="config_contrato_apuracao_template"
            item-text="text"
            item-value="value"
            v-model="parametrizacao.apuracao.template"
            :items="templates"
            :rules="[rules.required]"
            required
            clearable>
          </v-select>
        </v-col>
      </v-row>

      <v-card-title primary-title class="card-header mt-4">
        {{ `${$t('title.periodo_apuracao')} *` }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.apuracao_periodo')}`" />
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6">
          <v-checkbox
              :rules="[rules.requiredLista]"
              required
              v-model="parametrizacao.apuracao.periodos"
              :label="$tc('label.unica_vez', 1)"
              value="UNICA_VEZ"
              @click.native="verificarUnicaVez">
          </v-checkbox>
          <v-checkbox
              :rules="[rules.requiredLista]"
              required
              v-model="parametrizacao.apuracao.periodos"
              :label="$tc('label.anual', 1)"
              value="ANUAL"
              :disabled="unicaVez">
          </v-checkbox>
          <v-checkbox
              :rules="[rules.requiredLista]"
              required
              v-model="parametrizacao.apuracao.periodos"
              :label="$tc('label.trimestral', 1)"
              value="TRIMESTRAL"
              :disabled="unicaVez">
          </v-checkbox>
          <v-checkbox
              :rules="[rules.requiredLista]"
              required
              v-model="parametrizacao.apuracao.periodos"
              :label="$tc('label.mensal', 1)"
              value="MENSAL"
              :disabled="unicaVez">
          </v-checkbox>
        </v-col>
      </v-row>

      <v-card-title primary-title class="card-header mt-4">
        {{ $t('title.inicio_processo_apuracao') }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.apuracao_inicio')}`" />
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6">
          <v-radio-group v-model="parametrizacao.apuracao.indApurarInicio"
            id="config_contrato_apuracao_inicio">
            <v-radio :label="$t('label.a_partir_inicio_vigencia')" :value="true"></v-radio>
            <v-radio :label="$t('label.a_partir_vigencia_concluida')" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <div v-if="!parametrizacao.apuracao.indManual">
        <v-card-title primary-title class="card-header mt-4">
          {{ $t('title.metas') }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.meta')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6">
            <v-radio-group v-model="parametrizacao.apuracao.meta"
              id="config_contrato_apuracao_meta">
              <v-radio :label="$t('label.nao_se_aplica')" value="N/A"></v-radio>
              <v-radio :label="$t('label.minima')" value="MINIMA"></v-radio>
              <v-radio :label="$t('label.intervalo')" value="INTERVALO"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </div>

      <div v-if="!parametrizacao.apuracao.indManual">
        <v-card-title primary-title class="card-header mt-4">
          {{ `${$tc('title.calculo_apuracao', 1)} *` }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.apuracao_calculo_apuracao')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6">
            <v-checkbox v-for="(m, index) in calculosApuracao"
              :key="index"
              :rules="[rules.requiredLista]" required
              v-model="parametrizacao.apuracao.calculosApuracao" :label="m.text" :value="m.value">
            </v-checkbox>
          </v-col>
        </v-row>
      </div>

      <div v-if="!parametrizacao.apuracao.indManual">
        <v-card-title primary-title class="card-header mt-4">
          {{ `${$tc('label.unidade_medida', 1)}` }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.apuracao_unidade_medida')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6">
            <v-autocomplete
              id="apuracao_unidade_medida"
              name="apuracao_unidade_medida"
              v-model="parametrizacao.apuracao.unidadesMedida"
              :items="listaUnidadesMedida"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomUnidade"
              item-value="id"
              return-object
              :label="`${$tc('label.unidade_medida', 1)}`"
              clearable
              multiple
              chips
              deletable-chips
              :error-messages="!parametrizacao.apuracao.unidadesMedida.length
               && !unidadeMedidaValida ?
                `${$t('message.campo_obrigatorio')}` : null"
              :search-input.sync="buscaListaUnidadesMedida"
              @click:append="triggerUnidadeMedida"
              @click.native="buscarUnidadesMedida"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </div>

      <div v-if="!parametrizacao.apuracao.indManual">
        <v-card-title primary-title class="card-header mt-4">
          {{ `${$tc('label.forma_bonificacao', 1)} *` }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.apuracao_bonificacao')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6">
            <v-checkbox v-for="(m, index) in bonificacoes" :key="index"
              :rules="[rules.requiredLista]" required
              v-model="parametrizacao.apuracao.bonificacoes" :label="m.text" :value="m.value">
            </v-checkbox>
          </v-col>
        </v-row>
      </div>

      <div v-if="!parametrizacao.apuracao.indManual">
        <v-card-title primary-title class="card-header mt-4">
          {{ `${$tc('title.calculo_bonificacao', 1)} *` }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.apuracao_calculo_bonificacao')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6">
            <v-checkbox
                v-for="(m, index) in calculosBonificacao"
                :key="index"
                :rules="[rules.requiredLista]"
                required
                v-model="parametrizacao.apuracao.calculosBonificacao"
                :label="m.text"
                :value="m.value">
            </v-checkbox>
          </v-col>
        </v-row>
      </div>

      <div v-if="!parametrizacao.apuracao.indManual">
        <v-card-title primary-title class="card-header mt-4">
          {{ `${$tc('title.base_calculo', 1)} *` }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.apuracao_base_calculo')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6">
            <v-checkbox v-for="(m, index) in bases" :key="index"
              :rules="[rules.requiredLista]" required
              v-model="parametrizacao.apuracao.bases" :label="m.text" :value="m.value">
            </v-checkbox>
          </v-col>
        </v-row>
      </div>

      <div v-if="!parametrizacao.apuracao.indManual">
        <v-card-title primary-title class="card-header mt-4">
          {{ `${$tc('title.desconto_calculo', 2)}` }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.apuracao_desconto_calculo')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6">
            <v-checkbox v-for="(m, index) in descontos" :key="index"
              v-model="parametrizacao.apuracao.descontos" :label="m.text" :value="m.value">
            </v-checkbox>
          </v-col>
        </v-row>
      </div>

      <v-card-title primary-title class="card-header mt-4">
        {{ `${$tc('title.tipo_pagamento', 1)} *` }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.apuracao_pagamento')}`" />
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6">
          <v-checkbox v-for="(p, index) in pagamentos" :key="index"
            :rules="[rules.requiredLista]" required
            v-model="parametrizacao.apuracao.pagamentos" :label="p.descricao" :value="p.id">
          </v-checkbox>
        </v-col>
      </v-row>

      <div v-if="!modoVarejo">
        <v-card-title primary-title class="card-header mt-4">
          {{ $tc('title.investimento_vendas', 1) }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.apuracao_vendas')}`" />
        </v-card-title>
        <v-row class="mx-4 mt-4">
          <v-col cols="12" sm="6" md="4">
            <v-switch
              id="config_contrato_apuracao_investimento_vendas"
              :label="`${$t('message.habilitar_calculo_automatico_investimento_vendas')}`"
              v-model="parametrizacao.apuracao.indCalculoAutomaticoVendas" />
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="parametrizacao.apuracao.indCalculoAutomaticoVendas">
            {{ $t('message.informe_periodo_historico_meses_anteriores') }}
          </v-col>
          <v-col cols="12" sm="6" md="2" v-if="parametrizacao.apuracao.indCalculoAutomaticoVendas">
            <v-text-field
              v-model="parametrizacao.apuracao.periodoHistorico"
              :label="$t('label.historico_meses')"
              type="number"
              min="0"
              :rules="[rules.required]"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="parametrizacao.apuracao.indCalculoAutomaticoVendas">
            {{ `${$tc('label.base_calculo', 1)}: *` }}
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="parametrizacao.apuracao.indCalculoAutomaticoVendas">
            <v-checkbox v-for="(m, index) in bases" :key="index"
              :rules="[rules.requiredLista]" required
              v-model="parametrizacao.apuracao.basesVendas" :label="m.text" :value="m.value"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </div>

      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('title.upload_evidencia', 1) }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.apuracao_evidencia')}`" />
      </v-card-title>
      <v-row class="mx-4 mt-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_contrato_apuracao_habilitar_evidencia"
            :label="`${$t('message.habilitar_upload_evidencia')}`"
            v-model="parametrizacao.apuracao.indHabilitarEvidencia"
            @change="alterarIndHabilitarEvidencia"/>
        </v-col>
      </v-row>
      <v-row class="mx-4 mt-4" v-if="parametrizacao.apuracao.indManual">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_contrato_apuracao_evidencia_obrigatoria"
            :disabled="!parametrizacao.apuracao.indHabilitarEvidencia"
            :label="`${$t('message.upload_evidencia_obrigatoria')}`"
            v-model="parametrizacao.apuracao.indEvidencia" />
        </v-col>
      </v-row>
      <v-row class="mx-4 mt-4" v-if="!parametrizacao.apuracao.indManual">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_contrato_apuracao_evidencia_obrigatoria_sellout"
            :disabled="!parametrizacao.apuracao.indHabilitarEvidencia"
            :label="`${$t('message.upload_evidencia_obrigatoria_sellout')}`"
            v-model="parametrizacao.apuracao.indEvidenciaSellout" />
        </v-col>
      </v-row>
      <v-row class="mx-4 mt-4" v-if="!parametrizacao.apuracao.indManual">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_contrato_apuracao_evidencia_obrigatoria_sellin"
            :disabled="!parametrizacao.apuracao.indHabilitarEvidencia"
            :label="`${$t('message.upload_evidencia_obrigatoria_sellin')}`"
            v-model="parametrizacao.apuracao.indEvidenciaSellin" />
        </v-col>
      </v-row>

      <div v-if="!parametrizacao.apuracao.indManual">
        <v-card-title primary-title class="card-header mt-4">
          {{ $t('title.valor_base_crescimento') }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_contrato.valor_base_crescimento')}`" />
        </v-card-title>
        <v-row class="mx-4">
          <v-col cols="12" sm="6" md="3">
            <v-radio-group v-model="parametrizacao.apuracao.indCrescimentoManual"
              id="config_contrato_apuracao_manual" row>
              <v-radio :label="$t('label.informado_manualmente')" :value="true"></v-radio>
              <v-radio :label="$t('label.calculado_sistema')" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-select
              :label="$t('label.template')"
              v-if="!parametrizacao.apuracao.indCrescimentoManual"
              id="config_contrato_apuracao_template"
              class="Form-text__invalid"
              name="config_contrato_apuracao_template"
              item-text="text"
              item-value="value"
              v-model="parametrizacao.apuracao.templateCrescimento"
              :items="templatesCrescimento"
              :rules="[rules.required]"
              required
              clearable>
            </v-select>
          </v-col>
        </v-row>
      </div>

      <v-row justify="end" class="my-4">
        <v-btn id="config_contrato_passo4_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="primary" id="config_contrato_passo4_botao_voltar" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
        <v-btn color="primary" id="config_contrato_passo4_botao_proximo" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script type="text/javascript">
import Passo4 from './Passo4';
import TooltipAjuda from '../TooltipAjuda';

export default {
  name: 'ParametrizacaoContratoPasso4',
  props: {
    id: Number,
    modoVarejo: Boolean,
  },
  components: {
    TooltipAjuda,
  },
  data() {
    return {
      tipoBeneficioResources: this.$api.tipoBeneficio(this.$resource),
      paramContratoResources: this.$api.parametrizacaoContrato(this.$resource),
      unidadeMedidaResources: this.$api.unidadeMedida(this.$resource),
      unidadeMedidaValida: true,

      passo: 4,
      parametrizacao: {
        apuracao: new Passo4(this.id, ''),
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredLista: (value) => (!!value && value.length > 0) || '',
      },
      activeBtn: 0,
      templates: [
        { text: `${this.$tc('label.crescimento', 1)}`, value: 'CRESCIMENTO' },
        { text: `${this.$tc('label.meta_nao_se_aplica', 1)}`, value: 'META_N/A' },
      ],
      templatesCrescimento: [
        { text: `${this.$tc('label.historico_ano_anterior', 1)}`, value: 'HISTORICO_ANO_ANTERIOR' },
      ],
      calculosApuracao: [
        { text: `${this.$tc('label.sellin_valor', 1)}`, value: 'SELLIN_VALOR' },
        { text: `${this.$tc('label.sellin_volume', 1)}`, value: 'SELLIN_VOLUME' },
        { text: `${this.$tc('label.sellout_valor', 1)}`, value: 'SELLOUT_VALOR' },
        { text: `${this.$tc('label.sellout_volume', 1)}`, value: 'SELLOUT_VOLUME' },
      ],
      bases: [
        { text: `${this.$tc('label.valor_liquido', 1)}`, value: 'VALOR_LIQUIDO' },
        { text: `${this.$tc('label.valor_bruto', 1)}`, value: 'VALOR_BRUTO' },
      ],
      bonificacoes: [
        { text: `${this.$tc('label.fixo', 1)}`, value: 'FIXO' },
        { text: `${this.$tc('label.percentual', 1)}`, value: 'PERCENTUAL' },
      ],
      calculosBonificacao: [
        { text: `${this.$tc('label.sellin', 1)}`, value: 'SELLIN' },
        { text: `${this.$tc('label.sellout', 1)}`, value: 'SELLOUT' },
      ],
      descontos: [
        { text: this.$tc('label.ipi', 1), value: 'IPI' },
        { text: this.$tc('label.icms', 1), value: 'ICMS' },
        { text: this.$tc('label.pis_cofins', 1), value: 'PIS_COFINS' },
        { text: this.$tc('label.devolucao', 2), value: 'DEVOLUCOES' },
      ],
      pagamentos: [],
      vigenciaAberta: false,
      valid: true,
      apuracaoBanco: [],
      unicaVez: false,
      listaUnidadesMedida: [],
      buscaListaUnidadesMedida: null,
    };
  },
  watch: {
    parametrizacao: {
      handler() {
        this.validarUnidadeMedida();
      },
      deep: true,
    },
  },
  methods: {
    alterarIndHabilitarEvidencia() {
      if (!this.parametrizacao.apuracao.indHabilitarEvidencia) {
        this.parametrizacao.apuracao.indEvidencia = false;
        this.parametrizacao.apuracao.indEvidenciaSellout = false;
        this.parametrizacao.apuracao.indEvidenciaSellin = false;
      }
    },
    setVigencia(vigenciaAberta) {
      this.vigenciaAberta = vigenciaAberta;
    },
    buscarTiposBeneficios() {
      this.tipoBeneficioResources.listarAtivos().then((response) => {
        this.pagamentos = response.data;
      });
    },
    cancelar() {
      this.$emit('PARAMETRIZACAO_CONTRATO_CANCELAR');
    },
    proximoPasso() {
      if (this.$refs.formApuracao.validate() && this.validarUnidadeMedida()) {
        this.$emit('PARAMETRIZACAO_CONTRATO_CONTINUAR', this.passo + 1, this.id);
        this.$emit('PARAMETRIZACAO_CONTRATO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
      } else {
        this.$toast(this.$t('errors.campos_obrigatorios.nao_informados'));
      }
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_CONTRATO_VOLTAR', this.passo - 1);
    },
    prepararPasso() {
      if (this.id) {
        this.obter(this.id);
      }
    },
    obter(id) {
      this.paramContratoResources.obterPasso4({ id }, { id }).then((response) => {
        this.apuracaoBanco = response.data.apuracao;
        this.parametrizacao.apuracao = this.apuracaoBanco;
        if (this.parametrizacao.apuracao.periodos.filter((p) => p === 'UNICA_VEZ').length) {
          this.unicaVez = true;
        }
        this.listaUnidadesMedida = [...this.parametrizacao.apuracao.unidadesMedida || []];
        this.$forceUpdate();
      }, (err) => {
        this.$error(this, err);
      });
    },
    verificarUnicaVez() {
      this.unicaVez = !this.unicaVez;
      if (this.unicaVez) {
        this.parametrizacao.apuracao.periodos = [];
        this.parametrizacao.apuracao.periodos.push('UNICA_VEZ');
      }
    },
    buscarUnidadesMedida() {
      const param = {
        nom_unidade: this.buscaListaUnidadesMedida,
        size: 50,
        page: 0,
      };
      return this.unidadeMedidaResources.listarAtivos(param)
        .then((response) => {
          this.listaUnidadesMedida = response.data.resposta;
        });
    },
    triggerUnidadeMedida() {
      document.getElementById('apuracao_unidade_medida').click();
    },
    validarUnidadeMedida() {
      const volume = this.parametrizacao.apuracao.calculosApuracao.filter((c) => c === 'SELLIN_VOLUME' || c === 'SELLOUT_VOLUME');
      if (volume && volume.length && !this.parametrizacao.apuracao.unidadesMedida.length) {
        this.unidadeMedidaValida = false;
        return false;
      }
      this.unidadeMedidaValida = true;
      return true;
    },
  },
  mounted() {
    const verba = new Passo4(this.id, '', true);
    this.parametrizacao.apuracao = verba;
    this.buscarTiposBeneficios();
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  @import '~@/config/theme/theme.scss';
  .card-header {
    background-color: $primary-color;
    color: #ffffff;
  }
  .margem {
    margin-top: 12px;
    margin-bottom: 0px !important;
  }
</style>
