import getBasePath from '../functions/api-resource';

const basePath = getBasePath('apuracao', 'dashboard');

const apuracaoActions = {
  pendencias: { method: 'GET', url: `${basePath}/pendencias` },
  apuracao: { method: 'GET', url: `${basePath}/apuracao/{id}` },
  listarDashApuracao: { method: 'GET', url: `${basePath}` },
  listarApuracoes: { method: 'GET', url: `${basePath}/apuracoes` },
  listarReivindicacoes: { method: 'GET', url: `${basePath}/reinvidicacoes` },
  listarTiposNegociacoes: { method: 'GET', url: `${basePath}/tipos-negociacoes` },
  listarMarcas: { method: 'GET', url: `${basePath}/marcas?marca={nome}` },
  listarFamilia: { method: 'GET', url: `${basePath}/familia?familia={nome}` },
  listarLinhaProduto: { method: 'GET', url: `${basePath}/linha-produto?linhaProduto={nome}` },
  listarPeriodos: { method: 'GET', url: `${basePath}/periodo` },
  listarSolicitantes: { method: 'GET', url: `${basePath}/solicitante` },
  listarTipoAcordo: { method: 'GET', url: `${basePath}/tipo-acordo` },
  statusEntidade: { method: 'GET', url: `${basePath}/status-entidade` },
  apuracoesFinalizadas: { method: 'GET', url: `${basePath}/cliente/finalizadas?competencia={competencia}` },
  apuracoesPendentes: { method: 'GET', url: `${basePath}/cliente/pendentes?competencia={competencia}` },
};

export default (resource) => resource(`${basePath}`, {}, apuracaoActions);
