<template lang="html">
  <v-data-table
    v-show="!esperar"
    class="elevation-1 tabela_relatorio_atingimento_historico"
    id="tabela_dados"
    :headers="headers"
    :items="listaDados"
    :options.sync="pagination"
    :server-items-length="totalPage"
    :no-data-text="$t('label.tabela_sem_conteudo')"
    :footer-props="{
      itemsPerPageOptions: [10, 25, 50],
    }"
    >
    <template v-slot:top>
      <v-toolbar color="white" class="elevation-0">
        <v-toolbar-title>{{$tc('title.relatorio', 1)}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="listaDados.length" icon @click="requestExportacao">
          <v-icon>get_app</v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.periodo_planejamento="{ item }">
      {{ item.periodo_planejamento }}
    </template>
    <template v-slot:item.estrutura_venda_diretoria="{ item }">
      {{ item.estrutura_venda_diretoria }}
    </template>
    <template v-slot:item.estrutura_venda_regional="{ item }">
      {{ item.estrutura_venda_regional }}
    </template>
    <template v-slot:item.estrutura_venda_rtv="{ item }">
      {{ item.estrutura_venda_rtv }}
    </template>
    <template v-slot:item.nome_fantasia="{ item }">
      {{ item.nome_fantasia }}
    </template>
    <template v-slot:item.cluster="{ item }">
      {{ item.cluster }}
    </template>
    <template v-slot:item.classificacao_dpm="{ item }">
      {{ item.classificacao_dpm }}
    </template>
    <template v-slot:item.grupo_cultura="{ item }">
      {{ item.grupo_cultura }}
    </template>
    <template v-slot:item.meta_marcas_fortes="{ item }">
      {{ getNumber(item.meta_marcas_fortes) }}
    </template>
    <template v-slot:item.faturado_marcas_fortes="{ item }">
      {{ getNumber(item.faturado_marcas_fortes) }}
    </template>
    <template v-slot:item.atingimento_fat_marcas_fortes="{ item }">
      {{ getPercent(item.atingimento_fat_marcas_fortes) }}
    </template>
    <template v-slot:item.meta_total="{ item }">
      {{ getNumber(item.meta_total) }}
    </template>
    <template v-slot:item.faturado_total="{ item }">
      {{ getNumber(item.faturado_total) }}
    </template>
    <template v-slot:item.atingimento_faturado_total="{ item }">
      {{ getPercent(item.atingimento_faturado_total) }}
    </template>
    <template v-slot:item.status_atingimento="{ item }">
      {{ item.status_atingimento }}
    </template>
  </v-data-table>
</template>

<script type="text/javascript">
import { listarRelatorioStatusAtingimento } from '@/common/resources/relatorio-contrato';
import { removeEmptyProperties, getNumber, getPercent } from '../../../../produto/common/functions/helpers';
import exportacao from '../../../../produto/common/functions/exportacao';

export default {
  props: {
    filtros: Object,
  },
  data() {
    return {
      filtrosAplicados: this.filtros,
      pagination: {},
      listaDados: [],
      totalPage: 0,
      esperar: false,
      headers: [
        { text: this.$tc('label.periodo_planejamento', 1), value: 'periodo_planejamento', sortable: false },
        { text: this.$tc('label.estrutura_venda_diretoria', 1), value: 'estrutura_venda_diretoria', sortable: false },
        { text: this.$tc('label.regional', 1), value: 'estrutura_venda_regional', sortable: false },
        { text: this.$tc('label.rtv', 1), value: 'estrutura_venda_rtv', sortable: false },
        { text: this.$tc('label.holding', 1), value: 'nome_fantasia', sortable: false },
        { text: this.$tc('label.cluster', 1), value: 'cluster', sortable: false },
        { text: this.$tc('label.classificacao', 1), value: 'classificacao_dpm', sortable: false },
        { text: this.$tc('label.grupo_cultura', 1), value: 'grupo_cultura', sortable: false },
        { text: this.$tc('label.meta_marcas_fortes', 1), value: 'meta_marcas_fortes', sortable: false },
        { text: this.$tc('label.faturado_marcas_fortes', 1), value: 'faturado_marcas_fortes', sortable: false },
        { text: this.$tc('label.atingimento_fat_marcas_fortes', 1), value: 'atingimento_fat_marcas_fortes', sortable: false },
        { text: this.$tc('label.meta_total', 1), value: 'meta_total', sortable: false },
        { text: this.$tc('label.faturado_total', 1), value: 'faturado_total', sortable: false },
        { text: this.$tc('label.atingimento_faturado_total', 1), value: 'atingimento_faturado_total', sortable: false },
        { text: this.$tc('label.status_atingimento', 1), value: 'status_atingimento', sortable: false },
      ],
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscar();
      },
    },
    pagination: {
      handler() {
        this.buscar();
      },
      deep: true,
    },
  },
  methods: {
    getNumber,
    getPercent,
    buscar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscarDados();
      }, 5E2);
    },
    buscarDados() {
      if (!this.filtros) {
        this.pararEsperar();
        return;
      }
      const params = {
        ...this.filtrosAplicados,
        historico: true,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      listarRelatorioStatusAtingimento(params, this.$resource)
        .then((response) => {
          this.listaDados = response.data.resposta;
          this.totalPage = response.data.quantidadeRegistrosPagina;
          this.pararEsperar();
        })
        .catch((err) => {
          this.pararEsperar();
          this.$error(this, err);
        });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    requestExportacao() {
      const params = { ...this.filtrosAplicados };
      const filtroTratado = removeEmptyProperties(params);
      exportacao.exportar(null, 'relatorio_status_atingimento_historico', this, filtroTratado);
    },
  },
};
</script>

<style>
.Container_Tabela table.v-table tbody td:first-child,.Container_Tabela  table.v-table tbody td:not(:first-child),
  .Container_Tabela table.v-table tbody th:first-child, .Container_Tabela table.v-table tbody th:not(:first-child),
  .Container_Tabela table.v-table thead td:first-child, .Container_Tabela table.v-table thead td:not(:first-child),
  .Container_Tabela table.v-table thead th:first-child, .Container_Tabela table.v-table thead th:not(:first-child) {
  padding: 0 11px;
}
.tabela_relatorio_atingimento_historico .v-data-table__wrapper table {
  width: 150%;
}
</style>
