import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('workflow', 'execucao/apuracao-acao');

const workflowApuracaoAcaoActions = {
  solicitante: { method: 'GET', url: `${basePath}/solicitante/{idApuracao}` },
  iniciaFluxo: { method: 'POST', url: `${basePath}/{idApuracao}` },
  aprovador: { method: 'GET', url: `${basePath}/{idApuracao}/passos/aprovador` },
  reprovar: { method: 'PUT', url: `${basePath}/{idApuracao}/passos/reprovar` },
  aprovarPasso: { method: 'PUT', url: `${basePath}/{idApuracao}/passos/aprovar` },
  enviarAnalise: { method: 'PUT', url: `${basePath}/{idApuracao}/passos/analise` },
  statusPasso: { method: 'GET', url: `${basePath}/{idApuracao}/passos/status` },
  existeFluxoCadastrado: { method: 'GET', url: `${basePath}/{idApuracao}/fluxo-cadastrado` },
  cancelar: { method: 'PUT', url: `${basePath}/{idApuracao}/cancelar` },
  nomeFornecedorAprovador: { method: 'GET', url: `${basePath}/fornecedor-aprovador/{id}` },
};
export default (resource) => resource(`${basePath}`, {}, workflowApuracaoAcaoActions);

export const pendencias = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pendencias', parametros).doGet();
