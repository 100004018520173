import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'divisao');

const divisaoActions = {
  ...genericActions(basePath),
  autocomplete: { method: 'GET', url: `${basePath}/autocomplete?{&id*}` },
  arvore: { method: 'GET', url: `${basePath}/arvore?filtro={filtro}&{&idsFilhosNotIn*}` },
  atualizarDivisao: { method: 'PUT', url: `${basePath}/{id}` },
  buscarFilhos: { method: 'GET', url: `${basePath}/filhas?{&idsFilhosNotIn*}` },
  hierarquia: { method: 'GET', url: `${basePath}/hierarquia` },
  hierarquiaFilha: { method: 'GET', url: `${basePath}/hierarquia-filha` },
  listar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}&{&camposDinamicos*}` },
  pesquisasrDivisoesSuperiores: { method: 'GET', url: `${basePath}/pai/hierarquia/{idEstruturaUsuario}` },
  listarAutocomplete: { method: 'GET', url: `${basePath}?nome={nome}` },
  listarFiltrado: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}&filtro={filter}` },
  selecaoPlanilha: { method: 'GET', url: `${basePath}/selecao/planilha?autocomplete={autocomplete}{&nomesDivisao*}` },
  regional: { method: 'GET', url: `${basePath}/regional` },
};

export default (resource) => resource(`${basePath}`, {}, divisaoActions);
