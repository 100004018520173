<template>
  <div id="ParametrizacaoPeriodo__Elegibilidade">
    <spreadsheet
      ref="spreadsheet"
      v-if="planilhaPronta"
      :toolbar="true"
      :disabled-sort="true"
      :sheetsbar="false"
      :rows="rowsNumber"
      :data="elegiveis"
      :colunas="colunas"
      :estilos="estilos"
      :formulas="formulas"
      :selectRange="celulaAtiva"
      :tamanhoColunas="tamanhoColunas"
      :toolbar-list="toolbarList"
      :filter="filter"
      :frozenRows="1"
      @DATASOURCE_CHANGING="validarAlteracao"
    />
    <confirm
      ref="confirmDialog"
      :message="mensagem"
      hide-disagree
      :agree-label="$t('label.ok')"
      :persistent="true">
    </confirm>
  </div>
</template>

<script>
import Spreadsheet from '../../../../produto/shared-components/spreadsheet/Spreadsheet';
import Confirm from '../../../../produto/shared-components/vuetify/dialog/Confirm';
import { generateComputed } from '../../../../produto/common/functions/roles-computed-generator';
import ParametrizacaoBus from '../ParametrizacaoBus';

export default {
  name: 'Elegibilidade',
  components: {
    Confirm,
    Spreadsheet,
  },
  props: {
    idPeriodo: {
      type: Number,
      default: null,
    },
    idsExtensoesClientesIndicadores: {
      type: Array,
    },
    idsExtensoesClientesClassificacao: {
      type: Array,
    },
    somenteLeitura: false,
    alterarFinalizado: false,
  },
  data() {
    return {
      parametrizacaoPeriodoPasso1: this.$api.parametrizacaoPeriodoPasso1(this.$resource),
      planilhaPronta: false,
      rowsNumber: 50,
      toolbarList: {
        home: ['filter', 'exportAs'], // define single tool
        insert: false,
        data: false, // disable tab
      },
      colunas: [
        { field: 'holding', title: this.$tc('label.holding', 1) },
        { field: 'canal', title: this.$tc('label.canal', 1) },
        { field: 'elegivelPlanilha', title: this.$tc('label.elegivel', 1) },
        { field: 'dpmPeriodoAnterior', title: this.$tc('label.dpm_periodo_anterior', 1) },
      ],
      filter: {
        ref: 'A1:D1',
        columns: [],
      },
      estilos: [
        {
          range: 'A1:D1',
          metodos: {
            textAlign: 'center',
            bold: true,
            enable: false,
            background: 'rgb(44,130,190)',
            color: 'rgb(255,255,255)',
          },
        },
      ],
      carregado: false,
      formulas: [],
      elegiveis: [],
      tamanhoColunas: [
        { width: 250 },
        { width: 250 },
        { width: 250 },
        { width: 250 },
      ],
      celulaAtiva: 'A2',
      sim: this.$t('label.sim'),
      nao: this.$t('label.nao'),
      range: null,
      mensagem: '',
      jaBuscouPeriodoAnterior: false,
    };
  },
  computed: {
    ...generateComputed('PARAM PERIODO', [
      'canAccessCRUD',
    ]),
    canHabilitarFlagDpmAnterior() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('SIMULA_DPM_ANTERIOR')).length;
    },
  },
  watch: {
    idsExtensoesClientesIndicadores(ids) {
      this.elegiveis.forEach((e) => {
        e.usadoIndicador = !!ids.includes(e.id);
      });
    },
    idsExtensoesClientesClassificacao(ids) {
      this.elegiveis.forEach((e) => {
        e.usadoClassificacao = !!ids.includes(e.id);
      });
    },
  },
  methods: {
    validarAlteracao(el) {
      if (el.changeType === 'edit' || el.changeType === 'paste') {
        const novosValores = this.recuperarValoresAlterados(el);
        const val = this.validar(el, novosValores);
        if (val.valorErrado || val.utilizadoClassificacao || val.utilizadoIndicadores || val.operacaoInvalida) {
          this.mensagem = this.montaMensagemErro(val.valorErrado, val.utilizadoClassificacao, val.utilizadoIndicadores, val.operacaoInvalida);
          this.$refs.confirmDialog.open();
          el.preventDefault();
        } else if (this.alterarFinalizado && !val.extensao.elegivel && novosValores[0] === this.sim) {
          ParametrizacaoBus.$emit('incluirValoresPeriodoFinalizado', val.extensao.id);
        }
      }
    },
    validar(el, novosValores) {
      const val = this.montaObjeto();
      el.range
        .forEachCell((linha) => {
          val.extensao = this.elegiveis[linha - 1];
          if (novosValores[val.index] !== this.sim && novosValores[val.index] !== this.nao && !val.valorErrado && el.changeType === 'paste') {
            val.valorErrado = true;
          }
          if (novosValores[val.index] === this.nao && val.extensao.usadoClassificacao && !val.utilizadoClassificacao) {
            val.utilizadoClassificacao = true;
          }
          if (novosValores[val.index] === this.nao && val.extensao.usadoIndicador && !val.utilizadoIndicadores) {
            val.utilizadoIndicadores = true;
          }
          if (this.alterarFinalizado && val.extensao.elegivel && novosValores[val.index] === this.nao && val.extensao.existeDpmCriada) {
            val.operacaoInvalida = true;
          }
          val.index += 1;
        });
      return val;
    },
    montaObjeto() {
      return {
        valorErrado: false,
        utilizadoClassificacao: false,
        utilizadoIndicadores: false,
        operacaoInvalida: false,
        extensao: {},
        index: 0,
      };
    },
    recuperarValoresAlterados(el) {
      const valores = [];
      if (el.changeType === 'edit') {
        valores.push(el.data);
      } else if (el.changeType === 'paste') {
        valores.push(...el.data.map((d) => d[0].value));
      }
      return valores;
    },
    montaMensagemErro(valorErrado, utilizadoClassificacao, utilizadoIndicadores, operacaoInvalida) {
      let msg = '';
      if (operacaoInvalida) {
        msg += this.$t('message.existe_dpm_periodo');
        msg += '<br />';
      }
      if (valorErrado) {
        msg += this.$t('message.valor_lista_invalido', { valores: `${this.$t('label.sim')}, ${this.$t('label.nao')}.` });
        msg += '<br />';
      }
      if (utilizadoClassificacao || utilizadoIndicadores) {
        let passos = '';
        if (utilizadoIndicadores) {
          passos = `3 - ${this.$tc('label.indicador', 2)} / ${this.$tc('label.secao', 1)} - ${this.$tc('label.configuracao', 1)}`;
        }
        if (utilizadoClassificacao) {
          passos += `${utilizadoIndicadores ? ' e' : ''} 4 - ${this.$t('label.classificacao')} / ${this.$tc('label.secao', 1)} - ${this.$tc('label.configuracao', 1)}`;
        }
        msg += this.$t('message.nome_fantasia_configurado_passo', { passos });
      }
      return msg;
    },
    recarregarBusca(idsContratoTipo) {
      if (!this.idPeriodo && idsContratoTipo
          && idsContratoTipo.length && !this.jaBuscouPeriodoAnterior) {
        this.planilhaPronta = false;
        this.buscarExtensoesElegiveis(idsContratoTipo, this.acharElefiveisAposBusca);
      }
    },
    acharElefiveisAposBusca() {
      const elegivel = this.elegiveis.find((e) => e.elegivel);
      if (elegivel) {
        this.jaBuscouPeriodoAnterior = true;
      }
    },
    getElegibilidade() {
      return this.$refs.spreadsheet.getDataSource()
        .filter((item) => item.elegivelPlanilha === this.sim)
        .map((item) => item.id);
    },
    getDpmPeriodoAnterior() {
      return this.$refs.spreadsheet.getDataSource()
        .filter((item) => item.dpmPeriodoAnterior === this.sim)
        .map((item) => item.id);
    },
    buscarExtensoesElegiveis(idsContratoTipo, cb) {
      const params = {};
      if (this.idPeriodo) {
        params.idPeriodoConfiguracao = this.idPeriodo;
      } else if (idsContratoTipo && idsContratoTipo.length) {
        params.idsContratoTipo = idsContratoTipo;
      }
      this.parametrizacaoPeriodoPasso1.buscarExtensoesElegiveis(params)
        .then((response) => {
          this.elegiveis = response.body;
          this.rowsNumber = (this.elegiveis.length || 10) + 1;
          this.bloquearColunas();
          this.montarFormulas();
          this.converterValores();
          this.prencherFiltro();
          this.planilhaPronta = true;
          if (cb) {
            cb();
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    prencherFiltro() {
      this.filter.ref = `A1:D${this.rowsNumber}`;
    },
    converterValores() {
      this.elegiveis.forEach((item) => {
        item.elegivelPlanilha = item.elegivel ? this.sim : this.nao;
        item.dpmPeriodoAnterior = item.indPeriodoAnterior ? this.sim : this.nao;
      });
    },
    bloquearColunas() {
      const estiloSomenteLeitura = {
        enable: false,
        color: 'rgb(0,0,0)',
        bold: true,
      };
      if (this.somenteLeitura && !this.canAccessCRUD) {
        this.estilos.push({
          range: `A2:D${this.rowsNumber}`,
          metodos: estiloSomenteLeitura,
        });
      } else {
        this.estilos.push({
          range: `A2:B${this.rowsNumber}`,
          metodos: estiloSomenteLeitura,
        });
        if (!this.canHabilitarFlagDpmAnterior) {
          this.estilos.push({
            range: `D2:D${this.rowsNumber}`,
            metodos: estiloSomenteLeitura,
          });
        }
      }
    },
    montarFormulas() {
      this.formulas.push({
        range: `C2:C${this.rowsNumber}`,
        metodos: {
          validation: {
            comparerType: 'list',
            allowNulls: false,
            showButton: true,
            from: `"${this.$t('label.sim')},${this.$t('label.nao')}"`,
            type: 'reject',
            messageTemplate:
                this.$t('message.valor_lista_invalido', { valores: `${this.$t('label.sim')}, ${this.$t('label.nao')}.` }),
            titleTemplate: this.$tc('title.erro', 1),
          },
        },
      },
      {
        range: `D2:D${this.rowsNumber}`,
        metodos: {
          validation: {
            comparerType: 'list',
            allowNulls: false,
            showButton: true,
            from: `"${this.$t('label.sim')},${this.$t('label.nao')}"`,
            type: 'reject',
            messageTemplate:
              this.$t('message.valor_lista_invalido', { valores: `${this.$t('label.sim')}, ${this.$t('label.nao')}.` }),
            titleTemplate: this.$tc('title.erro', 1),
          },
        },
      });
    },
  },
  mounted() {
    this.buscarExtensoesElegiveis();
  },
};
</script>
