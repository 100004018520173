<template>
  <div class="FornecedorList">
    <v-card class="card-size">
      <v-card-title>
        <h2 class="headline mb-0">{{ $tc('title.fornecedor', 2) }}</h2>
      </v-card-title>

      <fornecedor-industria-sidebar-importacao
        v-if="canAccessCRUD && tipoImportacao"
        ref="sidebar">
      </fornecedor-industria-sidebar-importacao>

      <fornecedor-industria-list-dados :canAccessCRUD="canAccessCRUD"
        @TABELA_EXPORTAR_DADOS="requestExportacao"
        @TABELA_IMPORTAR_DADOS="$refs.sidebar.open()"
        ref="tabelaFornecedor">
      </fornecedor-industria-list-dados>

      <v-card-actions v-if="canAccessCRUD">
        <v-row>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn id="btn_novo" fab @click="novo" class="v-btn__fab" color="primary" v-on="on" bottom right fixed>
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>{{$t('label.novo')}}</span>
          </v-tooltip>
        </v-row>
      </v-card-actions>

    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { generateComputed } from '../../../produto/common/functions/roles-computed-generator';
import FornecedorIndustriaListDados from './FornecedorIndustriaListDados';
import exportacao from '../../../produto/common/functions/exportacao';
import FornecedorIndustriaSidebarImportacao from './FornecedorIndustriaSidebarImportacao';

export default {
  name: 'FornecedorIndustriaList',
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
    };
  },
  components: {
    FornecedorIndustriaListDados,
    FornecedorIndustriaSidebarImportacao,
  },
  computed: {
    ...generateComputed('FORN', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
    ...mapGetters('importacao', {
      tiposImportacao: 'getTiposImportacao',
    }),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    tipoImportacao() {
      return this.tiposImportacao.find((el) => el.nome === 'importacao_forca_vendas');
    },
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    novo() {
      this.$router.push({ name: 'novoFornecedor' });
    },
    requestExportacao(cb) {
      const params = {
        filtro: this.$refs.tabelaFornecedor.getSearchQuery() || '',
      };
      exportacao.exportar(cb, 'fornecedor_industria', this, params);
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
