<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent>
    <v-form v-model="valid" lazy-validation ref="form">
      <v-card>
        <v-card-title class="headline" primary-title>
          {{ $t('label.classificacao') }}
        </v-card-title>

        <v-card-text>

          <v-text-field
            v-model="idExterno"
            :counter="50"
            maxlength="50"
            :rules="[rules.required]"
            :label="`${$t('label.codigo')} *`"
            required>
          </v-text-field>

          <v-text-field
            v-model="descricao"
            :counter="50"
            maxlength="50"
            :rules="[rules.required]"
            :label="`${$t('label.descricao')} *`"
            required>
          </v-text-field>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="cancelar">{{ $t('label.cancelar') }}</v-btn>
          <v-btn color="primary" text @click="salvar">{{ $t('label.salvar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'CadastrarClassificacao',
  props: {
    classificacao: {
      type: Object,
    },
  },
  data() {
    return {
      extensaoResources: this.$api.extensao(this.$resource),
      dialog: false,
      valid: true,
      idExterno: null,
      descricao: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    open() {
      this.dialog = true;
      if (this.classificacao) {
        this.descricao = this.classificacao.nomExtensao;
        this.idExterno = this.classificacao.idExterno;
      } else if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    cancelar() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    salvar() {
      if (!this.$refs.form.validate()) return;

      const extensao = {
        idExterno: this.idExterno,
        nomExtensao: this.descricao,
        mnemonico: 'CLASSIFICACAO',
      };

      if (this.classificacao) {
        extensao.id = this.classificacao.idExtensao;
        extensao.idNivelExtensao = this.classificacao.idNivelExtensao;
        this.alterar(extensao);
      } else {
        this.inserir(extensao);
      }
    },
    inserir(extensao) {
      this.extensaoResources.gravar(extensao)
        .then((response) => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.$emit('CadastrarClassificacao_salvo', response.body);
          this.cancelar();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    alterar(extensao) {
      const id = this.classificacao.idExtensao;
      this.extensaoResources.atualizar({ id }, extensao)
        .then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'));
          this.$emit('CadastrarClassificacao_atualizado', extensao);
          this.cancelar();
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
};
</script>
