<template>
  <spreadsheet
    style="width: 600px;"
    ref="spreadsheet"
    id="pontuacao-marca"
    :disabled-sort="true"
    v-if="planilhaPronta"
    :toolbar="true"
    :sheetsbar="false"
    :rows="rowsNumber"
    :data="marcas"
    :colunas="colunas"
    :estilos="estilos"
    :formulas="formulas"
    :selectRange="celulaAtiva"
    :tamanhoColunas="tamanhoColunas"
    :toolbar-list="toolbarList"
    :filter="filter"
    :frozenRows="1"
  />
</template>

<script>
import Spreadsheet from '../../../../produto/shared-components/spreadsheet/Spreadsheet';

export default {
  name: 'PontuacaoMarca',
  components: {
    Spreadsheet,
  },
  props: {
    marcas: {
      type: Array,
      default: () => ([]),
    },
    somenteLeitura: false,
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      planilhaPronta: false,
      rowsNumber: 50,
      filter: {
        ref: 'A1:C1',
        columns: [],
      },
      toolbarList: {
        home: ['filter', 'exportAs'], // define single tool
        insert: false,
        data: false, // disable tab
      },
      colunas: [
        { field: 'nome', title: this.$tc('label.marca', 1) },
        { field: 'pontuacao', title: this.$tc('label.pontuacao', 1) },
      ],
      estilos: [
        {
          range: 'A1:B1',
          metodos: {
            textAlign: 'center',
            bold: true,
            enable: false,
            background: 'rgb(44,130,190)',
            color: 'rgb(255,255,255)',
          },
        },
      ],
      formulas: [],
      datasource: [],
      tamanhoColunas: [
        { width: 250 },
        { width: 250 },
      ],
      celulaAtiva: 'A2',
    };
  },
  watch: {
    marcas(val) {
      if (val) {
        this.montarPlanilha();
      }
    },
  },
  methods: {
    preencherMarca() {
      const valores = {};
      this.$refs.spreadsheet.getDataSource()
        .forEach((item) => {
          valores[item.idMarca] = Number.isNaN(item.pontuacao) ? 0 : item.pontuacao;
        });
      this.marcas.forEach((marca) => {
        if (valores[marca.idMarca] || valores[marca.idMarca] === 0) {
          marca.pontuacao = valores[marca.idMarca];
        }
      });
    },
    bloquearColunas() {
      const estiloSomenteLeitura = {
        enable: false,
        color: 'rgb(0,0,0)',
        bold: true,
      };
      if (this.somenteLeitura && !this.alterarFinalizado) {
        this.estilos.push({
          range: `A2:B${this.rowsNumber}`,
          metodos: estiloSomenteLeitura,
        });
      } else {
        this.estilos.push({
          range: `A2:A${this.rowsNumber}`,
          metodos: estiloSomenteLeitura,
        });
      }
    },
    montarFormulas() {
      this.formulas.push({
        range: `B2:B${this.rowsNumber}`,
        metodos: {
          format: '#,##0.00',
          validation: {
            dataType: 'custom',
            from: 'AND(ISNUMBER(B2),LEN(B2)<14, B2 >= 0)',
            allowNulls: false,
            showButton: true,
            type: 'reject',
            messageTemplate: this.$t('message.numero_invalido'),
            titleTemplate: this.$tc('title.erro', 1),
          },
        },
      });
    },
    prencherFiltro() {
      this.filter.ref = `A1:C${this.rowsNumber}`;
    },
    montarPlanilha() {
      this.rowsNumber = (this.marcas.length || 50) + 1;
      this.bloquearColunas();
      this.montarFormulas();
      this.prencherFiltro();
      this.planilhaPronta = true;
    },
  },
  mounted() {
    this.montarPlanilha();
  },
};
</script>
>
