export default {
  data() {
    return {
      ajusteVerbaResource: this.$api.ajusteVerba(this.$resource),
      anoFiscalResource: this.$api.anoFiscal(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'tipo_de_ajuste',
            nomCampoId: 'tipo_de_ajuste',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.ajusteVerbaResource
                .listarTipoAjuste({ autocomplete }),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'tipo_de_verba',
            nomCampoId: 'tipo_de_verba',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.ajusteVerbaResource
                .listarTipoVerba({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'orcamento',
            nomCampoId: 'orcamento',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.ajusteVerbaResource
                .listarOrcamento({ autocomplete }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'periodo_orcamentario',
            nomCampoId: 'periodo_orcamentario',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              dependencia: 'periodo',
            },
            async: {
              fetchFunction: (autocomplete) => this.ajusteVerbaResource
                .listarPeriodoOrcamentario({ autocomplete }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              lista: [
                {
                  valor: 'EM_CADASTRO',
                  texto: this.$tc('label.em_cadastro', 1),
                },
                {
                  valor: 'AGUARDANDO_APROVACAO',
                  texto: this.$tc('label.aguardando_aprovacao', 1),
                },
                {
                  valor: 'APROVADO',
                  texto: this.$tc('label.aprovado', 1),
                },
                {
                  valor: 'REPROVADO',
                  texto: this.$tc('label.reprovado', 1),
                },
                {
                  valor: 'EM_ANALISE',
                  texto: this.$tc('label.em_analise', 1),
                },
                {
                  valor: 'CANCELADO',
                  texto: this.$tc('label.cancelado', 1),
                },
              ],
              textoItem: 'texto',
              valorItem: 'valor',
            },
          },
          {
            labelCampo: 'linha_de_investimento',
            nomCampoId: 'tipo_linha_orcamento',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.ajusteVerbaResource
                .listarLinhaInvestimento({ autocomplete }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'minhas_aprovacoes_pendentes',
            tipoCampo: 'BOOLEAN',
          },
        ],
      },
    };
  },
};
