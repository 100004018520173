<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title>
        <h2 class="headline mb-0">{{ $tc('title.acao_previa', 1) }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="acao-previa-autocomplete-divisao"
              name="acao-previa-autocomplete-divisao"
              :label="`${$tc('label.divisao', 1)} *`"
              v-model="acao.idDivisao"
              :items="divisoes"
              item-value="id"
              item-text="nome"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :disabled="somenteLeitura || divisoes.length === 1"
              @input="divisaoAlterada"
              clearable
              required
              :rules="[rules.required]"
              :placeholder="somenteLeitura ? null : $tc('message.digite_para_pesquisar', 1)"
              @click.native="buscarDivisoes">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="acao-previa-autocomplete-holding"
              name="acao-previa-autocomplete-holding"
              :label="`${$tc('label.holding', 1)} *`"
              v-model="acao.idHolding"
              :items="holdings"
              item-value="id"
              item-text="nomExtensao"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :disabled="somenteLeitura || holdings.length === 1 || !acao.idDivisao"
              @input="holdingAlterada"
              clearable
              required
              :rules="[rules.required]"
              :placeholder="somenteLeitura ? null : $tc('message.digite_para_pesquisar', 1)"
              @click.native="buscaHoldings">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="acao-previa-autocomplete-tipo-verba"
              name="acao-previa-autocomplete-tipo-verba"
              :label="`${$tc('label.tipo_verba', 1)} *`"
              v-model="acao.idTipoVerba"
              :items="tiposVerba"
              item-value="id"
              item-text="nome"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :disabled="somenteLeitura || tiposVerba.length === 1 || !acao.idHolding"
              @input="tipoVerbaAlterado"
              clearable
              required
              :rules="[rules.required]"
              :placeholder="somenteLeitura ? null : $tc('message.digite_para_pesquisar', 1)"
              @click.native="buscarTiposVerba">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="acao-previa-autocomplete-orcamento"
              name="acao-previa-autocomplete-orcamento"
              :label="`${$tc('label.orcamento', 1)} *`"
              v-model="acao.idOrcamento"
              :items="orcamentos"
              item-value="id"
              item-text="descricao"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :disabled="somenteLeitura || orcamentos.length === 1 || !acao.idTipoVerba"
              @input="orcamentoAlterado"
              clearable
              required
              :rules="[rules.required]"
              :placeholder="somenteLeitura ? null : $tc('message.digite_para_pesquisar', 1)"
              @click.native="buscarOrcamentos">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="acao-previa-autocomplete-periodo"
              name="acao-previa-autocomplete-periodo"
              :label="`${$tc('label.periodo_orcamentario', 1)} *`"
              v-model="acao.idPeriodoOrcamentario"
              :items="periodosOrcamentarios"
              item-value="id"
              item-text="descricao"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :disabled="somenteLeitura || periodosOrcamentarios.length === 1 || !acao.idOrcamento"
              @input="periodoOrcamentarioAlterado"
              clearable
              required
              :rules="[rules.required]"
              :placeholder="somenteLeitura ? null : $tc('message.digite_para_pesquisar', 1)"
              @click.native="buscarPeriodosOrcamentarios">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="acao-previa-linha-investimento"
              name="acao-previa-linha-investimento"
              :label="`${$tc('label.linha_de_investimento', 1)} *`"
              v-model="acao.idLinhaInvestimento"
              :items="linhasInvestimento"
              item-value="id"
              item-text="descricao"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :disabled="somenteLeitura || linhasInvestimento.length === 1 || !acao.idPeriodoOrcamentario"
              @input="linhaInvestimentoAlterada"
              clearable
              required
              :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              id="acao-previa-tipo-pagamento"
              name="acao-previa-tipo-pagamento"
              :label="`${$tc('label.tipo_pagamento',2)} *`"
              v-model="acao.idTipoPagamento"
              :items="tiposPagamentoPermitidos"
              item-value="id"
              item-text="nome"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :disabled="somenteLeitura || tiposPagamentoPermitidos.length === 1"
              clearable
              required
              :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <input-money
              id="acao-previa-saldo-disponivel"
              name="acao-previa-saldo-disponivel"
              :label="$t('label.saldo_disponivel')"
              disabled
              v-model="saldoDisponivel">
            </input-money>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <input-money
              id="acao-previa-valor-solicitacao"
              name="acao-previa-valor-solicitacao"
              :label="$t('label.total_solicitacao')"
              disabled
              v-model="totalValorSolicitado">
            </input-money>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-show="!somenteLeitura">
            <input-money
              id="acao-previa-saldo-ajustado"
              name="acao-previa-saldo-ajustado"
              :label="$t('label.saldo_ajustado')"
              disabled
              v-bind:class="{
                'AcaoPreviaForm__valor-negativo': (saldoAjustado < 0)
              }"
              v-model="saldoAjustado">
            </input-money>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              id="acao-previa-status"
              name="acao-previa-status"
              v-model="statusAcao"
              :label="`${$tc('label.status', 1)}`"
              :disabled="true"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" v-if="exibirJustificativa">
            <v-text-field
              v-model="acao.justificativa"
              :label="`${$tc('label.justificativa', 1)}`"
              :disabled="true"/>
          </v-col>
        </v-row>
        <v-row v-show="!somenteLeitura">
          <v-col cols="12">
            <v-row justify="end">
              <v-btn
                @click="atualizaListaFornecedores"
                class="mr-3"
                color="accent"
                :disabled="!acao.idPeriodoOrcamentario">
                {{$tc('label.atualizar', 1)}}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-container mt-5 fluid>
        <v-row>
          <v-col cols="12">
            <v-data-table
              dense
              :headers="headers"
              :items="fornecedores"
              :options.sync="pagination"
              :server-items-length="totalPage"
              :no-data-text="$t('label.tabela_sem_conteudo')"
              :footer-props="{
                itemsPerPageOptions: [10, 25, 50],
              }">
              <template v-slot:item.valor="{ item }">
                <input-money
                  dense
                  v-model="item.valor"
                  :disabled="somenteLeitura"
                  :rules="[value =>  value >= 0 || $t('message.valor_negativo'), rules.maximoPadrao]"
                  @input="atualizaTotalValorSolicitado"/>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <acao-previa-form-acoes-fluxo
          ref="componenteFluxo"
          :permite-salvar="permiteSalvar"
          :acao-edicao="acao"
          :acao-salvar="salvar"
          :edicao="edicao">
          <v-btn slot="acoes-padrao" @click="cancelar" class="mr-3">
            {{ $t('label.voltar') }}
          </v-btn>
          <v-btn slot="acoes-padrao" @click="salvarRecarregar()"
            class="mr-3"
            dark
            color="primary"
            v-show="exibeSalvar"
            :disabled="!permiteSalvar">
            {{ $t('label.salvar') }}
          </v-btn>
        </acao-previa-form-acoes-fluxo>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmAlterarHoldingDialog"
      :message="$t('message.deseja_alterar_nome_fantasia')"
      @agree="confirmarAlteracaoHolding"
      @disagree="cancelarAlteracaoHolding">
    </confirm>

    <confirm
      ref="cancelarDialog" :title="$t('title.atencao')"
      :message="$t('message.tem_certeza_cancelar')"
      @agree="voltar">
    </confirm>
  </v-form>
</template>
<script>
import { buscaAutocomplete, triggerSelecao } from '../../produto/common/functions/autocomplete-utils';
import { buscarHoldings } from '../../produto/common/resources/planejamento/planejamento-acao-cadastro';
import { generateComputed } from '../../produto/common/functions/roles-computed-generator';
import InputMoney from '../../produto/shared-components/inputs/InputMoney';
import Confirm from '../../produto/shared-components/vuetify/dialog/Confirm';
import AcaoConfiguracao from '../../produto/spa/planejamento-acao/AcaoConfiguracao';
import AcaoPreviaFormAcoesFluxo from './AcaoPreviaFormAcoesFluxo';

export default {
  components: {
    AcaoPreviaFormAcoesFluxo,
    InputMoney,
    Confirm,
  },
  data() {
    return {
      acaoPreviaResource: this.$api.acaoPrevia(this.$resource),
      tipoBeneficioResources: this.$api.tipoBeneficio(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      acao: {},
      fornecedores: [],
      divisoes: [],
      holdings: [],
      tiposVerba: [],
      orcamentos: [],
      periodosOrcamentarios: [],
      linhasInvestimento: [],
      tiposPagamento: [],
      statusAcao: this.$tc('status_entidade.novo', 1),
      pagination: {},
      totalPage: 0,
      configuracaoAcao: null,
      saldo: 0,
      saldoAjustado: 0,
      saldoDisponivel: 0,
      totalValorSolicitado: 0,
      idHoldingPreviamenteSelecionada: null,

      headers: [
        {
          text: this.$tc('label.codigo', 1), value: 'idExterno', width: '10%', sortable: false,
        },
        {
          text: this.$tc('label.razao_social', 1), value: 'desRazaoSocial', width: '35%', sortable: false,
        },
        {
          text: this.$tc('label.nome_fantasia', 1), value: 'desNomeFantasia', width: '25%', sortable: false,
        },
        {
          text: this.$tc('label.cpf_cnpj', 1), value: 'desCnpj', width: '20%', sortable: false,
        },
        {
          text: this.$tc('label.valor', 1), value: 'valor', width: '10%', sortable: false,
        },
      ],

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        maximoPadrao: (value) => value <= 99999999.99 || this.$t('message.valor_padrao_maximo'),
      },
    };
  },
  computed: {
    ...generateComputed('ACAO_PREVIA', [
      'canAccessPage',
      'canEdit',
    ]),
    tiposPagamentoPermitidos() {
      if (!this.carregouConfiguracao) {
        return this.tiposPagamento;
      }
      const { pagamentos } = this.configuracaoAcao;
      return this.tiposPagamento
        .filter((p) => pagamentos.indexOf(p.id) >= 0)
        .map((p) => ({ nome: this.$tc(`tipos_pagamentos.${p.codTipo}`, 1), id: p.id }));
    },
    permiteSalvar() {
      return (
        !this.acao.status
        || this.acao.status === 'EM_CADASTRO'
      );
    },
    exibeSalvar() {
      return !this.somenteLeitura;
    },
    carregouConfiguracao() {
      return this.configuracaoAcao !== null;
    },
    exibirJustificativa() {
      return this.acao.justificativa
        && (this.acao.status === 'EM_CADASTRO'
        || this.acao.status === 'EM_ANALISE'
        || this.acao.status === 'REPROVADO');
    },
  },
  props: {
    edicao: Boolean,
    novo: Boolean,
    somenteLeitura: Boolean,
  },
  watch: {
    pagination: {
      handler() {
        this.atualizaListaFornecedores();
      },
      deep: true,
    },
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    carregarAcao(idAcao) {
      this.acaoPreviaResource.buscarAcaoPrevia({ idAcao })
        .then((res) => {
          this.acao = { ...res.data };
          this.statusAcao = this.$tc(`status_entidade.${this.acao.status.toLowerCase()}`, 1);
          this.idHoldingPreviamenteSelecionada = this.acao.idHolding;
          return this.acao.idAcaoTipo;
        }).then((idAcaoTipo) => {
          Promise.all([
            this.carregarConfiguracao(idAcaoTipo),
            this.buscarDivisoes(),
            this.buscaHoldings(),
            this.buscarTiposVerba(),
            this.buscarOrcamentos(),
            this.buscarPeriodosOrcamentarios(),
            this.buscarLinhaInvestimentos(),
            this.atualizaListaFornecedores(),
          ]).then(() => {
            setTimeout(() => this.$refs.componenteFluxo.carregaInformacoesFluxo(idAcao));
          });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarDivisoes(autocomplete) {
      if (typeof autocomplete !== 'string') {
        autocomplete = null;
      }
      this.planejamentoAcaoResource.buscarDivisoesPorVisao({ autocomplete })
        .then((res) => {
          this.divisoes = res.data;
          if (autocomplete === null && this.divisoes.length === 1) {
            const unicaDivisao = this.divisoes[0];
            this.acao.idDivisao = unicaDivisao.id;
            this.buscaHoldings();
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    divisaoAlterada() {
      this.acao.idHolding = null;
      this.acao.idTipoVerba = null;
      this.acao.idOrcamento = null;
      this.acao.idPeriodoOrcamentario = null;
      this.resetLinhaInvestimento();
      if (this.acao.idDivisao) {
        this.buscaHoldings();
      }
    },
    buscaHoldings(autocomplete = null) {
      if (typeof autocomplete !== 'string') {
        autocomplete = null;
      }
      const parametros = {
        autocomplete,
        idDivisao: this.acao.idDivisao,
      };
      buscarHoldings(parametros, this.$resource)
        .then((res) => {
          this.holdings = res.data;
          if (autocomplete === null && this.holdings.length === 1) {
            const unicaHolding = this.holdings[0];
            this.acao.idHolding = unicaHolding.id;
            this.buscarTiposVerba();
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    holdingAlterada() {
      if (this.idHoldingPreviamenteSelecionada && this.fornecedores.length) {
        this.confirmarSeAlteraHolding();
      } else {
        this.confirmarAlteracaoHolding();
      }
    },
    confirmarSeAlteraHolding() {
      this.$refs.confirmAlterarHoldingDialog.open();
    },
    confirmarAlteracaoHolding() {
      this.idHoldingPreviamenteSelecionada = this.acao.idHolding;
      this.acao.idTipoVerba = null;
      this.acao.idOrcamento = null;
      this.acao.idPeriodoOrcamentario = null;
      this.fornecedores = [];
      this.resetLinhaInvestimento();
      if (this.acao.idHolding) {
        this.buscarTiposVerba();
      }
    },
    cancelarAlteracaoHolding() {
      this.acao.idHolding = this.idHoldingPreviamenteSelecionada;
      this.$forceUpdate();
    },
    buscarTiposVerba(autocomplete) {
      if (typeof autocomplete !== 'string') {
        autocomplete = null;
      }
      const params = {
        autocomplete,
        id_holding: this.acao.idHolding,
      };
      this.acaoPreviaResource.buscarTiposVerba(params)
        .then((r) => {
          this.tiposVerba = r.data;
          if (autocomplete === null && this.tiposVerba.length === 1) {
            const unicaTipoVerba = this.tiposVerba[0];
            this.acao.idTipoVerba = unicaTipoVerba.id;
            this.buscarOrcamentos();
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    tipoVerbaAlterado() {
      this.acao.idOrcamento = null;
      this.acao.idPeriodoOrcamentario = null;
      this.resetLinhaInvestimento();
      if (this.acao.idTipoVerba) {
        this.buscarOrcamentos();
      }
    },
    buscarOrcamentos(autocomplete) {
      if (typeof autocomplete !== 'string') {
        autocomplete = null;
      }
      const params = {
        autocomplete,
        id_holding: this.acao.idHolding,
        id_tipo_verba: this.acao.idTipoVerba,
      };

      this.acaoPreviaResource.buscarOrcamentos(params)
        .then((res) => {
          this.orcamentos = res.data;
          if (autocomplete === null && this.orcamentos.length === 1) {
            const unicoOrcamento = this.orcamentos[0];
            this.acao.idOrcamento = unicoOrcamento.id;
            this.buscarPeriodosOrcamentarios();
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    orcamentoAlterado() {
      this.acao.idPeriodoOrcamentario = null;
      this.resetLinhaInvestimento();
      if (this.acao.idOrcamento) {
        this.buscarPeriodosOrcamentarios();
      }
    },
    buscarPeriodosOrcamentarios(autocomplete) {
      if (typeof autocomplete !== 'string') {
        autocomplete = null;
      }
      const params = {
        autocomplete,
        id_holding: this.acao.idHolding,
        id_orcamento: this.acao.idOrcamento,
      };

      this.acaoPreviaResource.buscarPeriodosOrcamentarios(params)
        .then((response) => {
          this.periodosOrcamentarios = response.data;
          if (autocomplete === null && this.periodosOrcamentarios.length === 1) {
            const unicoPeriodo = this.periodosOrcamentarios[0];
            this.acao.idPeriodoOrcamentario = unicoPeriodo.id;
            this.buscarLinhaInvestimentos();
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    periodoOrcamentarioAlterado() {
      this.resetLinhaInvestimento();
      this.valorPlanejado = 0;
      this.acao.valor = 0;
      if (this.acao.idPeriodoOrcamentario) {
        this.buscarLinhaInvestimentos();
      }
    },
    buscarLinhaInvestimentos(autocomplete) {
      if (typeof autocomplete !== 'string') {
        autocomplete = null;
      }
      const params = {
        autocomplete,
        id_holding: this.acao.idHolding,
        id_tipo_verba: this.acao.idTipoVerbaDestino,
        id_orcamento: this.acao.idOrcamento,
        id_periodo_orcamentario: this.acao.idPeriodoOrcamentario,
      };

      this.acaoPreviaResource.buscarLinhasInvestimento(params)
        .then((res) => {
          this.linhasInvestimento = res.data;
          if (autocomplete === null && this.linhasInvestimento.length === 1) {
            const unicaLinhaInvestimento = this.linhasInvestimento[0];
            this.acao.idLinhaInvestimento = unicaLinhaInvestimento.id;
            this.saldo = unicaLinhaInvestimento.saldo;
            this.saldoDisponivel = unicaLinhaInvestimento.saldo;
          } else if (this.edicao) {
            const linhaInvestimento = this.linhasInvestimento.filter((l) => l.id === this.acao.idLinhaInvestimento)[0];
            this.saldo = linhaInvestimento.saldo;
            this.saldoDisponivel = linhaInvestimento.saldo;
          }
          this.atualizaTotalValorSolicitado();
        }, (err) => {
          this.$error(this, err);
        });
    },
    linhaInvestimentoAlterada() {
      if (!this.acao.idLinhaInvestimento) {
        this.totalValorSolicitado = 0;
        this.saldoDisponivel = 0;
      } else {
        this.saldoDisponivel = this.saldo;
      }
    },
    resetLinhaInvestimento() {
      this.acao.idLinhaInvestimento = null;
      this.saldoDisponivel = 0;
    },
    atualizaTotalValorSolicitado() {
      this.totalValorSolicitado = this.fornecedores.map((f) => f.valor).reduce((a, b) => a + b, 0);
      this.saldoAjustado = this.saldoDisponivel - this.totalValorSolicitado;
    },
    atualizaListaFornecedores() {
      if (this.acao.idHolding) {
        if (!this.$refs.form.validate()) {
          return;
        }
        const params = {
          id_holding: this.acao.idHolding,
        };

        if (this.acao.id) {
          params.id_acao_trade_previa = this.acao.id;
        }

        this.acaoPreviaResource.buscarFornecedores(params)
          .then((res) => {
            this.fornecedores = res.data;
            this.totalPage = res.data.length;
            this.atualizaTotalValorSolicitado();
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    cancelar() {
      if (this.somenteLeitura) {
        this.voltar();
        return;
      }

      this.$refs.cancelarDialog.open();
    },
    voltar() {
      this.$router.push({
        name: 'acao-previa',
      });
    },
    formatarObjeto() {
      const acao = { ...this.acao };
      acao.totalValorSolicitacao = this.totalValorSolicitado;
      acao.fornecedores = this.fornecedores.filter((f) => f.valor > 0);

      return acao;
    },
    salvarRecarregar() {
      this.salvar()
        .then((idAcao) => {
          this.alternaRota(idAcao);
        })
        .catch((err) => {
          if (err) {
            this.$toast(err.data.error);
          }
        });
    },
    salvar() {
      if (!this.$refs.form.validate()) {
        return Promise.reject();
      }
      if (!this.acaoPreviaValida()) {
        return Promise.reject();
      }
      const acao = this.formatarObjeto();

      if (!this.edicao) {
        return this.salvarNovo(acao);
      }
      return this.atualizar(acao);
    },
    acaoPreviaValida() {
      if (this.saldoAjustado < 0) {
        this.$toast(this.$t('message.saldo_insuficiente'));
        return false;
      }
      if (this.totalValorSolicitado <= 0) {
        this.$toast(this.$t('errors.informe_valor_pagamento'));
        return false;
      }
      return true;
    },
    salvarNovo(acao) {
      return this.acaoPreviaResource.salvarAcao(acao)
        .then((res) => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          const idAcao = res.data;
          return idAcao;
        });
    },
    atualizar(acao) {
      return this.acaoPreviaResource.atualizarAcao(acao)
        .then((res) => {
          this.$toast(this.$t('message.acao_atualizada'));
          const idAcao = res.data;
          return idAcao;
        });
    },
    alternaRota(idAcao) {
      const routeEdicao = 'editarAcaoPrevia';

      let { name } = this.$router.currentRoute;
      name = name === routeEdicao
        ? name.concat('_') : routeEdicao;

      this.$router.replace({
        name,
        params: { idAcao, from: 'detalharAcaoPrevia' },
      });
    },
    carregarSeUsuarioPossuirAcesso(idAcao) {
      this.acaoPreviaResource.verificarSeUsuarioPossuiAcesso({ idAcao })
        .then((res) => {
          if (res.data) {
            this.carregarAcao(idAcao);
          } else {
            this.$router.push({ name: 'inicio' });
            this.$toast('Acesso negado');
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    buscarTiposPagamento() {
      this.tipoBeneficioResources
        .listarAtivos()
        .then((response) => {
          this.tiposPagamento = response.data;
          if (this.tiposPagamentoPermitidos.length === 1) {
            const unicoTipoPagamento = this.tiposPagamentoPermitidos[0];
            this.acao.idTipoPagamento = unicoTipoPagamento.id;
          }
        });
    },
    carregarConfiguracao() {
      const { id } = this.acao;

      const promise = id !== undefined
        ? this.acaoPreviaResource.buscarConfigSnapshot({ idAcao: id })
        : this.acaoPreviaResource.buscarConfigVigente();

      return promise
        .then((res) => {
          this.configuracaoAcao = new AcaoConfiguracao(res.data);
          if (this.novo) {
            this.acao.idConfiguracao = this.configuracaoAcao.idConfiguracao;
            this.acao.idAcaoTipo = this.configuracaoAcao.idAcaoTipo;
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    this.carregarConfiguracao()
      .then(() => {
        this.buscarTiposPagamento();
      });
    this.buscarDivisoes();
    if (!this.novo) {
      const { idAcao, from } = this.$route.params;
      if (!from) {
        this.carregarSeUsuarioPossuirAcesso(idAcao);
      } else {
        this.carregarAcao(idAcao);
      }
    }
  },
};
</script>
<style>
.AcaoPreviaForm__valor-negativo,
.AcaoPreviaForm__valor-negativo input.v-money {
  color: #ff0000ba;
}
</style>
