<template>
  <parametrizacao-periodo-passo
    tem-proximo
    tem-anterior
    :somente-leitura="somenteLeitura"
    @PARAMETRIZACAO_PERIODO_PASSO_VOLTAR="voltarPasso"
    @PARAMETRIZACAO_PERIODO_PASSO_PROXIMO="salvarEProximoPasso"
    @PARAMETRIZACAO_PERIODO_PASSO_SALVAR_RASCUNHO="salvarRascunho">

    <v-container fluid>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ $t('title.percentual_rebate') }}</v-toolbar-title>
      </v-toolbar>

      <rebates-cards
        ref="rebatesCards"
        :id-periodo="idPeriodo"
        :rebates="passo6.rebates"
        :somente-leitura="somenteLeitura"
        :alterar-finalizado="alterarFinalizado"
        @RebatesCards_configAlteradas="configuracaoAlterada">
      </rebates-cards>
    </v-container>

  </parametrizacao-periodo-passo>
</template>

<script>
import ParametrizacaoPeriodoPasso from '../ParametrizacaoPeriodoPasso';
import ParametrizacaoPeriodo from '../ParametrizacaoPeriodo';
import Passo6 from './Passo6';
import Passo2 from '../passo2/Passo2';
import rebatesCards from './RebatesCards';

export default {
  name: 'ParametrizacaoPeriodoPasso6',
  components: {
    ParametrizacaoPeriodoPasso,
    rebatesCards,
  },
  props: {
    idPeriodo: {
      type: Number,
      default: null,
    },
    periodoConfiguracao: {
      type: ParametrizacaoPeriodo,
      default: null,
    },
    exibindo: false,
    somenteLeitura: false,
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parametrizacaoPeriodoPasso6: this.$api.parametrizacaoPeriodoPasso6(this.$resource),
      parametrizacaoPeriodoPasso2: this.$api.parametrizacaoPeriodoPasso2(this.$resource),
      tipoInvestimentoResources: this.$api.tipoInvestimento(this.$resource),
      extensaoResources: this.$api.extensao(this.$resource),
      passo6: new Passo6(),
      carregado: false,
      rebates: [],
      tiposInvestimento: [],
      extensoesCluster: [],
    };
  },
  watch: {
    exibindo(val) {
      if (val && !this.carregado) {
        this.carregado = true;
        this.buscarPasso();
      }
    },
  },
  methods: {
    remover(rebate) {
      this.rebateTemp = rebate;
      this.$refs.confirmDialog.open();
    },
    configuracaoAlterada() {
      this.$emit('ParametrizacaoPeriodoPasso6_configuracaoAlterada', this.passo6.idsExtensoesRebate);
    },
    removerRebate() {
      this.passo6.removerRebate(this.rebateTemp);
    },
    buscarPasso() {
      if (!this.idPeriodo) return;
      const id = this.idPeriodo;
      this.parametrizacaoPeriodoPasso6.obterPasso6({ id })
        .then((response) => {
          this.passo6 = new Passo6(response.body);
        })
        .then(this.configuracaoAlterada)
        .catch((err) => {
          this.$error(this, err);
        });
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_PERIODO_PASSO6_VOLTAR');
    },
    salvarEProximoPasso() {
      if (this.somenteLeitura && !this.alterarFinalizado) {
        this.proximoPasso();
      } else {
        if (!this.passo6.estaConfigurado) {
          this.$toast(this.$t('message.rebate_nao_configurado'));
          return;
        }

        let podeContinuar = false;
        const idsMarcas = [];

        this.passo6.rebates.forEach((rbt) => {
          rbt.rebatesConfiguracao.forEach((rebatesConfig) => {
            if (!podeContinuar) {
              podeContinuar = (rebatesConfig.idsExtensoesProduto === undefined
                || rebatesConfig.idsExtensoesProduto.length <= 0);

              rebatesConfig.idsExtensoesProduto.forEach((id) => {
                idsMarcas.push(id);
              });
            }
          });
        });

        let marcaFaltando = '';

        if (!podeContinuar) {
          podeContinuar = true;

          this.parametrizacaoPeriodoPasso2.obterPasso2({ id: this.idPeriodo })
            .then((response) => {
              const passo2 = new Passo2(response.body);

              passo2.grupoCulturas.forEach((grupo) => {
                grupo.marcaFortes.forEach((marcaForte) => {
                  if (podeContinuar) {
                    const id = idsMarcas.find((idMarca) => idMarca === marcaForte.idMarca);

                    if (id === undefined) {
                      marcaFaltando = marcaForte.nome;
                      podeContinuar = false;
                    }
                  }
                });
              });

              if (!podeContinuar) {
                this.$toast(`${this.$t('message.rebate_nao_configurado_marca')} ${marcaFaltando}.`);
                return;
              }

              this.parametrizacaoPeriodoPasso6.salvarPasso6({ id: this.idPeriodo }, this.passo6)
                .then(() => {
                  this.buscarPasso();
                  this.proximoPasso();
                })
                .catch((err) => {
                  this.$error(this, err);
                });
            })
            .catch((err) => {
              this.$error(this, err);
            });
        } else {
          this.parametrizacaoPeriodoPasso6.salvarPasso6({ id: this.idPeriodo }, this.passo6)
            .then(() => {
              this.buscarPasso();
              this.proximoPasso();
            })
            .catch((err) => {
              this.$error(this, err);
            });
        }
      }
    },
    proximoPasso() {
      this.$emit('PARAMETRIZACAO_PERIODO_PASSO6_PROXIMO', this.passo6);
    },
    salvarRascunho() {
      this.parametrizacaoPeriodoPasso6.salvarRascunhoPasso6({ id: this.idPeriodo }, this.passo6)
        .then(() => {
          this.$toast(this.$t('message.rascunho_salvo'));
          this.buscarPasso();
          this.$emit('PARAMETRIZACAO_PERIODO_PASSO6_RASCUNHO');
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
};
</script>
