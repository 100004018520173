<template>
  <v-data-iterator
    :items="grupoCulturas"
    disable-pagination
    hide-default-footer>
    <template v-slot:default="props">
      <v-row>
        <v-col
          v-for="grupo in props.items"
          :key="grupo.grupoCultura"
          cols="12"
          sm="6"
          md="4"
          lg="3">
          <marca-forte
            :grupo="grupo"
            :somente-leitura="somenteLeitura"
            :marcas="marcas"
            :alterar-finalizado="alterarFinalizado">
          </marca-forte>
        </v-col>
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
import MarcaForte from './MarcaForte';

export default {
  name: 'MarcasFortes',
  components: {
    MarcaForte,
  },
  props: {
    grupoCulturas: {
      type: Array,
      default: () => ([]),
    },
    marcas: {
      type: Array,
      default: () => ([]),
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
    somenteLeitura: false,
  },
};
</script>
