<template>
  <div class="OrcamentoList" style="padding-bottom: 60px;">
    <orcamento-list-filtros
      ref="filtros"
      @OrcamentoListFiltros__AplicaFiltros="aplicaFiltros">
    </orcamento-list-filtros>
    <orcamento-list-dados ref="dados">
    </orcamento-list-dados>

    <v-speed-dial
      v-model="fab"
      bottom
      right
      fixed
      direction="left"
      v-show="canEdit && algumTipoVerba"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="primary"
          fab
        >
          <v-icon v-if="!fab" id="add">add</v-icon>
          <v-icon v-if="fab" id="close">close</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="accent"
        @click="novoOrcamento(tipoVerba)"
        v-for="(tipoVerba, index) in tiposVerbas"
        :key="index"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
            >{{tipoVerba.icone}}</v-icon>
          </template>
          <span>{{tipoVerba.nome}}</span>
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';

import OrcamentoListFiltros from './OrcamentoListFiltros';
import OrcamentoListDados from './OrcamentoListDados';

export default {
  name: 'Orcamento',
  data() {
    return {
      orcamentoCfgResources: this.$api.orcamentoConfiguracao(this.$resource),
      fab: null,
      tiposVerbas: null,
    };
  },
  components: {
    OrcamentoListFiltros,
    OrcamentoListDados,
  },
  computed: {
    ...generateComputed('ORC', [
      'canAccessPage',
      'canEdit',
      'canGet',
      'canPost',
      'canSearch',
      'canHistory',
      'canCopy',
      'canCancel',
      'canStartWorkflow',
    ]),
    canAccessPageAjuste() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ORC_AJUSTE').length;
    },
    canAccessPageTransferir() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ORC_TRANSFERENCIA').length;
    },
    canAccessPageRateio() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ORC_RATEIO').length;
    },
    algumTipoVerba() {
      return this.tiposVerbas && this.tiposVerbas.length > 0;
    },
    ...mapGetters('metadados', [
      'getOrcamentoMetadado',
    ]),
  },
  methods: {
    aplicaFiltros(filtros = {}) {
      this.$refs.dados.aplicaFiltros(filtros);
    },
    aplicaTiposVerbas(tiposVerbas) {
      this.tiposVerbas = tiposVerbas;
    },
    novoOrcamento(tipoVerba) {
      const idTipoVerba = tipoVerba.id;
      this.$router.push({ name: 'novoOrcamento', params: { idTipoVerba } });
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    buscaTiposVerbasAtivos() {
      this.orcamentoCfgResources.buscarConfigTiposVerba()
        .then((res) => {
          this.tiposVerbas = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
      return;
    }
    this.buscaTiposVerbasAtivos();
  },
};
</script>
