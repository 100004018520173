<template>
  <v-card>
    <v-card-title><h4>{{ grupo.grupoCultura }}</h4></v-card-title>
    <v-divider></v-divider>
    <v-row v-if="!somenteLeitura || alterarFinalizado">
      <v-col cols="10">
        <v-autocomplete
          multiple
          class="mx-2"
          clearable
          return-object
          v-model="marcasSelecionadas"
          item-value="idMarca"
          item-text="nome"
          :label="$t('label.marca')"
          :items="marcasSelecao">
        </v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text icon @click="adicionar" v-on="on" class="my-3 px-0">
              <v-icon>done</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.adicionar') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-list dense>
      <v-list-item v-for="marcaForte in grupo.marcaFortes" :key="`${grupo.idGrupoCultura}-${marcaForte.idMarca}`">
        <v-list-item-icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon @click="remover(marcaForte)" v-on="on" v-show="!somenteLeitura || marcaForte.adicionadoAgora">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.remover') }}</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-content>{{ marcaForte.nome }}</v-list-item-content>
        <v-list-item-content class="align-end">
          <input-decimal
            :negative="false"
            dense
            hide-details
            single-line
            :suffix="$tc('label.litro_ha', 1)"
            :suffix-class="'MarcaForte__litroha-label-width'"
            v-model="marcaForte.litroHa"
            :disabled="somenteLeitura && !marcaForte.adicionadoAgora"
            class="v-text-field py-0 my-0">
          </input-decimal>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { getNumber } from '../../../../produto/common/functions/helpers';
import InputDecimal from '../../../../produto/shared-components/inputs/InputDecimal';

export default {
  name: 'MarcaForte',
  components: {
    InputDecimal,
  },
  props: {
    grupo: {
      type: Object,
      default: () => ({}),
    },
    marcas: {
      type: Array,
      default: () => ([]),
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
    somenteLeitura: false,
  },
  data() {
    return {
      marcasSelecionadas: [],
    };
  },
  computed: {
    marcasSelecao() {
      return this.marcas.filter((marca) => !this.grupo.marcaFortes.find((m) => m.idMarca === marca.idMarca));
    },
  },
  methods: {
    getNumber,
    remover(marcaForte) {
      this.grupo.marcaFortes
        .splice(
          this.grupo
            .marcaFortes
            .findIndex((m) => m.idMarca === marcaForte.idMarca), 1,
        );
    },
    adicionar() {
      this.marcasSelecionadas.forEach((m) => {
        this.grupo.marcaFortes.push({ ...m, adicionadoAgora: true });
      });
      this.marcasSelecionadas = [];
    },
  },
};
</script>

<style>
  .MarcaForte__litroha-label-width {
    width: 98px;
  }
</style>
