var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"tabela_dados","headers":_vm.headers,"items":_vm.listaDados,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
    itemsPerPageOptions: [10, 25, 50],
  }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"elevation-0",attrs:{"color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$tc('title.relatorio', 1)))]),_c('v-spacer'),(_vm.listaDados.length)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.requestExportacao}},[_c('v-icon',[_vm._v("get_app")])],1):_vm._e()],1)]},proxy:true},{key:"item.periodo_planejamento",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.periodo_planejamento)+" ")]}},{key:"item.estrutura_venda_diretoria",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.estrutura_venda_diretoria)+" ")]}},{key:"item.estrutura_venda_regional",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.estrutura_venda_regional)+" ")]}},{key:"item.estrutura_venda_rtv",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.estrutura_venda_rtv)+" ")]}},{key:"item.nome_fantasia",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome_fantasia)+" ")]}},{key:"item.tipo_dpm",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipo_dpm)+" ")]}},{key:"item.classificacao_dpm",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.classificacao_dpm)+" ")]}},{key:"item.ano",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.ano)+" ")]}},{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$tc(("status_entidade." + (item.status.toLowerCase())), 1))+" ")]}},{key:"item.passo_fluxo",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.passo_fluxo)+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }