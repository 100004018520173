var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canAccessPage)?_c('div',{staticClass:"CRUDGenerico__Wrapper"},[_c('v-card',{staticStyle:{"min-height":"86vh"}},[_c('v-card-title',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$tc('title.parametrizacao_acao', 1)))])]),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},on:{"input":_vm.filtrar},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.lista,"items-per-page":10,"no-data-text":_vm.$t('label.tabela_sem_conteudo')},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":'more_vert_' + item.id,"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('label.editar', 1)))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.validaAlteracaoStatus(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("block")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('label.ativar_inativa', 1)))])],1)],1)],1)]}},{key:"item.ind_ativo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.indAtivo ? _vm.$t('label.sim') : _vm.$t('label.nao'))+" ")]}}],null,false,1535054175)})],1),_c('v-card-actions',[_c('v-row',[_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"v-btn__fab",attrs:{"fab":"","bottom":"","right":"","fixed":"","color":"primary"},nativeOn:{"click":function($event){return _vm.openNewForm($event)}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,3941665535)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.novo_tipo')))])])],1)],1),_c('confirm',{ref:"dialogAtivarInativar",attrs:{"persistent":true,"message":_vm.$t('message.tem_certeza_alterar_status', {param: _vm.acaoTipo.nome}),"title":_vm.$t('label.atencao'),"agree-label":_vm.$t('label.sim'),"disagree-label":_vm.$t('label.nao')},on:{"agree":function($event){return _vm.confirmarAlteracaoStatus()}}}),_c('confirm',{ref:"impossibilitadoAtivarInativarDialog",attrs:{"max-width":"500","agree-label":_vm.$t('label.ok'),"hide-disagree":true,"persistent":true}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }