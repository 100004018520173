<template>
  <parametrizacao-periodo-passo
    tem-proximo
    tem-anterior
    :alterar-finalizado="alterarFinalizado"
    :somente-leitura="somenteLeitura"
    @PARAMETRIZACAO_PERIODO_PASSO_VOLTAR="voltarPasso"
    @PARAMETRIZACAO_PERIODO_PASSO_PROXIMO="abrirDialogConfirmarEProximo"
    @PARAMETRIZACAO_PERIODO_PASSO_SALVAR_RASCUNHO="salvarRascunho">
    <v-container fluid>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ `${$tc('title.marca_forte', 2)} | ${$tc('title.hectar_tratado', 2)}` }}</v-toolbar-title>
      </v-toolbar>
      <marcas-fortes
        class="my-3"
        :grupo-culturas="passo2.grupoCulturas"
        :marcas="passo2.marcasSelecao"
        :somente-leitura="somenteLeitura"
        :alterar-finalizado="alterarFinalizado">
      </marcas-fortes>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ $tc('title.pontuacao_unitaria', 2) }}</v-toolbar-title>
      </v-toolbar>
      <pontuacao-marca
        class="my-3"
        ref="pontuacaoMarca"
        v-if="passo2.marcas.length > 0"
        :marcas="passo2.marcas"
        :somente-leitura="somenteLeitura"
        :alterar-finalizado="alterarFinalizado">
      </pontuacao-marca>
    </v-container>
    <confirm
      ref="confirmProximoDialog"
      :message="$t('message.confirmar_salvar_ao_avancar')"
      @agree="salvarEProximoPasso">
    </confirm>
  </parametrizacao-periodo-passo>
</template>

<script>
import ParametrizacaoPeriodoPasso from '../ParametrizacaoPeriodoPasso';
import PeriodoConfiguracao from '../ParametrizacaoPeriodo';
import Passo2 from './Passo2';
import InputDecimal from '../../../../produto/shared-components/inputs/InputDecimal';
import MarcasFortes from './MarcasFortes';
import PontuacaoMarca from './PontuacaoMarca';
import Confirm from '../../../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  name: 'ParametrizacaoPeriodoPasso2',
  components: {
    PontuacaoMarca,
    MarcasFortes,
    InputDecimal,
    ParametrizacaoPeriodoPasso,
    Confirm,
  },
  props: {
    idPeriodo: {
      type: Number,
      default: null,
    },
    periodoConfiguracao: {
      type: PeriodoConfiguracao,
      default: null,
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
    exibindo: false,
    somenteLeitura: false,
  },
  data() {
    return {
      parametrizacaoPeriodoPasso2: this.$api.parametrizacaoPeriodoPasso2(this.$resource),
      passo2: new Passo2(),
      carregado: false,
    };
  },
  watch: {
    exibindo(val) {
      if (val && !this.carregado) {
        this.buscarPasso();
      }
    },
  },
  methods: {
    buscarPasso() {
      if (!this.idPeriodo) return;
      const id = this.idPeriodo;
      this.parametrizacaoPeriodoPasso2.obterPasso2({ id })
        .then((response) => {
          this.passo2 = new Passo2(response.body);

          this.passo2.grupoCulturas.forEach((grupo) => {
            grupo.marcaFortes.forEach((marcaForte) => {
              marcaForte.adicionadoAgora = false;
            });
          });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    recarregarEntidade() {
      this.passo2.marcas = [];
      this.buscarPasso();
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_PERIODO_PASSO2_VOLTAR');
    },
    abrirDialogConfirmarEProximo() {
      let temMarcaAdicionadaAgora = false;

      this.passo2.grupoCulturas.forEach((grupo) => {
        grupo.marcaFortes.forEach((marcaForte) => {
          if (marcaForte.adicionadoAgora) {
            temMarcaAdicionadaAgora = true;
          }
        });
      });

      if (temMarcaAdicionadaAgora) {
        this.$refs.confirmProximoDialog.open();
      } else {
        this.salvarEProximoPasso();
      }
    },
    salvarEProximoPasso() {
      if (this.somenteLeitura && !this.alterarFinalizado) {
        this.proximoPasso();
      } else {
        this.$refs.pontuacaoMarca.preencherMarca();
        const id = this.idPeriodo;
        this.parametrizacaoPeriodoPasso2.salvarPasso2({ id }, this.passo2)
          .then(() => {
            this.recarregarEntidade();
            this.proximoPasso();
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    proximoPasso() {
      this.$emit('PARAMETRIZACAO_PERIODO_PASSO2_PROXIMO', this.passo2);
    },
    salvarRascunho() {
      this.$refs.pontuacaoMarca.preencherMarca();
      const id = this.idPeriodo;
      this.parametrizacaoPeriodoPasso2.salvarRascunhoPasso2({ id }, this.passo2)
        .then(() => {
          this.recarregarEntidade();
          this.$toast(this.$t('message.rascunho_salvo'));
          this.$emit('PARAMETRIZACAO_PERIODO_PASSO2_RASCUNHO');
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
};
</script>
