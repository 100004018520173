<template>
  <div>
    <v-row v-if="!somenteLeitura">
      <v-col cols="4">
        <v-autocomplete
          multiple
          class="mx-2"
          clearable
          return-object
          v-model="classificacoesSelecionadas"
          item-value="id"
          item-text="nomExtensao"
          :label="$t('label.classificacao')"
          :items="classificacoesSelecao">
        </v-autocomplete>
      </v-col>
      <v-btn color="secondary" class="my-auto" @click="abrirNovaExtensao">{{ $t('label.novo') }}</v-btn>
      <v-btn color="primary" class="ml-3 my-auto" @click="adicionar">{{ $t('label.adicionar') }}</v-btn>
    </v-row>
    <v-row v-if="passo4.classificacoes.length">
      <v-icon size="42" color="primary">arrow_upward</v-icon>
      <v-col cols="11" md="4" v-if="!somenteLeitura || alterarFinalizado">
        <draggable :list="passo4.classificacoes" :disabled="somenteLeitura">
          <transition-group>
            <div class="list-group-item" v-for="(classificacao, indice) in passo4.classificacoes" :key="`classificacao-${indice}`"
              :style="!somenteLeitura ? 'cursor: grab' : ''">
              <v-btn text icon @click="abrirEdicaoExtensao(classificacao)" v-if="!somenteLeitura">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn text icon @click="remover(classificacao)" v-if="!somenteLeitura">
                <v-icon>delete</v-icon>
              </v-btn>
              {{`${classificacao.idExterno} - ${classificacao.nomExtensao}`}}
            </div>
          </transition-group>
        </draggable>
      </v-col>
    </v-row>
    <cadastrar-classificacao
      ref="cadastrarClassificacao"
      :classificacao="extensaoEdicao"
      @CadastrarClassificacao_salvo="incluirClassificacao"
      @CadastrarClassificacao_atualizado="atualizarClassificacao">
    </cadastrar-classificacao>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import CadastrarClassificacao from './CadastrarClassificacao';
import Passo4 from './Passo4';
import Classificacao from './Classificacao';

export default {
  name: 'Ranking',
  components: {
    draggable,
    CadastrarClassificacao,
  },
  props: {
    passo4: {
      type: Passo4,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parametrizacaoPeriodoPasso4: this.$api.parametrizacaoPeriodoPasso4(this.$resource),
      classificacoes: [],
      classificacoesSelecionadas: [],
      extensaoEdicao: null,
    };
  },
  computed: {
    classificacoesSelecao() {
      return this.classificacoes.filter((classificacao) => !this.passo4.classificacoes.find((c) => c.idExtensao === classificacao.id));
    },
  },
  methods: {
    remover(classificacao) {
      this.$emit('Ranking__removerClassificacao', classificacao);
    },
    abrirNovaExtensao() {
      this.extensaoEdicao = null;
      setTimeout(() => {
        this.$refs.cadastrarClassificacao.open();
      }, 2E2);
    },
    abrirEdicaoExtensao(extensao) {
      this.extensaoEdicao = extensao;
      setTimeout(() => {
        this.$refs.cadastrarClassificacao.open();
      }, 2E2);
    },
    buscarClassificacao() {
      this.parametrizacaoPeriodoPasso4.buscarExtensao({ mnemonico: 'CLASSIFICACAO' })
        .then((response) => {
          this.classificacoes = response.body;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    incluirClassificacao(classificacao) {
      this.classificacoesSelecionadas.push(classificacao);
      this.classificacoes.push(classificacao);
    },
    atualizarClassificacao(classificacao) {
      let classif = this.classificacoes.find((c) => c.id === classificacao.id);
      classif.nomExtensao = classificacao.nomExtensao;
      classif.idExterno = classificacao.idExterno;
      classif = this.passo4.classificacoes.find((c) => c.idExtensao === classificacao.id);
      classif.nomExtensao = classificacao.nomExtensao;
      classif.idExterno = classificacao.idExterno;
    },
    adicionar() {
      this.classificacoesSelecionadas.forEach((c) => {
        this.passo4.classificacoes.push(new Classificacao({
          idExtensao: c.id,
          idExterno: c.idExterno,
          idNivelExtensao: c.idNivelExtensao,
          nomExtensao: c.nomExtensao,
        }));
      });

      this.classificacoesSelecionadas = [];
    },
  },
  mounted() {
    this.buscarClassificacao();
  },
};
</script>

<style scoped>

</style>
