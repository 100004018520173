export default {
  name: 'contaCorrenteRedistribuicaoMixin',
  props: {
    edicao: Boolean,
  },
  data() {
    return {
      solicitaRedistribuicaoContas: false,
      solicitaRedistribuicaoContasValor: false,
      forcaRedistribuicaoContas: false,
      forcaRedistribuicaoContasValor: false,
      jaEntrouPassoConta: false,
    };
  },
  methods: {
    informaAlteracaoContas() {
      this.solicitaRedistribuicaoContas = true;
    },
    informaAlteracaoContasValor() {
      this.solicitaRedistribuicaoContasValor = true;
    },
    validarAntesIrProximo(evento) {
      if ((!this.solicitaRedistribuicaoContas
        && !this.solicitaRedistribuicaoContasValor)
        || (!this.edicao && !this.jaEntrouPassoConta)) {
        return;
      }

      if (this.solicitaRedistribuicaoContasValor) {
        this.solicitaRedistribuicaoContasValor = false;
        this.forcaRedistribuicaoContasValor = true;
      }

      if (this.solicitaRedistribuicaoContas) {
        evento.desabilitarExecucaoDoneAcaoForm();
        this.$emit('ContaCorrenteRedistribuicaoMixin__abrirRedistribuicaoContas',
          () => {
            this.forcaRedistribuicaoContas = true;
            this.solicitaRedistribuicaoContas = false;
            evento.executarDone();
          });
      }
    },
    abrePasso() {
      this.jaEntrouPassoConta = true;
      if (this.forcaRedistribuicaoContas) {
        this.redistribuirContas();
        this.alterarValor();
        this.solicitaRedistribuicaoContas = false;
        this.forcaRedistribuicaoContas = false;
        this.solicitaRedistribuicaoContasValor = false;
        this.forcaRedistribuicaoContasValor = false;
      } else if (this.forcaRedistribuicaoContasValor) {
        this.alterarValor();
        this.solicitaRedistribuicaoContasValor = false;
        this.forcaRedistribuicaoContasValor = false;
      }
      setTimeout(() => this.abrePassoContaCorrente());
    },
  },
};
