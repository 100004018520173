<template>
  <v-card>
    <v-card-title>
      {{$t('title.parametrizacao_periodo')}}
    </v-card-title>
    <v-row justify="end">
      <v-col cols="12" sm="6" md="3" class="py-0">
        <v-text-field
          v-model="searchQuery"
          append-icon="search"
          :label="$t('label.pesquisar')"
          single-line
          clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="registros"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item.acoes="{ item }">
        <v-tooltip bottom v-if="canAccessCRUD && periodoEmCadastro(item)">
          <template v-slot:activator="{ on }">
            <v-btn icon class="mx-0" v-on="on" @click.stop="edit(item)">
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.editar') }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="canAccessCRUD && periodoFinalizado(item)">
          <template v-slot:activator="{ on }">
            <v-btn icon class="mx-0" v-on="on" @click.stop="alterar(item)">
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.alterar_parametrizacao') }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!canAccessCRUD">
          <template v-slot:activator="{ on }">
            <v-btn icon class="mx-0" v-on="on" @click.stop="visualizar(item)">
              <v-icon>search</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.visualizar') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.ano_fiscal="{ item }">
        {{ item.anoFiscal }}
      </template>
      <template v-slot:item.contrato_tipo="{ item }">
        {{ item.contratoTipo }}
      </template>
      <template v-slot:item.status="{ item }">
        {{ retornaStatusFormatado(item.status) }}
      </template>
      <template v-slot:item.dta_criacao="{ item }">
        {{ item.dtaCriacao }}
      </template>
      <template v-slot:item.usuario_criacao="{ item }">
        {{ item.usuarioCriacao }}
      </template>
      <template v-slot:item.dta_alteracao="{ item }">
        {{ item.dtaAlteracao }}
      </template>
      <template v-slot:item.usuario_alteracao="{ item }">
        {{ item.usuarioAlteracao }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { generateComputed } from '../../../produto/common/functions/roles-computed-generator';

export default {
  name: 'ParametrizacaoPeriodoTabela',
  data() {
    return {
      parametrizacaoPeriodo: this.$api.parametrizacaoPeriodo(this.$resource),
      esperar: false,
      headers: [
        {
          text: this.$tc('label.acoes', 2), value: 'acoes', sortable: false, width: '150px',
        },
        { text: this.$tc('label.periodo', 1), value: 'ano_fiscal', sortable: true },
        { text: this.$tc('label.tipo_contrato', 1), value: 'contrato_tipo', sortable: true },
        { text: this.$tc('label.status', 1), value: 'status', sortable: true },
        { text: this.$tc('label.data_criacao', 1), value: 'dta_criacao', sortable: true },
        { text: this.$tc('label.usuario_criacao', 1), value: 'usuario_criacao', sortable: true },
        { text: this.$tc('label.data_alteracao', 1), value: 'dta_alteracao', sortable: true },
        { text: this.$tc('label.usuario_alteracao', 1), value: 'usuario_alteracao', sortable: true },
      ],
      pagination: {},
      totalPage: 0,
      searchQuery: '',
      registros: [],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    searchQuery() {
      this.pagination.page = 1;
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  computed: {
    ...generateComputed('PARAM PERIODO', [
      'canAccessCRUD',
    ]),
    statusEmCadastro() {
      return 'EM_CADASTRO';
    },
    statusFinalizado() {
      return 'FINALIZADO';
    },
  },
  methods: {
    buscar() {
      const params = {
        filtro: this.searchQuery,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.parametrizacaoPeriodo.listar(params).then((response) => {
        this.registros = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },
    periodoEmCadastro(periodo) {
      return periodo.status === this.statusEmCadastro;
    },
    periodoFinalizado(periodo) {
      return periodo.status === this.statusFinalizado;
    },
    edit(row) {
      this.$emit('ParametrizacaoPeriodoTabela_EDITAR', row);
    },
    alterar(row) {
      this.$emit('ParametrizacaoPeriodoTabela_ALTERAR', row);
    },
    visualizar(row) {
      this.$emit('ParametrizacaoPeriodoTabela_VISUALIZAR', row);
    },
    retornaStatusFormatado(status) {
      switch (status) {
        case 'EM_CADASTRO':
          return 'Em cadastro';
        case 'FINALIZADO':
          return 'Finalizado';
        default:
          return '';
      }
    },
  },
};
</script>
