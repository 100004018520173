import moment from 'moment';

const filtrarConta = (conta, dtaInicioMoment, dtaFimMoment) => {
  const dataInicioConta = moment(conta.dataInicio);
  const dataFimConta = moment(conta.dataFim);
  return dataInicioConta.isBetween(dtaInicioMoment, dtaFimMoment)
    || dataFimConta.isBetween(dtaInicioMoment, dtaFimMoment)
    || (dataInicioConta.isSameOrBefore(dtaInicioMoment)
      && dataFimConta.isSameOrAfter(dtaFimMoment));
};

export default class Reserva {
  constructor(dtaInicio, dtaFim, valor, ordem, periodoApuracao, descricao = '', contas = [], reservaValor = false) {
    this._ordem = ordem;
    this._dtaInicio = dtaInicio;
    this._dtaInicioMoment = moment(this._dtaInicio);
    this._dtaFim = dtaFim;
    this._dtaFimMoment = moment(this._dtaFim);
    this._valor = valor;
    this._descricao = descricao;
    this._contas = contas;

    if (reservaValor) {
      this._valorReservado = valor;
    } else {
      this._valorReservado = this._contas.map((c) => c.valor).reduce((a, b) => a + b, 0);
    }

    this._pendente = this._valor - this._valorReservado;
    this._periodoApuracao = periodoApuracao;
  }

  filtrarEPreencherConta(contas) {
    this._contas = contas
      .filter((c) => filtrarConta(c, this._dtaInicioMoment, this._dtaFimMoment))
      .map((c) => ({ ...c }));
  }

  contaComValorNaReserva(idConta) {
    const conta = this._contas.find((c) => c.idContaCorrente === idConta);
    return conta && conta.valor > 0;
  }

  ajustarPendente() {
    this._valorReservado = this._contas.map((c) => c.valor).reduce((a, b) => a + b, 0);
    this._pendente = this._valor - this._valorReservado;
  }

  valorDistribuidoUnicaConta() {
    return this._contas.filter((value) => value.valor > 0);
  }

  get valorDistribuido() {
    return this._contas.map((c) => c.valor || 0).reduce((p, a) => p + a, 0);
  }

  get pendente() {
    return this._pendente;
  }

  set pendente(pendente) {
    this._pendente = pendente;
  }

  get dtaInicio() {
    return this._dtaInicio;
  }

  get dtaInicioFormatada() {
    return this._dtaInicioMoment.format('DD/MM/YYYY');
  }

  set dtaInicioFormatada(dtaInicioFormatada) {
    this._dtaInicioMoment = moment(dtaInicioFormatada, 'DD/MM/YYYY');
    this._dtaInicio = this._dtaInicioMoment.format('YYYY-MM-DD');
  }

  set dtaInicio(value) {
    this._dtaInicio = value;
    this._dtaInicioMoment = moment(this._dtaInicio);
  }

  get dtaFim() {
    return this._dtaFim;
  }

  get dtaFimFormatada() {
    return this._dtaFimMoment.format('DD/MM/YYYY');
  }

  set dtaFimFormatada(dtaFimFormatada) {
    this._dtaFimMoment = moment(dtaFimFormatada, 'DD/MM/YYYY');
    this._dtaFim = this._dtaFimMoment.format('YYYY-MM-DD');
  }

  set dtaFim(value) {
    this._dtaFim = value;
    this._dtaFimMoment = moment(this._dtaFim);
  }

  get valor() {
    return this._valor;
  }

  set valor(value) {
    this._valor = value;
    this._pendente = this._valor;
  }

  get descricao() {
    return this._descricao;
  }

  set descricao(value) {
    this._descricao = value;
  }

  get contas() {
    return this._contas;
  }

  set contas(value) {
    this._contas = value;
  }

  get valorReservado() {
    return this._valorReservado;
  }

  set valorReservado(value) {
    this._valorReservado = value;
  }

  get periodoApuracao() {
    return this._periodoApuracao;
  }

  set periodoApuracao(value) {
    this._periodoApuracao = value;
  }

  get idsContasCorrente() {
    return this._contas.map((e) => (e.idContaCorrente));
  }

  toJSON() {
    const contas = this._contas
      .map((c) => ({
        idContaCorrente: c.idContaCorrente,
        valor: c.valor,
      }));
    return {
      ordem: this._ordem,
      dtaInicio: this._dtaInicio,
      dtaFim: this._dtaFim,
      valor: this._valor,
      periodoApuracao: this._periodoApuracao,
      contas,
    };
  }
}
