import getBasePath from '../../../produto/common/functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'parametrizacao-periodo');

const grupoActions = {
  obterPasso7: { method: 'GET', url: `${basePath}/passo7/{id}` },
  salvarRascunhoPasso7: { method: 'PUT', url: `${basePath}/passo7/{id}/rascunho` },
  salvarPasso7: { method: 'PUT', url: `${basePath}/passo7/{id}` },
  salvarElegivelPasso7: { method: 'PUT', url: `${basePath}/passo7/elegivel/{id}` },
  buscarConcorrentesPorPeriodo: { method: 'GET', url: `${basePath}/passo7/vinculo/{idPeriodo}` },
};

export default (resource) => resource(`${basePath}`, {}, grupoActions);
