var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"Container_Tabela",attrs:{"fluid":"","px-0":"","py-2":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"Barra_Pesquisa mx-4",attrs:{"append-icon":"search","label":("" + (_vm.$tc('label.pesquisar', 1))),"single-line":"","hide-details":"","clearable":""},on:{"input":_vm.filtrar},model:{value:(_vm.pesquisaTabela),callback:function ($$v) {_vm.pesquisaTabela=$$v},expression:"pesquisaTabela"}})],1),_c('v-container',{staticClass:"Container_Tabela py-3",attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"id":"tabela_notas","headers":_vm.isSellIn || _vm.isDevolucao ? _vm.headersSellIn : _vm.headersPedido,"items":_vm.listaNotasDetalhado,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
                itemsPerPageOptions: [10, 25, 50],
              }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([(_vm.isSellIn || _vm.isDevolucao)?{key:"item.data_emissao",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.data_emissao || item.dataEmissao)+" ")]}}:null,(_vm.isPedidoCarteira || _vm.isPedidoRemessa)?{key:"item.data_remessa",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.data_remessa || item.dataRemessa)+" ")]}}:null,(_vm.isSellIn || _vm.isDevolucao)?{key:"item.numero_nota_fiscal",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.numero_nota_fiscal || item.numeroNotaFiscal)+" ")]}}:null,(_vm.isPedidoCarteira || _vm.isPedidoRemessa)?{key:"item.numero_pedido",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.numero_pedido || item.numeroPedido)+" ")]}}:null,{key:"item.cod_produto",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.cod_produto || item.codProduto)+" ")]}},{key:"item.nom_produto",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome_produto || item.nomeProduto || item.nom_produto)+" ")]}},(_vm.isPedidoCarteira || _vm.isPedidoRemessa)?{key:"item.cultura",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.cultura)+" ")]}}:null,{key:"item.qtd_item",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.qtd_item || item.qtdItem)+" ")]}},{key:"item.valor_bruto",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.valor_bruto ? _vm.getMoney(item.valor_bruto) : _vm.getMoney(item.valorBruto))+" ")]}},{key:"item.valor_liquido",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.valor_liquido ? _vm.getMoney(item.valor_liquido) : _vm.getMoney(item.valorLiquido))+" ")]}}],null,true)})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }