import { toColumnName } from '../../common/functions/helpers';

export default {
  data() {
    return {
      colunasFixas: ['descricao_linha', 'tipo_investimento'],
      tamanhoColunas: [],
      estiloTitulo: {
        textAlign: 'center',
        bold: true,
        enable: false,
        background: this.$vuetify.theme.currentTheme.primary,
        color: 'rgb(255,255,255)',
      },
      estiloSomenteLeitura: { enable: false, color: 'rgb(0,0,0)' },
      rowsNumber: this.linhasInvestimento.length > 50
        ? this.linhasInvestimento.length + 15 : 50,
      estilos: [],
      formulas: [],
      celulaAtiva: 'A2',
      primeiraLinha: 2,

      abasListaValores: [],
      flagValues: ['SIM', 'NAO'],
    };
  },
  methods: {
    aplicaTamanhoColunas() {
      this.tamanhoColunas = this.colunas.map((coluna) => {
        if (this.colunasFixas.indexOf(coluna.field) >= 0) {
          return { width: 250 };
        }
        if (coluna.title.length >= 20) {
          return { width: 250 };
        }
        return { width: 150 };
      });
    },
    aplicaEstiloCabecalho() {
      const letraInicial = toColumnName(0);
      const letraFinal = toColumnName(this.colunas.length - 1);
      this.estilos.push({ range: `${letraInicial}1:${letraFinal}1`, metodos: this.estiloTitulo });
    },
    aplicaEstiloDesabilitados() {
      if (!this.somenteLeitura) {
        const letras = this.colunas.map((coluna, indice) => {
          if (coluna.disabled) {
            return toColumnName(indice);
          }
          return null;
        }).filter((c) => c != null);

        letras.forEach((letra) => {
          this.estilos.push({
            range: `${letra}${this.primeiraLinha}:${letra}${this.rowsNumber}`,
            metodos: this.estiloSomenteLeitura,
          });
        });
      } else {
        const letraInicial = toColumnName(0);
        const letraFinal = toColumnName(this.colunas.length - 1);
        const numeroFinal = this.linhasInvestimento.length + 1;
        this.estilos.push({
          range: `${letraInicial}${this.primeiraLinha}:${letraFinal}${numeroFinal}`,
          metodos: this.estiloSomenteLeitura,
        });
      }
    },
    capturaColunasPorTipo(tipo) {
      return this.colunas.map((coluna, indice) => {
        const { type, attributes, field } = coluna;
        if (type === tipo) {
          return {
            letra: toColumnName(indice),
            field,
            ...(attributes),
          };
        }
        return null;
      }).filter((c) => c != null);
    },
    aplicaEstiloCampoPercentual() {
      // if (this.somenteLeitura) return;

      const colunas = this.capturaColunasPorTipo('PERCENTUAL');
      colunas.forEach((coluna) => {
        const { letra } = coluna;
        const max = coluna.max || 100,
          min = coluna.min || 0;

        this.estilos.push({
          range: `${letra}${this.primeiraLinha}:${letra}${this.rowsNumber}`,
          metodos: { format: '#,0.00' },
        });
        if (!this.somenteLeitura) {
          this.formulas.push({
            range: `${letra}${this.primeiraLinha}:${letra}${this.rowsNumber}`,
            metodos: {
              validation: {
                allowNulls: true,
                comparerType: 'between',
                from: min,
                to: max,
                dataType: 'number',
                type: 'reject',
                messageTemplate:
                  this.$t('message.intervalo_numerico_percentual', { min, max }),
                titleTemplate: this.$tc('title.erro', 1),
              },
            },
          });
        }
      });
    },
    aplicaEstiloCampoNumerico() {
      // if (this.somenteLeitura) return;

      const colunas = [
        ...this.capturaColunasPorTipo('NUMERICO'),
        ...this.capturaColunasPorTipo('DINHEIRO'),
      ];
      colunas.forEach((coluna) => {
        const { letra } = coluna;
        const max = coluna.max || 9999999999.99,
          min = coluna.min || -9999999999.99;

        this.estilos.push({
          range: `${letra}${this.primeiraLinha}:${letra}${this.rowsNumber}`,
          metodos: { format: '#,0.00' },
        });
        if (!this.somenteLeitura) {
          this.formulas.push({
            range: `${letra}${this.primeiraLinha}:${letra}${this.rowsNumber}`,
            metodos: {
              validation: {
                allowNulls: true,
                comparerType: 'between',
                from: min,
                to: max,
                dataType: 'number',
                type: 'reject',
                messageTemplate:
                  this.$t('message.intervalo_numerico', { min, max }),
                titleTemplate: this.$tc('title.erro', 1),
              },
            },
          });
        }
      });
    },
    aplicaEstiloCampoBoolean() {
      if (this.somenteLeitura) return;
      const colunas = this.capturaColunasPorTipo('BOOLEAN'),
        ultimaColuna = toColumnName(this.flagValues.length - 1),
        nomeLista = 'FlagValues';

      if (colunas.length === 0) return;

      const abas = this.abasListaValores.map((aba) => aba.nomeLista);
      if (abas.indexOf(nomeLista) < 0) {
        this.abasListaValores.push({
          nomeLista,
          valores: this.flagValues,
        });
      }

      const valores = this.flagValues.join(', ');
      colunas.forEach((coluna) => {
        const { letra } = coluna;
        this.formulas.push({
          range: `${letra}${this.primeiraLinha}:${letra}${this.rowsNumber}`,
          metodos: {
            validation: {
              allowNulls: true,
              from: `${nomeLista}!$A$1:$${ultimaColuna}$1`,
              dataType: 'list',
              type: 'reject',
              messageTemplate:
                this.$t('message.valor_lista_invalido', { valores }),
              titleTemplate: this.$tc('title.erro', 1),
            },
          },
        });
      }, this);
    },
    aplicaEstiloCampoLista() {
      if (this.somenteLeitura) return;
      const colunas = this.capturaColunasPorTipo('LISTA');
      if (colunas.length === 0) return;

      colunas.forEach((coluna) => {
        const { letra, field, lista } = coluna;
        const ultimaColuna = toColumnName(lista.length - 1),
          nomeLista = `${field}Values`,
          valores = [...lista];

        const abas = this.abasListaValores.map((aba) => aba.nomeLista);
        if (abas.indexOf(nomeLista) < 0) {
          this.abasListaValores.push({ nomeLista, valores });
        }

        this.formulas.push({
          range: `${letra}${this.primeiraLinha}:${letra}${this.rowsNumber}`,
          metodos: {
            validation: {
              allowNulls: true,
              from: `${nomeLista}!$A$1:$${ultimaColuna}$1`,
              dataType: 'list',
              type: 'reject',
              messageTemplate:
                this.$t('message.valor_lista_invalido', { valores }),
              titleTemplate: this.$tc('title.erro', 1),
            },
          },
        });
      }, this);
    },
    aplicaFormulaSomatoria() {
      const aplicouPeriodo = this.colunasPeriodos.length > 0;
      if (!aplicouPeriodo) return;

      const letraInicial = toColumnName(this.colunasCampos.length),
        letraFinal = toColumnName(this.colunas.length - 2),
        letraRange = toColumnName(this.colunas.length - 1),
        linhaInicial = 2;

      for (let i = linhaInicial; i <= this.rowsNumber; i += 1) {
        const soma = `SUM(${letraInicial}${i}:${letraFinal}${i})`;
        this.formulas.push({
          range: `${letraRange}${i}`,
          metodos: { formula: `IF(${soma}<>0,${soma},"")` },
        });
      }
    },
    aplicaEstilosCamposDinamicos() {
      this.aplicaEstiloCampoNumerico();
      this.aplicaEstiloCampoPercentual();
      this.aplicaEstiloCampoBoolean();
      this.aplicaEstiloCampoLista();
    },
    aplicaEstiloUltimaLinha() {
      const letraInicial = toColumnName(0),
        letraFinal = toColumnName(this.colunas.length - 1),
        numeroLinha = this.rowsNumber;

      this.estilos.push({
        range: `${letraInicial}${numeroLinha}:${letraFinal}${numeroLinha}`,
        metodos: this.estiloSomenteLeitura,
      });
    },
    aplicaEstiloColunas() {
      this.estilos = [];
      this.formulas = [];
      this.aplicaTamanhoColunas();
      this.aplicaEstiloCabecalho();
      this.aplicaEstiloDesabilitados();
      this.aplicaEstilosCamposDinamicos();
      // this.aplicaEstiloUltimaLinha();
      this.aplicaFormulaSomatoria();
    },
  },
};
