import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'extensao');

const extensaoActions = {
  ...genericActions(basePath),
  listarAtivos: { method: 'GET', url: `${basePath}?nome={nome}&indAtivo=true&colunaOrdenacao=nom_extensao&idNivelExtensao={idNivelExtensao}{&idsNotIn*}{&idsExternosObrigatorios*}{&idsNiveisExtensao*}{&idsDependencias*}` },
  listarModalidadesAtivas: { method: 'GET', url: `${basePath}/modalidades?nome={nome}&indAtivo=true&colunaOrdenacao=nom_extensao&idNivelExtensao={idNivelExtensao}{&idsNotIn*}{&idsExternosObrigatorios*}` },
  listarQuantidadeMaiorAtivos: { method: 'GET', url: `${basePath}?nome={nome}&indAtivo=true&colunaOrdenacao=nom_extensao&idNivelExtensao={idNivelExtensao}{&idsNotIn*}{&idsExternosObrigatorios*}` },
  listarPorUsuario: { method: 'GET', url: `${basePath}?tamanhoPagina=10&numeroPagina=1&nome={nome}&indAtivo=true&idNivelExtensao={idNivelExtensao}&usuarioLogado=true&colunaOrdenacao=nom_extensao` },
  listarAutocomplete: { method: 'GET', url: `${basePath}?autocomplete={autocomplete}&indAtivo=true&idNivelExtensao={idNivelExtensao}&colunaOrdenacao=nom_extensao` },
  obter: { method: 'GET', url: `${basePath}/{id}` },
  listarAtivosPorRepresentante: { method: 'GET', url: `${basePath}/por-representante?&nome={nome}&indAtivo=true&colunaOrdenacao=nom_extensao` },
  buscarExtensoesRelacionadas: { method: 'GET', url: `${basePath}/extensao-relacionada?nome={nome}&indAtivo=true&idNivelExtensao={idNivelExtensao}&colunaOrdenacao=nom_extensao` },
  atualizarAutoRelacionamento: { method: 'PUT', url: `${basePath}/auto-relacionamento` },
  gravarAutoRelacionamento: { method: 'POST', url: `${basePath}/auto-relacionamento` },
  listarNiveisExtensao: { method: 'GET', url: `${basePath}/nivel-extensao?{&idsNiveisExtensao*}` },
  listarDetalhado: { method: 'GET', url: `${basePath}/detalhado?tamanhoPagina={size}&numeroPagina={page}{&idsNiveisExtensao*}` },
  listarNiveisExtensaoEntidade: { method: 'GET', url: `${basePath}/nivel-extensao/{entidade}` },
  selecao: { method: 'GET', url: `${basePath}/selecao?{&idsDependencias*}` },
  selecaoPlanilha: { method: 'GET', url: `${basePath}/selecao/planilha?` },
  selecaoMnemonico: { method: 'GET', url: `${basePath}/selecao/mnemonico/{mnemonico}` },
};

export default (resource) => resource(`${basePath}`, {}, extensaoActions);
