import getBasePath from '../../produto/common/functions/api-resource';
import resourceBuilder from '../../produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('workflow', 'execucao/acao-previa');

const workflowApuracaoAcaoActions = {
  solicitante: { method: 'GET', url: `${basePath}/solicitante/{idAcaoPrevia}` },
  iniciaFluxo: { method: 'POST', url: `${basePath}/{idAcaoPrevia}` },
  aprovador: { method: 'GET', url: `${basePath}/{idAcaoPrevia}/passos/aprovador` },
  reprovar: { method: 'PUT', url: `${basePath}/{idAcaoPrevia}/passos/reprovar` },
  aprovarPasso: { method: 'PUT', url: `${basePath}/{idAcaoPrevia}/passos/aprovar` },
  enviarAnalise: { method: 'PUT', url: `${basePath}/{idAcaoPrevia}/passos/analise` },
  statusPasso: { method: 'GET', url: `${basePath}/{idAcaoPrevia}/passos/status` },
  existeFluxoCadastrado: { method: 'GET', url: `${basePath}/{idAcaoPrevia}/fluxo-cadastrado` },
  cancelar: { method: 'PUT', url: `${basePath}/{idAcaoPrevia}/cancelar` },
  status: { method: 'GET', url: `${basePath}/{idAcaoPrevia}/status` },
};
export default (resource) => resource(`${basePath}`, {}, workflowApuracaoAcaoActions);

export const pendencias = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pendencias', parametros).doGet();
