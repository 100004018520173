<template>
  <div>
    <div class="mt-2 mb-2 title-float accent--text">
    {{$tc('label.crescimento_grupo_cultura',1)}}
    </div>
    <v-card>
    <v-data-table
        :headers="cabecalhoGrupoPrincipal"
        :items="gruposCulturaPrincipal"
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-footer>
        <template v-slot:item.anoAnterior="{ item }">
        {{getNumber(item.anoAnterior)}}
        </template>
        <template v-slot:item.anoAtual="{ item }">
        {{getNumber(item.anoAtual)}}
        </template>
        <template v-slot:item.crescimento="{ item }">
        {{getPercent(item.crescimento)}}
        </template>
    </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { getPercent, getNumber } from '../../../../produto/common/functions/helpers';

export default {
  props: {
    anoAtualPeriodo: {
      type: Number,
      required: true,
    },
    anoAnteriorPeriodo: {
      type: Number,
      required: true,
    },
    gruposCulturaPrincipal: Array,
  },
  data() {
    return {
      cabecalhoGrupoPrincipal: [
        {
          text: this.$tc('label.segmento', 1),
          value: 'segmento',
          align: 'start',
        },
        {
          text: this.anoAnteriorPeriodo.toString(),
          value: 'anoAnterior',
          align: 'end',
        },
        {
          text: this.anoAtualPeriodo.toString(),
          value: 'anoAtual',
          align: 'end',
        },
        {
          text: this.$t('label.crescimento'),
          value: 'crescimento',
          align: 'end',
        },
      ],
    };
  },
  methods: {
    getPercent,
    getNumber,
  },
};
</script>
