<template>
  <transition name="slide-fade"
    mode="out-in"
    v-if="carregouMetadados">
    <router-view></router-view>
  </transition>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getContratoMetadado',
      'getClienteMetadado',
      'getDivisaoMetadado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canAccessPage() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('REL_CONTRATO')).length;
    },
    carregouMetadados() {
      const acaoMetadados = this.getAcaoMetadado;
      const contratoMetadados = this.getContratoMetadado;
      const clienteMetadados = this.getClienteMetadado;
      const divisaoMetadados = this.getDivisaoMetadado;

      if (contratoMetadados != null
        && acaoMetadados != null
        && clienteMetadados != null
        && divisaoMetadados != null) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setContratoMetadado',
      'setAcaoMetadado',
      'setClienteMetadado',
      'setDivisaoMetadado',
    ]),
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
  },
  beforeMount() {
    this.setClienteMetadado({
      resource: this.metadadoResource.listarCliente,
    });
    this.setDivisaoMetadado({
      resource: this.metadadoResource.listarDivisao,
    });
    this.setContratoMetadado({
      resource: this.metadadoResource.definicaoContrato,
    });
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
