import getBasePath from '../../../produto/common/functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'dpm');

const actions = {
  buscarNovo: { method: 'GET', url: `${basePath}/plano-metas` },
  isClassificacaoValida: { method: 'GET', url: `${basePath}/plano-metas/is-classificacao-valida/{idDpm}` },
  buscarRating: { method: 'GET', url: `${basePath}/rating` },
};

export default (resource) => resource(`${basePath}`, {}, actions);
