<template>
  <parametrizacao-periodo-passo
    tem-anterior
    :somente-leitura="somenteLeitura"
    :alterar-finalizado="alterarFinalizado"
    @PARAMETRIZACAO_PERIODO_PASSO_VOLTAR="voltarPasso"
    @PARAMETRIZACAO_PERIODO_PASSO_FINALIZAR="salvarFinalizar"
    @PARAMETRIZACAO_PERIODO_PASSO_APLICAR_ALTERACOES="salvar"
    @PARAMETRIZACAO_PERIODO_PASSO_SALVAR_RASCUNHO="salvarRascunho">
    <v-form ref="formVinculo" lazy-validation>
      <v-container fluid>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ $tc('title.fornecedor', 2) }}</v-toolbar-title>
        </v-toolbar>
        <v-row class="mx-4" v-for="conc in passo7.concorrentes" :key="conc.id">
          <v-col cols="12" sm="12" style="height: 4em;">
            <v-switch
              :label="conc.nomConcorrente"
              color="primary"
              class="mt-2"
              v-model="conc.habilitado"
              :readonly="desabilitarSelecao(conc)"
              :disabled="somenteLeitura">
              <template v-slot:label>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ conc.nomConcorrente }}</span>
                  </template>
                  <div>
                    {{ $t('label.codigo') }}: {{ conc.idExterno }}<br />
                  </div>
                </v-tooltip>
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </parametrizacao-periodo-passo>
</template>

<script>
import ParametrizacaoPeriodoPasso from '../ParametrizacaoPeriodoPasso';
import ParametrizacaoPeriodo from '../ParametrizacaoPeriodo';
import Passo7 from './Passo7';
import ParametrizacaoBus from '../ParametrizacaoBus';

export default {
  name: 'ParametrizacaoPeriodoPasso7',
  components: {
    ParametrizacaoPeriodoPasso,
  },
  props: {
    idPeriodo: {
      type: Number,
      default: null,
    },
    periodoConfiguracao: {
      type: ParametrizacaoPeriodo,
      default: null,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
    exibindo: false,
  },
  data() {
    return {
      parametrizacaoPeriodoPasso7: this.$api.parametrizacaoPeriodoPasso7(this.$resource),
      parametrizacaoPeriodoResources: this.$api.parametrizacaoPeriodo(this.$resource),
      passo7: new Passo7(),
      carregado: false,
      IdsExtensaoElegivelFinalizada: [],
    };
  },
  watch: {
    exibindo(val) {
      if (val && !this.carregado) {
        this.buscarPasso();
      }
    },
  },
  created() {
    ParametrizacaoBus.$on('incluirValoresPeriodoFinalizado', (item) => {
      this.IdsExtensaoElegivelFinalizada.push(item);
    });
  },
  methods: {
    buscarConcorrentes() {
      if (!this.idPeriodo) return;
      const { idPeriodo } = this;
      this.parametrizacaoPeriodoPasso7.buscarConcorrentesPorPeriodo({ idPeriodo })
        .then((response) => {
          this.passo7.concorrentes = [...response.data];
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarPasso() {
      const id = this.idPeriodo;
      this.parametrizacaoPeriodoPasso7.obterPasso7({ id })
        .then((response) => {
          this.passo7 = new Passo7(response.body);
        })
        .then(this.buscarConcorrentes)
        .catch((err) => {
          this.$error(this, err);
        });
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_PERIODO_PASSO7_VOLTAR');
    },
    salvarFinalizar() {
      const { concorrentes } = this.passo7;
      this.passo7.preencherListaIdsConcorrentesVinculados(concorrentes);

      this.parametrizacaoPeriodoPasso7.salvarPasso7({ id: this.idPeriodo }, this.passo7)
        .then(this.finalizar)
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvar() {
      if (this.IdsExtensaoElegivelFinalizada.length) {
        this.salvarElegivelPasso7();
      } else {
        this.salvarPasso();
      }
    },
    salvarElegivelPasso7() {
      const id = this.idPeriodo;
      this.parametrizacaoPeriodoPasso7.salvarElegivelPasso7({ id }, this.IdsExtensaoElegivelFinalizada)
        .then(() => {
          this.$toast(this.$t('message.configuracao_salva'));
          this.$router.push({ name: 'parametrizacao-periodo' });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarPasso() {
      const { concorrentes } = this.passo7;
      this.passo7.preencherListaIdsConcorrentesVinculados(concorrentes);

      this.parametrizacaoPeriodoPasso7.salvarPasso7({ id: this.idPeriodo }, this.passo7)
        .then(() => {
          this.$toast(this.$t('message.configuracao_salva'));
          this.$router.push({ name: 'parametrizacao-periodo' });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    finalizar() {
      const id = this.idPeriodo;
      this.parametrizacaoPeriodoResources.finalizar({ id }, { id })
        .then(() => {
          this.$toast(this.$t('message.configuracao_finalizada'));
          this.$router.push({ name: 'parametrizacao-periodo' });
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarRascunho() {
      const { concorrentes } = this.passo7;
      this.passo7.preencherListaIdsConcorrentesVinculados(concorrentes);

      this.parametrizacaoPeriodoPasso7.salvarRascunhoPasso7({ id: this.idPeriodo }, this.passo7)
        .then(() => {
          this.$toast(this.$t('message.rascunho_salvo'));
          this.buscarPasso();
          this.$emit('PARAMETRIZACAO_PERIODO_PASSO7_RASCUNHO');
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    desabilitarSelecao(item) {
      const codigo = item.idExterno.toUpperCase();
      const nome = item.nomConcorrente.toUpperCase();
      if (nome === 'ADAMA' && codigo === 'ADAMA') {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.idPeriodo) {
      this.buscarPasso();
    }
  },
};
</script>
