<template>
  <div>
    <v-stepper v-model="passo">
      <v-stepper-header>

        <v-stepper-step :complete="passo > 1" edit-icon="$vuetify.icons.complete" :class="{ 'v-stepper__step--editable': passo > 1}" :step="1" @click="openVoltarPasso(1)">{{ $t('label.geral') }}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="passo > 2" edit-icon="$vuetify.icons.complete" :class="{ 'v-stepper__step--editable': passo > 2}" :step="2" @click="openVoltarPasso(2)">{{ $tc('label.marca_forte', 2) }}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="passo > 3" edit-icon="$vuetify.icons.complete" :class="{ 'v-stepper__step--editable': passo > 3}" :step="3" @click="openVoltarPasso(3)">{{ $tc('label.indicador', 2) }}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="passo > 4" edit-icon="$vuetify.icons.complete" :class="{ 'v-stepper__step--editable': passo > 4}" :step="4" @click="openVoltarPasso(4)">{{ $t('label.classificacao') }}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="passo > 5" edit-icon="$vuetify.icons.complete" :class="{ 'v-stepper__step--editable': passo > 5}" :step="5" @click="openVoltarPasso(5)">{{ $t('label.atingimento') }}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="passo > 6" edit-icon="$vuetify.icons.complete" :class="{ 'v-stepper__step--editable': passo > 6}" :step="6" @click="openVoltarPasso(6)">{{ $t('label.rebate') }}</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="passo > 7" edit-icon="$vuetify.icons.complete" :step="7">{{ $tc('label.fornecedores', 2) }}</v-stepper-step>

      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content :step="1">
          <parametrizacao-periodo-passo1
            :exibindo="passo === 1"
            :id-periodo="idPeriodo"
            :somente-leitura="somenteLeitura"
            :alterar-finalizado="alterarFinalizado"
            :ids-extensoes-clientes-classificacao="idsExtensoesClientesClassificacao"
            :ids-extensoes-clientes-indicadores="idsExtensoesClientesIndicadores"
            @PARAMETRIZACAO_PERIODO_PASSO1_PROXIMO="proximoPasso"
            @PARAMETRIZACAO_PERIODO_PASSO1_RASCUNHO="preencherId">
          </parametrizacao-periodo-passo1>
        </v-stepper-content>
        <v-stepper-content :step="2">
          <parametrizacao-periodo-passo2
            :exibindo="passo === 2"
            :id-periodo="idPeriodo"
            :somente-leitura="somenteLeitura"
            :alterar-finalizado="alterarFinalizado"
            @PARAMETRIZACAO_PERIODO_PASSO2_VOLTAR="voltarPasso"
            @PARAMETRIZACAO_PERIODO_PASSO2_PROXIMO="proximoPasso">
          </parametrizacao-periodo-passo2>
        </v-stepper-content>
        <v-stepper-content :step="3">
          <parametrizacao-periodo-passo3
            ref="passo3"
            :exibindo="passo === 3"
            :id-periodo="idPeriodo"
            :somente-leitura="somenteLeitura"
            :alterar-finalizado="alterarFinalizado"
            @PARAMETRIZACAO_PERIODO_PASSO3_VOLTAR="voltarPasso"
            @PARAMETRIZACAO_PERIODO_PASSO3_PROXIMO="proximoPasso"
            @ParametrizacaoPeriodoPasso3_configuracaoAlterada
              ="alterarElegiveisBloqueadosIndicadores">
          </parametrizacao-periodo-passo3>
        </v-stepper-content>
        <v-stepper-content :step="4">
          <parametrizacao-periodo-passo4
            :exibindo="passo === 4"
            :id-periodo="idPeriodo"
            :somente-leitura="somenteLeitura"
            :alterar-finalizado="alterarFinalizado"
            :ids-extensoes-rebate="idsExtensoesRebate"
            @PARAMETRIZACAO_PERIODO_PASSO4_VOLTAR="voltarPasso"
            @PARAMETRIZACAO_PERIODO_PASSO4_PROXIMO="proximoPasso"
            @ParametrizacaoPeriodoPasso4_configuracaoAlterada
              ="alterarElegiveisBloqueadosClassificacao">
          </parametrizacao-periodo-passo4>
        </v-stepper-content>
        <v-stepper-content :step="5">
          <parametrizacao-periodo-passo5
            :exibindo="passo === 5"
            :id-periodo="idPeriodo"
            :somente-leitura="somenteLeitura"
            @PARAMETRIZACAO_PERIODO_PASSO5_VOLTAR="voltarPasso"
            @PARAMETRIZACAO_PERIODO_PASSO5_PROXIMO="proximoPasso">
          </parametrizacao-periodo-passo5>
        </v-stepper-content>
        <v-stepper-content :step="6">
          <parametrizacao-periodo-passo6
            :exibindo="passo === 6"
            :id-periodo="idPeriodo"
            :somente-leitura="somenteLeitura"
            :alterar-finalizado="alterarFinalizado"
            @PARAMETRIZACAO_PERIODO_PASSO6_VOLTAR="voltarPasso"
            @PARAMETRIZACAO_PERIODO_PASSO6_PROXIMO="proximoPasso"
            @ParametrizacaoPeriodoPasso6_configuracaoAlterada="alterarClassificacaoRebate">
          </parametrizacao-periodo-passo6>
        </v-stepper-content>
        <v-stepper-content :step="7">
          <parametrizacao-periodo-passo7
            :exibindo="passo === 7"
            :id-periodo="idPeriodo"
            :somente-leitura="somenteLeitura"
            :alterar-finalizado="alterarFinalizado"
            @PARAMETRIZACAO_PERIODO_PASSO7_VOLTAR="voltarPasso">
          </parametrizacao-periodo-passo7>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <confirm
      ref="confirmDialog"
      :message="textoDialog"
      @agree="agreeDialog">
    </confirm>
  </div>
</template>

<script>
import ParametrizacaoPeriodoPasso1 from './passo1/ParametrizacaoPeriodoPasso1';
import ParametrizacaoPeriodoPasso2 from './passo2/ParametrizacaoPeriodoPasso2';
import ParametrizacaoPeriodoPasso3 from './passo3/ParametrizacaoPeriodoPasso3';
import ParametrizacaoPeriodoPasso4 from './passo4/ParametrizacaoPeriodoPasso4';
import ParametrizacaoPeriodoPasso5 from './passo5/ParametrizacaoPeriodoPasso5';
import ParametrizacaoPeriodoPasso6 from './passo6/ParametrizacaoPeriodoPasso6';
import ParametrizacaoPeriodoPasso7 from './passo7/ParametrizacaoPeriodoPasso7';
import PeriodoConfiguracao from './PeriodoConfiguracao';
import Confirm from '../../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  name: 'ParametrizacaoPeriodoForm',
  components: {
    Confirm,
    ParametrizacaoPeriodoPasso7,
    ParametrizacaoPeriodoPasso6,
    ParametrizacaoPeriodoPasso5,
    ParametrizacaoPeriodoPasso4,
    ParametrizacaoPeriodoPasso3,
    ParametrizacaoPeriodoPasso2,
    ParametrizacaoPeriodoPasso1,
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      passo: 1,
      idPeriodo: null,
      periodoConfiguracao: null,
      idsExtensoesRebate: {},
      idsExtensoesClientesIndicadores: [],
      idsExtensoesClientesClassificacao: [],
      agreeDialog: () => {},
      textoDialog: '',
      passoVoltar: 0,
    };
  },
  methods: {
    openVoltarPasso(passo) {
      if (this.passo <= passo) {
        return;
      }
      this.passoVoltar = passo;
      if (this.somenteLeitura) {
        this.voltarPassoStepper();
      } else {
        this.textoDialog = this.$t('message.configuracao_voltar_passo');
        this.agreeDialog = this.voltarPassoStepper;
        this.$refs.confirmDialog.open();
      }
    },
    alterarElegiveisBloqueadosIndicadores(idsExtensoesClientes) {
      this.idsExtensoesClientesIndicadores = idsExtensoesClientes;
    },
    alterarElegiveisBloqueadosClassificacao(idsExtensoesClientes) {
      this.idsExtensoesClientesClassificacao = idsExtensoesClientes;
    },
    alterarClassificacaoRebate(idsExtensoesRebate) {
      this.idsExtensoesRebate = idsExtensoesRebate;
    },
    voltarPassoStepper() {
      this.passo = this.passoVoltar;
    },
    voltarPasso() {
      this.passo -= 1;
    },
    proximoPasso(passo) {
      this.preencherPasso(passo);
      this.passo += 1;
    },
    preencherPasso(passo) {
      if (this.passo === 1) {
        this.idPeriodo = passo.id;
        this.periodoConfiguracao.id = passo.id;
        this.periodoConfiguracao.passo1 = passo;
        this.$refs.passo3.recarregarIndicadores();
      } else if (this.passo === 2) {
        this.periodoConfiguracao.passo2 = passo;
      } else if (this.passo === 3) {
        this.periodoConfiguracao.passo3 = passo;
      } else if (this.passo === 4) {
        this.periodoConfiguracao.passo4 = passo;
      } else if (this.passo === 5) {
        this.periodoConfiguracao.passo5 = passo;
      } else if (this.passo === 6) {
        this.periodoConfiguracao.passo6 = passo;
      } else if (this.passo === 7) {
        this.periodoConfiguracao.passo7 = passo;
      }
    },
    preencherId(id) {
      this.periodoConfiguracao.id = id;
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.idPeriodo = parseInt(this.$route.params.id, 10);
    }
  },
  mounted() {
    if (this.idPeriodo) {
      this.periodoConfiguracao = new PeriodoConfiguracao(this.idPeriodo);
    } else {
      this.periodoConfiguracao = new PeriodoConfiguracao();
    }
  },
};
</script>
