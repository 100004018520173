<template>
  <div class="ApuracaoDpmProdutosComplementares">
    <div class="mt-2 mb-2 title-float accent--text">
      {{`${$tc('label.produto_complementar', 2)}/${$tc('label.servico', 2)}`}}
    </div>
    <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-expansion-panels v-model="painel">
            <v-expansion-panel>
              <v-expansion-panel-header>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="cabecalho"
                  :items="produtosComplementares"
                  dense
                  disable-filtering
                  disable-pagination
                  disable-sort
                  fixed-header
                  :height="400"
                  hide-default-footer>
                    <template v-slot:item.pontoUnitario="{ item }">
                      {{getNumber(item.pontoUnitario)}}
                    </template>
                    <template v-slot:item.volumeMeta="{ item }">
                      {{getNumber(item.volumeMeta)}}
                    </template>
                    <template v-slot:item.volumeFaturado="{ item }">
                      {{getNumber(item.volumeFaturado)}}
                    </template>
                    <template v-slot:item.volumeFatCart="{ item }">
                      {{getNumber(item.volumeFatCart)}}
                    </template>
                    <template v-slot:item.pontosMeta="{ item }">
                      {{getNumber(item.pontosMeta)}}
                    </template>
                    <template v-slot:item.pontosFaturamento="{ item }">
                      {{getNumber(item.pontosFaturamento)}}
                    </template>
                    <template v-slot:item.pontosFatCart="{ item }">
                      {{getNumber(item.pontosFatCart)}}
                    </template>
                    <template v-slot:body.append>
                      <tr>
                        <td class="text-right" colspan="5" style="font-weight:bold">
                          {{getNumber(totalVolumeMeta)}}
                        </td>
                        <td class="text-right" style="font-weight:bold">
                          {{getNumber(totalVolumeFaturado)}}
                        </td>
                        <td class="text-right" style="font-weight:bold">
                          {{getNumber(totalVolumeFatCart)}}
                        </td>
                        <td class="text-right" style="font-weight:bold">
                          {{getNumber(totalPontosMeta)}}
                        </td>
                        <td class="text-right" style="font-weight:bold">
                          {{getNumber(totalPontosFaturamento)}}
                        </td>
                        <td class="text-right" style="font-weight:bold">
                          {{getNumber(totalPontosFatCart)}}
                        </td>
                      </tr>
                    </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { getNumber } from '../../../../produto/common/functions/helpers';

export default {
  props: {
    produtosComplementares: Array,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      painel: null,
      cabecalho: [
        {
          text: this.$tc('label.segmento', 1),
          value: 'segmentos',
          align: 'start',
        },
        {
          text: this.$t('label.mix'),
          value: 'mix',
          align: 'start',
        },
        {
          text: this.$t('label.marca'),
          value: 'marca',
          align: 'start',
        },
        {
          text: this.$t('label.ponto_unitario'),
          value: 'pontoUnitario',
          align: 'end',
        },
        {
          text: this.$t('label.volume_meta'),
          value: 'volumeMeta',
          align: 'end',
        },
        {
          text: this.$t('label.volume_faturado'),
          value: 'volumeFaturado',
          align: 'end',
        },
        {
          text: this.$t('label.volume_fat_cart'),
          value: 'volumeFatCart',
          align: 'end',
        },
        {
          text: this.$t('label.pontos_meta'),
          value: 'pontosMeta',
          align: 'end',
        },
        {
          text: this.$t('label.pontos_faturamento'),
          value: 'pontosFaturamento',
          align: 'end',
        },
        {
          text: this.$t('label.pontos_fat_cart'),
          value: 'pontosFatCart',
          align: 'end',
        },
      ],
    };
  },
  computed: {
    totalVolumeMeta() {
      return this.produtosComplementares
        .map((m) => m.volumeMeta)
        .reduce((p, a) => p + a, 0);
    },
    totalVolumeFaturado() {
      return this.produtosComplementares
        .map((m) => m.volumeFaturado)
        .reduce((p, a) => p + a, 0);
    },
    totalVolumeFatCart() {
      return this.produtosComplementares
        .map((m) => m.volumeFatCart)
        .reduce((p, a) => p + a, 0);
    },
    totalPontosMeta() {
      return this.produtosComplementares
        .map((m) => m.pontosMeta)
        .reduce((p, a) => p + a, 0);
    },
    totalPontosFaturamento() {
      return this.produtosComplementares
        .map((m) => m.pontosFaturamento)
        .reduce((p, a) => p + a, 0);
    },
    totalPontosFatCart() {
      return this.produtosComplementares
        .map((m) => m.pontosFatCart)
        .reduce((p, a) => p + a, 0);
    },
  },
  methods: {
    getNumber,
  },
};
</script>
<style scoped>
.preview {
  font-size:.8rem;
}
</style>
<style>
.ApuracaoDpmProdutosComplementares .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
