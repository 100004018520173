<template>
  <div>
    <v-container fluid px-0>
      <v-row align="center" justify="center" class="mx-0">
        <v-col cols="12">
          <v-text-field
            v-model="pesquisaCards"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.pesquisar', 1)}`"
            class="Barra_Pesquisa"
            single-line
            hide-details
            clearable>
          </v-text-field>
        </v-col>

        <v-container fluid class="Barra_Navegacao elevation-1 mt-2 mx-3">
          <v-row align="center" justify="center">
            <v-bottom-navigation
              v-model="visao_selecionada"
              v-if="visao_selecionada"
              height="50px"
              class="elevation-0"
              color="transparent"
              @change="recarregaBusca">
              <v-col cols="6" sm="2" v-for="aba in abasFiltradas" :key="aba.valor" style="background-color: white; text-align: center;" class="pt-1">
                <v-btn text small style="color: #0086ff" :value="aba.valor">
                  <span>{{ aba.titulo }}</span>
                  <v-icon>{{ aba.icon }}</v-icon>
                </v-btn>
              </v-col>
            </v-bottom-navigation>
          </v-row>
        </v-container>

        <v-container fluid grid-list-md class="Container_Cards" v-if="listaCards.length">
          <v-data-iterator
            :items="listaCards"
            :options.sync="pagination"
            :hide-default-footer="12 >= totalPage"
            :server-items-length="totalPage"
            :footer-props="{
              itemsPerPageOptions: rowsPerPageItems,
            }">
            <template v-slot:default="props">
              <v-row justify="start">
                <v-col
                  v-for="item in props.items"
                  :key="item.titulo"
                  cols="12"
                  sm="4"
                 md="3">
                  <v-hover v-slot:default="{ hover }">
                    <v-card :class="`elevation-${hover ? 5 : 1}`" fill-height
                            class="Card_Apuracao clickable" @click="selectItem(item)">
                      <v-card-title class="Card_Title pa-2 justify-center">
                        <div style="width: 90%;" class="text-center mb-0">
                          {{ $te(item.titulo, 1) ? $tc(item.titulo, 1) : item.titulo }}
                        </div>
                         <div  v-bind:style="[item.status ? {background: corValorPercentual(item)}:{background: '#808080'}]" class="text-center mb-0 Bolinha">
                          <p style="color: white">{{item.quantidadeRegistros}}</p>
                        </div>
                      </v-card-title>
                      <v-card-text class="Card_Content py-0">
                        <v-row class="Card_Apuracoes" v-if="!isVisaoStatus">
                          <v-col class="d-flex justify-space-between text-caption pt-10 pb-0" justify="center" align="center">
                              <div>
                                <p class="mb-0" v-bind:style="{color: corValorPercentualAprovado(item)}">
                                  {{ getMoney(item.vl_aprovado) }} <br />
                                  {{ getPercent(item.valor_percentual_aprovado) }}
                                </p>
                                <p class="mb-0">
                                  {{ $tc('label.apurado', 1) }}
                                </p>
                              </div>
                              <div>
                                <p class="mb-0" v-bind:style="{color: corValorPercentualAgAprovacao()}">
                                  {{ getMoney(item.vl_ag_aprovacao) }} <br />
                                  {{ getPercent(item.valor_percentual_ag_aprovado) }}
                                </p>
                                <p class="mb-0" >
                                  {{ $tc('label.ag_apuracao') }}
                                </p>
                              </div>
                          </v-col>
                        </v-row>
                        <v-row justify="center" align="center" v-if="isVisaoStatus">
                          <p v-bind:style="{color: corValorPercentual(item)}" class="headline font-weight-light">
                            {{ getPercent(item.valor_percentual) }}
                          </p>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="Card_Actions">
                        <v-row class="text-xs-center" align="end" v-if="isVisaoStatus">
                          <v-col cols="6" class="py-3">
                            {{ indModoVarejo ? $t('label.total_recebimento') : $tc('label.total_pagamento', 2) }}: <br />
                            {{ getMoney(item.total_recebimentos) }}
                          </v-col>
                          <v-col cols="6" class="py-0">
                            <v-card v-bind:style="{background: corCardMenor(item)}" class="white--text text-center">
                              {{ $t(item.status_composicao) }}
                            </v-card>
                          </v-col>
                          <v-col cols="12" style="text-align: end;"  class="py-1">
                            <v-icon v-if="false" @click.native="abrirModalConfirmacao('exportar')">get_app</v-icon>
                          </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-else>
                          <v-spacer />
                          <v-col cols="12" style="text-align: end;" class="py-1">
                            <v-icon v-if="false" @click.native="abrirModalConfirmacao('exportar')">get_app</v-icon>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
            <template v-slot:no-data>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
            <template v-slot:no-results>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-row>
      <confirm
        ref="modalConfirmacao"
        :message="mensagemConfirmacao"
        @agree="validarAcao">
      </confirm>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  getMoney,
  getPercent,
} from '../../common/functions/helpers';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import {
  listarApuracoesPorStatus,
  listarApuracoesPorCliente,
  listarApuracoesPorHolding,
  listarApuracoesPorFornecedor,
  listarApuracoesPorFornecedorPagador,
  listarApuracoesPorGrupoFornecedor,
  listarApuracoesPorNivelExtensao,
  listarApuracoesPorRegional,
  listarApuracoesNivel2,
} from '../../common/resources/apuracao/apuracao-acao-listagem';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'ApuracaoAcaoDadosNivel1Agrupado',
  components: {
    Confirm,
  },
  props: {
    visao: String,
  },
  data() {
    return {
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoListagem(this.$resource),
      focoExtensoes: [],
      pesquisaCards: '',
      mensagemConfirmacao: '',
      objeto_selecionado: '',
      visao_selecionada: null,
      listaCards: [],
      rowsPerPageItems: [12],
      totalPage: 0,
      filtrosAplicados: this.filtros,
      itensPorVisao: {},
      pagination: {
        itemsPerPage: 12,
      },
      buscando: false,
      esperarDigitar: false,
    };
  },
  watch: {
    filtroApuracaoAcao: {
      handler() {
        this.filtrosAplicados = this.filtroApuracaoAcao;
        this.recarregaBusca();
      },
    },
    pagination: {
      handler() {
        if (this.filtroApuracaoAcao) {
          this.buscar();
        }
      },
      deep: true,
    },
  },
  filters: {
    truncate(value, limit) {
      if (value.length > limit) {
        value = value.substring(0, (limit - 3)).concat('...');
      }
      return value;
    },
  },
  computed: {
    ...generateComputed('APU_ACAO', [
      'canAccessPage',
      'canAccessCRUD',
    ]),

    ...mapGetters('filtros', [
      'filtroApuracaoAcaoNivel1',
      'filtroApuracaoAcao',
    ]),

    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),

    abas() {
      const abas = [
        {
          titulo: this.$tc('label.status', 1),
          valor: 'Status',
          icon: 'update',
        },
      ];

      if (this.indModoVarejo) {
        abas.push({
          titulo: this.$tc('label.grupo_fornecedor', 1),
          valor: 'Grupo Fornecedor',
          icon: 'group_work',
        });
        abas.push({
          titulo: this.$tc('label.fornecedor', 1),
          valor: 'Fornecedor',
          icon: 'fa-industry',
        });
      } else {
        abas.push({
          titulo: this.$tc('label.holding', 1),
          valor: 'Holding',
          icon: 'group_work',
        });
      }

      return [...abas, ...this.extensoesComFoco];
    },

    abasComItens() {
      const abas = [];
      const cliente = 'cliente';
      const regional = 'regional';
      const fornecedor = 'fornecedor';

      if (this.itensPorVisao[cliente]) {
        abas.push({
          titulo: this.$tc('label.cliente', 1),
          valor: 'Cliente',
          icon: 'store',
        });
      }
      if (this.itensPorVisao[regional]) {
        abas.push({
          titulo: this.$tc('label.regional', 1),
          valor: 'Regional',
          icon: 'line_weight',
        });
      }
      if (!this.indModoVarejo) {
        if (this.itensPorVisao[fornecedor]) {
          abas.push({
            titulo: this.$tc('label.fornecedor', 1),
            valor: 'Fornecedor Pagador',
            icon: 'fa-industry',
          });
        }
      }
      return [...abas];
    },

    abasFiltradas() {
      return [...this.abas, ...this.abasComItens];
    },

    extensoesComFoco() {
      return (this.focoExtensoes || []).map((el) => {
        const aba = {
          titulo: this.$tc(`label.${el.toLowerCase()}`),
          valor: el,
          icon: 'store',
        };
        return aba;
      });
    },

    isVisaoStatus() {
      return this.visao_selecionada === 'Status';
    },

    isVisaoCliente() {
      return this.visao_selecionada === 'Cliente';
    },

    isVisaoHolding() {
      return this.visao_selecionada === 'Holding';
    },

    isVisaoFornecedor() {
      return this.visao_selecionada === 'Fornecedor';
    },

    isVisaoFornecedorPagador() {
      return this.visao_selecionada === 'Fornecedor Pagador';
    },

    isVisaoGrupoFornecedor() {
      return this.visao_selecionada === 'Grupo Fornecedor';
    },

    isVisaoRegional() {
      return this.visao_selecionada === 'Regional';
    },
  },
  methods: {
    getMoney,
    getPercent,
    ...mapActions('filtros', [
      'setFiltroApuracaoAcaoNivel1',
    ]),
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },
    selectItem(item) {
      this.$emit('ApuracaoAcao__SelecionaItemNivel1', item, this.visao_selecionada);
    },
    recarregaBusca() {
      this.pagination.page = 1;
      this.listaCards = [];
      this.buscar();
    },
    buscar() {
      if (this.buscando) return;
      this.buscando = true;
      const params = {
        ...this.filtrosAplicados,
        filtro: this.pesquisaCards,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };

      let promise = null;

      if (this.isVisaoCliente) {
        promise = listarApuracoesPorCliente(params, this.$resource);
      } else if (this.isVisaoHolding) {
        promise = listarApuracoesPorHolding(params, this.$resource);
      } else if (this.isVisaoFornecedor) {
        promise = listarApuracoesPorFornecedor(params, this.$resource);
      } else if (this.isVisaoFornecedorPagador) {
        promise = listarApuracoesPorFornecedorPagador(params, this.$resource);
      } else if (this.isVisaoGrupoFornecedor) {
        promise = listarApuracoesPorGrupoFornecedor(params, this.$resource);
      } else if (this.isVisaoStatus) {
        promise = listarApuracoesPorStatus(params, this.$resource);
      } else if (this.isVisaoRegional) {
        promise = listarApuracoesPorRegional(params, this.$resource);
      } else {
        const par = { ...params, mnemonico: this.visao_selecionada };
        promise = listarApuracoesPorNivelExtensao(par, this.$resource);
      }

      promise.then((response) => {
        if (this.listaCards.length === 0) {
          this.totalPage = response.data.quantidadeRegistrosPagina;
          response.data.resposta.forEach((card) => {
            if (card.status) {
              params.status = card.status;
            } else if (card.id_holding) {
              params.id_holding = card.id_holding;
            } else if (card.id_cliente) {
              params.id_cliente = card.id_cliente;
            }
            listarApuracoesNivel2(params, this.$resource).then((resp) => {
              card.quantidadeRegistros = resp.data.quantidadeRegistrosPagina;
              this.listaCards.push(card);
            });
          });
        }
        this.rowsPerPageItems = [12, 16, 20, 24, 28, 32, 36, 40];
        setTimeout(this.finalizandoBusca);
        this.preencherFiltrosNivel1Vuex();
      })
        .catch((err) => {
          this.finalizandoBusca();
          this.$error(this, err);
        });
    },

    finalizandoBusca() {
      this.buscando = false;
    },

    corValorPercentual(item) {
      if (this.isVisaoStatus) {
        if (item.status === 'APURACAO_PREVIA') {
          return 'rgb(149, 165, 166)';
        } if (item.status === 'AGUARDANDO_APURACAO') {
          return 'rgb(30, 136, 229)';
        } if (item.status === 'AGUARDANDO_APROVACAO') {
          return 'rgb(230, 126, 34)';
        } if (item.status === 'EM_ANALISE') {
          return 'rgb(241, 196, 15)';
        } if (item.status === 'APROVADO') {
          return 'rgb(26, 188, 156)';
        } if (item.status === 'REPROVADO') {
          return 'rgb(231, 76, 60)';
        } if (item.status === 'CANCELADO') {
          return 'rgb(126, 87, 194)';
        } if (item.status === 'VENCIDO') {
          return 'rgb(107, 35, 142)';
        }
      } else if (this.isVisaoCliente || this.isVisaoHolding
        || this.isVisaoFornecedor || this.isVisaoGrupoFornecedor
        || this.isVisaoRegional) {
        if (item.valor_percentual === 0) {
          return 'rgb(149, 165, 166)';
        } if (item.valor_percentual > 0 && item.valor_percentual < 100) {
          return 'rgb(241, 196, 15)';
        } if (item.valor_percentual === 100) {
          return 'rgb(26, 188, 156)';
        }
      }

      return '#757575';
    },

    corCardMenor(item) {
      if (this.isVisaoStatus) {
        if (item.status === 'APURACAO_PREVIA'
        || item.status === 'AGUARDANDO_APURACAO'
        || item.status === 'AGUARDANDO_APROVACAO'
        || item.status === 'EM_ANALISE') {
          return 'rgb(230, 126, 34)';
        } if (item.status === 'APROVADO') {
          return 'rgb(26, 188, 156)';
        } if (item.status === 'REPROVADO'
        || item.status === 'CANCELADO') {
          return 'rgb(231, 76, 60)';
        }
      }
      return '#757575';
    },

    corValorPercentualAprovado(item) {
      if (this.isVisaoCliente || this.isVisaoHolding
        || this.isVisaoFornecedor || this.isVisaoGrupoFornecedor
        || this.isVisaoRegional) {
        if (item.valor_percentual_aprovado === 0) {
          return 'rgb(149, 165, 166)';
        } if (item.valor_percentual_aprovado > 0 && item.valor_percentual_aprovado < 100) {
          return 'rgb(230, 126, 34)';
        } if (item.valor_percentual_aprovado === 100) {
          return 'rgb(26, 188, 156)';
        }
      }

      return '#757575';
    },

    corValorPercentualAgAprovacao() {
      if (this.isVisaoCliente || this.isVisaoHolding
        || this.isVisaoFornecedor || this.isVisaoGrupoFornecedor
        || this.isVisaoRegional) {
        return 'rgb(0, 0, 0)';
      }

      return '#757575';
    },

    abrirModalConfirmacao(acao) {
      if (acao === 'editar') {
        this.mensagemConfirmacao = this.$t('message.deseja_editar_item');
      } else if (acao === 'enviar_analise') {
        this.mensagemConfirmacao = this.$t('message.deseja_enviar_analise');
      } else if (acao === 'aprovar') {
        this.mensagemConfirmacao = this.$t('message.deseja_aprovar_item');
      } else if (acao === 'exportar') {
        this.mensagemConfirmacao = this.$t('message.deseja_exportar');
      } else {
        this.mensagemConfirmacao = this.$t('message.deseja_reprovar_item');
      }
      setTimeout(() => this.$refs.modalConfirmacao.open());
    },
    preencherFiltrosNivel1Vuex() {
      const pagina = this.pagination.page;
      const quantidatePorPagina = this.pagination.itemsPerPage;

      this.setFiltroApuracaoAcaoNivel1({
        pagina,
        quantidatePorPagina,
      });
    },
    preencherFiltrosNivel1() {
      this.pagination.page = this.filtroApuracaoAcaoNivel1.pagina;
      this.pagination.itemsPerPage = this.filtroApuracaoAcaoNivel1.quantidatePorPagina;
    },
    validarAcao() {
    },
    buscarVisoes() {
      this.apuracaoAcaoListagemResource.buscarVisoes()
        .then((resp) => {
          this.itensPorVisao = resp.data;
        });
    },
    buscaFocoExtensoes() {
      return this.apuracaoAcaoListagemResource.extensoes()
        .then((res) => {
          this.focoExtensoes = res.data;
          this.visao_selecionada = this.visao;

          if (this.filtroApuracaoAcaoNivel1) {
            this.preencherFiltrosNivel1();
          }

          if (this.filtroApuracaoAcao) {
            this.filtrosAplicados = this.filtroApuracaoAcao;
            this.buscar();
          }
        })
        .then(this.buscarVisoes);
    },
  },
  beforeMount() {
    this.buscaFocoExtensoes();
  },
};
</script>

<style>
.Barra_Navegacao .v-item-group.v-bottom-nav .v-btn,.Barra_Navegacao .v-item-group.v-bottom-nav .v-btn--active {
  padding: 4px;
}
.Barra_Pesquisa {
  width: 33%;
}
.clickable {
  cursor: pointer;
}
.Card_Apuracao {
  min-height: 146px !important;
  height: 100%;
}
.Card_Apuracao .Card_Title, .Card_Apuracao .Card_Content {
  color: #757575;
}
.Bolinha {
  width: 30px;
  height: 30px;
  border: 1px solid black;
  border-radius: 100px 100px 100px 100px;
}
</style>
