<template>
  <v-expansion-panels
    multiple>
    <v-expansion-panel
      v-for="(reserva, index) in reservas"
      :key="index">
      <v-expansion-panel-header v-slot="{ open }">
        <v-row no-gutters>
          <v-col cols="3">{{ reserva.descricao }}</v-col>
          <v-col
            cols="9"
            class="text--secondary">
            <v-fade-transition leave-absolute>
              <v-row
                v-if="!open"
                no-gutters
                style="width: 100%">
                <v-col cols="3">{{ `${$t('label.data_inicio')}: ${reserva.dtaInicioFormatada}` }}</v-col>
                <v-col cols="3">{{ `${$t('label.data_fim')}: ${reserva.dtaFimFormatada}` }}</v-col>
                <v-col cols="3">{{ `${$tc('label.valor', 1)}: ${getMoney(reserva.valor)}` }}</v-col>
                <v-col cols="3">{{ `${$tc('label.pendente_reserva', 1)}: ${getMoney(reserva.pendente)}` }}</v-col>
              </v-row>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content eager>
        <planejamento-acao-reserva-apuracao
          :reserva="reserva"
          :contas-correntes="contasCorrentes"
          :somente-leitura="somenteLeitura"
          :exibir-saldo="exibirSaldo"
          @PlanejamentoAcaoReservaApuracao_valorAlterado="valorAlterado"
          @PlanejamentoAcaoReservaApuracao_reservaAlterada="reservaAlterada">
        </planejamento-acao-reserva-apuracao>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import InputMoney from '../../../../shared-components/inputs/InputMoney';
import PlanejamentoAcaoReservaApuracao from './PlanejamentoAcaoReservaApuracao';
import { getMoney } from '../../../../common/functions/helpers';

export default {
  name: 'PlanejamentoAcaoReservasApuracao',
  components: {
    PlanejamentoAcaoReservaApuracao,
    InputMoney,
  },
  props: {
    reservas: {
      type: Array,
      default: () => ([]),
    },
    somenteLeitura: Boolean,
    contasCorrentes: {
      type: Array,
      default: () => ([]),
    },
    exibirSaldo: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getMoney,
    valorAlterado(diferencaValor) {
      const valorTotal = this.reservas
        .map((r) => r.valor)
        .reduce((accumulator, currentValue) => accumulator + currentValue);
      this.$emit('PlanejamentoAcaoReservasApuracao_valorTotalAlterado', valorTotal + diferencaValor);
    },
    reservaAlterada() {
      const valorReservado = this.reservas.map((r) => r.valorReservado).reduce((a, b) => a + b, 0);
      this.$emit('PlanejamentoAcaoReservasApuracao_reservaAlterada', valorReservado);
    },
  },
};
</script>
