var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PlanejamentoDpmDeclaracaoCompra"},[_c('div',{staticClass:"mt-2 mb-2 title-float accent--text"},[_vm._v(" "+_vm._s(_vm.$tc('label.declaracao_compra_agro'))+" ")]),_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-expansion-panels',{model:{value:(_vm.painel),callback:function ($$v) {_vm.painel=$$v},expression:"painel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pb-0 pt-0"},[_c('v-row',{staticClass:"pr-8",attrs:{"align":"start","justify":"space-between"}},[_c('span',{staticClass:"grey--text text--darken-1 preview"},[_c('b',[_vm._v(_vm._s(((this.$tc('label.compra', 2)) + " " + (this.anoAnteriorPeriodo) + ": ")))]),_vm._v(_vm._s(_vm.getMoney(_vm.sumComprasAnterior, 0, 2, '$' ))+" ")]),_c('span',{staticClass:"grey--text text--darken-1 preview"},[_c('b',[_vm._v(_vm._s(((this.$t('label.cs')) + " " + (this.anoAnteriorPeriodo) + ": ")))]),_vm._v(_vm._s(_vm.getPercent(_vm.sumCSAnterior))+" ")]),_c('span',{staticClass:"grey--text text--darken-1 preview"},[_c('b',[_vm._v(_vm._s(((this.$tc('label.compra', 2)) + " " + (this.anoAtualPeriodo) + ": ")))]),_vm._v(_vm._s(_vm.getMoney(_vm.sumComprasAtual, 0, 2, '$' ))+" ")]),_c('span',{staticClass:"grey--text text--darken-1 preview"},[_c('b',[_vm._v(_vm._s(((this.$t('label.cs')) + " " + (this.anoAtualPeriodo) + ": ")))]),_vm._v(_vm._s(_vm.getPercent(_vm.sumCSAtual))+" ")])])],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.cabecalho,"items":_vm.linhas,"dense":"","disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.comprasAnterior",fn:function(ref){
var item = ref.item;
return [(item.fornecedor !== 'ADAMA')?_c('input-decimal',{staticClass:"py-0 mt-0 mb-1",attrs:{"single-line":"","hide-details":"","dense":"","align-right":"","rules":[_vm.rules.maximoPadrao],"disabled":_vm.somenteLeitura},on:{"input":function($event){return _vm.compraAnteriorAlterada()}},model:{value:(item.comprasAnterior),callback:function ($$v) {_vm.$set(item, "comprasAnterior", $$v)},expression:"item.comprasAnterior"}}):_c('span',[_vm._v(" "+_vm._s(_vm.getMoney(item.comprasAnterior, 0, 2, '$'))+" ")])]}},{key:"item.csAnterior",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPercent(item.csAnterior))+" ")]}},{key:"item.comprasAtual",fn:function(ref){
var item = ref.item;
return [(item.fornecedor !== 'ADAMA')?_c('input-decimal',{staticClass:"py-0 mt-0 mb-1",attrs:{"disabled":_vm.somenteLeitura,"single-line":"","hide-details":"","dense":"","align-right":"","rules":[_vm.rules.maximoPadrao]},on:{"input":function($event){return _vm.compraAtualAlterada()}},model:{value:(item.comprasAtual),callback:function ($$v) {_vm.$set(item, "comprasAtual", $$v)},expression:"item.comprasAtual"}}):_c('span',[_vm._v(" "+_vm._s(_vm.getMoney(item.comprasAtual, 0, 2, '$'))+" ")])]}},{key:"item.csAtual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPercent(item.csAtual))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticClass:"text-left",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t('label.total'))+": ")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"right"}},[_vm._v(" "+_vm._s(_vm.getMoney(_vm.sumComprasAnterior, 0, 2, '$'))+" ")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"right"}},[_vm._v(" "+_vm._s(_vm.getPercent(_vm.sumCSAnterior))+" ")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"right"}},[_vm._v(" "+_vm._s(_vm.getMoney(_vm.sumComprasAtual, 0, 2, '$'))+" ")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"right"}},[_vm._v(" "+_vm._s(_vm.getPercent(_vm.sumCSAtual))+" ")])])]},proxy:true}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }