import Classificacao from './Classificacao';

export default class Passo4 {
  constructor(passo4 = {}) {
    this._classificacoes = (passo4.classificacoes || []).map((c) => new Classificacao(c));
  }

  get classificacoes() {
    return this._classificacoes;
  }

  set classificacoes(value) {
    this._classificacoes = value;
  }

  get estaConfigurado() {
    return this.classificacaoSelecionada
      && this._classificacoes.filter((cla) => !cla.classificacaoConfigurada).length === 0;
  }

  get classificacaoSelecionada() {
    return this._classificacoes && this._classificacoes.length > 0;
  }

  get idsExtensoesCliente() {
    return this._classificacoes
      .flatMap((c) => c.idsExtensoesCliente)
      .filter((c, i, s) => s.indexOf(c) === i);
  }

  removerClassificacao(classificacao) {
    for (let i = 0; i < this._classificacoes.length; i += 1) {
      if (this._classificacoes[i].idExtensao === classificacao.idExtensao) {
        this._classificacoes[i].pontos = 0;
        this._classificacoes[i].classificacoesConfiguracao = [];
        this._classificacoes.splice(i, 1);
      }
    }
  }

  toJSON() {
    this._classificacoes.forEach((c, i) => {
      c.ordem = i;
    });
    return {
      idPeriodoConfiguracao: this._idPeriodoConfiguracao,
      classificacoes: this._classificacoes,
    };
  }
}
