import getBasePath from '../functions/api-resource';

const basePath = getBasePath('workflow_estrutural', 'execucao/apuracao');

const workflowApuracaoActions = {
  aprovador: { method: 'GET', url: `${basePath}/{idApuracao}/passos/aprovador` },
  aprovar: { method: 'PUT', url: `${basePath}/{idApuracao}/passos/aprovar` },
  cancelar: { method: 'PUT', url: `${basePath}/{idApuracao}/cancelar` },
  gravar: { method: 'POST', url: `${basePath}/{idApuracao}` },
  pendentes: { method: 'GET', url: `${basePath}/busca-fluxo-pendente` },
  reprovar: { method: 'PUT', url: `${basePath}/{idApuracao}/passos/reprovar` },
  status: { method: 'GET', url: `${basePath}/{idApuracao}/status` },
  statusPasso: { method: 'GET', url: `${basePath}/{idApuracao}/passos/status` },
  minhasAprovacoes: { method: 'GET', url: `${basePath}/fluxos/aprovador` },
  iniciaFluxo: { method: 'POST', url: `${basePath}/{idApuracao}` },
};

export default (resource) => resource(`${basePath}`, {}, workflowApuracaoActions);
