import { mapGetters } from 'vuex';
import moment from 'moment';
import BotoesDadosTabelaMixin from '@/produto/spa/planejamento-contrato/list/BotoesDadosTabelaMixin';
import getBasePath from '../../../produto/common/functions/api-resource';

export default {
  components: { BotoesDadosTabelaMixin },
  data() {
    return {
      botoesCustomizados: [
        {
          id: '',
          label: this.$t('label.reiniciar_dpm'),
          icon: 'fa-file-contract',
          click: this.reiniciarDpm,
          exibe: this.habilitarReiniciarDpm,
        },
        {
          id: 'btnPrint',
          label: this.$t('label.imprimir'),
          icon: 'fa-print',
          click: this.exportarPDF,
          exibe: this.habilitarImprimirDpm,
        },
      ],
      planejamentoContratoResource: this.$api.planejamentoDpmCadastro(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      ssoRole: this.$api.ssoRole(this.$resource),
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    podeReiniciarDPM() {
      if (!this.getAllRoles) {
        return false;
      }
      return this.getAllRoles
        .filter((el) => el === 'REINICIAR_DPM').length > 0;
    },
    podeImprimirDpm() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'DPM_PRINT').length;
    },
  },
  methods: {
    exibicaoReprovarPersonalizada() {
      return this.getAllRoles.filter((el) => el === 'REPROVAR_DPM').length > 0;
    },
    exibicaoCancelarPersonalizada(item) {
      return this.cancelarDPM(item);
    },
    cancelarDPM(item) {
      return (item.status === 'EM_CADASTRO' || item.status === 'AGUARDANDO_APROVACAO'
        || item.status === 'EM_ANALISE' || item.status === 'APROVADO')
        && (this.getAllRoles.filter((el) => el === 'CANCELAR_DPM_NAO_APROVADA').length > 0
        || this.getAllRoles.filter((el) => el === 'CANCELAR_CONTRATO').length > 0);
    },
    habilitarReiniciarDpm(item) {
      const today = moment(new Date()).format('YYYY-MM-DD');
      const dtInicio = this.moment(item.data_inicio, 'DD-MM-YYYY').format('YYYY-MM-DD');
      const dtFim = this.moment(item.data_fim, 'DD-MM-YYYY').format('YYYY-MM-DD');
      if (today >= dtInicio && today <= dtFim) {
        return this.contratoAprovado(item) && this.podeReiniciarDPM;
      }

      return false;
    },
    habilitarImprimirDpm(item) {
      return (item.status === 'APROVADO' || item.status === 'AGUARDANDO_APROVACAO' || item.status === 'EM_ANALISE') && this.podeImprimirDpm;
    },
    reiniciarDpm(item) {
      const idContrato = item.id;
      this.planejamentoContratoResource.reiniciar({ idContrato }, { idContrato })
        .then(() => {
          this.editarContrato(idContrato);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    exportarPDF(item) {
      const idContrato = item.id;
      const parametros = {
        idContrato: item.id,
      };

      const basePath = getBasePath('job', 'exportacao_pdf');
      const downloadUrl = `${basePath}/Dpm/${idContrato}`;
      this.$http.post(downloadUrl, parametros, { responseType: 'arraybuffer' })
        .then((response) => {
          const fileName = this.getFileName(response);
          const type = this.getResponseProperty(response.headers.map, 'content-type', 'Content-Type');
          const file = new window.Blob([response.data], { type }); // eslint-disable-line
          const a = document.createElement('a'); // eslint-disable-line
          a.download = fileName;
          a.href = window.URL.createObjectURL(file); // eslint-disable-line
          document.body.appendChild(a); // eslint-disable-line
          a.click();
          return a;
        })
        .catch((err) => this.$toast(this.$t(err)));
    },
    getFileName(response) {
      const header = this.getResponseProperty(response.headers.map, 'content-disposition', 'Content-Disposition');
      const temp = header.split('filename=');
      const fileName = temp[1];
      return fileName;
    },
    getResponseProperty(obj, ...params) {
      const attribute = params.filter((el) => obj.hasOwnProperty(el))[0];
      const value = obj[attribute];
      return value[0];
    },
  },
};
