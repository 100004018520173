<template>
  <v-card>
    <v-toolbar color="primary" dark dense>
      <v-toolbar-title>{{ $t('title.conta_corrente') }}</v-toolbar-title>
    </v-toolbar>
    <v-list two-line>
      <v-list-item>
        <v-list-item-avatar class="my-0">
          <v-icon color="primary">person</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="contaCorrente.descricaoPosse"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider inset></v-divider>

      <v-list-item>
        <v-list-item-avatar class="my-0">
          <v-icon color="primary">monetization_on</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
              v-text="getMoney(contaCorrente.vlrPlanejamentoAdendoRecudao)">
          </v-list-item-title>
          <v-list-item-subtitle v-text="$t('label.vlr_planejado')"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-menu
            v-model="menuPopoverPlanejado"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon color="grey lighten-1">info</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-avatar class="my-0">
                    <v-icon color="primary">attach_money</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                        v-text="getMoney(contaCorrente.vlrPlanejado)">
                    </v-list-item-title>
                    <v-list-item-subtitle v-text="$t('label.vlr_planejado')"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-avatar class="my-0">
                    <v-icon color="primary">attach_money</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                        v-text="getMoney(contaCorrente.vlrAdendo)">
                    </v-list-item-title>
                    <v-list-item-subtitle v-text="$t('label.vlr_adendo')"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-avatar class="my-0">
                    <v-icon color="primary">attach_money</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                        v-text="getMoney(contaCorrente.vlrReducao)">
                    </v-list-item-title>
                    <v-list-item-subtitle v-text="$t('label.vlr_reducao')"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-list-item-action>
      </v-list-item>

      <v-divider inset></v-divider>

      <v-list-item>
        <v-list-item-avatar class="my-0">
          <v-icon color="primary">attach_money</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="getMoney(contaCorrente.vlrComprometido)"></v-list-item-title>
          <v-list-item-subtitle v-text="$t('label.vlr_comprometido')"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-menu
            v-model="menuPopover"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon color="grey lighten-1">info</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-avatar class="my-0">
                    <v-icon color="primary">attach_money</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                        v-text="getMoney(contaCorrente.vlrReservado)">
                    </v-list-item-title>
                    <v-list-item-subtitle v-text="$t('label.vlr_reservado')"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-avatar class="my-0">
                    <v-icon color="primary">attach_money</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                        v-text="getMoney(contaCorrente.vlrConfAcao)">
                    </v-list-item-title>
                    <v-list-item-subtitle v-text="$t('label.vlr_conf_acao')"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-avatar class="my-0">
                    <v-icon color="primary">attach_money</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                        v-text="getMoney(contaCorrente.vlrConfApuracao)">
                    </v-list-item-title>
                    <v-list-item-subtitle v-text="$t('label.vlr_conf_apuracao')"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-list-item-action>
      </v-list-item>

      <v-divider inset></v-divider>

      <v-list-item>
        <v-list-item-avatar class="my-0">
          <v-icon color="primary">attach_money</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="getMoney(contaCorrente.vlrConsumido)"></v-list-item-title>
          <v-list-item-subtitle v-text="$t('label.vlr_consumido')"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider inset></v-divider>

      <v-list-item>
        <v-list-item-avatar class="my-0">
          <v-icon color="primary">attach_money</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="getMoney(contaCorrente.vlrSaldo)"></v-list-item-title>
          <v-list-item-subtitle v-text="$t('label.vlr_saldo')"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-card>
</template>

<script>
import { getMoney } from '../../../common/functions/helpers';

export default {
  name: 'ExtratoContaCorrenteCard',
  props: {
    idsContasCorrentes: {
      Type: Array,
    },
    idLinha: {
      Type: Number,
    },
  },
  data() {
    return {
      extratoContaCorrente: this.$api.extratoContaCorrente(this.$resource),
      contaCorrente: {},
      menuPopover: false,
      menuPopoverPlanejado: false,
    };
  },
  watch: {
    idsContasCorrentes() {
      this.buscarContaCorrente();
    },
    idLinha() {
      this.buscarContaCorrente();
    },
  },
  methods: {
    getMoney,
    buscarContaCorrente() {
      const param = { ids: this.idsContasCorrentes };
      if (this.idLinha) {
        param.idLinha = this.idLinha;
      }
      this.extratoContaCorrente.contaCorrente(param)
        .then((res) => {
          this.contaCorrente = res.data;
        }, (err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    this.buscarContaCorrente();
  },
};
</script>
