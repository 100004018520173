var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"Container_Tabela",attrs:{"fluid":"","px-0":"","py-2":""}},[_c('v-row',{attrs:{"d-flex":"","align":"center","justify":"left"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"Barra_Pesquisa mb-2",attrs:{"append-icon":"search","label":("" + (_vm.$tc('label.pesquisar', 1))),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.pesquisaTabela),callback:function ($$v) {_vm.pesquisaTabela=$$v},expression:"pesquisaTabela"}}),_c('v-data-table',{attrs:{"id":"tabela_apuracoes","show-select":"","headers":_vm.headers,"items":_vm.listaApuracoes,"search":_vm.pesquisaTabela,"options":_vm.pagination,"server-items-length":_vm.totalPage,"items-per-page":_vm.rowsPerPageItems,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"rows-per-page-text":_vm.$t('label.linhas_por_pagina')},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{class:['text-sm-center']},[_c('td',[_vm._v(_vm._s(props.item.cod_acao))]),_c('td',[_vm._v(_vm._s(props.item.cod_apuracao))]),_c('td',[_vm._v(_vm._s(props.item.descricao_acao))]),_c('td',{attrs:{"nowrap":""}},[_vm._v(_vm._s(props.item.data_inicio))]),_c('td',{attrs:{"nowrap":""}},[_vm._v(_vm._s(props.item.data_fim))]),_c('td',[_vm._v(_vm._s(props.item.divisao))]),_c('td',[_vm._v(_vm._s(props.item.unidade_negocio))]),_c('td',[_vm._v(" "+_vm._s(props.item.is_foco_cliente ? props.item.nom_cliente : props.item.is_foco_regional ? props.item.nom_regional : props.item.holding)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item.is_origem_fornecedor ? props.item.nome_fornecedor : props.item.nome_grupo_fornecedor)+" ")]),_c('td',{staticClass:"Botoes_Acao",class:['text-sm-left'],attrs:{"nowrap":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},nativeOn:{"click":function($event){return _vm.abrirModalConfirmacao('editar')}}},on),[_c('v-icon',{staticClass:"fa-sm"},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.executar_analise')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},nativeOn:{"click":function($event){return _vm.abrirModalConfirmacao('enviar_analise')}}},on),[_c('v-icon',{staticClass:"fa-sm"},[_vm._v("cached")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.enviar_analise')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},nativeOn:{"click":function($event){return _vm.abrirModalConfirmacao('aprovar')}}},on),[_c('v-icon',{staticClass:"fa-sm"},[_vm._v("fa-thumbs-up")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.aprovar')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},nativeOn:{"click":function($event){return _vm.abrirModalConfirmacao('reprovar')}}},on),[_c('v-icon',{staticClass:"fa-sm"},[_vm._v("fa-thumbs-down")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.reprovar')))])])],1)])]}},{key:"pageText",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" "+_vm._s(_vm.$tc('label.de', 1))+" "+_vm._s(props.itemsLength)+" ")]}}])})],1)],1),_c('confirm',{ref:"modalConfirmacao",attrs:{"message":_vm.mensagemConfirmacao.text},on:{"agree":_vm.validarAcao}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }