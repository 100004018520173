<template lang="html">
  <div v-if="canAccessPage">
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title>
          <h3 class="headline mb-0">{{ $t('label.termo_aceite') }}</h3>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                class="CalendarioForm-data__text"
                id="termo_aceite_descricao"
                name="termo_aceite_descricao"
                v-model="termo.descricao"
                :label="`${$t('label.descricao')} *`"
                :counter="100"
                :rules="[rules.required]"
                :disabled="somenteLeitura">
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                v-if="indModoVarejo"
                :id="`termo_aceite_grupo_fornecedor`"
                :name="`termo_aceite_grupo_fornecedor`"
                class="Form-text__invalid"
                :items="gruposFornecedor"
                v-model="termo.gruposFornecedor"
                item-text="nomExtensao"
                item-value="id"
                :label="`${$tc('label.grupo_fornecedor', 1)}`"
                return-object
                multiple
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :clearable="true"
                :search-input.sync="triggerGrupoFornecedor"
                @click:append="() => triggerSelecao('termo_aceite_grupo_fornecedor')"
                @click.native="(i) => buscaAutocomplete(i, buscarGruposFornecedores)"
                :disabled="somenteLeitura">
              </v-autocomplete>

              <v-select
                v-else
                :id="`termo_aceite_unidades`"
                :name="`termo_aceite_unidades`"
                class="Form-text__invalid"
                :items="unidades"
                v-model="termo.unidades"
                item-text="nomExtensao"
                item-value="id"
                :label="`${$tc('label.unidades_negocio', 2)} *`"
                return-object
                multiple
                :no-data-text="$tc('message.nenhum_registro', 1)"
                required
                :rules="[() => termo.unidades.length > 0 || this.$t('message.campo_obrigatorio')]"
                :disabled="somenteLeitura">
              </v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-select
                v-if="criarNovoTermoAceite"
                class="Form-text__invalid"
                id="termo_aceite_objetivo"
                name="termo_aceite_objetivo"
                item-text="text"
                item-value="value"
                v-model="termo.objetivo"
                :items="objetivosSemRevalidacao"
                :rules="[rules.required]"
                :label="`${$tc('label.objetivo', 1)} *`"
                required
                disabled>
              </v-select>

              <v-select
                v-if="!criarNovoTermoAceite"
                class="Form-text__invalid"
                id="termo_aceite_objetivo"
                name="termo_aceite_objetivo"
                item-text="text"
                item-value="value"
                v-model="termo.objetivo"
                :items="objetivos"
                :rules="[rules.required]"
                :label="`${$tc('label.objetivo', 1)} *`"
                required
                :disabled="somenteLeitura">
              </v-select>

            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-menu
                ref="data_inicio"
                :close-on-content-click="false"
                v-model="menuDataInicio"
                id="data_inicio"
                name="data_inicio"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                :disabled="somenteLeitura">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    class="filtro-acesso-usuario__select"
                    :return-masked-value="true"
                    v-mask="'##-##-####'"
                    :rules="[rules.required, rules.validDate]"
                    v-model="dateInicioFormatada"
                    :label="`${$t('label.data_inicio')} *`">
                  </v-text-field>
                </template>
                <v-date-picker locale="pt-br" color="primary" v-model="dataInicio" @input="menuDataInicio = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-select
                class="Form-text__invalid"
                id="termo_aceite_frequencia"
                name="termo_aceite_frequencia"
                item-text="text"
                item-value="value"
                v-model="termo.frequencia"
                :items="frequencias"
                :rules="[rules.required]"
                :label="`${$tc('label.frequencia', 1)} *`"
                required
                :disabled="somenteLeitura">
              </v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-select
                class="Form-text__invalid"
                id="termo_aceite_tipo_usuario"
                name="termo_aceite_tipo_usuario"
                item-text="text"
                item-value="value"
                v-model="termo.tipoUsuario"
                :items="tiposUsuario"
                :rules="[rules.required]"
                :label="`${$tc('label.tipo_usuario', 1)} *`"
                required
                disabled>
              </v-select>
            </v-col>

            <v-col cols="12">
              <div v-if="indModoVarejo" v-html="$t('message.variaveis_termo_aceite_varejo')"></div>
              <div v-else v-html="$t('message.variaveis_termo_aceite_industria')"></div>

              <editor v-if="!somenteLeitura" ref="editor"></editor>

              <span v-if="somenteLeitura" v-html="termo.conteudo"></span>
            </v-col>

          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="cancelar">{{ somenteLeitura ? $t('label.voltar') : $t('label.cancelar') }}</v-btn>
          <v-btn @click.native="abrirDialogSalvar" color="primary" v-if="!somenteLeitura">{{ $t('label.salvar') }}</v-btn>
        </v-card-actions>
      </v-card>
      <confirm
        ref="confirmDialog"
        :message="$t('message.deseja_salvar', {text: $t('label.termo_aceite')})"
        :persistent="true"
        @agree="salvar">
      </confirm>
    </v-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { parseDateYYYYMMDD, formatDateDDMMYYYY } from '../../common/functions/date-utils';
import { buscaAutocomplete, triggerSelecao } from '../../common/functions/autocomplete-utils';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import Editor from '../../shared-components/editor/Editor';

export default {
  name: 'TermoAceiteForm',
  components: {
    Confirm,
    Editor,
  },
  props: {
    somenteLeitura: { type: Boolean, default: false },
    criarNovoTermoAceite: { type: Boolean },
  },
  data() {
    return {
      extensaoResources: this.$api.extensao(this.$resource),
      termoAceiteResources: this.$api.termoAceite(this.$resource),

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
      },
      objetivos: [
        { text: this.$t('label.primeiro_acesso'), value: 'PRIMEIRO_ACESSO' },
        { text: this.$t('label.revalidacao'), value: 'REVALIDACAO' },
      ],
      objetivosSemRevalidacao: [
        { text: this.$t('label.primeiro_acesso'), value: 'PRIMEIRO_ACESSO' },
      ],
      frequencias: [
        { text: this.$t('label.sempre'), value: 'SEMPRE' },
        { text: this.$t('label.unica_vez'), value: 'UNICA_VEZ' },
      ],
      tiposUsuario: [
        { text: this.$tc('label.fornecedor', 1), value: 'FORNECEDOR' },
        { text: this.$tc('label.cliente', 1), value: 'CLIENTE' },
      ],
      termo: {
        descricao: '',
        objetivo: undefined,
        frequencia: undefined,
        unidades: [],
        gruposFornecedor: [],
        tipoUsuario: undefined,
        conteudo: '',
      },
      menuDataInicio: false,
      dataInicio: '',
      unidades: [],
      gruposFornecedor: [],
      triggerGrupoFornecedor: null,
      timeoutTrigger: null,
    };
  },
  watch: {
    triggerGrupoFornecedor(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarGruposFornecedores(val);
      }, 500);
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    canAccessPage() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('TERMO_ACEITE')).length;
    },
    dateInicioFormatada: {
      get() {
        return formatDateDDMMYYYY(this.dataInicio);
      },
      set(newValue) {
        this.dataInicio = parseDateYYYYMMDD(newValue);
      },
    },
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    accessForbidden() {
      this.$router.push({ name: 'inicio' });
      this.$toast(this.$t('message.acesso_negado'));
    },
    buscarUnidades() {
      const value = {
        tamanhoPagina: 1000,
        idNivelExtensao: 1,
      };
      return this.extensaoResources.listarPorUsuario(value).then((resultado) => {
        this.unidades = resultado.data.resposta;
      });
    },
    buscarGruposFornecedores(filtro = null) {
      const value = {
        tamanhoPagina: 5000,
        idNivelExtensao: 3,
        nome: filtro,
      };
      return this.extensaoResources.listarAtivos(value).then((resultado) => {
        this.gruposFornecedor = resultado.data.resposta;
      });
    },
    abrirDialogSalvar() {
      if (!this.$refs.form.validate()) return;
      const texto = this.$refs.editor.getTexto();
      if (!texto || texto === '' || texto === ' ') {
        this.$toast(this.$t('message.insira_conteudo'));
        return;
      }
      this.$refs.confirmDialog.open();
    },
    salvar() {
      this.$refs.confirmDialog.close();
      this.termo.idsExtensoes = [];
      if (this.indModoVarejo) {
        this.termo.idsExtensoes = this.termo.idsExtensoes
          .concat(this.termo.gruposFornecedor.map((el) => el.id));
      } else {
        this.termo.idsExtensoes = this.termo.idsExtensoes
          .concat(this.termo.unidades.map((el) => el.id));
      }
      this.termo.dataInicio = this.dataInicio;
      this.termo.conteudo = this.$refs.editor.getTexto();

      if (this.termo.id) {
        this.termoAceiteResources.atualizar(this.termo)
          .then(() => {
            this.$router.push({ name: 'termo-aceite' });
            this.$toast(this.$t('message.atualizado_confira_tabela'));
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      } else {
        this.termoAceiteResources.gravar(this.termo)
          .then(() => {
            this.$router.push({ name: 'termo-aceite' });
            this.$toast(this.$t('message.adicionado_confira_tabela'));
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      }
    },
    cancelar() {
      this.$router.push({ name: 'termo-aceite' });
    },
    carregarTermo(id) {
      this.termoAceiteResources.buscar({ id })
        .then((res) => {
          this.termo = { ...this.termo, ...res.body };
          this.dataInicio = this.termo.dataInicio;
          if (this.indModoVarejo) {
            this.termo.gruposFornecedor = [];
            if (this.termo.idsExtensoes && this.termo.idsExtensoes.length) {
              this.gruposFornecedor.forEach((gf) => {
                if (this.termo.idsExtensoes.indexOf(gf.id) !== -1) {
                  this.termo.gruposFornecedor.push(gf);
                }
              });
            }
          } else {
            this.termo.unidades = [];
            this.unidades.forEach((u) => {
              if (this.termo.idsExtensoes.indexOf(u.id) !== -1) {
                this.termo.unidades.push(u);
              }
            });
          }
          window.setTimeout(() => {
            this.$refs.editor.setTexto(this.termo.conteudo);
          }, 1000);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    if (this.indModoVarejo) {
      this.termo.tipoUsuario = 'FORNECEDOR';
      this.termo.objetivo = 'PRIMEIRO_ACESSO';
      this.buscarGruposFornecedores().then(() => {
        const { id } = this.$route.params;
        if (id) {
          this.carregarTermo(id);
        }
      });
    } else {
      this.termo.tipoUsuario = 'CLIENTE';
      this.termo.objetivo = 'PRIMEIRO_ACESSO';
      this.buscarUnidades().then(() => {
        const { id } = this.$route.params;
        if (id) {
          this.carregarTermo(id);
        }
      });
    }
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
};
</script>
