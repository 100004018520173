<template>
  <v-form ref="formRateio"
          v-model="formRateioValido"
          lazy-validation>
    <div class="PlanejamentoAcaoFormRateio">
      <planejamento-acao-form-resumo
        v-if="objetoPassoGeral"
        :exibe-cliente-pagador="exibeClientePagador"
        :exibe-fornecedor-pagador="exibeFornecedorPagador"
        :objeto-passo-geral="objetoPassoGeral"
        :ordenacao-formulario="ordenacaoFormulario"
        :somenteLeitura="somenteLeitura"
        ref="resumo">
        <div class="card-title" slot="titulo-acao">
          {{ $t('label.criacao_acao')}} :: {{ tituloTipoAcao }} :: {{$tc('title.resumo', 1)}}
        </div>
      </planejamento-acao-form-resumo>

      <div class="mt-2 mb-2 title-float accent--text">
        {{$tc('label.pagamento', 1)}}
      </div>

       <v-card v-if="isFocoNomeFantasia() && false">
          <v-container fluid grid-list-md>
            <v-row>
              <v-col cols="12" md="4" class="d-flex align-center">
                <span class="info-calcular-rateio"> {{$t('message.clique_calcular_pagamento_adama')}}</span>
              </v-col>
              <v-col cols="12" md="3" class="d-flex align-center">
                <v-btn @click="confirmaCalcularRateios"
                  :disabled="!isStatusEmCadastro()">
                  {{$t('label.calcular_pagamento_adama')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

         <v-card class="container-rateios">
           <v-card-title>
             <span class="headline">{{ $t('label.canal_adama') }}</span>
           </v-card-title>
           <v-card-subtitle>
             <span class="headline PlanejamentoAcaoFormRateio__Subtitle">{{ $t('subtitle.preencher_pagamento') }}</span>
           </v-card-subtitle>
          <v-container fluid grid-list-md>
            <v-row>
             <v-col cols="12" md="3">
                <input-money
                  :label="$tc('label.valor_acao_pagamento', 1)"
                  v-model="formulario.valorTotal"
                  :disabled="true"/>
              </v-col>
              <v-col cols="12" md="3">
                <input-money
                  :label="$t('label.pendente_distribuicao_rateio_adama')"
                  v-model="formulario.pendente"
                  v-bind:class="{
                    'PlanejamentoAcaoFormRateio__valor-negativo': (formulario.pendente < 0)
                  }"
                  :disabled="true"/>
              </v-col>
              </v-row>
              <v-col cols="12">
              <div class="div-table">
              <planejamento-acao-form-rateio-tabela
                   :somenteLeitura="!isFocoNomeFantasia() || !isStatusEmCadastro() "
                   @RateioProporcionalTabela_atualizaSaldoPendente="atualizaSaldoPendente"
                   :rateios="rateiosProporcional">
              </planejamento-acao-form-rateio-tabela>
              </div>
            </v-col>
          </v-container>
        </v-card>
        <confirm
        ref="confirmaCalcularRateiosDialog"
        :message="$t('message.confirma_calcular_pagamento_adama')"
        :persistent="true"
        @agree="calcularRateios"/>
        <confirm
        ref="confirmaAlteradoFocoAcaoRateioDialog"
        :message="$t('message.valor_redistruicao_rateio_proporcional_adama')"
        :persistent="true"
        @agree="irProximoPasso"/>

    </div>
  </v-form>
</template>

<script>
import PlanejamentoAcaoFormResumo from '@/produto/spa/planejamento-acao/form/PlanejamentoAcaoFormResumo';
import PlanejamentoAcaoFormPassosDinamicosMixin from '@/produto/spa/planejamento-acao/form/passos-dinamicos/PlanejamentoAcaoFormPassosDinamicosMixin';
import PlanejamentoAcaoFormRateioTabela from './PlanejamentoAcaoFormRateioTabela';
import Confirm from '../../../produto/shared-components/vuetify/dialog/Confirm';
import InputMoney from '../../../produto/shared-components/inputs/InputMoney';

export default {
  name: 'PlanejamentoAcaoFormRateio',
  components: {
    PlanejamentoAcaoFormRateioTabela, PlanejamentoAcaoFormResumo, Confirm, InputMoney,
  },
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acaoEdicao: Object,
    edicao: Boolean,
  },
  mixins: [PlanejamentoAcaoFormPassosDinamicosMixin],
  data() {
    return {
      planejamentoAcaoPreviaResource: this.$api.planejamentoAcaoPrevia(this.$resource),
      rateioProporcionalResource: this.$api.rateioProporcional(this.$resource),
      clienteResource: this.$api.cliente(this.$resource),
      rateiosProporcional: [],
      formRateioValido: false,
      alteradoFocoAcao: false,
      eventoRateio: null,
      formulario: {
        valorTotal: 0,
        pendente: 0,
      },

    };
  },
  methods: {
    confirmaCalcularRateios() {
      this.$refs.confirmaCalcularRateiosDialog.open();
    },
    calcularRateios() {
      this.rateiosProporcional = this.rateiosProporcional.map((e) => {
        e.valorRateioOriginal = parseFloat(e.proporcao > 0 ? ((e.proporcao / 100).toFixed(2) * this.objetoPassoGeral.valor).toFixed(2) : 0);
        e.valorRateio = e.valorRateioOriginal;
        return e;
      });
      this.calcularSobraRateio();
      this.calcularValorPendente();
    },
    calcularSobraRateio() {
      if (this.rateiosProporcional.length === 0) {
        return;
      }

      let valorTotalRateado = 0.0;
      let indiceMenorRateio = 0;
      let menorRateio = null;
      for (let i = 0; i < this.rateiosProporcional.length; i += 1) {
        const rateio = this.rateiosProporcional[i];
        if (rateio.valorRateioOriginal > 0) {
          valorTotalRateado += rateio.valorRateioOriginal;

          if (menorRateio == null) {
            indiceMenorRateio = i;
            menorRateio = rateio;
          } else if (rateio.valorRateioOriginal < menorRateio.valorRateioOriginal) {
            indiceMenorRateio = i;
            menorRateio = rateio;
          }
        }
      }
      const valorSobraRateio = this.objetoPassoContas.valorTotal - valorTotalRateado.toFixed(2);
      if (valorSobraRateio > 0) {
        this.rateiosProporcional[indiceMenorRateio].valorRateioOriginal += valorSobraRateio;
        this.rateiosProporcional[indiceMenorRateio].valorRateio += valorSobraRateio;
      }
    },
    calcularValorPendente() {
      this.formulario.pendente = this.formulario.valorTotal - this.rateiosProporcional.map((i) => i.valorRateio)
        .reduce((p, a) => p + a, 0);
    },
    buscarRateios() {
      const idsContasCorrente = this.getContasCorrentes();
      if (idsContasCorrente[0].length < 1) {
        this.$toast(this.$t('message.contas_corrente_nao_selecionadas_para_rateio'));
        return;
      }
      const param = {
        idNomeFantasia: (this.objetoPassoGeral.holding || {}).id,
        idsContasCorrente: idsContasCorrente[0],
        idDivisao: (this.objetoPassoGeral.divisao || {}).id,
      };
      this.rateioProporcionalResource.buscarRateiosProporcional(param)
        .then((res) => {
          this.rateiosProporcional = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarRateiosCliente(idAcao) {
      const params = {
        idAcao,
        idDivisao: (this.objetoPassoGeral.divisao || {}).id,
      };
      this.rateioProporcionalResource.buscarRateiosProporcionalCliente(params)
        .then((res) => {
          this.rateiosProporcional = res.data;
          this.calcularValorPendente();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    preencherObjetoFormatado(acao) {
      this.preencherPassoDinamicoFormatado(acao, 4, 'passoRateioCliente', this.getObjetoFormatado());
    },
    getObjetoFormatado() {
      return {
        rateios: this.rateiosProporcional,
      };
    },
    getContasCorrentes() {
      return this.objetoPassoContas.reservas.map((e) => e.idsContasCorrente);
    },
    atualizaSaldoPendente(valor) {
      const total = this.formulario.pendente;
      this.formulario.pendente = total - valor;
    },
    valida(isSolicitandoAprovacao) {
      if (!this.$refs.formRateio.validate()) {
        return false;
      }
      if (isSolicitandoAprovacao && this.formulario.pendente !== 0) {
        this.$toast(this.$t('message.valor_acao_nao_distribuido_rateio_adama'));
        return false;
      }
      return true;
    },
    validaSalvar(isSolicitandoAprovacao = false) {
      return this.valida(isSolicitandoAprovacao);
    },
    onEvent(evento) {
      if (evento.ehEvento('focoAlterado')) {
        this.alteradoFocoAcao = true;
      }

      if (evento.ehEvento('antesIrProximo') && evento.objeto.proximo === 4 && this.alteradoFocoAcao) {
        this.preencherPassosGeral();
        if (this.objetoPassoGeral.holding && this.edicao) {
          this.eventoRateio = evento;
          evento.desabilitarExecucaoDoneAcaoForm();
          this.$refs.confirmaAlteradoFocoAcaoRateioDialog.open();
        }
      }
    },
    irProximoPasso() {
      this.eventoRateio.executarDone();
    },
    abrePasso() {
      this.preencherPassosContas();
      this.montarPasso4();
    },
    montarPasso4() {
      if (!this.alteradoFocoAcao) {
        this.preencherPassosGeral();
      }
      this.formulario.valorTotal = this.objetoPassoGeral.valor;
      if (this.isNovaAcaoHoldingRateio() || this.isEdicaoFocoAlteradoHolding()) {
        this.buscarRateios();
        this.formulario.pendente = this.objetoPassoGeral.valor;
      }
      if (this.isEdicaoFocoNaoAlterado()) {
        this.buscarRateiosCliente(this.acaoEdicao.id);
      }

      if (this.objetoPassoGeral.cliente) {
        this.getObjetoClienteConta();
        this.formulario.pendente = 0;
      }
    },
    getObjetoClienteConta() {
      const params = {
        id: this.objetoPassoGeral.cliente.id,
      };
      this.clienteResource.buscar(params)
        .then((res) => {
          this.rateiosProporcional = [];
          const cliente = res.data;
          this.rateiosProporcional.push({
            idExterno: cliente.idExterno,
            nomCliente: cliente.nomCliente,
            desCnpj: cliente.desCnpj,
            valorRateioOriginal: this.formulario.valorTotal,
            valorRateio: this.formulario.valorTotal,
            valorTotalGeral: this.formulario.valorTotal,
            valorTotalCliente: this.formulario.valorTotal,
            proporcao: 0,
            idCliente: cliente.id,
          });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    isNovaAcaoHoldingRateio() {
      return this.novaAcao && !!this.objetoPassoGeral.holding;
    },
    isEdicaoFocoAlteradoHolding() {
      return !this.novaAcao && !!this.objetoPassoGeral.holding && this.alteradoFocoAcao;
    },
    isEdicaoFocoNaoAlterado() {
      return !this.novaAcao && !this.alteradoFocoAcao;
    },
    isFocoNomeFantasia() {
      return !!this.objetoPassoGeral && !!this.objetoPassoGeral.holding;
    },
    isStatusEmCadastro() {
      return !!this.objetoPassoGeral && this.objetoPassoGeral.status === 'Em cadastro';
    },
  },
};
</script>
<style>
.PlanejamentoAcaoFormRateio .title-float {
  font-weight: 400;
  font-size: 20px;
}
.PlanejamentoAcaoFormRateio .container-rateios{
   margin-top:15px;
  }
.PlanejamentoAcaoFormRateio .div-table {
   max-height: 300px;
   overflow : auto;
  }
.PlanejamentoAcaoFormRateio .info-calcular-rateio {
   font-size: 14px;
   color: blue;
  }
.PlanejamentoAcaoFormRateio__valor-negativo,
.PlanejamentoAcaoFormRateio__valor-negativo input.v-money {
  color: #ff0000ba;
}
.PlanejamentoAcaoFormRateio__Subtitle {
  color: rgba(0, 0, 0, 0.87) !important;
  background: #70c777;
}
</style>
