import getBasePath from '../functions/api-resource';

const basePath = getBasePath('orcamento', 'ajuste_verba_reducao_massiva');

const actions = {
  buscarTiposVerba: {
    method: 'GET',
    url: `${basePath}/tipo_verba`,
  },
  buscarOrcamentos: {
    method: 'GET',
    url: `${basePath}/tipo_verba/{idTipoVerba}/orcamento`,
  },
  buscarPeriodosOrcamentarios: {
    method: 'GET',
    url: `${basePath}/tipo_verba/{idTipoVerba}/orcamento/{idOrcamento}/periodo_orcamentario`,
  },
  buscarPlanejadoSaldoPeriodo: {
    method: 'GET',
    url: `${basePath}/tipo_verba/{idTipoVerba}/orcamento/{idOrcamento}/periodo_orcamentario/{idPeriodo}/planejado_saldo`,
  },
  buscarLinhasOrcamento: {
    method: 'GET',
    url: `${basePath}/tipo_verba/{idTipoVerba}/orcamento/{idOrcamento}/periodo_orcamentario/{idPeriodo}/linhas`,
  },
  carregarLinhasQuePossuemFluxoReducaoEmAndamento: {
    method: 'GET',
    url: `${basePath}/linhas_com_fluxo_andamento?tamanhoPagina={size}&numeroPagina={page}`,
  },
  buscarTiposLinha: {
    method: 'GET',
    url: `${basePath}/tipo_verba/{idTipoVerba}/tipo_linha`,
  },
  verificaFluxo: {
    method: 'GET',
    url: `${basePath}/tipo_verba/{idTipoVerba}/fluxo`,
  },
  salvar: {
    method: 'POST',
    url: `${basePath}`,
  },
  atualizar: {
    method: 'PUT',
    url: `${basePath}`,
  },
  finalizar: {
    method: 'PUT',
    url: `${basePath}/{idAjuste}`,
  },
  carregar: {
    method: 'GET',
    url: `${basePath}/{idAjuste}`,
  },
};

export default (resource) => resource(`${basePath}`, {}, actions);
