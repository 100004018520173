export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      extensaoAdama: this.$api.extensaoAdama(this.$resource),
      exportacaoAcaoPagamentoResource: this.$api.exportacaoAcaoPagamento(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),

      camposFormulario: {
        padrao: [
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            desAtributos: {
              textoItem: 'texto',
              valorItem: 'valor',
              lista: [
                {
                  texto: this.$t('status_entidade_pagamento.aguardando_pagamento'),
                  valor: 'AGUARDANDO_PAGAMENTO',
                },
                {
                  texto: this.$tc('status_entidade_pagamento.aprovado', 1),
                  valor: 'APROVADO',
                },
                {
                  texto: this.$tc('status_entidade_pagamento.programado', 1),
                  valor: 'PROGRAMADO',
                },
                {
                  texto: this.$tc('status_entidade_pagamento.liquidado', 1),
                  valor: 'LIQUIDADO',
                },
                {
                  texto: this.$t('status_entidade_pagamento.bloqueado'),
                  valor: 'BLOQUEADO',
                },
                {
                  texto: this.$t('status_entidade_pagamento.cancelado'),
                  valor: 'CANCELADO',
                },
              ],
            },
          },
          {
            labelCampo: 'data_inicio',
            nomCampo: this.$t('label.data_inicio'),
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim',
            nomCampo: this.$t('label.data_fim'),
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'tipo_verba',
            nomCampoId: 'ids_tipo_verba',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.orcamentoTipoVerbaResource
                .buscarAtivos({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'periodo_orcamentario',
            nomCampoId: 'ids_periodo_orcamentario',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              dependencia: 'periodo',
            },
            async: {
              fetchFunction: () => this.exportacaoAcaoPagamentoResource.listarPeriodosOrcamentarios(),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'tipo_linha_investimento',
            nomCampoId: 'ids_tipo_investimento',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => this.exportacaoAcaoPagamentoResource.listarTiposInvestimentos(),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'data_inicio_pagamento',
            nomCampo: this.$t('label.data_inicio_pagamento'),
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim_pagamento',
            nomCampo: this.$t('label.data_fim_pagamento'),
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'unidadenegocio',
            nomCampoId: 'ids_unidadenegocio',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarUnidadesNegocioEntidadesControlador({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'divisao_adama',
            nomCampoId: 'ids_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarDivisoesPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'ids_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => this.exportacaoAcaoPagamentoResource.buscarTiposAcoesAtivos(),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'tipo_pagamento',
            nomCampoId: 'tipo_pagamento',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => this.exportacaoAcaoPagamentoResource.listarTiposPagamentos(),
              itemValue: 'codTipo',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'nome_fantasia',
            nomCampoId: 'ids_nome_fantasia',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              // eslint-disable-next-line no-return-assign
              fetchFunction: (autocomplete) => this.extensaoAdama.buscarNomesFantasiaRelatorio({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
        ],
      },
    };
  },
};
