<template>
  <v-dialog v-model="dialog" width="800px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $tc('title.inclusao_divisao_rateio', 1) }}</span>
      </v-card-title>
      <v-card-text>
        <v-expansion-panel expand v-model="panel" v-for="(item,i) in lista" :key="i">
          <v-expansion-panel-header>
            {{item.linha.descricao}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="item.divisoes"
                  hide-default-footer
                  disable-pagination
                  :items-per-page="-1"
                  class="elevation-1">
                  <template v-slot:item.novaDivisao="{ item }">
                    {{ item.nome }}
                  </template>
                  <template v-slot:item.divisaoSuperior="{ item }">
                    {{ item.nomeDivisaoPai }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="dialog = false">
          {{ $t('label.cancelar') }}
        </v-btn>

        <v-btn color="primary" @click="adicionarNovasDivisoes">
          {{ $t('label.aplicar') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ModalRateioNovaDivisao',
  props: {
    lista: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      panel: [],
      headers: [
        { text: this.$t('label.nova_divisao'), value: 'novaDivisao', sortable: false },
        { text: this.$tc('label.divisao_superior', 1), value: 'divisaoSuperior', sortable: false },
      ],
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.$forceUpdate();
    },
    adicionarNovasDivisoes() {
      this.dialog = false;
      this.$emit('ModalRateioNovaDivisao_adicionar');
    },
  },
};
</script>
