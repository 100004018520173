<template lang="html">
  <v-app-bar dark app color="primary" elevate-on-scroll clipped-right clipped-left>
    <v-btn
    color="#bf5c9c"
    rounded
        @click="toggleSidenav">
        <v-icon>reorder</v-icon>
        Menu
     </v-btn>

    <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>

    <v-toolbar-title class="white--text" style="padding-left: 0;">
      <span v-if="$vuetify.breakpoint.mdAndUp">
        {{ title === 'Tradelinks' ? '' : `${String.fromCharCode(160)} ${title}` }}
      </span>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <calendario-toolbar></calendario-toolbar>
    <badge
      @RESET_SIDEBAR="resetSidebar">
    </badge>
    <usuario-toolbar
    :usuario="usuario"
    :mail="mail"
    :estrutura-usuario="estruturaUsuario"
    :perfil="perfil"/>
    <v-btn
      icon
      v-for="(option, index) in options"
      :key="index">
      <v-icon>{{ option.icon }}</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script type="text/javascript">
import Badge from '../../produto/shared-components/badge/Badge';
import ManualSistema from '../../produto/shared-components/manual/ManualSistema';
import UsuarioToolbar from '../../produto/shared-components/toolbar/UsuarioToolbar';
import CalendarioToolbar from '../../produto/spa/calendario/CalendarioToolbar';

export default {
  props: {
    hideMenu: {
      type: Boolean,
      default() { return false; },
    },
    title: {
      type: String,
      default() { return ''; },
    },
    options: {
      type: Array,
      default() { return []; },
    },
    usuario: String,
    mail: String,
    estruturaUsuario: String,
    perfil: String,
    state: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      progress: 0,
      progressInterval: null,
      done: false,
      transition: true,
    };
  },
  components: {
    UsuarioToolbar,
    Badge,
    CalendarioToolbar,
    ManualSistema,
  },
  methods: {
    toggleSidenav() {
      this.$emit('TOGGLE_SIDEBAR');
    },
    resetSidebar() {
      this.$emit('RESET_SIDEBAR');
    },
    home() {
      this.resetSidebar();
      this.$router.push({ name: 'dashboard-inicial' });
    },
  },
};
</script>
<style src="../../produto/shared-components/toolbar/Toolbar.scss" lang="scss"></style>
