var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"Container_Nivel2_Recebimento",attrs:{"fluid":"","px-0":"","py-2":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"Barra_Pesquisa",attrs:{"append-icon":"search","label":("" + (_vm.$tc('label.pesquisar', 1))),"single-line":"","hide-details":"","clearable":""},on:{"input":_vm.filtrar},model:{value:(_vm.pesquisaCards),callback:function ($$v) {_vm.pesquisaCards=$$v},expression:"pesquisaCards"}})],1),_c('v-container',{staticClass:"Container_Cards",attrs:{"fluid":"","grid-list-md":""}},[_c('v-data-iterator',{attrs:{"items":_vm.lista,"options":_vm.pagination,"hide-default-footer":12 >= _vm.totalPage,"server-items-length":_vm.totalPage,"footer-props":{
            itemsPerPageOptions: _vm.rowsPerPageItems,
          }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',{staticClass:"mx-0",attrs:{"justify":"start"}},_vm._l((props.items),function(item){return _c('v-col',{key:item.codApuracao,attrs:{"cols":"12","sm":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-card',{staticClass:"clickable Card_Recebimento",class:("elevation-" + (hover ? 5 : 1)),attrs:{"fill-height":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-card-title',{staticClass:"Card_Title pa-1 justify-center font-weight-medium",on:{"click":function($event){return _vm.selectItem(item)}}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_vm._v(_vm._s(item.codAcao)+" - "+_vm._s(item.codApuracao))]),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_vm._v(_vm._s(_vm._f("truncate")(item.titulo,25)))])],1)],1)],1)]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('label.descricao'))+": "+_vm._s(_vm._f("truncate")(item.descricaoAcao,25))),_c('br')])]),_c('v-card-text',{staticClass:"Card_Content py-0 headline font-weight-light",on:{"click":function($event){return _vm.selectItem(item)}}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_vm._v(_vm._s(_vm.getMoney(item.valorRecebimento)))])],1)],1)],1),_c('v-card-actions',{staticClass:"Card_Actions pa-1"},[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-col',{staticStyle:{"text-align":"end"},attrs:{"cols":"12","sm":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({nativeOn:{"click":function($event){return _vm.abrirModalConfirmacao('exportar', item)}}},on),[_vm._v("print")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('label.exportar'))+" "+_vm._s(_vm.$tc('label.nota_debito', 1))+" ")])])],1)],1)],1)],1)]}}],null,true)})],1)}),1)]}},{key:"no-data",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[_vm._v(" "+_vm._s(_vm.$t('label.tabela_sem_conteudo'))+" ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[_vm._v(" "+_vm._s(_vm.$t('label.tabela_sem_conteudo'))+" ")])]},proxy:true}])})],1)],1)],1),_c('confirm',{ref:"modalConfirmacao",attrs:{"message":_vm.mensagemConfirmacao},on:{"agree":_vm.validarAcao}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }