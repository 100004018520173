var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-row',{attrs:{"justify-end":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$t('title.calendario')))])]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-switch',{attrs:{"color":"primary","append-icon":"search","label":_vm.$t('label.eventos_cancelados'),"single-line":"","hide-details":""},on:{"change":_vm.filtrar},model:{value:(_vm.indCancelado),callback:function ($$v) {_vm.indCancelado=$$v},expression:"indCancelado"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","hide-details":""},on:{"input":_vm.filtrar},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{ref:"mes_pesquisa",attrs:{"close-on-content-click":false,"id":"mes_pesquisa","name":"mes_pesquisa","nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({directives:[{name:"mask",rawName:"v-mask",value:('##-####'),expression:"'##-####'"}],staticClass:"CalendarioTabela-data__text",attrs:{"return-masked-value":true,"rules":[_vm.rules.validDate],"label":_vm.$t('label.mes'),"prepend-icon":"event"},model:{value:(_vm.mesPesquisaFormatado),callback:function ($$v) {_vm.mesPesquisaFormatado=$$v},expression:"mesPesquisaFormatado"}},on))]}}]),model:{value:(_vm.menuMesPesquisa),callback:function ($$v) {_vm.menuMesPesquisa=$$v},expression:"menuMesPesquisa"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","color":"primary","type":"month"},on:{"input":function($event){_vm.menuMesPesquisa = false}},model:{value:(_vm.mesPesquisa),callback:function ($$v) {_vm.mesPesquisa=$$v},expression:"mesPesquisa"}})],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.eventos,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
      itemsPerPageOptions: [10, 25, 50],
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.cor",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.cor}},[_vm._v("lens")])]}},{key:"item.nome",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome))]}},{key:"item.descricao",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.descricao))]}},{key:"item.dataInicio",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDate(item.dataInicio)))]}},{key:"item.dataFim",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDate(item.dataFim)))]}},{key:"item.cancelamento",fn:function(ref){
    var item = ref.item;
return [(_vm.dataFimMaiorQueDataAtual(item) && !item.indCancelado)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.cancelarItem(item)}}},on),[_c('v-icon',[_vm._v("cancel")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.cancelar')))])]):_vm._e()]}},{key:"pageText",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" "+_vm._s(_vm.$tc('label.de', 1))+" "+_vm._s(props.itemsLength)+" ")]}}])}),_c('confirm',{ref:"cancelDialog",attrs:{"message":_vm.$t('message.deseja_cancelar_evento'),"persistent":true},on:{"disagree":_vm.cancelarAlteracaoStatus,"agree":_vm.cancelarEvento}}),_c('confirm',{ref:"confirmDialog",attrs:{"message":_vm.$t('message.deseja_ativar_inativar'),"persistent":true},on:{"disagree":_vm.cancelarAlteracaoStatus,"agree":_vm.alterarStatus}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }