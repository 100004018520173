import ApuracaoAcaoDetalhesPagamentosClientes from './ApuracaoAcaoDetalhesPagamentosClientes';

const habilitaPagamentosClientes = (configuracao) => !!((configuracao.passo6
  && configuracao.passo6.liquidacao
  && configuracao.passo6.liquidacao.templateCalculo
  && configuracao.passo6.liquidacao.templateCalculo === 'PROPORCIONAL_POR_CLIENTE_ADAMA'));

export default {
  componentes: {
    ApuracaoAcaoDetalhesPagamentosClientes,
  },
  paineisHabilitados(ref, configuracao, acao) { // eslint-disable-line
    return {
      pagamentosClientes: {
        habilitado: habilitaPagamentosClientes(configuracao, acao),
        label: ref.$tc('label.pagamento', 1),
        iniciaExpandido: false,
        componente: 'apuracao-acao-detalhes-pagamentos-clientes',
      },
    };
  },
};
