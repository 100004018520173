import getBasePath from '../functions/api-resource';

const basePath = getBasePath('orcamento', 'metas');

// id_produto, id_grupo_produto, id_marca, id_categoria e id_unidade_negocio
const alcadaActions = {
  // `${basePath}/calculo/tipo/{SELLIN_VALOR}/cliente/{2}?periodo={BIMESTRAL}&nroPeriodo={2}`
  calculoBase: { method: 'GET', url: `${basePath}/calculo/tipo/{tipo}/idCliente/{cliente}?periodo={periodo}&nro_periodo={nroPeriodo}` },
};

export default (resource) => resource(`${basePath}`, {}, alcadaActions);
