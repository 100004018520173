import Vue from 'vue';
import VueRouter from 'vue-router';

import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { sync } from 'vuex-router-sync';
import App from './produto/App';
import configRouter from './config/router';
import store from './vuex';
import vuetify from './config/vuetify';
import directives from './produto/config/directives';
import Auth, { auth } from './produto/config/sso/auth';
import i18n from './produto/config/i18n';
import interceptors from './produto/config/interceptors';
import plugins from './config/plugins';
import dicionario from './produto/common/functions/dicionario';

// Forçando o jQuery global
window.jQuery = require("jquery"); // eslint-disable-line
window.$ = window.jQuery; // eslint-disable-line
window.$logger = console;

plugins(Vue, VueRouter);
directives(Vue);
interceptors(Vue);

Vue.config.productionTip = false;

Vue.config.ignoredElements = [ // CHANGE
  'angular-gauge-chart',
  'bar-chart-3d',
  'clustered-chart',
  'column-chart',
  'data-grid',
  'line-chart',
  'lined-bar-chart',
  'pie-chart',
  'sbx-dashboard',
];

// Verifica autenticacao no sso
auth.loginRequired().then(dicionario.carregar).then((resDicionario) => {
  const router = configRouter(VueRouter);
  // Sincronização entre rotas e vuex.
  // Garante o estado da store em todas as páginas SPA.
  sync(store, router);

  const i18nData = i18n(Vue, resDicionario);
  i18nData.locale = 'pt-br';

  new Vue({
    router,
    store,
    vuetify,
    i18n: i18nData,
    render: (h) => h(App),
    created: () => {
      // Cria os interceptors para as requisicoes dos servicos
      auth.createInterceptors();
      // Registra o plugin $auth com as informacoes de sessao com o keycloak
      Vue.use(Auth);
    },
  }).$mount('#app');
}).catch((e) => {
  if (e.status && e.message && e.status === 403) {
    window.alert(e.message); // eslint-disable-line
  }
});
