<template>
  <v-card style="margin-bottom:1em" class="PlanejamentoContratoFiltros">
    <v-card-title>
      <span class="title">{{ $tc('title.filtro', 2) }}</span>

      <v-radio-group class="PlanejamentoContratoFiltros__Opcoes"
        v-model="tipoFiltro"
        v-show="filtrosAbertos"
        row>
        <v-radio :label="$tc('label.em_comum', 1)" value="comum"></v-radio>
        <v-radio :label="$t('label.todos')" value="todos"></v-radio>
      </v-radio-group>

      <v-spacer></v-spacer>
      <v-icon v-show="filtrosAbertos"
        @click="fixaFiltros"
        :class="filtrosFixosClass">
        fa-thumbtack
      </v-icon>
      <v-icon @click="toggleFiltros">filter_list</v-icon>
    </v-card-title>

    <v-container fluid grid-list-md v-show="filtrosAbertos">
      <metadados-container-layout
        v-if="metadadosPlanejamentoContrato != null && carregouCampos"
        :metadados-entidade="metadadosPlanejamentoContrato"
        :ordenacao-campos="ordenacaoSelecionada"
        :campos-formulario="camposFormulario"
        :explode-hierarquia="true"
        :formulario-filtros="true"
        :ignora-obrigatorios="true"
        :layout-class="layoutClass"
        :input-layout="inputLayout"
        :objeto="filtrosPlanejamentoContrato"
        ref="container">
      </metadados-container-layout>
    </v-container>
    <v-card-actions v-show="filtrosAbertos">
      <v-spacer></v-spacer>
      <v-btn @click="resetaFiltros"
        color="accent"
      >{{$t('label.limpar_filtros')}}</v-btn>
      <v-btn @click="aplicarFiltros"
        color="primary"
      >{{$t('label.filtrar')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import { skipLoading } from '../../../common/functions/loading';
import { copyObject } from '../../../common/functions/helpers';

import MetadadosContainerLayout from '../../../shared-components/metadados/MetadadosContainerLayout';
import PlanejamentoContratoListFiltrosCampos from './PlanejamentoContratoListFiltrosCampos';

export default {
  name: 'PlanejamentoContratoFiltros',
  mixins: [
    PlanejamentoContratoListFiltrosCampos,
  ],
  components: {
    MetadadosContainerLayout,
  },
  computed: {
    ...mapGetters('metadados', [
      'getContratoMetadado',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
    ordenacaoSelecionada() {
      return this.ordenacaoCampos[this.tipoFiltro] || [];
    },
    usuarioCliente() {
      return this.usuarioLogado.tipo === 'CLIENTE';
    },
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      configuracaoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),

      filtrosAbertos: false,
      filtrosFixados: false,
      carregouCampos: false,

      metadadosPlanejamentoContrato: null,

      ordenacaoCampos: {},
      tipoFiltro: 'comum',

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      filtrosPlanejamentoContrato: {},
      workspace: {},
      tiposDeInvestimento: null,

      entidadeWorkspace: 'planejamento_contrato',
    };
  },
  methods: {
    carregarWorkspaceFiltros() {
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          this.filtrosFixados = this.workspace.indAberto || false;
          this.filtrosAbertos = this.filtrosFixados;
          this.filtrosPlanejamentoContrato = this.workspace.filtros || {};

          this.unidadesNegocioDivisao = [this.filtrosPlanejamentoContrato.unidadeNegocio];

          this.tipoFiltro = this.filtrosPlanejamentoContrato.tipoFiltro || 'comum';

          setTimeout(() => this.restaurarFiltros());
        }).catch((err) => {
          this.$error(this, err);
        }).finally(() => {
          this.carregouCampos = true;
        });
    },
    toggleFiltros() {
      this.filtrosAbertos = !this.filtrosAbertos;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    resetaFiltros() {
      this.filtrosPlanejamentoContrato = {};
      this.$refs.container.refresh();
      setTimeout(() => this.aplicarFiltros());
    },
    restaurarFiltros() {
      const parametros = {
        ...this.$refs.container.getValoresDependencias(),
        ...this.$refs.container.getValoresCamposPadrao(),
        ...this.$refs.container.getValoresCamposDinamicos(),
      };
      this.$emit('PlanejamentoContratoFiltros__AplicaFiltros', parametros);
    },
    aplicarFiltros() {
      this.restaurarFiltros();
      this.salvarFiltrosWorkspace();
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosPlanejamentoContrato;
      this.workspace.filtros.tipoFiltro = this.tipoFiltro;

      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    habilitaCamposExtra() {
      const chaves = Object.keys(this.ordenacaoCampos);
      chaves.forEach((chave) => {
        const campos = this.ordenacaoCampos[chave];

        const indicePeriodo = campos.indexOf('periodo');
        const indiceReferencial = indicePeriodo + 1;
        if (indicePeriodo >= 0) {
          campos.splice(indiceReferencial, 0, 'data_inicio');
          campos.splice(indiceReferencial + 1, 0, 'data_fim');
        } else {
          const indiceDataInicio = campos.indexOf('data_inicio');
          const indiceDataFim = campos.indexOf('data_fim');
          if (indiceDataInicio >= 0) {
            if (indiceDataFim >= 0) {
              campos.splice(indiceDataFim, 1);
            }
            campos.splice(indiceDataInicio + 1, 0, 'data_fim');
          }
        }

        if (chave === 'todos') {
          const indiceCampoCliente = campos.indexOf('campo_cliente');
          if (indiceCampoCliente >= 0) {
            this.configuracaoResource
              .buscarLabelsCampoCliente()
              .then((res) => {
                res.data.forEach((campo, index) => {
                  campos.splice(indiceCampoCliente + index, 0, campo.label);
                });
              }).catch((err) => {
                this.$error(this, err);
              });
          }
        }

        campos.splice(0, 0, 'tipo_acao');
      });
    },
    carregaCamposFiltros() {
      this.configuracaoResource
        .buscarCamposFiltro()
        .then((res) => {
          this.ordenacaoCampos = res.data;
          this.habilitaCamposExtra();
          this.carregarWorkspaceFiltros();
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    preencheHoldings() {
      this.configuracaoResource
        .buscarHoldingsCliente()
        .then((res) => {
          if (this.filtrosPlanejamentoContrato.holding === null
                || this.filtrosPlanejamentoContrato.holding === undefined) {
            this.filtrosPlanejamentoContrato.holding = res.body;
            this.$refs.container.refresh();
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    const metadados = this.getContratoMetadado;
    this.metadadosPlanejamentoContrato = copyObject(metadados);
    this.carregaCamposFiltros();
  },
};
</script>
<style>
  .PlanejamentoContratoFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .PlanejamentoContratoFiltros__Opcoes div.v-input__slot,
  .PlanejamentoContratoFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .PlanejamentoContratoFiltros__Opcoes div.v-messages {
    display: none;
  }
  .PlanejamentoContratoFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
