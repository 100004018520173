var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticStyle:{"width":"100%"}},[_c('v-card-title',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$tc('label.nota_debito', 2)))])]),_c('v-data-table',{staticClass:"tableNotasDebito",attrs:{"headers":_vm.headers,"items":_vm.notas,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
        itemsPerPageOptions: [10, 25, 50],
      }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","id":"more_vert","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.detalhesApuracao(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("details")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$tc('label.detalhe', 2))+" ")])],1),(_vm.isNotaIntegrada(item))?_c('v-list-item',{on:{"click":function($event){return _vm.imprimirNotas(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("print")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('label.imprimir'))+" ")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.exportarNotas(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("get_app")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('label.exportar'))+" ")])],1),(_vm.canReenviarNotaDebito && _vm.notaComErro(item))?_c('v-list-item',{on:{"click":function($event){return _vm.modalConfirmacaoReenviar(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("replay")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('label.reenviar'))+" ")])],1):_vm._e()],1)],1)]}},{key:"item.cod_nota_debito",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.numero)+" ")])]}},{key:"item.statusIntegracao",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$tc(("status_integracao." + (item.statusIntegracao)), 1))+" "),(_vm.notaComErroOuPendenteIntegracao(item))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":""}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("info")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.formataMensagemDeResposta(item.respostaIntegracao)))])]):_vm._e()],1)]}},{key:"item.cnpj",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.cnpj)+" ")])]}},{key:"item.valor_base",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.getMoney(item.valorBase))+" ")])]}},{key:"item.recebimento",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.getMoney(item.recebimento))+" ")])]}},{key:"item.data_vencimento",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.dtaVencimento)+" ")])]}},{key:"item.dataContabilizacao",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.dataContabilizacao)+" ")])]}}])})],1),_c('confirm',{ref:"modalConfirmacaoReenvio",attrs:{"message":_vm.$t('message.reenviar_novamente_nota_debito')},on:{"agree":_vm.reenviaNotaParaIntegracao,"disagree":function($event){return _vm.$refs.modalConfirmacaoReenvio.close()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }