import { buscarProdutosSelecao } from '../../common/resources/produto';

export default {
  data() {
    return {
      anoFiscalResource: this.$api.anoFiscal(this.$resource),
      tipoInvestimentoResource: this.$api.tipoInvestimento(this.$resource),
      centroCustoResource: this.$api.centroCusto(this.$resource),
      clienteResource: this.$api.cliente(this.$resource),
      orcamentoListagemResource: this.$api.orcamentoListagem(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      statusOrcamento: [
        {
          texto: this.$t('status_entidade.em_cadastro'),
          valor: 'EM_CADASTRO',
        },
        {
          texto: this.$t('status_entidade.aguardando_aprovacao'),
          valor: 'AGUARDANDO_APROVACAO',
        },
        {
          texto: this.$tc('status_entidade.aprovado', 1),
          valor: 'APROVADO',
        },
        {
          texto: this.$tc('status_entidade.reprovado', 1),
          valor: 'REPROVADO',
        },
      ],
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'data_inicio',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'descricao',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'tipo_investimento',
            nomCampoId: 'id_tipo_investimento',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.tipoInvestimentoResource
                .selecao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'verba',
            nomCampoId: 'verba',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.orcamentoListagemResource
                .buscarTags({ autocomplete }),
              itemValue: 'tags',
              itemText: 'tags',
            },
          },
          {
            labelCampo: 'status',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: this.statusOrcamento,
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'periodo',
            nomCampoId: 'id_periodo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              dependentes: ['periodo_orcamentario'],
            },
            async: {
              fetchFunction: (autocomplete) => this.anoFiscalResource.buscar({ autocomplete }),
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'periodo_orcamentario',
            nomCampoId: 'id_periodo_orcamentario',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            desAtributos: {
              dependencia: 'periodo',
            },
            async: {
              fetchFunction: (autocomplete) => {
                const periodos = this.filtrosOrcamento.periodo || [];
                const idsAnoFiscal = periodos.map((p) => p.id);
                const tipo = 'PLANEJAMENTO_ORCAMENTARIO';
                return this.anoFiscalResource.buscarPeriodosAnoFiscal({
                  idsAnoFiscal,
                  tipo,
                  autocomplete,
                });
              },
              itemValue: 'id',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'centro_custo',
            nomCampoId: 'id_centro_custo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresDependencias(),
                };
                return this.centroCustoResource.selecao(parametros);
              },
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'cliente',
            nomCampoId: 'id_cliente',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresDependencias(),
                };
                return this.clienteResource.selecao(parametros);
              },
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'produto',
            nomCampoId: 'id_produto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.container.getValoresDependencias(),
                };
                return buscarProdutosSelecao(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'codigoNome',
            },
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource
                .buscarDivisoesPorVisao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
        ],
      },
    };
  },
};
