var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"tabindex":"100"}},[_c('v-card',{staticStyle:{"min-height":"83vh"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-container',[_c('v-row',{attrs:{"justify-end":""}},[_c('v-col',{staticClass:"mr-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.registros,"search":_vm.search,"items-per-page":5,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{itemsPerPageText: _vm.$t('label.linhas_por_pagina')}},scopedSlots:_vm._u([{key:"item.desImportacao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.desImportacao)+" ")]}},{key:"item.nomArquivoOrigem",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomArquivoOrigem)+" ")]}},{key:"item.dtaCriacao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dtaCriacao)+" ")]}},(_vm.isErros)?{key:"item.desResultado",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogErros(item)}}},on),[_c('v-icon',[_vm._v("visibility")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.visualizar_detalhes')))])])]}}:null],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }