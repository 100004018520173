<template lang="html">
  <div>
    <v-form ref="formLiquidacao" lazy-validation autocomplete="off">
      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('title.operacao', 2) }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.liquidacao')}`" />
      </v-card-title>
      <v-row class="mx-4 mt-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_liquidacao_parcial"
            :label="`${$t('message.habilitar_liquidacao_parcial')}`"
            v-model="parametrizacao.liquidacao.indLiquidacaoParcial" />
        </v-col>
      </v-row>

      <v-card-title primary-title class="card-header mt-4">
        {{ $t('title.integracao_software_terceiro') }}
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_liquidacao_integracao_envio"
            :label="`${modoVarejo ? $t('message.habilitar_integracao_envio_recebimento') : $t('message.habilitar_integracao_envio_pagamento')}`"
            v-model="parametrizacao.liquidacao.indIntegracaoEnvio" />
        </v-col>
      </v-row>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_liquidacao_integracao_retorno"
            :label="`${modoVarejo ? $t('message.habilitar_integracao_retorno_recebimento') : $t('message.habilitar_integracao_retorno_pagamento')}`"
            v-model="parametrizacao.liquidacao.indIntegracaoRetorno" />
        </v-col>
      </v-row>

      <v-card-title primary-title class="card-header mt-4">
        {{  `${$t('title.liquidacao')} *` }}
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_liquidacao_unica_liquidacao"
            :label="$t('label.unica_liquidacao')"
            :rules="[rules.liquidacaoNaoSelecionada]"
            @change="resetarValidacaoLiquidacao('indUnicaLiquidacao')"
            v-model="parametrizacao.liquidacao.indUnicaLiquidacao"
            :disabled="apuracaoParalelaLiquidacao" />
        </v-col>
      </v-row>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_liquidacao_multiplas_liquidacao"
            :label="$t('label.multiplas_liquidacoes')"
            :rules="[rules.liquidacaoNaoSelecionada]"
            @change="resetarValidacaoLiquidacao('indMultiplasLiquidacoes')"
            v-model="parametrizacao.liquidacao.indMultiplasLiquidacoes"
            :disabled="unicoPagador || apuracaoParalelaLiquidacao" />
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-select
            :label="$t('label.template')"
            v-if="parametrizacao.liquidacao.indMultiplasLiquidacoes"
            id="config_acao_apuracao_template"
            class="Form-text__invalid"
            name="config_acao_liquidacao_template"
            item-text="text"
            item-value="value"
            v-model="parametrizacao.liquidacao.templateCalculo"
            :items="templates"
            :rules="[rules.required]"
            required
            clearable>
          </v-select>
        </v-col>
      </v-row>

      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_liquidacao_liquidacao_manual"
            :label="$t('label.liquidacao_manual')"
            v-model="parametrizacao.liquidacao.indLiquidacaoManual"
            :rules="[rules.liquidacaoNaoSelecionada]"
            @change="resetarValidacaoLiquidacao('indLiquidacaoManual')"
            :disabled="apuracaoParalelaLiquidacao || isFluxoApuracaoHabilitado" />
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            id="config_acao_apuracao_liq_template"
            name="config_acao_liquidacao_liq_template"
            :label="$tc('label.campo_customizado', 1)"
            class="Form-text__invalid"
            v-if="parametrizacao.liquidacao.indLiquidacaoManual"
            item-text="nomeLabel"
            item-value="idCampoDinamico"
            v-model="parametrizacao.camposDinamicosPagamentoManual"
            :items="listaCamposDinamicos"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            clearable
            chips
            multiple
            deletable-chips
            return-object
            @click.native="buscarCamposDinamicos">
          </v-autocomplete>
        </v-col>
      </v-row>

      <div v-if="!fluxoComApuracao || apuracaoParalelaLiquidacao">
        <v-card-title primary-title class="card-header mt-4">
          {{  `${$t('title.criacao_pagamento')} *` }}
        </v-card-title>
        <v-row class="mx-4">
          <v-col id="criacao_pagamento" cols="12" sm="6" md="4">
            <v-radio-group
                v-model="parametrizacao.liquidacao.criacaoPagamento"
                :rules="[rules.required]">
              <v-radio
                  id="criacao_pagamento_acao"
                  :label="$t('label.pagamento_a_partir_aprovacao_acao')"
                  value="APROVACAO_ACAO">
              </v-radio>
              <v-radio
                  id="criacao_pagamento_ini_vigencia"
                  :label="$t('label.pagamento_a_partir_inicio_vigencia')"
                  value="INICIO_VIGENCIA">
              </v-radio>
              <v-radio
                  id="criacao_pagamento_vigencia_concluida"
                  :label="$t('label.pagamento_a_partir_vigencia_concluida')"
                  value="VIGENCIA_CONCLUIDA">
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </div>

      <v-card-title primary-title class="card-header mt-4">
        {{ $t('title.status_criacao_pagamento') }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.status_criacao_pagamento')}`" />
      </v-card-title>
      <v-row class="mx-4">
        <v-col cols="12" sm="6" md="4">
          <v-switch
            id="config_acao_liquidacao_status_criacao_pagamento"
            :label="`${modoVarejo ? $t('label.criar_recebimento_status_liquidado') : $t('label.criar_pagamento_status_liquidado')}`"
            v-model="parametrizacao.liquidacao.indStatusCriacaoLiquidado" />
        </v-col>
      </v-row>

      <v-row justify="end" class="my-4">
        <v-btn id="config_acao_passo6_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="primary" id="config_acao_passo6_botao_voltar" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
        <v-btn color="primary" id="config_acao_passo6_botao_proximo" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script type="text/javascript">
import Passo6 from './Passo6';
import TooltipAjuda from '../TooltipAjuda';

export default {
  name: 'ParametrizacaoAcaoPasso6',
  props: {
    id: Number,
    modoVarejo: Boolean,
    passo: Number,
    fluxo: String,
  },
  components: {
    TooltipAjuda,
  },
  data() {
    return {
      paramAcaoResources: this.$api.parametrizacaoAcao(this.$resource),
      acaoConfiguracaoCamposResources: this.$api.acaoConfiguracaoCampos(this.$resource),
      camposCustomizadosResources: this.$api.camposCustomizados(this.$resource),
      rules: {
        required: (value) => !!value || '',
        liquidacaoNaoSelecionada: () => (this.parametrizacao.liquidacao.indUnicaLiquidacao
          || this.parametrizacao.liquidacao.indMultiplasLiquidacoes
          || this.parametrizacao.liquidacao.indLiquidacaoManual)
          || '',

      },
      templates: [
        { text: this.$tc('label.rateio_proporcional_fornecedor', 1), value: 'PROPORCIONAL_POR_FORNECEDOR' },
        { text: this.$tc('label.rateio_proporcional_cliente', 1), value: 'PROPORCIONAL_POR_CLIENTE' },
      ],
      listaCamposDinamicos: [],
      parametrizacao: {
        liquidacao: new Passo6(this.id, ''),
      },
      liquidacaoBanco: [],
      tipoVerba: false,
      activeBtn: 0,
      unicoPagador: false,
      isFluxoApuracaoHabilitado: false,
    };
  },
  computed: {
    fluxoComApuracao() {
      return !!this.fluxo && this.fluxo.includes('APURACAO');
    },
    apuracaoParalelaLiquidacao() {
      return !!this.fluxo && this.fluxo.split(',').includes('APURACAO_E_LIQUIDACAO');
    },
    isLiquidacaoManual() {
      return this.parametrizacao.liquidacao
          && this.parametrizacao.liquidacao.indLiquidacaoManual;
    },
  },
  methods: {
    setCamposUnicoPagador(campos) {
      this.unicoPagador = campos.length !== 0;
      if ((this.unicoPagador || this.apuracaoParalelaLiquidacao)
          && !this.isLiquidacaoManual) {
        this.parametrizacao.liquidacao.indUnicaLiquidacao = true;
        this.parametrizacao.liquidacao.indMultiplasLiquidacoes = false;
      }
    },
    cancelar() {
      this.$emit('PARAMETRIZACAO_ACAO_CANCELAR');
    },
    resetarValidacaoLiquidacao(campo) {
      if (campo === 'indLiquidacaoManual') {
        if (this.parametrizacao.liquidacao.indLiquidacaoManual) {
          this.parametrizacao.liquidacao.indUnicaLiquidacao = false;
          this.parametrizacao.liquidacao.indMultiplasLiquidacoes = false;
        }
        this.$emit('PARAMETRIZACAO_LIQ_MANUAL_ALTERADA', this.parametrizacao.liquidacao.indLiquidacaoManual);
      } else {
        if (this.parametrizacao.liquidacao.indUnicaLiquidacao
          || this.parametrizacao.liquidacao.indMultiplasLiquidacoes) {
          this.parametrizacao.liquidacao.indLiquidacaoManual = false;
        }
        if (this.parametrizacao.liquidacao.indMultiplasLiquidacoes) {
          this.$refs.formLiquidacao.resetValidation();
          this.$refs.formLiquidacao.validate();
        }
      }
    },

    proximoPasso() {
      if (this.$refs.formLiquidacao.validate()) {
        if (!this.parametrizacao.liquidacao.indMultiplasLiquidacoes) {
          this.parametrizacao.liquidacao.templateCalculo = null;
        }
        this.$emit('PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
        this.$emit('PARAMETRIZACAO_ACAO_CONTINUAR', this.passo + 1, this.id);
      } else {
        this.$toast(this.$t('errors.campos_obrigatorios.nao_informados'));
      }
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_ACAO_VOLTAR', this.passo - 1);
    },
    prepararPasso() {
      if (this.id) {
        this.obter(this.id);
      }
      this.buscarCamposDinamicos();
      this.buscarTemplateMuiltiLiquidacao();
    },
    obter(id) {
      this.paramAcaoResources.obterPasso6({ id }, { id }).then((response) => {
        if (response.data && response.data.liquidacao) {
          this.liquidacaoBanco = response.data.liquidacao;
          this.parametrizacao.liquidacao = this.liquidacaoBanco;
          this.parametrizacao.camposDinamicosPagamentoManual = response
            .data.camposDinamicosPagamentoManual;
        }
      }, (err) => {
        this.$error(this, err);
      });
    },
    setHabilitacaoFluxoApuracao(isFluxoApuracaoHabilitado = false) {
      this.isFluxoApuracaoHabilitado = isFluxoApuracaoHabilitado;
    },
    buscarCamposDinamicos() {
      return this.camposCustomizadosResources.listarPorEntidadeAcao()
        .then((response) => {
          this.listaCamposDinamicos = response
            .data.filter((item) => !item.isPreenchidoProcessoCustomizado);
          this.listaCamposDinamicos.forEach((l) => {
            l.nomeLabel = l.labelCampo.concat(' - ').concat(l.nomCampo);
            l.nome = l.nomCampo;
            l.desAtributos = l.desAtributos && l.desAtributos.value
              ? JSON.parse(l.desAtributos.value) : {};
            l.idCampoDinamico = l.id;
            l.label = l.labelCampo;
            l.obrigatorio = l.vlrObrigatorio;
            l.edicaoAprovado = false;
            delete l.id;
          });
        }, (err) => {
          this.$error(this, err);
        });
    },
    buscarTemplateMuiltiLiquidacao() {
      return this.acaoConfiguracaoCamposResources.buscarTemplateMuiltiLiquidacao()
        .then((response) => {
          this.templates = response.data.map((t) => ({ text: this.$tc(t.descricao, 1), value: t.template }));
        }, (err) => {
          this.$error(this, err);
        });
    },
    ajustarCamposDinamicos() {
      if (this.parametrizacao.camposDinamicosPagamentoManual
        && this.parametrizacao.camposDinamicosPagamentoManual.length) {
        this.parametrizacao.camposDinamicosPagamentoManual.forEach((c) => {
          delete c.id;
          this.listaCamposDinamicos.forEach((l) => {
            if (c.label === l.labelCampo) {
              c.nome = l.nomCampo;
              c.nomeLabel = l.labelCampo.concat(' - ').concat(l.nomCampo);
            }
          });
        });
      }
    },
  },
  mounted() {
    this.tipoVerba = false;
    const verba = new Passo6(this.id, '');
    this.parametrizacao.liquidacao = verba;
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  @import '~@/config/theme/theme.scss';
  .card-header {
    background-color: $primary-color;
    color: #ffffff;
  }
  .margem {
    margin-top: 12px;
    margin-bottom: 0px !important;
  }
</style>
