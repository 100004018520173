var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"show-select":"","headers":_vm.headers,"item-key":"idApuracao","items":_vm.apuracoes,"footer-props":{
    itemsPerPageOptions: [10, 25, 50],
  },"no-data-text":_vm.$t('label.tabela_sem_conteudo')},scopedSlots:_vm._u([{key:"item.idAcao",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.idAcao))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.descricaoAcao))])])]}},{key:"item.baseCalculo",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.baseCalculo ? _vm.$t(("configuracao." + (item.baseCalculo))) : '')+" ")]}},{key:"item.verba",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.exibirVerba(item))+" ")]}},{key:"item.vlrConfApuracao",fn:function(ref){
  var item = ref.item;
return [_c('input-money',{staticClass:"v-text-field",attrs:{"id":("contrato_apuracao_apurado_" + (item.idApuracao)),"disabled":!_vm.podeEditarValorPagamento(item)},model:{value:(item.vlrConfApuracao),callback:function ($$v) {_vm.$set(item, "vlrConfApuracao", $$v)},expression:"item.vlrConfApuracao"}})]}},{key:"item.aportado",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.aportado))+" ")]}},{key:"item.dataInicio",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(((_vm.converterMesAno(item.dataInicio)) + " " + (_vm.$tc('label.a', 2)) + " " + (_vm.converterMesAno(item.dataFim))))+" ")]}},{key:"item.vlrRealizado",fn:function(ref){
  var item = ref.item;
return [_c('input-money',{staticClass:"v-text-field",attrs:{"id":("contrato_apuracao_realizado_" + (item.idApuracao)),"disabled":!_vm.podeEditarValorRealizado(item)},model:{value:(item.vlrRealizado),callback:function ($$v) {_vm.$set(item, "vlrRealizado", $$v)},expression:"item.vlrRealizado"}})]}},{key:"item.pago",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.pago))+" ")]}},{key:"item.notas",fn:function(ref){
  var item = ref.item;
return [(item.temNota)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){$event.stopPropagation();return _vm.exportar(item)}}},on),[_vm._v("notes")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.visualizar_notas_clique_aqui')))])]):_vm._e()]}},{key:"item.vlrAAportar",fn:function(ref){
  var item = ref.item;
return [_c('input-money',{staticClass:"v-text-field",attrs:{"id":("contrato_apuracao_aportar_" + (item.idApuracao)),"disabled":!_vm.podeEditarValorPagamento(item),"rules":[function (value) { return value <= item.maxAAportar || _vm.$t('message.valor_aportar_nao_permitido'); } ]},model:{value:(item.vlrAAportar),callback:function ($$v) {_vm.$set(item, "vlrAAportar", $$v)},expression:"item.vlrAAportar"}})]}},{key:"item.vlrAPagar",fn:function(ref){
  var item = ref.item;
return [_c('input-money',{staticClass:"v-text-field",attrs:{"id":("contrato_apuracao_aportar_" + (item.idApuracao)),"disabled":!_vm.podeEditarValorPagamento(item),"rules":[function (value) { return value <= item.maxAPagar || _vm.$t('message.valor_pagar_maior_que_apurado'); } ]},model:{value:(item.vlrAPagar),callback:function ($$v) {_vm.$set(item, "vlrAPagar", $$v)},expression:"item.vlrAPagar"}})]}},{key:"item.possuiMeta",fn:function(ref){
  var item = ref.item;
return [(item.possuiMetaIntervalo)?_c('v-menu',{attrs:{"id":("contrato_apuracao_meta_intervalo_" + (item.idApuracao)),"close-on-content-click":false,"nudge-width":400,"bottom":"","open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("attach_money")])]}}],null,true)},[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headersMetaIntervalo,"items":_vm.getMetasIntervalo(item),"hide-default-footer":"","no-data-text":_vm.$t('label.tabela_sem_conteudo')},scopedSlots:_vm._u([{key:"item.vlrMetaDe",fn:function(ref){
  var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.exibirMeta(item.vlrMetaDe, item.calculoApuracao))}})]}},{key:"item.vlrMetaAte",fn:function(ref){
  var item = ref.item;
return [(item.vlrMetaAte)?_c('span',{domProps:{"textContent":_vm._s(_vm.exibirMeta(item.vlrMetaAte, item.calculoApuracao))}}):_vm._e()]}},{key:"item.vlrBonificacao",fn:function(ref){
  var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.exibirPagamento(item.vlrBonificacao, item.formaBonificacao))}})]}}],null,true)})],1)],1):(item.possuiMeta)?_c('span',[_vm._v(_vm._s(_vm.exibirMeta(item.meta, item.calculoApuracao)))]):_vm._e()]}}]),model:{value:(_vm.apuracoesSelecionadas),callback:function ($$v) {_vm.apuracoesSelecionadas=$$v},expression:"apuracoesSelecionadas"}})}
var staticRenderFns = []

export { render, staticRenderFns }