<template lang="html">
  <v-card>
    <register-form>
      <v-card-title slot="header">
        <h2 style="font-size: 18px; width: 100%;" class="mb-0">{{ $t('label.fazer_nova_importacao') }}</h2>
      </v-card-title>
      <div slot="content">
        <v-row>
          <v-col cols="12"   >
            <v-select
              class="Form-text__invalid"
              id="periodo"
              name="periodo"
              :items="tipos"
              :label="`${$t('label.tipo_importacao')}`"
              v-model="importacao">
              <template v-slot:selection="data">
                <div>
                  {{ data.item.descricao }}
                </div>
              </template>
              <template v-slot:item="data">
                <div>
                  {{ data.item.descricao }}
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" v-if="sellinOuSellout || pedido">
            <v-date-picker
              locale="pt-br"
              v-model="mesesImportacao"
              type="month"
              no-title
              color="primary"
              multiple/>
          </v-col>

          <v-col>
            <v-radio-group
                v-model="tipoPedido" row
                :mandatory="true" v-if="pedido && possuiMesesImportacao">
              <v-radio label="Carteira" value="carteira"></v-radio>
              <v-radio label="Remessa" value="remessa"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div style="position: relative; min-height: 352px;"
              v-if="(!sellinOuSellout && !pedido)
               || (sellinOuSellout && possuiMesesImportacao)
                || (pedido && possuiMesesImportacao)">
              <upload-file
                v-if="importacao"
                class="md-hide-medium"
                :id="uploadFileDropzoneId"
                @UPLOADFILE_SUCCESS="uploadSuccess"
                @UPLOADFILE_ERROR="uploadError"
                @UPLOADFILE_SENDING="enviandoArquivo"
                :maxFileSizeInMB="50"
                :url="uploadFileUrl"
                :accepted-file-types="importacao.arquivosSuportados"></upload-file>
            </div>
          </v-col>
        </v-row>

      </div>
      <div slot="button"></div>
      <v-btn
        v-if="exibeCancelar"
        color="primary"
        id="importacaoLateral-dialog-button-cancel"
        @click.native="cancelarImportacao()">
        {{ $t('label.cancelar') }}
      </v-btn>
    </register-form>

    <v-dialog v-model="dialog.open" width="350">
      <v-card>
        <v-card-title>
          {{ dialog.title }}:
        </v-card-title>
        <v-card-text>
          {{ dialog.content }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog(dialog)">
            {{ $tc('label.cancelar') }}
          </v-btn>
          <v-btn color="primary" text @click="closeDialog(dialog, dialog.action)">
            {{ $tc('label.sim') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import RegisterForm from '../../shared-components/generico/RegisterForm';
import UploadFile from '../../shared-components/upload/UploadFile';

import { basePath } from '../../common/resources/importacoes';

export default {
  name: 'ImportacaoSideColumn',
  props: {
    tipos: {
      type: Array,
      default() { return []; },
    },
    exibeCancelar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadFileUrl: `${basePath}importacao`,
      dialog: {
        ref: 'dialog1',
        from: 'saveRegisterFromButton',
        title: this.$t('label.atencao'),
        content: '',
        open: false,
        action() {},
      },
      foo: 'hello',
      importacaoId: -1,
      showBlockDropzone: true,
      dataInicio: '',
      dataFim: '',
      urlFim: '',
      mesesImportacao: [],
      tipoPedido: null,
      importacao: null,
    };
  },
  computed: {
    sellinOuSellout() {
      return this.importacao && (this.importacao.nome === 'importacao_sellin'
        || this.importacao.nome === 'importacao_sellout');
    },
    pedido() {
      return this.importacao && this.importacao.nome === 'importacao_pedido';
    },
    possuiMesesImportacao() {
      return this.mesesImportacao && this.mesesImportacao.length > 0;
    },
    uploadFileDropzoneId() {
      return `myVueDropzone${(Math.random() * Math.random() * 100).toString().replace('.', '')}`;
    },
  },
  components: {
    RegisterForm,
    UploadFile,
  },
  methods: {
    enviandoArquivo(data, xhr, formData) {
      const parametros = { meses: this.mesesImportacao, tipo: this.tipoPedido };

      formData.append('id_tipo_importacao', this.importacao.id);
      formData.append('parametros', JSON.stringify(parametros));
    },
    cancelarImportacao() {
      this.$emit('IMPORTACAO_LATERACAO_CANCELAR');
    },
    closeDialog(dialog, cb) {
      dialog.open = false;
      if (cb) {
        cb();
      }
    },
    openDialog(dialog) {
      dialog.open = true;
    },
    openSaveDialog(ref, from) {
      if (this.validateFields()) {
        const content = this.$t('message.salvar_novo_canal');
        this.setDialogContent(content, null, from || this.dialog.from);

        this.openDialog(this.dialog);
      }
    },
    setDialogContent(content, action, from) {
      this.dialog.content = content || this.$t('message.deseja_importar');
      this.dialog.action = action || this.save;
      this.dialog.from = from || this.dialog.from;
    },
    uploadError(file, msg) {
      this.$toast(msg || this.$t('message.erro_tente_importar_novamente'));
    },
    uploadSuccess() {
      this.importacao = null;
      this.$toast(this.$t('message.importado_em_breve_sera_processado'), 'bottom', 8E3);
      this.$emit('IMPORTACAO_LATERACAO_UPLOAD');
    },
  },
};
</script>

<style lang="css">
  .ImportacaoLateral__BlockDropzone {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.65);
    color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    transition: height .3s ease, color .1s ease;
  }

  .ImportacaoLateral__BlockDropzone--deactive {
    color: rgba(0, 0, 0, 0.3);
    height: 0px;
    min-height: 0px;
    min-width: 352px;
    width: 100%;
    transition: height .3s ease, color .1s ease;
  }

  .ImportacaoLateral__BlockDropzone--active {
    height: 100%;
    width: 100%;
    transition: height .3s ease, color .1s ease;
  }
</style>
