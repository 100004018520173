<template>
  <v-form ref="formComportamento"
    v-model="formComportamentoValido"
    lazy-validation
    autocomplete="off">
    <planejamento-acao-form-apuracao
      v-if="!!objetoPassoGeral"
      :tipo-acao="tipoAcao"
      :configuracao="configuracao"
      :objeto-passo-geral="objetoPassoGeral"
      :acao-edicao="acaoEdicao"
      :nova-acao="novaAcao"
      :somente-leitura="somenteLeitura"
      ref="componenteApuracao"
      @PlanejamentoAcaoFormApuracao__alteradoValorTotal="emitirAlteradoValorTotal"
      @PlanejamentoAcaoFormApuracao__periodoApuracao="emitirPeriodoApuracao">
      <div class="card-title" slot="titulo-acao">
        {{ $t('label.criacao_acao')}} :: {{ tituloTipoAcao }} :: {{$tc('title.resumo', 1)}}
      </div>
    </planejamento-acao-form-apuracao>
  </v-form>
</template>
<script>
import PlanejamentoAcaoFormApuracao from './PlanejamentoAcaoFormApuracao';
import AcaoComponenteEvento from './passos-dinamicos/AcaoComponenteEvento';

export default {
  components: {
    PlanejamentoAcaoFormApuracao,
  },
  props: {
    somenteLeitura: Boolean,
    novaAcao: Boolean,
    acaoEdicao: Object,
    tipoAcao: {
      type: Object,
      required: true,
    },
    getObjetoOutroPasso: {
      type: Function,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    objetoPassoGeral: null,
    formComportamentoValido: false,
  }),
  computed: {
    tituloTipoAcao() {
      return this.tipoAcao != null ? this.tipoAcao.nome : '';
    },
  },
  methods: {
    emitirPeriodoApuracao() {
      const evento = new AcaoComponenteEvento('periodoApuracaoAlterado');
      this.$emit('PlanejamentoAcaoForm__evento', evento);
    },
    emitirAlteradoValorTotal(valor, coeficiente) {
      const evento = new AcaoComponenteEvento(
        'alteradoValorTotal',
        {
          valor,
          coeficiente,
        },
      );
      this.objetoPassoGeral.valor = valor;
      this.objetoPassoGeral.coeficienteValorTotal = coeficiente;
      this.$emit('PlanejamentoAcaoForm__evento', evento);
    },
    onChainedEvents(evento) {
      if (evento.ehEvento('antesIrUltimoPasso')
          || evento.ehEvento('carregarEdicao')) {
        this.abrePasso(evento);
      }
    },
    valida() {
      return this.$refs.formComportamento.validate()
          && this.validacaoComplementar();
    },
    validaSalvar() {
      if (!this.valida()) {
        return false;
      }
      return this.validacaoCustomizada();
    },
    preencherObjetoFormatado(acao) {
      const passoComportamento = this.getObjetoFormatado();
      if (passoComportamento
          && this.valorDaAcaoFoiAlterado()
          && acao.passoGeral) {
        acao.passoGeral.valor = this.getValorTotalAcao();
        acao.passoGeral.coeficienteValorTotal = this.getCoeficienteValorTotal();
      }
      acao.passoComportamento = passoComportamento;
    },
    getObjetoPasso() {
      return this.getObjetoNatural();
    },
    abrePasso(evento = null) {
      this.objetoPassoGeral = this.getObjetoOutroPasso('geral');
      setTimeout(() => {
        this.$refs.componenteApuracao.abrePasso();
        if (evento) {
          evento.executarProximoPasso();
        }
      }, 5E2);
    },
    getObjetoNatural() {
      return this.$refs.componenteApuracao.getObjetoNatural();
    },
    validacaoComplementar() {
      return this.$refs.componenteApuracao.validarMetas();
    },
    validacaoCustomizada() {
      return this.$refs.componenteApuracao.validacaoCustomizada();
    },
    getObjetoFormatado() {
      return this.$refs.componenteApuracao.getObjetoFormatado();
    },
    valorDaAcaoFoiAlterado() {
      return this.$refs.componenteApuracao.valorDaAcaoFoiAlterado();
    },
    getValorTotalAcao() {
      return this.$refs.componenteApuracao.getValorTotalAcao();
    },
    getCoeficienteValorTotal() {
      return this.$refs.componenteApuracao.getCoeficienteValorTotal();
    },
  },
};
</script>
