<template>
  <div>
    <v-container fluid class='Container_Tabela' style="text-align: center;">
      <v-row justify="start" class="py-4">
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="pesquisaTabela"
            append-icon="search"
            @input="filtrarDadosInconsistenciaDetalhado"
            :label="`${$tc('label.pesquisar', 1)}`"
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col class="text-center" cols="12" sm="4">
          <v-btn
            color="primary darken-1"
            @click.native="openReprocessarGeralDialog"
            class="ml-2">
            {{ $tc('label.reprocessar_cinquenta')}}
          </v-btn>
        </v-col>
      </v-row>
      <notas-lista-inconsistencia-detalhado
        v-if="tipoErro != null"
        ref="tabelaDetalhado"
        :lote="listaLotes"
        :tipoErro="tipoErro"
        :pesquisaTabela="pesquisaTabela"
        @InconsistenciaReprocessada="atualizarTabela"/>
    </v-container>
    <confirm
      ref="reprocessarDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="reprocessarLote()">
    </confirm>
    <confirm
      ref="reprocessarDialogGeral"
      :message="$t('message.lote_reprocessar_possiveis')"
      :persistent="true"
      @agree="reprocessarGeral()">
    </confirm>
  </div>
</template>

<script>
import {
  getMoney,
} from '../../produto/common/functions/helpers';
import notasListaInconsistenciaDetalhado from './NotasNivel2DadosInconsistenciaDetalhado';
import Confirm from '../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  name: 'NotasListaInconsistenciaAgrupado',
  components: {
    notasListaInconsistenciaDetalhado,
    Confirm,
  },
  props: {
    visao: String,
  },
  data() {
    return {
      resourcesInconsistencia: this.$api.notas(this.$resource),
      resourcesReprocessamento: this.$api.notas(this.$resource),
      pesquisaTabela: null,
      listaLotes: [],
      esperarDigitar: false,
      loteSelecionado: {},
      pagina: 1,
      totalPagina: 1,
      quantidadePagina: 5,
      painel: null,
      iniciandoPainel: true,
      tipoErro: null,
      dialog: {
        from: '',
        title: this.$t('label.atencao'),
        content: '',
        action() {},
      },
    };
  },
  watch: {
    visao: {
      handler() {
        this.buscar();
      },
    },
  },
  computed: {
    isSellIn() {
      if (this.visao === 'Pedido') {
        this.tipoErro = 'PEDIDO';
        return true;
      }
      return false;
    },

    isDevolucao() {
      if (this.visao === 'Faturamento') {
        this.tipoErro = 'SELLIN';
        return true;
      }
      return false;
    },

    isSellOut() {
      if (this.visao === 'Devoluções') {
        this.tipoErro = 'DEVOLUCAO';
        return true;
      }
      return false;
    },
    isInconsistencia() {
      if (this.visao === 'Inconsistências') {
        return true;
      }
      return false;
    },
  },
  methods: {
    getMoney,

    filtrarDadosInconsistenciaDetalhado() {
      this.$refs.tabelaDetalhado.filtrar();
    },
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },

    buscar() {
      this.listaLotes = [];
      this.painel = null;
      const params = {
        filtro: this.pesquisaTabela,
        tipoErro: this.tipoErro,
        numeroPagina: this.pagina,
        tamanhoPagina: this.quantidadePagina,
      };

      this.resourcesInconsistencia.listarNotasInconsistenciaAgrupado(params).then((res) => {
        this.listaLotes = res.data.resposta;
        this.totalPagina = Math.ceil(res.data.quantidadeRegistrosPagina / this.quantidadePagina);
        this.iniciandoPainel = false;
        this.panelKey += 1;
      }, (err) => {
        this.$error(this, err);
      });
    },

    reprocessarGeral() {
      if (this.tipoErro === 'FATURAMENTO') {
        this.tipoErro = 'SELLIN';
      }
      this.resourcesReprocessamento.reprocessarGeral({ tipo: this.tipoErro })
        .then(() => {
          this.$toast(this.$t('message.lote_reprocessado_possiveis'));
          this.filtrar();
          this.atualizarTabelasDetalhado();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },

    reprocessarLote() {
      const lote = this.loteSelecionado;

      const params = {
        tipoErro: lote.tipo,
        numeroLote: lote.lote,
      };

      this.resourcesReprocessamento.reprocessarInconsistencia(params).then((response) => {
        this.atualizarTabela(response);
        this.atualizarTabelasDetalhado();
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },

    atualizarLote(lote, qtdErros) {
      const original = this.listaLotes.filter((l) => l === lote)[0];
      if (original) {
        original.qtdErros = qtdErros;
        this.$forceUpdate();
      }
    },

    atualizarTabela(msgRetorno) {
      this.filtrar();
      setTimeout(() => {
        if (msgRetorno.bodyText === 'Sucesso') {
          this.$toast(this.$t('message.inconsistencia_integrada_sucesso'));
        } else {
          this.$toast(this.$t('message.inconsistencia_continua_pendente'));
        }
      }, 3E2);
    },

    openReprocessarDialog(ref, from) {
      this.loteSelecionado = ref;
      this.dialog.content = this.$t('message.lote_reprocessar');
      this.dialog.action = this.reprocessarLote;
      this.dialog.from = from;
      setTimeout(() => this.$refs.reprocessarDialog.open());
    },

    openReprocessarGeralDialog() {
      this.$refs.reprocessarDialogGeral.open();
    },

    pararEsperar() {
      setTimeout(() => {
        this.esperarDigitar = false;
      }, 2E2);
    },

    atualizarTabelasDetalhado() {
      if (this.$refs.tabelaDetalhado) {
        this.$refs.tabelaDetalhado.buscar();
      }
    },

  },
  beforeMount() {
  },
  mounted() {
    if (this.visao != null && this.visao.length) {
      if (this.visao === 'Faturamento') {
        this.tipoErro = 'SELLIN';
      } else if (this.visao === 'Devoluções') {
        this.tipoErro = 'DEVOLUCAO';
      } else if (this.visao === 'Pedido') {
        this.tipoErro = 'PEDIDO';
      }
      this.buscar();
    }
  },
};
</script>

<style>
.Container_Tabela table.v-table tbody td:first-child,.Container_Tabela  table.v-table tbody td:not(:first-child),
  .Container_Tabela table.v-table tbody th:first-child, .Container_Tabela table.v-table tbody th:not(:first-child),
  .Container_Tabela table.v-table thead td:first-child, .Container_Tabela table.v-table thead td:not(:first-child),
  .Container_Tabela table.v-table thead th:first-child, .Container_Tabela table.v-table thead th:not(:first-child) {
  padding: 0 11px;
}
.Barra_Navegacao .v-item-group.v-bottom-nav .v-btn,.Barra_Navegacao .v-item-group.v-bottom-nav .v-btn--active {
  padding: 4px;
}
</style>
