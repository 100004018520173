import { skipLoading } from '../../../common/functions/loading';

export default {
  data() {
    return {
      workflowAjusteVerbaResource: this.$api.workflowAjusteVerba(this.$resource),
      aprovadorAtual: false,
      solicitanteFluxo: false,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      justificativa: '',
      justificativaSomenteLeitura: false,
    };
  },
  methods: {
    solicitarAprovacao() {
      if (!this.$refs.resumo.valida()) return;
      if (!this.$refs.linhas.valida()) return;

      const parametros = {
        ...this.ajusteVerba,
        linhas: this.$refs.linhas.linhasValidas(),
      };

      const promise = !this.ajusteVerba.idAjuste
        ? this.resource.salvar(parametros)
        : this.resource.atualizar(parametros);

      promise
        .then((res) => {
          const idAjuste = res.data;
          return this.workflowAjusteVerbaResource.iniciaFluxo({ idAjuste }, { idAjuste });
        })
        .then(() => {
          this.$toast(this.$tc('message.ajuste_verba_enviado_aprovacao', 2));
          this.voltar();
        })
        .catch((err) => this.$refs.validacao.apresentaErrosServidor(err));
    },
    aprovar(justificativa) {
      const { status, idAjuste } = this.ajusteVerba;
      if (status !== 'AGUARDANDO_APROVACAO') {
        return;
      }

      this.workflowAjusteVerbaResource.aprovarPasso({ idAjuste },
        { observacao: justificativa })
        .then(() => {
          this.voltar();
          this.$toast(this.$tc('message.ajuste_verba_aprovado', 2));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    reprovar(justificativa) {
      const { status, idAjuste } = this.ajusteVerba;
      if (status !== 'AGUARDANDO_APROVACAO') {
        return;
      }

      this.workflowAjusteVerbaResource.reprovar({ idAjuste },
        { observacao: justificativa })
        .then(() => {
          this.voltar();
          this.$toast(this.$tc('message.ajuste_verba_reprovado', 2));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    enviarAnalise(justificativa) {
      const { status, idAjuste } = this.ajusteVerba;
      if (status !== 'AGUARDANDO_APROVACAO') {
        return;
      }

      this.workflowAjusteVerbaResource.enviarAnalise({ idAjuste },
        { observacao: justificativa })
        .then(() => {
          this.voltar();
          this.$toast(this.$tc('message.ajuste_verba_enviado_analise', 2));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificarUsuarioSolicitante() {
      this.workflowAjusteVerbaResource.solicitante()
        .then((res) => {
          this.solicitanteFluxo = res.data.usuarioHabilitado;
        });
    },
    verificarUsuarioAprovador() {
      const { status, idAjuste } = this.ajusteVerba;
      if (status !== 'AGUARDANDO_APROVACAO') {
        return;
      }

      skipLoading();
      this.workflowAjusteVerbaResource.aprovador({ idAjuste })
        .then((response) => {
          this.aprovadorAtual = response.data.usuarioHabilitado;
        });
    },
    abrirJustificativaAprovar() {
      this.abrirJustificativaAvaliar(this.aprovar, false);
    },
    abrirJustificativaReprovar() {
      this.abrirJustificativaAvaliar(this.reprovar, true);
    },
    abrirJustificativaAnalise() {
      this.abrirJustificativaAvaliar(this.enviarAnalise, true);
    },
    abrirJustificativaAvaliar(acaoJustificativa, obrigatorio) {
      this.justificativaObrigatoria = obrigatorio;
      this.actionJustificativa = acaoJustificativa;
      this.justificativa = '';
      this.justificativaSomenteLeitura = false;
      this.$refs.modalJustificativa.open();
    },
  },
};
