import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      exibirFiltro: true,
      breadcrumbs: [
        {
          text: this.$tc('title.apuracao', 1),
          to: 'apuracao-acao',
          disabled: true,
          nivel: 1,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('filtros', [
      'filtroApuracaoAcaoNivel3',
    ]),
  },
  watch: {
    filtroApuracaoAcaoNivel3(val) {
      if (val && this.breadcrumbs[2]) {
        this.breadcrumbs[2].text = this.montarTextoNivel3(val);
      }
    },
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroApuracaoAcaoNivel2',
      'setFiltroApuracaoAcaoNivel3',
    ]),
    navegarBreadcrumbs(item) {
      if (item.disabled) {
        return;
      }

      const visaoGrupo = this.$router.currentRoute.name === 'listarApuracoesAcaoNivel2Grupo'
        || this.$router.currentRoute.name === 'detalharApuracaoAcaoGrupo';

      const visaoFornecedor = this.$router.currentRoute.name === 'listarApuracoesAcaoNivel2Fornecedor'
        || this.$router.currentRoute.name === 'detalharApuracaoAcaoFornecedor';

      const visaoFornecedorPagador = this.$router.currentRoute.name === 'listarApuracoesAcaoNivel2FornecedorPagador'
        || this.$router.currentRoute.name === 'detalharApuracaoAcaoFornecedorPagador';

      const visaoHolding = this.$router.currentRoute.name === 'listarApuracoesAcaoNivel2Holding'
        || this.$router.currentRoute.name === 'detalharApuracaoAcaoHolding';

      const visaoCliente = this.$router.currentRoute.name === 'listarApuracoesAcaoNivel2Cliente'
        || this.$router.currentRoute.name === 'detalharApuracaoAcaoCliente';

      const visaoExtensao = this.$router.currentRoute.name === 'listarApuracoesAcaoNivel2Extensao'
        || this.$router.currentRoute.name === 'detalharApuracaoAcaoExtensao';

      const visaoRegional = this.$router.currentRoute.name === 'listarApuracoesAcaoNivel2Regional'
        || this.$router.currentRoute.name === 'detalharApuracaoAcaoRegional';

      let visao = 'Status';
      const obj = {};

      if (visaoHolding) {
        visao = 'Holding';
      } else if (visaoCliente) {
        visao = 'Cliente';
      } else if (visaoGrupo) {
        visao = 'Grupo Fornecedor';
      } else if (visaoFornecedor) {
        visao = 'Fornecedor';
      } else if (visaoFornecedorPagador) {
        visao = 'Fornecedor Pagador';
      } else if (visaoRegional) {
        visao = 'Regional';
      } else if (visaoExtensao) {
        visao = this.$router.currentRoute.params.mnemonico;
        obj.mnemonico = this.$router.currentRoute.params.mnemonico;
        obj.idExterno = this.$router.currentRoute.params.idExterno;
      }

      const params = {
        visao,
        ...obj,
      };

      let query;

      if (item.status) {
        params.status = item.status;
      }

      if (item.idHolding) {
        params.idHolding = item.idHolding;
        query = { origem: item.text };
      }

      if (item.idCliente) {
        params.idCliente = item.idCliente;
        query = { origem: item.text };
      }

      if (item.idGrupoFornecedor) {
        params.idGrupoFornecedoro = item.idGrupoFornecedoro;
        query = { origem: item.text };
      }

      if (item.idFornecedor) {
        params.idFornecedor = item.idFornecedor;
        query = { origem: item.text };
      }

      if (item.idFornecedorPagador) {
        params.idFornecedorPagador = item.idFornecedorPagador;
        query = { origem: item.text };
      }

      if (item.idRegional) {
        params.idRegional = item.idRegional;
        query = { origem: item.text };
      }

      if (item.mnemonico) {
        query = this.$router.currentRoute.query;
      }

      this.$router.push({ name: item.to, params, query });
    },
    alteracaoRota(to) {
      this.exibirFiltro = true;
      if (to.name === 'listarApuracoesAcaoNivel2Status') {
        this.adicionarItemBreadcrumbsStatus(to);
      } else if (to.name === 'listarApuracoesAcaoNivel2Holding') {
        this.adicionarItemBreadcrumbsHolding(to);
      } else if (to.name === 'listarApuracoesAcaoNivel2Cliente') {
        this.adicionarItemBreadcrumbsCliente(to);
      } else if (to.name === 'listarApuracoesAcaoNivel2Grupo') {
        this.adicionarItemBreadcrumbsGrupo(to);
      } else if (to.name === 'listarApuracoesAcaoNivel2Fornecedor') {
        this.adicionarItemBreadcrumbsFornecedor(to);
      } else if (to.name === 'listarApuracoesAcaoNivel2FornecedorPagador') {
        this.adicionarItemBreadcrumbsFornecedorPagador(to);
      } else if (to.name === 'listarApuracoesAcaoNivel2Extensao') {
        this.adicionarItemBreadcrumbsExtensao(to);
      } else if (to.name === 'listarApuracoesAcaoNivel2Regional') {
        this.adicionarItemBreadcrumbsRegional(to);
      } else if (to.name === 'apuracao-acao') {
        this.removerItemBreadcrumbs();
      } else {
        this.exibirFiltro = false;
        this.abrirFiltro = false;
        if (to.name === 'detalharApuracaoAcaoStatus') {
          this.adicionarItemBreadcrumbsDetalhesStatus(to);
        } else if (to.name === 'detalharApuracaoAcaoHolding') {
          this.adicionarItemBreadcrumbsDetalhesHolding(to);
        } else if (to.name === 'detalharApuracaoAcaoCliente') {
          this.adicionarItemBreadcrumbsDetalhesCliente(to);
        } else if (to.name === 'detalharApuracaoAcaoGrupo') {
          this.adicionarItemBreadcrumbsDetalhesGrupo(to);
        } else if (to.name === 'detalharApuracaoAcaoFornecedor') {
          this.adicionarItemBreadcrumbsDetalhesFornecedor(to);
        } else if (to.name === 'detalharApuracaoAcaoFornecedorPagador') {
          this.adicionarItemBreadcrumbsDetalhesFornecedorPagador(to);
        } else if (to.name === 'detalharApuracaoAcaoExtensao') {
          this.adicionarItemBreadcrumbsDetalhesExtensao(to);
        } else if (to.name === 'detalharApuracaoAcaoRegional') {
          this.adicionarItemBreadcrumbsDetalhesRegional(to);
        } else if (to.name === 'detalharApuracaoOrigem') {
          this.habilitarItemBreadcrumbsRaiz();
        }
      }
    },
    habilitarItemBreadcrumbsRaiz() {
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsStatus(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: this.$tc(`status_entidade.${to.params.status.toLowerCase()}`, 1),
          status: to.params.status,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroApuracaoAcaoNivel3(null);
    },
    adicionarItemBreadcrumbsExtensao(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idExtensao: to.params.idExtensao,
          idExterno: to.params.idExterno,
          mnemonico: to.params.mnemonico,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroApuracaoAcaoNivel3(null);
    },
    adicionarItemBreadcrumbsHolding(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idHolding: to.params.idHolding,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroApuracaoAcaoNivel3(null);
    },
    adicionarItemBreadcrumbsGrupo(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idGrupoFornecedor: to.params.idGrupoFornecedor,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroApuracaoAcaoNivel3(null);
    },
    adicionarItemBreadcrumbsFornecedor(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idFornecedor: to.params.idFornecedor,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroApuracaoAcaoNivel3(null);
    },
    adicionarItemBreadcrumbsFornecedorPagador(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idFornecedorPagador: to.params.idFornecedorPagador,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroApuracaoAcaoNivel3(null);
    },
    adicionarItemBreadcrumbsRegional(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idRegional: to.params.idRegional,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroApuracaoAcaoNivel3(null);
    },
    adicionarItemBreadcrumbsCliente(to) {
      this.breadcrumbs[0].disabled = false;

      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idCliente: to.params.idCliente,
          to: to.name,
          disabled: true,
          nivel: 2,
        },
      ];
      this.setFiltroApuracaoAcaoNivel3(null);
    },
    adicionarItemBreadcrumbsDetalhesStatus(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: this.$tc(`status_entidade.${to.params.status.toLowerCase()}`, 1),
          status: to.params.status,
          to: 'listarApuracoesAcaoNivel2Status',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          to: to.name,
          disabled: true,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsDetalhesHolding(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idHolding: to.params.idHolding,
          to: 'listarApuracoesAcaoNivel2Holding',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          to: to.name,
          disabled: true,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsDetalhesCliente(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idCliente: to.params.idCliente,
          to: 'listarApuracoesAcaoNivel2Cliente',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          to: to.name,
          disabled: true,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsDetalhesGrupo(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idGrupoFornecedor: to.params.idGrupoFornecedor,
          to: 'listarApuracoesAcaoNivel2Grupo',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          to: to.name,
          disabled: true,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsDetalhesFornecedor(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idFornecedor: to.params.idFornecedor,
          to: 'listarApuracoesAcaoNivel2Fornecedor',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          to: to.name,
          disabled: true,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsDetalhesFornecedorPagador(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idFornecedorPagador: to.params.idFornecedorPagador,
          to: 'listarApuracoesAcaoNivel2FornecedorPagador',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          to: to.name,
          disabled: true,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsDetalhesRegional(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idRegional: to.params.idRegional,
          to: 'listarApuracoesAcaoNivel2Regional',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          to: to.name,
          disabled: true,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    adicionarItemBreadcrumbsDetalhesExtensao(to) {
      this.breadcrumbs = [
        this.breadcrumbs[0],
        {
          text: to.query.origem,
          idExtensao: to.params.idExtensao,
          idExterno: to.params.idExterno,
          mnemonico: to.params.mnemonico,
          to: 'listarApuracoesAcaoNivel2Extensao',
          disabled: false,
          nivel: 2,
        },
        {
          text: this.montarTextoNivel3(to.params),
          to: to.name,
          disabled: true,
          nivel: 3,
        },
      ];
      this.breadcrumbs[0].disabled = false;
    },
    montarTextoNivel3(params) {
      let texto = '';
      if (params.idAcao) {
        texto = texto.concat(params.idAcao).concat(' - ');
      }
      if (params.codAcao) {
        texto = texto.concat(params.codAcao).concat(' - ');
      }
      if (params.cod_acao) {
        texto = texto.concat(params.cod_acao).concat(' - ');
      }
      if (params.codApuracao) {
        texto = texto.concat(params.codApuracao);
      }
      if (params.cod_apuracao) {
        texto = texto.concat(params.cod_apuracao);
      }
      if (params.id) {
        texto = texto.concat(params.id);
      }
      return texto;
    },
    removerItemBreadcrumbs() {
      this.breadcrumbs[0].disabled = true;
      this.breadcrumbs = [this.breadcrumbs[0]];
      this.setFiltroApuracaoAcaoNivel2(null);
      this.setFiltroApuracaoAcaoNivel3(null);
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.alteracaoRota(to);
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.alteracaoRota(to));
  },
};
