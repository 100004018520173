<template>
  <v-container fluid grid-list-md>
    <v-row v-if="!somenteLeitura">
      <v-col cols="12" sm="1">
        <input-number
          id="qtdLinhas_input"
          prepend-icon=""
          label=""
          v-model="valorQtdLinhasAdicionar"/>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-btn id="add_linhas" @click="adicionarLinhas()" style="margin-top: 0.8em;">
          {{ $tc('label.adicionar_linha', 2) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <spreadsheet
        ref="spreadsheet"
        v-if="planilhaPronta"
        :toolbar="false"
        :sheetsbar="false"
        :rows="rowsNumber"
        :data="datasource"
        :colunas="colunas"
        :estilos="estilos"
        :formulas="formulas"
        :selectRange="celulaAtiva"
        :tamanhoColunas="tamanhoColunas"
        :abasListaValores="abasListaValores"
        :frozenRows="1"
        :autocomplete="true"
        @DATASOURCE_CHANGE="datasourceAlterado"
        @SPREADSHEET_AUTOCOMPLETE="onAutocomplete"/>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import Spreadsheet from '../../shared-components/spreadsheet/Spreadsheet';

import { camelCase } from '../../common/functions/helpers';
import { getNivelExtensao } from '../../shared-components/metadados/metadadosUtils';

import InputNumber from '../../shared-components/inputs/InputNumber';

import OrcamentoFormLinhasEstilizacao from './OrcamentoFormLinhasEstilizacao';
import OrcamentoFormLinhasPreenchimento from './OrcamentoFormLinhasPreenchimento';
import OrcamentoFormLinhasAlteracao from './OrcamentoFormLinhasAlteracao';

export default {
  components: {
    Spreadsheet,
    InputNumber,
  },
  mixins: [
    OrcamentoFormLinhasEstilizacao,
    OrcamentoFormLinhasPreenchimento,
    OrcamentoFormLinhasAlteracao,
  ],
  props: {
    edicao: Boolean,
    somenteLeitura: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
    valorPlanejado: Number,
    linhasInvestimento: {
      type: Array,
      default: () => [],
    },
    getFiltrosResumo: {
      type: Function,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('metadados', [
      'getLinhaInvestimentoMetadado',
      'getProdutoMetadado',
      'getClienteMetadado',
      'getCentroCustoMetadado',
      'getDivisaoMetadado',
    ]),
    nomesColunasPadrao() {
      return this.colunasPadrao.map((coluna) => coluna.field);
    },
    nomesColunasExtensao() {
      return this.configuracao.colunasExtensaoLinha;
    },
    nomesColunasDinamicas() {
      return this.configuracao.colunasDinamicasLinha;
    },
    nomesColunasObrigatorias() {
      return [
        ...this.configuracao.colunasObrigatoriasLinha,
        ...this.colunasFixas,
      ];
    },
  },
  data() {
    return {
      centroCustoResource: this.$api.centroCusto(this.$resource),
      clienteResource: this.$api.cliente(this.$resource),
      produtoResource: this.$api.produto(this.$resource),
      divisaoResource: this.$api.divisao(this.$resource),
      tipoInvestimentoResource: this.$api.tipoInvestimento(this.$resource),
      extensaoResource: this.$api.extensao(this.$resource),

      metadadosParametrizado: null,
      planilhaPronta: false,

      valorQtdLinhasAdicionar: 0,
    };
  },
  methods: {
    getObjetoLinha() {
      const linhasInvestimento = [];
      const linhas = this.linhasValidas();
      linhas.forEach((linha) => {
        const { id } = linha;
        const chaveLinha = this.identificadorLinha(linha);
        const indiceLinha = this.getIndiceLinha(chaveLinha);

        const linhaInvestimento = { id, indiceLinha };

        this.formataValoresPadrao(linha, linhaInvestimento);
        this.formataExtensoes(linha, linhaInvestimento);
        this.formataCamposDinamicos(linha, linhaInvestimento);
        this.formataDesdobramento(linha, linhaInvestimento);

        linhasInvestimento.push(linhaInvestimento);
      });

      return { linhasInvestimento };
    },
    formataValoresPadrao(linha, linhaInvestimento) {
      const colunas = this.colunas.map((col) => col.field),
        colunasPadrao = this.nomesColunasPadrao;

      colunas
        .filter((coluna) => colunasPadrao.indexOf(coluna) >= 0)
        .filter((coluna) => linha[coluna] != null && linha[coluna] !== '')
        .forEach((coluna) => {
          linhaInvestimento[camelCase(coluna)] = linha[coluna];
        });
    },
    formataExtensoes(linha, linhaInvestimento) {
      const colunas = this.colunas.map((col) => col.field),
        colunasExtensao = this.nomesColunasExtensao,
        metadados = this.getLinhaInvestimentoMetadado,
        dependencias = metadados.mapaEntidades;

      const mapaExtensoes = {};
      linhaInvestimento.mapaExtensoes = mapaExtensoes;

      colunas
        .filter((coluna) => colunasExtensao.indexOf(coluna) >= 0)
        .filter((coluna) => linha[coluna] != null && linha[coluna] !== '')
        .forEach((coluna) => {
          const nivelExtensao = getNivelExtensao(metadados, coluna),
            codNomExtensao = linha[coluna];
          const chaveMapa = dependencias[coluna].campo;

          mapaExtensoes[chaveMapa] = { ...nivelExtensao, codNomExtensao };
        }, this);
    },
    formataCamposDinamicos(linha, linhaInvestimento) {
      const colunasDinamicas = this.nomesColunasDinamicas;

      const mapaCamposDinamicos = {};
      linhaInvestimento.mapaCamposDinamicos = mapaCamposDinamicos;

      this.colunas
        .filter((coluna) => {
          const hash = coluna.field,
            label = this.controleColunas.hash[hash];
          return colunasDinamicas.indexOf(label) >= 0;
        })
        .filter((coluna) => linha[coluna.field] != null && linha[coluna.field] !== '')
        .forEach((coluna) => {
          const hash = coluna.field;
          let valor = linha[hash];
          if (coluna.type === 'BOOLEAN') {
            valor = valor === 'SIM';
          }

          const label = this.controleColunas.hash[hash];
          mapaCamposDinamicos[label] = valor;
        }, this);
    },
    formataDesdobramento(linha, linhaInvestimento) {
      const colunas = this.colunas.map((col) => col.field),
        colunasPeriodo = this.colunasPeriodos.map((col) => col.field);

      colunas
        .filter((coluna) => colunasPeriodo.indexOf(coluna) >= 0)
        .forEach((hash) => {
          if (!linhaInvestimento.desdobramentos) {
            linhaInvestimento.desdobramentos = [];
          }
          const { desdobramentos } = linhaInvestimento;
          const desdobramento = {
            valor: linha[hash],
            periodo: this.controleColunas.hash[hash],
          };
          desdobramentos.push(desdobramento);
        }, this);
    },
    datasourceAlterado(alteracao) {
      this.atualizaValorRemanescente();
      this.trataAlteracao(alteracao);
    },
    atualizaValorRemanescente() {
      if (this.colunasPeriodos.length === 0) return;

      const colsPeriodo = this.colunasPeriodos.map((col) => col.field);
      const linhas = this.linhasValidas();
      const valorDistribuido = linhas
        .map((linha) => colsPeriodo
          .map((col) => linha[col])
          .filter((valor) => valor != null)
          .reduce((previo, atual) => previo + atual, 0))
        .reduce((previo, atual) => previo + atual, 0);

      this.$emit('OrcamentoFormLinhas__AtualizaValorRemanescente', valorDistribuido);
    },
    linhasValidas() {
      const datasource = this.$refs.spreadsheet.getDataSource();
      return datasource
        .filter((r) => r.descricao_linha && r.descricao_linha !== '');
    },
    exportarSpreadsheet() {
      this.$refs.spreadsheet.exportarExcel();
    },
    iniciaPlanilha() {
      this.montaColunas();
      this.preencheDadosIniciais(() => {
        this.aplicaEstiloColunas();
        this.planilhaPronta = true;
      });
    },
    cruzaMetadados(metadadosCampo, entidade) {
      const { mapaEntidades } = this.metadadosParametrizado;

      const dependenciasRef = Object.values(metadadosCampo.mapaEntidades)
        .map((dependencia) => dependencia.label);

      const dependenciasEntidade = Object.values(mapaEntidades);
      dependenciasEntidade
        .filter((dependencia) => dependenciasRef.indexOf(dependencia.label) >= 0)
        .forEach((dependencia) => {
          dependencia.dependentes.push(entidade.toLowerCase());
        });
    },
    configuraMetadadoCruzados() {
      const configuracoes = [
        {
          entidade: 'cliente',
          metadadosCampo: this.getClienteMetadado,
        },
        {
          entidade: 'produto',
          metadadosCampo: this.getProdutoMetadado,
        },
        {
          entidade: 'centro_custo',
          metadadosCampo: this.getCentroCustoMetadado,
        },
        {
          entidade: 'divisao',
          metadadosCampo: this.getDivisaoMetadado,
        },
      ];

      configuracoes.forEach((cfg) => {
        const { metadadosCampo, entidade } = cfg;
        this.cruzaMetadados(metadadosCampo, entidade);
      }, this);
    },
    adicionarLinhas() {
      this.rowsNumber += this.valorQtdLinhasAdicionar;
      if (this.rowsNumber > 1500) {
        this.rowsNumber = 1500;
      }
      this.valorQtdLinhasAdicionar = 0;
      setTimeout(() => this.$refs.spreadsheet.recriar());
      this.aplicaEstiloColunas();
    },
  },
  mounted() {
    const metadados = this.getLinhaInvestimentoMetadado;
    this.metadadosParametrizado = this.configuracao.aplicaParametrizacaoLinha(metadados);

    this.configuraMetadadoCruzados();
    this.iniciaPlanilha();
  },
};
</script>
