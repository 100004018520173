import getBasePath from '../../functions/api-resource';
import resourceBuilder from '../../functions/metadados-resource-builder';

const basePath = getBasePath('apuracao_acao', 'contrato');

const apuracaoActions = {
  buscarApuracao: { method: 'GET', url: `${basePath}/listagem/carregamento{/idApuracao}` },
  concluir: { method: 'PUT', url: `${basePath}{/idContrato}/acao/{idAcao}/apuracao{/idApuracao}/concluir` },
  buscarDataApuracaoAcaoPorStatus: { method: 'GET', url: `${basePath}{/idContrato}/status/{status}/datas` },
  buscarApuracaoFinalizar: { method: 'GET', url: `${basePath}/apuracao/{idApuracao}/finalizar` },
};

export default (resource) => resource(`${basePath}`, {}, apuracaoActions);

export const listarApuracoesContratoPorStatus = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/por-status', parametros).doGet();

export const listarApuracoesPorContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/por-contrato', parametros).doGet();

export const listarApuracoesContratoNivel2 = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/nivel2', parametros).doGet();

export const listarApuracoesContratoNivel2Simplificado = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/nivel2/simplificado', parametros).doGet();

export const buscarTotalApuradoPorContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'apuracao/total', parametros).doGet();

export const buscarApuracaoFornecedorPorContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'fornecedor/apuracoes', parametros).doGet();

export const buscarApuracaoPorContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'apuracao/finalizar', parametros).doGet();

export const finalizarApuracaoLote = (parametros, resource, body) => resourceBuilder.buildPUT(resource, basePath, '{idContrato}/finalizar', parametros).doPut(parametros, body);

export const finalizarApuracoesIndustriaLote = (parametros, resource, body) => resourceBuilder.buildPUT(resource, basePath, 'finalizar/industria', parametros).doPut(parametros, body);
