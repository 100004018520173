<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ `${$t('title.configuracao')} - ${classificacao.nomExtensao}` }}</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-form ref="formConfiguracoes" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <input-decimal
                :label="$tc('label.ponto', 2)"
                v-model="classificacao.pontos"
                :rules="[rules.numeroValido]"
                :disabled="somenteLeitura"
                class="v-text-field"/>
            </v-col>
          </v-row>
          <v-row v-if="!somenteLeitura || alterarFinalizado">
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                id="parametrizacao_periodo_passo4_regional"
                name="parametrizacao_periodo_passo4_regional"
                item-text="nome"
                item-value="id"
                return-object
                multiple
                chips
                clearable
                :items="divisoes"
                v-model="classificacaoConfiguracao.divisoes"
                :label="$tc('label.divisao', 1)"
                :disabled="somenteLeitura && !alterarFinalizado">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                id="parametrizacao_periodo_passo4_extensao_produto"
                name="parametrizacao_periodo_passo4_extensao_produto"
                item-text="nomExtensao"
                item-value="id"
                return-object
                multiple
                chips
                clearable
                :items="extensoesProduto"
                v-model="classificacaoConfiguracao.extensoesProduto"
                :label="$t('label.grupo_culturas')"
                :disabled="somenteLeitura && !alterarFinalizado">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                id="parametrizacao_periodo_passo4_extensao_cliente"
                name="parametrizacao_periodo_passo4_extensao_cliente"
                item-text="nomExtensao"
                item-value="id"
                return-object
                multiple
                chips
                clearable
                :items="extensoesCliente"
                v-model="classificacaoConfiguracao.extensoesCliente"
                :label="alterarFinalizado ? `${$t('label.holding')} *` : `${$t('label.holding')}`"
                :rules="[rules.requiredParaAlteracao]"
                :disabled="somenteLeitura && !alterarFinalizado">
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="!somenteLeitura || alterarFinalizado">
            <v-col cols="12" sm="6" md="4">
              <input-decimal
                :label="`${$tc('label.potuacao_minima_marca_forte', 2)} *`"
                v-model="classificacaoConfiguracao.pontuacao"
                :disabled="somenteLeitura && !alterarFinalizado"
                class="v-text-field"/>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <input-decimal
                :label="`${$t('label.pontuacao_minima_total')} *`"
                v-model="classificacaoConfiguracao.pontuacaoTotal"
                :disabled="somenteLeitura && !alterarFinalizado"
                class="v-text-field"/>
            </v-col>
          </v-row>
          <v-row v-if="!somenteLeitura || alterarFinalizado">
            <v-col cols="12" sm="6" md="4">
              <input-decimal
                :label="`${$t('label.crescimento_marca_forte')} *`"
                v-model="classificacaoConfiguracao.crescimento"
                :disabled="somenteLeitura && !alterarFinalizado"
                suffix="%"
                class="v-text-field"/>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <input-decimal
                :label="`${$t('label.crescimento_total')} *`"
                v-model="classificacaoConfiguracao.crescimentoTotal"
                :disabled="somenteLeitura && !alterarFinalizado"
                suffix="%"
                class="v-text-field"/>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <input-decimal
                :label="`${$t('label.crescimento_seguimento')} *`"
                v-model="classificacaoConfiguracao.crescimentoSegmento"
                :disabled="somenteLeitura && !alterarFinalizado"
                suffix="%"
                class="v-text-field"/>
            </v-col>
          </v-row>
          <v-row justify="end" v-if="!somenteLeitura || alterarFinalizado">
            <v-btn color="secondary" @click="limpar" class="mr-3">{{ $t('label.limpar') }}</v-btn>
            <v-btn color="primary" @click="adicionar" class="mr-3">{{ edicaoConfiguracao ?
                                                                   $t('label.salvar') :
                                                                   $t('label.adicionar') }}</v-btn>
          </v-row>
        </v-form>
        <v-row>
          <v-data-table
            style="width: 100%;"
            :headers="headers"
            :items="classificacao.classificacoesConfiguracao">
            <template v-slot:item.action="{ item }">
              <v-btn
                text
                icon
                @click="editar(item)"
                v-if="!somenteLeitura || (alterarFinalizado && itemPossuiExtensoes(item))">
                <v-icon>edit</v-icon>
              </v-btn>
              <v-btn text icon @click="excluir(item)" v-if="!somenteLeitura">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.nomesDivisoes="{ item }">
              {{ item.divisoes && item.divisoes.length ? item.nomesDivisoes : $t('label.todos') }}
            </template>
            <template v-slot:item.nomesExtensoesProduto="{ item }">
              {{ item.extensoesProduto && item.extensoesProduto.length ? item.nomesExtensoesProduto : $t('label.todos') }}
            </template>
            <template v-slot:item.nomesExtensoesCliente="{ item }">
              {{ item.extensoesCliente && item.extensoesCliente.length ? item.nomesExtensoesCliente : $t('label.todos') }}
            </template>
            <template v-slot:item.pontuacao="{ item }">
              {{ getNumber(item.pontuacao) }}
            </template>
            <template v-slot:item.pontuacaoTotal="{ item }">
              {{ getNumber(item.pontuacaoTotal) }}
            </template>
            <template v-slot:item.crescimento="{ item }">
              {{ getPercent(item.crescimento) }}
            </template>
            <template v-slot:item.crescimentoTotal="{ item }">
              {{ getPercent(item.crescimentoTotal) }}
            </template>
            <template v-slot:item.crescimentoSegmento="{ item }">
              {{ getPercent(item.crescimentoSegmento) }}
            </template>
          </v-data-table>
        </v-row>
      </v-container>

    </v-card>
  </v-dialog>
</template>

<script>
import Classificacao from './Classificacao';
import InputDecimal from '../../../../produto/shared-components/inputs/InputDecimal';
import ClassificacaoConfiguracao from './ClassificacaoConfiguracao';
import { getNumber, getPercent } from '../../../../produto/common/functions/helpers';

export default {
  name: 'ClassificacaoConfiguracoes',
  components: { InputDecimal },
  props: {
    classificacao: {
      type: Classificacao,
      default: () => (new Classificacao()),
    },
    idPeriodo: {
      type: Number,
      default: null,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parametrizacaoPeriodoPasso3: this.$api.parametrizacaoPeriodoPasso3(this.$resource),
      parametrizacaoPeriodoPasso4: this.$api.parametrizacaoPeriodoPasso4(this.$resource),
      classificacaoConfiguracao: new ClassificacaoConfiguracao(),
      dialog: false,
      divisoes: [],
      extensoesProduto: [],
      extensoesCliente: [],
      index: 0,
      carregado: false,
      edicaoConfiguracao: false,
      rules: {
        numeroValido: (value) => {
          let msg = this.$t('message.numero_invalido');
          if (!value || value < 0) {
            msg = this.$t('errors.valor.minimo', { min: '0.01' });
          }
          return (!!value && !Number.isNaN(value.toString()) && Number(value) > 0) || msg;
        },
        requiredParaAlteracao: (value) => (!this.alterarFinalizado
                                 || (this.alterarFinalizado && !!value && value.length > 0))
                                 || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    headers() {
      const headers = [];
      headers.push({ text: '', sortable: false, value: 'action' });
      headers.push({ text: this.$tc('label.divisao', 1), value: 'nomesDivisoes' });
      headers.push({ text: this.$t('label.grupo_culturas'), value: 'nomesExtensoesProduto' });
      headers.push({ text: this.$t('label.holding'), value: 'nomesExtensoesCliente' });
      headers.push({ text: this.$tc('label.potuacao_minima_marca_forte', 2), value: 'pontuacao' });
      headers.push({ text: this.$t('label.pontuacao_minima_total'), value: 'pontuacaoTotal' });
      headers.push({ text: this.$t('label.crescimento_marca_forte'), value: 'crescimento' });
      headers.push({ text: this.$t('label.crescimento_total'), value: 'crescimentoTotal' });
      headers.push({ text: this.$t('label.crescimento_seguimento'), value: 'crescimentoSegmento' });
      return headers;
    },
  },
  watch: {
    classificacao(val) {
      if (val) {
        this.preencherIndex();
      }
    },
  },
  methods: {
    getNumber,
    getPercent,
    limpar() {
      this.classificacaoConfiguracao = new ClassificacaoConfiguracao();
      this.edicaoConfiguracao = false;
      this.$refs.formConfiguracoes.resetValidation();
    },
    excluir(item) {
      this.classificacao.removerItem(item);
      this.limpar();
    },
    editar(item) {
      this.edicaoConfiguracao = true;
      this.classificacaoConfiguracao = new ClassificacaoConfiguracao(this.classificacao
        .retornarConfiguracao(item));
    },
    adicionar() {
      if (this.validarConflito()) {
        this.$toast(this.$t('message.configuracao_conflito'));
        return;
      }
      if (!this.$refs.formConfiguracoes.validate()) {
        return;
      }
      if (!this.classificacaoConfiguracao.index) {
        this.index += 1;
        this.classificacaoConfiguracao.index = this.index;
        this.classificacao.classificacoesConfiguracao.push(this.classificacaoConfiguracao);
      } else {
        const classificacaoConf = this.classificacao
          .retornarConfiguracao(this.classificacaoConfiguracao);
        classificacaoConf.divisoes = this.classificacaoConfiguracao.divisoes;
        classificacaoConf.extensoesProduto = this.classificacaoConfiguracao.extensoesProduto;
        classificacaoConf.extensoesCliente = this.classificacaoConfiguracao.extensoesCliente;
        classificacaoConf.pontuacao = this.classificacaoConfiguracao.pontuacao;
        classificacaoConf.pontuacaoTotal = this.classificacaoConfiguracao.pontuacaoTotal;
        classificacaoConf.crescimento = this.classificacaoConfiguracao.crescimento;
        classificacaoConf.crescimentoTotal = this.classificacaoConfiguracao.crescimentoTotal;
        classificacaoConf.crescimentoSegmento = this.classificacaoConfiguracao.crescimentoSegmento;
      }
      this.limpar();
    },
    validarConflito() {
      return this.classificacao.validarConflitosVinculos(this.classificacaoConfiguracao);
    },
    open() {
      this.dialog = true;
      if (!this.carregado) {
        this.carregado = true;
        this.buscarDivisoes();
        this.buscarExtensoesProduto();
      }
      this.buscarExtensoesCliente();
    },
    close() {
      if (this.classificacao.pontos >= 0) {
        this.dialog = false;
      }
    },
    preencherIndex() {
      this.index = 0;
      this.classificacao.classificacoesConfiguracao.forEach((ind) => {
        this.index += 1;
        ind.index = this.index;
      });
    },
    buscarDivisoes() {
      this.parametrizacaoPeriodoPasso3.buscarDivisoes()
        .then((response) => {
          this.divisoes = response.body;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarExtensoesProduto() {
      const param = { mnemonico: 'GRUPO_CULTURA' };
      this.buscarExtensoes(param,
        (response) => {
          this.extensoesProduto = response.body;
        });
    },
    buscarExtensoesCliente() {
      const param = {
        mnemonico: 'HOLDING',
        id: this.idPeriodo,
      };
      this.buscarExtensoes(param,
        (response) => {
          this.extensoesCliente = response.body;
        });
    },
    buscarExtensoes(param, cb) {
      this.parametrizacaoPeriodoPasso3.buscarExtensoes(param)
        .then(cb)
        .catch((err) => {
          this.$error(this, err);
        });
    },
    itemPossuiExtensoes(item) {
      if (item.extensoesCliente && item.extensoesCliente.length) {
        return true;
      }
      return false;
    },
  },
};
</script>
