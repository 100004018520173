<template>
  <div>
    <v-data-table
      :headers="cabecalhoPorTipoVerba()"
      :items="tipoVerba.orcamentos"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20],
      }">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td v-if="canEdit || canAccessPageRateio">
              <v-menu bottom
                origin="center center"
                transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on"
                    icon
                    text>
                    <v-icon id="more_vert">more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="orcamentoEmCadastro(item) && canEdit"
                    @click="editarOrcamento(item.id)">
                    <v-list-item-action>
                      <v-icon>edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.editar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mostrarDetalhar(item)"
                    @click="visualizarOrcamento(item.id)">
                    <v-list-item-action>
                      <v-icon>details</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="orcamentoAprovado(item) && canAccessPageRateio"
                    @click="ratearOrcamento(item.id)">
                    <v-list-item-action>
                      <v-icon>scatter_plot</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.rateio', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mostrarCancelar(item)"
                    @click="confirmarCancelarAcao(item)">
                    <v-list-item-action>
                      <v-icon>block</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.cancelar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mostrarAcompanhamento(item)"
                    @click="abrirAcompanhamento(item)">
                    <v-list-item-action>
                      <v-icon>assignment_turned_in</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.acompanhamento', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo"
                    @click="abrirJustificativaAprovar(item)">
                    <v-list-item-action>
                      <v-icon>thumb_up</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.aprovar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo"
                    @click="abrirJustificativaAnalise(item)">
                    <v-list-item-action>
                      <v-icon>chat_bubble</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.enviar_analise', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.aprovadorFluxo"
                    @click="abrirJustificativaReprovar(item)">
                    <v-list-item-action>
                      <v-icon>thumb_down</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.reprovar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="orcamentoEmAnalise(item) && canEdit && item.solicitanteFluxo"
                    @click="confirmarExecutarAnalise(item)">
                    <v-list-item-action>
                      <v-icon>edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.executar_analise', 1) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td v-for="col in colunasPorTipoVerba()" :key="col">
              <status
                v-if="col === 'status'"
                :status="item.status"
                :tooltip="item.status">
              </status>
              <span v-else>
                {{formataValorColuna(col, item)}}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
    />
    <orcamento-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :observacao="justificativa"
      :somenteLeitura="justificativaSomenteLeitura"
    />
    <confirm
      ref="confirmDialog"
      :persistent="true"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import { buscaOrcamentos } from '../../common/resources/orcamento-listagem';
import { getMoney } from '../../common/functions/helpers';
import Acompanhamento from '../../shared-components/workflow/Acompanhamento';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import Status from '../../shared-components/Status';

import OrcamentoListDadosTabelaFluxo from './OrcamentoListDadosTabelaFluxo';
import OrcamentoJustificativa from './OrcamentoJustificativa';

export default {
  components: {
    Status,
    Acompanhamento,
    OrcamentoJustificativa,
    Confirm,
  },
  mixins: [
    OrcamentoListDadosTabelaFluxo,
  ],
  computed: {
    ...mapGetters('metadados', [
      'getOrcamentoMetadado',
    ]),
    ...generateComputed('ORC', [
      'canEdit',
    ]),
    metadadosOrcamento() {
      if (this.getOrcamentoMetadado && this.getOrcamentoMetadado.mapaEntidades) {
        return this.getOrcamentoMetadado;
      }
      return undefined;
    },
    canAccessPageRateio() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ORC_RATEIO').length;
    },
  },
  props: {
    tipoVerba: Object,
    ordenacao: Array,
    filtros: Object,
  },
  watch: {
    pagination: {
      handler(pagination) {
        if (this.mesmaPagina(pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = pagination;
        this.buscarOrcamentos();
      },
      deep: true,
    },
  },
  data() {
    return {
      orcamentoResource: this.$api.orcamento(this.$resource),

      pagination: {},
      totalPage: 0,
      lastPagination: {},
      cabecalhoFixo: [
        { text: this.$tc('label.status', 1), value: 'status', sortable: false },
        {
          text: this.$tc('label.descricao', 1), value: 'descricao', sortable: false, width: '20%',
        },
        {
          text: this.$tc('label.periodo', 1),
          value: 'periodo',
          sortable: false,
          class: 'OrcamentoListDados__ColunaGrande',
        },
        { text: this.$tc('label.periodo_orcamentario', 1), value: 'periodo_orcamentario', sortable: false },
        { text: this.$tc('label.planejado', 1), value: 'planejado', sortable: false },
        { text: this.$tc('label.comprometido', 1), value: 'comprometido', sortable: false },
        { text: this.$tc('label.consumido', 1), value: 'consumido', sortable: false },
        { text: this.$tc('label.reservado', 1), value: 'reservado', sortable: false },
        { text: this.$tc('label.adendo', 1), value: 'adendo', sortable: false },
        { text: this.$tc('label.reducao', 1), value: 'reducao', sortable: false },
        { text: this.$tc('label.remanescente', 1), value: 'remanescente', sortable: false },
        { text: this.$tc('label.apurado', 1), value: 'apurado', sortable: false },
        { text: this.$tc('label.unidade_negocio', 1), value: 'unidadenegocio', sortable: false },
        { text: this.$tc('label.saldo', 1), value: 'saldo', sortable: false },
        { text: this.$tc('label.cliente', 1), value: 'cliente', sortable: false },
        { text: this.$tc('label.produto', 1), value: 'produto', sortable: false },
        { text: this.$tc('label.centro_custo', 1), value: 'centro_custo', sortable: false },
        { text: this.$tc('label.acoes', 2), value: 'acoes', sortable: false },
      ],
    };
  },
  methods: {
    getMoney,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    buscarOrcamentos() {
      const params = {
        id_tipo_verba: this.tipoVerba.id,
        ...this.filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };
      buscaOrcamentos(params, this.$resource)
        .then((res) => {
          this.totalPage = res.data.quantidadeRegistrosPagina;
          this.tipoVerba.orcamentos = [...res.data.resposta];

          const { orcamentos } = this.tipoVerba;
          const promises = [
            ...orcamentos.map((o) => this.verificarUsuarioAprovador(o)),
            ...orcamentos.map((o) => this.verificarUsuarioSolicitante(o)),
            ...orcamentos.map((o) => this.buscarStatusFluxo(o)),
          ];

          if (promises.length > 0) {
            Promise.all(promises).then(() => this.$forceUpdate());
          } else {
            setTimeout(() => this.$forceUpdate());
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    editarOrcamento(idOrcamento) {
      this.$router.push({ name: 'editarOrcamento', params: { idOrcamento, from: 'orcamento' } });
    },
    visualizarOrcamento(idOrcamento) {
      this.$router.push({ name: 'verOrcamento', params: { idOrcamento, from: 'orcamento' } });
    },
    ratearOrcamento(idOrcamento) {
      this.$router.push({ name: 'rateioOrcamento', params: { idOrcamento } });
    },
    cabecalhoPorTipoVerba() {
      const { tipoVerba } = this;
      if (tipoVerba.cabecalho) return tipoVerba.cabecalho;

      const ordenacao = this.colunasPorTipoVerba(),
        cabecalho = [];

      ordenacao.forEach((col) => {
        let isColFixa = false;
        for (let i = 0; i < this.cabecalhoFixo.length; i += 1) {
          const defCol = this.cabecalhoFixo[i];
          isColFixa = defCol.value === col;
          if (isColFixa) {
            cabecalho.push(defCol);
            break;
          }
        }
        if (!isColFixa) {
          cabecalho.push({
            text: this.tituloCampoDinamico(col),
            value: col,
            sortable: false,
          });
        }
      }, this);
      if (this.canEdit || this.canAccessPageRateio) {
        const colAcoes = this.cabecalhoFixo[this.cabecalhoFixo.length - 1];
        tipoVerba.cabecalho = [colAcoes, ...cabecalho];
      } else {
        tipoVerba.cabecalho = [...cabecalho];
      }

      return tipoVerba.cabecalho;
    },
    colunasPorTipoVerba() {
      return this.ordenacao;
    },
    tituloCampoDinamico(nomeCampo) {
      const dependencia = this.getDepenciaMetadadosPorNome(nomeCampo);
      if (dependencia != null) {
        return dependencia.entidadeEstrangeira;
      }
      const campoDinamico = this.getCampoDinamicoPorNome(nomeCampo);
      return campoDinamico.nomCampo;
    },
    formataValorColuna(nomeColuna, item) {
      const coluna = this.cabecalhoFixo.filter((c) => c.value === nomeColuna)[0],
        colunaFixa = coluna != null;

      if (colunaFixa) {
        const chave = coluna.exibicao || coluna.value;
        return this.ehCampoDinheiro(chave)
          ? this.getMoney(item[chave])
          : item[chave];
      }

      if (this.ehDependenciaExtensao(nomeColuna)) {
        return this.formataValorExtensao(nomeColuna, item);
      }

      // neste caso é um campo dinamico
      // TODO: implementar tratativa de formatador(?)
      return item[nomeColuna];
    },
    ehCampoDinheiro(coluna) {
      const camposDinheiro = ['planejado', 'comprometido', 'consumido', 'saldo', 'adendo', 'reservado', 'apurado', 'remanescente'];
      return camposDinheiro.indexOf(coluna) >= 0;
    },
    formataValorExtensao(coluna, item) {
      const dependencia = this.getDepenciaMetadadosPorNome(coluna),
        valorConcatenado = item[coluna];

      if (!valorConcatenado) return '';

      if (dependencia.isManyToMany) {
        return valorConcatenado.split(',')
          .map((p) => this.extraiNomeExtensao(p))
          .join(', ');
      }

      return this.extraiNomeExtensao(valorConcatenado);
    },
    extraiNomeExtensao(valorConcatenado) {
      const valores = valorConcatenado.split('|'),
        nomExtensao = valores[valores.length - 1];
      return nomExtensao;
    },
    ehDependenciaExtensao(nomeCampo) {
      return this.getDepenciaMetadadosPorNome(nomeCampo) != null;
    },
    getDepenciaMetadadosPorNome(nomeCampo) {
      const dependencias = this.metadadosOrcamento.mapaEntidades,
        dependencia = dependencias[nomeCampo];
      return dependencia;
    },
    getCampoDinamicoPorNome(nomeCampo) {
      const campos = this.metadadosOrcamento.mapaCamposDinamicos,
        campo = campos[nomeCampo];
      return campo;
    },
  },
};
</script>
<style>
.OrcamentoListDados__ColunaGrande {
  min-width: 210px;
}
</style>
