import getBasePath from '../../produto/common/functions/api-resource';

const basePathCadastro = getBasePath('api');
export const basePath = getBasePath('job');

const importacaoActions = {
  // Multipart POST
  importar: { method: 'POST', url: `${basePath}importacao?tipo_importacao={tipoImportacao}&nome_arquivo={nomeArquivo}` },
  listarAndamento: { method: 'GET', url: `${basePathCadastro}importacao/andamento` },
  listarErro: { method: 'GET', url: `${basePathCadastro}importacao/erro` },
  listarFinalizado: { method: 'GET', url: `${basePathCadastro}importacao/finalizado` },
  listarTipos: { method: 'GET', url: `${basePathCadastro}tipo-importacao` },
  listarDetalhesErros: { method: 'GET', url: `${basePathCadastro}importacao/erro-detalhes?tamanhoPagina={size}&numeroPagina={page}&idErro={idErro}` },
  listarUltimasSellInSellOut: { method: 'GET', url: `${basePathCadastro}importacao/sellin-sellout` },
};

export default (resource) => resource(`${basePath}`, {}, importacaoActions);
