<template lang="html">
  <v-navigation-drawer
    v-model="abrirMenu"
    app
    temporary
    style="overflow: hidden"
    width="350"
    class="SideBar__Menu">

    <div style="display: flex;margin: 0px; height: 100px;">
      <div class="imagem_menu" style="margin-left: 15px;">
        <img :src="require(`../../assets/img/logo_novo.svg`)" width="150px" height="80px" style="padding: 0;">
      </div>
      <div style="width: 100%;">
        <div @click="fechar()" style="width: 90px;height: 25px;display: flex;margin: 40px;margin-top: 45px;color: lightslategrey;">
          <img :src="require(`../../assets/img/close.svg`)" class="icon" width="24px">
          <span>Fechar</span>
        </div>
      </div>
    </div>
    <v-list style="height: 76vh; overflow-y: auto;" class="listaMenu">
      <template v-for="item in menuList">
        <v-list-group
          v-model="item.model"
          v-if="item.sub"
          :key="item.text"
          no-action
          >
          <div class="icon-container" slot="prependIcon" v-if="existeSvg(item.icon)">
            <object :ref="`icon_${item.icon}`" :data="require(`../../assets/img/${item.icon}.svg`)" type="image/svg+xml" :class="{svgIcon: isColored, iconStyle: true}" style="color: blue;"></object>
          </div>
          <v-icon v-else slot="prependIcon" style="color: blue;">{{ item.icon }}</v-icon>
          <v-list-item slot="activator" @click="paintButton(`icon_${item.icon}`)">
            <v-list-item-content>
              <v-list-item-title class="black-text">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(child, i) in item.sub"
            :key="i"
            @click="goToPage(child.route); paintButton(`sub_icon_${child.icon}`)"
          >
            <v-list-item-action v-if="child.icon">
              <v-icon :ref="`sub_icon_${child.icon}`" class="subIcon">{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="black-text">
                {{ child.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item v-else @click="goToPage(item.route); paintButton(`sub_icon_${child.icon}`)">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="black-text">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <v-footer class="footer" inset style="margin-top: 13px;">
      <span>Tradelinks</span>
    </v-footer>

  </v-navigation-drawer>
</template>

<script type="text/javascript">
import { showLoading, hideLoading } from '../../common/functions/loading';

export default {
  props: {
    menuList: {
      type: Array,
      default() {
        return [];
      },
    },
    abrir: false,
    logo: String,
  },
  data() {
    return {
      abrirMenu: false,
      rotaAtual: null,
      isColored: false,
    };
  },
  watch: {
    abrir() {
      this.abrirMenu = !this.abrirMenu;
    },
  },
  methods: {
    paintButton(refName) {
      Object.keys(this.$refs).forEach((refs) => {
        if (refs === refName) {
          if (this.$refs[refs][0].classList) {
            this.$refs[refs][0].classList.toggle('svgIcon');
            if (refName.includes('sub')) {
              this.$refs[refs][0].classList.toggle('subIcon');
            }
          }
          if (this.$refs[refs][0].style) {
            this.$refs[refs][0].style.color = '#9D1D96';
          }
        } else {
          if (refName.includes('sub')) {
            if (this.$refs[refs][0].style) {
              this.$refs[refs][0].style.color = 'lightgray';
            }
            return;
          }
          if (this.$refs[refs][0].classList) {
            this.$refs[refs][0].classList.remove('svgIcon');
          }
        }
      });
    },
    fechar() {
      this.abrirMenu = !this.abrirMenu;
    },
    existeSvg(icon) {
      try {
        require(`../../assets/img/${icon}.svg`); //eslint-disable-line
        return true;
      } catch (error) { //eslint-disable-line
        return false;
      }
    },
    lockBodyScroll() {
      document.body.style.overflowY = 'hidden';
    },
    onItemClicked(item) {
      if (!item.sub) {
        this.goToPage(item.route);
      }
    },
    getAbsolutePath() {
      return window.location.hash ? window.location.hash.replace('#/', '') : '';
    },
    shouldReloadRoute(route) {
      const { current } = this.$router.history;
      return current.matched.filter((m) => m.name === route).length > 0;
    },
    goToPage(route) {
      this.abrirMenu = !this.abrirMenu;
      if (this.$route.name === route && this.$route.name !== 'importacoes') {
        return;
      }

      setTimeout(() => {
        if (!this.shouldReloadRoute(route)) {
          showLoading();
          this.$router
            .push({ name: route, params: { menu: true } }, null, () => hideLoading());
        } else {
          this.$router.go();
        }
      }, 500);
    },
    toggleLockBodyScroll() {
      document.body.style.overflowY = document.body.style.overflowY === 'hidden'
        ? 'auto'
        : 'hidden';
    },
    unlockBodyScroll() {
      document.body.style.overflowY = 'auto';
    },
    toggleExpand(...args) {
      $logger.log('Args', args);
    },
    resetSidebar() {
      this.rotaAtual = null;
    },
  },
  mounted() {
    $logger.log('Mounting', 'Sidebar');
  },
};
</script>

<style src="./Sidebar.scss" lang="scss"></style>
<style lang="scss">
  @import '~@/config/theme/theme.scss';
  .imagem_menu {
    padding: 10px;
    height: 18vh;
    max-height: 6vw !important;
    min-height: 6em !important;
  }
  .imagem_menu img {
    height: 15vh !important;
    max-height: 5vw !important;
    min-height: 5em !important;
    margin: auto;
    display: block;
  }
  .black-text {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .footer {
    color: $primary-color !important;
  }
  .listaMenu .v-list-group__header__prepend-icon {
    justify-content: center;
  }
  .v-application--is-ltr .v-list-group--no-action>.v-list-group__items>div>.v-list-item {
    padding-left: 3em;
  }
  .SideBar__Menu .v-list-group .v-list-group__header .v-list-item {
    padding-left: 0px;
  }
  .v-list-item__action .fa {
    padding-left: 0.2em;
  }
  .color {
    background-color: aqua;
  }
  .material-symbols-outlined {
    font-variation-settings: 'FILL' 0, 'wght' 400 !important;
  }
  .icon-container {
    color: blue; /* This color will apply to the SVG path */
    fill: blue;
    width: 24px;
    height: 24px;
  }
  .iconStyle {
    color: greenyellow;
    fill: blue;
  }
  .subIcon {
    color: #9D1D96;
  }
  .svgIcon {
    width: 100%;
    height: 100%;
    fill: blue;
    filter: brightness(0) saturate(100%) invert(15%) sepia(75%) saturate(4600%) hue-rotate(290deg) brightness(95%) contrast(108%),
  }
</style>
