<template>
  <div class="resumo-dpm">
    <div class="mt-2 mb-2 title-float accent--text">
      {{$tc('title.resumo', 1)}}
    </div>
    <div class="fill-height" style="height: 100%;" fill-height>
            <v-card>
              <v-container fluid grid-list-md v-show="resumoAberto">
                  <v-row>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        v-if="objetoPassoGeral.holding"
                        v-model="objetoPassoGeral.holding.nomExtensao"
                        :disabled="true"
                        :label="`${$tc('label.holding', 1)}`"/>
                      <v-text-field
                        v-else
                        v-model="objetoPassoGeral.cliente.codNome"
                        disabled
                        :label="`${$tc('label.cliente', 1)}`"/>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-switch
                        v-if="passoAberto === 2"
                        v-model="resumo.pgtoForcaVendas"
                        :label="$tc('label.aceitar_forca_venda', 1)"
                        :disabled="somenteLeitura"
                      ></v-switch>
                      <v-text-field
                        v-if="passoAberto !== 2"
                        v-model="objetoPassoGeral.divisao.nome"
                        :disabled="true"
                        :label="`${$tc('label.divisao', 1)}`"/>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        v-model="objetoPassoGeral.periodoPlanejamento.nome"
                        :disabled="true"
                        :label="`${$tc('label.periodo', 1)}`"/>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        v-if="passoAberto !== 2"
                        v-model="resumo.ratingCredito"
                        :disabled="true"
                        :label="`${$t('label.rating_credito')}`"/>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        v-if="passoAberto === 2"
                        v-model="objetoPassoGeral.divisao.nome"
                        :disabled="true"
                        :label="`${$tc('label.divisao', 1)}`"/>
                        <v-switch
                        v-if="passoAberto !== 2"
                        v-model="resumo.pgtoForcaVendas"
                        :label="$t('label.aceitar_forca_venda')"
                        :disabled="somenteLeitura"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-switch
                        v-model="resumo.termoAdesao"
                        :disabled="somenteLeitura"
                        :label="$tc('label.termo_adesao', 1)"
                        ></v-switch>
                    </v-col>
                  </v-row>
              </v-container>
            </v-card>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    objetoPassoGeral: {
      type: Object,
      required: true,
    },
    somenteLeitura: Boolean,
    novoContrato: Boolean,
    contratoEdicao: Object,
    passoAberto: Number,
  },
  data() {
    return {
      planoMetasResource: this.$api.planejamentoPlanoMetas(this.$resource),
      resumoAberto: true,
      resumo: {
        ratingCredito: null,
        termoAdesao: false,
        pgtoForcaVendas: false,
      },
    };
  },
  methods: {
    toggleResumo() {
      this.resumoAberto = !this.resumoAberto;
    },
    objTermoEPgto() {
      return {
        termoAdesao: this.resumo.termoAdesao,
        pgtoForcaVendas: this.resumo.pgtoForcaVendas,
      };
    },
    buscarRating() {
      const { cliente, holding } = this.objetoPassoGeral;
      const parametros = {};
      if (holding) {
        parametros.idHolding = holding.id;
      } else {
        parametros.idCliente = cliente.id;
      }
      this.planoMetasResource.buscarRating(parametros).then((res) => {
        this.resumo.ratingCredito = res.bodyText;
      });
    },
  },
  mounted() {
    if (!this.novoContrato) {
      const { passoAcoes } = this.contratoEdicao;
      const { planoMetas } = passoAcoes;
      this.resumo.termoAdesao = planoMetas.termoAdesao;
      this.resumo.pgtoForcaVendas = planoMetas.pgtoForcaVendas;
    }
    this.buscarRating();
  },
};
</script>
<style>
.resumo-dpm input {
  color: rgb(0, 0, 0) !important;
}
.resumo-dpm label{
  color: rgb(0, 0, 0) !important;
}
</style>
