let el = null;
const getLoadingElement = () => {
  if (el === null) {
    el = document.getElementById('spinner-loading');
  }
  return el;
};

// let requestCounter = 0;
let skip = false;

export const showLoading = () => {
  // requestCounter += 1;
  if (!skip) {
    const element = getLoadingElement();
    if (element != null && element.style.display !== 'block') {
      element.style.display = 'block';
    }
  } else {
    skip = false;
  }
};

export const hideLoading = () => {
  setTimeout(() => {
    const element = getLoadingElement();
    if (element != null && element.style.display !== 'none') {
      element.style.display = 'none';
    }
  }, 2E2);
};

export const skipLoading = () => {
  skip = true;
};

export const showInitialLoading = () => {
  showLoading();
  getLoadingElement().style.opacity = 1;
};

export const hideInitialLoading = () => {
  hideLoading();
  getLoadingElement().style.opacity = null;
};
