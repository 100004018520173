import ParametrizacaoPeriodo from '../../spa/parametrizacao/periodo/ParametrizacaoPeriodo';
import ParametrizacaoPeriodoList from '../../spa/parametrizacao/periodo/ParametrizacaoPeriodoList';
import ParametrizacaoPeriodoForm from '../../spa/parametrizacao/periodo/ParametrizacaoPeriodoForm';

export default {
  path: 'parametrizacao-periodo',
  component: ParametrizacaoPeriodo,
  children: [
    {
      path: '',
      name: 'parametrizacao-periodo',
      component: ParametrizacaoPeriodoList,
    },
    {
      path: 'novo',
      name: 'novoParametrizacaoPeriodo',
      component: ParametrizacaoPeriodoForm,
      props: {
        somenteLeitura: false,
      },
    },
    {
      path: ':id/editar',
      name: 'editarParametrizacaoPeriodo',
      component: ParametrizacaoPeriodoForm,
      props: {
        somenteLeitura: false,
      },
    },
    {
      path: ':id/alterar',
      name: 'alterarParametrizacaoPeriodo',
      component: ParametrizacaoPeriodoForm,
      props: {
        somenteLeitura: true,
        alterarFinalizado: true,
      },
    },
    {
      path: ':id',
      name: 'verParametrizacaoPeriodo',
      component: ParametrizacaoPeriodoForm,
      props: {
        somenteLeitura: true,
      },
    },
  ],
};
