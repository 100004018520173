<template>
  <v-dialog v-model="showModal" max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $tc('title.erro', 2) }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="cabecalho"
          :items="erros"
          hide-default-footer>
          <template v-slot:item.erro="{ item }">
            {{ item.texto }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click.native="confirma">{{ $t('label.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'OrcamentoRateioValidacao',
  data() {
    return {
      showModal: false,
      erros: [],
      cabecalho: [
        {
          text: this.$tc('label.linha_investimento', 1), value: 'linha', sortable: false, width: '35%',
        },
        { text: this.$tc('label.erro', 1), value: 'erro', sortable: false },
      ],
      mapaCampos: {},
      erro: '',
    };
  },
  methods: {
    confirma() {
      this.showModal = false;
    },
    apresentaErrosServidor(res) {
      const objErros = JSON.parse(res.bodyText);
      this.erros = [];

      const { rateio } = objErros;
      rateio.forEach((erro) => {
        const { linha } = erro;
        const texto = this.montaMsgErro(erro);
        this.erros.push({ linha, texto });
      });

      this.showModal = true;
    },
    montaMsgErro(erro) {
      const { mnemonico } = erro;
      if (mnemonico === 'SALDO_NEGATIVO') {
        return this.$t('errors.rateio.saldo_negativo', erro);
      }
      return 'wut?';
    },
  },
};
</script>
