<template>
  <div>
    <v-container fluid px-0 py-2 class='Container_Tabela'>
      <v-row d-flex align="center" justify="left">
        <v-col cols="12">
          <v-text-field
            v-model="pesquisaTabela"
            append-icon="search"
            :label="`${$tc('label.pesquisar', 1)}`"
            class="Barra_Pesquisa mb-2"
            single-line
            hide-details
            clearable
          ></v-text-field>
          <v-data-table
            id="tabela_apuracoes"
            show-select
            :headers="headers"
            :items="listaApuracoes"
            :search="pesquisaTabela"
            :options.sync="pagination"
            :server-items-length="totalPage"
            :items-per-page="rowsPerPageItems"
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :rows-per-page-text="$t('label.linhas_por_pagina')"
            >
            <template slot="items" slot-scope="props">
              <tr :class="['text-sm-center']">
                <td>{{ props.item.cod_acao }}</td>
                <td>{{ props.item.cod_apuracao }}</td>
                <td>{{ props.item.descricao_acao }}</td>
                <td nowrap>{{ props.item.data_inicio }}</td>
                <td nowrap>{{ props.item.data_fim }}</td>
                <td>{{ props.item.divisao }}</td>
                <td>{{ props.item.unidade_negocio }}</td>
                <td>
                  {{ props.item.is_foco_cliente
                    ? props.item.nom_cliente
                    : props.item.is_foco_regional
                        ? props.item.nom_regional
                        : props.item.holding }}
                </td>
                <td>
                  {{ props.item.is_origem_fornecedor
                    ? props.item.nome_fornecedor
                    : props.item.nome_grupo_fornecedor }}
                </td>
                <td class="Botoes_Acao" nowrap :class="['text-sm-left']">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click.native="abrirModalConfirmacao('editar')" v-on="on" text icon>
                        <v-icon class="fa-sm">edit</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('label.executar_analise') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click.native="abrirModalConfirmacao('enviar_analise')" v-on="on" text icon>
                        <v-icon class="fa-sm">cached</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('label.enviar_analise') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click.native="abrirModalConfirmacao('aprovar')" v-on="on" text icon>
                        <v-icon class="fa-sm">fa-thumbs-up</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('label.aprovar') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click.native="abrirModalConfirmacao('reprovar')" v-on="on" text icon>
                        <v-icon class="fa-sm">fa-thumbs-down</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('label.reprovar') }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <template slot="pageText" slot-scope="props">
              {{ props.pageStart }} - {{ props.pageStop }} {{$tc('label.de', 1)}} {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    <confirm
      ref="modalConfirmacao"
      :message="mensagemConfirmacao.text"
      @agree="validarAcao">
    </confirm>
    </v-container>
  </div>
</template>

<script>
import {
  getMoney,
  getPercent,
} from '../../common/functions/helpers';
import { listarApuracoesNivel2 } from '../../common/resources/apuracao/apuracao-acao-listagem';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'ApuracaoAcaoDadosNivel1Lista',
  components: {
    Confirm,
  },
  data() {
    return {
      activeBtn: 'STATUS',
      visao: 'Status',
      pesquisaTabela: '',
      mensagemConfirmacao: '',
      listaApuracoes: [],
      filtrosAplicados: this.filtros,
      rowsPerPageItems: [8],
      filtros: null,
      pagination: {
        rowsPerPage: 8,
        page: 1,
      },
      totalPage: 0,
      headers: [
        { text: this.$tc('label.cod_acao', 1), value: 'cod_acao', sortable: true },
        { text: this.$tc('label.cod_apuracao', 1), value: 'cod_apuracao', sortable: true },
        { text: this.$tc('label.descricao_acao', 1), value: 'descricao_acao', sortable: true },
        { text: this.$tc('label.inicio', 1), value: 'data_inicio', sortable: true },
        { text: this.$tc('label.fim', 1), value: 'data_fim', sortable: true },
        { text: this.$tc('label.divisao', 1), value: 'divisao', sortable: true },
        { text: this.$tc('label.unidade_negocio', 1), value: 'unidade_negocio', sortable: true },
        { text: this.$tc('label.foco_acao', 1), value: 'foco_acao', sortable: true },
        { text: this.$tc('label.origem_acao', 1), value: 'origem_acao', sortable: true },
        { text: '', value: '', sortable: false },
      ],
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscar();
      },
    },
    pagination: {
      handler() {
        if (this.filtros) {
          this.buscar();
        }
      },
      deep: true,
    },
  },
  computed: {
  },
  methods: {
    getMoney,
    getPercent,

    buscar() {
      const params = {
        ...this.filtrosAplicados,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };

      listarApuracoesNivel2(params, this.$resource)
        .then((response) => {
          this.listaApuracoes = response.data.resposta;
          this.totalPage = response.data.quantidadeRegistrosPagina;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },

    abrirModalConfirmacao(acao) {
      if (acao === 'editar') {
        this.mensagemConfirmacao = this.$t('message.deseja_editar_item');
      } else if (acao === 'enviar_analise') {
        this.mensagemConfirmacao = this.$t('message.deseja_enviar_analise');
      } else if (acao === 'aprovar') {
        this.mensagemConfirmacao = this.$t('message.deseja_aprovar_item');
      } else if (acao === 'exportar') {
        this.mensagemConfirmacao = this.$t('message.deseja_exportar');
      } else {
        this.mensagemConfirmacao = this.$t('message.deseja_reprovar_item');
      }
      setTimeout(() => this.$refs.modalConfirmacao.open());
    },
    validarAcao() {
    },
  },
  beforeMount() {
  },
  mounted() {
    if (this.filtros) {
      this.filtrosAplicados = this.filtros;
      this.buscar();
    }
  },
};
</script>

<style>
.Container_Tabela table.v-table tbody td:first-child,.Container_Tabela  table.v-table tbody td:not(:first-child),
  .Container_Tabela table.v-table tbody th:first-child, .Container_Tabela table.v-table tbody th:not(:first-child),
  .Container_Tabela table.v-table thead td:first-child, .Container_Tabela table.v-table thead td:not(:first-child),
  .Container_Tabela table.v-table thead th:first-child, .Container_Tabela table.v-table thead th:not(:first-child) {
  padding: 0 11px;
}
</style>
