<template lang="html">
  <div>
    <div v-if="termos.length > 0 && !aceito">
      <termo-aceite-login
          :termos="termos"
          :title="implantacao.nome"
          @ACEITO="termoAceito">
      </termo-aceite-login>
    </div>
    <div v-if="aplicacaoLiberada">
      <sidebar
        ref="sidenav"
        :abrir="abrirMenu"
        :title="implantacao.nome"
        :logo="implantacao.logomarca"
        :perfilUsuario="usuarioLogado.perfil"
        :nomeUsuario="usuario"
        :estruturaUsuario="usuarioLogado.estrutura_usuario"
        :menuList="filteredMenuList"></sidebar>

      <toolbar class="Home__PageTeller"
        :title="implantacao.nome"
        :hideMenu="hideMenu"
        :usuario="usuario"
        :perfil="usuarioLogado.perfil"
        :estruturaUsuario="usuarioLogado.estrutura_usuario"
        :mail="usuarioLogado.email"
        scroll-target="#content"
        @TOGGLE_SIDEBAR="toggleSidenav"
        @RESET_SIDEBAR="resetSidebar"></toolbar>

      <v-main id="content">
        <v-container
          class="fill-height"
          fluid>
          <v-row style="padding-bottom: 4em" v-if="dicionarioCarregado">
            <v-col cols="12">
              <transition name="slide-fade"
                          mode="out-in" v-if="dicionarioCarregado">
                <router-view></router-view>
              </transition>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </div>

    <div id="spinner-loading">
      <div>
        <v-container fluid>
          <v-row align="center" justify="center">
            <v-progress-circular
              indeterminate
              :size="100"
              :width="3"
              color="primary"
            />
          </v-row>
        </v-container>
      </div>
    </div>

    <confirm ref="modalSessao"
      :persistent="true"
      :message="$t('errors.sessao_expirada')"
      :title="$t('title.sessao_expirada')"
      :agree-label="$t('label.ok')"
      hide-disagree
      @agree="logoutAceito()"
      />

    <confirm ref="modalErroInicializacaoAplicacao"
      :persistent="true"
      :message="mensagemModalInicializacao"
      :title="$tc('title.aviso', 1)"
      :agree-label="$t('label.ok')"
      :disagree-label="$t('label.recarregar')"
      @disagree="recarregarAplicacao()"
      @agree="fecharModalErro()"
      />
    <snackbar></snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Sidebar from '@/shared-components/sidebar/Sidebar';
import Toolbar from '@/shared-components/toolbar/Toolbar';
import menuList from '@/menu/menu';
import Snackbar from '@/produto/shared-components/snackbar/Snackbar';
import resourceImportacao from '../common/resources/importacoes';
import resourceDicionario from '../common/resources/dicionario';
import resourceUsuario from '../common/resources/usuario';
import resourceControladorTrade from '../common/resources/controlador-trade/controlador-trade';
import { auth } from '../config/sso/auth';
import { showInitialLoading, hideInitialLoading } from '../common/functions/loading';
import TermoAceiteLogin from './termo-aceite/TermoAceiteLogin';
import resourceTermoAceite from '../common/resources/termo-aceite';
import resourceUsuarioPreCadastro from '../common/resources/usuario-pre-cadastro';
import Confirm from '../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'Home',
  data() {
    return {
      importacaoResources: resourceImportacao(this.$resource),
      dicionarioResources: resourceDicionario(this.$resource),
      usuarioResources: resourceUsuario(this.$resource),
      controladorResources: resourceControladorTrade(this.$resource),
      termoAceiteResources: resourceTermoAceite(this.$resource),
      usuarioPreCadastroResources: resourceUsuarioPreCadastro(this.$resource),
      aplicacaoLiberada: false,
      dicionarioCarregado: false,
      eventLoop: null,
      eventLoopTimer: 10E3,
      mensagemModalInicializacao: '',
      menuList: menuList(this),
      usuarioInfo: {},
      sombraToolbar: false,
      abrirMenu: false,
      recarregar: false,
      termos: [],
      aceito: false,
    };
  },
  components: {
    Snackbar,
    Sidebar,
    Toolbar,
    TermoAceiteLogin,
    Confirm,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
      'getUsuario',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('implantacao', [
      'implantacao',
      'indModoVarejo',
    ]),
    ...mapGetters('parametros', [
      'parametros',
    ]),
    canAccessPageList() {
      if (!this.getAllRoles) {
        return [];
      }
      return this.getAllRoles
        .filter((el) => el.indexOf('_PAGINA') !== -1 || el.indexOf('_CRUD') !== -1);
    },
    canImport() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.endsWith('_IMPORTAR') || el.endsWith('_CRUD')).length;
    },
    filteredMenuList() {
      if (!this.getAllRoles || !this.getAllRoles.length) {
        return [];
      }
      const filter = [];
      const routesFiltradas = [];
      this.menuList
        .forEach((m) => {
          if (m.sub) {
            const subFiltrado = this.filtrarSubMenu(m.sub);
            if (subFiltrado.length) {
              m.sub = subFiltrado;
              filter.push(m);
            }
          } else {
            this.getAllRoles.forEach((r) => {
              if (this.validarRotaPorRole(m, routesFiltradas, r)) {
                filter.push(m);
                routesFiltradas.push(m.route);
              }
            });
          }
        });
      return filter;
    },
    hideMenu() {
      return !this.filteredMenuList.length;
    },
    pageName() {
      return this.$route.name;
    },
    usuario() {
      return this.getUsuario;
    },
    state() {
      return this.$route.name;
    },
    path() {
      return this.$route.path;
    },
  },
  methods: {
    ...mapActions('importacao', [
      'popularImportacoesAndamento',
      'popularTiposImportacao',
    ]),
    ...mapActions('usuario', [
      'carregarInformacoesUsuario',
    ]),
    ...mapActions('controlador', [
      'carregarControladores',
    ]),
    ...mapActions('implantacao', [
      'carregarImplantacao',
    ]),
    filtrarSubMenu(subMenu) {
      const filter = [];
      const routesFiltradas = [];
      subMenu.forEach((m) => {
        this.getAllRoles.forEach((r) => {
          if (this.validarRotaPorRole(m, routesFiltradas, r)) {
            filter.push(m);
            routesFiltradas.push(m.route);
          }
        });
      });
      return filter;
    },
    validarRotaPorRole(menu, routesFiltradas, role) {
      return ((Array.isArray(menu.role) && menu.role.includes(role)) || role.startsWith(menu.role))
        && !routesFiltradas.includes(menu.route);
    },
    buscarImportacoesAndamento() {
      if (this.canImport) {
        this.popularImportacoesAndamento(this.importacaoResources.listarAndamento)
          .then(() => {
            this.resetEventLoop(10E3);
          });
      }
    },
    buscarTiposImportacao() {
      if (this.canImport) {
        this.popularTiposImportacao({
          resource: this.importacaoResources.listarTipos,
          roles: this.getAllRoles,
        });
      }
    },
    resetEventLoop(timer) {
      if (this.eventLoopTimer === timer) {
        return;
      }
      window.clearInterval(this.eventLoop);
      this.eventLoopTimer = timer;
      this.eventLoopImportacoes();
    },
    eventLoopImportacoes() {
      if (!this.eventLoop && this.canImport) {
        this.eventLoop = setInterval(() => {
          this.buscarImportacoesAndamento();
        }, this.eventLoopTimer);
      }
    },
    toggleSidenav() {
      this.abrirMenu = !this.abrirMenu;
    },
    resetSidebar() {
      this.$refs.sidenav.resetSidebar();
    },
    logoutAceito() {
      this.$refs.modalSessao.close();
      auth.doLogout();
    },
    iniciaAplicacao() {
      this.aplicacaoLiberada = true;
      this.dicionarioCarregado = true;
      this.buscarImportacoesAndamento();
      this.buscarTiposImportacao();
    },
    recarregarAplicacao() {
      window.location.reload();
    },
    fecharModalErro() {
      this.$refs.modalErroInicializacaoAplicacao.close();
    },
    carregarTermos() {
      return this.termoAceiteResources.buscarTermosLogin()
        .then((response) => {
          this.termos = response.data;
        });
    },
    termoAceito() {
      this.aceito = true;
      this.iniciaAplicacao();
      setTimeout(() => this.$vuetify.goTo(0), 3000);
      setTimeout(() => hideInitialLoading(), 3000);
    },
    carregarInformacoes() {
      showInitialLoading();
      Promise.all([
        this.carregarInformacoesUsuario(this.usuarioResources.informacao),
        this.carregarControladores(this.controladorResources.listar),
        this.carregarImplantacao(this.dicionarioResources.implantacao),
      ])
        .then(() => this.carregarTermos())
        .then(() => {
          if (this.termos.length === 0) {
            this.iniciaAplicacao();
            setTimeout(() => hideInitialLoading(), 3000);
          }
        })
        .catch((e) => {
          if (e.status === 403) {
            this.mensagemModalInicializacao = this.$t('errors.inicializacao_sem_perfil');
          } else {
            this.mensagemModalInicializacao = this.$t('errors.inicializacao');
          }
          this.$refs.modalErroInicializacaoAplicacao.open();
          setTimeout(() => hideInitialLoading());
          window.setTimeout(() => { throw e; });
        });
    },
    preCadastroUsuario() {
      showInitialLoading();
      this.usuarioPreCadastroResources.primeiroAcesso()
        .then((res) => {
          hideInitialLoading();
          if (res.data) {
            this.recarregarAplicacao();
          } else {
            this.carregarInformacoes();
          }
        }).catch((err) => {
          this.carregarInformacoes();
          this.$error(this, err, null, 'snack', 0);
        });
    },
  },
  mounted() {
    auth.registerSessionListener({
      onSessionExpired: this.$refs.modalSessao.open,
    });

    this.preCadastroUsuario();
  },
  beforeDestroy() {
    window.clearInterval(this.eventLoop);
  },
};
</script>
<style lang="scss">
@import '~@/config/theme/theme.scss';

.HOME__logo_div {
  width: 120px;
  height: 120px;
  padding: 35px;
  position: absolute;
}

.HOME__logo svg path {
  fill: $primary-color;
}

#spinner-loading > div {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: #eae9e9;
  opacity: .7;
}
</style>
