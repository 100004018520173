import vanillaMasker from 'vanilla-masker';
import { convertFloatToMoney } from './helpers';

export const convertToMoney = (value) => {
  if (!value) {
    return 'R$ 0,00';
  }
  const valor = convertFloatToMoney(value);
  if (value < 0) {
    return `R$ -${vanillaMasker.toMoney(valor)}`;
  }
  return `R$ ${vanillaMasker.toMoney(valor)}`;
};

export const convertToPercent = (value) => {
  if (!value) {
    return '0,00 %';
  }
  const valor = convertFloatToMoney(value);
  if (value < 0) {
    return `-${vanillaMasker.toMoney(valor)} %`;
  }
  return `${vanillaMasker.toMoney(valor)} %`;
};

export const convertToNumberFormat = (value) => {
  if (!value) {
    return '0';
  }
  const valor = convertFloatToMoney(value);
  if (value < 0) {
    return `-${vanillaMasker.toMoney(valor)}`;
  }
  return `${vanillaMasker.toMoney(valor)}`;
};

export const numberBetweenValues = (n, a, b) => a <= n && (!b || b >= n);
