import getBasePath from '../functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'tipo-contrato');

const tipoContratoActions = {
  ...genericActions(basePath),
  buscarAtivos: { method: 'GET', url: `${basePath}/ativos` },
  buscarTipoContratoComConfiguracao: { method: 'GET', url: `${basePath}/{idTipoContrato}/configuracao` },
  buscarTipoContrato: { method: 'GET', url: `${basePath}/{id}` },
};

export default (resource) => resource(`${basePath}`, {}, tipoContratoActions);
