<template>
  <v-card>
    <v-card-title>
      <h3 class="headline mb-0">{{ $tc('label.cliente', 1) }}</h3>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          v-model="search"
          @input="filtrar"
          append-icon="search"
          :label="$t('label.pesquisar')"
          single-line
          clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="clientes"
      :options.sync="pagination"
      :server-items-length="totalPage"
      class="elevation-1"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item.id_externo="{ item }">
        {{ item.idExterno }}
      </template>
      <template v-slot:item.nom_cliente="{ item }">
        {{ item.nomCliente }}
      </template>
      <template v-slot:item.des_cnpj="{ item }">
        {{ item.documento }}
      </template>
      <template v-slot:item.acoes="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" class="mx-0" @click="verCliente(item)" v-if="canAccessPage">
              <v-icon>people_outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.visualizar_cliente') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon class="mx-0" v-on="on" @click="editCliente(item)" v-if="canAccessCRUD">
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.editar_cliente') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click.native="cancelar" color="accent">{{ $t('label.cancelar') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  name: 'ClientesCarteiraClientes',
  props: {
    idCarteiraCliente: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      clientes: [],
      headers: [
        { text: this.$tc('label.codigo', 1), value: 'id_externo' },
        { text: this.$tc('label.nome', 1), value: 'nom_cliente' },
        { text: this.$tc('label.documento', 1), value: 'des_cnpj' },
        { text: '', value: 'acoes', sortable: false },
      ],
      search: '',
      resources: this.$api.carteiraCliente(this.$resource),
      esperar: false,
      pagination: {},
      totalPage: 0,
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  computed: {
    ...generateComputed('Cliente', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
  },
  methods: {
    cancelar() {
      this.$emit('cancelar');
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },
    verCliente(item) {
      this.$router.push({ name: 'verCliente', params: { id: item.id, from: 'carteira-cliente' } });
    },
    editCliente(item) {
      this.$router.push({ name: 'editarCliente', params: { id: item.id, from: 'carteira-cliente' } });
    },
    buscar() {
      const params = {
        id: this.idCarteiraCliente,
        filter: this.search,
        ativo: true,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.resources.obterClientes(params).then((response) => {
        this.clientes = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
