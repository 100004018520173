import getBasePath from '../../../produto/common/functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'parametrizacao-periodo');

const grupoActions = {
  obterPasso5: { method: 'GET', url: `${basePath}/passo5/{id}` },
  salvarRascunhoPasso5: { method: 'PUT', url: `${basePath}/passo5/{id}/rascunho` },
  salvarPasso5: { method: 'PUT', url: `${basePath}/passo5/{id}` },
};

export default (resource) => resource(`${basePath}`, {}, grupoActions);
