<template>
  <v-row style="text-align:right">
    <v-col cols="12">
      <slot name="acoes-padrao" />
      <v-btn @click="salvarAntesDeSolicitar()"
      class="mr-3"
        dark
        color="red"
        v-show="podeSolicitarAprovacao"
        :disabled="!permiteSalvar">
        {{ $t('label.solicitar_aprovacao') }}
      </v-btn>
      <v-btn @click="abrirJustificativaAprovar()"
      class="mr-3"
        dark
        color="primary"
        v-show="podeAprovar">
        {{ $t('label.aprovar') }}
      </v-btn>
      <v-btn @click="abrirJustificativaReprovar()"
      class="mr-3"
        dark
        color="red"
        v-show="podeAprovar">
        {{ $t('label.reprovar') }}
      </v-btn>
      <v-btn @click="abrirJustificativaAnalise()"
      class="mr-3"
        dark
        color="primary"
        v-show="podeAprovar">
        {{ $tc('label.enviar_analise', 1) }}
      </v-btn>
      <confirm
        ref="fluxoSemUsuarioClienteDialog"
        :message="msgFluxoSemAprovador"
        :persistent="true"
        @agree="iniciaFluxo"/>
      <planejamento-acao-justificativa
        ref="modalJustificativa"
        :obrigatorio="justificativaObrigatoria"
        :salvarJustificativa="actionJustificativa"/>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import Confirm from '../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  props: {
    permiteSalvar: Boolean,
    edicao: Boolean,
    acaoEdicao: Object,
    acaoSalvar: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      workflowAcaoPreviaResource: this.$api.workflowAcaoPrevia(this.$resource),

      solicitanteFluxo: false,
      aprovadorFluxo: false,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      msgFluxoSemAprovador: '',
    };
  },
  components: {
    PlanejamentoAcaoJustificativa,
    Confirm,
  },
  computed: {
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    isFocoCliente() {
      return this.acaoEdicao && this.acaoEdicao
        && (this.acaoEdicao.cliente || this.acaoEdicao.holding);
    },
    podeSolicitarAprovacao() {
      return this.edicao && this.acaoEdicao
        && this.acaoEdicao.status === 'EM_CADASTRO' && this.solicitanteFluxo;
    },
    podeAprovar() {
      return this.acaoEdicao
        && this.acaoEdicao.status === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo;
    },
  },
  methods: {
    carregaInformacoesFluxo(idAcao) {
      if (this.acaoEdicao.status === 'EM_CADASTRO') {
        this.verificarUsuarioSolicitante(idAcao);
      } else {
        this.verificarUsuarioAprovador(idAcao);
        this.buscarStatusFluxo(idAcao);
      }
    },
    verificarUsuarioSolicitante(idAcaoPrevia) {
      if (this.acaoEdicao && this.acaoEdicao.status === 'EM_CADASTRO') {
        this.workflowAcaoPreviaResource.solicitante({ idAcaoPrevia })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
          });
      }
    },
    salvarAntesDeSolicitar() {
      this.acaoSalvar().then(() => this.solicitarAprovacao());
    },
    solicitarAprovacao() {
      this.iniciaFluxo();
    },
    iniciaFluxo() {
      const idAcaoPrevia = this.$route.params.idAcao;
      this.workflowAcaoPreviaResource.iniciaFluxo({ idAcaoPrevia }, { idAcaoPrevia })
        .then(() => {
          this.$router.push({ name: 'acao-previa' });
          this.$toast(this.$t('message.solicitacao_aprovacao'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificarUsuarioAprovador(idAcaoPrevia) {
      if (this.acaoEdicao && this.acaoEdicao.status === 'AGUARDANDO_APROVACAO') {
        this.workflowAcaoPreviaResource.aprovador({ idAcaoPrevia })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            }
          });
      }
    },
    abrirJustificativaAprovar() {
      this.justificativaObrigatoria = false;
      this.actionJustificativa = this.aprovar;
      this.$refs.modalJustificativa.open();
    },
    aprovar(justificativa) {
      const idAcaoPrevia = this.$route.params.idAcao;
      this.workflowAcaoPreviaResource.aprovarPasso({ idAcaoPrevia },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'acao-previa' });
          this.$toast(this.$t('message.acao_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaReprovar() {
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.reprovar;
      this.$refs.modalJustificativa.open();
    },
    reprovar(justificativa) {
      const idAcaoPrevia = this.$route.params.idAcao;
      this.workflowAcaoPreviaResource.reprovar({ idAcaoPrevia },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'acao-previa' });
          this.$toast(this.$t('message.acao_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaAnalise() {
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.enviarAnalise;
      this.$refs.modalJustificativa.open();
    },
    enviarAnalise(justificativa) {
      const idAcaoPrevia = this.$route.params.idAcao;
      this.workflowAcaoPreviaResource.enviarAnalise({ idAcaoPrevia },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'acao-previa' });
          this.$toast(this.$t('message.acao_enviada_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarStatusFluxo(idAcaoPrevia) {
      this.workflowAcaoPreviaResource.status({ idAcaoPrevia })
        .then((response) => {
          this.acaoEdicao.fluxo = response.data;
        });
    },
  },
};
</script>
