<template>
  <v-card style="margin-bottom:1em" class="RelatorioExportacaoSaldo">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.relatorio_saldo', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px">
          fa-thumbtack
        </v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :objeto="filtrosExportacaoPagamento"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { skipLoading } from '../../../common/functions/loading';
import MetadadosContainerLayout from '../../../shared-components/metadados/MetadadosContainerLayout';
import RelatorioExportacaoSaldoFiltrosCampos from './RelatorioExportacaoSaldoFiltrosCampos';
import { countRelatorioSaldo } from '../../../common/resources/quantidade-registro-relatorio';

export default {
  name: 'RelatorioExportacaoSaldo',
  mixins: [
    RelatorioExportacaoSaldoFiltrosCampos,
  ],
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {

      ordenacaoCampos: [
        'data_inicio',
        'data_fim',
        'tipo_verba',
        'unidadenegocio',
        'divisao',
        'holding',
      ],
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosExportacaoPagamento: {},
    };
  },
  methods: {
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio || filtrosAplicados.data_fim) {
        const dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        const dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        if (dtFinal.diff(dtInicio, 'days') > 365) {
          this.$toast(this.$t('message.data_intervalo_invalido_ano'));
          return false;
        }

        return true;
      }
      return true;
    },
    resetaFiltros() {
      this.filtrosExportacaoPagamento = {};
    },
    aplicarFiltros() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroPeriodoValido(this.$refs.container.getValoresCamposPadrao())) {
        return;
      }
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };

      let quantidadeRegistros = 0;
      countRelatorioSaldo(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > 0) {
            this.$emit('RelatorioExportacaoSaldo__AplicaFiltros', params, 'saldo', 'orcamento');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'ajuste-verba';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'ajuste-verba';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosAjusteVerba;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
  },
};
</script>
<style>
  .RelatorioExportacaoSaldo__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioExportacaoSaldo__Opcoes div.v-input__slot,
  .RelatorioExportacaoSaldo__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioExportacaoSaldo__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioExportacaoSaldo__Opcoes label {
    font-size: 14px;
  }
</style>
