<template>
  <parametrizacao-periodo-passo
    tem-proximo
    tem-anterior
    :somente-leitura="somenteLeitura"
    :alterar-finalizado="alterarFinalizado"
    @PARAMETRIZACAO_PERIODO_PASSO_VOLTAR="voltarPasso"
    @PARAMETRIZACAO_PERIODO_PASSO_PROXIMO="salvarEProximoPasso"
    @PARAMETRIZACAO_PERIODO_PASSO_SALVAR_RASCUNHO="salvarRascunho">
    <v-container fluid>

      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ $tc('title.ranking', 1) }}</v-toolbar-title>
      </v-toolbar>

      <ranking
        class="my-3"
        :somente-leitura="somenteLeitura"
        :alterar-finalizado="alterarFinalizado"
        :passo4="passo4"
        @Ranking__removerClassificacao="remover">
      </ranking>

      <v-toolbar color="primary" dark class="mt-6">
        <v-toolbar-title>{{ $t('title.configuracao') }}</v-toolbar-title>
      </v-toolbar>

      <classificacoes-cards
        ref="classifiadoresCards"
        :id-periodo="idPeriodo"
        :classificacoes="passo4.classificacoes"
        :somente-leitura="somenteLeitura"
        :alterar-finalizado="alterarFinalizado"
        @ClassificacoesCards_remover="remover">
      </classificacoes-cards>

    </v-container>
    <confirm
      ref="confirmDialog"
      :hide-disagree="esconderDisagree"
      :message="textoMensagem"
      :agree-label="agreeLabel"
      @agree="funcaoAgree">
    </confirm>
  </parametrizacao-periodo-passo>
</template>

<script>
import ParametrizacaoPeriodoPasso from '../ParametrizacaoPeriodoPasso';
import ParametrizacaoPeriodo from '../ParametrizacaoPeriodo';
import Passo4 from './Passo4';
import Ranking from './Ranking';
import ClassificacoesCards from './ClassificacoesCards';
import Confirm from '../../../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  name: 'ParametrizacaoPeriodoPasso4',
  components: {
    Confirm,
    ClassificacoesCards,
    Ranking,
    ParametrizacaoPeriodoPasso,
  },
  props: {
    idPeriodo: {
      type: Number,
      default: null,
    },
    periodoConfiguracao: {
      type: ParametrizacaoPeriodo,
      default: null,
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    alterarFinalizado: {
      type: Boolean,
      default: false,
    },
    idsExtensoesRebate: {
      type: Object,
    },
    exibindo: false,
  },
  data() {
    return {
      parametrizacaoPeriodoPasso4: this.$api.parametrizacaoPeriodoPasso4(this.$resource),
      passo4: new Passo4(),
      classificacaoTemp: null,
      carregado: false,
      textoMensagem: '',
      esconderDisagree: true,
      agreeLabel: '',
      funcaoAgree: () => {},
    };
  },
  watch: {
    exibindo(val) {
      if (val && !this.carregado) {
        this.buscarPasso();
      }
    },
    idsExtensoesRebate(ids) {
      this.passo4.classificacoes.forEach((e) => {
        e.cardsRebate = ids[e.idExtensao];
      });
    },
  },
  methods: {
    remover(classificacao) {
      this.classificacaoTemp = classificacao;
      if (classificacao.cardsRebate) {
        this.esconderDisagree = true;
        const passo = `6 - ${this.$t('label.rebate')}`;
        this.textoMensagem = this.$t('message.remover_classificacao_bloqueado',
          { passo, card: classificacao.cardsRebate });
        this.funcaoAgree = () => {};
        this.agreeLabel = this.$t('label.ok');
      } else {
        this.esconderDisagree = false;
        this.textoMensagem = this.$t('message.deseja_excluir_configuracao_classificacao');
        this.funcaoAgree = this.removerClassificacao;
        this.agreeLabel = this.$t('label.sim');
      }
      this.$refs.confirmDialog.open();
    },
    configuracaoAlterada() {
      this.$emit('ParametrizacaoPeriodoPasso4_configuracaoAlterada', this.passo4.idsExtensoesCliente);
    },
    removerClassificacao() {
      this.passo4.removerClassificacao(this.classificacaoTemp);
    },
    buscarPasso() {
      if (!this.idPeriodo) return;
      const id = this.idPeriodo;
      this.parametrizacaoPeriodoPasso4.obterPasso4({ id })
        .then((response) => {
          this.passo4 = new Passo4(response.body);
        })
        .then(this.configuracaoAlterada)
        .catch((err) => {
          this.$error(this, err);
        });
    },
    voltarPasso() {
      this.$emit('PARAMETRIZACAO_PERIODO_PASSO4_VOLTAR');
    },
    salvarEProximoPasso() {
      if (this.somenteLeitura && !this.alterarFinalizado) {
        this.proximoPasso();
      } else {
        if (!this.passo4.classificacaoSelecionada) {
          this.$toast(this.$t('message.classificacao_nao_selecionada'));
          return;
        }
        if (!this.passo4.estaConfigurado) {
          this.$toast(this.$t('message.classificacao_nao_configurada'));
          return;
        }
        this.parametrizacaoPeriodoPasso4.salvarPasso4({ id: this.idPeriodo }, this.passo4)
          .then(() => {
            this.buscarPasso();
            this.proximoPasso();
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    proximoPasso() {
      this.$emit('PARAMETRIZACAO_PERIODO_PASSO4_PROXIMO', this.passo4);
    },
    salvarRascunho() {
      this.parametrizacaoPeriodoPasso4.salvarRascunhoPasso4({ id: this.idPeriodo }, this.passo4)
        .then(() => {
          this.$toast(this.$t('message.rascunho_salvo'));
          this.buscarPasso();
          this.$emit('PARAMETRIZACAO_PERIODO_PASSO4_RASCUNHO');
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
};
</script>
