<template>
  <v-row :class="layoutClass">
    <slot name="antes"></slot>
    <v-col cols="12" v-for="(metadados) in relacaoComponentesDinamicos"
      :sm="smCol"
      :md="mdCol"
      :key="metadados.label || metadados.labelCampo"
      :class="colClass">
      <metadados-componente-dinamico
        :metadados="metadados"
        :objeto="objeto"
        :ignora-obrigatorios="ignoraObrigatorios"
        :ignora-filtro-recursivo="explodeHierarquia"
        :novo-cadastro="novoCadastro"
        :get-valores-dependencias="getValoresDependenciasRecursivas"
        :somente-leitura="somenteLeitura"
        :filtro-rapido="filtroRapido"
        :grupo-ativo="grupoAtivo"
        :exibe-loading-overlay="exibeLoadingOverlay"
        ref="componentes"
        @MetadadosExtensao_FiltraAmostragemRecursiva="filtraAmostragemRecursiva"
        @MetadadosComponenteDinamico_valorAlterado="valorAlterado"
        @MetadadosComponenteDinamico_abrirCadastro="abrirCadastro"
        @MetadadosComponenteDinamico_GrupoAtivo="(val) => $emit('MetadadosContainerLayout_GrupoAtivo', val)">
      </metadados-componente-dinamico>
    </v-col>
    <metadados-filtros-recursivos ref="dialogFiltros"
      :metadados-entidade="metadadosEntidade"
      :objeto="objeto"
      :callback-filtro="filtroAplicado"
      v-if="!explodeHierarquia">
    </metadados-filtros-recursivos>
    <slot name="depois"></slot>
  </v-row>
</template>

<script>
import MetadadosComponenteDinamico from './MetadadosComponenteDinamico';
import MetadadosFiltrosRecursivos from './MetadadosFiltrosRecursivos';
import {
  ordenaComponentesDinamicos,
  formataDependencia,
} from './metadadosUtils';

import MetadadosContainerLayoutGetters from './MetadadosContainerLayoutGetters';
import MetadadosContainerLayoutRecursividade from './MetadadosContainerLayoutRecursividade';
import MetadadosContainerLayoutCruzador from './MetadadosContainerLayoutCruzador';

export default {
  name: 'MetadadosContainerLayout',
  mixins: [
    MetadadosContainerLayoutGetters,
    MetadadosContainerLayoutRecursividade,
    MetadadosContainerLayoutCruzador,
  ],
  components: {
    MetadadosComponenteDinamico,
    MetadadosFiltrosRecursivos,
  },
  props: {
    objeto: Object,
    metadadosEntidade: {
      type: Object,
      default: () => ({}),
    },
    inputLayout: {
      type: Object,
      default: () => ({
        sm12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
        md12: false,
      }),
    },
    layoutClass: {
      type: Object,
      default: null,
    },
    colClass: {
      type: Object,
      default: null,
    },
    formularioFiltros: {
      type: Boolean,
      default: false,
    },
    ignoraObrigatorios: {
      type: Boolean,
      default: false,
    },
    explodeHierarquia: {
      type: Boolean,
      default: false,
    },
    camposFormulario: {
      type: Object,
      default: () => ({ padrao: [] }),
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    novoCadastro: {
      type: Boolean,
      default: false,
    },
    ordenacaoCampos: {
      type: Array,
      default: () => ([]),
    },
    filtroRapido: {
      type: Boolean,
      default: false,
    },
    grupoAtivo: {
      type: Boolean,
      default: false,
    },
    exibeLoadingOverlay: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    metadadosCamposDinamicos() {
      return this.metadadosEntidade.mapaCamposDinamicos
        ? Object.values(this.metadadosEntidade.mapaCamposDinamicos) : [];
    },
    relacaoComponentesDinamicos() {
      const dependencias = this.dependenciasDiretas(),
        camposDinamicos = this.metadadosCamposDinamicos,
        camposFixos = (this.camposFormulario.padrao || []);

      return ordenaComponentesDinamicos(
        this.ordenacaoCampos,
        dependencias,
        camposDinamicos,
        camposFixos,
      )
        .filter((metadado) => this.testaCondicionalCampo(metadado));
    },
    smCol() {
      if (this.inputLayout.sm12) {
        return 12;
      }
      if (this.inputLayout.sm6) {
        return 6;
      }
      return undefined;
    },
    mdCol() {
      if (this.inputLayout.md3) {
        return 3;
      }
      if (this.inputLayout.md4) {
        return 4;
      }
      if (this.inputLayout.md6) {
        return 6;
      }
      if (this.inputLayout.md9) {
        return 9;
      }
      if (this.inputLayout.md12) {
        return 12;
      }
      return undefined;
    },
  },
  data() {
    return {
      dependenciasExtensoes: null,
      ready: false,
    };
  },
  methods: {
    /**
     * Atualiza os campos apos alteracao do objeto do container.
     * Ex:
     *
     *  this.produto.marca = {...};
     *  this.$refs.container.refresh();
     */
    refresh() {
      if (!this.$refs.componentes) return;
      this.$refs.componentes
        .forEach((componente) => componente.refresh());
    },
    dependenciasDiretas() {
      if (!this.containerPossuiMapaEntidades()) {
        return [];
      }

      const metadados = this.metadadosEntidade,
        { entidade } = metadados;

      if (!this.ready) {
        return [];
      }
      if (this.dependenciasExtensoes != null) {
        return this.dependenciasExtensoes;
      }

      const dependencias = Object.values(metadados.mapaEntidades);
      this.dependenciasExtensoes = dependencias
        .filter((dependencia) => dependencia.dependentes.indexOf(entidade) >= 0)
        .map((dependencia) => formataDependencia(dependencia, this.formularioFiltros));

      return this.dependenciasExtensoes;
    },
    valorAlterado(alteracao) {
      if (!this.containerPossuiMapaEntidades()) {
        return;
      }

      const { label, dependentes } = alteracao;
      const metadados = this.metadadosEntidade,
        extensoesDependentes = Object.values(metadados.mapaEntidades)
          .filter((dependente) => dependente.dependencias.indexOf(label) >= 0)
          .map((dependente) => dependente.label);

      const relDependentes = [
        ...dependentes,
        ...extensoesDependentes,
      ];

      relDependentes.forEach((dependente) => {
        const objetoDependente = this.objeto[dependente];
        if (objetoDependente) {
          this.objeto[dependente] = null;
        }
      }, this);

      this.$emit('MetadadosContainerLayout_valorAlterado', alteracao);
      this.refresh();
    },
    abrirCadastro(metadados) {
      this.$emit('MetadadosContainerLayout_abrirCadastro', metadados);
    },
    testaCondicionalCampo(campo) {
      if (!campo.desAtributos || !campo.desAtributos.condicional) {
        return true;
      }

      const { condicional } = campo.desAtributos;

      let validado = true;
      Object.keys(condicional).forEach((key) => {
        const esperado = condicional[key],
          atual = this.objeto[key];
        validado = validado && (esperado === atual);
      }, this);

      return validado;
    },
    containerPossuiMapaEntidades() {
      const { mapaEntidades } = this.metadadosEntidade;
      return !!mapaEntidades;
    },
  },
  mounted() {
    if (this.explodeHierarquia) {
      this.vinculaTopoHierarquiaComDependentesIndiretos();
      this.vinculaEntidadeComDependenciasIndiretas();
    }
    this.montaDependenciasCruzadas();
    this.ready = true;
    this.$emit('MetadadosContainerLayout_mounted');
  },
};
</script>
