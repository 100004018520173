import getBasePath from '../../../produto/common/functions/api-resource';
import resourceBuilder from '../../../produto/common/functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', 'adama/cadastro');

const planejamentoAcaoActions = {
  buscarHoldings: { method: 'GET', url: `${basePath}/campos/holding` },
  buscarClientes: { method: 'GET', url: `${basePath}/campos/cliente` },
  buscarClientesPagadores: { method: 'GET', url: `${basePath}/campos/cliente/pagador` },
  buscarPeriodosVigentePlanejamento: { method: 'GET', url: `${basePath}/campos/periodo/planejamento/vigente` },
};

export default (resource) => resource(`${basePath}`, {}, planejamentoAcaoActions);

export const buscarGruposCultura = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'campos/grupo_cultura', parametros).doGet();

export const buscarClusters = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'campos/cluster', parametros).doGet();
