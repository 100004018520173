<style>
  .PlanejamentoAcaoForm {
    margin-bottom: 70px;
    }
  .PlanejamentoAcaoForm .card-title {
    font-size: 18px;
    font-weight: 500;
  }
  .PlanejamentoAcaoForm .card-subtitle {
    font-size: 16px;
    font-weight: 400;
  }
</style>
<template>
  <div class="PlanejamentoAcaoForm" v-if="!!configuracao">
    <component v-for="(p, index) in componentesPassos" :key="index"
      :is="p.componente"
      :ref="p.nome"
      v-if="carregouEstrutura"
      v-show="exibePasso(p.componente)"
      :tipo-acao="tipoAcao"
      :configuracao="configuracao"
      :acao-edicao="acaoEdicao"
      :nova-acao="novo"
      :somente-leitura="naoPodeEditar"
      :passoAberto="passoAberto"
      :edicao="edicao"
      :passos-habilitados="passosHabilitados"
      :get-objeto-outro-passo="getObjetoOutroPasso"
      @PlanejamentoAcaoForm__AtualizaIndTemEvidencia="atualizaIndTemEvidencia"
      @PlanejamentoAcaoForm__evento="executarEvento"
      @PlanejamentoAcaoForm__cancelar="cancelarEdicao">
    </component>

    <div class="PlanejamentoAcaoForm_Acoes" v-if="carregouEstrutura">
      <planejamento-acao-form-rodape-novo
        ref="rodapeAcoes"
        :definicao-passos="planejamentoDefinicaoPassos"
        :edicao="edicao"
        :somente-leitura="naoPodeEditar"
        :acao-edicao="acaoEdicao"
        :configuracao="configuracao"
        :get-componentes-passos="getComponentesPassos"
        :valida-componente-passo="validaComponentePasso"
        :valida-componente-passo-salvar="validaComponentePassoSalvar"
        :indTemEvidencia="indTemEvidencia"
        :usuario-cliente="usuarioCliente"
        :usuario-cliente-pode-acessar-passo-contas="usuarioClientePodeAcessarPassoContas"
        @PlanejamentoAcaoFormRodape__MudaPasso="mudaPasso"
        @PlanejamentoAcaoForm__antesIrProximo="executarEvento"
        @PlanejamentoAcaoForm__executarEventoEncadeado="montarExecucaoEncadeada"
        />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import PlanejamentoAcaoFormRodapeNovo from './PlanejamentoAcaoFormRodapeNovo';
import AcaoConfiguracao from '../AcaoConfiguracao';
import PlanejamentoAcaoFormPassosMixin from './PlanejamentoAcaoFormPassosMixin';

export default {
  components: {
    PlanejamentoAcaoFormRodapeNovo,
  },
  mixins: [PlanejamentoAcaoFormPassosMixin],
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    usuarioCliente() {
      return this.usuarioLogado.tipo === 'CLIENTE';
    },
    carregouEstrutura() {
      return this.tipoAcao != null && this.configuracao != null;
    },
    naoPodeEditar() {
      return this.somenteLeitura
          || this.statusAcao !== 'EM_CADASTRO';
    },
    chavePassosHabilitados() {
      return Object.keys(this.passosHabilitados).filter((k) => this.passosHabilitados[k].habilitado);
    },
    passoComportamentoHabilitado() {
      return this.passosHabilitados.comportamento.habilitado;
    },
    usuarioClientePodeAcessarPassoContas() {
      const { passoGeral, passoContas } = this.acaoEdicao;
      if (this.novo || passoGeral.isClienteCriadorAcao) {
        return true;
      }
      if (passoContas && passoContas.tipoVerba) {
        const idTipoVerbaAcao = passoContas.tipoVerba.id;
        return this.tiposVerbaUsuarioLogado.includes(idTipoVerbaAcao);
      }
      return true;
    },
  },
  props: {
    edicao: Boolean,
    novo: Boolean,
    somenteLeitura: Boolean,
  },
  data() {
    return {
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      usuarioLogadoResources: this.$api.usuarioLogado(this.$resource),

      configuracao: null,
      tipoAcao: null,
      acaoEdicao: {},
      statusAcao: 'EM_CADASTRO',

      passoAberto: 1,

      tiposVerbaUsuarioLogado: [],

      indTemEvidencia: false,
    };
  },
  methods: {
    cancelarEdicao(forceVoltaParaLista = false) {
      this.$refs.rodapeAcoes.cancelar(forceVoltaParaLista);
    },
    exibePasso(componente) {
      return this.componentesPassos
        .map((p) => p.componente)
        .indexOf(componente) + 1 === this.passoAberto;
    },
    getObjetoOutroPasso(passo) {
      const componente = this.$refs[passo][0];
      if (componente.getObjetoPasso) {
        return componente.getObjetoPasso();
      }
      return null;
    },
    mudaPasso(passo) {
      if (passo > this.passoAberto) {
        this.avancaPasso(passo);
      }
      this.passoAberto = passo;
    },
    avancaPasso(passo) {
      const ref = this.chavePassosHabilitados[passo - 1];
      this.$refs[ref][0].abrePasso();
    },
    validaComponentePasso() {
      window.console.log('validaComponentePasso');
      const componente = this.$refs.geral[0];
      if (componente.valida) {
        return componente.valida();
      }
      return true;
    },
    validaComponentePassoSalvar(isSolicitandoAprovacao) {
      // const passos = this.chavePassosHabilitados;
      let valido = true;
      // for (let i = 0; i < passos.length && valido; i += 1) {
      //   const componente = this.$refs[passos[i]][0];
      //   window.console.log(passos[i]);
      //   if (componente.validaSalvar) {
      //     valido = componente.validaSalvar(isSolicitandoAprovacao);
      //   }
      // }
      const componente = this.$refs.geral[0];
      valido = componente.validaSalvar(isSolicitandoAprovacao);
      return valido;
    },
    carregarAcao(idAcao) {
      this.idAcao = idAcao;

      this.planejamentoAcaoListagemResource.buscarAcao({ idAcao })
        .then((res) => {
          this.acaoEdicao = { ...res.data };
          this.statusAcao = this.acaoEdicao.passoGeral.status;
          return this.acaoEdicao.passoGeral.idAcaoTipo;
        }).then((idAcaoTipo) => {
          Promise.all([
            this.carregarTipoAcao(idAcaoTipo),
            this.carregarConfiguracao(idAcaoTipo),
          ]).then(() => {
            setTimeout(() => this.$refs.rodapeAcoes.carregaInformacoesFluxo(idAcao));
          });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarConfiguracao(idTipoAcao) {
      const { idAcao } = this;

      const promise = this.novo
        ? this.configuracaoResource.buscarConfigVigente({ idTipoAcao })
        : this.configuracaoResource.buscarConfigSnapshot({ idAcao });

      return promise.then((res) => {
        this.configuracao = new AcaoConfiguracao(res.data);
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarTipoAcao(idTipoAcao) {
      return this.tipoAcaoResource.buscarTipoAcaoComConfiguracao({ idTipoAcao })
        .then((res) => {
          this.tipoAcao = res.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    getComponentesPassos() {
      const componentes = [];
      // this.componentesPassos.map((c) => c.nome).forEach((ref) => {
      //   const [referencia] = this.$refs[ref];
      //   componentes[ref] = referencia;
      // });
      componentes.geral = this.$refs.geral;
      return componentes;
    },
    carregarSeUsuarioPossuirAcesso(idAcao) {
      this.planejamentoAcaoListagemResource.verificarSeUsuarioPossuiAcesso({ idAcao })
        .then((res) => {
          if (res.data) {
            this.carregarAcao(idAcao);
          } else {
            this.$router.push({ name: 'inicio' });
            this.$toast('Acesso negado');
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    carregaTiposVerbaUsuarioLogado() {
      this.usuarioLogadoResources.tiposVerba()
        .then((res) => {
          if (res.data.length) {
            this.tiposVerbaUsuarioLogado = res.data;
          }
        })
        .catch(() => {});
    },
    atualizaIndTemEvidencia(indicador) {
      this.indTemEvidencia = indicador;
    },
  },
  mounted() {
    if (this.novo) {
      const { idTipoAcao } = this.$route.params;
      this.carregarTipoAcao(idTipoAcao);
      this.carregarConfiguracao(idTipoAcao);
    } else {
      const { idAcao, from } = this.$route.params;
      if (!from) {
        this.carregarSeUsuarioPossuirAcesso(idAcao);
      } else {
        this.carregarAcao(idAcao);
      }
      if (this.usuarioCliente) {
        this.carregaTiposVerbaUsuarioLogado();
      }
    }
  },
};
</script>
