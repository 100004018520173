<template>
  <div>
    <div style="min-height: 350px;">
      <grafico-barras
        category-field="anoMes"
        :title="`${$t('title.grafico_cliente_pagamento_acoes')}`"
        :colors="['#3232CD', '#FF7F00']"
        :value-fields="valueFieldsPagamentoAcoes"
        :dados="dados"
        v-if="dados"
        :height="412"
        :unit-y-axis="`${$t('label.unidade_dinheiro')} `"/>
    </div>
  </div>
</template>

<script>
import GraficoBarras from '../../GraficoBarras';
import { buscarGraficoPagamentosCliente } from '../../../../common/resources/liquidacao/pagamento';

export default {
  name: 'dash-cliente-graficos',
  components: {
    GraficoBarras,
  },
  props: {
    filtros: Object,
  },
  data() {
    return {

      valueFieldsPagamentoAcoes: [
        {
          balloonText: `${this.$tc('status_entidade_pagamento.aprovado', 1)}: ${this.$t('label.unidade_dinheiro')} [[value]]`,
          title: this.$tc('status_entidade_pagamento.aprovado', 1),
          valueField: 'pagamentoAprovado',
          type: 'column',
          valueAxis: 'v1',
        },
        {
          balloonText: `${this.$t('status_entidade_pagamento.liquidado')}: ${this.$t('label.unidade_dinheiro')} [[value]]`,
          title: this.$t('status_entidade_pagamento.liquidado'),
          valueField: 'pagamentoLiquidado',
          type: 'column',
          valueAxis: 'v2',
        },
      ],
      valueFieldsTop5Pagamentos: [
        {
          balloonText: `[[title]]: ${this.$t('label.unidade_dinheiro')} [[value]]`,
          title: 'primeiraColuna',
          valueField: 'primeiroPagamento',
          type: 'column',
          valueAxis: 'v1',
          data: null,
        },
        {
          balloonText: `[[title]]: ${this.$t('label.unidade_dinheiro')} [[value]]`,
          title: this.$t('status_entidade_pagamento.liquidado'),
          valueField: 'segundoPagamento',
          type: 'column',
          valueAxis: 'v2',
        },
        {
          balloonText: `[[title]]: ${this.$t('label.unidade_dinheiro')} [[value]]`,
          title: this.$t('status_entidade_pagamento.liquidado'),
          valueField: 'terceiroPagamento',
          type: 'column',
          valueAxis: 'v3',
        },
        {
          balloonText: `[[title]]: ${this.$t('label.unidade_dinheiro')} [[value]]`,
          title: this.$t('status_entidade_pagamento.liquidado'),
          valueField: 'quartoPagamento',
          type: 'column',
          valueAxis: 'v4',
        },
        {
          balloonText: `[[title]]: ${this.$t('label.unidade_dinheiro')} [[value]]`,
          title: this.$t('status_entidade_pagamento.liquidado'),
          valueField: 'quintoPagamento',
          type: 'column',
          valueAxis: 'v5',
        },
      ],
      dados: null,
      filtrosAplicados: this.filtros,
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.dados = null;
        this.buscarDados();
      },
    },
  },
  methods: {
    buscarDados() {
      const params = {
        ...this.filtrosAplicados,
      };
      buscarGraficoPagamentosCliente(params, this.$resource)
        .then((res) => {
          this.dados = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    if (this.filtros && this.filtros.data_inicio && this.filtros.data_fim) {
      this.buscarDados();
    }
  },
};
</script>
