<template>
  <orcamento-form
    :somente-leitura="false"
    :edicao="true"
    :novo="false"
    :copia="false"
  />
</template>
<script>
import OrcamentoForm from './OrcamentoForm';

export default {
  components: {
    OrcamentoForm,
  },
};
</script>
