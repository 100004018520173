import getBasePath from '../functions/api-resource';

const basePath = getBasePath('orcamento', 'desdobramento');

const desdobramentoActions = {
  desdobramentos: { method: 'GET', url: `${basePath}/desdobramentos/{id}` },
  transferir: { method: 'PUT', url: `${basePath}/transferir/{object}` },
  buscarPorLinhaInvestimento: { method: 'GET', url: `${basePath}/linhas-investimento/{codigo}?anoMes={anoMes}&vigente=false` },
  buscarVigentesPorLinhaInvestimento: { method: 'GET', url: `${basePath}/linhas-investimento/{codigo}?anoMes={anoMes}&vigente=true` },
  buscarVigentesPorLinhaInvestimentoTransferencia: { method: 'GET', url: `${basePath}/linhas-investimento/{codigo}?anoMes={anoMes}&vigente=true&valorMaiorZero=true` },
  buscarDestinoVigentesPorLinhaInvestimento: { method: 'GET', url: `${basePath}/linhas-investimento/{codigo}?anoMes={anoMes}&vigente=true&idDesdobramentoOrigem={idDesdobramentoOrigem}` },
  buscarRepresentantes: { method: 'GET', url: `${basePath}/{codigo}/representantes?nome={nome}` },
  buscarHierarquia: { method: 'GET', url: `${basePath}/{codigo}/hierarquia` },
  buscarPlanejadoDisponivel: { method: 'GET', url: `${basePath}/{codigo}/saldo-planejado-disponivel` },
  buscarPlanejadoDisponivelRepresentante: { method: 'GET', url: `${basePath}/{codigo}/saldo-planejado-disponivel/{idRepresentante}` },
};

export default (resource) => resource(`${basePath}`, {}, desdobramentoActions);
