<template>
  <v-data-table
    :headers="headers"
    :items="apuracoes"
    :options.sync="pagination"
    :server-items-length="totalPage"
    :items-per-page="[10, 25, 50]"
    class="elevation-1"
    :no-data-text="$t('label.tabela_sem_conteudo')"
    :rows-per-page-text="$t('label.linhas_por_pagina')">
    <template slot="items" slot-scope="props">
      <td class="justify-center px-0" style="width: 1%; text-align: center;">
        <v-btn icon class="mx-0" @click="exportarNotas(props.item)">
          <v-icon>get_app</v-icon>
        </v-btn>
      </td>
      <td>{{ props.item.idExterno }}</td>
      <td>{{ props.item.desRazaoSocial }}</td>
      <td nowrap>{{ props.item.desCnpj }}</td>
      <td>
        {{ props.item.baseCalculo
          ? $t(`label.${props.item.baseCalculo.toLowerCase()}`)
          : props.item.baseCalculo }}
      </td>
      <td nowrap>{{ getMoney(props.item.total) }}</td>
      <td>
        <popover-lista
            :objeto="props.item.descontos"
            :campos="campoPopover"
            v-if="props.item.vlrDescontos">
          <span slot="ativadorPopover">{{ getMoney(props.item.vlrDescontos) }}</span>
        </popover-lista>
        <span nowrap v-else>{{ getMoney(props.item.vlrDescontos) }}</span>
      </td>
      <td nowrap>{{ getMoney(props.item.valorBase) }}</td>
      <td nowrap>{{ formatarVerba(props.item) }}</td>
      <td nowrap
          v-if="ehRecebimentoComValor(props.item)">
        {{ getMoney(props.item.recebimento) }}
      </td>
      <td nowrap v-else>
        {{ getMoney(props.item.recebimento) }}
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon dark color="warning" small v-on="on">error_outline</v-icon>
          </template>
          <span>{{ $tc('message.apuracao_nao_finalizada_vlr_recebimento', 1) }}</span>
        </v-tooltip>
      </td>
    </template>
    <template slot="pageText" slot-scope="props">
      {{ props.pageStart }} - {{ props.pageStop }} {{$tc('label.de', 1)}} {{ props.itemsLength }}
    </template>
  </v-data-table>
</template>

<script>
import { buscarApuracaoFornecedorPorContrato } from '../../common/resources/apuracao/apuracao-contrato';
import { getMoney, getPercent } from '../../common/functions/helpers';
import exportacao from '../../common/functions/exportacao';
import PopoverLista from '../../shared-components/PopoverLista';

export default {
  name: 'ApuracaoContratoFinalizarLoteTabela',
  components: { PopoverLista },
  props: {
    idContrato: {
      type: Number,
    },
    filtros: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      pagination: {},
      totalPage: 0,
      esperar: false,
      apuracoes: [],
      recebimentoSemValorNaoEmitido: true,
      headers: [
        { text: '', value: 'acoes', sortable: false },
        { text: this.$tc('label.codigo', 1), value: 'id_externo' },
        { text: this.$tc('label.razao_social', 1), value: 'des_razao_social' },
        { text: this.$tc('label.cnpj', 1), value: 'des_cnpj' },
        { text: this.$tc('label.base_calculo', 1), value: 'base_calculo' },
        { text: this.$tc('label.total', 1), value: 'total' },
        { text: this.$tc('label.desconto', 1), value: 'vlr_descontos' },
        { text: this.$tc('label.valor_base', 1), value: 'valor_base' },
        { text: this.$tc('label.verba', 1), value: 'verba' },
        { text: this.$tc('label.recebimento', 1), value: 'recebimento' },
      ],
      campoPopover: [
        {
          label: this.$tc('label.ipi', 1),
          campo: 'ipi',
          formatarCampos: getMoney,
        },
        {
          label: this.$tc('label.icms', 1),
          campo: 'icms',
          formatarCampos: getMoney,
        },
        {
          label: this.$tc('label.pis_cofins', 1),
          campo: 'pisCofins',
          formatarCampos: getMoney,
        },
        {
          label: this.$tc('label.devolucao', 1),
          campo: 'devolucao',
          formatarCampos: getMoney,
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  methods: {
    getPercent,
    getMoney,
    ehRecebimentoComValor(item) {
      if (item.recebimento) {
        return true;
      }
      if (this.recebimentoSemValorNaoEmitido) {
        this.recebimentoSemValorNaoEmitido = false;
        this.$emit('ApuracaoContratoFinalizarLoteTabela__recebimentoSemValor');
      }
      return false;
    },
    formatarVerba(item) {
      if (item.formaBonificacao === 'FIXO') {
        return this.getMoney(item.verba);
      }
      return this.getPercent(item.verba);
    },
    filtrar() {
      if (this.esperar) return;

      this.esperar = true;
      setTimeout(() => {
        this.buscarApuracoes();
      }, 5E2);
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    exportarNotas(item) {
      const params = {
        id_apuracao: item.idApuracao,
        id_fornecedor: item.idFornecedor,
      };

      if (item.calculoBonificacao === 'SELLIN') {
        exportacao.exportar(null, 'notas_sellin_memoria', this, params);
      } else if (nota.calculoBonificacao === 'SELLOUT') {
        exportacao.exportar(null, 'notas_sellout_memoria', this, params);
      }
    },
    buscarApuracoes() {
      const params = { ...this.filtros };
      params.cod_contrato = this.idContrato;
      params.tamanhoPagina = this.pagination.itemsPerPage;
      params.numeroPagina = this.pagination.page;
      params.asc = !this.pagination.descending;
      params.colunaOrdenacao = this.pagination.sortBy;

      return buscarApuracaoFornecedorPorContrato(params, this.$resource)
        .then((response) => {
          this.apuracoes = response.data.resposta;
          this.totalPage = response.data.quantidadeRegistrosPagina;
          this.pararEsperar();
        })
        .catch((err) => {
          this.$error(this, err);
          this.pararEsperar();
        });
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
