<template>
 <div class="RelatorioStatusAtingimentoHistorico">
    <relatorio-status-atingimento-historico-filtros
      ref="filtros"
      @RelatorioStatusAtingimentoHistoricoFiltros__AplicaFiltros="aplicaFiltros"
    />
    <relatorio-status-atingimento-historico-tabela
      ref="tabela"
      :filtros="filtros"
    />
  </div>
</template>
<script>
import RelatorioStatusAtingimentoHistoricoFiltros from './RelatorioStatusAtingimentoHistoricoFiltros';
import RelatorioStatusAtingimentoHistoricoTabela from './RelatorioStatusAtingimentoHistoricoTabela';

export default {
  components: {
    RelatorioStatusAtingimentoHistoricoFiltros,
    RelatorioStatusAtingimentoHistoricoTabela,
  },
  data() {
    return {
      filtros: null,
    };
  },
  methods: {
    aplicaFiltros(filtrosAplicados) {
      if (!filtrosAplicados) {
        return;
      }
      this.filtros = {
        ...filtrosAplicados,
      };
    },
  },
};
</script>
<style>
.RelatorioStatusAtingimentoHistorico {
  padding-bottom: 60px;
}
.RelatorioStatusAtingimentoHistorico__Modal .headline {
  width: 100%;
  text-align: center;
}
.RelatorioStatusAtingimentoHistorico__Lista {
  margin-top: 20px;
}
</style>
