var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s(_vm.$tc('label.produto', 1)))])]),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ml-3",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('label.pesquisar'),"single-line":"","clearable":"","hide-details":""},on:{"input":_vm.filtrar},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","align-self":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.canAccessCRUD && _vm.selected.length > 0),expression:"canAccessCRUD && selected.length > 0"}],staticClass:"mx-0",attrs:{"icon":"","id":"produto-botao-ativar-inativar"},on:{"click":_vm.ativarDesativarRegistros}},on),[_c('v-icon',[_vm._v("block")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('label.ativar_inativa')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.canAccessCRUD && _vm.tipoImportacao),expression:"canAccessCRUD && tipoImportacao"}],staticClass:"mx-0",attrs:{"icon":"","id":"produto-botao-importar","disabled":!_vm.importacao || _vm.bloquearBotaoExportacao},on:{"click":_vm.importarDados}},on),[_c('v-icon',[_vm._v("backup")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('label.importar_conteudo')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.canAccessPage),expression:"canAccessPage"}],staticClass:"mx-0",attrs:{"icon":"","id":"produto-botao-exportar","disabled":!_vm.exportacao || _vm.bloquearBotaoExportacao},on:{"click":_vm.exportarDados}},on),[_c('v-icon',[_vm._v("get_app")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('label.exportar_conteudo')))])])],1)],1),_c('v-data-table',{staticClass:"tableProdutos",attrs:{"headers":_vm.headersDinamico,"items":_vm.produtos,"show-select":"","options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
      itemsPerPageOptions: [10, 25, 50],
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
    var isSelected = ref.isSelected;
    var select = ref.select;
return [_c('tr',{key:item.idExterno,class:{ 'TableRow--disabled': !item.indAtivo }},[_c('td',{staticStyle:{"width":"2%"}},[_c('v-simple-checkbox',{attrs:{"value":isSelected},on:{"input":function($event){return select($event)}}})],1),_c('td',{staticClass:"justify-center px-0",staticStyle:{"width":"2%"}},[(_vm.canEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editarItem(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.editar_produto')))])]):_vm._e(),(!_vm.canEdit && _vm.canGet)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.visualizarItem(item)}}},on),[_c('v-icon',[_vm._v("search")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.visualizar_produto')))])]):_vm._e()],1),_c('td',{staticStyle:{"width":"5%"},attrs:{"nowrap":""}},[_vm._v(_vm._s(item.idExterno))]),_c('td',{staticStyle:{"width":"15%"}},[_vm._v(_vm._s(item.nomProduto))]),_c('td',{staticStyle:{"width":"5%"},attrs:{"nowrap":""}},[_vm._v(_vm._s(item.ean))]),_vm._l((Object.keys(_vm.headersDinamicoObrigatorio)),function(entidade,indice){return _c('td',{key:indice,staticStyle:{"width":"5%"}},[_vm._v(" "+_vm._s(_vm.getNomesSeparadosPorVirgula(entidade, 'nomExtensao', item))+" ")])})],2)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }