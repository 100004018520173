<template>
  <div class="PlanejamentoDpmDeclaracaoCompra">
    <div class="mt-2 mb-2 title-float accent--text">
      {{$tc('label.declaracao_compra_agro')}}
    </div>
    <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-expansion-panels v-model="painel">
            <v-expansion-panel>
              <v-expansion-panel-header class="pb-0 pt-0">
                <v-row align="start" justify="space-between" class="pr-8">
                  <span class="grey--text text--darken-1 preview">
                    <b>{{`${this.$tc('label.compra', 2)} ${this.anoAnteriorPeriodo}: `}}</b>{{getMoney(sumComprasAnterior, 0, 2, '$' )}}
                  </span>
                  <span class="grey--text text--darken-1 preview">
                    <b>{{`${this.$t('label.cs')} ${this.anoAnteriorPeriodo}: `}}</b>{{getPercent(sumCSAnterior)}}
                  </span>
                  <span class="grey--text text--darken-1 preview">
                    <b>{{`${this.$tc('label.compra', 2)} ${this.anoAtualPeriodo}: `}}</b>{{getMoney(sumComprasAtual, 0, 2, '$' )}}
                  </span>
                  <span class="grey--text text--darken-1 preview">
                    <b>{{`${this.$t('label.cs')} ${this.anoAtualPeriodo}: `}}</b>{{getPercent(sumCSAtual)}}
                  </span>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="cabecalho"
                  :items="linhas"
                  dense
                  disable-filtering
                  disable-pagination
                  disable-sort
                  hide-default-footer>
                  <template v-slot:item.comprasAnterior="{ item }">
                    <input-decimal
                      v-model="item.comprasAnterior"
                      single-line
                      hide-details
                      dense
                      align-right
                      :rules="[rules.maximoPadrao]"
                      class="py-0 mt-0 mb-1"
                      v-if="item.fornecedor !== 'ADAMA'"
                      :disabled="somenteLeitura"
                      @input="compraAnteriorAlterada()"
                      />
                    <span v-else>
                      {{getMoney(item.comprasAnterior, 0, 2, '$')}}
                    </span>
                  </template>
                  <template v-slot:item.csAnterior="{ item }">
                    {{getPercent(item.csAnterior)}}
                  </template>
                  <template v-slot:item.comprasAtual="{ item }">
                    <input-decimal
                      :disabled="somenteLeitura"
                      v-model="item.comprasAtual"
                      single-line
                      hide-details
                      dense
                      align-right
                      :rules="[rules.maximoPadrao]"
                      class="py-0 mt-0 mb-1"
                      v-if="item.fornecedor !== 'ADAMA'"
                      @input="compraAtualAlterada()"
                      />
                    <span v-else>
                      {{getMoney(item.comprasAtual, 0, 2, '$')}}
                    </span>
                  </template>
                  <template v-slot:item.csAtual="{ item }">
                    {{getPercent(item.csAtual)}}
                  </template>
                  <template v-slot:body.append>
                    <tr>
                      <td class="text-left" style="font-weight:bold">
                        {{ $t('label.total') }}:
                      </td>
                      <td style="font-weight:bold;text-align:right">
                        {{getMoney(sumComprasAnterior, 0, 2, '$')}}
                      </td>
                      <td style="font-weight:bold;text-align:right">
                        {{getPercent(sumCSAnterior)}}
                      </td>
                      <td style="font-weight:bold;text-align:right">
                        {{getMoney(sumComprasAtual, 0, 2, '$')}}
                      </td>
                      <td style="font-weight:bold;text-align:right">
                        {{getPercent(sumCSAtual)}}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { getPercent, getMoney } from '../../../../produto/common/functions/helpers';
import InputDecimal from '../../../../produto/shared-components/inputs/InputDecimal';

export default {
  props: {
    objetoPassoGeral: {
      type: Object,
      required: true,
    },
    somenteLeitura: Boolean,
    anoAtualPeriodo: {
      type: Number,
      required: true,
    },
    anoAnteriorPeriodo: {
      type: Number,
      required: true,
    },
    declaracoesCompras: Array,
    gruposCultura: Array,
    produtosServicos: Array,
  },
  components: {
    InputDecimal,
  },
  data() {
    return {
      sumCSAnterior: 0,
      sumComprasAnterior: 0,
      sumComprasAtual: 0,
      sumCSAtual: 0,
      rules: {
        maximoPadrao: (value) => value <= 99999999.99 || this.$t('message.valor_padrao_maximo'),
      },

      painel: -1,
      linhas: [],
      cabecalho: [
        {
          text: this.$tc('label.fornecedor', 2),
          value: 'fornecedor',
          align: 'start',
          width: '20%',
        },
        {
          text: `${this.$tc('label.compra', 2)} ${this.anoAnteriorPeriodo}`,
          value: 'comprasAnterior',
          align: 'end',
          width: '20%',
        },
        {
          text: `${this.$t('label.cs')} ${this.anoAnteriorPeriodo}`,
          value: 'csAnterior',
          align: 'end',
          width: '20%',
        },
        {
          text: `${this.$tc('label.compra', 2)} ${this.anoAtualPeriodo}`,
          value: 'comprasAtual',
          align: 'end',
          width: '20%',
        },
        {
          text: `${this.$t('label.cs')} ${this.anoAtualPeriodo}`,
          value: 'csAtual',
          align: 'end',
          width: '20%',
        },
      ],
    };
  },
  methods: {
    getPercent,
    getMoney,
    atualizaCompraAtualAdama() {
      const total = this.gruposCultura
        .map((grupo) => grupo.marcas)
        .flat()
        .concat(this.produtosServicos)
        .map((i) => i.pontuacaoMeta)
        .reduce((p, a) => p + a, 0);

      const dec = this.declaracoesCompras
        .filter((d) => d.fornecedor === 'ADAMA')[0];
      if (dec) {
        dec.comprasAtual = total;
      }

      this.linhas = [...this.declaracoesCompras];
      this.calcSumCSAnterior();
      this.calcSumCSAtual();
      this.calcSumComprasAnterior();
      this.calcSumComprasAtual();

      this.recalculaCustomerShareAtual();
    },
    calcSumCSAnterior() {
      this.sumCSAnterior = this.linhas.map((i) => i.csAnterior)
        .reduce((p, a) => (p || 0) + (a || 0), 0);
    },
    calcSumComprasAnterior() {
      this.sumComprasAnterior = this.linhas.map((i) => i.comprasAnterior)
        .reduce((p, a) => (p || 0) + (a || 0), 0);
    },
    calcSumComprasAtual() {
      this.sumComprasAtual = this.linhas.map((i) => i.comprasAtual)
        .reduce((p, a) => (p || 0) + (a || 0), 0);
    },
    calcSumCSAtual() {
      this.sumCSAtual = this.linhas.map((i) => i.csAtual)
        .reduce((p, a) => (p || 0) + (a || 0), 0);
    },
    compraAnteriorAlterada() {
      this.calcSumComprasAnterior();
      this.recalculaCustomerShareAnterior();
      this.calcSumCSAnterior();
    },
    compraAtualAlterada() {
      this.calcSumComprasAtual();
      this.recalculaCustomerShareAtual();
      this.calcSumCSAtual();
    },
    recalculaCustomerShareAnterior() {
      this.linhas.forEach((item) => {
        const div = this.sumComprasAnterior === 0 ? 1 : this.sumComprasAnterior;
        item.csAnterior = ((item.comprasAnterior || 0) / div) * 100;
      });
    },
    recalculaCustomerShareAtual() {
      this.linhas.forEach((item) => {
        const div = this.sumComprasAtual === 0 ? 1 : this.sumComprasAtual;
        item.csAtual = ((item.comprasAtual || 0) / div) * 100;
      });
    },
    calculosIniciais() {
      this.calcSumComprasAnterior();
      this.calcSumComprasAtual();

      this.recalculaCustomerShareAtual();
      this.recalculaCustomerShareAnterior();

      this.calcSumCSAnterior();
      this.calcSumCSAtual();
    },
  },
  mounted() {
    this.linhas = [...this.declaracoesCompras];
    this.calculosIniciais();
  },
};
</script>
<style scoped>
.preview {
  font-size:.8rem;
}
</style>
<style>
.PlanejamentoDpmDeclaracaoCompra .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
