<template>
  <div>
    <v-card class="PlanejamentoContratoListDados">
      <v-card-title class="mb-3">
        <div class="title">{{ $tc('title.contrato', 2) }}</div>
        <planejamento-contrato-list-dados-agrupamento
          ref="agrupamento"
          @PlanejamentoContratoListDadosAgrupamento_Alterado="alterarAgrupamento"/>
      </v-card-title>

      <v-expansion-panels
        accordion
        focusable
        v-if="!iniciandoPainel">
        <v-expansion-panel
          v-for="(tipoContrato, index) in tiposContrato"
          :key="index">
          <v-expansion-panel-header>
            {{ tituloDoPainel(tipoContrato) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <planejamento-contrato-list-dados-tabela
              :tipo-contrato="tipoContrato"
              :ordenacao="parametrizacaoContrato[tipoContrato.id]"
              :label-campo-cliente="labelsCampoCliente[tipoContrato.id]"
              :filtros="filtrosAplicados"
              :agrupamento="filtrosAplicados.agrupamento"
              :ref="`tabela-${tipoContrato.id}`"
              @refazer_busca="aplicaFiltros({})"
              />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <div class="text-xs-center mt-3" v-if="totalPagina > 1">
      <v-pagination
        v-model="pagina"
        :length="totalPagina"
        :total-visible="$vuetify.breakpoint.mdAndUp ? 15 : 7"
        @input="aplicaFiltros({})"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import PlanejamentoContratoListDadosTabela from './PlanejamentoContratoListDadosTabela';
import PlanejamentoContratoListDadosAgrupamento from './PlanejamentoContratoListDadosAgrupamento';
import {
  buscarTiposContrato,
} from '../../../common/resources/planejamento/planejamento-contrato-listagem';

export default {
  name: 'PlanejamentoContratoListDados',
  components: {
    PlanejamentoContratoListDadosTabela,
    PlanejamentoContratoListDadosAgrupamento,
  },
  props: {
    tipoContrato: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      configuracaoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),

      tiposContrato: [],
      painel: null,
      pagina: 1,
      totalPagina: 1,
      quantidadePagina: 15,
      iniciandoPainel: true,

      filtrosAplicados: {},
      parametrizacaoContrato: {},
      labelsCampoCliente: {},
    };
  },
  methods: {
    async carregaParametrosTiposContrato(tiposContrato) {
      await Promise.all(tiposContrato
        .map((tipo) => this.configuracaoResource
          .buscarCamposGrid({ idTipoContrato: tipo.id })
          .then((res) => {
            const campos = res.data;
            this.parametrizacaoContrato[tipo.id] = campos;
          })));
    },
    async carregaLabelCampoCliente(tiposContrato) {
      await Promise.all(tiposContrato
        .map((tipo) => this.configuracaoResource
          .buscarLabelCampoCliente({ idTipoContrato: tipo.id })
          .then((res) => {
            this.labelsCampoCliente[tipo.id] = res.bodyText;
          })));
    },
    tituloDoPainel(tipoContrato) {
      let titulo = `${this.$t('label.tipo_contrato')} :: ${tipoContrato.nome}`;
      if (tipoContrato.agrupamento) {
        const { tipoAgrupamento, agrupamento } = tipoContrato;
        let labelTipo;
        if (['cliente', 'fornecedor'].indexOf(tipoAgrupamento) >= 0) {
          labelTipo = this.$tc(`label.${tipoAgrupamento}`, 1);
        } else {
          labelTipo = tipoAgrupamento;
        }
        titulo += ` :: ${labelTipo} :: ${agrupamento}`;
      }
      return titulo;
    },
    aplicaFiltros(filtros) {
      this.tiposContrato = null;
      this.painel = null;
      const agrupamento = this.$refs.agrupamento.agrupamentoHabilitado();
      const { indiceLabelAgrupamento } = this.$refs.agrupamento;

      this.filtrosAplicados = {
        ...filtros,
        numeroPagina: this.pagina,
        tamanhoPagina: this.quantidadePagina,
        indice_label_agrupamento: indiceLabelAgrupamento,
        agrupamento,
      };

      if (this.restringeAgrupamentoTipo) {
        this.filtrosAplicados.idTipoContrato = [this.tipoContrato.id];
      }

      buscarTiposContrato(this.filtrosAplicados, this.$resource)
        .then((res) => {
          const tiposContrato = res.data;
          this.totalPagina = Math.ceil(res.data.quantidadeRegistrosPagina / this.quantidadePagina);
          this.carregaParametrosTiposContrato(tiposContrato)
            .then(() => this.carregaLabelCampoCliente(tiposContrato))
            .then(() => {
              this.tiposContrato = tiposContrato;
              this.iniciandoPainel = false;
            });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    alterarAgrupamento() {
      this.pagina = 1;
      this.$emit('PlanejamentoContratoDados__AplicaFiltros');
    },
  },
  mounted() {
    if (this.restringeAgrupamentoTipo) {
      setTimeout(() => this.$refs.agrupamento.agrupamentoPadraoPorExtensao());
    } else {
      setTimeout(() => this.$refs.agrupamento.agrupamentoPadraoPorTipo());
    }
  },
};
</script>
